import Query from '../../../components/Report';

export const query = (cluster) =>
    new Query('search-keywords-clusters-detail-chart', {
        cluster,
    });

export const query_for_manual = (cluster_id, deleted_keywords, user_domain_id) =>
    new Query('search-keywords-clusters-detail-chart-manual', {
        cluster_id,
        deleted_keywords: deleted_keywords ? `and keyword_name NOT IN ${deleted_keywords}` : '',
        user_domain_id,
    });
