import Component from '../../components/Component';
import query from './query';
import CategoriesView from '../CategoriesView';
import CategoriesBubbles from '../CategoriesBubbles';
import { tags } from './keys';
import Opportunities from './opportunities';

class CategoriesViewAll extends Component {
    constructor() {
        super();
        this.query = query(true);
        this.urlParams = new URLSearchParams(window.location.search);
        this.view = this.urlParams.get('view');

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.query = query(this.state.isConfidence);
        this.query.bind(this.setState.bind(this));
        this.query.update();

        this.onClick = this.onClick.bind(this);
        this.addActive = this.addActive.bind(this);
        this.showElement = this.showElement.bind(this);

        this.setState({
            isConfidence: !this.state.isConfidence,
            loading: true,
        });
    }

    showElement(id) {
        if (!this.state.types && this.view) return this.view === id;
        if (!this.state.types && id === 'categories') return true;
        return this.state.types?.includes(id);
    }

    onClick(id) {
        this.url = new URL(window.location);
        this.url.searchParams.set('view', id);
        history.pushState({}, '', this.url);

        return this.setState({
            types: [id],
        });
    }

    addActive(id) {
        if (!this.state.types && this.view) return this.view === id;
        if (!this.state.types && id === 'categories') return true;
        return !!this.state.types?.includes(id);
    }

    render() {
        return (
            <div>
                <div className="card-row" id="categories-bubbles">
                    <div className="col-12">
                        <div className="card rounded-main overflow-hidden">
                            <div className="card-body">
                                <CategoriesBubbles
                                    data={this.state.data}
                                    loading={this.state.loading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tags-container">
                    {tags.map((tag) => (
                        <span
                            className={`tab-item ${this.addActive(tag.id) && 'active-tag'}`}
                            key={tag.id}
                            onClick={() => this.onClick(tag.id)}
                        >
                            {tag.label}
                        </span>
                    ))}
                </div>
                <div
                    style={{
                        display: this.showElement('categories') ? 'block' : 'none',
                    }}
                >
                    <CategoriesView
                        data={this.state.data}
                        loading={this.state.loading}
                        content_id={this.state.content_id}
                        parent_id={this.state.parent_id}
                        toggle={this.toggle}
                        forceRun={this.forceRun}
                        isConfidence={!this.state.isConfidence}
                        {...this.props}
                    />
                </div>
                <div
                    style={{
                        display: this.showElement('opportunities') ? 'block' : 'none',
                    }}
                >
                    <Opportunities data={this.state.data} />
                </div>
            </div>
        );
    }
}

export default CategoriesViewAll;
