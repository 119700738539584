import React, { Component } from 'react';
import Select from 'react-select';
import { isTrue } from '../../helpers/isTrue';

class SearchDropdown extends Component {
    constructor(props) {
        super();

        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangeVisibility = this.handleChangeVisibility.bind(this);
        const domain = JSON.parse(props.domains).find((domain) => domain.id === props.domain_id);
        this.state = {
            value: isTrue(props.is_superuser)
                ? {
                      label: (
                          <div className="gsc-test-user">
                              <div> {domain?.url ?? 'Select Domain'}</div>
                              <div>
                                  {domain?.active ? (
                                      <i className="text-success icofont-verification-check"></i>
                                  ) : (
                                      <i className=" text-danger icofont-ui-close"></i>
                                  )}{' '}
                              </div>
                          </div>
                      ),
                      value: domain?.id,
                      id: domain?.id,
                  }
                : {
                      label: <span className="domain-name">{domain?.url ?? 'Select Domain'}</span>,
                      value: domain?.id,
                      id: domain?.id,
                  },
            isHidden: false,
        };
    }

    componentDidMount() {
        this.handleChangeVisibility();
        document.addEventListener('changeDomainVisibilityEvent', this.handleChangeVisibility);
    }

    componentWillUnmount() {
        document.removeEventListener('changeDomainVisibilityEvent', this.handleChangeVisibility);
    }

    handleChangeVisibility() {
        const isHidden = JSON.parse(localStorage.getItem('hide-domain'));
        this.setState({ isHidden });
    }

    handleSelect(event) {
        const pathName = window.location.pathname.split('/');
        pathName.splice(2, 1, event.id);
        const newPath = pathName.join('/');
        window.location.pathname = newPath;
    }

    render() {
        const domains = JSON.parse(this.props.domains);
        const optionList = isTrue(this.props.is_superuser)
            ? domains.map((domain) => ({
                  id: domain?.id,
                  value: domain?.url,
                  label: (
                      <div className="gsc-test-user">
                          <div> {domain?.url ?? 'Select Domain'}</div>
                          <div>
                              {domain?.active ? (
                                  <i className="text-success icofont-verification-check"></i>
                              ) : (
                                  <i className=" text-danger icofont-ui-close"></i>
                              )}{' '}
                          </div>
                      </div>
                  ),
              }))
            : domains.map((domain) => ({
                  value: domain?.id,
                  label: domain?.url,
                  id: domain?.id,
              }));

        return (
            <div className="dropdown border primary no-padding">
                <i className="icofont-globe domain-dropdown-icon"></i>
                <Select
                    options={optionList}
                    defaultValue={this.state.value}
                    className="domains-select"
                    menuPortalTarget={document.querySelector('body')}
                    menuPosition={'fixed'}
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            zIndex: 11,
                            minWidth: '295px',
                            boxShadow: 'none',
                        }),
                        menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 10000,
                            WebkitTextSecurity: this.state.isHidden ? 'disc' : 'none',
                        }),
                        menu: (provided) => ({ ...provided, zIndex: 10000 }),
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: '0 4px 4px 0',
                        zIndex: 11,
                        colors: {
                            ...theme.colors,
                            primary: '#888adb',
                        },
                    })}
                    onChange={this.handleSelect}
                    isSearchable={true}
                    placeholder="Select domain"
                    onFocus={(e) => {
                        const parent = e.nativeEvent.target.parentNode.parentNode;
                        const selectedValue = parent.children[0];
                        selectedValue.style.display = 'none';
                    }}
                    onBlur={(e) => {
                        const parent = e.nativeEvent.target.parentNode.parentNode;
                        const selectedValue = parent.children[0];
                        selectedValue.style.display = 'inline-grid';
                    }}
                    blurInputOnSelect={true}
                />
            </div>
        );
    }
}

export default SearchDropdown;
