import Query from '../../components/Report';
import unique from '../../lib/unique';

const query = ({ offset = 0, type }) =>
    type
        ? new Query('discover-entities-type', {
              filtersMap: (f) => {
                  if (f && f.key == 'page') {
                      f.key = 'pages';
                      f.cmp = 'in';
                  }

                  return f;
              },
              transformRow: (row) => {
                  row.pages = unique(row.pages).length;
                  row.ipr = row.impressions / row.pages;

                  return row;
              },
              offset,
              type,
          })
        : new Query('discover-entities', {
              filtersMap: (f) => {
                  if (f && f.key == 'page') {
                      f.key = 'pages';
                      f.cmp = 'in';
                  }

                  return f;
              },
              transformRow: (row) => {
                  row.pages = unique(row.pages).length;
                  row.ipr = row.impressions / row.pages;

                  return row;
              },
              offset,
          });

export default query;
