import { Component } from 'react';
import { addEmails, deleteEmail, getEmails } from './query';
import Loading from '../../components/Loading';
import { v4 as uuid } from 'uuid';
import Message from '../Message';

class AdditionalEmailsSettings extends Component {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.toggelEdit = this.toggelEdit.bind(this);
        this.deleteUserFileds = this.deleteUserFileds.bind(this);
        this.addUserFileds = this.addUserFileds.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);
        this.getEmailsFn = this.getEmailsFn.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.addInDeleteList = this.addInDeleteList.bind(this);

        this.state = {
            emails: [],
            updatedEmails: [],
            isEdit: false,
            loader: false,
            getLoader: false,
            deleteList: [],
        };
    }

    componentDidMount() {
        this.getEmailsFn();
    }

    async getEmailsFn() {
        try {
            this.setState({
                getLoader: true,
            });
            const res = await getEmails();
            const data = await res.json();
            if (res.status === 200) {
                const valuesWithIds = data.result.map((item) => ({ id: uuid(), email: item }));
                this.setState({
                    getLoader: false,
                    emails: valuesWithIds,
                    updatedEmails: valuesWithIds?.length
                        ? valuesWithIds
                        : [{ id: uuid(), email: '' }],
                });
            } else {
                this.setState({
                    getLoader: false,
                });
            }
        } catch (err) {
            console.log('err', err);
        }
    }

    handleChange(e, id) {
        const emails = this.state.updatedEmails.map((email) => {
            if (email.id === id) {
                return { ...email, [e.target.name]: e.target.value };
            }
            return email;
        });

        this.setState({
            updatedEmails: emails,
        });
    }

    addUserFileds() {
        const emptyUser = {
            id: uuid(),
            email: '',
        };

        this.setState({
            updatedEmails: [...this.state.updatedEmails, emptyUser],
        });
    }

    deleteUserFileds(id, email) {
        this.addInDeleteList(email);
        this.setState({
            updatedEmails: this.state.updatedEmails.filter((item) => item.id !== id),
        });
    }

    toggelEdit() {
        this.setState({
            isEdit: !this.state.isEdit,
        });
    }

    async onDelete(emails) {
        try {
            this.setState({
                emails: this.state.emails.map((item) => ({
                    ...item,
                    loading: emails.includes(item.email) ? true : item.loading,
                })),
            });
            const res = await deleteEmail(emails);
            const data = await res.json();

            if (res.status === 200) {
                const freshUpdatedDatas = this.state.updatedEmails.filter(
                    (item) => !emails.includes(item.email),
                );
                this.setState({
                    emails: this.state.emails.filter((item) => !emails.includes(item.email)),
                    updatedEmails: freshUpdatedDatas?.length
                        ? freshUpdatedDatas
                        : [{ id: uuid(), email: '' }],
                });
            } else {
                this.setState({
                    emails: this.state.emails.map((item) => ({
                        ...item,
                        loading: emails.includes(item.email) ? false : item.loading,
                    })),
                    message: data?.message,
                });
            }
        } catch (err) {
            console.log('err', err);
        }
    }

    async onSubmit() {
        try {
            this.setState({
                loader: true,
            });

            if (this.state.deleteList.length) {
                await this.onDelete(this.state.deleteList);
                this.setState({
                    deleteList: [],
                });
            }

            const emails = this.state.updatedEmails
                .filter((item) => !this.state.emails.find((state) => state.email === item.email))
                .map((item) => item.email.trim())
                .filter((item, i, arr) => arr.indexOf(item) === i);

            const res = await addEmails(emails);
            if (res.status === 200) {
                this.setState({
                    loader: false,
                    isEdit: false,
                });
                this.getEmailsFn();
            } else {
                const data = await res.json();
                this.setState({
                    loader: false,
                    message: data?.message ?? 'Something went wrong. Please try again.',
                    message_tags: 'error',
                });
            }
        } catch (err) {
            this.setState({
                loader: false,
                message: err.message ?? 'Something went wrong. Please try again.',
                message_tags: 'error',
            });
            console.log(err);
        }
    }

    addInDeleteList(email) {
        if (this.state.emails.find((item) => item.email === email)) {
            this.setState({
                deleteList: [...this.state.deleteList, email],
            });
        }
    }

    deleteMessage() {
        this.setState({ message: '', message_tags: '' });
    }

    render() {
        return (
            <>
                <div className="card rounded-main table-main">
                    <div
                        className="card-header d-flex justify-content-between"
                        style={{ borderRadius: '10px' }}
                    >
                        <div>
                            <div className="card-title text-dark fw-bold">
                                Reports Additional Emails
                            </div>
                            <small className="d-block card-sub-title text-muted text-dark fs-7 text-capitalize py-2">
                                Add other users to receive weekly and monthly performance emails.
                                These users will not have access to the tool but will receive
                                performance reports via email.
                            </small>
                        </div>
                        <button
                            className="btn text-primary dropdown-toggle primary d-inline-flex align-items-center justify-content-center"
                            onClick={this.toggelEdit}
                        >
                            {this.state.isEdit ? (
                                <i className="icon-close" />
                            ) : (
                                <i className="icon-edit" />
                            )}
                        </button>
                    </div>
                    <div className="card-body additional-emails-settings">
                        {this.state.isEdit ? (
                            <div id="onboarding-general-settings" className="w-100">
                                <div className="have-scroll">
                                    {this.state.updatedEmails.map((item, i, arr) => (
                                        <div className="onboarding-add-user short" key={item.id}>
                                            <div className="onboarding-add-user-field">
                                                <label htmlFor="email" className="form-label">
                                                    Email Address
                                                </label>
                                                <input
                                                    name="email"
                                                    id="email"
                                                    className="form-control"
                                                    value={item.email}
                                                    onChange={(e) => this.handleChange(e, item.id)}
                                                />
                                            </div>
                                            <div className="onboarding-add-user-btns">
                                                {arr.length !== 1 && (
                                                    <button
                                                        onClick={() =>
                                                            this.deleteUserFileds(
                                                                item.id,
                                                                item.email,
                                                            )
                                                        }
                                                    >
                                                        -
                                                    </button>
                                                )}
                                                {i === arr.length - 1 && (
                                                    <button
                                                        className="onboarding-add-user-btns-add"
                                                        onClick={this.addUserFileds}
                                                    >
                                                        +
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="d-flex">
                                        <button
                                            className="button  button-filled button-primary width-sm btn onboarding-btn-next d-flex justify-content-center"
                                            onClick={this.onSubmit}
                                            disabled={this.state.loader}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : this.state.getLoader ? (
                            <Loading />
                        ) : (
                            <>
                                {this.state.emails?.map((item) => (
                                    <div key={item.id} className="additional-emails-settings-item">
                                        {item.email}
                                        {item.loading ? (
                                            <Loading onlyPreloader />
                                        ) : (
                                            <button onClick={() => this.onDelete([item.email])}>
                                                <i className="icon-close" />
                                            </button>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tags}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}{' '}
            </>
        );
    }
}

export default AdditionalEmailsSettings;
