import Timeline from '../../../components/Timeline';
import { Component } from 'react';
import { module1Keys } from './keys';

class PositionDistributionModule1 extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <Timeline
                chartSize="lg"
                metricType="primary"
                data={this.props.data}
                error={this.props.error}
                keys={module1Keys}
                forceRun={this.props.forceRun}
                parent_id={this.props.parent_id}
                content_id={this.props.content_id}
                leftAxisLabel="# of Keywords"
            />
        );
    }
}

export default PositionDistributionModule1;
