import React, { Component } from 'react';
import LinkAccount from './components/01_LinkAccount';
import Domains from './components/02_Domains';
import ActivateDomains from './components/03_ActivateDomains';
import AddUsers from './components/04_AddUsers';
import TrackGoogleDiscover from './components/06_TrackGoogleDiscover';
import AuthorReports from './components/07_AuthorReports';
import BrandedTerms from './components/08_BrandedTerms';
import SearchBehavior from './components/10_SearchBehavior';
import AddTaxIdOrVat from './components/AddTaxIdOrVat';
import AddAdditionalEmails from './components/05_AddAdditionalEmails';
// import AdditionalAddOns from './components/AdditionalAddOns';

function getIsEmptyProfile(obj) {
    for (let key in obj) {
        if (obj[key]) return false;
    }
    return true;
}

class Onboarding extends Component {
    constructor(props) {
        super();

        this.showOpened = this.showOpened.bind(this);
        this.onClickOnStep = this.onClickOnStep.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.selectAccount = this.selectAccount.bind(this);
        this.onAddUsers = this.onAddUsers.bind(this);
        this.finishSteps = this.finishSteps.bind(this);
        this.isDisabled = this.isDisabled.bind(this);

        this.domains = JSON.parse(props.domains);
        this.profile = JSON.parse(props.profile);
        this.available_accounts = JSON.parse(props.available_accounts);

        const isActiveDomain = this.domains?.find((item) => item.status);
        const isEmptyProfile = getIsEmptyProfile(this.profile);

        const isFinished = isActiveDomain && !isEmptyProfile;
        const alreadySelectedAccountId = localStorage.getItem('onboarding_account_id');
        let alreadySelectedAccount = null;
        if (alreadySelectedAccountId) {
            alreadySelectedAccount = this.available_accounts.find(
                (item) => item.account_id === alreadySelectedAccountId,
            );
        }

        this.state = {
            allData: [],
            isLoading: true,
            selectedCollapse: isFinished ? 0 : this.domains?.length > 0 ? 3 : 1,
            paymentInformation: null,
            selectedAccount: alreadySelectedAccount,
            users_remaining_count: Number(props.users_remaining_count),
            users_total_number_of_available: Number(props.users_total_number_of_available),
            isFinished,
        };
    }

    selectAccount(account) {
        localStorage.setItem('onboarding_account_id', account.account_id);
        this.setState({
            selectedAccount: account,
        });
        this.handleNext(1); // current step is 1
    }

    onClickOnStep(name) {
        this.setState({
            selectedCollapse: name,
        });
    }

    showOpened(name, value) {
        return this.state.selectedCollapse === name ? value ?? 'active' : '';
    }

    handleNext(id) {
        const index = this.data.findIndex((item) => item.id === id);
        const nextId = this.data[index + 1].id;
        this.setState({
            selectedCollapse: nextId,
        });
    }

    handleBack(id) {
        const index = this.data.findIndex((item) => item.id === id);
        const prevId = this.data[index - 1].id;
        this.setState({
            selectedCollapse: prevId,
        });
    }

    onAddUsers(count) {
        this.setState({
            users_remaining_count: this.state.users_remaining_count - count,
        });
    }

    finishSteps() {
        this.setState({
            isFinished: true,
            selectedCollapse: 0,
        });
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
    }

    isDisabled(id) {
        switch (id) {
            case 1:
                return false;
            case 2: {
                if (
                    this.available_accounts.length === 0 ||
                    (!this.state.selectedAccount && this.domains.length === 0)
                )
                    return true;
                return false;
            }
            case 3: {
                if (this.domains.length === 0) return true;
                return false;
            }
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10: {
                if (
                    this.available_accounts.length === 0 ||
                    this.domains.length === 0 ||
                    !this.domains.find((item) => item.status)
                )
                    return true;
                return false;
            }
            default: {
                return false;
            }
        }
    }

    render() {
        this.data = [
            {
                id: 1,
                name: 'Connect your Google Search Console',
                component: (
                    <LinkAccount
                        account={this.props.gd_src}
                        domains={this.domains}
                        available_accounts={this.available_accounts}
                        csrf_token={this.props.csrf_token}
                        selectAccount={this.selectAccount}
                    />
                ),
            },
            {
                id: 2,
                name: 'Select Your Domains',
                component: (
                    <Domains
                        csrf_token={this.props.csrf_token}
                        domains={this.domains}
                        selectedAccount={this.state.selectedAccount}
                        handleNext={() => this.handleNext(2)}
                        handleBack={() => this.handleBack(2)}
                        // isDisabledNext={this.isDisabled(3)}
                    />
                ),
            },
            {
                id: 3,
                name: 'Activate Domains for Trial',
                component: (
                    <ActivateDomains
                        domains={this.domains}
                        csrf_token={this.props.csrf_token}
                        handleNext={() => this.handleNext(3)}
                        handleBack={() => this.handleBack(3)}
                        // isDisabledNext={this.isDisabled(4)}
                    />
                ),
            },
            {
                id: 4,
                name: 'Add Users (Optional)',
                component: (
                    <AddUsers
                        domains={this.domains}
                        csrf_token={this.props.csrf_token}
                        users_remaining_count={this.state.users_remaining_count}
                        users_total_number_of_available={this.state.users_total_number_of_available}
                        onAddUsers={this.onAddUsers}
                        handleNext={() => this.handleNext(4)}
                        handleBack={() => this.handleBack(4)}
                    />
                ),
            },
            {
                id: 5,
                name: 'Add Additional Emails (Optional)',
                component: (
                    <AddAdditionalEmails
                        domains={this.domains}
                        csrf_token={this.props.csrf_token}
                        users_remaining_count={this.state.users_remaining_count}
                        users_total_number_of_available={this.state.users_total_number_of_available}
                        onAddUsers={this.onAddUsers}
                        handleNext={() => this.handleNext(5)}
                        handleBack={() => this.handleBack(5)}
                    />
                ),
            },
            {
                id: 6,
                name: 'Track Google Discover',
                component: (
                    <TrackGoogleDiscover
                        domains={this.domains}
                        csrf_token={this.props.csrf_token}
                        handleNext={() => this.handleNext(6)}
                        handleBack={() => this.handleBack(6)}
                        tracking_entity={this.props.tracking_entity}
                    />
                ),
            },
            {
                id: 7,
                name: 'Author Reports (Optional)',
                component: (
                    <AuthorReports
                        csrf_token={this.props.csrf_token}
                        handleNext={() => this.handleNext(7)}
                        handleBack={() => this.handleBack(7)}
                    />
                ),
            },
            {
                id: 8,
                name: 'Branded Terms',
                component: (
                    <BrandedTerms
                        domains={this.domains}
                        csrf_token={this.props.csrf_token}
                        handleNext={() => this.handleNext(8)}
                        handleBack={() => this.handleBack(8)}
                    />
                ),
            },
            {
                id: 9,
                name: 'Search Behavior',
                component: (
                    <SearchBehavior
                        domains={this.domains}
                        csrf_token={this.props.csrf_token}
                        handleNext={() => this.handleNext(9)}
                        handleBack={() => this.handleBack(9)}
                    />
                ),
            },
            // {
            //     id: 9,
            //     name: 'Additional Add-Ons',
            //     component: (
            //         <AdditionalAddOns
            //             csrf_token={this.props.csrf_token}
            //             handleNext={() => this.handleNext(9)}
            //             handleBack={() => this.handleBack(9)}
            //         />
            //     ),
            // },
            {
                id: 10,
                name: 'Billing Settings (Optional): Add Tax ID or VAT',
                component: (
                    <AddTaxIdOrVat
                        csrf_token={this.props.csrf_token}
                        tax_id_types={this.props.tax_id_types}
                        handleNext={() => this.handleNext(10)}
                        handleBack={() => this.handleBack(10)}
                        profile={this.profile}
                        finishSteps={this.finishSteps}
                    />
                ),
            },
        ];

        return (
            <div className="row">
                <div className="card table-main rounded-main">
                    <div className="card-header" style={{ borderRadius: '10px' }}>
                        <div className="card-title text-dark fw-bold">
                            Welcome to GDdash Account!
                        </div>
                    </div>
                    <div className="card-body">
                        We’re excited to have you on board! Let’s get started by setting up your
                        account. This quick setup will ensure you unlock the full potential of our
                        tool and customize it to your needs. Follow the steps below to complete your
                        setup. Most steps are quick and easy, and we’ve marked optional ones so you
                        can skip them if they’re not relevant right now.
                    </div>
                </div>
                {this.state.isFinished && (
                    <div className="onboarding-all-done-box">
                        <div className="card table-main rounded-main">
                            <div className="card-header" style={{ borderRadius: '10px' }}>
                                <div className="card-title text-dark fw-bold">
                                    <strong>&#128077; You're all set!</strong>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    We're analyzing your Google Discover data from the past 30 days
                                    and will update daily after that. We'll also crawl your site and
                                    analyze your content.You reports will be ready in 2-3 days.
                                    Check back soon for your personalized insights! We will  once
                                    you data is in and fully analyzed.For the time being you can
                                    start with your{' '}
                                    <a href="/tools/google-discover-data/">
                                        Google Discover Tracking report
                                    </a>
                                    , it should be available in 30 minutes.
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div id="onboarding" style={{ opacity: this.state.isFinished ? '0.3' : '1' }}>
                    {this.data?.map((item, i) => {
                        return (
                            <div
                                className="card rounded-main"
                                style={{ opacity: this.isDisabled(item.id) ? '0.3' : '1' }}
                                key={item.id}
                            >
                                <div className="card-body">
                                    <div className="onboarding">
                                        <div className="header-button-container">
                                            <button
                                                id={`onboard-step${i}`}
                                                className={`collapsed header-buttons panel-heading ${this.showOpened(
                                                    item.id,
                                                )}`}
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#onboard-step${i}Content`}
                                                aria-expanded="false"
                                                aria-controls={`onboard-step${i}Content`}
                                                disabled={
                                                    this.state.isFinished ||
                                                    this.isDisabled(item.id)
                                                }
                                                onClick={() => this.onClickOnStep(item.id)}
                                            >
                                                <span className="collaps-btn">
                                                    {i + 1}. {item?.name}
                                                </span>
                                            </button>
                                        </div>
                                        <div
                                            id={`onboard-step${i}Content`}
                                            className={`collapse panel-collapse pl-20 ${this.showOpened(
                                                item.id,
                                                'show',
                                            )}`}
                                            aria-labelledby={`headingStep${i}`}
                                            data-bs-parent="#onboarding"
                                        >
                                            {item.component ?? 'In Process'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default Onboarding;
