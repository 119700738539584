import React, { useState } from 'react';
import ReactModal from '../../components/Modal';
import DataBackFill from '../DataBackfill';
import Loading from '../../components/Loading';
import DomainRefreshModal from '../DomainRefreshModal';

const DomainsData = ({ isOpen, onClose, domains, base_url, csrf_token }) => {
    const [openRefreshModal, setOpenRefreshModal] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [refreshIconType, setRefreshIconType] = useState({});
    const [domainData, setDomainData] = useState({});
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});
    const [dates, setDates] = useState({});

    const showRefreshIcon = (type) => {
        switch (type) {
            case 'success':
                return <i className="icon-check-icon"></i>;
            case 'fail':
                return <i className="icon-close" style={{ color: 'red' }}></i>;
            default:
                return <i className="icon-refresh"></i>;
        }
    };
    const findMean = (data, domain_id) => {
        const last_30 = data.slice(-30);
        const sum = last_30.reduce(
            (acc, curr) => {
                acc.page_collected += curr.pages;
                acc.page_processed += curr.scraped;

                return acc;
            },
            { page_processed: 0, page_collected: 0 },
        );

        const page_processed_data = Math.round(sum.page_processed / last_30.length);
        const page_collected_data = Math.round(sum.page_collected / last_30.length);

        setDates((prev) => ({
            ...prev,
            [domain_id]: { ...prev[domain_id], page_processed_data, page_collected_data },
        }));
    };

    const findLastCollected = (data, type) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i][type] > 0) {
                return data[i].date.value;
            }
        }
    };

    const getDate = (date) => {
        if (!date) {
            return '';
        }
        const newDate = new Date(date).toString();
        const value = newDate.split(' ').slice(1, 4).join(' ');

        return value;
    };

    const findDates = (data, domain_id) => {
        if (!data || data?.length === 0) return;
        const sortedDate = [...data].sort((a, b) => {
            return new Date(b.date.value) - new Date(a.date.value);
        });

        const lastDataCollected = findLastCollected(sortedDate, 'scraped');
        const lastPagesCollected = findLastCollected(sortedDate, 'pages');

        setDates((prev) => ({
            ...prev,
            [domain_id]: {
                ...prev[domain_id],
                lastDataCollected: getDate(lastDataCollected),
                lastPagesCollected: getDate(lastPagesCollected),
            },
        }));
    };

    const onOpenModal = (domain_id) => {
        setSelectedDomain(domain_id);
        setOpenRefreshModal(true);
    };

    const onCloseModal = () => {
        setSelectedDomain(null);
        setOpenRefreshModal(false);
    };

    const getDomainData = (data, domain_id) => {
        const sorted = data.sort((a, b) => new Date(a.date.value) - new Date(b.date.value));
        setDomainData((prev) => ({ ...prev, [domain_id]: sorted }));
    };

    const changeState = (domain_id, bool, refreshIconType, message) => {
        setLoading((prev) => ({ ...prev, [domain_id]: bool }));
        if (refreshIconType)
            setRefreshIconType((prev) => ({ ...prev, [domain_id]: refreshIconType }));
        if (message) setMessage((prev) => ({ ...prev, [domain_id]: message }));
    };

    return (
        <>
            <ReactModal modalState={isOpen} className="refresh-domain-modal width-md">
                <table className="table table-striped table-sortable">
                    <thead>
                        <tr>
                            <th style={{ verticalAlign: 'middle' }}>Domain</th>
                            <th className="tb-center">Last Data Collected</th>
                            <th className="tb-center">Last Data Scraped</th>
                            <th className="tb-center">Pages Collected</th>
                            <th className="tb-center">Pages Scraped</th>
                        </tr>
                    </thead>
                    <tbody>
                        {domains.map((domain) => (
                            <tr key={domain.id}>
                                <td>
                                    <div className="domain-name">
                                        <div>
                                            <small>
                                                {domain.active ? (
                                                    <i className="text-success icofont-verification-check"></i>
                                                ) : (
                                                    <i className=" text-danger icofont-ui-close"></i>
                                                )}
                                            </small>
                                            <a href={`/domains/${domain.id}`}>{domain.name}</a>
                                        </div>
                                        {loading[domain.id] ? (
                                            <Loading onlyPreloader />
                                        ) : (
                                            <button
                                                className="btn-icon"
                                                onClick={() => onOpenModal(domain.id)}
                                                title={message[domain.id]}
                                                // disabled={!domainData[domain.id]?.length}
                                            >
                                                {showRefreshIcon(refreshIconType[domain.id])}
                                            </button>
                                        )}
                                    </div>
                                    <small style={{ color: '#999' }}>{domain.dataEmail}</small>
                                    <DataBackFill
                                        domain_url={domain.url}
                                        domain_id={domain.id}
                                        findDates={findDates}
                                        findMean={findMean}
                                        getDomainData={getDomainData}
                                        existingData={domainData[domain.id]}
                                    />
                                </td>
                                <td className="tb-center">
                                    {dates[domain.id]?.lastDataCollected ?? '-'}
                                </td>
                                <td className="tb-center">
                                    {dates[domain.id]?.lastPagesCollected ?? '-'}
                                </td>
                                <td className="tb-center">
                                    {dates[domain.id]?.page_collected_data ?? '-'}
                                </td>
                                <td className="tb-center">
                                    {dates[domain.id]?.page_processed_data ?? '-'}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="btn-wrapper">
                    <button
                        type="button"
                        className="button button-outline button-primary width-sm btn"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                </div>
            </ReactModal>
            {selectedDomain && (
                <DomainRefreshModal
                    isOpen={openRefreshModal}
                    onClose={onCloseModal}
                    domain_id={selectedDomain}
                    base_url={base_url}
                    csrf_token={csrf_token}
                    changeState={changeState}
                    domainData={domainData[selectedDomain]}
                />
            )}
        </>
    );
};

export default DomainsData;
