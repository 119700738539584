import Query from '../../../components/Report';

export const query = ({ offset = 0, best, ignored_keywords = [], search }) => {
    return new Query('search-evergreen-best', {
        offset,
        best: collectKeywords(best),
        ignored_keywords: collectIgnoredKeywords(ignored_keywords),
        search: searchKeywords(search),
    });
};

export const setBestTranslationQuery = async (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/add-domain-gsc-data/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const setIgnoreKeywords = async (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/ignored-keyword-url/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

const collectIgnoredKeywords = (ignored_keywords) => {
    if (ignored_keywords?.length === 0) return '';
    const keywords = ignored_keywords.map((keyword) => `and keyword !='${keyword}' `).join('');
    return keywords;
};

const collectKeywords = (keywords) => {
    if (keywords?.length === 0) return '';
    const keywordsForQuery = keywords
        .map(
            (keyword) =>
                `keyword like '${keyword} %' or keyword like '% ${keyword} %' or keyword like '% ${keyword}'`,
        )
        .join(' or ');
    return `(${keywordsForQuery})`;
};

function searchKeywords(search) {
    if (!search) return '';
    return `and (keyword like '% ${search}' OR keyword like '${search} %' OR keyword like '% ${search} %')`;
}
