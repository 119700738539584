const query = ({ product_id, promotion_code, updateState, changeLoading, endpoint_url }) => {
    fetch(`${endpoint_url}?product_id=${product_id}&promotion_code=${promotion_code}`)
        .then((res) => res.json())
        .then((res) => {
            updateState(res);
            changeLoading(false);
        })
        .catch((err) => {
            console.log(err);
            changeLoading(false);
        });
};

export default query;
