import Query from '../../components/Report';

export default new Query('discover-domains-top-entities', {
    filtersMap: (f) => {
        if (f && f.key == 'page') {
            f.key = 'pages';
            f.cmp = 'in';
        }

        return f;
    },
});
