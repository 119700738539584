import React from 'react';
import { v4 as uuid } from 'uuid';

class LoadMoreComponent extends React.Component {
    constructor() {
        super();

        this.loadMore = this.loadMore.bind(this);
        this.forceRun = this.forceRun.bind(this);

        this.state = {
            loading: true,
            loadMore: false,
            offset: 0,
            allData: [],
            isShowMore: false,
            filters: localStorage.getItem('filters'),
            status: 'all',
        };
    }

    async componentDidMount() {
        if (this.query) {
            this.query.bind(this.setState.bind(this));
            await this.query.update();
        }
    }

    async loadMore(attr) {
        const { query, offsetStep } = attr;
        const oldOffset = this.state.offset ?? 0;
        const newOffset = oldOffset + offsetStep;
        this.setState({
            loadMore: true,
        });
        this.query = query({ offset: newOffset, ...attr });
        this.query.bind(this.setState.bind(this));
        this.setState({
            offset: newOffset,
        });

        await this.query.update();
        this.setState({ loadMore: false });
    }

    componentDidUpdate(prevProps, prevState) {
        const filters = localStorage.getItem('filters');
        const data = this.state.data?.map((x) => ({ id: uuid(), ...x }));
        if (prevState.filters !== filters && !this.state.loading) {
            return this.setState({ allData: data, filters });
        }
        if (
            JSON.stringify(this.state.data) !== JSON.stringify(prevState.data) &&
            !this.state.loading
        ) {
            this.setState({ allData: [...this.state.allData, ...data] });
        }
    }

    forceRun() {
        if (this.query) {
            this.setState({
                allData: [],
                data: [],
            });

            this.query.bind(this.setState.bind(this));
            this.query.update({ force: true, alreadyInQueue: true });
        }
    }
}

export default LoadMoreComponent;
