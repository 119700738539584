import moment from 'moment';

const collectFullData = (week, year) => {
    const twoDigitWeek = week < 10 ? `0${week}` : week;
    return `${year}-${twoDigitWeek}`;
};

const getLastWeek = (week, year) =>
    week === 1 ? { week: 53, year: year - 1 } : { week: week - 1, year };

export const getWeeks = () => {
    const year = moment().year();
    const week = moment().week();

    const { week: last_week, year: last_year } = getLastWeek(week, year);
    const { week: prior_week, year: prior_year } = getLastWeek(last_week, last_year);

    return {
        prior_week: collectFullData(prior_week, prior_year),
        last_week: collectFullData(last_week, last_year),
    };
};
