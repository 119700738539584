/* eslint-disable */

import numeral from 'numeral';
import { Cell } from '@table-library/react-table-library/table';
import Sparkline from '../../../../../../components/Sparklines';

const color = '#f7f7f7';

function createLink(path) {
    const url = new URL(window.location);
    const intent = url.searchParams.get('intent');

    if (intent) path += `&intent=${intent}`;
    return path;
}

export const COLUMNS = (
    project_id,
    own_domain,
    competitors,
    keyword,
    maxScore,
    minScore,
    midScore,
) => {
    const columns = [
        {
            label: 'Cluster name',
            key: 'cluster_name',
            data: 'cluster_name',
            sortType: 'string',
            Component: Cell,
            renderCell: ({ item, csvDownload }) => {
                if (csvDownload) return item.cluster_name;

                if(!item.cluster_name) return '-'

                return (
                    <strong className="wripe">
                        {' '}
                        <a
                            href={createLink(
                                `/commerce/${project_id}/cluster/` +
                                    encodeURIComponent(item.cluster_name) +
                                    `?own_domain=${own_domain}&keyword=${keyword}`,
                            )}
                        >
                            <span>{item.cluster_name}</span>{' '}
                            <span style={{ fontSize: '30px' }}>→</span>
                        </a>
                    </strong>
                );
            },
        },
        {
            label: 'Opportunity Score',
            key: 'score',
            data: 'score',
            sortType: (array) =>
                array.sort(
                    (a, b) =>
                        getNormalizedScore(a.score, maxScore, minScore, midScore) -
                        getNormalizedScore(b.score, maxScore, minScore, midScore),
                ),
            Component: Cell,
            renderCell: ({ item, csvDownload }) => {
                const value = getNormalizedScore(item.score, maxScore, minScore, midScore);
                if (csvDownload) return value;
                return (
                    <div className="col align-center">
                        <strong>{numeral(value).format('0.00a')}</strong>
                    </div>
                );
            },
        },
        {
            label: 'Keywords',
            key: 'keywords',
            data: 'keywords',
            Component: Cell,
            sortType: 'number',
            renderCell: ({ data, csvDownload }) => {
                if (csvDownload) return data;

                return (
                    <div className="col list-col align-left">
                        <strong>{numeral(data).format('0a')}</strong>
                    </div>
                );
            },
        },
        {
            label: 'Median CPC',
            key: 'median_cpc',
            data: 'median_cpc',
            sortType: 'number',
            Component: Cell,
            renderCell: ({ data, csvDownload }) => {
                if (csvDownload) return data;

                return (
                    <div className="col list-col align-left">
                        <strong>$ {numeral(data).format('0.0a')}</strong>
                    </div>
                );
            },
        },
        {
            label: 'Median Keyword Difficulty',
            key: 'median_keyword_difficulty',
            data: 'median_keyword_difficulty',
            sortType: 'number',
            Component: Cell,
            renderCell: ({ data, csvDownload }) => {
                if (csvDownload) return data;

                return (
                    <div className="col list-col align-left">
                        <strong>{numeral(data).format('0.0a')}</strong>
                    </div>
                );
            },
        },
        {
            label: 'Total sv',
            key: 'total_sv',
            data: 'total_sv',
            sortType: 'number',
            Component: Cell,
            renderCell: ({ data, csvDownload }) => {
                if (csvDownload) return data;

                return (
                    <div className="col align-center">
                        <strong>{numeral(data).format('0.0a')}</strong>
                    </div>
                );
            },
        },
        {
            label: 'Domains',
            key: 'domains',
            data: 'domains',
            sortType: 'number',
            Component: Cell,
            renderCell: ({ data, csvDownload }) => {
                if (csvDownload) return data;

                return (
                    <div className="col list-col align-left">
                        <strong>{numeral(data).format('0a')}</strong>
                    </div>
                );
            },
        },
        {
            label: 'Monthly Trends',
            key: 'trends',
            data: 'trends',
            Component: Cell,
            renderCell: ({ data, csvDownload }) => {
                if (csvDownload) return data;

                return <Sparkline dataL={data} keyName="Trends" dontShowLable={true} />;
            },
        },
        {
            label: 'URLS',
            key: 'own_urls',
            data: 'own_urls',
            sortType: 'number',
            Component: Cell,
            style: () => {
                return { backgroundColor: color };
            },
            renderCell: ({ data, csvDownload }) => {
                if (csvDownload) return data;

                return (
                    <div className="col list-col align-left">
                        <strong>{numeral(data).format('0a')}</strong>
                    </div>
                );
            },
        },
        {
            label: 'SV',
            key: 'own_sv',
            data: 'own_sv',
            sortType: 'number',
            style: () => {
                return { backgroundColor: color };
            },
            cellSize: 'minmax(150px, 1.5fr)',
            Component: Cell,
            renderCell: ({ data, csvDownload }) => {
                if (csvDownload) return data;

                return (
                    <div className="col list-col align-left">
                        <strong>{numeral(data).format('0.0a')}</strong>
                    </div>
                );
            },
        },
        {
            label: 'Position',
            key: 'own_position',
            data: 'own_position',
            sortType: 'number',
            Component: Cell,
            style: () => {
                return { backgroundColor: color };
            },
            renderCell: ({ data, csvDownload }) => {
                if (csvDownload) return data;

                return (
                    <div className="col list-col align-left">
                        <strong>{numeral(data).format('0.0a')}</strong>
                    </div>
                );
            },
        },
        {
            label: 'KWS',
            key: 'own_kws',
            data: 'own_kws',
            sortType: 'number',
            Component: Cell,
            style: () => {
                return { backgroundColor: color };
            },
            renderCell: ({ data, csvDownload }) => {
                if (csvDownload) return data;

                return (
                    <div className="col list-col align-left">
                        <strong>{numeral(data).format('0.0a')}</strong>
                    </div>
                );
            },
        },
    ];
    const competitionKeys = competitors?.flatMap((_, i) => [
        {
            label: `URLS`,
            key: `comp_${i}_urls`,
            data: `comp_${i}_urls`,
            sortType: 'number',
            Component: Cell,
            style: () => {
                return { backgroundColor: i % 2 !== 0 ? color : 'unset' };
            },
            renderCell: ({ data, csvDownload }) => {
                if (csvDownload) return data;

                return (
                    <div className="col list-col align-left">
                        <strong>{numeral(data).format('0a')}</strong>
                    </div>
                );
            },
        },
        {
            label: `SV`,
            key: `comp_${i}_sv`,
            data: `comp_${i}_sv`,
            cellSize: 'minmax(150px, 1.5fr)',
            style: () => {
                return { backgroundColor: i % 2 !== 0 ? color : 'unset' };
            },
            sortType: 'number',
            Component: Cell,
            renderCell: ({ data, csvDownload }) => {
                if (csvDownload) return data;

                return (
                    <div className="col list-col align-left">
                        <strong>{numeral(data).format('0.0a')}</strong>
                    </div>
                );
            },
        },
        {
            label: `POSITION`,
            key: `comp_${i}_position`,
            data: `comp_${i}_position`,
            style: () => {
                return { backgroundColor: i % 2 !== 0 ? color : 'unset' };
            },
            sortType: 'number',
            Component: Cell,
            renderCell: ({ data, csvDownload }) => {
                if (csvDownload) return data;

                return (
                    <div className="col list-col align-left">
                        <strong>{numeral(data).format('0.0a')}</strong>
                    </div>
                );
            },
        },
        {
            label: `KWS`,
            key: `comp_${i}_kws`,
            data: `comp_${i}_kws`,
            sortType: 'number',
            Component: Cell,
            style: () => {
                return { backgroundColor: i % 2 !== 0 ? color : 'unset' };
            },
            renderCell: ({ data, csvDownload }) => {
                if (csvDownload) return data;

                return (
                    <div className="col list-col align-left">
                        <strong>{numeral(data).format('0a')}</strong>
                    </div>
                );
            },
        },
    ]);
    if (competitionKeys) columns.push(...competitionKeys);
    return columns;
};
export const EXTRA_HEADER = (own_domain, competitors) => {
    const columns = [
        {
            label: '',
            key: 'cluster_name',
        },
        {
            label: '',
            key: 'score',
        },
        {
            label: '',
            key: 'keywords',
        },
        {
            label: '',
            key: 'median_cpc',
        },

        {
            label: '',
            key: 'median_keyword_difficulty',
        },
        {
            label: '',
            key: 'total_sv',
        },
        {
            label: '',
            key: 'domains',
        },
        {
            label: '',
            key: 'trends',
        },
        {
            label: '',
            key: 'own_urls',
            style: () => {
                return { backgroundColor: color };
            },
        },
        {
            label: own_domain,
            key: 'own_sv',
            style: () => {
                return { backgroundColor: color };
            },
        },
        {
            label: '',
            key: 'own_position',
            style: () => {
                return { backgroundColor: color };
            },
        },
        {
            label: '',
            key: 'own_kws',
            style: () => {
                return { backgroundColor: color };
            },
        },
    ];

    const competitionKeys = competitors?.flatMap((item, i) => [
        {
            label: '',
            key: `comp_${i}_urls`,
            style: () => {
                return { backgroundColor: i % 2 !== 0 ? color : 'unset' };
            },
        },
        {
            label: item,
            key: `comp_${i}_sv`,
            style: () => {
                return { backgroundColor: i % 2 !== 0 ? color : 'unset' };
            },
        },
        {
            label: '',
            key: `comp_${i}_position`,
            style: () => {
                return { backgroundColor: i % 2 !== 0 ? color : 'unset' };
            },
        },
        {
            label: '',
            key: `comp_${i}_kws`,
            style: () => {
                return { backgroundColor: i % 2 !== 0 ? color : 'unset' };
            },
        },
    ]);

    if (competitionKeys) columns.push(...competitionKeys);

    return columns;
};

function getNormalizedScore(score, maxScore, minScore, midScore) {
    const normalizedScore = 50 + (50 * (score - midScore)) / (maxScore - minScore);
    return normalizedScore;
}

export function getScore(item) {
    const opportunity_score =
        Math.log10(item.total_sv) * 8 +
        item.median_cpc * 20 +
        (100 - item.median_competition * 50) +
        (100 - item.median_keyword_difficulty * 15) +
        (item.own_urls > 0 ? 100 : 0) +
        (item.own_sv > 0 ? Math.log10(item.own_sv) * 15 : 0) +
        (item.own_position > 0 ? (10 - Math.min(item.own_position, 10)) * 10 : 0) +
        item.keywords * 5;

    return opportunity_score;
}
