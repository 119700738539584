import Query from '../../components/Report';

export const query = ({ entity_ids, country, language, base_domain, offset = 0, limit = 100 }) =>
    base_domain
        ? new Query(
              'discover-entity-tracking-top-domains-single-domain',
              {
                  entity_id: `entity_id in (${entity_ids.split(',').map((item) => `'${item}'`)})`,
                  country,
                  language,
                  base_domain,
                  offset,
                  limit,
              },
              { get_tools_dates: true },
          )
        : new Query(
              'discover-entity-tracking-top-domains',
              {
                  entity_id: `entity_id in (${entity_ids.split(',').map((item) => `'${item}'`)})`,
                  country,
                  language,
                  offset,
                  limit,
              },
              { get_tools_dates: true },
          );
