import { Component } from 'react';
import { STATUSES } from './keys';

class ExtraButtons extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className="categories-view no-margin">
                <div className="view-btns">
                    <button
                        className={`categories-view-btn ${
                            (!this.props.view || this.props.view === 1) &&
                            'categories-view-btn-active'
                        }`}
                        onClick={() => this.props.onChange(1)}
                    >
                        View 1
                    </button>
                    <button
                        className={`categories-view-btn ${
                            this.props.view === 2 && 'categories-view-btn-active'
                        }`}
                        onClick={() => this.props.onChange(2)}
                    >
                        View 2
                    </button>
                </div>
                {this.props.view === 2 &&
                    STATUSES.map((item) => {
                        return (
                            <button
                                className={`categories-view-btn ${
                                    this.props.status === item.value && 'categories-view-btn-active'
                                }`}
                                key={item.value}
                                onClick={() => this.props.getData(item.value)}
                                disabled={this.props.status === item.value}
                            >
                                {item.label}
                            </button>
                        );
                    })}
            </div>
        );
    }
}

export default ExtraButtons;
