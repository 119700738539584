/* eslint-disable */
import { Cell } from '@table-library/react-table-library/table';
import moment from 'moment';

export const COLUMNS = (onOpenModal) => [
    {
        label: 'URL',
        key: 'url',
        data: 'url',
        sortType: 'string',
        cellSize: 'minmax(300px, 3fr)',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="d-flex justify-content-between">
                    <strong style={{ width: '80%' }}>
                        <a target="_blank" rel="noreferrer" href={`/domains/${item.domain_id}/`}>
                            {data}
                        </a>
                    </strong>
                    <button
                        className="btn-icon"
                        style={{ width: '15%' }}
                        onClick={() => onOpenModal(item.domain_id, item.note)}
                    >
                        <i
                            className="icofont-edit"
                            style={{ color: item.note ? 'green' : 'black' }}
                        ></i>
                    </button>
                </div>
            );
        },
    },
    {
        label: 'Entity',
        key: 'Entity',
        data: 'Entity',
        Component: Cell,
        sortType: (array) => array.sort((a, b) => (a.Entity ?? 0) - (b.Entity ?? 0)),
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return !!data;

            return !data ? (
                <i className="text-success icofont-verification-check"></i>
            ) : (
                <i className=" text-danger icofont-ui-close"></i>
            );
        },
    },
    {
        label: 'Category',
        key: 'Category',
        data: 'Category',
        sortType: (array) => array.sort((a, b) => (a.Category ?? 0) - (b.Category ?? 0)),
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return !!data;

            return !data ? (
                <i className="text-success icofont-verification-check"></i>
            ) : (
                <i className=" text-danger icofont-ui-close"></i>
            );
        },
    },
    {
        label: 'NLP Category',
        key: 'nlp_category',
        data: 'nlp_category',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return data || 'google';
        },
    },
    {
        label: 'NLP Entity',
        key: 'nlp_entity',
        data: 'nlp_entity',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return data || 'google';
        },
    },
    {
        label: 'Main User',
        key: 'main_user',
        data: 'main_user',
        cellSize: 'minmax(300px, 3fr)',
        Component: Cell,
    },
    {
        label: 'Created',
        key: 'created',
        data: 'created',
        sortType: (array) =>
            array.sort(
                (a, b) =>
                    new Date(a.created === 'None' ? 0 : a.created) -
                    new Date(b.created === 'None' ? 0 : b.created),
            ),
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (data === 'None') return '-';
            if (csvDownload) return data;

            return moment(data).format('YYYY-MM-DD');
        },
    },
    {
        label: 'Reactivated at',
        key: 'reactivated_at',
        data: 'reactivated_at',
        sortType: (array) =>
            array.sort(
                (a, b) =>
                    new Date(a.reactivated_at === 'None' ? 0 : a.reactivated_at) -
                    new Date(b.reactivated_at === 'None' ? 0 : b.reactivated_at),
            ),
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (data === 'None') return '-';
            if (csvDownload) return data;

            return moment(data).format('YYYY-MM-DD');
        },
    },
    {
        label: 'Deactivated at',
        key: 'deactivated_at',
        data: 'deactivated_at',
        Component: Cell,
        sortType: (array) =>
            array.sort(
                (a, b) =>
                    new Date(a.deactivated_at === 'None' ? 0 : a.deactivated_at) -
                    new Date(b.deactivated_at === 'None' ? 0 : b.deactivated_at),
            ),
        renderCell: ({ data, csvDownload }) => {
            if (data === 'None') return '-';
            if (csvDownload) return data;

            return moment(data).format('YYYY-MM-DD');
        },
    },
    {
        label: 'Actions',
        key: 'actions',
        data: 'actions',
        sortType: 'date',
        cellSize: 'minmax(400px, 3fr)',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return '-';

            return (
                <div className="d-flex align-items-center">
                    <a
                        className="table-action-btn mr-10"
                        href={`/customers/list/?customer_type=active_customers&filter=${item.url}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Customer info
                    </a>
                    <a
                        className="table-action-btn mr-10"
                        href={`/halubcnd/docs/log/?q=${item.url}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Logs
                    </a>
                    <a
                        className="table-action-btn mr-10"
                        href={
                            item.url_settings_id
                                ? `/halubcnd/domains/urlsetting/${item.url_settings_id}/change/`
                                : `/halubcnd/domains/urlsetting/add/?selected_domain=${item.url}`
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        Add alternate entities
                    </a>
                </div>
            );
        },
    },
];
