import React from 'react';
import Modal from 'react-modal';

export default function ReactModal({ className, modalState, children }) {
    return (
        <Modal
            overlayClassName="react-modal-overlay"
            className={`react-modal ${className ? className : ''}`}
            isOpen={modalState}
            ariaHideApp={false}
        >
            {children}
        </Modal>
    );
}
