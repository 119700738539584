import Component from '../../components/Component';
import Loading from '../../components/Loading';
import DataTable from '../../components/Datatable';
import { v4 as uuid } from 'uuid';
import { COLUMNS, getStrength } from './keys';
import numeral from 'numeral';
import { scrollForTable } from '../../components/Datatable/utils';

function findParts(acc, curr, ids) {
    const parts = curr.path.split('/');
    if (parts.length > 1) {
        const parent = parts.shift();
        return findParts(
            acc.nodes.find((item) => item.name === parent),
            { ...curr, path: parts.join('/') },
            ids,
        );
    } else {
        const id = uuid();
        ids.push(id);
        if (acc.nodes) {
            acc.nodes.push({ ...curr, name: parts[0], id });
        } else {
            acc.nodes = [{ ...curr, name: parts[0], id }];
        }
    }
    return acc;
}

function createTree(data) {
    if (!data || data?.length === 0) return { nodes: [], ids: [] };
    const ids = [];
    const collectedData = data.reduce((acc, curr) => {
        const levelParts = curr.level.slice(0, -1).split('/');
        levelParts.shift();
        if (levelParts.length === 1) {
            const id = uuid();
            ids.push(id);
            acc.push({ ...curr, name: levelParts[0], id });
        } else if (levelParts.length >= 2) {
            const parent = levelParts.shift();

            findParts(
                acc.find((item) => item.name === parent),
                { ...curr, path: levelParts.join('/') },
                ids,
            );
        }
        return acc;
    }, []);
    const allData = [
        {
            name: 'All Categories',
            sum_impressions: null,
            sum_clicks: null,
            avg_ctr: null,
            unique_pages: null,
            nodes: collectedData.map((item) => ({ ...item, haveStrength: true })),
            id: 'all_categories',
        },
    ];
    return { nodes: allData, ids };
}

function getNestedView(data, length) {
    if (!data || data.length === 0) return [];
    const collectedView = data
        .filter((item) => item.level.split('/').length === length)
        .map((item) => ({ ...item, name: `all${item.level}`, id: uuid(), haveStrength: true }));

    return collectedView;
}

class Table extends Component {
    constructor(props) {
        super();
        this.checkIsEmptyAll = this.checkIsEmptyAll.bind(this);
        this.chooseView = this.chooseView.bind(this);
        this.getViewData = this.getViewData.bind(this);
        this.onToggle = this.onToggle.bind(this);

        this.images = {
            strong: props.image_strong,
            striking_distance: props.image_striking_distance,
            weak: props.image_weak,
        };
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            const { nodes, ids } = createTree(this.props.data);
            this.setState({
                nodes,
                ids,
            });
        }
    }
    checkIsEmptyAll(list) {
        for (let element of list) {
            if (!element.classList.contains('hide-component')) return false;
        }
        return true;
    }

    getAverages(data) {
        const length = data?.length ?? 1;
        const sumAll = data?.reduce((acc, curr, i) => {
            if (i === 0) {
                acc = {
                    sumPages: curr.unique_pages,
                    sumImpressions: curr.sum_impressions,
                    sumClicks: curr.sum_clicks,
                    sumCTR: curr.avg_ctr,
                };
            } else {
                acc = {
                    sumPages: acc.sumPages + curr.unique_pages,
                    sumImpressions: acc.sumImpressions + curr.sum_impressions,
                    sumClicks: acc.sumClicks + curr.sum_clicks,
                    sumCTR: acc.sumCTR + curr.avg_ctr,
                };
            }
            return acc;
        }, {});
        const averages = {
            avgPages: sumAll.sumPages / length,
            avgImpressions: sumAll.sumImpressions / length,
            avgClicks: sumAll.sumClicks / length,
            avgCTR: sumAll.sumCTR / length,
        };
        return averages;
    }

    chooseView(view) {
        this.setState({ view });
    }

    getViewData(nodes) {
        switch (this.state.view) {
            case 2: {
                const data = getNestedView(this.props.data, 4);
                const { avgPages, avgImpressions, avgClicks, avgCTR } = this.getAverages(data);
                return {
                    nodes: data,
                    avgPages,
                    avgImpressions,
                    avgClicks,
                    avgCTR,
                    levelName: '2nd Level',
                };
            }
            case 3: {
                const data = getNestedView(this.props.data, 5);
                const { avgPages, avgImpressions, avgClicks, avgCTR } = this.getAverages(data);
                return {
                    nodes: data,
                    avgPages,
                    avgImpressions,
                    avgClicks,
                    avgCTR,
                    levelName: 'third level',
                };
            }
            default: {
                const nesteddData = nodes[0]?.nodes || [];
                const { avgPages, avgImpressions, avgClicks, avgCTR } =
                    this.getAverages(nesteddData);

                return {
                    nodes,
                    avgPages,
                    avgImpressions,
                    avgClicks,
                    avgCTR,
                    levelName: 'top level',
                };
            }
        }
    }

    onToggle(value, view) {
        if (value === 'all') {
            this.setState({
                sortedData: null,
            });
        } else if (value === 'striking_distance_2' || value === 'striking_distance_3') {
            const sortedData = this.props.data.filter(
                (item) => item.unique_pages >= 5 && item.unique_pages <= 9,
            );
            const data = getNestedView(sortedData, view + 2);
            const { avgPages, avgImpressions, avgClicks, avgCTR } = this.getAverages(data);
            this.setState({
                sortedData: {
                    nodes: data,
                    avgPages,
                    avgImpressions,
                    avgClicks,
                    avgCTR,
                    levelName: view === 2 ? '2nd Level' : 'third level',
                },
            });
        } else {
            const sortedData = this.props.data.filter((item) => item.unique_pages >= 10);
            const data = getNestedView(sortedData, view + 2);
            const { avgPages, avgImpressions, avgClicks, avgCTR } = this.getAverages(data);
            this.setState({
                sortedData: {
                    nodes: data,
                    avgPages,
                    avgImpressions,
                    avgClicks,
                    avgCTR,
                    levelName: view === 2 ? '2nd Level' : 'third level',
                },
            });
        }
        this.chooseView(view);

        this.setState({
            toggle: value,
            sortKey: 'median_impressions_per_page',
        });
    }

    render() {
        if (this.props.loading) {
            return <Loading padding={10} />;
        }

        const extraBtns = (hasTree, tree) => (
            <div className="categories-view">
                <button
                    className={`categories-view-btn ${
                        (!this.state.view || this.state.view === 1) && 'categories-view-btn-active'
                    }`}
                    onClick={() => this.chooseView(1)}
                >
                    <i className="icon-account-tree" style={{ marginRight: '3px' }}></i> Nested View
                </button>
                <button
                    className={`categories-view-btn ${
                        this.state.view === 2 && 'categories-view-btn-active'
                    }`}
                    onClick={() => this.chooseView(2)}
                >
                    <i className="icon-list" style={{ marginRight: '3px' }}></i> 2nd Level
                </button>
                <button
                    className={`categories-view-btn ${
                        this.state.view === 3 && 'categories-view-btn-active'
                    }`}
                    onClick={() => this.chooseView(3)}
                >
                    <i className="icon-list" style={{ marginRight: '3px' }}></i> 3rd Level
                </button>
                {hasTree && (
                    <button
                        type="button"
                        className="categories-view-btn"
                        onClick={() => {
                            // check if "all categories" is open or not
                            if (!tree.state.ids.includes('all_categories')) {
                                tree.fns.onToggleById('all_categories');
                            }

                            // minus 1 because ids doesn't include 'all_categoris'
                            // this one for closing all
                            if (tree.state.ids.length - 1 === this.state.ids?.length) {
                                return this.state.ids.forEach((id) => tree.fns.onToggleById(id));
                            }

                            //this one for opening all
                            this.state.ids?.forEach((id) => {
                                if (!tree.state.ids.includes(id)) {
                                    tree.fns.onToggleById(id);
                                }
                            });
                        }}
                    >
                        {tree.state.ids.length - 1 === this.state.ids?.length
                            ? 'Collaps All'
                            : 'Expand All'}
                    </button>
                )}
                <div className="toggle-Invalid-items">
                    <span>Only Show categories above 25% relevancy</span>
                    <span
                        className={`switch-button mr-10 ${
                            this.props.isConfidence ? 'active' : 'not-active'
                        }`}
                        onClick={this.props.toggle}
                        title="Delayed"
                    >
                        <span className="switch-circle" />
                    </span>
                </div>
            </div>
        );

        if (!this.state.nodes) return null;

        const { nodes, avgPages, avgImpressions, avgClicks, avgCTR } =
            this.state.sortedData || this.getViewData(this.state.nodes);
        const averages = { avgPages, avgImpressions, avgClicks, avgCTR };

        const sortFns = {
            category: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
            unique_pages: (array) =>
                array.sort((a, b) => {
                    return a.unique_pages - b.unique_pages;
                }),
            sum_impressions: (array) => array.sort((a, b) => a.sum_impressions - b.sum_impressions),
            sum_clicks: (array) => array.sort((a, b) => a.sum_clicks - b.sum_clicks),
            avg_ctr: (array) =>
                array.sort(
                    (a, b) => a.sum_clicks / a.sum_impressions - b.sum_clicks / b.sum_impressions,
                ),
            strength: (array) =>
                array.sort((a, b) => getStrength(a, averages) - getStrength(b, averages)),
            median_impressions_per_page: (array) =>
                array.sort((a, b) => a.median_impressions_per_page - b.median_impressions_per_page),
        };

        return (
            <>
                <div className="card table-main rounded-main overflow-hidden">
                    <div className="card-header">
                        <p className="card-title">Oppoprtunities</p>
                    </div>
                    <div className="card-body">
                        <div className="entity-toggle-container">
                            <div className="toggle-Invalid-items right-border">
                                <span>All</span>
                                <span
                                    className={`switch-button mr-10 ${
                                        !this.state.toggle || this.state.toggle === 'all'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => this.onToggle('all')}
                                >
                                    <span className="switch-circle" />
                                </span>
                            </div>
                            <div
                                className="toggle-Invalid-items right-border"
                                title="These are the 2nd level categories that have high median impression per article, continue writing about them"
                            >
                                <span>Strong Categories 2nd Level</span>
                                <span
                                    className={`switch-button mr-10 ${
                                        this.state.toggle === 'strong_categories_2'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => this.onToggle('strong_categories_2', 2)}
                                    // title="Displaying Top entities that have 10 pages or more ordered by 'Relative Authority'"
                                >
                                    <span className="switch-circle" />
                                </span>
                            </div>
                            <div
                                className="toggle-Invalid-items right-border"
                                title="These are the 2nd level categories that have high median impression per article but low number of articles, test writing about them"
                            >
                                <span>Striking Distance 2nd Level</span>
                                <span
                                    className={`switch-button mr-10 ${
                                        this.state.toggle === 'striking_distance_2'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => this.onToggle('striking_distance_2', 2)}
                                    // title="Displaying Top entities that have 10 pages or more ordered by 'No. of Pages'"
                                >
                                    <span className="switch-circle" />
                                </span>
                            </div>
                            <div
                                className="toggle-Invalid-items right-border"
                                title="These are the 3rd level categories that have high median impression per article, continue writing about them"
                            >
                                <span>Strong Categories 3nd Level</span>
                                <span
                                    className={`switch-button mr-10 ${
                                        this.state.toggle === 'strong_categories_3'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => this.onToggle('strong_categories_3', 3)}
                                    // title="Displaying Top entities that have 10 pages or more ordered by 'Relative Authority'"
                                >
                                    <span className="switch-circle" />
                                </span>
                            </div>
                            <div
                                className="toggle-Invalid-items right-border"
                                title="These are the 2nd level categories that have high median impression per article but low number of articles, test writing about them"
                            >
                                <span>Striking Distance 3nd Level</span>
                                <span
                                    className={`switch-button mr-10 ${
                                        this.state.toggle === 'striking_distance_3'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => this.onToggle('striking_distance_3', 3)}
                                    // title="Displaying Top entities that have 10 pages or more ordered by 'No. of Pages'"
                                >
                                    <span className="switch-circle" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="card table-main rounded-main overflow-hidden"
                    style={{ overflow: 'hidden' }}
                >
                    <div className="card-header">
                        <p className="card-title">Categories</p>
                        <small className="card-sub-title text-muted fs-7 py-2 categories-help-text">
                            Google organizes content into both Categories and Entities to align with
                            user interests before it appears in Google Discover. Categories, also
                            known as "Content Classifiers" or "Ontology," are standardized labels
                            that Google uses across its products—Search, Discover, Ads, Trends,
                            Shopping, and more—to categorize content. For simplicity, we refer to
                            these as "Categories." You can view the full list of categories{' '}
                            <a
                                className="text-muted"
                                target="_blank"
                                rel="noreferrer"
                                href="https://cloud.google.com/natural-language/docs/categories?hl=en"
                            >
                                here
                            </a>
                        </small>
                        <small className="d-block card-sub-title text-muted fs-7 py-2 text-capitalize">
                            Based On you average performance We Have Identified Your Top Strong,
                            Weak And Striking Distance Categories. For Your Top Level Categories,
                            Your Average Performance Is:
                            <div>
                                -{' '}
                                <span className="categories-averages">
                                    {numeral(avgPages).format('0.0a')}
                                </span>{' '}
                                Pages/Category
                            </div>
                            <div>
                                -{' '}
                                <span className="categories-averages">
                                    {numeral(avgCTR).format('0.000%')}
                                </span>{' '}
                                CTR
                            </div>
                            <div>
                                -{' '}
                                <span className="categories-averages">
                                    {numeral(avgImpressions).format('0.0a')}
                                </span>{' '}
                                Impressions/Category
                            </div>
                            <div>
                                -{' '}
                                <span className="categories-averages">
                                    {numeral(avgClicks).format('0.0a')}
                                </span>{' '}
                                Clicks/Category.
                            </div>
                        </small>
                    </div>
                    <div className="card-body">
                        <DataTable
                            nodes={nodes}
                            sortFns={sortFns}
                            openedItemIds={['all_categories']}
                            COLUMNS={COLUMNS(averages, this.images)}
                            fileName="Categories"
                            extraBtns={extraBtns}
                            hasTree={!this.state.view || this.state.view === 1}
                            searchWithTree={!this.state.view || this.state.view === 1}
                            forceRun={this.props.forceRun}
                            defaultSortKey={this.state.sortKey || 'sum_impressions'}
                            additionalStyles={{
                                Table: `
                                --data-table-library_grid-template-columns : minmax(400px, 3fr) minmax(120px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                ${scrollForTable}
                            `,
                                BaseCell: ` 
                                font-family: 'Raleway';
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 19px;
                                padding: 16px 5px !important;
                                color: #0D182C;
                                border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                            
                                &.underline {
                                    text-decoration: underline;
                                }
                            `,
                            }}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default Table;
