import { COLUMNS } from './keys';
import { query } from './query';
import DataTable from '../../../../components/Datatable';
import { getDifference } from '../../../../helpers/showDelta';
import { noDataMessage } from '../../../../helpers/noDataMessage';
import Loading from '../../../../components/Loading';
import LoadMoreComponent from '../../../../components/LoadMoreComponent';
import { v4 as uuid } from 'uuid';
import { scrollForTable } from '../../../../components/Datatable/utils';

class KeywordClusters extends LoadMoreComponent {
    constructor(props) {
        super();
        if (!props.data) this.query = query();

        this.offsetStep = 1000;
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) =>
            item.cluster_name?.toLowerCase().includes(search.toLowerCase()),
        );
    }

    componentDidUpdate(prevProps, prevState) {
        const filters = localStorage.getItem('filters');
        const data = this.state.data?.map((x) => ({ id: uuid(), ...x }));

        if (prevState.filters !== filters && !this.state.loading) {
            return this.setState({ allData: data, filters });
        }
        if (
            JSON.stringify(this.state.data) !== JSON.stringify(prevState.data) &&
            !this.state.loading
        ) {
            this.setState({ allData: [...this.state.allData, ...data] });
        }

        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDatas({ key: 'keywordClusters', value: this.state.data });
        }
    }

    render() {
        const data = this.state.allData?.length ? this.state.allData : this.props.data;
        if ((!data || !data.length) && this.state.loading) return <Loading />;
        const sortFns = {
            cluster_name: (array) =>
                array.sort((a, b) => a.cluster_name.localeCompare(b.cluster_name)),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            avgCTR: (array) => array.sort((a, b) => a.avgCTR - b.avgCTR),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            weeks: (array) => array.sort((a, b) => a.weeks - b.weeks),
            impressions_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.impressions, a.impressionsPrior) -
                        getDifference(b.impressions, b.impressionsPrior),
                ),
            clicks_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.clicks, a.clicksPrior) -
                        getDifference(b.clicks, b.clicksPrior),
                ),
            avgPosition_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.avgPosition, a.avgPositionPrior) -
                        getDifference(b.avgPosition, b.avgPositionPrior),
                ),
            avgCTR_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.avgCTR, a.avgCTRPrior) -
                        getDifference(b.avgCTR, b.avgCTRPrior),
                ),
        };

        return (!data || !data.length) && !this.state.filter ? (
            <div>
                {this.props.message ? (
                    <>
                        {noDataMessage}
                        <div className="redirect-button">
                            <a
                                className="bg-purple button-close"
                                href="/customers/change_plan/?without_basic=True"
                            >
                                Adjust Plan
                            </a>
                        </div>
                    </>
                ) : (
                    noDataMessage
                )}
            </div>
        ) : (
            <>
                <DataTable
                    nodes={data}
                    sortFns={sortFns}
                    COLUMNS={COLUMNS}
                    fileName="Keyword Clusters"
                    defaultSortKey="clicks"
                    searchFunction={this.searchFunction}
                    forceRun={this.forceRun}
                    errorMessage={this.props.message}
                    additionalStyles={{
                        Table: `
                                --data-table-library_grid-template-columns : minmax(120px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                               ${scrollForTable}
                            `,
                        BaseCell: ` font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                        text-decoration: underline;
                                        }`,
                    }}
                />
                <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                    <a
                        className="view-all-btn"
                        href={`/domains/${window.info.id}/gsc-reports/algo-comparison/?view=keyword_clusters&defaultFilter=decline`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        View Full Report
                    </a>
                </div>
            </>
        );
    }
}

export default KeywordClusters;
