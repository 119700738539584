import { icons } from '../modules/Commerce/CommerceView/icons/config';

export function showIntent(value) {
    const box = [];
    value.split(',').forEach((item) => {
        const number = item.trim();
        switch (number) {
            case '0':
                box.push(
                    <div className="intent intent-c" title="Commercial">
                        C
                    </div>,
                );
                break;
            case '1':
                box.push(
                    <div className="intent intent-i" title="Informational">
                        I
                    </div>,
                );
                break;
            case '2':
                box.push(
                    <div className="intent intent-n" title="Navigational">
                        N
                    </div>,
                );
                break;
            case '3':
                box.push(
                    <div className="intent intent-t" title="Transactional">
                        T
                    </div>,
                );
                break;
        }
    });
    return box;
}

export function showDifficulty(value) {
    if (value <= 25) {
        return <div className="all-data-dot green"></div>;
    }
    if (value <= 50) {
        return <div className="all-data-dot light-orange"></div>;
    }
    if (value <= 75) {
        return <div className="all-data-dot dark-orange"></div>;
    }
    return <div className="all-data-dot red"></div>;
}

export function showSerpFeatures(value) {
    const box = [];
    value.split(',').forEach((item) => {
        const component = icons[item?.trim()];
        box.push(
            <div title={component?.name} className="serp-features-icons">
                {component?.icon}
            </div>,
        );
    });
    return box;
}

export const changeIntent = (values) => {
    const url = new URL(window.location);
    if (values?.length) {
        url.searchParams.set('intent', values?.map((item) => item.value).join(','));
        history.pushState({}, '', url);
    } else {
        url.searchParams.delete('intent');
    }
};
