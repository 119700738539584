import { Component } from 'react';
import Loading from '../../components/Loading';
import Authors from '../Authors';

class AuthorsContainer extends Component {
    constructor() {
        super();

        this.state = {};
    }

    render() {
        const redirectButton = (
            <div className="redirect-button">
                {this.props.isLink ? (
                    <a className="bg-purple button-close" href={this.props.href}>
                        {this.props.buttonName}
                    </a>
                ) : (
                    <div>
                        <button
                            className="bg-purple button-close"
                            onClick={this.props.onClick}
                            disabled={this.props.loading}
                        >
                            {this.props.buttonName}
                        </button>
                        <div className="d-flex justify-content-center pt-2">
                            {this.props.loading && <Loading onlyPreloader />}
                        </div>
                    </div>
                )}
            </div>
        );
        return (
            <div id="authors-page">
                <div className="row card-row d-flex" id="authors-content">
                    <div className="col-12">
                        <Authors
                            content_id="authors-content"
                            parent_id="authors-page"
                            component="Authors"
                            domain={this.props.domain}
                            plan_type={this.props.plan_type}
                            message={this.props.message}
                            redirectButton={redirectButton}
                        />
                    </div>
                </div>
                {this.props.message && (
                    <div className="row card-row d-flex" id="authors-content">
                        <div className="col-12">
                            <div className="card table-main rounded-main overflow-hidden">
                                <div className="card-header">
                                    <p className="card-title">Author Topical Authority</p>
                                </div>
                                <div className="card-body">
                                    <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                                        {' '}
                                        <i className="icon-lightbulb-solid lightbulb-styles"></i>The
                                        Topical Authority report identifies the top Entities that
                                        perform the best for the Author. This could be a good
                                        practice when assigning stories to edit teams to write about
                                        their top entities to achieve better results.
                                    </small>
                                    <img
                                        src={this.props.module_2_img}
                                        alt="Author Topical Authority image"
                                        className="w-100"
                                        loading="lazy"
                                    />
                                </div>
                                {redirectButton}
                            </div>
                        </div>
                        <div className="row card-row d-flex" id="authors-content">
                            <div className="col-12">
                                <div className="card table-main rounded-main overflow-hidden">
                                    <div className="card-header">
                                        <p className="card-title">Detailed Author Performance</p>
                                    </div>
                                    <div className="card-body">
                                        <img
                                            src={this.props.module_3_img}
                                            alt="Detailed Author Performance image"
                                            className="w-100"
                                            loading="lazy"
                                        />
                                    </div>
                                    {redirectButton}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
export default AuthorsContainer;
