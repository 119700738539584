export const getInformation = () => {
    return fetch('/customers/get-additional-tires-price/');
};

export const purchaseQuery = (data, csrf_token) => {
    return fetch('/customers/purchase-additional/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
