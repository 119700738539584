import Component from '../../components/Component';
import Loading from '../../components/Loading';

import query from './query';
import { labelColors } from '../../lib/colors';

import React from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    BubbleController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    BubbleController,
);

/*
    Using ChartJs for show differnet type of charts
*/

class Summary extends Component {
    constructor() {
        super();
        this.query = query;
        this.handleMoodeChange = this.handleMoodeChange.bind(this);
        this.isDark = !!JSON.parse(localStorage.getItem('dark-mode'));

        document.addEventListener('changeMoodEvent', this.handleMoodeChange);
    }

    handleMoodeChange() {
        const isDark = JSON.parse(localStorage.getItem('dark-mode'));
        this.setState({ redraw: true, isDark });

        if (this.timeoutID) clearTimeout(this.timeoutID);

        this.timeoutID = setTimeout(() => {
            this.setState({ redraw: false });
        }, 100);
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        const sortedData = this.state.data.sort((a, b) => {
            const aStartedNumber = Number(a.words.split(' ')[0]);
            const bStartedNumber = Number(b.words.split(' ')[0]);

            return aStartedNumber - bStartedNumber;
        });

        const data = Object.values(
            sortedData.reduce((acc, curr) => {
                if (!curr.words) return acc;

                if (acc[curr.words]) {
                    acc[curr.words].impressions = acc[curr.words].impressions + curr.impressions;
                    acc[curr.words].clicks = acc[curr.words].clicks + curr.clicks;

                    return acc;
                }

                acc[curr.words] = {
                    impressions: curr.impressions,
                    clicks: curr.clicks,
                    words: curr.words,
                    pages: curr.pages,
                };

                return acc;
            }, {}),
        ).sort((a, b) => a.words - b.words);

        const chartData = {
            labels: data.map((item) => item.words),
            datasets: [
                {
                    type: 'line',
                    label: 'Impressions',
                    data: data.map((item) => item.impressions),
                    backgroundColor: labelColors[6],
                    borderColor: labelColors[6],
                    yAxisID: 'y',
                    borderRadius: 4,
                    tension: 0.4,
                },
                {
                    type: 'line',
                    label: 'Clicks',
                    data: data.map((item) => item.clicks),
                    backgroundColor: labelColors[5],
                    borderColor: labelColors[5],
                    yAxisID: 'y',
                    borderRadius: 4,
                    tension: 0.4,
                },
                {
                    type: 'bar',
                    label: 'Pages',
                    data: data.map((item) => item.pages),
                    backgroundColor: labelColors[1],
                    borderColor: labelColors[1],
                    yAxisID: 'y1',
                    borderRadius: 4,
                    tension: 0.4,
                },
            ],
        };

        const isDark = this.state.isDark ?? this.isDark;

        const keys = {
            maintainAspectRatio: false,
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: 'Words',
                    },
                    ticks: {
                        color: isDark ? '#959ca5' : '#777777',
                    },
                },
                y: {
                    type: 'logarithmic',

                    grid: {
                        display: false,
                    },
                    ticks: {
                        color: isDark ? '#959ca5' : '#777777',
                    },
                    title: {
                        display: true,
                        text: 'Impressions/Clicks',
                    },
                },
                y1: {
                    type: 'logarithmic',
                    display: true,
                    position: 'right',
                    grid: {
                        drawOnChartArea: false,
                    },
                    ticks: {
                        color: isDark ? '#959ca5' : '#777777',
                    },
                    title: {
                        display: true,
                        text: 'Pages',
                    },
                },
            },
            plugins: {
                legend: {
                    align: 'start',
                    labels: {
                        usePointStyle: true,
                        pointStyle: 'rect',
                    },
                },
            },
        };

        return (
            <div
                className="Timeline"
                style={{ height: '100%', minHeight: '150px', position: 'relative' }}
            >
                <h3 className="font-weight-bold">
                    How does the articles length impact the performances in Google Discover?
                </h3>
                <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                    Should we write longer articles?
                </small>
                <div
                    className="dt-exports d-flex justify-content-end w-100"
                    title="Refresh and clear cache"
                >
                    <button
                        className="dt-exports__button with-refresh"
                        type="button"
                        onClick={this.forceRun}
                    >
                        <i className="icon-refresh"></i>
                    </button>
                </div>
                {this.state.data[0] ? (
                    <div style={{ height: '400px', position: 'relative' }}>
                        <Chart data={chartData} options={keys} redraw={!!this.state.redraw} />
                    </div>
                ) : (
                    <div>There is no data</div>
                )}
            </div>
        );
    }
}
export default Summary;
