import Loading from '../../components/Loading';
import LoadMoreComponent from '../../components/LoadMoreComponent';

import { COLUMNS } from './keys';
import { query } from './query';
import DataTable from '../../components/Datatable';
import { noDataMessage } from '../../helpers/noDataMessage';
import { scrollForTable } from '../../components/Datatable/utils';

class LowCTR extends LoadMoreComponent {
    constructor(props) {
        super();
        this.query = query({ offset: 0, domain: props.domain, avgctr: props.avgctr });

        this.loadMore = this.loadMore.bind(this);
        this.showMore = this.showMore.bind(this);
        this.offsetStep = 100;
        this.defaultCount = 10;
    }

    showMore() {
        this.setState({
            isShowMore: true,
        });
    }

    searchFunction(data, search) {
        return data.filter((item) => {
            if (item.title) return item.title.toLowerCase().includes(search.toLowerCase());
            return item.page?.toLowerCase().includes(search.toLowerCase());
        });
    }

    render() {
        if (this.state.loading && !this.state.loadMore) {
            return <Loading padding={10} />;
        }

        const attr = {
            query,
            offsetStep: this.offsetStep,
            domain: this.props.domain,
            avgctr: this.props.avgctr,
        };

        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const sortFns = {
            title: (array) =>
                array.sort((a, b) => {
                    const a_title = a.title ?? a.page;
                    const b_title = b.title ?? b.page;
                    return a_title.localeCompare(b_title);
                }),
            ctr: (array) => array.sort((a, b) => a.ctr - b.ctr),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
        };
        return !this.state.allData.length ? (
            <div>{noDataMessage}</div>
        ) : (
            <>
                <DataTable
                    nodes={this.state.allData}
                    sortFns={sortFns}
                    COLUMNS={COLUMNS(this.props.default_image)}
                    fileName="Low CTR"
                    defaultSize={this.defaultCount}
                    searchFunction={this.searchFunction}
                    forceRun={this.forceRun}
                    defaultSortKey="ctr"
                    additionalStyles={{
                        Table: `
                                        --data-table-library_grid-template-columns : minmax(300px, 3fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                       ${scrollForTable}
                                    `,
                        BaseCell: `  font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                        text-decoration: underline;
                                        }`,
                    }}
                />
                {this.state.loadMore ? (
                    <Loading padding={5} />
                ) : (
                    showLoadMoreBtn && (
                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                            <button
                                onClick={() => this.loadMore(attr)}
                                disabled={this.state.loading}
                                className="load-more-btn"
                            >
                                Load More
                            </button>
                        </div>
                    )
                )}
            </>
        );
    }
}

export default LowCTR;
