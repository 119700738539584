/* eslint-disable */
import Query from '../../components/Report';

const query = ({ offset = 0, entity }) =>
    new Query('discover-domains-top-pages', {
        offset,
        entity: entity ? `AND '${entity?.replace(/'/g, "\\'")}' IN UNNEST(entities)` : '',
    });
export const getAllQuery = () => new Query('discover-domains-top-pages-all');

export default query;
