import numeral from 'numeral';
import { linReg } from '../../lib/reduce';

const keysFunction = (default_image) => [
    {
        key: (d) => {
            let c = linReg(d.impressionsL);
            return c.slope;
        },
        label: 'Impressions Δ',
        change: true,
        classes: 'col-2 align-center max-width-sm',
    },
    {
        key: (d) => {
            return d.title || d.page;
        },
        render: (d) => {
            let title = d.title;

            if (!d.title) {
                title = d.page;
            }

            return (
                <div className="col-6 list-col d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <div style={{ float: 'left', marginRight: '10px', width: '100px' }}>
                            <img
                                onError={(event) => (event.target.src = default_image)}
                                src={d.image ?? default_image}
                                style={{
                                    width: '100px',
                                    display: 'inline-block',
                                    minHeight: '60px',
                                    background: '#BBB',
                                }}
                                loading="lazy"
                            />
                        </div>
                        <strong>
                            <a
                                href={
                                    `/domains/${window.info.id}/pages/page?page=` +
                                    encodeURIComponent(d.page)
                                }
                            >
                                {title.length > 64 ? title.substr(0, 64) + '...' : title}
                            </a>
                        </strong>
                    </div>
                    <div className="d-flex align-items-center">
                        <a
                            target="_blank"
                            className="link-website d-inline-flex align-items-center justify-content-center"
                            href={d.page}
                            rel="noreferrer"
                        >
                            <i className="icon-external-link"></i>
                        </a>
                    </div>
                </div>
            );
        },
        label: 'Page',
        type: 'label',
        classes: 'col-6',
    },
    {
        key: 'age',
        label: 'Lifespan',
        classes: 'col-1',
        render: (d) => {
            return (
                <div className="col-1">
                    <strong>{numeral(d.age).format('0,00')} Days</strong>
                </div>
            );
        },
    },
    {
        key: 'impressions',
        label: 'Impressions',
        classes: 'col-2',
        render: (d) => {
            return (
                <div className="col-2">
                    <strong>{numeral(d.impressions).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        key: 'clicks',
        label: 'Clicks',
        classes: 'col-1',
        render: (d) => {
            return (
                <div className="col-1">
                    <strong>{numeral(d.clicks).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        key: 'ctr',
        label: 'CTR',
        format: '0.00%',
        classes: 'col-1',
        render: (d) => {
            return (
                <div className="col-1 align-center">
                    <strong>{numeral(d.ctr).format('0.00%')}</strong>{' '}
                </div>
            );
        },
    },
];

export default keysFunction;
