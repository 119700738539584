export const createData = (images) => {
    const data = [
        {
            label: 'Domains',
            description: '',
            image: images.entity_tracking_domains,
        },
        {
            label: 'Top Stories',
            description: '',
            image: images.entity_tracking_stories,
        },
        {
            label: 'Top Videos',
            description: '',
            image: images.entity_tracking_videos,
        },
    ];

    return data;
};
