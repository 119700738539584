import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import { collectCell, getPercent } from '../../../helpers/showDelta';

export const COLUMNS = [
    {
        label: 'Entity',
        key: 'name',
        data: 'name',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{data}</strong>;
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            if (item.id === 'all_categories') return null;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'impressions_delta',
        data: 'impressions_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return getPercent(item.impressions, item.impressions_prior);
            return collectCell(item.impressions, item.impressions_prior);
        },
    },
    {
        label: 'Pages Count',
        key: 'pages_cnt',
        data: 'pages_cnt',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'pages_cnt_delta',
        data: 'pages_cnt_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return getPercent(item.pages_cnt, item.pages_cnt_prior);
            return collectCell(item.pages_cnt, item.pages_cnt_prior);
        },
    },
    {
        label: 'Median impressions per entity',
        key: 'median_impressions_per_entity',
        data: 'median_impressions_per_entity',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'median_impressions_per_entity_delta',
        data: 'median_impressions_per_entity_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload)
                return getPercent(
                    item.median_impressions_per_entity,
                    item.median_impressions_per_entity_prior,
                );
            return collectCell(
                item.median_impressions_per_entity,
                item.median_impressions_per_entity_prior,
            );
        },
    },
    {
        label: 'alience',
        key: 'salience',
        data: 'salience',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'salience_delta',
        data: 'salience_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return getPercent(item.salience, item.salience_prior);
            return collectCell(item.salience, item.salience_prior);
        },
    },
];
