import Component from '../../components/Component';
import List from '../../components/List';

import query from './query';
import colors from '../../lib/colors';
import { keys, keysPage } from './keys';

class Summary extends Component {
    constructor(props) {
        super();
        this.query = query(props.page);
    }

    render() {
        const data = this.state.data?.length
            ? this.state.data?.sort((a, b) => b.sum_impressions - a.sum_impressions)?.slice(0, 10)
            : [];
        const dataKeys = this.props.page ? keysPage : keys;
        return (
            <List
                loading={this.state.loading}
                noItems="No categories available for this content."
                data={data}
                label={this.props.label || 'Recommended Topics'}
                keys={dataKeys}
                color={colors[1]}
                forceRun={this.forceRun}
                content_id={this.props.content_id}
                parent_id={this.props.parent_id}
                direct_parent_id={this.props.direct_parent_id}
                refreshCoustomStyles={{ fontSize: '16px', padding: '5px 7px' }}
            />
        );
    }
}

export default Summary;
