import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';

export const COLUMNS = () => [
    {
        label: 'Author name',
        key: 'author_name',
        data: 'author_name',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return (
                <a
                    style={{ fontWeight: 600 }}
                    href={`/domains/${window.info.id}/authors/author?author=` + data}
                    target="_blank"
                    rel="noreferrer"
                >
                    {data}
                </a>
            );
        },
    },
    {
        label: 'Pages',
        key: 'pages',
        data: 'pages',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{data}</strong>;
        },
    },
    {
        label: 'Impressions',
        key: 'total_impressions',
        data: 'total_impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div>
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Clicks',
        key: 'total_clicks',
        data: 'total_clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div>
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'CTR',
        key: 'ctr',
        data: 'ctr',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.total_clicks / item.total_impressions;
            if (csvDownload) return ctrValue;

            return (
                <div>
                    <strong>{numeral(ctrValue).format('0.00%')}</strong>{' '}
                </div>
            );
        },
    },
];
