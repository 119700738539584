import numeral from 'numeral';
import colors, { labelColors } from '../../lib/colors';

export default [
    {
        key: 'impressions',
        label: 'Daily Impressions',
        color: colors[0],
        labelColor: labelColors[0],
        icon: 'search',
    },
    {
        key: 'clicks',
        label: 'Clicks',
        color: colors[3],
        yAxisID: 'y3',
        labelColor: labelColors[3],
        icon: 'clicks',
    },
    {
        key: 'ctr',
        label: 'Avg. CTR',
        format: '0.00%',
        render: (data) => numeral(data * 100).format('0.00'),
        color: colors[1],
        yAxisID: 'y1',
        labelColor: labelColors[1],
        icon: 'ctr',
    },
    {
        key: 'pages',
        label: 'Daily Articles',
        format: '0,00',
        color: colors[2],
        yAxisID: 'y2',
        labelColor: labelColors[2],
        icon: 'clicks',
    },
];
