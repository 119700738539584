import Query from '../../../../components/Report';

export const query = ({ fromEnd, fromStart, toStart, toEnd, offset = 0 }) =>
    new Query('search-algo-comparision-urls', {
        fromEnd,
        fromStart,
        toStart,
        toEnd,
        // filter: addfilter(filter),
        offset,
    });
