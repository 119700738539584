import { Component } from 'react';
import { createData } from './config';
import Announcement from '../../components/Announcement';
import Loading from '../../components/Loading';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import { activateMessage } from '../../helpers/messages';

class ToolsAnnouncement extends Component {
    constructor() {
        super();

        this.createButton = this.createButton.bind(this);
        this.createLink = this.createLink.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.endTrial = this.endTrial.bind(this);

        this.state = {
            buttonName: '',
            isLink: false,
            href: '',
            isOpen: false,
            loading: false,
            onClick: () => {},
        };
    }

    componentDidMount() {
        if (this.props.plan_type === 'Trial') this.createButton();

        if (this.props.plan_type === 'Basic' || this.props.plan_type === 'Trial_Basic')
            this.createLink();
    }

    createButton() {
        this.setState({
            buttonName: 'Activate your Subscription',
            onClick: this.onOpenModal,
        });
    }

    endTrial() {
        this.setState({ loading: true });
        fetch(`${this.props.current_url}/customers/end-trial/?domain=${this.props.domain_id}`)
            .then((res) => res.json())
            .then((data) => {
                const redirect_url = data.redirect_url;
                window.location.pathname = redirect_url;
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    createLink() {
        this.setState({
            buttonName:
                this.props.plan_type === 'Basic'
                    ? 'Upgrade your Subscription'
                    : 'Activate & Upgrade your subscription',
            isLink: true,
            href: `${this.props.current_url}/customers/change_plan/?without_basic=True`,
        });
    }

    onOpenModal() {
        this.setState({ isOpen: true });
    }

    onCloseModal() {
        this.setState({ isOpen: false });
    }

    render() {
        const content = createData(this.props);
        return (
            <>
                <Announcement
                    image={this.props.announcement_image}
                    buttonName={this.state.buttonName}
                    onClick={this.state.onClick}
                    message={this.props.message}
                    isLink={this.state.isLink}
                    href={this.state.href}
                    announcement_bar_styles={this.props.announcement_bar_styles}
                    buttonStyles={this.props.button_styles || 'bg-purple'}
                    loading={this.state.loading}
                />

                {content.map((item, i) => {
                    return (
                        <div key={i} className="show-sample-container">
                            <div className="row card-row d-flex" id="authors-content">
                                <div className="col-12">
                                    <div className="card table-main rounded-main overflow-hidden">
                                        <div className="card-header">
                                            <div className="card-title">{item.label}</div>
                                            {item.description && (
                                                <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                                                    {item.description}
                                                </small>
                                            )}
                                        </div>
                                        <div className="card-body">
                                            <img src={item.image} alt={item.label} loading="lazy" />
                                            <div className="redirect-button">
                                                {this.state.isLink ? (
                                                    <a
                                                        className="bg-purple button-close"
                                                        href={this.state.href}
                                                    >
                                                        {this.state.buttonName}
                                                    </a>
                                                ) : (
                                                    <div>
                                                        <button
                                                            className={'bg-purple button-close'}
                                                            onClick={this.state.onClick}
                                                            disabled={this.state.loading}
                                                        >
                                                            {this.state.buttonName}
                                                        </button>
                                                        <div className="d-flex justify-content-center pt-2">
                                                            {this.state.loading && (
                                                                <Loading onlyPreloader />
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <ConfirmationModal
                    isOpen={this.state.isOpen}
                    message={activateMessage}
                    onClose={this.onCloseModal}
                    onSubmit={this.endTrial}
                    cancleBtn="Continue Trial"
                    buttonName="Activate Paid Plan"
                />
            </>
        );
    }
}

export default ToolsAnnouncement;
