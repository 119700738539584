import React from 'react';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

const doc = new jsPDF();

export default function Exports({ pdfColumnSize, data, COLUMNS, fileName }) {
    const escapeCsvCell = (cell) => {
        if (cell == null) {
            return '';
        }
        const sc = cell.toString().trim();

        if (sc === '' || sc === '""') {
            return sc;
        }

        if (sc.includes('"') || sc.includes(',') || sc.includes('\n') || sc.includes('\r')) {
            return '"' + sc.replace(/"/g, '""') + '"';
        }

        return sc;
    };

    const makeCsvData = (columns, data) => {
        return data.reduce((csvString, rowItem) => {
            return (
                csvString +
                columns.map(({ accessor }) => escapeCsvCell(accessor(rowItem))).join(',') +
                '\r\n'
            );
        }, columns.map(({ name }) => escapeCsvCell(name)).join(',') + '\r\n');
    };

    function getAllNodes(nodes, allNodes = []) {
        nodes.forEach((node) => {
            allNodes.push(node);
            if (node.nodes) getAllNodes(node.nodes, allNodes);
        });
        return allNodes;
    }

    const downloadAsCsv = (columns, data, filename) => {
        const csvData = makeCsvData(columns, data);
        const csvFile = new Blob([csvData], { type: 'text/csv' });
        const downloadLink = document.createElement('a');

        downloadLink.display = 'none';
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleDownloadCsv = () => {
        const columns = COLUMNS.map((column) => ({
            accessor: (item) =>
                column.renderCell
                    ? column.renderCell({ data: item[column.data], item, csvDownload: true })
                    : item[column.data],
            name: column.label,
        }));
        const allNodes = getAllNodes(data.nodes);
        downloadAsCsv(columns, allNodes, fileName);
    };

    const handleDownloadPdf = async () => {
        const allNodes = getAllNodes(data.nodes);

        const columnWidth = COLUMNS.reduce((acc, curr, index) => {
            acc = {
                ...acc,
                [index]: { columnWidth: pdfColumnSize ?? 'auto' },
            };
            return acc;
        }, {});

        autoTable(doc, {
            head: [COLUMNS.map((item) => item.label)],
            body: allNodes.map((item) =>
                COLUMNS.map((column) =>
                    column.renderCell
                        ? column.renderCell({ data: item[column.data], item, csvDownload: true })
                        : item[column.data],
                ),
            ),
            theme: 'grid',
            styles: {
                fontSize: 6, // Adjust font size for better fit with many columns
                cellPadding: 1, // Adjust cell padding for compactness
                overflow: 'linebreak', // Handle overflow by breaking lines
            },
            headStyles: {
                fillColor: [22, 160, 133],
                textColor: [255, 255, 255],
                fontSize: 7, // Smaller font size for headers
            },
            bodyStyles: {
                fontSize: 6,
            },
            columnStyles: {
                ...columnWidth,
            },
        });

        doc.save(`${fileName}.pdf`);
    };

    return (
        <div className="dt-exports">
            <button className="dt-exports__button" type="button" onClick={handleDownloadCsv}>
                CSV
            </button>
            <button className="dt-exports__button" type="button" onClick={handleDownloadPdf}>
                PDF
            </button>
        </div>
    );
}
