import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import Sparkline from '../../../components/Sparklines';

export const COLUMNS = [
    {
        label: 'URL',
        key: 'url',
        data: 'url',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <strong>
                    <a
                        href={`/domains/${
                            window.info.id
                        }/gsc-reports/urls/url/?url=${encodeURIComponent(data)}`}
                        className="link-website d-inline-flex align-items-center justify-content-center"
                        rel="noreferrer"
                    >
                        {data}
                    </a>
                </strong>
            );
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Trend',
        key: 'sparkline',
        data: 'sparkline',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return '-';

            return (
                <Sparkline
                    dataL={item.clicksL}
                    labels={item.start_dateL.map((x) => x.value)}
                    keyName="Clicks"
                />
            );
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Trend',
        key: 'impressions_sparkline',
        data: 'impressions_sparkline',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return '-';

            return (
                <Sparkline
                    dataL={item.impressionsL}
                    labels={item.start_dateL.map((x) => x.value)}
                    keyName="Impressions"
                />
            );
        },
    },
    {
        label: 'avg. Position',
        key: 'avgPosition',
        data: 'avgPosition',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Weekly keywords',
        key: 'weekly_keywords',
        data: 'weekly_keywords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'CTR',
        key: 'avgCTR',
        data: 'avgCTR',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
];
