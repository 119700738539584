import React from 'react';
import ReactDOM from 'react-dom';

export default function render(sel, Component) {
    let els = document.querySelectorAll(sel);

    if (els.length == 0) {
        return;
    }

    for (let el of els) {
        let inner = el.innerHTML;

        let attrs = el.attributes;
        let props = {};

        for (let n in attrs) {
            let v = attrs[n].value;
            props[attrs[n].name] = v;
        }

        props.html = inner;

        ReactDOM.render(<Component {...props}></Component>, el);
    }
}
