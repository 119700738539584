export const updateDefaultPaymentMethod = async (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/customers/add-payment-method/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
