import Loading from '../../components/Loading';
import LoadMoreComponent from '../../components/LoadMoreComponent';

import query, { getAllQuery } from './query';
import { COLUMNS, COLUMNS_EXPORT } from './keys';
import CheckData from '../../components/CatchError';
import DataTable from '../../components/Datatable';
import { scrollForTable } from '../../components/Datatable/utils';
import { linReg } from '../../lib/reduce';
import { downloadAllCsv } from '../../helpers/downloadAll';

class Summary extends LoadMoreComponent {
    constructor() {
        super();
        this.query = query({});

        this.loadMore = this.loadMore.bind(this);
        this.exportAll = this.exportAll.bind(this);

        this.offsetStep = 100;
    }

    searchFunction(data, search) {
        return data.filter(
            (item) =>
                item?.title?.toLowerCase().includes(search.toLowerCase()) ||
                item?.page?.toLowerCase().includes(search.toLowerCase()),
        );
    }

    enableAnalyzeButton() {
        const btn = document.getElementById('analyze-content-btn');
        btn.style.display = 'block';
    }

    async exportAll() {
        try {
            this.setState({
                exportAllLoading: true,
            });
            const allQuery = getAllQuery();
            const response = await allQuery.update();
            downloadAllCsv(response, 'Pages', COLUMNS_EXPORT);
            this.setState({
                exportAllLoading: false,
            });
        } catch (err) {
            this.setState({
                exportAllLoading: false,
            });
            console.log(err);
        }
    }

    render() {
        // if we get less than offsetStep it means we don't have more data
        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const attr = {
            query,
            offsetStep: this.offsetStep,
        };
        if (this.state?.data && this.state?.allData?.length !== 0) {
            this.enableAnalyzeButton();
        }
        const sortFns = {
            'impr-delta': (array) =>
                array.sort((a, b) => linReg(a.impressionsL)?.slope - linReg(b.impressionsL)?.slope),
            page: (array) =>
                array.sort((a, b) => {
                    const a_title = a.title || a.page;
                    const b_title = b.title || b.page;
                    return a_title.localeCompare(b_title);
                }),
            age: (array) => array.sort((a, b) => a.age - b.age),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            ctr: (array) => array.sort((a, b) => a.ctr - b.ctr),
        };
        if (this.state.loading && !this.state.loadMore) {
            return <Loading padding={10} />;
        }

        if (this.state.allData?.length === 0)
            return <div style={{ padding: '10px' }}>There is no data</div>;

        return (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.allData}
                    loading={this.state.loading}
                    loadMore={this.state.loadMore}
                >
                    <DataTable
                        nodes={this.state.allData}
                        sortFns={sortFns}
                        COLUMNS={COLUMNS(this.props.default_image)}
                        customColumnsForExport={COLUMNS_EXPORT}
                        fileName="Pages"
                        forceRun={this.forceRun}
                        searchFunction={this.searchFunction}
                        additionalStyles={{
                            Table: `
                                --data-table-library_grid-template-columns :  minmax(250px, 2.5fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                ${scrollForTable}
                            `,
                            BaseCell: `  font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                        }}
                    />
                </CheckData>
                {this.state.loadMore ? (
                    <Loading padding={10} />
                ) : (
                    <div className="d-flex align-items-center justify-content-center pt-3 load-more-section mr-10">
                        {showLoadMoreBtn && (
                            <button
                                onClick={() => this.loadMore(attr)}
                                disabled={this.state.loading}
                                className="load-more-btn mr-10"
                            >
                                Load More
                            </button>
                        )}
                        {this.state.exportAllLoading ? (
                            <Loading padding={10} />
                        ) : (
                            <button
                                onClick={this.exportAll}
                                disabled={this.state.loading}
                                className="load-more-btn"
                            >
                                Export All
                            </button>
                        )}
                    </div>
                )}
            </>
        );
    }
}

export default Summary;
