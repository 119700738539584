import LoadMoreComponent from '../../../../../../components/LoadMoreComponent';
import { changeIntent } from '../../../../../../helpers/commerce';
import { getSortKeys, setAdditionalStyles } from '../../../../../../helpers/table';
import Item from './Item';
import { COLUMNS } from './keys';
import query from './query';
import { v4 as uuid } from 'uuid';

class OpportunitiesKeywords extends LoadMoreComponent {
    constructor(props) {
        super();
        if (!props.data)
            this.query = query({
                project_id: props.project_id,
                domain: props.own_domain,
                offset: 0,
            });

        this.customForceRun = this.customForceRun.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.updateTables = this.updateTables.bind(this);

        this.offsetStep = 1000;

        this.url = new URL(window.location);
        this.intent = this.url.searchParams.get('intent');

        const breadcrumbBox = document.getElementById('breadcrumb-box');
        if (breadcrumbBox)
            breadcrumbBox.innerHTML = `<a class="text-primary domain-name" aria-current="page" href="/commerce/">Commerce</a><i class="icon-arrow-right"></i> <a href="#" >Project: ${props.keyword} </a> <i class="icon-arrow-right"></i> <a href="#" >Opportunities</a>`;
    }

    searchFunction(data, search) {
        return data.filter((item) => item?.keyword?.toLowerCase()?.includes(search.toLowerCase()));
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDatas({ key: 'allData', value: this.state.data });
            this.props.setKeywordsFunction(this.handleSelectChange);
        }

        const filters = localStorage.getItem('filters');
        const data = this.state.data?.map((x) => ({ id: uuid(), ...x }));

        if (prevState.filters !== filters && !this.state.loading) {
            return this.setState({ allData: data, filters });
        }
        if (
            JSON.stringify(this.state.data) !== JSON.stringify(prevState.data) &&
            !this.state.loading
        ) {
            const allData = [...this.state.allData, ...data];
            this.setState({ allData });
            let sortedValues = null;
            if (this.intent) {
                sortedValues = allData.filter((item) =>
                    this.checkIntent(item.intent, this.intent?.split(',')),
                );
            }
            this.setState({ allData, sortedValues });
        }
    }

    customForceRun() {
        this.forceRun();
        this.setState({
            allData: [],
            data: null,
        });
        this.props.updateDatas({ key: 'allData', empty: true });
    }

    checkIntent(itemIntent, selectedIntent) {
        for (let i = 0; i < selectedIntent?.length; i++) {
            if (itemIntent.includes(selectedIntent[i])) return true;
        }
        return false;
    }

    updateTables(values) {
        this.handleSelectChange(values);
        this.props.recallClusters && this.props.recallClusters(values);
    }

    handleSelectChange(values) {
        let data = this.state.allData?.length ? this.state.allData : this.props.data;
        changeIntent(values);
        this.props.updateDatas({ emptyAll: true });

        const sortedValues = values?.length
            ? data.filter((item) =>
                  this.checkIntent(
                      item.intent,
                      values?.map((item) => item.value),
                  ),
              )
            : null;

        this.setState({
            sortedValues,
        });
        this.props.changeSelect(values);
    }

    render() {
        let data = this.state.allData?.length ? this.state.allData : this.props.data;

        data = this.state.sortedValues || data;

        const items = [
            {
                title: 'Highest Search Volume',
                defaultSortKey: '',
                getData: (data) =>
                    data?.sort((a, b) => b.search_volume - a.search_volume)?.slice(0, 100),
            },
            {
                title: 'High Search Volume & Low Keyword Difficulty',
                defaultSortKey: '',
                getData: (data) =>
                    data
                        ?.sort((a, b) => b.search_volume - a.search_volume)
                        ?.filter(
                            (item) =>
                                item.keyword_difficulty_index >= 1 &&
                                item.keyword_difficulty_index < 30,
                        )
                        ?.slice(0, 100),
            },
            {
                title: 'Long Tail Keywords',
                defaultSortKey: '',
                getData: (data) =>
                    data
                        ?.sort((a, b) => b.search_volume - a.search_volume)
                        ?.filter((item) => item.keyword?.split(' ')?.length > 4)
                        ?.slice(0, 100),
            },
            {
                title: 'Almost There - Striking Distance Keywords (Rankings 4-10)',
                defaultSortKey: '',
                getData: (data) =>
                    data
                        ?.sort((a, b) => b.search_volume - a.search_volume)
                        ?.filter((item) => item.position > 4 && item.position <= 10)
                        ?.slice(0, 100),
            },
            {
                title: 'Quick Wins - 2nd Page Keywords (Rankings 11-20)',
                defaultSortKey: '',
                getData: (data) =>
                    data
                        ?.sort((a, b) => b.search_volume - a.search_volume)
                        ?.filter((item) => item.position >= 11)
                        ?.slice(0, 100),
            },
            {
                title: 'Low Difficulty Keywords',
                defaultSortKey: '',
                getData: (data) =>
                    data
                        ?.sort((a, b) => b.search_volume - a.search_volume)
                        ?.filter((item) => item.keyword_difficulty_index < 50)
                        ?.slice(0, 100),
            },
        ];

        return (
            <>
                {items.map((item) => (
                    <Item
                        key={item.title}
                        title={item.title}
                        data={item.getData(data)}
                        sortFns={getSortKeys(COLUMNS())}
                        loading={!data && this.state.loading}
                        columns={COLUMNS(this.props.project_id, this.props.own_domain)}
                        forceRun={this.customForceRun}
                        defaultSortKey={item.defaultSortKey}
                        searchFunction={this.searchFunction}
                        handleSelectChange={this.updateTables}
                        select={this.props.select}
                        additionalStyles={setAdditionalStyles(COLUMNS())}
                    />
                ))}
            </>
        );
    }
}

export default OpportunitiesKeywords;
