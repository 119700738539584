import React from 'react';
import ChangePlanComponent from '../../components/ChangePlan';
import { isTrue } from '../../helpers/isTrue';
import CardDetails from './CardDetails';
import { changePlanQuery } from './query';

class ChangePlane extends React.Component {
    constructor(props) {
        super();

        this.onSelectPaymentMethode = this.onSelectPaymentMethode.bind(this);
        this.handlePaymentMethod = this.handlePaymentMethod.bind(this);
        this.onEmptyCardError = this.onEmptyCardError.bind(this);
        this.updateCardInfo = this.updateCardInfo.bind(this);
        this.onChangePlan = this.onChangePlan.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.updateCard = this.updateCard.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.addCard = this.addCard.bind(this);

        this.paymentMethods = JSON.parse(props.payment_methods);
        this.current_plan = JSON.parse(props.current_plan);

        this.state = {
            cardInfo: {
                full_name: props.full_name,
                email: props.email,
            },
            selectedPlan: {},
            card_token: '',
            errors: {},
            cardError: '',
            loading: false,
            selected_payment_method: props.default_payment_method,
            addNewCard: false,
        };
    }

    async addCard() {
        if (!this.state.addNewCard) return { token: this.state.selected_payment_method };
        const card = await this.state.stripe.createPaymentMethod({
            type: 'card',
            card: this.state.card,
            billing_details: {
                name: this.state.cardInfo.full_name,
                email: this.state.cardInfo.email,
            },
        });

        return { token: card.paymentMethod.id, error: card.error };
    }

    updateCardInfo(name, value) {
        this.setState({
            cardInfo: {
                ...this.state.cardInfo,
                [name]: value,
            },
        });
    }

    updateCard(card, stripe) {
        if (stripe) return this.setState({ card, stripe });

        this.setState({ card });
    }

    onChangePlan(selectedPlan) {
        this.setState({
            selectedPlan,
        });
    }

    onSelectPaymentMethode(data) {
        const errors = { ...this.state.errors };
        delete errors['selected-card'];

        this.setState({ errors });
        this.setState({
            selected_payment_method: data.value,
            errors,
        });
    }

    checkErrors() {
        const errors = {};
        if (!this.state.addNewCard) {
            const card = this.paymentMethods.find(
                (x) => x.token === this.props.default_payment_method,
            );
            if (card.expired) errors['selected-card'] = 'Card is expired';
        } else {
            const cardInfo = { ...this.state.cardInfo };

            for (let key in cardInfo) {
                if (!cardInfo[key]) {
                    errors[key] = 'This field is required';
                } else {
                    delete errors[key];
                }
            }
        }
        this.setState({ errors });
        return errors;
    }

    onFocus(e) {
        const errors = { ...this.state.errors };
        delete errors[e.target.name];

        this.setState({ errors });
    }

    onEmptyCardError() {
        this.setState({ cardError: '' });
    }

    handlePaymentMethod() {
        this.setState({ addNewCard: !this.state.addNewCard });
    }

    async onSubmit({ selectedPlanInterval }) {
        try {
            const errors = this.checkErrors();
            if (Object.keys(errors).length !== 0) return;

            this.setState({ loading: true });

            const card = await this.addCard();
            if (card.error) {
                this.setState({ loading: false });
                this.setState({ cardError: card.error.message });
                return;
            }
            const params = {
                token: card.token,
                new_payment_method: this.state.addNewCard,
                selected_plan: this.state.selectedPlan.fields.plan_id,
                current_plan: this.current_plan?.plan_id,
                interval: selectedPlanInterval?.trim()?.toLowerCase()?.split(' ')[0],
                end_trial_now:
                    isTrue(this.props.without_basic) &&
                    isTrue(this.props.trialing) &&
                    isTrue(this.props.basic_plan),
            };

            if (this.state.selectedPlan.promotion_code)
                params.promotion_code = this.state.selectedPlan.promotion_code;

            const response = await changePlanQuery(
                this.props.endpoint_url,
                params,
                this.props.csrf_token,
                this.props.license_id,
            );
            const data = await response.json();

            this.setState({ loading: false });

            if (data.redirect_url) {
                window.location.href = data.redirect_url;
                return;
            }

            this.setState({ paymentError: data.message });
        } catch (err) {
            this.setState({ loading: false });
            console.log(err);
        }
    }

    render() {
        return (
            <div className="add-payment-method-wrapper">
                <div className="card rounded-main table-main">
                    <div className="row">
                        <CardDetails
                            updateCardInfo={this.updateCardInfo}
                            cardInfo={this.state.cardInfo}
                            updateCard={this.updateCard}
                            errors={this.state.errors}
                            cardError={this.state.cardError}
                            onFocus={this.onFocus}
                            onEmptyCardError={this.onEmptyCardError}
                            active={this.props.active}
                            paymentMethods={this.paymentMethods}
                            onSelectPaymentMethode={this.onSelectPaymentMethode}
                            default_payment_method={this.props.default_payment_method}
                            addNewCard={this.state.addNewCard}
                            handlePaymentMethod={this.handlePaymentMethod}
                        />
                        <div className="col-xxl-7">
                            <ChangePlanComponent
                                endpoint_url={this.props.endpoint_url}
                                pl={JSON.parse(this.props.pl)}
                                plan_intervals={JSON.parse(this.props.plan_intervals)}
                                plan_names={JSON.parse(this.props.plan_names)}
                                current_plan={this.current_plan}
                                showOneBorder
                                onChangePlan={this.onChangePlan}
                                coupon={JSON.parse(this.props.coupon)}
                                showBtns
                                discount={this.props.discount}
                                submitName={this.props.button_name}
                                onSubmit={this.onSubmit}
                                loading={this.state.loading}
                                without_basic={this.props.without_basic}
                                trialing={this.props.trialing}
                                showCurrentPlan
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePlane;
