import Query from '../../components/Report';
import { getDatesForAppearance } from '../../helpers/appearancesDates';

const query = new Query('discover-stats-appearances-view', {
    prior_start_date: '',
    prior_end_date: '',
    get_prior_dates: (filters) => getDatesForAppearance(filters),
});

export default query;
