import { Component } from 'react';
import MessageCustom from '../MessageCustom';
import { getMessage } from './query';

class StripeMessage extends Component {
    constructor() {
        super();

        this.state = {
            payment_action_required_link: null,
            payment_action_required_message: null,
        };
    }

    async componentDidMount() {
        try {
            const response = await getMessage();
            const data = await response.json();
            if (data.payment_action_required_link && data.payment_action_required_message) {
                this.setState({
                    payment_action_required_link: data.payment_action_required_link,
                    payment_action_required_message: data.payment_action_required_message,
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        return (
            this.state.payment_action_required_message && (
                <MessageCustom
                    message={this.state.payment_action_required_message}
                    header="Action Needed: Verify Your Payment to GDdash"
                    link_name="Confirm here"
                    link={this.state.payment_action_required_link}
                    isHeaderSeperated
                    hideCloseBtn
                />
            )
        );
    }
}

export default StripeMessage;
