import { Component } from 'react';
import Directories from './Directories';
import GeoPerformance from './GeoPerformance';
import { TAGS } from './keys';
import KeywordClusters from './KeywordClusters';
import SearchAppearancePerformance from './SearchAppearancePerformance';
import OverallPerformanceChart from './OverallPerformance/chart';
import OverallPerformance from './OverallPerformance';
import CTR from './CTR';
import URLs from './URLs';

class GSCReportComparisonItems extends Component {
    constructor() {
        super();
        this.onChangeTags = this.onChangeTags.bind(this);
        this.showElement = this.showElement.bind(this);
        this.addActive = this.addActive.bind(this);
        this.updateDates = this.updateDates.bind(this);

        this.url = new URL(window.location);
        let view = this.url.searchParams.get('view');

        if (view === 'all') {
            view = TAGS.map((tag) => tag.id);
        } else {
            view = [view];
        }

        this.state = {
            types: view,
            datas: {},
        };
    }

    onChangeTags(id, filter) {
        this.url.searchParams.set('view', id);
        if (filter) {
            this.url.searchParams.set('defaultFilter', filter);
        } else {
            this.url.searchParams.delete('defaultFilter');
        }
        history.pushState({}, '', this.url);

        if (id === 'all') {
            return this.setState({
                types: TAGS.map((tag) => tag.id),
            });
        }

        this.setState({
            types: [id],
        });
    }

    showElement(id) {
        return !this.state.types.length || this.state.types.includes(id);
    }

    addActive(id) {
        if (this.state.types.includes('all')) {
            if (id === 'all') return true;
            return false;
        }

        return this.state.types.includes(id);
    }

    updateDates({ key, value, emptyAll = false }) {
        if (emptyAll) {
            return this.setState({
                datas: {},
            });
        }

        this.setState({
            datas: {
                ...this.state.datas,
                [key]: value,
            },
        });
    }
    render() {
        return (
            <>
                <div className="tags-container">
                    {TAGS.map((tag) => (
                        <span
                            className={`tab-item ${this.addActive(tag.id) && 'active-tag'}`}
                            key={tag.id}
                            onClick={() => this.onChangeTags(tag.id, tag.filter)}
                        >
                            {tag.label}
                        </span>
                    ))}
                </div>
                {this.showElement('overall_performance') && (
                    <>
                        <div id="overal-chart-content">
                            <div className="row card-row" id="overal-chart">
                                <div className="col-12">
                                    <div className="card rounded-main overflow-hidden">
                                        <div className="card-body">
                                            <OverallPerformanceChart
                                                content_id="overal-chart"
                                                parent_id="overal-chart-content"
                                                dates={this.props.dates}
                                                data={this.state.datas.overallPerformanceChart}
                                                updateDates={this.updateDates}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="overall-table-content">
                            <div className="row card-row" id="overall-table">
                                <div className="col-12">
                                    <div className="card table-main rounded-main overflow-hidden">
                                        <div className="card-header">
                                            <p className="card-title">Overall Performance</p>
                                        </div>
                                        <div className="card-body">
                                            <OverallPerformance
                                                content_id="recommendations-page"
                                                parent_id="recommendations-content"
                                                dates={this.props.dates}
                                                data={this.state.datas.overallPerformance}
                                                updateDates={this.updateDates}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {this.showElement('geo_performance') && (
                    <div id="geoPerformance-content">
                        <div className="row card-row" id="geoPerformance-page">
                            <div className="col-12">
                                <div className="card table-main rounded-main overflow-hidden">
                                    <div className="card-header">
                                        <p className="card-title">Geo Performance</p>
                                    </div>
                                    <div className="card-body">
                                        <GeoPerformance
                                            content_id="geoPerformance-page"
                                            parent_id="geoPerformance-content"
                                            dates={this.props.dates}
                                            data={this.state.datas.geoPerformance}
                                            updateDates={this.updateDates}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.showElement('search_appearance_performance') && (
                    <div id="searchAppearancePerformance-content">
                        <div className="row card-row" id="searchAppearancePerformance-page">
                            <div className="col-12">
                                <div className="card table-main rounded-main overflow-hidden">
                                    <div className="card-header">
                                        <p className="card-title">Search Appearance Performance</p>
                                    </div>
                                    <div className="card-body">
                                        <SearchAppearancePerformance
                                            content_id="searchAppearancePerformance-page"
                                            parent_id="searchAppearancePerformance-content"
                                            dates={this.props.dates}
                                            data={this.state.datas.searchAppearancePerformance}
                                            updateDates={this.updateDates}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.showElement('keyword_clusters') && (
                    <div id="keywordClusters-content">
                        <div className="row card-row" id="keywordClusters-page">
                            <div className="col-12">
                                <div className="card table-main rounded-main overflow-hidden">
                                    <div className="card-header">
                                        <p className="card-title">Keyword Clusters</p>
                                    </div>
                                    <div className="card-body">
                                        <KeywordClusters
                                            content_id="keywordClusters-page"
                                            parent_id="keywordClusters-content"
                                            dates={this.props.dates}
                                            data={this.state.datas.keywordClusters}
                                            updateDates={this.updateDates}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.showElement('urls') && (
                    <div id="keywordClusters-content">
                        <div className="row card-row" id="keywordClusters-page">
                            <div className="col-12">
                                <div className="card table-main rounded-main overflow-hidden">
                                    <div className="card-header">
                                        <p className="card-title">URLs</p>
                                    </div>
                                    <div className="card-body">
                                        <URLs
                                            content_id="keywordClusters-page"
                                            parent_id="keywordClusters-content"
                                            dates={this.props.dates}
                                            data={this.state.datas.urls}
                                            updateDates={this.updateDates}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.showElement('directories') && (
                    <div id="directories-content">
                        <div className="row card-row" id="directories-page">
                            <div className="col-12">
                                <div className="card table-main rounded-main overflow-hidden">
                                    <div className="card-header">
                                        <p className="card-title">Directories</p>
                                    </div>
                                    <div className="card-body">
                                        <Directories
                                            content_id="directories-page"
                                            parent_id="directories-content"
                                            dates={this.props.dates}
                                            data={this.state.datas.directories}
                                            updateDates={this.updateDates}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.showElement('ctr') && (
                    <CTR
                        dates={this.props.dates}
                        data={this.state.datas.ctr}
                        updateDates={this.updateDates}
                    />
                )}
            </>
        );
    }
}

export default GSCReportComparisonItems;
