import React from 'react';

class Modal extends React.Component {
    constructor(props) {
        super();

        this.state = {
            active: props.active || false,
        };

        this.toggleActive = this.toggleActive.bind(this);
    }

    toggleActive() {
        this.setState({
            active: !this.state.active,
        });
    }

    render() {
        let active = this.state.active ? 'active' : 'inactive';

        return (
            <div>
                <a
                    href="#"
                    onClick={this.toggleActive}
                    className={this.props.btnclass || 'btn btn-sm btn-primary text-white'}
                >
                    {this.props.btntext || 'Open'}
                </a>
                <div className={'modal ' + active}>
                    <div className="modal-content" style={{ width: this.props.width || '600px' }}>
                        {this.props.children}
                    </div>
                    <div className="modal-backdrop" onClick={this.toggleActive} />
                </div>
            </div>
        );
    }
}

export default Modal;
