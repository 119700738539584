import { Component } from 'react';
import { v4 as uuid } from 'uuid';
import Loading from '../../../components/Loading';
import { addEmails, deleteEmail, getEmails } from '../../AdditionalEmailsSettings/query';

class AddAdditionalEmails extends Component {
    constructor() {
        super();

        this.handleChange = this.handleChange.bind(this);
        this.addUserFileds = this.addUserFileds.bind(this);
        this.deleteUserFileds = this.deleteUserFileds.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getEmailsFn = this.getEmailsFn.bind(this);
        this.addInDeleteList = this.addInDeleteList.bind(this);
        this.onDelete = this.onDelete.bind(this);

        this.state = {
            updatedEmails: [{ id: uuid(), email: '' }],
            emails: [],
            getLoader: false,
            deleteList: [],
        };
    }

    componentDidMount() {
        this.getEmailsFn();
    }

    async getEmailsFn() {
        try {
            this.setState({
                getLoader: true,
            });
            const res = await getEmails();
            const data = await res.json();
            if (res.status === 200) {
                const valuesWithIds = data.result.map((item) => ({ id: uuid(), email: item }));
                this.setState({
                    getLoader: false,
                    emails: valuesWithIds,
                    updatedEmails: valuesWithIds?.length
                        ? valuesWithIds
                        : [{ id: uuid(), email: '' }],
                });
            } else {
                this.setState({
                    getLoader: false,
                });
            }
        } catch (err) {
            console.log('err', err);
        }
    }

    handleChange(e, id) {
        const updatedEmails = this.state.updatedEmails.map((user) => {
            if (user.id === id) {
                return { ...user, [e.target.name]: e.target.value };
            }
            return user;
        });

        this.setState({
            updatedEmails,
        });
    }

    addUserFileds() {
        const emptyUser = {
            id: uuid(),
            email: '',
        };

        this.setState({
            updatedEmails: [...this.state.updatedEmails, emptyUser],
        });
    }

    deleteUserFileds(id, email) {
        this.addInDeleteList(email);
        this.setState({
            updatedEmails: this.state.updatedEmails.filter((item) => item.id !== id),
        });
    }

    addInDeleteList(email) {
        if (this.state.emails.find((item) => item.email === email)) {
            this.setState({
                deleteList: [...this.state.deleteList, email],
            });
        }
    }

    async onDelete(emails) {
        try {
            const res = await deleteEmail(emails);
            const data = await res.json();

            if (res.status === 200) {
                this.setState({
                    emails: this.state.emails.filter((item) => !emails.includes(item.email)),
                });
            } else {
                this.setState({
                    message: data?.message,
                });
            }
        } catch (err) {
            console.log('err', err);
        }
    }

    async onSubmit() {
        try {
            this.setState({
                loading: true,
            });

            if (this.state.deleteList.length) {
                await this.onDelete(this.state.deleteList);
                this.setState({
                    deleteList: [],
                });
            }

            const emails = this.state.updatedEmails
                .filter((item) => !this.state.emails.find((state) => state.email === item.email))
                .map((item) => item.email.trim())
                .filter((item, i, arr) => arr.indexOf(item) === i);

            const res = await addEmails(emails);
            if (res.status === 200) {
                this.setState({
                    loading: false,
                });
                this.getEmailsFn();
            } else {
                const data = await res.json();
                this.setState({
                    loading: false,
                    message: data?.message ?? 'Something went wrong. Please try again.',
                    message_tags: 'error',
                });
            }
        } catch (err) {
            this.setState({
                loading: false,
                message: err.message ?? 'Something went wrong. Please try again.',
                message_tags: 'error',
            });
        }
    }

    render() {
        return (
            <>
                <div id="onboarding-general-settings">
                    <div className="onboarding-subheader">
                        Add other users to receive weekly and monthly performance emails. These
                        users will not have access to the tool but will receive performance reports
                        via email.
                    </div>
                    {this.state.getLoader ? (
                        <Loading />
                    ) : (
                        this.state.updatedEmails.map((user, i, arr) => (
                            <div className="onboarding-add-user short" key={user.id}>
                                <div className="onboarding-add-user-field">
                                    <label htmlFor="email" className="form-label">
                                        Email Address
                                    </label>
                                    <input
                                        name="email"
                                        id="email"
                                        className="form-control"
                                        value={user.email}
                                        onChange={(e) => this.handleChange(e, user.id)}
                                    />
                                </div>
                                <div className="onboarding-add-user-btns">
                                    {arr.length !== 1 && (
                                        <button
                                            onClick={() =>
                                                this.deleteUserFileds(user.id, user.email)
                                            }
                                        >
                                            -
                                        </button>
                                    )}
                                    {i === arr.length - 1 && (
                                        <button
                                            className="onboarding-add-user-btns-add"
                                            onClick={this.addUserFileds}
                                        >
                                            +
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))
                    )}
                    {this.state.message && (
                        <div className={`onboarding-message-${this.state.message_tags}`}>
                            {this.state.message}
                        </div>
                    )}

                    <div className="d-flex justify-content-between">
                        <button
                            className="button button-outline button-primary width-sm btn"
                            onClick={this.onSubmit}
                            disabled={this.state.loading}
                        >
                            {this.state.loading && <Loading onlyPreloader />}
                            Save
                        </button>
                        <div className="d-flex">
                            <button
                                className="button width-sm btn onboarding-btn-next d-flex justify-content-center onboarding-skip-btn"
                                onClick={() => this.props.handleBack()}
                            >
                                back
                            </button>
                            <button
                                className="button  button-filled button-primary width-sm btn onboarding-btn-next d-flex justify-content-center"
                                onClick={() => this.props.handleNext()}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AddAdditionalEmails;
