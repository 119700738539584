import { Component } from 'react';
import { options } from './config';
import CheckboxDropdown from '../CheckboxDropdown';

class CommerceFilterIntent extends Component {
    constructor() {
        super();

        this.onApply = this.onApply.bind(this);

        this.url = new URL(window.location);
        this.intent = this.url.searchParams.get('intent');
    }

    onApply(values) {
        const selectedOptions = options.filter((item) => values.includes(item.value));
        this.props.handleSelectChange(selectedOptions);
    }

    render() {
        return (
            <div className="intent-container">
                <CheckboxDropdown
                    options={options}
                    label={'Select Intent'}
                    alreadySelected={this.intent?.split(',')?.map((item) => Number(item))}
                    onApply={this.onApply}
                />
            </div>
        );
    }
}

export default CommerceFilterIntent;
