import Component from '../../../components/Component';
import Loading from '../../../components/Loading';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { getRandomColor, labelColors } from '../../../lib/colors';
import CheckData from '../../../components/CatchError';
import DataTable from '../../../components/Datatable';
import { COLUMNS } from './keys';
import { query } from './query';
import { Pie } from 'react-chartjs-2';
import numeral from 'numeral';
import _ from 'lodash';
import { noDataMessage } from '../../../helpers/noDataMessage';

ChartJS.register(ArcElement, Tooltip, Legend);

class GSCReportAnonymoized extends Component {
    constructor() {
        super();
        this.query = query();
    }

    searchFunction(data) {
        return data;
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        const data = this.state.data[0] ?? {};

        const options = {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Keywords',
                    padding: {
                        top: 10,
                        bottom: 10,
                    },
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            return `${context.label} : ${numeral(context.parsed).format('0.00%')} `;
                        },
                    },
                },
            },
        };

        const keywordClicks = data.kwClicks / data.totalClicks;
        const anonymizedClicks = (data.totalClicks - data.kwClicks) / data.totalClicks;

        const chartArray = [
            { label: 'Keyword Clicks', value: keywordClicks },
            { label: 'Anonymized Clicks', value: anonymizedClicks },
        ];
        console.log('data', data);
        const tableData = !_.isEmpty(data)
            ? [
                  { domain: data.domain, type: 'Total', clicks: data.totalClicks, percent: 1 },
                  {
                      domain: data.domain,
                      type: 'GSC Keywords',
                      clicks: data.kwClicks,
                      percent: keywordClicks,
                  },
                  {
                      domain: data.domain,
                      type: 'Anonymized Keywords',
                      clicks: data.totalClicks - data.kwClicks,
                      percent: anonymizedClicks,
                  },
              ]
            : [];

        const chartData = {
            labels: chartArray.map((item) => item.label),
            datasets: [
                {
                    label: 'Clicks',
                    data: chartArray.map((item) => item.value),
                    backgroundColor: chartArray.map(
                        (item, i) => labelColors[i] ?? getRandomColor(),
                    ),
                    borderRadius: 4,
                    tension: 0.4,
                },
            ],
        };

        const sortFns = {
            type: (array) => array.sort((a, b) => a.type.localeCompare(b.type)),
            domain: (array) => array.sort((a, b) => a.domain.localeCompare(b.domain)),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            percent: (array) => array.sort((a, b) => a.percent - b.percent),
        };

        if (tableData?.length === 0)
            return (
                <div>
                    <h3 className="font-weight-bold">Anonymized Keywords</h3>
                    {noDataMessage}
                </div>
            );
        return (
            <CheckData
                content_id={this.props.content_id}
                parent_id={this.props.parent_id}
                data={this.state.data}
                loading={this.state.loading}
            >
                <div style={{ height: '100%', minHeight: '150px', position: 'relative' }}>
                    <h3 className="font-weight-bold">Anonymized Keywords</h3>
                    <div className="row">
                        <div className="col-6" style={{ height: '400px', position: 'relative' }}>
                            <Pie data={chartData} options={options} />
                        </div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col">
                                    <DataTable
                                        nodes={tableData}
                                        sortFns={sortFns}
                                        COLUMNS={COLUMNS}
                                        uniqKey={'type'}
                                        fileName="Brandes vs Non-branded"
                                        searchFunction={this.searchFunction}
                                        forceRun={this.forceRun}
                                        onlyTable
                                        additionalStyles={{
                                            Table: `
                                                --data-table-library_grid-template-columns :  minmax(120px, 1.5fr) minmax(120px, 1.5fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                                height: auto !important;
                                            `,
                                            BaseCell: `  font-family: 'Raleway';
                                                        font-weight: 500;
                                                        font-size: 16px;
                                                        line-height: 19px;
                                                        padding: 16px 5px !important;
                                                        color: #0D182C;
                                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                        &.underline {
                                                            text-decoration: underline;
                                                        }
                                                        `,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CheckData>
        );
    }
}

export default GSCReportAnonymoized;
