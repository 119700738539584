import { Component } from 'react';
import Loading from '../../../components/Loading';
import { setDeviceQuery } from './queries';

class BrandedTerms extends Component {
    constructor(props) {
        super();

        this.updateInput = this.updateInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.activeDomain = props.domains?.find((item) => item.status);

        this.state = {
            branded_terms: '',
            isLoadingBrands: false,
        };
    }

    onSubmit(e, value) {
        e.preventDefault();

        const params = {
            domain_url: this.activeDomain?.url,
            branded_terms: value ?? this.state.branded_terms,
        };

        this.setState({ isLoadingBrands: true });

        setDeviceQuery(params, this.props.csrf_token)
            .then((res) => res.json())
            .then(() => {
                this.setState({ message: 'Brand added successfully.', message_tags: 'success' });
            })
            .catch((err) =>
                this.setState({
                    message: err.message ?? 'Something went wrong.',
                    message_tags: 'error',
                }),
            )
            .finally(() => {
                this.setState({ isLoadingBrands: false });
            });
    }

    updateInput(e) {
        this.setState({ branded_terms: e.target.value });
    }

    render() {
        return (
            <>
                <div id="onboarding-general-settings">
                    <div className="onboarding-subheader">
                        What are your branded terms? (e.g., your domain name, brand name, etc.)
                    </div>
                    {this.activeDomain ? (
                        <div
                            className="Timeline"
                            style={{ height: '100%', minHeight: '150px', position: 'relative' }}
                        >
                            <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                                Enter your branded terms - comma separated, no spaces - to view
                                report
                            </small>
                            <form onSubmit={this.onSubmit}>
                                <div>
                                    <div className="row mr-10 align-items-center">
                                        <div className="col-3">{this.activeDomain?.url}</div>
                                        <div className="col-9 text-field">
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="form-label"
                                            >
                                                Branded Terms
                                            </label>
                                            <input
                                                name="branded_terms"
                                                className="form-control"
                                                onChange={this.updateInput}
                                                value={this.state.branded_terms ?? ''}
                                            />
                                        </div>
                                    </div>
                                    <button
                                        className="button button-outline button-primary width-sm btn d-flex justify-content-center"
                                        disabled={this.state.isLoadingBrands}
                                    >
                                        Save
                                        {this.state.isLoadingBrands && <Loading onlyPreloader />}
                                    </button>
                                </div>
                            </form>
                        </div>
                    ) : (
                        'You need to activate the domain.'
                    )}
                    {this.state.message && (
                        <div className={`onboarding-message-${this.state.message_tags}`}>
                            {this.state.message}
                        </div>
                    )}
                    <div className="d-flex justify-content-end">
                        <button
                            className="button width-sm btn onboarding-btn-next d-flex justify-content-center onboarding-skip-btn"
                            style={{ lineHeight: 'unset' }}
                            onClick={() => this.props.handleBack()}
                        >
                            back
                        </button>
                        <button
                            className="button  button-filled button-primary width-sm btn onboarding-btn-next d-flex justify-content-center"
                            onClick={() => this.props.handleNext()}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

export default BrandedTerms;
