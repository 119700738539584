import React from 'react';

import Component from '../../../components/Component';
import Loading from '../../../components/Loading';
import PositionModule1 from './module1';
import PositionModule2 from './module2';
import { query } from './query';

class GSCReportCtrPerPosition extends Component {
    constructor(props) {
        super();
        this.branded_terms = JSON.parse(props.branded_terms);
        this.query = query({ brandedTerms: this.branded_terms });
    }

    render() {
        return (
            <div id="position-distribution-page">
                <div className="row card-row d-flex" id="number-of-keywords">
                    <div className="col-12">
                        <div className="card table-main rounded-main overflow-hidden">
                            <div className="card-header">
                                <div className="card-title text-dark fw-bold">
                                    CTR per Position (NON-BRANDED TERMS ONLY)
                                </div>
                            </div>
                            <div className="card-body">
                                {this.state.loading ? (
                                    <Loading />
                                ) : (
                                    <PositionModule1
                                        content_id="number-of-keywords"
                                        parent_id="position-distribution-page"
                                        data={this.state.data}
                                        error={this.state.error}
                                        forceRun={this.forceRun}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row card-row d-flex" id="number-of-keywords-traffic">
                    <div className="col-12">
                        <div className="card table-main rounded-main overflow-hidden">
                            <div className="card-header">
                                <div className="card-title text-dark fw-bold">
                                    Keywords Share per position
                                </div>
                            </div>
                            <div className="card-body">
                                <PositionModule2
                                    data={this.state.data}
                                    content_id="number-of-keywords-traffic"
                                    parent_id="position-distribution-page"
                                    loading={this.state.loading}
                                    forceRun={this.forceRun}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GSCReportCtrPerPosition;
