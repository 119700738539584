import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
export const COLUMNS = [
    {
        label: 'Category',
        key: 'name',
        data: 'name',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return data ?? item?.label;
        },
    },
    {
        label: 'Relevance',
        key: 'confidence',
        data: 'confidence',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            const value = data ?? item?.score;
            if (csvDownload) return value;
            return numeral(value).format('0.00');
        },
    },
];
