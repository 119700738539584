import React from 'react';
import debounce from '../../lib/debounce';
import Loading from '../../components/Loading';
import ChoicesDates, { WITH_TODAY_CHOICES } from './Choices/Dates';
import FilterOptions from '../../components/FilterOptions';

class Filters extends React.Component {
    constructor() {
        super();

        this.state = window.filters.get();

        this.updateFilter = this.updateFilter.bind(this);

        window.filters.bind(this.setState.bind(this));
        this.updateFilter = debounce(this.updateFilter.bind(this), 500);
    }

    updateFilter(field, choice) {
        window.filters.update(choice);
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }
        const isTool = window.location.pathname.split('/')[1] === 'tools';

        const choices = isTool ? WITH_TODAY_CHOICES : ChoicesDates;

        return (
            <>
                <FilterOptions
                    name="dates"
                    choices={choices}
                    default={this.state.dates}
                    onChange={this.updateFilter}
                    gsc_report={this.props.gsc_report}
                    show_all={this.props.show_all}
                    dont_show_date_filter={this.props.dont_show_date_filter}
                />
            </>
        );
    }
}

export default Filters;
