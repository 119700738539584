import GSCReportKeywordDetailTable from '../GSCReportKeywordDetailTable';
import GSCReportDirectoryUrls from '../GSCReportDirectoryDetail/Urls';
import GSCReportUrlDetailTable from '../GSCReportUrlDetailTable';
import Component from '../../../components/Component';
import GSCReportKeywords from '../GSCReportKeywords';
import { getWeeks } from '../../../helpers/weeks';
import Loading from '../../../components/Loading';
import GSCReportUrls from '../GSCReportUrls';
import query from './query';
import _ from 'lodash';

class GSCContainerWithWeeks extends Component {
    constructor() {
        super();
        this.query = query;
    }

    getPriorAndLastWeeks() {
        if (this.state.data && !_.isEmpty(this.state.data)) {
            return {
                last_week: this.state.data[0]?.week,
                prior_week: this.state.data.at(-1)?.week,
            };
        }
        return getWeeks();
    }

    render() {
        if (this.state.loading) return <Loading padding={10} />;

        const { last_week, prior_week } = this.getPriorAndLastWeeks();
        return (
            <>
                {this.props.component_type === 'keywords' && (
                    <GSCReportKeywords
                        content_id={this.props.content_id}
                        parent_id={this.props.parent_id}
                        last_week={last_week}
                        prior_week={prior_week}
                        current_url={this.props.current_url}
                        csrf_token={this.props.csrf_token}
                    />
                )}
                {this.props.component_type === 'keywords-detail' && (
                    <GSCReportKeywordDetailTable
                        content_id={this.props.content_id}
                        parent_id={this.props.parent_id}
                        keyword={this.props.keyword}
                        last_week={last_week}
                        prior_week={prior_week}
                        current_url={this.props.current_url}
                        csrf_token={this.props.csrf_token}
                    />
                )}
                {this.props.component_type === 'urls' && (
                    <GSCReportUrls
                        content_id={this.props.content_id}
                        parent_id={this.props.parent_id}
                        last_week={last_week}
                        prior_week={prior_week}
                        current_url={this.props.current_url}
                        csrf_token={this.props.csrf_token}
                    />
                )}
                {this.props.component_type === 'url-detail' && (
                    <GSCReportUrlDetailTable
                        content_id={this.props.content_id}
                        parent_id={this.props.parent_id}
                        last_week={last_week}
                        prior_week={prior_week}
                        url={this.props.url}
                        current_url={this.props.current_url}
                        csrf_token={this.props.csrf_token}
                    />
                )}
                {this.props.component_type === 'directory_urls' && (
                    <GSCReportDirectoryUrls
                        content_id={this.props.content_id}
                        parent_id={this.props.parent_id}
                        last_week={last_week}
                        prior_week={prior_week}
                        directory={this.props.directory}
                        subdomain={this.props.subdomain}
                        current_url={this.props.current_url}
                        csrf_token={this.props.csrf_token}
                    />
                )}
            </>
        );
    }
}

export default GSCContainerWithWeeks;
