export default function onResize(fnStart, fnEnd) {
    var resizeTimer;
    var s = 0;

    window.addEventListener('resize', () => {
        if (s == 0) {
            s++;
            fnEnd && fnStart();
        }

        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
            s = 0;
            (fnEnd && fnEnd()) || fnStart();
        }, 250);
    });
}
