import { Component, Fragment } from 'react';
import Table from './table';
import ContentGapsForm from './form';
import { removeHttpHttps } from '../../../../../helpers/removeHttpHttps';
import ReportExplainerContentGaps from '../../../../../components/ReportExplainer/specificOnes/commerce-content-gaps';

class ContentGaps extends Component {
    constructor(props) {
        super();
        this.onApply = this.onApply.bind(this);

        this.url = new URL(window.location);
        this.comp_domains = this.url.searchParams.get('comp_domains');

        this.state = {
            own_domain: props.own_domain ?? '',
            competitors: props.competitors ?? this.comp_domains?.split(',') ?? [],
        };

        const breadcrumbBox = document.getElementById('breadcrumb-box');
        breadcrumbBox.innerHTML = `<a class="text-primary domain-name" aria-current="page" href="/commerce/">Commerce</a><i class="icon-arrow-right"></i> <a href="#" >Project: ${props.keyword} </a> <i class="icon-arrow-right"></i> <a href="#" >Content Gaps</a>`;
    }

    onApply({ own_domain, competitors }) {
        this.setState({
            own_domain: removeHttpHttps(own_domain),
            competitors: removeHttpHttps(competitors),
        });
        this.props.updateDomains({
            own_domain: removeHttpHttps(own_domain),
            competitors: removeHttpHttps(competitors),
        });
    }

    render() {
        return (
            <>
                <div className="card rounded-main">
                    <div className="card-body">
                        <ReportExplainerContentGaps />
                    </div>
                </div>
                <ContentGapsForm
                    own_domain={this.state.own_domain}
                    competitors={this.state.competitors}
                    keyword={this.props.keyword}
                    onApply={this.onApply}
                    project_id={this.props.project_id}
                    updateDatas={this.props.updateDatas}
                    domains_for_select={this.props.domains_for_select}
                />
                {(this.props.data || this.state.own_domain) && (
                    <Table
                        own_domain={this.state.own_domain}
                        competitors={this.state.competitors}
                        project_id={this.props.project_id}
                        keyword={this.props.keyword}
                        updateDatas={this.props.updateDatas}
                        updateDomains={this.props.updateDomains}
                        data={this.props.data}
                    />
                )}
            </>
        );
    }
}

export default ContentGaps;
