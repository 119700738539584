import { Component } from 'react';
import { tags } from './keys';
import TopPages from '../TopPages';
import EntityTopAuthors from '../EntityTopAuthors';

class EntityContentContainer extends Component {
    constructor() {
        super();
        this.onClick = this.onClick.bind(this);
        this.addActive = this.addActive.bind(this);
        this.showElement = this.showElement.bind(this);

        this.state = {
            types: ['pages'],
            alreadeOpenedAuthors: false,
        };
    }

    showElement(id) {
        return !this.state.types.length || this.state.types.includes(id);
    }

    onClick(id) {
        if (id === 'authors') {
            return this.setState({
                types: [id],
                alreadeOpenedAuthors: true,
            });
        }
        return this.setState({
            types: [id],
        });
    }

    addActive(id) {
        return this.state.types.includes(id);
    }

    render() {
        return (
            <>
                <div className="tags-container">
                    {tags.map((tag) => (
                        <span
                            className={`tab-item ${this.addActive(tag.id) && 'active-tag'}`}
                            key={tag.id}
                            onClick={() => this.onClick(tag.id)}
                        >
                            {tag.label}
                        </span>
                    ))}
                </div>
                <div
                    className="row card-row"
                    id="entity_pages"
                    style={{
                        display: this.showElement('pages') ? 'flex' : 'none',
                    }}
                >
                    <div className="col-12">
                        <div className="card table-main rounded-main overflow-hidden">
                            <div className="card-header">
                                <div className="card-title text-dark fw-bold">Top Pages</div>
                                <small className="shown-date-under-title d-block card-sub-title text-muted fs-7 text-capitalize py-2"></small>
                            </div>
                            <div className="card-body">
                                <TopPages
                                    default_image={this.props.default_image}
                                    parent_id="entities_data"
                                    content_id="entity_pages"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.alreadeOpenedAuthors && (
                    <div
                        className="row card-row"
                        id="entity_pages"
                        style={{
                            display: this.showElement('authors') ? 'flex' : 'none',
                        }}
                    >
                        <div className="col-12">
                            <div className="card table-main rounded-main overflow-hidden">
                                <div className="card-header">
                                    <div className="card-title text-dark fw-bold">Top Authors</div>
                                    <small className="shown-date-under-title d-block card-sub-title text-muted fs-7 text-capitalize py-2"></small>
                                </div>
                                <div className="card-body">
                                    <EntityTopAuthors />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
export default EntityContentContainer;
