import CheckData from '../../../components/CatchError';
import Component from '../../../components/Component';
import Chart from './chart';
import Table from './table';
import query from './query';

class GSCReportSubdomainsDetail extends Component {
    constructor(props) {
        super();
        this.query = query(props.subdomain);
    }

    render() {
        return (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.data}
                    loading={this.state.loading}
                >
                    <div className="row card-row d-flex" id="directory-detail-chart">
                        <div className="col-12">
                            <div className="card rounded-main overflow-hidden">
                                <div className="card-body">
                                    <Chart data={this.state.data} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row card-row d-flex" id="directory-detail-table">
                        <div className="col-12">
                            <div className="card rounded-main overflow-hidden " id="domain-summery">
                                <div className="card-body">
                                    <Table data={this.state.data} forceRun={this.forceRun} />
                                </div>
                            </div>
                        </div>
                    </div>
                </CheckData>
            </>
        );
    }
}

export default GSCReportSubdomainsDetail;
