import List from '../../components/List';
import LoadMoreComponent from '../../components/LoadMoreComponent';

import query from './query';
import colors from '../../lib/colors';
import keysFunction, { COLUMNS } from '../PageCard/keys';
import DataTable from '../../components/Datatable';
import { scrollForTable } from '../../components/Datatable/utils';
import Loading from '../../components/Loading';

class Summary extends LoadMoreComponent {
    constructor(props) {
        super();
        this.query = query({ page: props.page });
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) => item.title?.toLowerCase().includes(search.toLowerCase()));
    }

    render() {
        // if we get less than offsetStep it means we don't have more data

        const keys = keysFunction(this.props.default_image, this.props.page);
        const sortFns = {
            title: (array) => array.sort((a, b) => a.title.localeCompare(b.title)),
            headline: (array) => array.sort((a, b) => a.headline.localeCompare(b.headline)),
            author: (array) => array.sort((a, b) => a.author.localeCompare(b.author)),
            ogtitle: (array) => array.sort((a, b) => a.ogtitle.localeCompare(b.ogtitle)),
            schema: (array) => array.sort((a, b) => a.schema.localeCompare(b.schema)),
            words: (array) => array.sort((a, b) => a.words - b.words),
            firstSeen: (array) =>
                array.sort((a, b) => new Date(a.firstSeen) - new Date(b.firstSeen)),
            modified: (array) => array.sort((a, b) => new Date(a.modified) - new Date(b.modified)),
            published: (array) =>
                array.sort((a, b) => new Date(a.published) - new Date(b.published)),
        };
        return (
            <>
                <div className="card rounded-main overflow-hidden">
                    <div className="card-body secondary">
                        {this.state.loading ? (
                            <Loading />
                        ) : (
                            <List
                                className="table-main"
                                loading={this.state.loading}
                                data={this.state.allData.length ? [this.state.allData[0]] : []}
                                parent_id="page-content"
                                content_id="page-card"
                                keys={keys}
                                forceRun={this.forceRun}
                                color={colors[1]}
                                header={false}
                            />
                        )}
                    </div>
                </div>
                <div className="card rounded-main table-main overflow-hidden">
                    <div className="card-header">
                        <p className="card-title">Article History</p>
                    </div>
                    <div className="card-body secondary">
                        {this.state.loading ? (
                            <Loading />
                        ) : (
                            <DataTable
                                nodes={this.state.allData}
                                sortFns={sortFns}
                                COLUMNS={COLUMNS()}
                                fileName="PageCard"
                                forceRun={this.forceRun}
                                searchFunction={this.searchFunction}
                                additionalStyles={{
                                    Table: `
                                        --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(150px, 1.5fr) minmax(150px, 1.5fr) minmax(120px, 1fr) minmax(120px, 1fr)  minmax(120px, 1fr)  minmax(120px, 1fr)  minmax(120px, 1fr)  minmax(120px, 1fr)  !important;
                                        ${scrollForTable}
                                    `,
                                    BaseCell: `  font-family: 'Raleway';
                                                font-weight: 500;
                                                font-size: 16px;
                                                line-height: 19px;
                                                padding: 16px 5px !important;
                                                color: #0D182C;
                                                border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                            
                                                &.underline {
                                                    text-decoration: underline;
                                                }
                                                `,
                                }}
                            />
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default Summary;
