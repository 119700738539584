import Component from '../../components/Component';
import Loading from '../../components/Loading';
import Bubble from '../../components/Bubble';
import keys from './keys';
import numeral from 'numeral';

class CategoriesBubbles extends Component {
    constructor() {
        super();
    }

    render() {
        if (this.props.loading) {
            return <Loading />;
        }

        // let data = topLevel(this.props.data);
        const firstLevels = this.props.data
            ?.filter((item) => item.level.split('/').length === 3)
            .map((item) => ({
                name: item.level.split('/')[1],
                ctr: numeral(item.sum_clicks / item.sum_impressions).format('0,0%'),
                impressions: item.sum_impressions,
            }));

        const data = firstLevels.sort((a, b) => {
            return a.impressions > b.impressions ? -1 : 1;
        });

        return (
            <div style={{ width: '100%', position: 'relative', height: '300px' }}>
                <Bubble
                    data={data?.slice(0, 10)}
                    keys={keys}
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                />
            </div>
        );
    }
}

export default CategoriesBubbles;
