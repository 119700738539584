import Query from '../../components/Report';

const query = ({ page = '' }) =>
    new Query('discover-page-entity', {
        map: {
            entities: 'name',
        },
        page,
    });

export default query;
