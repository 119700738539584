import Loading from '../../../components/Loading';

import { query } from './query';
import { COLUMNS } from './keys';
import CheckData from '../../../components/CatchError';
import DataTable from '../../../components/Datatable';
import LoadMoreComponent from '../../../components/LoadMoreComponent';
import { scrollForTable } from '../../../components/Datatable/utils';

class GSCReportKeywordClustersUrlsDetailTable extends LoadMoreComponent {
    constructor(props) {
        super();
        this.query = query({ offset: 0, cluster: props.cluster });
        this.offsetStep = 1000;
    }

    searchFunction(data, search) {
        return data.filter((item) => item?.url?.toLowerCase().includes(search.toLowerCase()));
    }

    render() {
        const attr = {
            query,
            offsetStep: this.offsetStep,
            cluster: this.props.cluster,
        };
        const sortFns = {
            url: (array) => array.sort((a, b) => a.url.localeCompare(b.url)),
            avgClicks: (array) => array.sort((a, b) => a.avgClicks - b.avgClicks),
            maxImpressions: (array) => array.sort((a, b) => a.maxImpressions - b.maxImpressions),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
            clusterKeywords: (array) => array.sort((a, b) => a.clusterKeywords - b.clusterKeywords),
            avgCTR: (array) =>
                array.sort(
                    (a, b) => a.avgClicks / a.maxImpressions - b.avgClicks / b.maxImpressions,
                ),
            maxClicks: (array) => array.sort((a, b) => a.maxClicks - b.maxClicks),
            weeks: (array) => array.sort((a, b) => a.weeks - b.weeks),
        };

        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        return (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.allData}
                    loading={this.state.loading}
                    loadMore={this.state.loadMore}
                >
                    <DataTable
                        nodes={this.state.allData}
                        sortFns={sortFns}
                        COLUMNS={COLUMNS}
                        fileName="URLs ranking for Cluster Keywords"
                        header="URLs ranking for Cluster Keywords"
                        searchFunction={this.searchFunction}
                        forceRun={this.forceRun}
                        defaultSortKey="clicks"
                        additionalStyles={{
                            Table: `
                                    --data-table-library_grid-template-columns : minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                   ${scrollForTable}
                                `,
                            BaseCell: ` font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                        }}
                    />
                </CheckData>
                {this.state.loadMore ? (
                    <Loading padding={10} />
                ) : (
                    showLoadMoreBtn && (
                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                            <button
                                onClick={() => this.loadMore(attr)}
                                disabled={this.state.loading}
                                className="load-more-btn"
                            >
                                Load More
                            </button>
                        </div>
                    )
                )}
            </>
        );
    }
}

export default GSCReportKeywordClustersUrlsDetailTable;
