import React from 'react';

export default function TextField({
    className,
    label,
    placeholder = '',
    type = 'text',
    value = '',
    onChange = () => {},
    min,
    max,
}) {
    return (
        <div className={`text-field mb-3 ${className && className}`}>
            {label && (
                <label htmlFor={label} className="form-label">
                    {label}
                </label>
            )}
            <input
                type={type}
                name={label}
                value={value}
                onChange={onChange}
                min={min}
                max={max}
                id={label}
                className="form-control"
                placeholder={placeholder}
            />
        </div>
    );
}
