import { Cell } from '@table-library/react-table-library/table';

export const COLUMNS = [
    {
        label: 'User',
        key: 'user',
        data: 'user',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <a
                    href={`/admin/customers/useractivity/?q=${encodeURIComponent(data)}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {data}
                </a>
            );
        },
    },
    {
        label: 'Domain',
        key: 'domain',
        data: 'domain',
        Component: Cell,
    },
    {
        label: 'Last access',
        key: 'created',
        data: 'created',
        Component: Cell,
    },
    {
        label: 'Pages',
        key: 'pages_count',
        data: 'pages_count',
        Component: Cell,
    },
    {
        label: 'Unique pages count',
        key: 'unique_pages_count',
        data: 'unique_pages_count',
        Component: Cell,
    },
    {
        label: 'Dates Count',
        key: 'days_count',
        data: 'days_count',
        Component: Cell,
    },
];
