import { Component } from 'react';

class ExtraButtons extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className="categories-view no-margin">
                <button
                    className={`categories-view-btn ${
                        (!this.props.view || this.props.view === 1) && 'categories-view-btn-active'
                    }`}
                    onClick={() => this.props.onChange(1)}
                >
                    View 1
                </button>
                <button
                    className={`categories-view-btn ${
                        this.props.view === 2 && 'categories-view-btn-active'
                    }`}
                    onClick={() => this.props.onChange(2)}
                >
                    View 2
                </button>
            </div>
        );
    }
}

export default ExtraButtons;
