import List from '../../components/List';
import Loading from '../../components/Loading';
import LoadMoreComponent from '../../components/LoadMoreComponent';

import query from './query';
import colors from '../../lib/colors';
import keysFunction from './keys';

class Summary extends LoadMoreComponent {
    constructor() {
        super();
        this.query = query({});

        this.loadMore = this.loadMore.bind(this);

        this.offsetStep = 100;
    }

    render() {
        // if we get less than offsetStep it means we don't have more data
        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const attr = {
            query,
            offsetStep: this.offsetStep,
        };

        const keys = keysFunction(this.props.default_image);

        return (
            <>
                <List
                    loading={this.state.loading}
                    loadMore={this.state.loadMore}
                    header={true}
                    className="extra-options"
                    data={this.state.allData}
                    label={'Top Pages'}
                    keys={keys}
                    color={colors[1]}
                    forceRun={this.forceRun}
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                />
                {this.state.loadMore ? (
                    <Loading padding={10} />
                ) : (
                    showLoadMoreBtn && (
                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                            <button
                                onClick={() => this.loadMore(attr)}
                                disabled={this.state.loading}
                                className="load-more-btn"
                            >
                                Load More
                            </button>
                        </div>
                    )
                )}
            </>
        );
    }
}

export default Summary;
