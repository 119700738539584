import React from 'react';
import Loading from '../../components/Loading';
import { deleteIgnoreItems } from './query';

class GSCDataSettingsIgnoredItems extends React.Component {
    constructor(props) {
        super();

        this.onDeleteItem = this.onDeleteItem.bind(this);

        this.state = {
            ignored_keywords_list: props.ignored_keywords_list
                ? JSON.parse(props.ignored_keywords_list)
                : [],
            ignored_urls_list: props.ignored_urls_list ? JSON.parse(props.ignored_urls_list) : [],
            loading_obj: {},
        };
    }

    onDeleteItem(data) {
        const params = {
            domain_url: data.domain_url,
            ignore_type: data.type,
            ignore_value: data.value,
            report_type: data.report_type,
        };
        this.setState({
            loading_obj: {
                ...this.state.loading_obj,
                [data.value]: true,
            },
        });
        deleteIgnoreItems(params, this.props.csrf_token)
            .then((res) => res.json())
            .then(() => {
                if (data.type === 'keyword') {
                    const ignored_keywords_list = this.state.ignored_keywords_list.map((x) => {
                        const ignored_keywords = x.ignored_keywords.filter(
                            (x) => x.keyword !== data.value,
                        );
                        return { ...x, ignored_keywords };
                    });

                    const filteredItems = ignored_keywords_list.filter(
                        (x) => !!x.ignored_keywords.length,
                    );
                    this.setState({
                        ignored_keywords_list: [...filteredItems],
                    });
                } else {
                    const ignored_urls_list = this.state.ignored_urls_list.map((x) => {
                        const ignored_urls = x.ignored_urls.filter((x) => x.url !== data.value);
                        return { ...x, ignored_urls };
                    });

                    const filteredItems = ignored_urls_list.filter((x) => !!x.ignored_urls.length);

                    this.setState({
                        ignored_urls_list: [...filteredItems],
                    });
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({
                    loading_obj: {
                        ...this.state.loading_obj,
                        [data.value]: false,
                    },
                });
            });
    }

    render() {
        return (
            <div>
                {!!this.state.ignored_keywords_list?.length && (
                    <div className="row card-rowtable-main">
                        <div className="col-12">
                            <div
                                className="card rounded-main overflow-hidden"
                                style={{ minHeight: '34vh' }}
                            >
                                <div className="my-3 card-body">
                                    <h4>Ignored Keywords</h4>
                                    {this.state.ignored_keywords_list.map((item) => {
                                        return (
                                            <div key={item.domain_url} className="mt-3">
                                                <h6>{item.domain_url}</h6>
                                                <div className="ignored-items">
                                                    {item.ignored_keywords.map((keyword_obj) => {
                                                        return (
                                                            <div
                                                                className="ignored-item"
                                                                key={keyword_obj.keyword}
                                                            >
                                                                {keyword_obj.keyword}
                                                                {this.state.loading_obj[
                                                                    keyword_obj.keyword
                                                                ] ? (
                                                                    <div
                                                                        style={{
                                                                            padding: '4px 10px ',
                                                                        }}
                                                                    >
                                                                        <Loading onlyPreloader />
                                                                    </div>
                                                                ) : (
                                                                    <button
                                                                        title="Delete from Ignored List"
                                                                        onClick={() => {
                                                                            this.onDeleteItem({
                                                                                domain_url:
                                                                                    item.domain_url,
                                                                                type: 'keyword',
                                                                                value: keyword_obj.keyword,
                                                                                report_type:
                                                                                    keyword_obj.report_type,
                                                                            });
                                                                        }}
                                                                    >
                                                                        <i className="icon-close"></i>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {!!this.state.ignored_urls_list?.length && (
                    <div className="row card-rowtable-main">
                        <div className="col-12">
                            <div
                                className="card rounded-main overflow-hidden"
                                style={{ minHeight: '34vh' }}
                            >
                                <div className="my-3 card-body">
                                    <h4>Ignored Urls</h4>
                                    {this.state.ignored_urls_list.map((item) => {
                                        return (
                                            <div key={item.domain_url} className="mt-3">
                                                <h6>{item.domain_url}</h6>
                                                <div className="ignored-items">
                                                    {item.ignored_urls.map((url_obj) => {
                                                        return (
                                                            <div
                                                                className="ignored-item"
                                                                key={url_obj.url}
                                                            >
                                                                {url_obj.url}
                                                                {this.state.loading_obj[
                                                                    url_obj.url
                                                                ] ? (
                                                                    <div
                                                                        style={{
                                                                            padding: '4px 10px ',
                                                                        }}
                                                                    >
                                                                        <Loading onlyPreloader />
                                                                    </div>
                                                                ) : (
                                                                    <button
                                                                        title="Delete from Ignored List"
                                                                        onClick={() => {
                                                                            this.onDeleteItem({
                                                                                domain_url:
                                                                                    item.domain_url,
                                                                                type: 'url',
                                                                                value: url_obj.url,
                                                                                report_type:
                                                                                    url_obj.report_type,
                                                                            });
                                                                        }}
                                                                    >
                                                                        <i className="icon-close"></i>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default GSCDataSettingsIgnoredItems;
