import React from 'react';
import { loadStripe } from '@stripe/stripe-js';

class Modal extends React.Component {
    constructor(props) {
        super();

        this.state = {
            active: props.active || false,
            cardError: '',
            data: {
                cardholder_name: props.data.cardholder_name ?? '',
            },
            card: {
                complete: false,
            },
        };

        this.next = this.next.bind(this);
    }

    async componentDidMount() {
        this.stripe = await loadStripe(window._config.stripe_public_key);
        this.elements = this.stripe.elements();
        this.card = this.elements.create('card');
        this.card.mount('#card');

        this.card.on('change', (v) => {
            this.setState({
                card: v,
            });
        });
    }

    toggleActive() {
        this.setState({
            active: !this.state.active,
        });
    }

    updateData(key, e) {
        const newState = { ...this.state };
        newState.data[key] = e.target.val || e.target.value;
        this.setState(newState);
    }

    async next(e) {
        e.preventDefault();
        const newState = { ...this.state };

        let cardToken = await this.stripe.createPaymentMethod({
            type: 'card',
            card: this.card,
            billing_details: {
                name: this.state.data.cardholder_name || this.props.data.name,
                email: this.props.data.email,
            },
        });
        //
        // let setupIntent = await this.stripe.confirmSetup({
        //   this.elements,
        //   confirmParams: {
        //     // Return URL where the customer should be redirected after the SetupIntent is confirmed.
        //     return_url: 'https://app.gddash.com/registration/confirm',
        //   },
        //   redirect: 'if_required'
        // })

        if (cardToken.error) {
            this.setState({ cardError: cardToken.error.message });
            return;
        }
        this.setState({ cardError: '' });

        newState.data.token = cardToken.paymentMethod.id;
        this.props.next && this.props.next(newState.data);
    }

    render() {
        let submit = [
            <button
                disabled={true}
                className="btn btn-lg text-white btn-primary "
                style={{ background: '#BBB', width: '47%' }}
                key={0}
            >
                Next Step
            </button>,
            <button
                href=""
                className={'btn btn-lg text-white btn-primary'}
                style={{ width: '47%' }}
                key={1}
            >
                Next Step
            </button>,
        ];

        return (
            <div className="registration info">
                <form onSubmit={this.next}>
                    <h3>Billing Info</h3>
                    <p>
                        After your trial completes, we&apos;ll automatically convert you to the paid
                        plan that reflects the number of pages your organization is collecting. You
                        can cancel at anytime.
                    </p>
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Billing Information
                        </label>
                        <div id="card" />
                        {this.state.cardError && (
                            <div className="error-message">{this.state.cardError}</div>
                        )}
                    </div>
                    <div className="text-field mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Cardholder Name (if different)
                        </label>
                        <input
                            name="cardholder_name"
                            className="form-control"
                            placeholder="John Snow"
                            aria-describedby="Your Name"
                            onChange={this.updateData.bind(this, 'cardholder_name')}
                            value={this.state.data.cardholder_name}
                        />
                    </div>
                    <div className="mb-3 d-flex justify-content-between">
                        <button
                            type="button"
                            className="btn btn-lg text-white btn-primary "
                            style={{ width: '47%' }}
                            onClick={() => this.props.previous(this.state.data.cardholder_name)}
                        >
                            Previous
                        </button>
                        {this.state.card.complete ? submit[1] : submit[0]}
                    </div>
                </form>
            </div>
        );
    }
}

export default Modal;
