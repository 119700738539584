import React from 'react';
import ReactModal from '../Modal';
import { getUserInfo } from '../../modules/CustomersDomainComponent/query';
import Loading from '../Loading';

class UserInfoModal extends React.Component {
    constructor() {
        super();
        this.state = {
            user: null,
            isLoading: true,
        };
    }

    async componentDidMount() {
        try {
            this.setState({
                isLoading: true,
            });
            const response = await getUserInfo(this.props.current_url, this.props.csrf_token, {
                user_mail: this.props.selectedUser,
            });
            const data = await response.json();
            this.setState({
                user: data?.user,
                isLoading: false,
            });
        } catch (err) {
            this.setState({
                isLoading: false,
            });
            console.log(err);
        }
    }

    render() {
        const { user } = this.state;
        return (
            <>
                <ReactModal
                    modalState={this.props.isOpen}
                    className="width-sm"
                    onClose={this.props.onClose}
                >
                    {this.state.isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <h2>User Info</h2>
                            {user ? (
                                <>
                                    <div>
                                        <strong>Full name -</strong> {user.first_name}{' '}
                                        {user.last_name} , {user.is_active ? 'Active' : 'Inactive'}
                                    </div>
                                    <div>
                                        <strong>Email -</strong> {user.email}
                                    </div>
                                    <div>
                                        <strong>Plan Name -</strong> {user.plan_name} ,{' '}
                                        {user.plan_period}
                                    </div>
                                    <div>
                                        <strong>Subscription status -</strong>{' '}
                                        {user.subscription_status}
                                    </div>
                                    {this.props.hasImportKeywords && (
                                        <div>
                                            <strong>Import Keywords -</strong>{' '}
                                            {this.props.selectedUserImportKeywords
                                                ? 'Active'
                                                : 'Not Active'}
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div>No Data</div>
                            )}
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button
                                    type="button"
                                    className="button button-outline button-primary width-sm btn"
                                    onClick={this.props.onClose}
                                    style={{ lineHeight: 'unset' }}
                                >
                                    Close
                                </button>
                            </div>
                        </>
                    )}
                </ReactModal>
            </>
        );
    }
}

export default UserInfoModal;
