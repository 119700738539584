import { COLUMNS } from './keys';
import { query } from './query';
import CheckData from '../../../components/CatchError';
import Loading from '../../../components/Loading';
import DataTable from '../../../components/Datatable';
import {
    addItem,
    addItemIndeletedList,
    getDeletedDomainsWithIds,
} from '../../../helpers/clusterActions';
import { Component } from 'react';
import { scrollForTable } from '../../../components/Datatable/utils';
import KeywordsModal from '../../../components/Modals/KeywordsModal';
import { getClustersQuery } from '../../../components/manualClusterModal/query';

class GSCReportsManualKeywordCluster extends Component {
    constructor(props) {
        super();
        this.deleted_clusters = props.deleted_clusters;
        this.deleted_keywords = props.deleted_keywords;
        this.deleted_gsc_keyword_dict_with_cluster = getDeletedDomainsWithIds(
            JSON.parse(props.deleted_gsc_keyword_dict_with_cluster),
        );
        this.query = query({
            user_domain_id: props.user_domain_id,
            deleted_clusters: this.deleted_clusters,
            deleted_keywords: this.deleted_keywords,
            deleted_gsc_keyword_dict_with_cluster: this.deleted_gsc_keyword_dict_with_cluster,
        });

        this.clusterNames = props.cluster_id_name_list
            ? JSON.parse(props.cluster_id_name_list)
            : [];
        this.updateClusterName = this.updateClusterName.bind(this);
        this.deleteCluster = this.deleteCluster.bind(this);
        this.deleteItem = this.deleteItem.bind(this);

        this.forceRun = this.forceRun.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onSaveKeywordsList = this.onSaveKeywordsList.bind(this);
        this.getClusters = this.getClusters.bind(this);
        this.state = {
            isLoadingClusters: false,
            loading: true,
            clusters: [],
        };
    }

    getClusters() {
        this.setState({ isLoadingClusters: true });
        const data = {
            domain_url: window.filters.get().domain.value,
            cluster_type: 'keyword',
        };
        getClustersQuery(data, this.props.csrf_token)
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 200) {
                    const items = data.clusters;
                    this.setState({ clusters: items });
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ isLoadingClusters: false });
            });
    }

    async componentDidMount() {
        if (this.query) {
            this.query.bind(this.setState.bind(this));
            await this.query.update({ force: true });
        }
        this.getClusters();
    }

    forceRun() {
        if (this.query) this.query.update({ force: true, alreadyInQueue: true });
    }

    searchFunction(data, search) {
        return data.filter((item) =>
            item.cluster_name?.toLowerCase().includes(search.toLowerCase()),
        );
    }

    updateClusterName(cluster_id, newName) {
        const updatedData = this.state.data.map((item) => {
            if (item.cluster_id === cluster_id) return { ...item, cluster_name: newName };
            return { ...item };
        });
        const clusterNames = this.clusterNames.map((item) => {
            if (item.id === cluster_id) return { ...item, name: newName };
            return { ...item };
        });
        this.setState({ data: updatedData, clusterNames });
        this.query.update({ force: true, dontUpdateExistingData: true });
    }

    deleteCluster(cluster) {
        const updatedData = this.state.data.filter((item) => item.cluster_name !== cluster);
        this.setState({ data: updatedData });
        this.deleted_clusters = addItem(this.deleted_clusters, cluster);
        this.query = query({
            user_domain_id: this.props.user_domain_id,
            deleted_clusters: this.deleted_clusters,
            deleted_gsc_keyword_dict_with_cluster: this.deleted_gsc_keyword_dict_with_cluster,
        });
    }

    deleteItem(keyword, cluster_id) {
        this.deleted_gsc_keyword_dict_with_cluster = addItemIndeletedList(
            this.deleted_gsc_keyword_dict_with_cluster,
            cluster_id,
            keyword,
        );
        this.query = query({
            user_domain_id: this.props.user_domain_id,
            deleted_clusters: this.deleted_clusters,
            deleted_gsc_keyword_dict_with_cluster: this.deleted_gsc_keyword_dict_with_cluster,
        });

        const clusterNames = this.clusterNames.map((item) => {
            if (item.id === cluster_id) return { ...item, keyword_count: item.keyword_count - 1 };
            return { ...item };
        });

        this.query.bind(this.setState.bind(this));
        this.query.update({ force: true, dontUpdateExistingData: true, updateOnlyData: true });
        this.setState({ clusterNames });
    }

    onCloseModal() {
        this.setState({
            isOpenModal: false,
        });
    }

    onOpenModal() {
        this.setState({
            isOpenModal: true,
        });
    }

    onSaveKeywordsList() {
        this.setState({
            loading: true,
        });
        window.location.reload();
        this.onCloseModal();
    }

    render() {
        const data = this.state.data?.length ? this.state.data : this.props.data;
        if (((!data || !data.length) && this.state.loading) || this.state.isLoadingClusters)
            return <Loading />;
        const clusterData =
            this.state?.clusters?.map((item) => {
                if (data?.find((state) => item.id === state.cluster_id)) {
                    return {
                        ...this.state,
                        cluster_name: item.name,
                    };
                }
                return { cluster_name: item?.name, cluster_id: item?.id };
            }) ?? [];
        const itemsOnlyInBigQuery =
            data?.filter((state) =>
                this.state?.clusters?.find((item) => item.id === state.cluster_id),
            ) ?? [];
        const allData = [...clusterData, ...itemsOnlyInBigQuery];
        const sortFns = {
            cluster_name: (array) =>
                array.sort((a, b) => a.cluster_name.localeCompare(b.cluster_name)),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            cluster_keywords: (array) =>
                array.sort((a, b) => {
                    const clusterNames = this.state.clusterNames ?? this.clusterNames;
                    const firstCluster = clusterNames.find((state) => state.id === a.cluster_id);
                    const secondCluster = clusterNames.find((state) => state.id === b.cluster_id);
                    return firstCluster?.keyword_count - secondCluster?.keyword_count;
                }),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            weekly_keywords: (array) => array.sort((a, b) => a.weekly_keywords - b.weekly_keywords),
            weekly_urls: (array) => array.sort((a, b) => a.weekly_urls - b.weekly_urls),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
            lifespan: (array) => array.sort((a, b) => a.lifespan - b.lifespan),
            avgCTR: (array) => array.sort((a, b) => a.avgCTR - b.avgCTR),
        };

        return (
            <>
                <div className="d-flex justify-content-end mb-10">
                    <button
                        className="button button-primary button-filled width-sm btn"
                        onClick={this.onOpenModal}
                    >
                        Add Keywords
                    </button>
                </div>
                <div id="domain-page">
                    <div className="row card-row d-flex" id="summary">
                        <div className="col-12">
                            <div
                                className="card rounded-main table-main overflow-hidden "
                                id="domain-summery"
                            >
                                <div className="card-header ">
                                    <p className="card-title">MANUAL KEYWORD LISTS</p>
                                </div>
                                <div className="card-body">
                                    <CheckData
                                        content_id="summary"
                                        parent_id="domain-page"
                                        // data={this.state.data}
                                        data={allData}
                                        loading={this.state.loading}
                                    >
                                        <DataTable
                                            // nodes={data}
                                            nodes={allData}
                                            sortFns={sortFns}
                                            COLUMNS={COLUMNS(
                                                this.props.csrf_token,
                                                this.props.endpoint_url,
                                                this.updateClusterName,
                                                this.deleteCluster,
                                                this.deleteItem,
                                                this.state.clusterNames ?? this.clusterNames,
                                            )}
                                            uniqKey={'cluster_id'}
                                            fileName="Manual Kewords Lists"
                                            searchFunction={this.searchFunction}
                                            forceRun={this.forceRun}
                                            onlyTable
                                            defaultSortKey={'clicks'}
                                            onlyPagination
                                            additionalStyles={{
                                                Table: `
                                                    --data-table-library_grid-template-columns: minmax(120px, 1fr)  minmax(120px, 1fr) minmax(120px, 1fr) minmax(150px, 1fr) minmax(140px, 1fr) minmax(140px, 1fr) minmax(150px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                                ${scrollForTable}
                                                `,
                                                BaseCell: ` font-family: 'Raleway';
                                                            font-weight: 500;
                                                            font-size: 16px;
                                                            line-height: 19px;
                                                            padding: 16px 5px;
                                                            color: #0D182C;
                                                            border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                        
                                                            &.underline {
                                                            text-decoration: underline;
                                                            }`,
                                            }}
                                        />
                                    </CheckData>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isOpenModal && (
                    <KeywordsModal
                        isOpen={this.state.isOpenModal}
                        onClose={this.onCloseModal}
                        current_url={this.props.endpoint_url}
                        csrf_token={this.props.csrf_token}
                        cluster_type="keyword"
                        header="Add To Keywords List"
                        onSaveKeywordsList={this.onSaveKeywordsList}
                    />
                )}
            </>
        );
    }
}

export default GSCReportsManualKeywordCluster;
