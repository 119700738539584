import { Component } from 'react';

/*
    Message types

    info -  alert-info
    danger - alert-danger
    success - alert-success
    warning - alert-warning


*/

class Message extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div
                className={`custom-message alert ${
                    this.props.message_type ?? 'alert-danger'
                } alert-dismissible fade show  mb-3`}
                role="alert"
            >
                {!this.props.hideCloseBtn && (
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                    ></button>
                )}
                <div>
                    {this.props.header &&
                        (this.props.isHeaderSeperated ? (
                            <div>
                                <strong>{this.props.header}</strong>
                            </div>
                        ) : (
                            <strong>{this.props.header}:</strong>
                        ))}{' '}
                    {this.props.message}
                </div>
                {this.props.link && (
                    <div className="ml-10 d-flex align-items-center">
                        <a href={this.props.link}>{this.props.link_name}</a>
                    </div>
                )}
            </div>
        );
    }
}

export default Message;
