import LoadMoreComponent from '../../components/LoadMoreComponent';

import query, { getAllQuery } from './query';
import { COLUMNS, FIELD_FOR_DOWNLOADS } from './keys';
import CheckData from '../../components/CatchError';
import DataTable from '../../components/Datatable';
import { scrollForTable } from '../../components/Datatable/utils';
import Loading from '../../components/Loading';
import AuthorsModal from '../../components/Modals/AuthorsModal/AuthorsModal';
import { downloadAllCsv } from '../../helpers/downloadAll';

class Summary extends LoadMoreComponent {
    constructor(props) {
        super();
        this.query = query({
            offset: 0,
            author: props.author,
        });

        this.onOpen = this.onOpen.bind(this);
        this.exportAll = this.exportAll.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.offsetStep = 50;
    }

    searchFunction(data, search) {
        return data.filter((item) => item.page?.toLowerCase().includes(search.toLowerCase()));
    }

    onCloseModal() {
        this.setState({
            isOpen: false,
            image: null,
        });
    }

    onOpen(item) {
        this.setState({
            isOpen: true,
            selectedItem: item,
        });
    }

    async exportAll() {
        try {
            this.setState({
                exportAllLoading: true,
            });
            const allQuery = getAllQuery({ author: this.props.author });
            const response = await allQuery.update();
            downloadAllCsv(response, 'Pages', [
                ...COLUMNS(this.props.default_image),
                ...FIELD_FOR_DOWNLOADS,
            ]);
            this.setState({
                exportAllLoading: false,
            });
        } catch (err) {
            this.setState({
                exportAllLoading: false,
            });
            console.log(err);
        }
    }

    render() {
        const attr = {
            query,
            offsetStep: this.offsetStep,
            author: this.props.author,
        };
        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const sortFns = {
            page: (array) => array.sort((a, b) => a.page.localeCompare(b.page)),
            lifespan: (array) => array.sort((a, b) => a.lifespan - b.lifespan),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            ctr: (array) => array.sort((a, b) => a.ctr - b.ctr),
            uniques: (array) => array.sort((a, b) => a.uniques - b.uniques),
        };
        return (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.data}
                    loading={this.state.loading}
                    loadMore={this.state.loadMore}
                >
                    <DataTable
                        nodes={this.state.allData}
                        sortFns={sortFns}
                        COLUMNS={COLUMNS(this.props.default_image, this.onOpen)}
                        fileName="Top Pages"
                        forceRun={this.forceRun}
                        searchFunction={this.searchFunction}
                        customColumnsForExport={[
                            ...COLUMNS(this.props.default_image),
                            ...FIELD_FOR_DOWNLOADS,
                        ]}
                        additionalStyles={{
                            Table: `
                                --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                ${scrollForTable}
                            `,
                            BaseCell: `  font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                        }}
                    />
                </CheckData>
                {this.state.loadMore ? (
                    <Loading padding={10} />
                ) : (
                    <div className="d-flex align-items-center justify-content-center pt-3 load-more-section mr-10">
                        {showLoadMoreBtn && (
                            <button
                                onClick={() => this.loadMore(attr)}
                                disabled={this.state.loading}
                                className="load-more-btn mr-10"
                            >
                                Load More
                            </button>
                        )}
                        {this.state.exportAllLoading ? (
                            <Loading padding={10} />
                        ) : (
                            <button
                                onClick={this.exportAll}
                                disabled={this.state.loading}
                                className="load-more-btn"
                            >
                                Export All
                            </button>
                        )}
                    </div>
                )}
                {this.state.selectedItem && (
                    <AuthorsModal
                        isOpen={this.state.isOpen}
                        onClose={this.onCloseModal}
                        item={this.state.selectedItem}
                    />
                )}
            </>
        );
    }
}

export default Summary;
