import { Component } from 'react';
import Loading from '../../../components/Loading';
import { setIgnoreUrl } from './query';

class IgnoreButton extends Component {
    constructor() {
        super();

        this.onIgnoreUrls = this.onIgnoreUrls.bind(this);

        this.state = {
            loading: false,
        };
    }

    onIgnoreUrls() {
        const params = {
            domain_url: window.filters.get().domain.value,
            ignore_type: 'url',
            ignore_value: this.props.url,
            report_type: 'evergreen',
        };
        this.setState({ loading: true });

        setIgnoreUrl(this.props.endpoint_url, params, this.props.csrf_token)
            .then((res) => res.json())
            .then(() => {
                this.props.deleteIgnoredItem(this.props.url);
            })
            .catch((err) => console.log(err))
            .finally(() => this.setState({ loading: false }));
    }

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <Loading onlyPreloader padding={10} />
                ) : (
                    <button
                        className="ignor-icon-btn"
                        onClick={this.onIgnoreUrls}
                        title="Ignore Url"
                    >
                        <i className="icon-ignor"></i>
                    </button>
                )}
            </div>
        );
    }
}

export default IgnoreButton;
