export const changePlanQuery = async (endpoint_url, data, csrf_token, license_id) => {
    return fetch(`${endpoint_url}/customers/change_plan/${license_id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
