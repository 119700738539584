import numeral from 'numeral';
import colors, { labelColors } from '../../lib/colors';

const keys = [
    {
        key: 'impressions',
        label: 'Avg. Daily Impressions',
        color: colors[0],
        labelColor: labelColors[0],
        icon: 'search',
    },
    {
        key: 'ctr',
        label: 'Avg. CTR',
        format: '0.00%',
        yAxisID: 'y1',
        render: (data) => numeral(data * 100).format('0.00'),
        color: colors[1],
        labelColor: labelColors[1],
        icon: 'ctr',
    },
    {
        key: 'pages',
        label: 'Daily Pages',
        format: '0.0',
        yAxisID: 'y2',
        color: colors[2],
        labelColor: labelColors[2],
        info: 'The number of pages that generate Google Discover traffic that day',
        icon: 'pagev-view',
    },
    {
        key: 'clicks',
        label: 'Daily Clicks',
        color: colors[3],
        labelColor: labelColors[3],
        icon: 'clicks',
        yAxisID: 'y3',
    },
];

export default keys;
