import Chart from '../Chart';
import chart from './chart';

class Bubble extends Chart {
    constructor() {
        super();
        this.chart = chart;
    }
}

export default Bubble;
