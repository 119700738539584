import React from 'react';
import Loading from '../../components/Loading';
import { isTrue } from '../../helpers/isTrue';
import ActivateModalDomains from './modal';
import { activateDomain, confirmActivateDomain } from './query';

class ActivateDomain extends React.Component {
    constructor() {
        super();

        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onClick = this.onClick.bind(this);
        this.checkDomainData = this.checkDomainData.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.onClickConfirm = this.onClickConfirm.bind(this);

        this.state = {
            isOpen: false,
            loading: false,
            activationResponse: null,
            checkWithConfirm: false,
            onSubmit: () => {},
            activationLink: null,
            btnName: null,
            message: null,
            cancleBtn: null,
            canConfirm: false,
            isLoadingConfirm: false,
        };
    }

    onOpen() {
        this.setState({ isOpen: true });
    }

    onClose() {
        this.setState({ isOpen: false });
    }

    onClickConfirm() {
        this.setState({
            isLoadingConfirm: true,
        });
    }
    async onConfirm() {
        try {
            this.props.changeDisableAll?.(true);
            this.setState({ isLoadingConfirm: true });
            const response = await confirmActivateDomain(
                this.props.csrf_token,
                this.props.domain_pk,
            );
            await response.json();
            window.location.reload();
        } catch (err) {
            console.log('err', err);
            this.setState({ isLoadingConfirm: false });
        }
    }

    checkDomainData() {
        this.setState({ loading: true });
        this.props.changeDisableAll?.(true);
        activateDomain(this.props.csrf_token, this.props.domain_pk)
            .then((res) => res.json())
            .then((data) => {
                if (data.activate_directly) {
                    const a = document.createElement('a');
                    a.href = `/domains/${this.props.domain_pk}/confirm-reactivate/${
                        this.props.from_onboarding ? '?dest=to_onboard' : ''
                    }`;
                    return a.click();
                } else if (data.can_confirm) {
                    this.setState({
                        isOpen: true,
                        canConfirm: data.can_confirm,
                        message: data.message,
                        error: data.error,
                        btnName: 'Activate Paid Plan',
                        onSubmit: this.onConfirm,
                        activationLink: `/domains/${this.props.domain_pk}/confirm-reactivate/${
                            this.props.from_onboarding ? '?dest=to_onboard' : ''
                        }`,
                        checkWithConfirm: true,
                        cancleBtn: 'Continue Trial',
                        isButton: false,
                        loading: false,
                    });
                } else if (data.need_upgrade && !data.can_onfirm) {
                    this.setState({
                        isOpen: true,
                        canConfirm: data.can_confirm,
                        message: data.message,
                        error: data.error,
                        btnName: 'Upgrade Plan',
                        activationLink: '/customers/change_plan/',
                        cancleBtn: 'Cancel',
                        isButton: false,
                        loading: false,
                    });
                } else {
                    this.setState({
                        isOpen: true,
                        canConfirm: data.can_confirm,
                        message: data.message,
                        error: data.error,
                        btnName: 'Proceed',
                        onSubmit: this.onConfirm,
                        activationLink: `/domains/${this.props.domain_pk}/confirm-reactivate/${
                            this.props.from_onboarding ? '?dest=to_onboard' : ''
                        }`,
                        checkWithConfirm: true,
                        cancleBtn: 'Cancel',
                        isButton: false,
                        loading: false,
                    });
                }
                this.props.changeDisableAll?.(false);
            })
            .catch((err) => console.log(err));
    }

    onClick() {
        this.props.changeDisableAll?.(true);
        this.setState({
            loading: true,
        });
    }

    render() {
        const is_viewer = isTrue(this.props.is_viewer);
        const data = () => {
            if (!isTrue(this.props.active_domain)) {
                return (
                    <>
                        {this.state.loading ? (
                            <Loading onlyPreloader />
                        ) : (
                            <button
                                className={`switch-button not-active  ${
                                    is_viewer || this.props.disableAll ? 'disabled-btn' : ''
                                }`}
                                style={{ border: 'none' }}
                                onClick={this.checkDomainData}
                                disabled={is_viewer || this.props.disableAll}
                            >
                                <span className="switch-circle"></span>
                            </button>
                        )}
                        <ActivateModalDomains
                            isOpen={this.state.isOpen}
                            onClose={this.onClose}
                            message={this.state.message}
                            isButton={this.state.isButton}
                            checkWithConfirm={this.state.checkWithConfirm}
                            onSubmit={this.state.onSubmit ?? this.onClickConfirm}
                            canConfirm={this.state.canConfirm}
                            activationLink={this.state.activationLink}
                            submitBtn={this.state.btnName ?? 'Proceed & Activate my Paid Plan'}
                            isLoadingConfirm={this.state.isLoadingConfirm || this.state.loading}
                            cancleBtn={this.state.cancleBtn}
                        />
                    </>
                );
            }

            return this.state.loading ? (
                <Loading onlyPreloader />
            ) : (
                <a
                    className={`switch-button active ${
                        is_viewer || this.props.disableAll ? 'disabled-btn' : ''
                    }`}
                    href={`/domains/${this.props.domain_pk}/deactivate/${
                        this.props.from_onboarding ? '?dest=to_onboard' : ''
                    }`}
                    onClick={this.onClick}
                    style={{
                        pointerEvents: is_viewer || this.props.disableAll ? 'none' : '',
                    }}
                >
                    <span className="switch-circle"></span>
                </a>
            );
        };
        return data();
    }
}

export default ActivateDomain;
