import Loading from '../../../components/Loading';

import { query } from './query';
import { COLUMNS } from './keys';
import DataTable from '../../../components/Datatable';
import CheckData from '../../../components/CatchError';
import LoadMoreComponent from '../../../components/LoadMoreComponent';
import { getPercent } from '../../../helpers/showDelta';
import ManualClusterSelect from '../../../components/manualClusterModal';
import Search from '../../../components/Search';
import { scrollForTable } from '../../../components/Datatable/utils';
import ExtraButtons from './extraBtns';

class GSCReportStrikingDistance extends LoadMoreComponent {
    constructor() {
        super();
        this.query = query({
            offset: 0,
            search: null,
        });
        this.offsetStep = 1000;

        this.onSelect = this.onSelect.bind(this);
        this.changePositions = this.changePositions.bind(this);
        this.onRemoveAllSelected = this.onRemoveAllSelected.bind(this);
        this.changeWeek = this.changeWeek.bind(this);
        this.getData = this.getData.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) => item.keyword?.toLowerCase().includes(search.toLowerCase()));
    }

    async getData({ search, first_position, second_position, weeks }) {
        this.query = query({
            offset: 0,
            search: search ?? this.state.search,
            first_position: first_position ?? this.state.first_position,
            second_position: second_position ?? this.state.second_position,
            weeks: weeks ?? this.state.weeks,
        });
        this.query.bind(this.setState.bind(this));
        this.setState({ allData: [] });

        await this.query.update();
    }

    onRemoveAllSelected(bool) {
        this.setState({
            removeAllSelected: bool,
            selectedItems: [],
        });
    }

    onSelect(action, state) {
        if (action.type === 'REMOVE_ALL') return this.setState({ selectedItems: [] });
        this.setState({ selectedItems: state.ids, removeAllSelected: false });
    }

    getTable() {
        return {
            columns: COLUMNS,
            tableStyles: `--data-table-library_grid-template-columns : minmax(60px, 0.5fr) minmax(200px, 1.2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(150px, 1.5fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important; ${scrollForTable}`,
            sortKey: 'sortFns_1',
            defaultSortKey: 'maxClicks',
            text: null,
        };
    }

    onSearch(value) {
        this.setState({
            search: value,
        });
        this.getData({ search: value });
    }

    changePositions(first_position, second_position) {
        this.getData({ first_position, second_position });
        this.setState({
            first_position,
            second_position,
        });
    }

    changeWeek(weeks) {
        this.getData({ weeks });
        this.setState({
            weeks,
        });
    }

    render() {
        const attr = {
            query,
            offsetStep: this.offsetStep,
            status: this.state.status,
            last_week: this.props.last_week,
            prior_week: this.props.prior_week,
            search: this.state.search,
        };

        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const sortFns = {
            sortFns_2: {
                keyword: (array) => array.sort((a, b) => a.keyword.localeCompare(b.keyword)),
                urls: (array) => array.sort((a, b) => a.urls - b.urls),
                urls_delta: (array) =>
                    array.sort(
                        (a, b) => getPercent(a.urls, a.urlsPrior) - getPercent(b.urls, b.urlsPrior),
                    ),
                clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
                weeks: (array) => array.sort((a, b) => a.weeks - b.weeks),
                clicks_delta: (array) =>
                    array.sort(
                        (a, b) =>
                            getPercent(a.clicks, a.clicksPrior) -
                            getPercent(b.clicks, b.clicksPrior),
                    ),
                impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
                impressions_delta: (array) =>
                    array.sort(
                        (a, b) =>
                            getPercent(a.impressions, a.impressionsPrior) -
                            getPercent(b.impressions, b.impressionsPrior),
                    ),
                position: (array) => array.sort((a, b) => a.position - b.position),
                position_delta: (array) =>
                    array.sort(
                        (a, b) =>
                            getPercent(a.position, a.positionPrior) -
                            getPercent(b.position, b.positionPrior),
                    ),
                ctr: (array) => array.sort((a, b) => a.ctr - b.ctr),
                ctr_delta: (array) =>
                    array.sort(
                        (a, b) => getPercent(a.ctr, a.ctrPrior) - getPercent(b.ctr, b.ctrPrior),
                    ),
            },
            sortFns_1: {
                keyword: (array) => array.sort((a, b) => a.keyword.localeCompare(b.keyword)),
                clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
                impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
                avgURLs: (array) => array.sort((a, b) => a.avgURLs - b.avgURLs),
                weeks: (array) => array.sort((a, b) => a.weeks - b.weeks),
                maxClicks: (array) => array.sort((a, b) => a.maxClicks - b.maxClicks),
                avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
                avgCTR: (array) => array.sort((a, b) => a.avgCTR - b.avgCTR),
            },
        };

        const customeSearch = (
            <Search
                onSubmit={this.onSearch}
                placeholder="Search for items"
                defaultValue={this.state.search}
            />
        );
        const extraBtns = () => (
            <ExtraButtons
                changePositions={this.changePositions}
                changeWeek={this.changeWeek}
                first_position={this.state.first_position}
                second_position={this.state.second_position}
                weeks={this.state.weeks}
            />
        );

        const { columns, tableStyles, sortKey, defaultSortKey, text } = this.getTable();
        return (
            <div className="card table-main rounded-main overflow-hidden">
                <div className="col-12">
                    <div className="card-header d-flex align-items-center justify-content-between">
                        <div>
                            <p className="card-title">Striking Distance</p>
                            {text && (
                                <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                                    {text}
                                </small>
                            )}
                        </div>
                        <div>
                            <ManualClusterSelect
                                current_url={this.props.current_url}
                                csrf_token={this.props.csrf_token}
                                selectedItems={this.state.selectedItems?.map((id) => ({
                                    id,
                                    keyword: this.state.allData.find((item) => item.id === id)
                                        .keyword,
                                }))}
                                cluster_type="keyword"
                                header="Add To Keyword List"
                                onRemoveAllSelected={this.onRemoveAllSelected}
                            />
                        </div>
                    </div>
                    <div className="card-body">
                        <CheckData
                            content_id={this.props.content_id}
                            parent_id={this.props.parent_id}
                            data={this.state.allData}
                            loading={this.state.loading}
                            loadMore={this.state.loadMore}
                            customCheck
                            customCheckCondition={
                                this.state.status !== 'all' ||
                                !!this.state.search ||
                                !!this.state.week
                            }
                        >
                            <DataTable
                                nodes={this.state.allData}
                                sortFns={sortFns[sortKey]}
                                COLUMNS={columns}
                                fileName="Keywords"
                                defaultSortKey={defaultSortKey}
                                searchFunction={this.searchFunction}
                                extraBtns={extraBtns}
                                removeAllSelected={this.state.removeAllSelected}
                                hasSelect={true}
                                onSelect={this.onSelect}
                                forceRun={this.forceRun}
                                customeSearch={customeSearch}
                                additionalStyles={{
                                    Table: tableStyles,
                                    BaseCell: `  font-family: 'Raleway';
                                                font-weight: 500;
                                                font-size: 16px;
                                                line-height: 19px;
                                                padding: 16px 5px !important;
                                                color: #0D182C;
                                                border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                            
                                                &.underline {
                                                    text-decoration: underline;
                                                }
                                                `,
                                }}
                            />
                        </CheckData>
                        {this.state.loadMore ? (
                            <Loading padding={10} />
                        ) : (
                            showLoadMoreBtn && (
                                <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                    <button
                                        onClick={() => this.loadMore(attr)}
                                        disabled={this.state.loading}
                                        className="load-more-btn"
                                    >
                                        Load More
                                    </button>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default GSCReportStrikingDistance;
