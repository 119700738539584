import { Cell } from '@table-library/react-table-library/table';

export const COLUMNS = ({ defaultMethod, setAsDefault }) => [
    {
        label: 'CARD HOLDER NAME',
        key: 'billing_details',
        data: 'billing_details',
        Component: Cell,
        renderCell: ({ item }) => (
            <span>
                {item.billing_details.name}
                {defaultMethod === item.id && <span className="default-selected">default</span>}
            </span>
        ),
    },
    {
        label: 'CARD TYPE',
        key: 'card',
        data: 'card',
        Component: Cell,
        renderCell: ({ item }) => item.card.brand,
    },
    {
        label: 'CARD NUMBER',
        key: 'card_number',
        data: 'card_number',
        Component: Cell,
        renderCell: ({ item }) => `**** **** **** ${item.card.last4}`,
    },
    {
        label: 'EXP. DATE',
        key: 'exp_date',
        data: 'exp_date',
        Component: Cell,
        renderCell: ({ item }) => `${item.card.exp_month}/${item.card.exp_year}`,
    },
    {
        label: 'ACTION',
        key: 'action',
        data: 'action',
        Component: Cell,
        renderCell: ({ item }) => {
            return defaultMethod !== item.id ? (
                <div className="payment-actions">
                    <button
                        className="set-default-btn"
                        onClick={() => setAsDefault(item.id)}
                        title="Set Default"
                    >
                        <span className="default-selected">Set Default</span>
                    </button>
                    <a href={`/customers/detach/${item.id}/`} className="delete-payment-method">
                        <i className="icon-delete"></i>
                    </a>
                </div>
            ) : null;
        },
    },
];
