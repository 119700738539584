import moment from 'moment';
import { getDaysOfMonth } from './getDataRange';

export function getDatesForAppearance(filters) {
    const start_date = filters.dates.values.start_date;
    const end_date = filters.dates.values.end_date;

    const { prior_start_date, prior_end_date } = getDataRange(filters?.dates);

    return {
        start_date: start_date.value,
        end_date: end_date.value,
        prior_start_date,
        prior_end_date,
    };
}

function getDataRange(choice) {
    switch (choice.id) {
        case 'last_week':
            return getPriorLastWeek();
        case 'last_month':
            return getPriorLastMonth();
        case 'custom':
            return getPriorCustomDate(choice);
        default:
            return getValuesByDays(choice.days);
    }
}

function getPriorLastWeek() {
    const now = new Date();
    const day = now.getDay();
    return {
        prior_start_date: moment()
            .subtract(day + 15, 'days')
            .format('YYYY-MM-DD'),
        prior_end_date: moment()
            .subtract(day + 7, 'days')
            .format('YYYY-MM-DD'),
    };
}

function getPriorLastMonth() {
    const now = new Date();
    const days = now.getDate();
    const month = now.getMonth();
    const lastMonthDays = getDaysOfMonth(month - 1);
    const priorlastMonthDays = getDaysOfMonth(month - 2);
    return {
        prior_start_date: moment()
            .subtract(days + lastMonthDays + priorlastMonthDays, 'days')
            .format('YYYY-MM-DD'),
        prior_end_date: moment()
            .subtract(days + lastMonthDays - 1, 'days')
            .format('YYYY-MM-DD'),
    };
}

function getValuesByDays(days = 28) {
    return {
        prior_start_date: moment()
            .subtract(2 * days + 2, 'days')
            .format('YYYY-MM-DD'),
        prior_end_date: moment()
            .subtract(days + 1, 'days')
            .format('YYYY-MM-DD'),
    };
}

function getPriorCustomDate(choice) {
    const {
        values: { start_date, end_date },
    } = choice;

    const start = new Date(start_date.value);
    const end = new Date(end_date.value);

    const difference_In_Time = end.getTime() - start.getTime();

    const prior_end_date = moment(start_date.value).add(1, 'days').format('YYYY-MM-DD');

    const prior_end_date_time = new Date(prior_end_date).getTime();
    const prior_start_date_time = prior_end_date_time - difference_In_Time;

    const prior_start_date = moment(new Date(prior_start_date_time)).format('YYYY-MM-DD');

    return {
        prior_start_date,
        prior_end_date,
    };
}
