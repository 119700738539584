import React, { Component } from 'react';
import SendEmailModal from '../../components/Modals/SendEmailModal.js';
import Message from '../Message/index.js';
import { sendEmailQuery, sendEmailToAllUsersQuery } from './query.js';

class SendEmail extends Component {
    constructor() {
        super();
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.setMessage = this.setMessage.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);
        this.sendEmailToAllUsers = this.sendEmailToAllUsers.bind(this);

        this.state = {
            isOpen: false,
            message: '',
            message_tag: '',
            isLoading: false,
            isAllUsers: false,
        };
    }

    onOpenModal(isAllUsers) {
        this.setState({ isOpen: true, isAllUsers });
    }

    onCloseModal() {
        this.setState({ isOpen: false });
    }

    sendEmail(args) {
        const { closeModal, subject, body_text } = args;

        if (!window.customers_emails?.length) {
            this.onCloseModal();
            return this.setMessage('You need to select a user.', 'info');
        }

        this.setState({ isLoading: true });

        const params = { subject, body_text, emails: window.customers_emails };

        sendEmailQuery(this.props.endpoint_url, params, this.props.csrf_token)
            .then((res) => res.json())
            .then((data) => {
                let message_tag = '';
                if (data.status === 200) message_tag = 'success';
                if (data.status === 400) message_tag = 'error';
                this.setMessage(data.message, message_tag);
            })
            .catch((err) => this.setMessage(err?.message, 'error'))
            .finally(() => {
                closeModal();
                this.unCheck();
                this.setState({ isLoading: false });
            });
    }

    sendEmailToAllUsers(args) {
        const { closeModal, subject, body_text } = args;

        this.setState({ isLoading: true });

        const params = { subject, body_text };

        sendEmailToAllUsersQuery(this.props.endpoint_url, params, this.props.csrf_token)
            .then((res) => res.json())
            .then((data) => {
                let message_tag = '';
                if (data.status === 200) message_tag = 'success';
                if (data.status === 400) message_tag = 'error';
                this.setMessage(data.message, message_tag);
            })
            .catch((err) => this.setMessage(err?.message, 'error'))
            .finally(() => {
                closeModal();
                this.unCheck();
                this.setState({ isLoading: false });
            });
    }

    setMessage(message, message_tag) {
        this.setState({
            message,
            message_tag,
        });
    }

    deleteMessage() {
        this.setState({
            message: '',
            message_tag: '',
        });
    }

    unCheck() {
        const check_all = document.getElementById('checkbox-all');
        const all_checkboxes = document.querySelectorAll('.email-checkbox');

        check_all.checked = false;
        all_checkboxes.forEach((item) => (item.checked = false));
        window.customers_emails = [];
    }

    render() {
        return (
            <>
                <button
                    className="btn btn-sm text-white bg-purple d-inline-block text-capitalize table-button mr-10"
                    onClick={() => this.onOpenModal(true)}
                >
                    Send Email To All Users
                </button>
                <button
                    className="btn btn-sm text-white bg-purple d-inline-block text-capitalize table-button"
                    onClick={() => this.onOpenModal(false)}
                >
                    Send Email
                </button>
                <SendEmailModal
                    isOpen={this.state.isOpen}
                    onClose={this.onCloseModal}
                    onSubmit={this.state.isAllUsers ? this.sendEmailToAllUsers : this.sendEmail}
                    isLoading={this.state.isLoading}
                />
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tag}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default SendEmail;
