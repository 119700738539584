export const LANGUAGES = [
    {
        name: 'United States',
        gl: 'us',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com/search',
    },
    {
        name: 'Afghanistan',
        gl: 'af',
        lang: 'Pashto',
        hl: 'ps',
        url: 'https://www.google.com.af/search',
    },
    {
        name: 'Albania',
        gl: 'al',
        lang: 'Albanian',
        hl: 'sq',
        url: 'https://www.google.al/search',
    },
    {
        name: 'Algeria',
        gl: 'dz',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.dz/search',
    },
    {
        name: 'American Samoa',
        gl: 'as',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.as/search',
    },
    {
        name: 'Andorra',
        gl: 'ad',
        lang: 'Catalan',
        hl: 'ca',
        url: 'https://www.google.ad/search',
    },
    {
        name: 'Angola',
        gl: 'ao',
        lang: 'Portuguese',
        hl: 'pt',
        url: 'https://www.google.co.ao/search',
    },
    {
        name: 'Anguilla',
        gl: 'ai',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.ai/search',
    },
    {
        name: 'Antigua and Barbuda',
        gl: 'ag',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.ag/search',
    },
    {
        name: 'Argentina',
        gl: 'ar',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.com.ar/search',
    },
    {
        name: 'Armenia',
        gl: 'am',
        lang: 'Armenian',
        hl: 'hy',
        url: 'https://www.google.am/search',
    },
    {
        name: 'Ascension Island',
        gl: 'ac',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.ac/search',
    },
    {
        name: 'Australia',
        gl: 'au',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.au/search',
    },
    {
        name: 'Austria',
        gl: 'at',
        lang: 'German',
        hl: 'de',
        url: 'https://www.google.at/search',
    },
    {
        name: 'Azerbaijan',
        gl: 'az',
        lang: 'Azerbaijani',
        hl: 'az',
        url: 'https://www.google.az/search',
    },
    {
        name: 'Bahamas',
        gl: 'bs',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.bs/search',
    },
    {
        name: 'Bahrain',
        gl: 'bh',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.com.bh/search',
    },
    {
        name: 'Bangladesh',
        gl: 'bd',
        lang: 'Bengali',
        hl: 'bn',
        url: 'https://www.google.com.bd/search',
    },
    {
        name: 'Belarus',
        gl: 'by',
        lang: 'Belarusian',
        hl: 'be',
        url: 'https://www.google.by/search',
    },
    {
        name: 'Belgium',
        gl: 'be',
        lang: 'Dutch',
        hl: 'nl',
        url: 'https://www.google.be/search',
    },
    {
        name: 'Belize',
        gl: 'bz',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.bz/search',
    },
    {
        name: 'Benin',
        gl: 'bj',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.bj/search',
    },
    {
        name: 'Bolivia',
        gl: 'bo',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.com.bo/search',
    },
    {
        name: 'Bosnia and Herzegovina',
        gl: 'ba',
        lang: 'Bosnian',
        hl: 'bs',
        url: 'https://www.google.ba/search',
    },
    {
        name: 'Botswana',
        gl: 'bw',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.co.bw/search',
    },
    {
        name: 'Brazil',
        gl: 'br',
        lang: 'Portuguese',
        hl: 'pt',
        url: 'https://www.google.com.br/search',
    },
    {
        name: 'British Virgin Islands',
        gl: 'vg',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.vg/search',
    },
    {
        name: 'Brunei',
        gl: 'bn',
        lang: 'Malay',
        hl: 'ms',
        url: 'https://www.google.com.bn/search',
    },
    {
        name: 'Bulgaria',
        gl: 'bg',
        lang: 'Bulgarian',
        hl: 'bg',
        url: 'https://www.google.bg/search',
    },
    {
        name: 'Burkina Faso',
        gl: 'bf',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.bf/search',
    },
    {
        name: 'Burundi',
        gl: 'bi',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.bi/search',
    },
    {
        name: 'Cambodia',
        gl: 'kh',
        lang: 'Cambodian',
        hl: 'km',
        url: 'https://www.google.com.kh/search',
    },
    {
        name: 'Cameroon',
        gl: 'cm',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.cm/search',
    },
    {
        name: 'Canada',
        gl: 'ca',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.ca/search',
    },
    {
        name: 'Cape Verde',
        gl: 'cv',
        lang: 'Portuguese',
        hl: 'pt',
        url: 'https://www.google.cv/search',
    },
    {
        name: 'Catalonia',
        gl: 'cat',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.cat/search',
    },
    {
        name: 'Central African Republic',
        gl: 'cf',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.cf/search',
    },
    {
        name: 'Chad',
        gl: 'td',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.td/search',
    },
    {
        name: 'Chile',
        gl: 'cl',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.cl/search',
    },
    {
        name: 'China',
        gl: 'cn',
        lang: 'Chinese (Simplified)',
        hl: 'zh-cn',
        url: 'https://www.google.cn/search',
    },
    {
        name: 'China',
        gl: 'cn',
        lang: 'Chinese (Traditional)',
        hl: 'zh-tw',
        url: 'https://www.google.cn/search',
    },
    {
        name: 'Christmas Island',
        gl: 'cx',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.cx/search',
    },
    {
        name: 'Cocos (Keeling) Islands',
        gl: 'cc',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.cc/search',
    },
    {
        name: 'Colombia',
        gl: 'co',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.com.co/search',
    },
    {
        name: 'Cook Islands',
        gl: 'ck',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.co.ck/search',
    },
    {
        name: 'Costa Rica',
        gl: 'cr',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.co.cr/search',
    },
    {
        name: 'Croatia',
        gl: 'hr',
        lang: 'Croatian',
        hl: 'hr',
        url: 'https://www.google.hr/search',
    },
    {
        name: 'Cuba',
        gl: 'cu',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.com.cu/search',
    },
    {
        name: 'Cyprus',
        gl: 'cy',
        lang: 'Greek',
        hl: 'el',
        url: 'https://www.google.com.cy/search',
    },
    {
        name: 'Czech Republic',
        gl: 'cz',
        lang: 'Czech',
        hl: 'cs',
        url: 'https://www.google.cz/search',
    },
    {
        name: 'Democratic Rep of Congo',
        gl: 'cg',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.cd/search',
    },
    {
        name: 'Denmark',
        gl: 'dk',
        lang: 'Danish',
        hl: 'da',
        url: 'https://www.google.dk/search',
    },
    {
        name: 'Djibouti',
        gl: 'dj',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.dj/search',
    },
    {
        name: 'Dominica',
        gl: 'dm',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.dm/search',
    },
    {
        name: 'Dominican Republic',
        gl: 'do',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.com.do/search',
    },
    {
        name: 'Ecuador',
        gl: 'ec',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.com.ec/search',
    },
    {
        name: 'Egypt',
        gl: 'eg',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.com.eg/search',
    },
    {
        name: 'El Salvador',
        gl: 'sv',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.com.sv/search',
    },
    {
        name: 'Estonia',
        gl: 'ee',
        lang: 'Estonian',
        hl: 'et',
        url: 'https://www.google.ee/search',
    },
    {
        name: 'Ethiopia',
        gl: 'et',
        lang: 'Amharic',
        hl: 'am',
        url: 'https://www.google.com.et/search',
    },
    {
        name: 'Fiji',
        gl: 'fj',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.fj/search',
    },
    {
        name: 'Finland',
        gl: 'fi',
        lang: 'Finnish',
        hl: 'fi',
        url: 'https://www.google.fi/search',
    },
    {
        name: 'France',
        gl: 'fr',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.fr/search',
    },
    {
        name: 'French Guiana',
        gl: 'gf',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.gf/search',
    },
    {
        name: 'Gabon',
        gl: 'ga',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.ga/search',
    },
    {
        name: 'Gambia',
        gl: 'gm',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.gm/search',
    },
    {
        name: 'Georgia',
        gl: 'ge',
        lang: 'Georgian',
        hl: 'ka',
        url: 'https://www.google.ge/search',
    },
    {
        name: 'Germany',
        gl: 'de',
        lang: 'German',
        hl: 'de',
        url: 'https://www.google.de/search',
    },
    {
        name: 'Ghana',
        gl: 'gh',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.gh/search',
    },
    {
        name: 'Gibraltar',
        gl: 'gi',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.gi/search',
    },
    {
        name: 'Greece',
        gl: 'gr',
        lang: 'Greek',
        hl: 'el',
        url: 'https://www.google.gr/search',
    },
    {
        name: 'Greenland',
        gl: 'gl',
        lang: 'Greenlandic',
        hl: 'kl',
        url: 'https://www.google.gl/search',
    },
    {
        name: 'Guadeloupe',
        gl: 'gp',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.gp/search',
    },
    {
        name: 'Guatemala',
        gl: 'gt',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.com.gt/search',
    },
    {
        name: 'Guyana',
        gl: 'gy',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.gy/search',
    },
    {
        name: 'Haiti',
        gl: 'ht',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.ht/search',
    },
    {
        name: 'Honduras',
        gl: 'hn',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.hn/search',
    },
    {
        name: 'Hong Kong',
        gl: 'hk',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.hk/search',
    },
    {
        name: 'Hungary',
        gl: 'hu',
        lang: 'Hungarian',
        hl: 'hu',
        url: 'https://www.google.hu/search',
    },
    {
        name: 'Iceland',
        gl: 'is',
        lang: 'Icelandic',
        hl: 'is',
        url: 'https://www.google.is/search',
    },
    {
        name: 'India',
        gl: 'in',
        lang: 'Hindi',
        hl: 'hi',
        url: 'https://www.google.co.in/search',
    },
    {
        name: 'Indian Ocean Territory',
        gl: 'io',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.io/search',
    },
    {
        name: 'Indonesia',
        gl: 'id',
        lang: 'Indonesian',
        hl: 'id',
        url: 'https://www.google.co.id/search',
    },
    {
        name: 'Iraq',
        gl: 'iq',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.iq/search',
    },
    {
        name: 'Ireland',
        gl: 'ie',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.ie/search',
    },
    {
        name: 'Isle of Man',
        gl: 'im',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.im/search',
    },
    {
        name: 'Israel',
        gl: 'il',
        lang: 'Hebrew',
        hl: 'he',
        url: 'https://www.google.co.il/search',
    },
    {
        name: 'Israel',
        gl: 'il',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.co.il/search',
    },
    {
        name: 'Italy',
        gl: 'it',
        lang: 'Italian',
        hl: 'it',
        url: 'https://www.google.it/search',
    },
    {
        name: 'Ivory Coast',
        gl: 'ci',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.ci/search',
    },
    {
        name: 'Jamaica',
        gl: 'jm',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.jm/search',
    },
    {
        name: 'Japan',
        gl: 'jp',
        lang: 'Japanese',
        hl: 'ja',
        url: 'https://www.google.co.jp/search',
    },
    {
        name: 'Jersey',
        gl: 'je',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.je/search',
    },
    {
        name: 'Jordan',
        gl: 'jo',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.jo/search',
    },
    {
        name: 'Kazakhstan',
        gl: 'kz',
        lang: 'Kazakh',
        hl: 'kk',
        url: 'https://www.google.kz/search',
    },
    {
        name: 'Kenya',
        gl: 'ke',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.co.ke/search',
    },
    {
        name: 'Kiribati',
        gl: 'ki',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.ki/search',
    },
    {
        name: 'Kuwait',
        gl: 'kw',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.com.kw/search',
    },
    {
        name: 'Kyrgyzstan',
        gl: 'kg',
        lang: 'Kyrgyz',
        hl: 'ky',
        url: 'https://www.google.kg/search',
    },
    {
        name: 'Laos',
        gl: 'la',
        lang: 'Laothian',
        hl: 'lo',
        url: 'https://www.google.la/search',
    },
    {
        name: 'Latvia',
        gl: 'lv',
        lang: 'Latvian',
        hl: 'lv',
        url: 'https://www.google.lv/search',
    },
    {
        name: 'Lebanon',
        gl: 'lb',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.com.lb/search',
    },
    {
        name: 'Lesotho',
        gl: 'ls',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.co.ls/search',
    },
    {
        name: 'Libya',
        gl: 'ly',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.com.ly/search',
    },
    {
        name: 'Liechtenstein',
        gl: 'li',
        lang: 'German',
        hl: 'de',
        url: 'https://www.google.li/search',
    },
    {
        name: 'Lithuania',
        gl: 'lt',
        lang: 'Lithuanian',
        hl: 'lt',
        url: 'https://www.google.lt/search',
    },
    {
        name: 'Luxembourg',
        gl: 'lu',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.lu/search',
    },
    {
        name: 'Macedonia',
        gl: 'mk',
        lang: 'Macedonian',
        hl: 'mk',
        url: 'https://www.google.mk/search',
    },
    {
        name: 'Madagascar',
        gl: 'mg',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.mg/search',
    },
    {
        name: 'Malawi',
        gl: 'mw',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.mw/search',
    },
    {
        name: 'Malaysia',
        gl: 'my',
        lang: 'Malay',
        hl: 'ms',
        url: 'https://www.google.com.my/search',
    },
    {
        name: 'Maldives',
        gl: 'mv',
        lang: 'Maldives',
        hl: 'mv',
        url: 'https://www.google.mv/search',
    },
    {
        name: 'Mali',
        gl: 'ml',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.ml/search',
    },
    {
        name: 'Malta',
        gl: 'mt',
        lang: 'Maltese',
        hl: 'mt',
        url: 'https://www.google.com.mt/search',
    },
    {
        name: 'Mauritius',
        gl: 'mu',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.mu/search',
    },
    {
        name: 'Mexico',
        gl: 'mx',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.com.mx/search',
    },
    {
        name: 'Micronesia',
        gl: 'fm',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.fm/search',
    },
    {
        name: 'Moldova',
        gl: 'md',
        lang: 'Romanian',
        hl: 'ro',
        url: 'https://www.google.md/search',
    },
    {
        name: 'Mongolia',
        gl: 'mn',
        lang: 'Mongolian',
        hl: 'mn',
        url: 'https://www.google.mn/search',
    },
    {
        name: 'Montserrat',
        gl: 'ms',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.ms/search',
    },
    {
        name: 'Morocco',
        gl: 'ma',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.co.ma/search',
    },
    {
        name: 'Mozambique',
        gl: 'mz',
        lang: 'Portuguese',
        hl: 'pt',
        url: 'https://www.google.co.mz/search',
    },
    {
        name: 'Myanmar',
        gl: 'mm',
        lang: 'Myanmar',
        hl: 'my',
        url: 'https://www.google.com.mm/search',
    },
    {
        name: 'Namibia',
        gl: 'na',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.na/search',
    },
    {
        name: 'Nauru',
        gl: 'nr',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.nr/search',
    },
    {
        name: 'Nepal',
        gl: 'np',
        lang: 'Nepali',
        hl: 'ne',
        url: 'https://www.google.com.np/search',
    },
    {
        name: 'Netherlands',
        gl: 'nl',
        lang: 'Dutch',
        hl: 'nl',
        url: 'https://www.google.nl/search',
    },
    {
        name: 'New Zealand',
        gl: 'nz',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.co.nz/search',
    },
    {
        name: 'Nicaragua',
        gl: 'ni',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.com.ni/search',
    },
    {
        name: 'Niger',
        gl: 'ne',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.ne/search',
    },
    {
        name: 'Nigeria',
        gl: 'ng',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.ng/search',
    },
    {
        name: 'Niue',
        gl: 'nu',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.nu/search',
    },
    {
        name: 'Norway',
        gl: 'no',
        lang: 'Norwegian',
        hl: 'no',
        url: 'https://www.google.no/search',
    },
    {
        name: 'Oman',
        gl: 'om',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.com.om/search',
    },
    {
        name: 'Pakistan',
        gl: 'pk',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.pk/search',
    },
    {
        name: 'Palestine',
        gl: 'ps',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.ps/search',
    },
    {
        name: 'Panama',
        gl: 'pa',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.com.pa/search',
    },
    {
        name: 'Papua New Guinea',
        gl: 'pg',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.pg/search',
    },
    {
        name: 'Paraguay',
        gl: 'py',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.com.py/search',
    },
    {
        name: 'Peru',
        gl: 'pe',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.com.pe/search',
    },
    {
        name: 'Philippines',
        gl: 'ph',
        lang: 'Filipino',
        hl: 'tl',
        url: 'https://www.google.com.ph/search',
    },
    {
        name: 'Poland',
        gl: 'pl',
        lang: 'Polish',
        hl: 'pl',
        url: 'https://www.google.pl/search',
    },
    {
        name: 'Portugal',
        gl: 'pt',
        lang: 'Portuguese',
        hl: 'pt',
        url: 'https://www.google.pt/search',
    },
    {
        name: 'Puerto Rico',
        gl: 'pr',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.com.pr/search',
    },
    {
        name: 'Qatar',
        gl: 'qa',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.com.qa/search',
    },
    {
        name: 'Romania',
        gl: 'ro',
        lang: 'Romanian',
        hl: 'ro',
        url: 'https://www.google.ro/search',
    },
    {
        name: 'Russia',
        gl: 'ru',
        lang: 'Russian',
        hl: 'ru',
        url: 'https://www.google.ru/search',
    },
    {
        name: 'Rwanda',
        gl: 'rw',
        lang: 'Kinyarwanda',
        hl: 'rw',
        url: 'https://www.google.rw/search',
    },
    {
        name: 'Saint Helena',
        gl: 'sh',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.sh/search',
    },
    {
        name: 'Saint Vincent',
        gl: 'vc',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.vc/search',
    },
    {
        name: 'Samoa',
        gl: 'ws',
        lang: 'Samoa',
        hl: 'ws',
        url: 'https://www.google.ws/search',
    },
    {
        name: 'San Marino',
        gl: 'sm',
        lang: 'Italian',
        hl: 'it',
        url: 'https://www.google.sm/search',
    },
    {
        name: 'Saudi Arabia',
        gl: 'sa',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.com.sa/search',
    },
    {
        name: 'Senegal',
        gl: 'sn',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.sn/search',
    },
    {
        name: 'Serbia',
        gl: 'rs',
        lang: 'Serbian',
        hl: 'sr',
        url: 'https://www.google.rs/search',
    },
    {
        name: 'Seychelles',
        gl: 'sc',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.sc/search',
    },
    {
        name: 'Sierra Leone',
        gl: 'sl',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.sl/search',
    },
    {
        name: 'Singapore',
        gl: 'sg',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.sg/search',
    },
    {
        name: 'Slovakia',
        gl: 'sk',
        lang: 'Slovak',
        hl: 'sk',
        url: 'https://www.google.sk/search',
    },
    {
        name: 'Slovenia',
        gl: 'si',
        lang: 'Slovenian',
        hl: 'sl',
        url: 'https://www.google.si/search',
    },
    {
        name: 'Solomon Islands',
        gl: 'sb',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.com.sb/search',
    },
    {
        name: 'Somalia',
        gl: 'so',
        lang: 'Somali',
        hl: 'so',
        url: 'https://www.google.so/search',
    },
    {
        name: 'South Africa',
        gl: 'za',
        lang: 'Afrikaans',
        hl: 'af',
        url: 'https://www.google.co.za/search',
    },
    {
        name: 'South Korea',
        gl: 'kp',
        lang: 'Korean',
        hl: 'ko',
        url: 'https://www.google.co.kr/search',
    },
    {
        name: 'Spain',
        gl: 'es',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.es/search',
    },
    {
        name: 'Sri Lanka',
        gl: 'lk',
        lang: 'Sinhalese',
        hl: 'si',
        url: 'https://www.google.lk/search',
    },
    {
        name: 'Suriname',
        gl: 'sr',
        lang: 'Dutch',
        hl: 'nl',
        url: 'https://www.google.sr/search',
    },
    {
        name: 'Sweden',
        gl: 'se',
        lang: 'Swedish',
        hl: 'sv',
        url: 'https://www.google.se/search',
    },
    {
        name: 'Switzerland',
        gl: 'ch',
        lang: 'German',
        hl: 'de',
        url: 'https://www.google.ch/search',
    },
    {
        name: 'Taiwan',
        gl: 'tw',
        lang: 'Chinese (Traditional)',
        hl: 'zh-TW',
        url: 'https://www.google.com.tw/search',
    },
    {
        name: 'Tajikistan',
        gl: 'tj',
        lang: 'Tajik',
        hl: 'tg',
        url: 'https://www.google.com.tj/search',
    },
    {
        name: 'Tanzania',
        gl: 'tz',
        lang: 'Swahili',
        hl: 'sw',
        url: 'https://www.google.co.tz/search',
    },
    {
        name: 'Thailand',
        gl: 'th',
        lang: 'Thai',
        hl: 'th',
        url: 'https://www.google.co.th/search',
    },
    {
        name: 'Timor-Leste',
        gl: 'tl',
        lang: 'Portuguese',
        hl: 'pt',
        url: 'https://www.google.tl/search',
    },
    {
        name: 'Togo',
        gl: 'tg',
        lang: 'French',
        hl: 'fr',
        url: 'https://www.google.tg/search',
    },
    {
        name: 'Tokelau',
        gl: 'tk',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.tk/search',
    },
    {
        name: 'Tonga',
        gl: 'to',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.to/search',
    },
    {
        name: 'Trinidad and Tobago',
        gl: 'tt',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.tt/search',
    },
    {
        name: 'Tunisia',
        gl: 'tn',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.tn/search',
    },
    {
        name: 'Turkey',
        gl: 'tr',
        lang: 'Turkish',
        hl: 'tr',
        url: 'https://www.google.com.tr/search',
    },
    {
        name: 'Turkmenistan',
        gl: 'tm',
        lang: 'Turkmen',
        hl: 'tk',
        url: 'https://www.google.tm/search',
    },
    {
        name: 'Uganda',
        gl: 'ug',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.co.ug/search',
    },
    {
        name: 'Ukraine',
        gl: 'ua',
        lang: 'Ukrainian',
        hl: 'uk',
        url: 'https://www.google.com.ua/search',
    },
    {
        name: 'United Arab Emirates',
        gl: 'ae',
        lang: 'Arabic',
        hl: 'ar',
        url: 'https://www.google.ae/search',
    },
    {
        name: 'United Kingdom',
        gl: 'gb',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.co.uk/search',
    },
    {
        name: 'Uruguay',
        gl: 'uy',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.com.uy/search',
    },
    {
        name: 'US Virgin Islands',
        gl: 'vi',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.co.vi/search',
    },
    {
        name: 'Uzbekistan',
        gl: 'uz',
        lang: 'Uzbek',
        hl: 'uz',
        url: 'https://www.google.co.uz/search',
    },
    {
        name: 'Venezuela',
        gl: 've',
        lang: 'Spanish',
        hl: 'es',
        url: 'https://www.google.co.ve/search',
    },
    {
        name: 'Vietnam',
        gl: 'vn',
        lang: 'Vietnamese',
        hl: 'vi',
        url: 'https://www.google.com.vn/search',
    },
    {
        name: 'Zambia',
        gl: 'zm',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.co.zm/search',
    },
    {
        name: 'Zimbabwe',
        gl: 'zw',
        lang: 'English',
        hl: 'en',
        url: 'https://www.google.co.zw/search',
    },
];
