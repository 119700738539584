import moment from 'moment';

export const createResource = ({ startDate, endDate, url, type }) => {
    return `{
        "aggregationType": "AUTO",
        "dataState": "ALL",
        "startDate": "${moment(startDate).format('YYYY-MM-DD')}",
        "endDate": "${moment(endDate).format('YYYY-MM-DD')}",
        "type": "${type}",
        "dimensions": [
            "date"
        ],
        "dimensionFilterGroups": [
            {
            "filters": [
                {
                "dimension": "page",
                "operator": "EQUALS",
                "expression": "${url}"
                }
            ]
            }
        ],
        "rowLimit": 1000
    }`;
};

export const createResourceSearch = ({ startDate, endDate, url }) => {
    return `
        {
            "aggregationType": "AUTO",
            "dataState": "ALL",
            "startDate": "${moment(startDate).format('YYYY-MM-DD')}",
            "endDate": "${moment(endDate).format('YYYY-MM-DD')}",
            "type": "WEB",
            "dimensions": [
                "page",
                "query"
                ],
            "dimensionFilterGroups": [
                {
                "filters": [
                    {
                        "dimension": "page",
                        "operator": "EQUALS",
                        "expression": "${url}"
                        }
                ]
                }
            ],
        "rowLimit": 1000
        }`;
};
export const createResourceDeviceTraffic = ({ startDate, endDate, url }) => {
    return `
        {
            "aggregationType": "AUTO",
            "dataState": "ALL",
            "startDate": "${moment(startDate).format('YYYY-MM-DD')}",
            "endDate": "${moment(endDate).format('YYYY-MM-DD')}",
            "type": "WEB",
            "dimensions": [
                    "device"
                ],
            "dimensionFilterGroups": [
                {
                "filters": [
                    {
                        "dimension": "page",
                        "operator": "EQUALS",
                        "expression": "${url}"
                        }
                ]
                }
            ],
        "rowLimit": 1000
        }`;
};

export const createResourceSearchAppearance = ({ startDate, endDate, url }) => {
    return `
        {
            "aggregationType": "AUTO",
            "dataState": "ALL",
            "startDate": "${moment(startDate).format('YYYY-MM-DD')}",
            "endDate": "${moment(endDate).format('YYYY-MM-DD')}",
            "type": "WEB",
            "dimensions": [
                    "searchAppearance"
                ],
            "dimensionFilterGroups": [
                {
                "filters": [
                    {
                        "dimension": "page",
                        "operator": "EQUALS",
                        "expression": "${url}"
                        }
                ]
                }
            ],
        "rowLimit": 1000
        }`;
};

export const createResourceTrafficPerCountry = ({ startDate, endDate, url }) => {
    return `
        {
            "aggregationType": "AUTO",
            "dataState": "ALL",
            "startDate": "${moment(startDate).format('YYYY-MM-DD')}",
            "endDate": "${moment(endDate).format('YYYY-MM-DD')}",
            "type": "WEB",
            "dimensions": [
                    "country"
                ],
            "dimensionFilterGroups": [
                {
                "filters": [
                    {
                        "dimension": "page",
                        "operator": "EQUALS",
                        "expression": "${url}"
                        }
                ]
                }
            ],
        "rowLimit": 1000
        }`;
};

export const createResourceTrafficPerCountry2 = ({ startDate, endDate, url }) => {
    return `
        {
            "aggregationType": "AUTO",
            "dataState": "ALL",
            "startDate": "${moment(startDate).format('YYYY-MM-DD')}",
            "endDate": "${moment(endDate).format('YYYY-MM-DD')}",
            "type": "discover",
            "dimensions": [
                "country"
                ],
            "dimensionFilterGroups": [
                {
                "filters": [
                    {
                        "dimension": "page",
                        "operator": "EQUALS",
                        "expression": "${url}"
                        }
                ]
                }
            ],
            "rowLimit": 1000
        }`;
};
