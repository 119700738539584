import React from 'react';
import Domain from './steps/Domain';
import Email from './steps/Email';
import Confirm from './steps/Confirm';
import Modal from './Modal';

class Registration extends React.Component {
    constructor(props) {
        super();

        this.state = {
            active: props.active == 'true' || props.active == true ? true : false,
            data: {},
            step: 0,
        };

        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
    }

    next(data) {
        const newState = { ...this.state };
        Object.assign(newState, data);
        newState.step += 1;

        this.setState(newState);
    }

    back() {
        const newState = { ...this.state };
        newState.step -= 1;

        this.setState(newState);
    }

    render() {
        let steps = [
            <Domain data={this.state.data} next={this.next} back={this.back} key={0} />,
            <Email data={this.state.data} next={this.next} back={this.back} key={1} />,
            <Confirm data={this.state.data} next={this.next} back={this.back} key={2} />,
        ];

        return (
            <div>
                <Modal active={this.state.active} btntext="+ Add Domain">
                    <div className="card text-dark">
                        <div className="card-body" style={{ padding: '30px' }}>
                            {steps[this.state.step]}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Registration;
