import DataTable from '../../../../../components/Datatable';
import CheckData from '../../../../../components/CatchError';
import LoadMoreComponent from '../../../../../components/LoadMoreComponent';
import Loading from '../../../../../components/Loading';
import { downloadAllCsv } from '../../../../../helpers/downloadAll';
import { getSortKeys, setAdditionalStyles } from '../../../../../helpers/table';
import { opportunities } from './config';
import { COLUMNS, EXTRA_HEADER, getScore } from './keys';
import query from './query';
import { v4 as uuid } from 'uuid';
import CommerceFilterIntent from '../../../../../components/CommerceFilterIntent';
import { changeIntent } from '../../../../../helpers/commerce';

class Table extends LoadMoreComponent {
    constructor(props) {
        super();
        this.exportAll = this.exportAll.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.customForceRun = this.customForceRun.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);

        this.url = new URL(window.location);
        this.keyword = this.url.searchParams.get('keyword');
        this.intent = this.url.searchParams.get('intent');

        const params = {
            project_id: props.project_id,
            offset: 0,
            own_domain: props.own_domain,
            competitors: props.competitors,
        };

        if (this.intent) {
            params.intent_filter = this.intent?.split(',');
        }

        if (!props.data) this.query = query(params);
        this.offsetStep = 1000;
    }

    searchFunction(data, search) {
        return data.filter((item) =>
            item?.cluster_name?.toLowerCase()?.includes(search.toLowerCase()),
        );
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDatas({ key: 'contentGaps', value: this.state.data });
        }

        if (
            prevProps.own_domain !== this.props.own_domain ||
            JSON.stringify(prevProps.competitors) !== JSON.stringify(this.props.competitors)
        ) {
            this.query = query({
                project_id: this.props.project_id,
                offset: 0,
                own_domain: this.props.own_domain,
                competitors: this.props.competitors,
            });
            this.setState({
                loading: true,
                offset: 0,
                allData: [],
            });
            this.props.updateDatas({ key: 'contentGaps', empty: true });
            this.query.bind(this.setState.bind(this));
            await this.query.update();
        }
    }

    customForceRun() {
        this.forceRun();
        this.setState({
            allData: [],
            data: null,
        });
        this.props.updateDatas({ key: 'contentGaps', empty: true });
    }

    onFilter({ toggle }) {
        let data = this.state.allData?.length ? this.state.allData : this.props.data;

        const versions = {
            all: data,
            untapped: data.filter((item) => !item.own_urls), // what is this?
            high_search_volume: data.sort((a, b) => b.total_sv - a.total_sv)?.slice(0, 20),
            low_competition: data
                .sort((a, b) => a.median_keyword_difficulty - b.median_keyword_difficulty)
                ?.slice(0, 20),
            striking_distance: data.filter(
                (item) => item.own_position > 4 && item.own_position <= 10,
            ),
            page_2: data.filter((item) => item.own_position >= 11),
            competitor_only_rankings: data.filter((item) => !item.own_position),
        };

        const sortKeys = {
            untapped: 'score',
            high_search_volume: 'total_sv',
            low_competition: 'median_keyword_difficulty',
        };
        const sortedValues = versions[toggle];
        const sortKey = sortKeys[toggle] ?? '';
        this.setState({
            sortedValues,
            toggle,
            sortKey,
        });
    }

    async exportAll() {
        try {
            this.setState({
                exportAllLoading: true,
            });
            let data = this.state.allData?.length ? this.state.allData : this.props.data;

            downloadAllCsv(
                data,
                'Content Gaps',
                COLUMNS(this.props.project_id, this.props.own_domain, this.props.competitors),
            );
            this.setState({
                exportAllLoading: false,
            });
        } catch (err) {
            this.setState({
                exportAllLoading: false,
            });
            console.log(err);
        }
    }

    async handleSelectChange(values) {
        this.setState({
            select: values,
            loading: true,
            data: null,
            allData: [],
        });

        changeIntent(values);

        this.query = query({
            project_id: this.props.project_id,
            offset: 0,
            own_domain: this.props.own_domain,
            competitors: this.props.competitors,
            intent_filter: values?.map((item) => item.value),
        });
        this.query.bind(this.setState.bind(this));
        this.query.update();
        this.props.updateDatas({ emptyAll: true });
    }

    render() {
        let data = this.state.allData?.length ? this.state.allData : this.props.data;

        data = (this.state.sortedValues ?? data)?.map((item, i) => ({
            ...item,
            rank: i + 1,
        }));

        const updatedData = data?.reduce(
            (acc, curr) => {
                const score = getScore(curr);
                if (!acc.max || acc.max < score) acc.max = score;

                if (!acc.min || acc.min > score) acc.min = score;
                acc.sum = acc?.sum ?? 0 + score;
                const trends = [
                    curr.t1 ?? 0,
                    curr.t2 ?? 0,
                    curr.t3 ?? 0,
                    curr.t4 ?? 0,
                    curr.t5 ?? 0,
                    curr.t6 ?? 0,
                    curr.t7 ?? 0,
                    curr.t8 ?? 0,
                    curr.t9 ?? 0,
                    curr.t10 ?? 0,
                    curr.t11 ?? 0,
                    curr.t12 ?? 0,
                ];
                const item = {
                    ...curr,
                    cluster_name_replaced: curr.cluster_name?.replace(this.props.keyword, ''),
                    trends,
                    score,
                    id: uuid(),
                };
                acc.data.push(item);
                return acc;
            },
            {
                data: [],
            },
        );

        const columns = COLUMNS(
            this.props.project_id,
            this.props.own_domain,
            this.props.competitors,
            this.keyword,
            updatedData?.max,
            updatedData?.min,
            updatedData?.sum / updatedData?.data?.length,
        );

        const sortFns = getSortKeys(columns);

        const extraBtns = () => (
            <CommerceFilterIntent
                select={this.state.select}
                handleSelectChange={this.handleSelectChange}
            />
        );

        return (
            <>
                <div className="card table-main rounded-main overflow-hidden">
                    <div className="card-header">
                        <p className="card-title">Oppoprtunities</p>
                    </div>
                    <div className="card-body">
                        <div className="entity-toggle-container">
                            {opportunities.map((item) => (
                                <div className="toggle-Invalid-items right-border" key={item.value}>
                                    <span>{item.label}</span>
                                    <button
                                        className={`switch-button mr-10 ${
                                            (!this.state.toggle && item.value === 'all') ||
                                            this.state.toggle === item.value
                                                ? 'active'
                                                : 'not-active'
                                        }`}
                                        onClick={() => this.onFilter({ toggle: item.value })}
                                    >
                                        <span className="switch-circle" />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="card rounded-main table-main">
                    <div
                        className="card-header d-flex justify-content-between"
                        style={{ borderRadius: '10px' }}
                    >
                        <div className="card-title text-dark fw-bold">
                            CONTENT GAPS:[{this.props.keyword}]
                        </div>
                    </div>
                    <div className="card-body">
                        <div>
                            <CheckData
                                data={data}
                                loading={!data && this.state.loading}
                                loadMore={this.state.loadMore}
                            >
                                <DataTable
                                    nodes={updatedData?.data ?? []}
                                    sortFns={sortFns}
                                    COLUMNS={columns}
                                    fileName=""
                                    extraBtns={extraBtns}
                                    defaultSortKey={this.state.sortKey ?? ''}
                                    EXTRA_HEADER={EXTRA_HEADER(
                                        this.props.own_domain,
                                        this.props.competitors,
                                    )}
                                    forceRun={this.customForceRun}
                                    searchFunction={this.searchFunction}
                                    additionalStyles={setAdditionalStyles(columns)}
                                    headerHasCustomStyles={true}
                                />
                            </CheckData>
                            {this.state.loadMore ? (
                                <Loading padding={10} />
                            ) : (
                                <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                    {this.state.exportAllLoading ? (
                                        <Loading padding={10} />
                                    ) : (
                                        <button
                                            onClick={this.exportAll}
                                            disabled={this.state.loading}
                                            className="load-more-btn"
                                        >
                                            Export All
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Table;
