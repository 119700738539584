export const addToHidenElements = (element) => {
    if (!window.hidenElements) return (window.hidenElements = [element]);

    if (window.hidenElements?.includes(element)) return;

    window.hidenElements = [...window.hidenElements, element];
};

export const showElements = () => {
    const warningBar = document.getElementById('warning-container');
    const hidenElements = window.hidenElements ?? [];

    hidenElements.forEach((element) => {
        if (element.classList.contains('hide-component')) {
            element.classList.remove('hide-component');
        } else {
            element.style.display = 'block';
        }
    });
    warningBar.style.display = 'none';
};
