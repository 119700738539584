import { Component } from 'react';
import AutocompleteInput from './autoCompleteInput';
import { getDomains } from './query';

class ContentGapsForm extends Component {
    constructor(props) {
        super();
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onChangeCompetitors = this.onChangeCompetitors.bind(this);
        this.addCompetitor = this.addCompetitor.bind(this);
        this.deleteCompetitor = this.deleteCompetitor.bind(this);
        this.onApply = this.onApply.bind(this);

        this.state = {
            own_domain: props.own_domain || '',
            competitors: props.competitors?.length ? [...props.competitors] : [''],
            options: [],
        };
    }
    async componentDidMount() {
        try {
            if (!this.props.domains_for_select) {
                this.query = getDomains({ project_id: this.props.project_id });
                this.query.bind(this.setState.bind(this));
                const domains = await this.query.update();
                this.props.updateDatas({ key: 'domains_for_select', value: domains });
            }
        } catch (err) {
            console.log(err);
        }
    }

    onChangeInput(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }
    onChangeCompetitors(value, index) {
        const competitors = [...this.state.competitors];
        competitors[index] = value;
        this.setState({
            competitors,
        });
    }

    addCompetitor() {
        this.setState({
            competitors: [...this.state.competitors, ''],
        });
    }

    deleteCompetitor(index) {
        const competitors = [...this.state.competitors];
        if (competitors.length === 1) return;
        competitors.splice(index, 1);
        this.setState({
            competitors,
        });
    }

    onApply() {
        if (this.state.own_domain && this.state.competitors?.length !== 0) {
            this.props.onApply({
                own_domain: this.state.own_domain,
                competitors: this.state.competitors,
            });
        }
    }

    render() {
        const domains = this.state.data ?? this.props.domains_for_select;
        return (
            <div className="card rounded-main table-main">
                <div
                    className="card-header d-flex justify-content-between"
                    style={{ borderRadius: '10px' }}
                >
                    <div className="card-title text-dark fw-bold">
                        Content Gaps: [{this.props.keyword}]
                    </div>
                </div>
                <div className="card-body">
                    <div>
                        <div className="form-commerce content-caps-form">
                            <label htmlFor="keyword">
                                <strong>Own Domain</strong>
                            </label>
                            <input
                                name="own_domain"
                                className="form-commerce-input"
                                type="text"
                                id="own_domain"
                                onChange={this.onChangeInput}
                                value={this.state.own_domain}
                            />
                            {this.state.competitors?.map((item, i, arr) => (
                                <AutocompleteInput
                                    key={i}
                                    domains={domains}
                                    addCompetitor={this.addCompetitor}
                                    deleteCompetitor={this.deleteCompetitor}
                                    onChange={this.onChangeCompetitors}
                                    item={item}
                                    showPlus={i === arr.length - 1 && arr.length < 5}
                                    disableMinus={arr.length === 1}
                                    i={i}
                                />
                            ))}
                            <div className="d-flex justify-content-end align-items-center mt-10">
                                <button
                                    className="btn btn-sm text-white bg-purple d-inline-block text-capitalize table-button"
                                    // disabled={this.state.loading || isDisabled}
                                    onClick={this.onApply}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContentGapsForm;
