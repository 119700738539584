import React from 'react';
import ReactModal from '../Modal';

class ConfirmationModal extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <ReactModal modalState={this.props.isOpen} className="width-sm">
                <p>{this.props.message}</p>
                <div className="btn-wrapper">
                    <button
                        type="button"
                        className="button button-outline button-primary width-sm btn"
                        onClick={this.props.onClose}
                        style={{ lineHeight: 'unset' }}
                    >
                        Cancel
                    </button>
                    <button
                        className="button button-filled button-primary width-sm btn"
                        onClick={this.props.onSubmit}
                    >
                        {this.props.buttonName}
                    </button>
                </div>
            </ReactModal>
        );
    }
}

export default ConfirmationModal;
