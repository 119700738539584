import React from 'react';
import DataTable from '../../components/Datatable';
import EditGSCDataModal from '../../components/Modals/EditGSCDataModal';
import Message from '../Message';
import { COLUMNS } from './keys';
import { setDeviceQuery } from './query';
import { scrollForTable } from '../../components/Datatable/utils';

class GSCDataSettings extends React.Component {
    constructor() {
        super();

        this.searchFunction = this.searchFunction.bind(this);
        this.openModal = this.openModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.updateDomainInfo = this.updateDomainInfo.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);

        this.state = {
            isOpen: false,
            isLoading: false,
            message: '',
            message_tag: '',
            selectedItem: null,
        };
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) =>
            item.domain_name?.toLowerCase().includes(search.toLowerCase()),
        );
    }

    openModal(item) {
        this.setState({
            isOpen: true,
            selectedItem: item,
        });
    }

    onCloseModal() {
        this.setState({
            isOpen: false,
            selectedItem: null,
        });
    }

    updateDomainInfo(params) {
        this.setState({
            isLoading: true,
        });

        setDeviceQuery(params, this.props.csrf_token)
            .then((res) => res.json())
            .then(() => window.location.reload())
            .catch(() => {
                this.setState({
                    isLoading: false,
                    message_tag: 'error',
                    message: 'Something went wrong',
                    isOpen: false,
                });
            });
    }

    deleteMessage() {
        this.setState({
            message: '',
            message_tag: '',
        });
    }

    render() {
        const domains = JSON.parse(this.props.domains);
        const columns = COLUMNS({
            openModal: this.openModal,
        });

        return (
            <div>
                <DataTable
                    nodes={domains}
                    sortFns={() => {}}
                    COLUMNS={columns}
                    searchFunction={this.searchFunction}
                    onlyTable
                    hasSort={false}
                    uniqKey="domain_url"
                    forceRun={this.forceRun}
                    additionalStyles={{
                        Table: `
                                --data-table-library_grid-template-columns : minmax(0px, 2fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) !important;
                               ${scrollForTable}
                            `,
                        BaseCell: `  font-family: 'Raleway';
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            padding: 16px 5px !important;
                            color: #0D182C;
                            border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                        
                            &.underline {
                                text-decoration: underline;
                            }
                            `,
                    }}
                />
                {this.state.isOpen && (
                    <EditGSCDataModal
                        isOpen={this.state.isOpen}
                        onClose={this.onCloseModal}
                        onSubmit={this.updateDomainInfo}
                        isLoading={this.state.isLoading}
                        selectedItem={this.state.selectedItem}
                    />
                )}
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tag}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default GSCDataSettings;
