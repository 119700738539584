import React, { Component } from 'react';
import ReportExplainer from '../index';

class ReportExplainerCompetitiveAnalysis extends Component {
    constructor() {
        super();

        this.state = {
            showMore: false,
        };
    }

    render() {
        const text = (
            <div className="report-explainer-custom">
                <div>
                    Our Competitive Analysis report is designed to give you a deep understanding of
                    how your content stacks up against the competition in Google Discover. We break
                    down your performance by specific content categories, using the same
                    standardized categories that Google uses across all its products.
                </div>
                {this.state.showMore ? (
                    <>
                        <div>
                            <span className="report-subtitle">Key Metrics and Insights:</span>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    Category Performance: We analyze your impressions, clicks, and
                                    click-through rate (CTR) for each category, comparing them to
                                    our massive dataset of 600 billion impressions and over 200
                                    million stories.
                                </li>
                                <li>
                                    Benchmarking: This allows you to see how you measure up against
                                    the average performance in your categories, identifying areas
                                    where you're excelling or lagging behind.
                                </li>
                                <li>
                                    Confidence Level: We indicate how reliable the comparison is for
                                    each category based on the number of your pages in our dataset.
                                    More pages mean higher confidence in the results.
                                </li>
                                <li>
                                    Visibility (Median Impressions): If your median impressions are
                                    higher than the dataset average, it means Google is favoring
                                    your content more often in Discover and Search results.
                                </li>
                                <li>
                                    Content Effectiveness (CTR): A higher CTR than the dataset
                                    indicates that your headlines and images are more compelling and
                                    enticing users to click.
                                </li>
                                <li>
                                    Engagement (Clicks): Higher median clicks compared to the
                                    dataset mean more users are actively engaging with your content.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <span className="report-subtitle">Important Considerations:</span>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    Number of Pages: The more pages you have in a category, the more
                                    reliable the comparison. If you have very few pages, the results
                                    might not be statistically significant.
                                </li>
                                <li>
                                    Content Quality: While this report provides valuable insights
                                    into visibility and engagement, it's important to remember that
                                    high-quality, relevant content is the foundation of success in
                                    Google Discover.
                                </li>
                                <li>
                                    Confidence Level: We indicate how reliable the comparison is for
                                    each category based on the number of your pages in our dataset.
                                    More pages mean higher confidence in the results.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <span className="report-subtitle">Example:</span> If your impressions in
                            the "Sports" category are declining, but the overall dataset for sports
                            is increasing, you know it's an issue specific to your site, not a
                            general trend in sports content. This insight helps you pinpoint areas
                            for improvement and focus your optimization efforts.
                        </div>
                        <div>
                            By understanding these metrics and their implications, you can make
                            informed decisions to optimize your content strategy and maximize your
                            success in Google Discover.
                        </div>
                    </>
                ) : (
                    <div className="show-more-btn">
                        <button onClick={() => this.setState({ showMore: true })}>Show More</button>
                    </div>
                )}
            </div>
        );
        return <ReportExplainer isNotText textcontent={text} descriptionHidden={false} title="" />;
    }
}

export default ReportExplainerCompetitiveAnalysis;
