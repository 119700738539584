import { Component } from 'react';
import ReactModal from '../../../components/Modal';
import { defaultWords } from './config';
import { updateQualifiers } from './query';

class QualifiersModal extends Component {
    constructor(props) {
        super();

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            loading: false,
            qualifiers: props.selectedItem?.qualifiers || defaultWords?.join(','),
        };
    }

    onChange(e, key) {
        const value = e.target.value;

        this.setState({
            [key]: value,
        });
    }

    async onSubmit(e) {
        e.preventDefault();
        try {
            this.setState({
                loading: true,
            });
            const qualifiers = this.state.qualifiers
                ?.split(',')
                ?.map((item) => item.trim())
                ?.join();

            const body = {
                project_id: this.props?.selectedItem?.project_id,
                qualifiers,
            };

            const response = await updateQualifiers(body, this.props.csrf_token);
            const data = await response.json();
            if (this.props.updateItemQuiliers)
                this.props.updateItemQuiliers(qualifiers, this.props?.selectedItem?.project_id);
            if (response.status === 200) {
                this.props.onOpenMessage(data?.message, 'success');
                this.props.onClose();
            } else {
                this.props.onOpenMessage(data?.message, 'error');
            }
        } catch (err) {
            console.log(err);
            this.props.onOpenMessage(err?.message, 'error');
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    render() {
        return (
            <ReactModal
                modalState={this.props.isOpen}
                className="width-sm"
                onClose={this.props.onClose}
            >
                <form onSubmit={this.onSubmit}>
                    <h2>Settings</h2>
                    <div className="pages-modal-container">
                        <div className="text-field mb-3">
                            <label>Qualifiers</label>
                            <textarea
                                className="form-control qualifieres-input"
                                onChange={(e) => this.onChange(e, 'qualifiers')}
                                value={this.state.qualifiers}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                            className="button button-filled button-primary width-sm btn"
                            style={{ lineHeight: 'unset', marginRight: '10px' }}
                            disabled={this.state.loading}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="button button-outline button-primary width-sm btn"
                            onClick={this.props.onClose}
                            style={{ lineHeight: 'unset' }}
                        >
                            Close
                        </button>
                    </div>
                </form>
            </ReactModal>
        );
    }
}

export default QualifiersModal;
