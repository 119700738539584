import { Component } from 'react';
import ReactSelect from '../../../components/ReactSelect';
import { COUNTRIES, KEYWORDS_COUNT } from './constants';
import { createProject, getBalance } from './query';
import Message from '../../Message';
import Loading from '../../../components/Loading';

class CommerceCreateProject extends Component {
    constructor(props) {
        super();
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);

        this.estimationPrice = 108;

        this.state = {
            own_domain: '',
            keyword: '',
            country: props.last_country_choice || '',
            number_of_keywords: '',
            balance: 0,
            message: '',
            message_tags: '',
        };
    }

    async componentDidMount() {
        try {
            const res = await getBalance(this.props.csrf_token);
            const balance = await res.json();
            if (res.status === 200) {
                this.setState({
                    balance: balance.result,
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    onChangeInput(e) {
        try {
            this.setState({
                [e.target.name]: e.target.value,
            });
        } catch (err) {
            console.log(err);
        }
    }

    handleSelectChange(type, value) {
        this.setState({
            [type]: value,
        });
    }

    async onSubmit() {
        try {
            const body = {
                own_domain: this.state.own_domain,
                phrase: this.state.keyword?.trim(),
                country: this.state.country,
                display_limit: this.state.number_of_keywords,
            };

            for (let key in body) {
                if (!body[key]) {
                    return this.setState({
                        message_tags: 'error',
                        message: 'Please fill in all fields.',
                    });
                }
            }

            this.setState({
                loading: true,
            });

            const response = await createProject(this.props.csrf_token, body);
            const data = await response.json();

            if (response.status === 200) {
                this.setState({
                    loading: false,
                    message: data?.message,
                    message_tags: 'success',
                });
                window.location.href = '/commerce/';
            } else {
                this.setState({
                    loading: false,
                    message: data?.message,
                    message_tags: 'error',
                });
            }
        } catch (err) {
            this.setState({
                loading: false,
                message: err?.message,
                message_tags: 'error',
            });
        }
    }

    deleteMessage() {
        this.setState({ message: '' });
    }

    render() {
        const countries = COUNTRIES.map((item) => ({ label: item.region, value: item.code }));

        const estimatedValue = this.state.number_of_keywords * this.estimationPrice;

        const isDisabled =
            this.state.own_domain && this.state.number_of_keywords
                ? estimatedValue > this.state.balance
                : true;
        return (
            <>
                <div className="card rounded-main table-main">
                    <div className="card-header" style={{ borderRadius: '10px' }}>
                        <div className="card-title text-dark fw-bold">Commerce</div>
                        <p style={{ color: 'black' }}>
                            Balance - {this.state.balance?.toLocaleString()}
                        </p>
                    </div>
                    <div className="card-body">
                        <div className="form-commerce">
                            <label htmlFor="own_domain">
                                <strong>Own domain</strong>
                            </label>
                            <input
                                name="own_domain"
                                className="form-commerce-input"
                                type="text"
                                id="own_domain"
                                onChange={this.onChangeInput}
                                value={this.state.own_domain}
                            />
                            <label htmlFor="keyword">
                                <strong>Seed Keyword</strong>
                            </label>
                            <input
                                name="keyword"
                                className="form-commerce-input"
                                type="text"
                                id="keyword"
                                onChange={this.onChangeInput}
                                value={this.state.keyword}
                            />
                            <label htmlFor="country_database">
                                <strong>Country</strong>
                            </label>
                            <ReactSelect
                                options={countries}
                                value={countries.find((x) => x.value === this.state.country)}
                                isSearchable={true}
                                onChange={(value) => this.handleSelectChange('country', value)}
                                blurInputOnSelect={true}
                                classname="form-commerce-select"
                            />

                            <label htmlFor="number_of_keywords ">
                                <strong>Number of keywords to analyze</strong>
                            </label>
                            <ReactSelect
                                options={KEYWORDS_COUNT}
                                value={KEYWORDS_COUNT.find(
                                    (x) => x.value === this.state.number_of_keywords,
                                )}
                                isSearchable={false}
                                onChange={(value) =>
                                    this.handleSelectChange('number_of_keywords', value)
                                }
                                blurInputOnSelect={true}
                                classname="form-commerce-select"
                            />

                            {estimatedValue !== 0 && (
                                <div className="estimation-box card-sub-title text-muted fs-7 text-capitalize py-2">
                                    This report will cost you {estimatedValue?.toLocaleString()}{' '}
                                    semrush api credits, you have now{' '}
                                    {this.state.balance?.toLocaleString()}
                                </div>
                            )}

                            <div className="d-flex justify-content-between align-items-center mt-10">
                                <div>{this.state.loading && <Loading />}</div>
                                <button
                                    className="btn btn-sm text-white bg-purple d-inline-block text-capitalize table-button"
                                    disabled={this.state.loading || isDisabled}
                                    onClick={this.onSubmit}
                                >
                                    Go
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tags}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default CommerceCreateProject;
