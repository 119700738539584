import { Component } from 'react';
import { getYears, DEFAULT_YEAR } from './keys';

class ExtraButtons extends Component {
    constructor() {
        super();

        this.years = getYears();
    }

    render() {
        return (
            <div className="categories-view">
                {this.years.map((item) => {
                    const activeYear = this.props.year ?? DEFAULT_YEAR;
                    return (
                        <button
                            className={`categories-view-btn ${
                                activeYear === item.value && 'categories-view-btn-active'
                            }`}
                            key={item.value}
                            onClick={() => this.props.getData(item.value)}
                            disabled={activeYear === item.value}
                        >
                            {item.label}
                        </button>
                    );
                })}
            </div>
        );
    }
}

export default ExtraButtons;
