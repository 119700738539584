export const CrownIcon4 = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled
        className="___SIcon_rdzag-red-team"
        data-ui-name="Crown"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="Crown"
        data-group="m"
        color="blue-500"
        style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.514 5.022 4.002 6l3.683-4.84a.4.4 0 0 1 .637 0L12.005 6l3.49-.987a.4.4 0 0 1 .481.531l-2.84 7.203a.4.4 0 0 1-.372.253H3.242a.4.4 0 0 1-.372-.253L.034 5.554a.4.4 0 0 1 .48-.532Zm2.563 2.796 1.694.474 3.232-4.247 3.235 4.25 1.692-.478L11.675 11H4.332L3.077 7.818Z"
            shapeRendering="geometricPrecision"
        />
        <path d="M4 14a1 1 0 0 0 0 2h8a1 1 0 1 0 0-2H4Z" shapeRendering="geometricPrecision" />
    </svg>
);

export const LinkIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled
        className="___SIcon_rdzag-red-team"
        data-ui-name="Link"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="Link"
        data-group="m"
        color="blue-500"
        style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 8a3 3 0 0 1 3-3h2V3H5a5 5 0 1 0 0 10h2v-2H5a3 3 0 0 1-3-3Zm9-5H9v2h2a3 3 0 0 1 0 6H9v2h2a5 5 0 1 0 0-10ZM5.293 8.707A1 1 0 0 1 6 7h4a1 1 0 1 1 0 2H6a1 1 0 0 1-.707-.293Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const PictureIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled
        className="___SIcon_rdzag-red-team"
        data-ui-name="Picture"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="Picture"
        data-group="m"
        color="blue-500"
        style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
    >
        <path d="M5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" shapeRendering="geometricPrecision" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 2a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h12ZM4 10.23 5.77 12H3v-.77l1-1ZM3 8.51V4h10v3.1l-.59-.58a2 2 0 0 0-2.82 0L6.35 9.75l-.94-.93A2 2 0 0 0 3 8.51Zm10 1.42-2-2L6.93 12H13V9.93Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const VideoAltIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled
        className="___SIcon_rdzag-red-team"
        data-ui-name="VideoAlt"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="VideoAlt"
        data-group="m"
        color="blue-500"
        style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
    >
        <path
            d="M6 5.404v5.198a.4.4 0 0 0 .61.34l4.203-2.605a.4.4 0 0 0 0-.68L6.61 5.063a.4.4 0 0 0-.61.34Z"
            shapeRendering="geometricPrecision"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1C4.14 1 1 4.14 1 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7ZM3 8c0-2.755 2.245-5 5-5s5 2.245 5 5-2.245 5-5 5-5-2.245-5-5Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const People = () => {
    return (
        <svg
            className="___SIcon_rdzag-red-team"
            data-ui-name="ChatQuestion"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="ChatQuestion"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path
                d="M7.403 4.819c0-.326.26-.6.6-.6.34 0 .6.274.6.6 0 .267-.11.492-.3.715-.152.18-.317.317-.495.465-.064.053-.13.108-.198.167a.6.6 0 0 0 .786.906l.125-.104c.188-.156.469-.388.697-.656.305-.359.585-.85.585-1.493 0-.963-.771-1.8-1.8-1.8-1.03 0-1.8.837-1.8 1.8a.6.6 0 0 0 1.2 0ZM8.603 8.419a.6.6 0 1 1-1.2 0 .6.6 0 0 1 1.2 0Z"
                shapeRendering="geometricPrecision"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.047.019H2.004A1.005 1.005 0 0 0 1 1.02v13.014a1 1 0 0 0 .552.891 1.006 1.006 0 0 0 1.004-.09l3.803-2.803h7.637A1.005 1.005 0 0 0 15 11.03V1.02a1 1 0 0 0-.953-1.002Zm-1.004 10.01H6.078c-.217 0-.428.07-.602.2l-2.469 1.823V2.042h10.036v7.988Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const ImagePack = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="Pictures"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Pictures"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path d="M5 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" shapeRendering="geometricPrecision"></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 12V2a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1Zm2-9h9v2.338a2.001 2.001 0 0 0-2.531.369l-3.007 3.34L4.414 8A2 2 0 0 0 2 7.682V3Zm7.955 4.045L11 8.35V11H6.395l3.56-3.955ZM3 9.415L4.586 11H2v-.586l1-1Z"
                shapeRendering="geometricPrecision"
            ></path>
            <path
                d="M2 15a1 1 0 0 1 1-1h11V5a1 1 0 1 1 2 0v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const AIOverview = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="GoogleGenerativeAI"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="GoogleGenerativeAI"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path
                fillRule="evenodd"
                d="M16 8.038c0-2.174-1.828-3.982-4.002-3.96C12.061 1.875 10.243 0 8.038 0c-2.204 0-4.023 1.877-3.96 4.079C1.878 4.015 0 5.834 0 8.039c0 2.204 1.876 4.022 4.077 3.96C4.056 14.171 5.864 16 8.038 16c2.175 0 3.983-1.827 3.962-4 2.173.02 4-1.787 4-3.962ZM2.655 8A6.97 6.97 0 0 0 8 2.655 6.97 6.97 0 0 0 13.345 8 6.97 6.97 0 0 0 8 13.345 6.97 6.97 0 0 0 2.655 8Z"
                clipRule="evenodd"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const FAQItem = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="Question"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Question"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path
                d="M9 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7 6c0-.544.433-1 1-1s1 .456 1 1c0 .445-.182.82-.498 1.192-.255.3-.53.527-.827.774-.107.089-.217.18-.33.278a1 1 0 1 0 1.31 1.512c.057-.05.127-.108.208-.175.315-.26.782-.646 1.162-1.093C10.534 7.89 11 7.07 11 6c0-1.605-1.285-3-3-3S5 4.395 5 6a1 1 0 0 0 2 0Z"
                shapeRendering="geometricPrecision"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm0-2A6 6 0 1 0 8 2a6 6 0 0 0 0 12Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const ReviewsItem = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="FavoriteFilled"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="FavoriteFilled"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.666 5.435a.4.4 0 0 1 .218.675l-3.503 3.539.716 4.893a.4.4 0 0 1-.57.417L8 12.774l-4.527 2.185a.4.4 0 0 1-.57-.417l.716-4.893L.116 6.11a.4.4 0 0 1 .218-.675l4.959-.842L7.647.21a.4.4 0 0 1 .706 0l2.354 4.382 4.96.842Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const IndentedResultItem = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="IndentedResult"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="IndentedResult"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 2a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V2Zm-2 3V3H2v2h9ZM16 10a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4Zm-2 3v-2H5v2h9Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const NewsItem = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="News"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="News"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path
                d="M3 11V3h7v7a1 1 0 1 0 2 0V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v9a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V6a1 1 0 1 0-2 0v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Z"
                shapeRendering="geometricPrecision"
            ></path>
            <path
                d="M5 5a1 1 0 0 0 0 2h3a1 1 0 0 0 0-2H5Zm0 3a1 1 0 0 0 0 2h3a1 1 0 0 0 0-2H5Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const FeaturedVideoItem = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="Video"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Video"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path
                d="M6 5.4v5.198a.4.4 0 0 0 .61.34l4.203-2.605a.4.4 0 0 0 0-.68L6.61 5.06A.4.4 0 0 0 6 5.4Z"
                shapeRendering="geometricPrecision"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 3v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1Zm12 9H3V4h10v8Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const VideoCarouselItem = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="VideoAlt"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="VideoAlt"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path
                d="M6 5.404v5.198a.4.4 0 0 0 .61.34l4.203-2.605a.4.4 0 0 0 0-.68L6.61 5.063a.4.4 0 0 0-.61.34Z"
                shapeRendering="geometricPrecision"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 1C4.14 1 1 4.14 1 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7ZM3 8c0-2.755 2.245-5 5-5s5 2.245 5 5-2.245 5-5 5-5-2.245-5-5Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const LocalPackItem = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="Pin"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Pin"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path d="M8 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" shapeRendering="geometricPrecision"></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0a7.115 7.115 0 0 0-4.94 1.978A6.68 6.68 0 0 0 1 6.784c0 2.763 1.572 4.945 3.038 6.388a15.85 15.85 0 0 0 3.081 2.35l.022.012a.286.286 0 0 1 .005.003h.002l.015.01L8 16l.837-.453.015-.01.007-.003.022-.013a9.021 9.021 0 0 0 .296-.176 15.851 15.851 0 0 0 2.785-2.174C13.428 11.73 15 9.548 15 6.785a6.68 6.68 0 0 0-2.06-4.806A7.116 7.116 0 0 0 8 0ZM4.455 3.398A5.106 5.106 0 0 1 8 1.983c1.335 0 2.61.512 3.545 1.415A4.705 4.705 0 0 1 13 6.784c0 1.994-1.142 3.696-2.447 4.98A13.955 13.955 0 0 1 8 13.735a13.956 13.956 0 0 1-2.553-1.97C4.142 10.48 3 8.778 3 6.784c0-1.265.52-2.483 1.455-3.386Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const KnowledgePanelItem = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="Education"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Education"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.476 1.154a.97.97 0 0 1 1.048 0l7 4.463c.296.19.476.523.476.883V13a1 1 0 1 1-2 0V8.354L12.988 9H13v3.133a1 1 0 0 1-.22.626c-2.4 2.993-7.16 2.993-9.56 0a1 1 0 0 1-.22-.626V9h.012L.476 7.383A1.045 1.045 0 0 1 0 6.5c0-.36.18-.694.476-.883l7-4.463Zm1.048 10.692L11 10.267v1.492c-1.585 1.66-4.415 1.66-6 0v-1.492l2.476 1.58a.97.97 0 0 0 1.048 0ZM2.908 6.5 8 9.747 13.092 6.5 8 3.253 2.908 6.5Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const TopStoriesItem = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="TopStories"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="TopStories"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 11V2h7v9H3ZM1 1a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V1Zm4 3a1 1 0 0 0 0 2h3a1 1 0 0 0 0-2H5ZM4 8a1 1 0 0 1 1-1h3a1 1 0 0 1 0 2H5a1 1 0 0 1-1-1Zm11-3a1 1 0 1 0-2 0v9H5a1 1 0 1 0 0 2h9a1 1 0 0 0 1-1V5Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const RecipesItem = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="Restaurant"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Restaurant"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path
                d="M0 6V1a1 1 0 1 1 2 0v5h2V1a1 1 0 1 1 2 0v5h2V1a1 1 0 1 1 2 0v5a2 2 0 0 1-2 2H6v6a1 1 0 1 1-2 0V8H2a2 2 0 0 1-2-2ZM15.2 0a.8.8 0 0 1 .8.8V14a1 1 0 1 1-2 0V8.465A3.998 3.998 0 0 1 12 5V3.2A3.2 3.2 0 0 1 15.2 0Z"
                shapeRendering="geometricPrecision"
            />
        </svg>
    );
};

export const JobsItem = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="Briefcase"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Briefcase"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h3a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3V3Zm6 0v1H6V3h4Zm-8 7v3h12v-3h-4a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1H2Zm8-2h4V6H2v2h4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1Z"
                shapeRendering="geometricPrecision"
            />
        </svg>
    );
};

export const TwitterItem = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="Twitter"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Twitter"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path
                fillRule="evenodd"
                d="m.974 2 4.889 7.333L1.974 14h2l2.778-3.333L8.974 14h6.052l-4.93-7.347L13.974 2h-2l-2.77 3.324L6.974 2h-6Zm3.737 2 5.333 8h1.231L5.908 4H4.71Z"
                clipRule="evenodd"
                shapeRendering="geometricPrecision"
            />
        </svg>
    );
};

export const TwitterCarouselIcon = (props) => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            width={props.width || 20}
            height={props.height || 20}
            viewBox="0 0 20 20"
            fill="none"
            stroke={props.color || 'currentColor'}
            {...props}
        >
            <path
                fillRule="evenodd"
                d="M5.863 7.333.974 0h6l2.23 3.324L11.974 0h2l-3.878 4.653L15.026 12H8.974L6.752 8.667 3.974 12h-2l3.889-4.667ZM10.044 10 4.711 2h1.197l5.367 8h-1.230Z"
                clipRule="evenodd"
                shapeRendering="geometricPrecision"
            />
            <path
                d="M1.974 14a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4ZM8.974 15a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM13.974 14a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
                shapeRendering="geometricPrecision"
            />
        </svg>
    );
};

export const ListCheckIcon = (props) => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            width={props.width || 20}
            height={props.height || 20}
            viewBox="0 0 20 20"
            fill="none"
            stroke={props.color || 'currentColor'}
            {...props}
        >
            <path
                d="M4.685 1.293a.992.992 0 0 0-1.408 0L1.991 2.586l-.292-.293a.992.992 0 0 0-1.407 0 1.003 1.003 0 0 0 0 1.414l.995 1a.992.992 0 0 0 1.407 0l1.991-2a1.003 1.003 0 0 0 0-1.414Zm0 5a.992.992 0 0 0-1.408 0L1.991 7.586l-.292-.293a.992.992 0 0 0-1.407 0 1.003 1.003 0 0 0 0 1.414l.995 1a.992.992 0 0 0 1.407 0l1.991-2a1.003 1.003 0 0 0 0-1.414Zm-1.408 5a.992.992 0 0 1 1.408 0c.389.39.389 1.024 0 1.414l-1.99 2a.992.992 0 0 1-1.408 0l-.995-1a1.003 1.003 0 0 1 0-1.414.992.992 0 0 1 1.407 0l.292.293 1.286-1.293ZM7.037 2a.998.998 0 0 0-.995 1c0 .552.446 1 .995 1h6.968c.55 0 .995-.448.995-1s-.446-1-.995-1H7.037Zm0 5a.998.998 0 0 0-.995 1c0 .552.446 1 .995 1h6.968c.55 0 .995-.448.995-1s-.446-1-.995-1H7.037Zm-.995 6c0-.552.446-1 .995-1h6.968c.55 0 .995.448.995 1s-.446 1-.995 1H7.037a.998.998 0 0 1-.995-1Z"
                shapeRendering="geometricPrecision"
            />
        </svg>
    );
};

export const FolderOpenIcon = (props) => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            width={props.width || 20}
            height={props.height || 20}
            viewBox="0 0 20 20"
            fill="none"
            stroke={props.color || 'currentColor'}
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 4a2 2 0 0 1 2-2h2.736l2.948 2.063H12a2 2 0 0 1 2 2V7h1a1 1 0 0 1 .857 1.514l-3 5A1 1 0 0 1 12 14H1a1 1 0 0 1-1-1V4Zm12 2.063V7H5a1 1 0 0 0-.857.486L2 11.056V4h2.106l2.947 2.063H12ZM5.566 9l-1.8 3h7.668l1.8-3H5.566Z"
                shapeRendering="geometricPrecision"
            />
        </svg>
    );
};

export const ChatIcon1 = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled=""
            className="___SIcon_rdzag-red-team"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Chat"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path
                d="M2 15a1 1 0 0 1-.45-.11A1 1 0 0 1 1 14V1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H6.39L2.6 14.79A1 1 0 0 1 2 15ZM3 2v10l2.46-1.82a1 1 0 0 1 .6-.2H13v-8L3 2Z"
                shapeRendering="geometricPrecision"
            />
        </svg>
    );
};

export const TagIcon1 = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled=""
            className="___SIcon_rdzag-red-team"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Tag"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path d="M12 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" shapeRendering="geometricPrecision" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.163.874A2.984 2.984 0 0 1 10.273 0h4.732c.55 0 .995.445.995.995v4.732c0 .791-.314 1.55-.874 2.11l-7.872 7.872a.995.995 0 0 1-1.407 0L.291 10.153a.995.995 0 0 1 0-1.407L8.163.874ZM2.401 9.449l4.15 4.15 7.168-7.169a.994.994 0 0 0 .292-.703V1.989h-3.738a.995.995 0 0 0-.703.292L2.401 9.449Z"
                shapeRendering="geometricPrecision"
            />
        </svg>
    );
};

export const FindResultsOnIcon1 = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled=""
            className="___SIcon_rdzag-red-team"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="FindResultsOn"
            data-group="m"
            color="blue-500"
            style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0a7 7 0 1 0 4.192 12.606l2.1 2.101a1 1 0 0 0 1.415-1.414l-2.1-2.1A7 7 0 0 0 8 0ZM3 7a5 5 0 1 1 10 0A5 5 0 0 1 3 7Zm6.293-1.707a1 1 0 0 1 1.414 1.414l-2.5 2.5a1 1 0 0 1-1.414 0l-1.5-1.5a1 1 0 0 1 1.414-1.414l.793.793 1.793-1.793Z"
                shapeRendering="geometricPrecision"
            />
        </svg>
    );
};

export const OrganicCarouselIcon1 = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        data-ui-name="OrganicCarousel"
        width="20"
        height="17"
        viewBox="0 0 20 17"
        focusable="false"
        data-name="OrganicCarousel"
        data-group="m"
        color="blue-500"
        style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
    >
        <path
            d="M4.02 5.021a1 1 0 0 1 .998-1h5.99a1 1 0 0 1 0 2h-5.99a1 1 0 0 1-.998-1ZM5.018 7.021a1 1 0 0 0 0 2h3.993a1 1 0 0 0 0-2H5.018Z"
            shapeRendering="geometricPrecision"
        />
        <path
            fillRule="evenodd"
            d="M1.026 1.021a1 1 0 0 1 .998-1h11.978a1 1 0 0 1 .998 1v11a1 1 0 0 1-.998 1H2.024a1 1 0 0 1-.998-1v-11Zm1.996 10v-9h9.982v9H3.022Z"
            clipRule="evenodd"
            shapeRendering="geometricPrecision"
        />
        <path
            d="M1.998 14.021a1 1 0 0 0 0 2h3.993a1 1 0 0 0 0-2H1.998ZM8.985 15.021a1 1 0 1 1 1.998-.002 1 1 0 0 1-1.998.002ZM12.978 15.021a.999.999 0 1 1 1.998-.002.999.999 0 0 1-1.998.002Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const QuestionsAnswersIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        data-ui-name="QuestionsAnswers"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="QuestionsAnswers"
        data-group="m"
        color="blue-500"
        style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
    >
        <path
            d="M8.003 4.221c-.34 0-.6.274-.6.6a.6.6 0 0 1-1.2 0c0-.963.77-1.8 1.8-1.8 1.028 0 1.8.837 1.8 1.8 0 .643-.28 1.134-.585 1.493-.228.268-.509.5-.697.656l-.125.104a.6.6 0 0 1-.786-.906c.067-.06.134-.114.198-.167.178-.148.343-.285.496-.465.19-.223.299-.448.299-.715 0-.326-.26-.6-.6-.6ZM8.003 9.021a.6.6 0 1 0 0-1.2.6.6 0 0 0 0 1.2Z"
            shapeRendering="geometricPrecision"
        />
        <path
            fillRule="evenodd"
            d="M2 3.021a3 3 0 0 1 3-3h8a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H5a3 3 0 0 1-3-3v-9Zm3-1h7v8H4v-7a1 1 0 0 1 1-1Zm-1 10h8v1H5a1 1 0 0 1-1-1Z"
            clipRule="evenodd"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const ThingsToKnowIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        data-ui-name="ListBullet"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="ListBullet"
        data-group="m"
        color="blue-500"
        style={{ '--color_rdzag': 'var(--blue-500, #006dca)' }}
    >
        <path
            d="M3 2H1v2h2V2Zm0 5H1v2h2V7Zm-2 5h2v2H1v-2ZM15 2H5v2h10V2Zm0 5H5v2h10V7ZM5 12h10v2H5v-2Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const CrownIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled
        className="___SIcon_rdzag-red-team"
        data-ui-name="Crown"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="Crown"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.514 5.022 4.002 6l3.683-4.84a.4.4 0 0 1 .637 0L12.005 6l3.49-.987a.4.4 0 0 1 .481.531l-2.84 7.203a.4.4 0 0 1-.372.253H3.242a.4.4 0 0 1-.372-.253L.034 5.554a.4.4 0 0 1 .48-.532Zm2.563 2.796 1.694.474 3.232-4.247 3.235 4.25 1.692-.478L11.675 11H4.332L3.077 7.818Z"
            shapeRendering="geometricPrecision"
        ></path>
        <path d="M4 14a1 1 0 0 0 0 2h8a1 1 0 1 0 0-2H4Z" shapeRendering="geometricPrecision"></path>
    </svg>
);

export const LinkIcon1 = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled
        className="___SIcon_rdzag-red-team"
        data-ui-name="Link"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="Link"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 8a3 3 0 0 1 3-3h2V3H5a5 5 0 1 0 0 10h2v-2H5a3 3 0 0 1-3-3Zm9-5H9v2h2a3 3 0 0 1 0 6H9v2h2a5 5 0 1 0 0-10ZM5.293 8.707A1 1 0 0 1 6 7h4a1 1 0 1 1 0 2H6a1 1 0 0 1-.707-.293Z"
            shapeRendering="geometricPrecision"
        ></path>
    </svg>
);

export const FavoriteFilledIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled
        className="___SIcon_rdzag-red-team"
        data-ui-name="FavoriteFilled"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="FavoriteFilled"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            fillRule="evenodd"
            d="M15.666 5.435a.4.4 0 0 1 .218.675l-3.503 3.539.716 4.893a.4.4 0 0 1-.57.417L8 12.774l-4.527 2.185a.4.4 0 0 1-.57-.417l.716-4.893L.116 6.11a.4.4 0 0 1 .218-.675l4.959-.842L7.647.21a.4.4 0 0 1 .706 0l2.354 4.382 4.96.842Z"
            clipRule="evenodd"
            shapeRendering="geometricPrecision"
        ></path>
    </svg>
);

export const QuestionIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled
        className="___SIcon_rdzag-red-team"
        data-ui-name="Question"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="Question"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            d="M9 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7 6c0-.544.433-1 1-1s1 .456 1 1c0 .445-.182.82-.498 1.192-.255.3-.53.527-.827.774-.107.089-.217.18-.33.278a1 1 0 1 0 1.31 1.512c.057-.05.127-.108.208-.175.315-.26.782-.646 1.162-1.093C10.534 7.89 11 7.07 11 6c0-1.605-1.285-3-3-3S5 4.395 5 6a1 1 0 0 0 2 0Z"
            shapeRendering="geometricPrecision"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm0-2A6 6 0 1 0 8 2a6 6 0 0 0 0 12Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const PictureIcon1 = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled
        className="___SIcon_rdzag-red-team"
        data-ui-name="Picture"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="Picture"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path d="M5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" shapeRendering="geometricPrecision" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 2a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h12ZM4 10.23 5.77 12H3v-.77l1-1ZM3 8.51V4h10v3.1l-.59-.58a2 2 0 0 0-2.82 0L6.35 9.75l-.94-.93A2 2 0 0 0 3 8.51Zm10 1.42-2-2L6.93 12H13V9.93Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const VideoAltIcon1 = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled
        className="___SIcon_rdzag-red-team"
        data-ui-name="VideoAlt"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="VideoAlt"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            d="M6 5.404v5.198a.4.4 0 0 0 .61.34l4.203-2.605a.4.4 0 0 0 0-.68L6.61 5.063a.4.4 0 0 0-.61.34Z"
            shapeRendering="geometricPrecision"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1C4.14 1 1 4.14 1 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7ZM3 8c0-2.755 2.245-5 5-5s5 2.245 5 5-2.245 5-5 5-5-2.245-5-5Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const VideoAltIcon2 = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        data-ui-name="VideoAlt"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="VideoAlt"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            d="M6 5.404v5.198a.4.4 0 0 0 .61.34l4.203-2.605a.4.4 0 0 0 0-.68L6.61 5.063a.4.4 0 0 0-.61.34Z"
            shapeRendering="geometricPrecision"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1C4.14 1 1 4.14 1 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7ZM3 8c0-2.755 2.245-5 5-5s5 2.245 5 5-2.245 5-5 5-5-2.245-5-5Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const ChatQuestionIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        data-ui-name="ChatQuestion"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="ChatQuestion"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            d="M7.403 4.819c0-.326.26-.6.6-.6.34 0 .6.274.6.6 0 .267-.11.492-.3.715-.152.18-.317.317-.495.465-.064.053-.13.108-.198.167a.6.6 0 0 0 .786.906l.125-.104c.188-.156.469-.388.697-.656.305-.359.585-.85.585-1.493 0-.963-.771-1.8-1.8-1.8-1.03 0-1.8.837-1.8 1.8a.6.6 0 0 0 1.2 0ZM8.603 8.419a.6.6 0 1 1-1.2 0 .6.6 0 0 1 1.2 0Z"
            shapeRendering="geometricPrecision"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.047.019H2.004A1.005 1.005 0 0 0 1 1.02v13.014a1 1 0 0 0 .552.891 1.006 1.006 0 0 0 1.004-.09l3.803-2.803h7.637A1.005 1.005 0 0 0 15 11.03V1.02a1 1 0 0 0-.953-1.002Zm-1.004 10.01H6.078c-.217 0-.428.07-.602.2l-2.469 1.823V2.042h10.036v7.988Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const PinIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        data-ui-name="Pin"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="Pin"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path d="M8 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" shapeRendering="geometricPrecision" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 0a7.115 7.115 0 0 0-4.94 1.978A6.68 6.68 0 0 0 1 6.784c0 2.763 1.572 4.945 3.038 6.388a15.85 15.85 0 0 0 3.081 2.35l.022.012a.286.286 0 0 1 .005.003h.002l.015.01L8 16l.837-.453.015-.01.007-.003.022-.013a9.021 9.021 0 0 0 .296-.176 15.851 15.851 0 0 0 2.785-2.174C13.428 11.73 15 9.548 15 6.785a6.68 6.68 0 0 0-2.06-4.806A7.116 7.116 0 0 0 8 0ZM4.455 3.398A5.106 5.106 0 0 1 8 1.983c1.335 0 2.61.512 3.545 1.415A4.705 4.705 0 0 1 13 6.784c0 1.994-1.142 3.696-2.447 4.98A13.955 13.955 0 0 1 8 13.735a13.956 13.956 0 0 1-2.553-1.97C4.142 10.48 3 8.778 3 6.784c0-1.265.52-2.483 1.455-3.386Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const EducationIcon = ({ className }) => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        className={className}
        data-ui-name="Education"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.476 1.154a.97.97 0 0 1 1.048 0l7 4.463c.296.19.476.523.476.883V13a1 1 0 1 1-2 0V8.354L12.988 9H13v3.133a1 1 0 0 1-.22.626c-2.4 2.993-7.16 2.993-9.56 0a1 1 0 0 1-.22-.626V9h.012L.476 7.383A1.045 1.045 0 0 1 0 6.5c0-.36.18-.694.476-.883l7-4.463Zm1.048 10.692L11 10.267v1.492c-1.585 1.66-4.415 1.66-6 0v-1.492l2.476 1.58a.97.97 0 0 0 1.048 0ZM2.908 6.5 8 9.747 13.092 6.5 8 3.253 2.908 6.5Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const CardsIcon = ({ className }) => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        className={className}
        data-ui-name="Cards"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 3h1a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2H2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h1a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2ZM2 5v6h1V5H2Zm3-2v10h6V3H5Zm9 2h-1v6h1V5ZM9 5H7a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2Zm0 3H7a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const TopStoriesIcon = ({ className }) => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        className={className}
        data-ui-name="TopStories"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 11V2h7v9H3ZM1 1a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V1Zm4 3a1 1 0 0 0 0 2h3a1 1 0 0 0 0-2H5ZM4 8a1 1 0 0 1 1-1h3a1 1 0 0 1 0 2H5a1 1 0 0 1-1-1Zm11-3a1 1 0 1 0-2 0v9H5a1 1 0 1 0 0 2h9a1 1 0 0 0 1-1V5Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const RestaurantIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        data-ui-name="Restaurant"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="Restaurant"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            d="M0 6V1a1 1 0 1 1 2 0v5h2V1a1 1 0 1 1 2 0v5h2V1a1 1 0 1 1 2 0v5a2 2 0 0 1-2 2H6v6a1 1 0 1 1-2 0V8H2a2 2 0 0 1-2-2ZM15.2 0a.8.8 0 0 1 .8.8V14a1 1 0 1 1-2 0V8.465A3.998 3.998 0 0 1 12 5V3.2A3.2 3.2 0 0 1 15.2 0Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const BriefcaseIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        data-ui-name="Briefcase"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="Briefcase"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h3a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3V3Zm6 0v1H6V3h4Zm-8 7v3h12v-3h-4a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1H2Zm8-2h4V6H2v2h4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const TwitterIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        data-ui-name="Twitter"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="Twitter"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            fillRule="evenodd"
            d="m.974 2 4.889 7.333L1.974 14h2l2.778-3.333L8.974 14h6.052l-4.93-7.347L13.974 2h-2l-2.77 3.324L6.974 2h-6Zm3.737 2 5.333 8h1.231L5.908 4H4.71Z"
            clipRule="evenodd"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const TwitterCarousel = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="TwitterCarousel"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            fillRule="evenodd"
            d="M5.863 7.333.974 0h6l2.23 3.324L11.974 0h2l-3.878 4.653L15.026 12H8.974L6.752 8.667 3.974 12h-2l3.889-4.667ZM10.044 10 4.711 2h1.197l5.367 8h-1.23Z"
            clipRule="evenodd"
            shapeRendering="geometricPrecision"
        />
        <path
            d="M1.974 14a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4ZM8.974 15a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM13.974 14a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const AddressPack = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="AddressPack"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path d="M0 1.031a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-3ZM0 7.031a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1ZM0 10.026a1 1 0 0 1 1-1h11a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1ZM4.236 13.64a.107.107 0 0 0-.058-.18l-1.323-.225-.628-1.168a.107.107 0 0 0-.188 0l-.628 1.168-1.322.225a.107.107 0 0 0-.058.18l.934.943-.191 1.305a.107.107 0 0 0 .152.111l1.207-.582 1.208.582a.107.107 0 0 0 .152-.111l-.191-1.305.934-.943ZM9.236 13.64a.107.107 0 0 0-.058-.18l-1.323-.225-.628-1.168a.107.107 0 0 0-.188 0l-.628 1.168-1.322.225a.107.107 0 0 0-.058.18l.934.943-.191 1.305a.107.107 0 0 0 .152.111l1.207-.582 1.208.582a.107.107 0 0 0 .152-.111l-.191-1.305.934-.943ZM14.178 13.46a.106.106 0 0 1 .058.18l-.934.943.19 1.305a.107.107 0 0 1-.152.111l-1.207-.582-1.207.582a.107.107 0 0 1-.152-.111l.191-1.305-.934-.943a.107.107 0 0 1 .058-.18l1.322-.225.628-1.168a.107.107 0 0 1 .188 0l.628 1.168 1.323.225Z" />
    </svg>
);

export const RelatedSearches = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="RelatedSearches"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path d="M1 5a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2H1ZM0 10a1 1 0 0 1 1-1h5a1 1 0 0 1 0 2H1a1 1 0 0 1-1-1ZM1 13a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2H1ZM10 5a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2h-5ZM9 10a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1ZM10 13a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5ZM1 1a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2H1ZM10 1a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2h-5Z" />
    </svg>
);

export const RelatedProductsIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        data-ui-name="RelatedProducts"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="RelatedProducts"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 1a1 1 0 0 1 1-1h1.8A1.2 1.2 0 0 1 6 1.2v.801h5.744a1.2 1.2 0 0 1 1.164 1.492l-.9 3.602a1.2 1.2 0 0 1-1.164.91H5.2A1.2 1.2 0 0 1 4 6.803V2.002H3A1 1 0 0 1 2 1Zm4 7.004a1 1 0 1 1 0 2.001 1 1 0 0 1 0-2Zm4 0a1 1 0 1 1 0 2.001 1 1 0 0 1 0-2ZM6 4.002v2.001h4.22l.5-2H6Zm-4 8.005a1 1 0 0 1 1-1h10a1 1 0 0 1 0 2H3a1 1 0 0 1-1-1Zm0 2.992a1 1 0 0 1 1-1h7A1 1 0 0 1 10 16H3a1 1 0 0 1-1-1Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const PeopleAlsoSearchIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        data-ui-name="PositionTop"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="PositionTop"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            d="M5 7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H5ZM4 9a1 1 0 0 0 1 1h6a1 1 0 1 0 0-2H5a1 1 0 0 0-1 1Zm1 4a1 1 0 1 1 0-2h6a1 1 0 1 1 0 2H5Z"
            shapeRendering="geometricPrecision"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v14ZM2 2v12h12V2H2Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const PopularProductsIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        data-ui-name="PopularProducts"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="PopularProducts"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            fillRule="evenodd"
            d="M1.867 1.031a1 1 0 0 1 1-1h1.8a1.2 1.2 0 0 1 1.2 1.2v.8h5.744a1.2 1.2 0 0 1 1.164 1.491l-.9 3.6a1.2 1.2 0 0 1-1.165.91H5.067a1.2 1.2 0 0 1-1.2-1.2v-4.8h-1a1 1 0 0 1-1-1Zm4 3v2h4.219l.5-2h-4.72Z"
            clipRule="evenodd"
            shapeRendering="geometricPrecision"
        />
        <path
            d="M5.867 8.031a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM9.867 8.031a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM5.102 13.64a.107.107 0 0 0-.058-.18l-1.322-.225-.628-1.168a.107.107 0 0 0-.188 0l-.628 1.168-1.322.225a.107.107 0 0 0-.058.18l.934.943-.191 1.305a.107.107 0 0 0 .152.111L3 15.417l1.207.582a.107.107 0 0 0 .152-.111l-.19-1.305.933-.943ZM10.102 13.64a.106.106 0 0 0-.058-.18l-1.322-.225-.628-1.168a.107.107 0 0 0-.188 0l-.628 1.168-1.322.225a.107.107 0 0 0-.058.18l.934.943-.191 1.305a.107.107 0 0 0 .152.111L8 15.417l1.207.582a.107.107 0 0 0 .152-.111l-.19-1.305.933-.943ZM15.044 13.46a.106.106 0 0 1 .058.18l-.934.943.191 1.305a.107.107 0 0 1-.152.111L13 15.417l-1.207.582a.107.107 0 0 1-.152-.111l.19-1.305-.933-.943a.107.107 0 0 1 .058-.18l1.322-.225.628-1.168a.107.107 0 0 1 .188 0l.628 1.168 1.322.225Z"
            shapeRendering="geometricPrecision"
        />
    </svg>
);

export const SearchResultsItem = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled=""
            className="___SIcon_rdzag-red-team"
            data-ui-name="SeeResultsAbout"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="SeeResultsAbout"
            data-group="m"
            color="gray-400"
            style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
        >
            <path
                d="M2 14V2h10v7l2-2V1a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h6l-2-2H2Z"
                shapeRendering="geometricPrecision"
            ></path>
            <path
                d="m10 13.586 3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 1 1 1.414-1.414L10 13.586ZM4 3a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H4ZM3 7a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1ZM4 9a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2H4Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const CartIcon = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="Cart"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Cart"
            data-group="m"
            color="gray-400"
            style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2h9.266c1.336 0 2.169 1.435 1.495 2.578l-2.693 4.571c-.31.527-.88.851-1.496.851h-6.84A1.723 1.723 0 0 1 3 10.286V3H2a1 1 0 0 1-1-1Zm12.78 4H5v4h6.424l2.357-4Z"
                shapeRendering="geometricPrecision"
            />
            <path
                d="M6 14.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm6 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                shapeRendering="geometricPrecision"
            />
        </svg>
    );
};

export const AdTopIcon = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="AdTop"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="AdTop"
            data-group="m"
            color="gray-400"
            style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
        >
            <path
                fillRule="evenodd"
                d="M9 7.504a.5.5 0 0 0 .5.5H11a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2H9.5a.5.5 0 0 0-.5.5v4Zm3-1.5a1 1 0 0 1-1 1h-1v-3h1a1 1 0 0 1 1 1v1ZM7.5 8.004a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5H7a3 3 0 0 0-3 3v1.5a.5.5 0 0 0 1 0v-.5h2v.5a.5.5 0 0 0 .5.5Zm-.5-4v2H5a2 2 0 0 1 2-2Z"
                clipRule="evenodd"
                shapeRendering="geometricPrecision"
            />
            <path
                fillRule="evenodd"
                d="M2 .004a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1H2Zm1 9v-7h11v7H3Z"
                clipRule="evenodd"
                shapeRendering="geometricPrecision"
            />
            <path
                d="M4 12.504a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5ZM4 14.504a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5Z"
                shapeRendering="geometricPrecision"
            />
        </svg>
    );
};

export const AdMiddleIcon = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="AdMiddle"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="AdMiddle"
            data-group="m"
            color="gray-400"
            style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
        >
            <path
                d="M4 .504a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5ZM4 14.504a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5Z"
                shapeRendering="geometricPrecision"
            />
            <path
                fillRule="evenodd"
                d="M9 9.504a.5.5 0 0 0 .5.5H11a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2H9.5a.5.5 0 0 0-.5.5v4Zm3-1.5a1 1 0 0 1-1 1h-1v-3h1a1 1 0 0 1 1 1v1ZM7.5 10.004a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5H7a3 3 0 0 0-3 3v1.5a.5.5 0 0 0 1 0v-.5h2v.5a.5.5 0 0 0 .5.5Zm-.5-4v2H5a2 2 0 0 1 2-2Z"
                clipRule="evenodd"
                shapeRendering="geometricPrecision"
            />
            <path
                fillRule="evenodd"
                d="M2 2.004a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1H2Zm1 9v-7h11v7H3Z"
                clipRule="evenodd"
                shapeRendering="geometricPrecision"
            />
        </svg>
    );
};

export const AdBottomIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        data-ui-name="AdBottom"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="AdBottom"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            d="M4 .504a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5ZM4 2.504a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5Z"
            shapeRendering="geometricPrecision"
        ></path>
        <path
            fillRule="evenodd"
            d="M9 11.504a.5.5 0 0 0 .5.5H11a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2H9.5a.5.5 0 0 0-.5.5v4Zm3-1.5a1 1 0 0 1-1 1h-1v-3h1a1 1 0 0 1 1 1v1ZM7.5 12.004a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5H7a3 3 0 0 0-3 3v1.5a.5.5 0 0 0 1 0v-.5h2v.5a.5.5 0 0 0 .5.5Zm-.5-4v2H5a2 2 0 0 1 2-2Z"
            clipRule="evenodd"
            shapeRendering="geometricPrecision"
        ></path>
        <path
            fillRule="evenodd"
            d="M2 4.004a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1H2Zm1 9v-7h11v7H3Z"
            clipRule="evenodd"
            shapeRendering="geometricPrecision"
        ></path>
    </svg>
);

export const ImagePackIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        data-ui-name="Pictures"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="Pictures"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path d="M5 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" shapeRendering="geometricPrecision"></path>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 12V2a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1Zm2-9h9v2.338a2.001 2.001 0 0 0-2.531.369l-3.007 3.34L4.414 8A2 2 0 0 0 2 7.682V3Zm7.955 4.045L11 8.35V11H6.395l3.56-3.955ZM3 9.415 4.586 11H2v-.586l1-1Z"
            shapeRendering="geometricPrecision"
        ></path>
        <path
            d="M2 15a1 1 0 0 1 1-1h11V5a1 1 0 1 1 2 0v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1Z"
            shapeRendering="geometricPrecision"
        ></path>
    </svg>
);

export const AIOverviewIcon = () => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled=""
        className="___SIcon_rdzag-red-team"
        data-ui-name="GoogleGenerativeAI"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="GoogleGenerativeAI"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
    >
        <path
            fillRule="evenodd"
            d="M16 8.038c0-2.174-1.828-3.982-4.002-3.96C12.061 1.875 10.243 0 8.038 0c-2.204 0-4.023 1.877-3.96 4.079C1.878 4.015 0 5.834 0 8.039c0 2.204 1.876 4.022 4.077 3.96C4.056 14.171 5.864 16 8.038 16c2.175 0 3.983-1.827 3.962-4 2.173.02 4-1.787 4-3.962ZM2.655 8A6.97 6.97 0 0 0 8 2.655 6.97 6.97 0 0 0 13.345 8 6.97 6.97 0 0 0 8 13.345 6.97 6.97 0 0 0 2.655 8Z"
            clipRule="evenodd"
            shapeRendering="geometricPrecision"
        ></path>
    </svg>
);

export const FAQIcon = () => {
    return (
        <svg
            aria-hidden="true"
            className="___SIcon_rdzag-red-team"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
        >
            <path d="M9 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7 6c0-.544.433-1 1-1s1 .456 1 1c0 .445-.182.82-.498 1.192-.255.3-.53.527-.827.774-.107.089-.217.18-.33.278a1 1 0 1 0 1.31 1.512c.057-.05.127-.108.208-.175.315-.26.782-.646 1.162-1.093C10.534 7.89 11 7.07 11 6c0-1.605-1.285-3-3-3S5 4.395 5 6a1 1 0 0 0 2 0Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm0-2A6 6 0 1 0 8 2a6 6 0 0 0 0 12Z"
            />
        </svg>
    );
};

export const IndentedResultIcon = () => {
    return (
        <svg
            aria-hidden="true"
            className="___SIcon_rdzag-red-team"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 2a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V2Zm-2 3V3H2v2h9ZM16 10a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4Zm-2 3v-2H5v2h9Z"
            />
        </svg>
    );
};

export const NewsIcon = () => {
    return (
        <svg
            aria-hidden="true"
            className="___SIcon_rdzag-red-team"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
        >
            <path d="M3 11V3h7v7a1 1 0 1 0 2 0V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v9a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V6a1 1 0 1 0-2 0v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Z" />
            <path d="M5 5a1 1 0 0 0 0 2h3a1 1 0 0 0 0-2H5Zm0 3a1 1 0 0 0 0 2h3a1 1 0 0 0 0-2H5Z" />
        </svg>
    );
};

export const VideoIcon3 = ({ className = '', ...props }) => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled
        className={`___SIcon_rdzag-red-team ${className}`}
        data-ui-name="Video"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="Video"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
        {...props}
    >
        <path
            d="M6 5.4v5.198a.4.4 0 0 0 .61.34l4.203-2.605a.4.4 0 0 0 0-.68L6.61 5.06A.4.4 0 0 0 6 5.4Z"
            shapeRendering="geometricPrecision"
        ></path>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 3v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1Zm12 9H3V4h10v8Z"
            shapeRendering="geometricPrecision"
        ></path>
    </svg>
);

export const EventIcon2 = ({ className = '', ...props }) => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled
        className={`___SIcon_rdzag-red-team ${className}`}
        data-ui-name="Event"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="Event"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 1a1 1 0 1 1 2 0v1h3a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h3V1a1 1 0 0 1 2 0v1h4V1ZM4 4a1 1 0 0 0 2 0h4a1 1 0 1 0 2 0h2v10H2V4h2Zm7.696 4.351a.186.186 0 0 0-.102-.315l-2.325-.393-1.104-2.045a.188.188 0 0 0-.33 0L6.73 7.643l-2.325.393a.186.186 0 0 0-.102.315l1.642 1.652-.335 2.283a.187.187 0 0 0 .267.195L8 11.461l2.122 1.02a.187.187 0 0 0 .267-.195l-.335-2.283 1.642-1.652Z"
            shapeRendering="geometricPrecision"
        ></path>
    </svg>
);

export const HotelIcon2 = ({ className = '', ...props }) => (
    <svg
        aria-hidden="true"
        tabIndex="-1"
        disabled
        className={`___SIcon_rdzag-red-team ${className}`}
        data-ui-name="Hotel"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        focusable="false"
        data-name="Hotel"
        data-group="m"
        color="gray-400"
        style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
        {...props}
    >
        <path
            d="M9.48 1.15a.07.07 0 0 0-.04-.13L8.5.86 8.07.04a.08.08 0 0 0-.14 0L7.5.86l-.93.16a.07.07 0 0 0-.04.13l.66.66-.14.92c0 .06.06.1.11.07L8 2.4l.85.4c.05.03.11-.01.1-.07l-.13-.92.66-.66ZM5.48 2.15a.07.07 0 0 0-.04-.13l-.93-.16-.44-.82a.08.08 0 0 0-.14 0l-.44.82-.93.16a.07.07 0 0 0-.04.13l.66.66-.14.92c0 .06.06.1.11.07L4 3.4l.85.4c.05.03.11-.01.1-.07l-.13-.92.66-.66ZM13.44 2.02c.06 0 .08.08.04.13l-.66.66.14.92c0 .06-.06.1-.11.07L12 3.4l-.85.4a.08.08 0 0 1-.1-.07l.13-.92-.66-.66a.07.07 0 0 1 .04-.13l.93-.16.44-.82c.03-.05.1-.05.14 0l.44.82.93.16ZM7 8a1 1 0 1 0 0 2h2a1 1 0 0 0 0-2H7Z"
            shapeRendering="geometricPrecision"
        ></path>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 16a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-2V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v4H1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14ZM5 7v7h2v-1a1 1 0 1 1 2 0v1h2V7H5Zm9 7h-1v-4h1v4ZM3 14v-2H2v2h1Z"
            shapeRendering="geometricPrecision"
        ></path>
    </svg>
);

export const VideoIcon = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="Video"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Video"
            data-group="m"
            color="gray-400"
            style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
        >
            <path
                d="M6 5.4v5.198a.4.4 0 0 0 .61.34l4.203-2.605a.4.4 0 0 0 0-.68L6.61 5.06A.4.4 0 0 0 6 5.4Z"
                shapeRendering="geometricPrecision"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 3v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1Zm12 9H3V4h10v8Z"
                shapeRendering="geometricPrecision"
            />
        </svg>
    );
};

export const EventIcon = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="Event"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Event"
            data-group="m"
            color="gray-400"
            style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 1a1 1 0 1 1 2 0v1h3a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h3V1a1 1 0 0 1 2 0v1h4V1ZM4 4a1 1 0 0 0 2 0h4a1 1 0 1 0 2 0h2v10H2V4h2Zm7.696 4.351a.186.186 0 0 0-.102-.315l-2.325-.393-1.104-2.045a.188.188 0 0 0-.33 0L6.73 7.643l-2.325.393a.186.186 0 0 0-.102.315l1.642 1.652-.335 2.283a.187.187 0 0 0 .267.195L8 11.461l2.122 1.02a.187.187 0 0 0 .267-.195l-.335-2.283 1.642-1.652Z"
                shapeRendering="geometricPrecision"
            />
        </svg>
    );
};

export const HotelIcon = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="Hotel"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Hotel"
            data-group="m"
            color="gray-400"
            style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
        >
            <path
                d="M9.48 1.15a.07.07 0 0 0-.04-.13L8.5.86 8.07.04a.08.08 0 0 0-.14 0L7.5.86l-.93.16a.07.07 0 0 0-.04.13l.66.66-.14.92c0 .06.06.1.11.07L8 2.4l.85.4c.05.03.11-.01.1-.07l-.13-.92.66-.66ZM5.48 2.15a.07.07 0 0 0-.04-.13l-.93-.16-.44-.82a.08.08 0 0 0-.14 0l-.44.82-.93.16a.07.07 0 0 0-.04.13l.66.66-.14.92c0 .06.06.1.11.07L4 3.4l.85.4c.05.03.11-.01.1-.07l-.13-.92.66-.66ZM13.44 2.02c.06 0 .08.08.04.13l-.66.66.14.92c0 .06-.06.1-.11.07L12 3.4l-.85.4a.08.08 0 0 1-.1-.07l.13-.92-.66-.66a.07.07 0 0 1 .04-.13l.93-.16.44-.82c.03-.05.1-.05.14 0l.44.82.93.16ZM7 8a1 1 0 1 0 0 2h2a1 1 0 0 0 0-2H7Z"
                shapeRendering="geometricPrecision"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 16a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-2V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v4H1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14ZM5 7v7h2v-1a1 1 0 1 1 2 0v1h2V7H5Zm9 7h-1v-4h1v4ZM3 14v-2H2v2h1Z"
                shapeRendering="geometricPrecision"
            />
        </svg>
    );
};

export const ChatIcon = (props) => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled=""
            className="___SIcon_rdzag-red-team"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Chat"
            data-group="m"
            color="gray-400"
            style={{ '--color_rdzag': props.color || '#8a8e9b', ...props.style }}
        >
            <path
                d="M2 15a1 1 0 0 1-.45-.11A1 1 0 0 1 1 14V1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H6.39L2.6 14.79A1 1 0 0 1 2 15ZM3 2v10l2.46-1.82a1 1 0 0 1 .6-.2H13v-8L3 2Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const TagIcon = (props) => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled=""
            className="___SIcon_rdzag-red-team"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Tag"
            data-group="m"
            color="gray-400"
            style={{ '--color_rdzag': props.color || '#8a8e9b', ...props.style }}
        >
            <path d="M12 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" shapeRendering="geometricPrecision"></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.163.874A2.984 2.984 0 0 1 10.273 0h4.732c.55 0 .995.445.995.995v4.732c0 .791-.314 1.55-.874 2.11l-7.872 7.872a.995.995 0 0 1-1.407 0L.291 10.153a.995.995 0 0 1 0-1.407L8.163.874ZM2.401 9.449l4.15 4.15 7.168-7.169a.994.994 0 0 0 .292-.703V1.989h-3.738a.995.995 0 0 0-.703.292L2.401 9.449Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const FindResultsOnIcon = (props) => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled=""
            className="___SIcon_rdzag-red-team"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="FindResultsOn"
            data-group="m"
            color="gray-400"
            style={{ '--color_rdzag': props.color || '#8a8e9b', ...props.style }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0a7 7 0 1 0 4.192 12.606l2.1 2.101a1 1 0 0 0 1.415-1.414l-2.1-2.1A7 7 0 0 0 8 0ZM3 7a5 5 0 1 1 10 0A5 5 0 0 1 3 7Zm6.293-1.707a1 1 0 0 1 1.414 1.414l-2.5 2.5a1 1 0 0 1-1.414 0l-1.5-1.5a1 1 0 0 1 1.414-1.414l.793.793 1.793-1.793Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const OrganicCarouselIcon = () => {
    return (
        <svg
            aria-hidden="true"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="OrganicCarousel"
            className="___SIcon_rdzag-red-team"
            color="gray-400"
            style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
        >
            <path
                d="M4.02 5.021a1 1 0 0 1 .998-1h5.99a1 1 0 0 1 0 2h-5.99a1 1 0 0 1-.998-1ZM5.018 7.021a1 1 0 0 0 0 2h3.993a1 1 0 0 0 0-2H5.018Z"
                shapeRendering="geometricPrecision"
            ></path>
            <path
                fillRule="evenodd"
                d="M1.026 1.021a1 1 0 0 1 .998-1h11.978a1 1 0 0 1 .998 1v11a1 1 0 0 1-.998 1H2.024a1 1 0 0 1-.998-1v-11Zm1.996 10v-9h9.982v9H3.022Z"
                clipRule="evenodd"
                shapeRendering="geometricPrecision"
            ></path>
            <path
                d="M1.998 14.021a1 1 0 0 0 0 2h3.993a1 1 0 0 0 0-2H1.998ZM8.985 15.021a1 1 0 1 1 1.998-.002 1 1 0 0 1-1.998.002ZM12.978 15.021a.999.999 0 1 1 1.998-.002.999.999 0 0 1-1.998.002Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

import React from 'react';

export const QuestionsAndAnswers = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="QuestionsAnswers"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="QuestionsAnswers"
            data-group="m"
            color="gray-400"
            style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
        >
            <path
                d="M8.003 4.221c-.34 0-.6.274-.6.6a.6.6 0 0 1-1.2 0c0-.963.77-1.8 1.8-1.8 1.028 0 1.8.837 1.8 1.8 0 .643-.28 1.134-.585 1.493-.228.268-.509.5-.697.656l-.125.104a.6.6 0 0 1-.786-.906c.067-.06.134-.114.198-.167.178-.148.343-.285.496-.465.19-.223.299-.448.299-.715 0-.326-.26-.6-.6-.6ZM8.003 9.021a.6.6 0 1 0 0-1.2.6.6 0 0 0 0 1.2Z"
                shapeRendering="geometricPrecision"
            ></path>
            <path
                fillRule="evenodd"
                d="M2 3.021a3 3 0 0 1 3-3h8a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H5a3 3 0 0 1-3-3v-9Zm3-1h7v8H4v-7a1 1 0 0 1 1-1Zm-1 10h8v1H5a1 1 0 0 1-1-1Z"
                clipRule="evenodd"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const Refine = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled
            className="___SIcon_rdzag-red-team"
            data-ui-name="Funnel"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="Funnel"
            data-group="m"
            color="gray-400"
            style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 1a1 1 0 0 1 .8 1.6L9.002 8.993V14a1 1 0 1 1-2 0V8.999L2.202 2.6a1 1 0 0 1 .8-1.6H13ZM8 6.996 5.002 3h5.997L8 6.996Z"
                shapeRendering="geometricPrecision"
            ></path>
        </svg>
    );
};

export const ThingsToKnow = () => {
    return (
        <svg
            aria-hidden="true"
            tabIndex="-1"
            disabled=""
            className="___SIcon_rdzag-red-team"
            data-ui-name="ListBullet"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            focusable="false"
            data-name="ListBullet"
            data-group="m"
            color="gray-400"
            style={{ '--color_rdzag': 'var(--gray-400, #8a8e9b)' }}
        >
            <path
                d="M3 2H1v2h2V2Zm0 5H1v2h2V7Zm-2 5h2v2H1v-2ZM15 2H5v2h10V2Zm0 5H5v2h10V7ZM5 12h10v2H5v-2Z"
                shapeRendering="geometricPrecision"
            />
        </svg>
    );
};
