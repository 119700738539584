import { scrollForTable } from '../components/Datatable/utils';

// function example (array) => array.sort((a, b) => a - b),
export const getSortKeys = (keys) => {
    const sortFns = {};

    const getTypes = (item) => ({
        number: (array) => array.sort((a, b) => a[item.key] - b[item.key]),
        date: (array) => array.sort((a, b) => new Date(a[item.key]) - new Date(b[item.key])),
        string: (array) =>
            array.sort((a, b) => (a[item.key] ?? '').localeCompare(b[item.key] ?? '')),
    });

    keys.forEach((item) => {
        if (typeof item.sortType === 'function') return (sortFns[item.key] = item.sortType);
        const types = getTypes(item);
        const sort = types[item.sortType];

        sortFns[item.key] = sort ?? types['number'];
    });

    return sortFns;
};

export const getTableColumnSizes = (columns) =>
    columns.map((item) => item.cellSize ?? 'minmax(120px, 1fr)').join(' ');

export const setAdditionalStyles = (columns, hasSelect = false) => ({
    Table: `
        --data-table-library_grid-template-columns : ${
            hasSelect ? 'minmax(60px, 0.5fr)' : ''
        } ${getTableColumnSizes(columns)} !important;
        ${scrollForTable}
    `,
    BaseCell: `  
        font-family: 'Raleway';
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        padding: 16px 5px !important;
        color: #0D182C;
        border-bottom: 1px solid rgba(13, 24, 44, 0.1);

        &.underline {
            text-decoration: underline;
        }
    `,
});
