import { Cell } from '@table-library/react-table-library/table';
import moment from 'moment';
import numeral from 'numeral';

export const COLUMNS = (onOpen) => [
    {
        label: 'Keyword',
        key: 'keyword',
        data: 'keyword',
        sortType: 'string',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <strong className="wripe">
                    {' '}
                    <a
                        href={`/commerce/${item.project_id}?keyword=${item.keyword}&own_domain=${item.own_domain}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span>{data}</span> <span style={{ fontSize: '20px' }}>→</span>
                    </a>
                </strong>
            );
        },
    },
    {
        label: 'Country',
        key: 'country',
        data: 'country',
        sortType: 'string',
        cellSize: 'minmax(60px, 1fr)',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <span className="wripe">{data}</span>;
        },
    },
    {
        label: 'Own domain',
        key: 'own_domain',
        data: 'own_domain',
        sortType: 'string',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <span className="wripe">{data}</span>;
        },
    },
    {
        label: 'Has cluster',
        key: 'has_cluster',
        data: 'has_cluster',
        sortType: (array) =>
            array.sort((a, b) =>
                a.competitors?.join(', ').localeCompare(b.competitors?.join(', ')),
            ),
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <span className="wripe">{data}</span>;
        },
    },
    {
        label: 'Number of keywords',
        key: 'display_limit',
        data: 'display_limit',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <span className="wripe">{numeral(data).format('0 a')}</span>;
        },
    },
    {
        label: 'Status',
        key: 'status',
        data: 'status',
        sortType: 'string',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <span className="wripe">{data}</span>;
        },
    },
    {
        label: 'Created at',
        key: 'created_at',
        data: 'created_at',
        sortType: 'date',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <span className="wripe">{moment(data).format('YYYY-MM-DD')}</span>;
        },
    },
    {
        label: 'Actions',
        key: 'actions',
        data: 'actions',
        Component: Cell,
        cellSize: 'minmax(280px, 2fr)',
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return null;
            return (
                <div className="d-flex align-items-center">
                    <a
                        className="table-action-btn mr-10"
                        href={`/commerce/${item.project_id}?keyword=${item.keyword}&own_domain=${item.own_domain}`}
                        style={{
                            pointerEvents: item.status !== 'Completed' ? 'none' : 'unset',
                            opacity: item.status !== 'Completed' ? '0.5' : '1',
                        }}
                    >
                        View Report
                    </a>
                    <a
                        className="table-action-btn mr-10"
                        href={`/commerce/${item.project_id}?keyword=${item.keyword}&own_domain=${item.own_domain}&view=opportunities`}
                        style={{
                            pointerEvents: item.status !== 'Completed' ? 'none' : 'unset',
                            opacity: item.status !== 'Completed' ? '0.5' : '1',
                        }}
                    >
                        Opportunities
                    </a>
                    <button className="commerce-action-settings-btn" onClick={() => onOpen(item)}>
                        <i className="icon-settings nav-icon"></i>
                    </button>
                </div>
            );
        },
    },
];
