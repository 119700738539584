import { Component } from 'react';
import ReactSelect from '../../../components/ReactSelect';
import { v4 as uuid } from 'uuid';
import { addUsers } from './queries';
import Loading from '../../../components/Loading';

class AddUsers extends Component {
    constructor(props) {
        super();

        this.handleChange = this.handleChange.bind(this);
        this.addUserFileds = this.addUserFileds.bind(this);
        this.deleteUserFileds = this.deleteUserFileds.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            users: [{ id: uuid(), first_name: '', last_name: '', email: '', domains: [] }],
            local_users_remaining_count: Number(props.users_remaining_count) - 1,
        };
    }

    handleChange(e, id) {
        const users = this.state.users.map((user) => {
            if (user.id === id) {
                return { ...user, [e.target.name]: e.target.value };
            }
            return user;
        });

        this.setState({
            users,
        });
    }

    addUserFileds() {
        const emptyUser = {
            id: uuid(),
            first_name: '',
            last_name: '',
            email: '',
            domains: [],
        };

        this.setState({
            users: [...this.state.users, emptyUser],
            local_users_remaining_count: this.state.local_users_remaining_count - 1,
        });
    }

    deleteUserFileds(id) {
        this.setState({
            users: this.state.users.filter((item) => item.id !== id),
            local_users_remaining_count: this.state.local_users_remaining_count + 1,
        });
    }

    onChangeSelect(id, value) {
        const users = this.state.users.map((user) => {
            if (user.id === id) {
                return { ...user, domains: value.map((item) => item.value) };
            }
            return user;
        });

        this.setState({
            users,
        });
    }

    async onSubmit() {
        try {
            this.setState({
                loading: true,
            });
            const res = await addUsers(this.state.users, this.props.csrf_token);
            const data = await res.json();
            if (data.message === 'Success') {
                this.props.onAddUsers(this.state.users.length);
                this.setState({
                    users: [{ id: uuid(), first_name: '', last_name: '', email: '', domains: [] }],
                    loading: false,
                    message: data.result,
                    message_tags: 'success',
                    local_users_remaining_count: this.state.local_users_remaining_count - 1,
                });
            } else {
                this.setState({
                    loading: false,
                    message: data.result,
                    message_tags: 'error',
                });
            }
        } catch (err) {
            this.setState({
                loading: false,
                message: err.message ?? 'Something went wrong',
                message_tags: 'error',
            });
        }
    }

    render() {
        const usersCount =
            this.props.users_total_number_of_available - this.props.users_remaining_count;
        const localUsersCount =
            this.props.users_total_number_of_available - this.state.local_users_remaining_count;

        const showPluseBtn = localUsersCount < this.props.users_total_number_of_available;

        const options =
            this.props.domains?.map((item) => ({
                label: (
                    <div className="row">
                        <div className="col-11 wrap-text"> {item?.url ?? 'Select Domain'}</div>
                        <div className="col-1">
                            {item?.status ? (
                                <i className="text-success icofont-verification-check"></i>
                            ) : (
                                <i className=" text-danger icofont-ui-close"></i>
                            )}{' '}
                        </div>
                    </div>
                ),
                value: item.pk,
            })) ?? [];
        return (
            <>
                <div id="onboarding-general-settings">
                    <div className="onboarding-subheader">
                        Users: {usersCount} / {this.props.users_total_number_of_available} seats
                        used
                    </div>
                    <div className="onboarding-subheader">
                        Would you like to invite other users to access the tool and reports? You can
                        also do this later in Settings.
                    </div>
                    {Number(this.props.users_remaining_count) > 0 &&
                        this.state.users.map((user, i, arr) => (
                            <div className="onboarding-add-user" key={user.id}>
                                <div className="onboarding-add-user-field">
                                    <label htmlFor="first_name" className="form-label">
                                        First Name
                                    </label>
                                    <input
                                        name="first_name"
                                        id="first_name"
                                        className="form-control"
                                        value={user.first_name}
                                        onChange={(e) => this.handleChange(e, user.id)}
                                    />
                                </div>
                                <div className="onboarding-add-user-field">
                                    <label htmlFor="last_name" className="form-label">
                                        Last Name
                                    </label>
                                    <input
                                        name="last_name"
                                        id="last_name"
                                        className="form-control"
                                        value={user.last_name}
                                        onChange={(e) => this.handleChange(e, user.id)}
                                    />
                                </div>
                                <div className="onboarding-add-user-field">
                                    <label htmlFor="email" className="form-label">
                                        Email Address
                                    </label>
                                    <input
                                        name="email"
                                        id="email"
                                        className="form-control"
                                        value={user.email}
                                        onChange={(e) => this.handleChange(e, user.id)}
                                    />
                                </div>
                                <div className="onboarding-add-user-field">
                                    <label htmlFor="first_name" className="form-label">
                                        Select Domain(s)
                                    </label>
                                    <ReactSelect
                                        options={options}
                                        isSearchable={false}
                                        onChange={(value) => this.onChangeSelect(user.id, value)}
                                        blurInputOnSelect={true}
                                        classname="mr-10"
                                        isMulti
                                    />
                                </div>
                                <div className="onboarding-add-user-btns">
                                    {arr.length !== 1 && (
                                        <button onClick={() => this.deleteUserFileds(user.id)}>
                                            -
                                        </button>
                                    )}
                                    {showPluseBtn && (
                                        <button
                                            className="onboarding-add-user-btns-add"
                                            onClick={this.addUserFileds}
                                        >
                                            +
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    {this.state.message && (
                        <div className={`onboarding-message-${this.state.message_tags}`}>
                            {this.state.message}
                        </div>
                    )}

                    <div className="d-flex justify-content-between">
                        <button
                            className="button button-outline button-primary width-sm btn"
                            onClick={this.onSubmit}
                            disabled={
                                this.state.loading || Number(this.props.users_remaining_count) <= 0
                            }
                        >
                            {this.state.loading && <Loading onlyPreloader />}
                            Save
                        </button>
                        <div className="d-flex">
                            <button
                                className="button width-sm btn onboarding-btn-next d-flex justify-content-center onboarding-skip-btn"
                                onClick={() => this.props.handleBack()}
                            >
                                back
                            </button>
                            <button
                                className="button  button-filled button-primary width-sm btn onboarding-btn-next d-flex justify-content-center"
                                onClick={() => this.props.handleNext()}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AddUsers;
