import CheckData from '../../../components/CatchError';
import CommerceFilterIntent from '../../../components/CommerceFilterIntent';
import Component from '../../../components/Component';
import DataTable from '../../../components/Datatable';
import { changeIntent } from '../../../helpers/commerce';
import { getSortKeys, setAdditionalStyles } from '../../../helpers/table';
import { COLUMNS } from './keys';
import query from './query';
import { v4 as uuid } from 'uuid';

class CommerceViewDetailClusterCommonUrls extends Component {
    constructor(props) {
        super();
        this.url = new URL(window.location);

        this.handleSelectChange = this.handleSelectChange.bind(this);

        this.query = query({
            project_id: props.project_id,
            cluster_name: props.cluster_name,
        });
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) => item?.url?.toLowerCase()?.includes(search.toLowerCase()));
    }

    componentDidUpdate(_, prevState) {
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            const data = this.state.data?.map((x) => ({ id: uuid(), ...x }));
            this.setState({ allData: data });
        }
    }

    async handleSelectChange(values) {
        this.setState({
            select: values,
        });

        changeIntent(values);

        this.query = query({
            project_id: this.props.project_id,
            cluster_name: this.props.cluster_name,
            intent_filter: values?.map((item) => item.value),
        });
        this.query.bind(this.setState.bind(this));
        this.query.update();
    }

    render() {
        //need to update query
        const extraBtns = () => (
            <CommerceFilterIntent
                select={this.state.select}
                handleSelectChange={this.handleSelectChange}
            />
        );
        return (
            <div className="card rounded-main table-main">
                <div
                    className="card-header d-flex justify-content-between"
                    style={{ borderRadius: '10px' }}
                >
                    <div className="card-title text-dark fw-bold">Common URLs table</div>
                </div>
                <div className="card-body">
                    <div>
                        <CheckData data={this.state?.allData ?? []} loading={this.state.loading}>
                            <DataTable
                                nodes={this.state?.allData ?? []}
                                sortFns={getSortKeys(COLUMNS)}
                                COLUMNS={COLUMNS}
                                fileName="Project"
                                extraBtns={extraBtns}
                                searchFunction={this.searchFunction}
                                additionalStyles={setAdditionalStyles(COLUMNS)}
                                customEmptyMessage="No Data available"
                            />
                        </CheckData>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommerceViewDetailClusterCommonUrls;
