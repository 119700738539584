import { Component } from 'react';
import Loading from '../Loading';

class CheckData extends Component {
    constructor() {
        super();
    }

    render() {
        if (this.props.loading && !this.props.loadMore) {
            return <Loading padding={10} />;
        }

        return this.props.children;
    }
}

export default CheckData;
