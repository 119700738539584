import { Cell } from '@table-library/react-table-library/table';
import moment from 'moment';

export const COLUMNS = () => [
    {
        label: 'Headline',
        key: 'headline',
        data: 'headline',
        Component: Cell,
        renderCell: ({ data, csvDownload, item }) => {
            if (csvDownload) return data;

            return (
                <div className="d-flex">
                    <div className="d-flex align-items-center tracking-pages-name mr-10">
                        <div style={{ float: 'left', marginRight: '10px' }}>
                            <img
                                src={item.image}
                                loading="lazy"
                                alt="page_image"
                                className="cursor-pointer"
                            />
                        </div>
                        <strong>{data.length > 64 ? data.substr(0, 64) + '...' : data}</strong>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Date',
        key: 'date',
        data: 'date',
        Component: Cell,
        renderCell: ({ data }) => {
            return moment(data?.value).format('YYYY-MM-DD');
        },
    },
];
