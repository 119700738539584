import Query from '../../../components/Report';

const query = ({ project_id, own_domain, cluster_name, intent_filter = '' }) => {
    return new Query('commerce-detail-cluster', {
        project_id,
        own_domain,
        cluster_name,
        intent_filter: setIntent(intent_filter),
    });
};

export default query;

function setIntent(intent_filter) {
    if (!intent_filter || intent_filter?.length === 0) return '';

    return `and (${intent_filter?.map((item) => `intent LIKE '%${item}%'`).join(' or ')})`;
}
