import Timeline from '../../../components/Timeline';
import { Component } from 'react';
import { module1Keys } from './keys';
import { labelColors } from '../../../lib/colors';
import { getRandomColor } from '../../../lib/colors';

class PositionModule1 extends Component {
    constructor() {
        super();

        this.addActive = this.addActive.bind(this);

        this.state = {
            selectedItem: {},
            type: 'all',
            labels: [],
            tags: [],
        };
    }

    componentDidMount() {
        const tags = this.props.data?.map((item) => ({
            id: item.searchappearance,
            label: item.searchappearance,
        }));
        tags.unshift({ label: 'All', id: 'all' });

        this.setState({
            type: 'all',
            selectedItem: this.props.data,
            tags,
        });
    }

    onChangeTags(tag_id) {
        this.setState({
            type: tag_id,
            selectedItem: this.props.data?.find((x) => x.searchappearance === tag_id),
        });
    }

    addActive(id) {
        if (this.state.type.includes('all')) {
            if (id === 'all') return true;
            return false;
        }

        return this.state.type.includes(id);
    }

    render() {
        let chartData = {};
        if (this.state.type === 'all') {
            chartData = {
                labels: this.props.data?.[0]?.dateL.map((item) => item.value),
                datasets: this.props.data?.map((item, i) => {
                    const color = labelColors[i] || getRandomColor();
                    return {
                        label: item.searchappearance,
                        type: 'line',
                        data: item.clicksL,
                        borderColor: color,
                        backgroundColor: color,
                        borderRadius: 4,
                        tension: 0.4,
                    };
                }),
            };
        } else {
            chartData = {
                labels: this.state.selectedItem?.dateL.map((item) => item.value),
                datasets: module1Keys.map((item) => ({
                    label: item.label,
                    type: item.type || 'line',
                    data: this.state.selectedItem[item.key],
                    borderColor: item.labelColor,
                    backgroundColor: item.labelColor,
                    yAxisID: item.yAxisID || 'y',
                    hidden: item.hidden || false,
                    borderRadius: 4,
                    tension: 0.4,
                })),
            };
        }

        return (
            <>
                <div className="tags-container">
                    {this.state.tags.map((tag) => (
                        <span
                            className={`tab-item extra-styles ${
                                tag.id === this.state.type && 'active-tag'
                            }`}
                            key={tag.id}
                            onClick={() => this.onChangeTags(tag.id)}
                        >
                            {tag.label}
                        </span>
                    ))}
                </div>
                <Timeline
                    chartSize="lg"
                    metricType="primary"
                    data={chartData?.labels?.map((x) => ({ date: x }))}
                    error={this.props.error}
                    keys={module1Keys}
                    parent_id={this.props.parent_id}
                    content_id={this.props.content_id}
                    forceRun={this.props.forceRun}
                    chartData={chartData}
                    hasRightAxis={this.state.type !== 'all'}
                    dontShowMetrics
                    leftAxisLabel={this.state.type === 'all' ? 'Clicks' : 'Clicks and Impressions'}
                    xAxisTitle="Date"
                    showGoogleFeeds
                    rightAxisLabel="CTR (%)"
                    rightSecondAxisLabel="Positions"
                    hasSecondRightAxis
                    secondRightAxisReversed
                />
            </>
        );
    }
}

export default PositionModule1;
