export const checkDomain = (endpoint_url, csrf_token, data) => {
    return fetch(`${endpoint_url}/domains/connect-to-other-user/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const activateDomain = (csrf_token, domain_pk) => {
    return fetch(`/domains/${domain_pk}/reactivate/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
    });
};

export const confirmActivateDomain = (csrf_token, domain_pk) => {
    return fetch(`/domains/${domain_pk}/confirm-reactivate/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
    });
};
