export default function request(url, data, opts) {
    const xhr = new XMLHttpRequest();
    opts = opts || {};
    data = data || {};
    data.force = data.force || opts.force || false;

    xhr.open(opts.method || 'POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Accept', 'application/json');

    xhr.send(JSON.stringify(data));

    return new Promise((s) => {
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                const response = JSON.parse(xhr.responseText);
                return s(response);
            }
            return s({
                error: 'There is no data yet. If this a new account, it takes 48-72 hours to process historic data. If the problem exists more than 3 days with new accounts, please contact us at info@gddash.com',
            });
        };
    });
}
