import colors, { labelColors } from '../../../lib/colors';

export const keywordsKeys = [
    {
        key: 'keywords',
        label: 'Keywords',
        color: colors[0],
        labelColor: labelColors[0],
        type: 'bar',
    },
    {
        key: 'newKeywords',
        label: 'New keywords',
        color: colors[1],
        labelColor: labelColors[1],
        type: 'bar',
    },
];

export const urlsKeys = [
    {
        key: 'urls',
        label: 'Urls',
        color: colors[2],
        labelColor: labelColors[2],
        type: 'bar',
    },
    {
        key: 'newURLs',
        label: 'New URLs',
        color: colors[3],
        labelColor: labelColors[3],
        type: 'bar',
    },
];
