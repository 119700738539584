import { Component } from 'react';
import { COLUMNS, getPercentForChart, webNodesTypes } from './keys';
import numeral from 'numeral';
import { Pie } from 'react-chartjs-2';
import { getRandomColor, labelColors } from '../../../../../lib/colors';
import DataTable from '../../../../../components/Datatable';
import { scrollForTable } from '../../../../../components/Datatable/utils';

class GoogleSurfaces extends Component {
    constructor() {
        super();
    }

    render() {
        const options = {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Google Surfaces',
                    padding: {
                        top: 10,
                        bottom: 10,
                    },
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const percent = `${getPercentForChart(
                                context.dataset.data,
                                context.parsed,
                            ).toFixed(2)}%`;
                            return `${context.label} : ${numeral(context.parsed).format(
                                '0.0a',
                            )} Click${context.parsed > 1 ? 's' : ''} (${percent})`;
                        },
                    },
                },
            },
        };

        const chartData = {
            labels: this.props.data?.map((item) => item.label),
            datasets: [
                {
                    label: 'Clicks',
                    data: this.props.data?.map(
                        (item) => item.items.reduce((acc, curr) => acc + curr),
                        0,
                    ),
                    backgroundColor: this.props.data?.map(
                        (item, i) => labelColors[i] ?? getRandomColor(),
                    ),
                    borderRadius: 4,
                    tension: 0.4,
                },
            ],
        };

        const summaryData = this.props.data.map((item) => {
            const itemSummary = item.data.reduce((acc, curr) => {
                acc.impressions = (acc.impressions ?? 0) + (curr.impressions ?? 0);
                acc.clicks = (acc.clicks ?? 0) + (curr.clicks ?? 0);
                acc.position = (acc.position ?? 0) + (curr.position ?? 0);
                return acc;
            }, {});

            return {
                label: item.label,
                searchtype: item.searchtype,
                ...itemSummary,
            };
        });

        const nodes = summaryData.reduce((acc, curr) => {
            if (webNodesTypes.includes(curr?.searchtype))
                acc.push({ ...curr, id: curr.searchtype });

            return acc;
        }, []);

        const web = summaryData.find((item) => item.searchtype === 'web');
        const webSummary = nodes.reduce((acc, curr) => {
            if (!acc) {
                acc = {
                    clicks: 0,
                    impressions: 0,
                    position: 0,
                };
            }
            acc = {
                clicks: acc.clicks + (curr.clicks ?? 0),
                impressions: acc.impressions + (curr.impressions ?? 0),
                position: acc.position + (curr.position ?? 0),
            };
            return acc;
        }, null);

        const webOther = {
            label: 'Web other',
            searchtype: 'web_other',
            clicks: web.clicks - webSummary.clicks,
            impressions: web.impressions - webSummary.impressions,
            position: null,
        };
        nodes.push(webOther);

        const data = summaryData.reduce((acc, curr) => {
            if (curr.searchtype === 'web') curr.nodes = nodes;

            if (!webNodesTypes.includes(curr?.searchtype))
                acc.push({ ...curr, id: curr.searchtype });

            return acc;
        }, []);

        return (
            <div className="row card-row d-flex">
                <div className="col-12">
                    <div className="card rounded-main overflow-hidden ">
                        <div className="card-body">
                            <div className="row">
                                <div
                                    className="col-4"
                                    style={{ height: '400px', position: 'relative' }}
                                >
                                    <Pie data={chartData} options={options} />
                                </div>
                                <div className="col-8">
                                    <div className="row">
                                        <div className="col">
                                            <DataTable
                                                nodes={data}
                                                sortFns={{}}
                                                COLUMNS={COLUMNS}
                                                openedItemIds={['web']}
                                                fileName="Brandes vs Non-branded"
                                                searchFunction={(data) => data}
                                                hasTree
                                                onlyTable
                                                uniqKey={'label'}
                                                additionalStyles={{
                                                    Table: `
                                                    --data-table-library_grid-template-columns :  minmax(160px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(160px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                                ${scrollForTable}
                                                `,
                                                    BaseCell: `  font-family: 'Raleway';
                                                            font-weight: 500;
                                                            font-size: 16px;
                                                            line-height: 19px;
                                                            padding: 16px 5px !important;
                                                            color: #0D182C;
                                                            border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                            &.underline {
                                                                text-decoration: underline;
                                                            }
                                                            `,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GoogleSurfaces;
