import React, { Component } from 'react';
import Select from 'react-select';
import { getAccountForDomain } from './query';
import Loading from '../../components/Loading';
import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import Table from './Table';
import moment from 'moment';

class GSCTestData extends Component {
    constructor(props) {
        super();

        this.result = props.result;
        this.onHandleText = this.onHandleText.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.collectData = this.collectData.bind(this);

        (this.end_date = moment().format('YYYY-MM-DD')),
            (this.start_date = moment().subtract(7, 'days').format('YYYY-MM-DD')),
            (this.state = {
                textValue: `{
            "aggregationType": "AUTO",
            "dataState": "ALL",
            "startDate": "${this.start_date}",
            "endDate": "${this.end_date}",
            "type": "WEB",
            "dimensions": [
                "page",
                "device",
                "country",
                "query"
            ]
            }`,
                domain_url: '',
                error: {},
                showedValue: [],
                isLoading: false,
                savedBody: {},
                data: [],
                columns: [],
            });
    }

    searchFunction(data, search) {
        return data.filter((item) => {
            for (let key in item) {
                if (item[key]?.toString().toLowerCase().includes(search.toLowerCase())) return true;
            }
            return false;
        });
    }
    handleSelect(e) {
        this.setState({
            domain_url: e.value,
            error: {
                ...this.state.error,
                select: '',
            },
        });
    }

    onHandleText(e) {
        this.setState({
            textValue: e.target.value,
            error: {
                ...this.state.error,
                textarea: '',
            },
        });
    }

    onSubmit() {
        const { textValue, domain_url } = this.state;
        try {
            if (!domain_url)
                return this.setState({
                    error: { ...this.state.error, select: 'You need to select domain.' },
                });
            const resource = JSON.parse(textValue);
            this.sendMessage(resource, domain_url);
        } catch (err) {
            return this.setState({
                error: { ...this.state.error, textarea: 'Please enter valid object' },
            });
        }
    }

    async sendMessage(resource, domain_url) {
        try {
            this.setState({ isLoading: true, data: [], savedBody: resource });
            const getAccountResponse = await getAccountForDomain(
                this.props.endpoint_url,
                { domain_url },
                this.props.csrf_token,
            );
            const getAccount = await getAccountResponse.json();
            const account = getAccount?.account_data;
            if (!account)
                return this.setState({
                    error: { ...this.state.error, textarea: 'No Account for this domain.' },
                });

            const params = {
                resource: resource,
                domain: domain_url,
            };

            const response = await fetch(
                'https://us-central1-eseos-apps.cloudfunctions.net/gscTestingQuery',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        account,
                        queries: [
                            {
                                ...params,
                                key: 'data',
                            },
                        ],
                    }),
                },
            );
            const data = await response.json();
            const results = data?.results;
            if (data?.status === 200 && results) {
                if (!results?.data?.length)
                    return this.setState({ error: { ...this.state.error, textarea: 'No Data' } });
                this.setState({
                    showedValue: results?.data,
                    error: { ...this.state.error, textarea: '' },
                });
                this.collectData();
            } else {
                this.setState({
                    showedValue: [],
                    error: {
                        ...this.state.error,
                        textarea: data?.message ?? 'Something went wrong. Please try again.',
                    },
                });
            }
        } catch (err) {
            return this.setState({
                error: {
                    ...this.state.error,
                    textarea: err?.message ?? 'Something went wrong. Please try again.',
                },
            });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    collectData() {
        try {
            if (this.state.showedValue?.length === 0) return;
            const parsedData = _.cloneDeep(this.state.showedValue);

            const data = parsedData?.map((item) => {
                if (item?.keys) {
                    const dimensions = item.keys.reduce((acc, curr, i) => {
                        const key = (this.state.savedBody?.dimensions?.[i] ?? `key - ${i}`).replace(
                            /_/g,
                            ' ',
                        );
                        acc[key] = curr;
                        return acc;
                    }, {});
                    delete item.keys;
                    return { ...dimensions, ...item, id: uuid() };
                }
                return item;
            });

            const keys = Object.keys(data?.[0])?.filter((item) => item !== 'id') ?? [];
            const columns = keys.map((key) => ({
                label: key,
                key: key,
                data: key,
                Component: Cell,
                renderCell: ({ data }) => {
                    if (key === 'ctr') return numeral(data).format('0.00%');
                    if (typeof data === 'number') return numeral(data).format('0.0a');
                    return data;
                },
            }));
            this.setState({ data, columns });
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const domains = JSON.parse(this.props.domains);

        const optionList = domains.map((domain) => ({
            value: domain.url,
            label: (
                <div className="gsc-test-user">
                    <div>{domain.url}</div>
                    <div>
                        {domain.active ? (
                            <i className="text-success icofont-verification-check"></i>
                        ) : (
                            <i className=" text-danger icofont-ui-close"></i>
                        )}{' '}
                    </div>
                </div>
            ),
        }));
        return (
            <div className="gsc-testing-container">
                <div className="gsc-testing-header">Domain</div>
                <div className="dropdown primary no-padding">
                    <Select
                        options={optionList}
                        menuPortalTarget={document.querySelector('body')}
                        // menuPosition={'fixed'}
                        styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                minWidth: '295px',
                                boxShadow: 'none',
                            }),
                            menuPortal: (provided) => ({ ...provided, zIndex: 10000 }),
                            menu: (provided) => ({ ...provided, zIndex: 10000 }),
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: '4px',
                            colors: {
                                ...theme.colors,
                                primary: '#888adb',
                            },
                        })}
                        onChange={this.handleSelect}
                        isSearchable={true}
                        placeholder="Select domain"
                        onFocus={(e) => {
                            const parent = e.nativeEvent.target.parentNode.parentNode;
                            const selectedValue = parent.children[0];
                            selectedValue.style.display = 'none';
                        }}
                        onBlur={(e) => {
                            const parent = e.nativeEvent.target.parentNode.parentNode;
                            const selectedValue = parent.children[0];
                            selectedValue.style.display = 'inline-grid';
                        }}
                        blurInputOnSelect={true}
                    />
                </div>
                <div>
                    {this.state.error?.select && (
                        <div className="error-message">{this.state.error.select}</div>
                    )}
                </div>
                <div className="logs-buttons-gsc-test">
                    <a
                        href={`/halubcnd/docs/log/?q=${this.state.domain_url}`}
                        disabled={!this.state.domain_url}
                        className={`${!this.state.domain_url ? 'disabled-btn' : ''}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Logs
                    </a>
                    <a
                        href={`/halubcnd/docs/linkedaccountsgranterror/?q=${this.state.domain_url}`}
                        disabled={!this.state.domain_url}
                        className={`${!this.state.domain_url ? 'disabled-btn' : ''}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Grants
                    </a>
                </div>
                <div>
                    <div className="gsc-testing-header">Request Body</div>
                    <textarea
                        rows={10}
                        onChange={this.onHandleText}
                        className="gsc-testing-header-textarea"
                        value={this.state.textValue}
                        style={{ width: '100%', marginTop: '19px', borderColor: 'hsl(0, 0%, 80%)' }}
                    ></textarea>
                </div>
                <div>
                    {this.state.error?.textarea && (
                        <div className="error-message">{this.state.error.textarea}</div>
                    )}
                </div>
                <div className="execute-btn">
                    <button
                        onClick={this.onSubmit}
                        className="bg-purple gsc-testing-execute"
                        disabled={this.state.isLoading}
                    >
                        Execute
                    </button>
                    {this.state.isLoading && <Loading />}
                </div>
                <Table
                    data={this.state.data}
                    columns={this.state.columns}
                    searchFunction={this.searchFunction}
                    isLoading={this.state.isLoading}
                />
            </div>
        );
    }
}

export default GSCTestData;
