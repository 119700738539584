import numeral from 'numeral';
import React, { Component } from 'react';
import Loading from '../../../components/Loading';
import query from './query';

class PromoCode extends Component {
    constructor(props) {
        super();

        this.updateState = this.updateState.bind(this);
        this.sendPromoCode = this.sendPromoCode.bind(this);
        this.changeLoading = this.changeLoading.bind(this);

        this.state = {
            loading: false,
            price: JSON.parse(props.price),
            product: JSON.parse(props.product),
            promotion: JSON.parse(props.promotion),
            discount_price: props.discount_price,
            product_id: props.product_id,
            message: props.message,
            data: {
                promotion_code: '',
            },
            show_plans: JSON.parse(props.show_plans) ?? [],
        };
    }

    updateData(key, e) {
        const data = { ...this.state.data };
        data[key] = e.target.val || e.target.value;
        this.setState({ data });
    }

    updateState(data) {
        const { discount_price, price, product, promotion, message, show_plans } = data;

        const promotionId = promotion?.active ? promotion?.id : '';
        this.props.setPromotionCode(promotionId);

        this.setState({
            discount_price,
            price,
            product,
            promotion,
            message,
            data: {
                promotion_code: '',
            },
            show_plans: JSON.parse(show_plans) ?? this.state.show_plans,
        });
    }

    changeLoading(bool) {
        this.setState({
            loading: bool,
        });
    }

    sendPromoCode(e) {
        e.preventDefault();
        const {
            product_id,
            data: { promotion_code },
        } = this.state;

        const args = {
            endpoint_url: this.props.endpoint_url,
            changeLoading: this.changeLoading,
            updateState: this.updateState,
            promotion_code,
            product_id,
        };

        this.changeLoading(true);
        query(args);
    }

    render() {
        const {
            discount_price,
            price,
            product,
            promotion,
            message,
            data: { promotion_code },
            loading,
            show_plans,
        } = this.state;

        return (
            <div className="col-lg-6 col-sm-6 col-xs-12">
                <div className="form-info">
                    <h1 className="text-primary">{product.name}</h1>
                    <p>{product.description}</p>
                    <p>
                        Enjoy a 14-day FREE trial, then billed at{' '}
                        {promotion.active && promotion.coupon ? (
                            <>
                                <span style={{ textDecoration: 'line-through' }}>
                                    {' '}
                                    {numeral(price.amount).format('$0.00')}
                                </span>
                                <strong style={{ display: 'inline-block' }}>
                                    {' '}
                                    {numeral(discount_price).format('$0.00')}
                                </strong>
                            </>
                        ) : (
                            numeral(price.amount).format('$0.00')
                        )}{' '}
                        per [month]. Your plan includes {this.props.domains_count} domains, but
                        during the trial, only one domain is active.
                        <strong> Cancel anytime!</strong>
                    </p>

                    <p>
                        <span className="bold">
                            {' '}
                            <i
                                className="icon-info"
                                style={{ marginRight: '4px', color: '#f19b68' }}
                            ></i>
                            Trial Eligibility:
                        </span>{' '}
                        Trials are only available for new domains. If a domain has been registered
                        with GDdash in the past, your account will be charged immediately. Some
                        features are only available with paid plans.
                    </p>

                    {promotion.active && (
                        <p>
                            <span style={{ background: '#FFFF99' }}>
                                Promotion code{' '}
                                <strong style={{ display: 'inline-block' }}>
                                    {promotion.code}
                                </strong>{' '}
                                original Price: {numeral(price.amount).format('$0.00')}
                                <br />
                                after discount: {numeral(discount_price).format('$0.00')}/month will
                                be applied to your subscription.
                            </span>
                        </p>
                    )}

                    {!!show_plans?.length && (
                        <div className="subscription-types">
                            <ul>
                                {show_plans.map((item) => {
                                    return (
                                        <li key={item.plan_id}>
                                            <a
                                                className="switch-button not-active"
                                                href={`/customers/registration/?product_id=${item.plan_id}`}
                                            >
                                                <span className="switch-circle" />
                                            </a>
                                            <p>Save with {item.name} billing</p>
                                            <span className="badge color-red">{item.discount}</span>
                                            <div className="subscription-types-amount">
                                                {item.discount_price ? (
                                                    <>
                                                        <span
                                                            style={{
                                                                textDecoration: 'line-through',
                                                            }}
                                                        >
                                                            {' '}
                                                            {numeral(item.amount).format('$0.00')}
                                                        </span>
                                                        <strong style={{ display: 'inline-block' }}>
                                                            {' '}
                                                            {numeral(item.discount_price).format(
                                                                '$0.00',
                                                            )}
                                                        </strong>
                                                    </>
                                                ) : (
                                                    numeral(item.amount).format('$0.00')
                                                )}

                                                <strong className="plan-amount">
                                                    {item.period}
                                                </strong>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}

                    {!promotion.active && (
                        <div className="coupon-code-container">
                            <form className="coupon-code" onSubmit={this.sendPromoCode}>
                                <div className="text-field">
                                    <input type="hidden" name="product_id" value="{{ price.id }}" />
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="promotion_code"
                                        onChange={this.updateData.bind(this, 'promotion_code')}
                                        value={promotion_code}
                                        placeholder="Discount Code"
                                        disabled={loading}
                                    />
                                    <button
                                        type="submit"
                                        className="button button-filled button-primary"
                                        id="coupon-code-submit"
                                    >
                                        Add
                                    </button>
                                </div>
                                {loading && <Loading padding={'10px 0'} />}
                            </form>
                            {message && !loading && <div className="error-message">{message}</div>}
                        </div>
                    )}

                    <p style={{ marginTop: '20px' }}>
                        <i
                            className="icon-info"
                            style={{ marginRight: '4px', color: '#F16B68' }}
                        ></i>
                        IMPORTANT:
                        <ul>
                            <li>
                                <span className="bold">Plan Limits:</span>Your plan include{' '}
                                {this.props.monthly_pages} Discover pages and{' '}
                                {this.props.monthly_search_records} Search records per month.
                            </li>
                            <li>
                                <span className="bold">Extra charges</span> apply beyond plan
                                limits; {numeral(this.props.per_page_amount).format('$0.00')} per
                                extra Discover page analyzed, and{' '}
                                {numeral(this.props.search_per_page_amount).format('$0.0000')} per
                                Search record (keyword+URL+Device).
                            </li>
                            <li>
                                <span className="bold">Charges</span> Charges are calculated in{' '}
                                {numeral(this.props.additional_pages_payment_amount).format(
                                    '$0.00',
                                )}{' '}
                                increments based on your traffic.
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        );
    }
}

export default PromoCode;
