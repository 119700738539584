import numeral from 'numeral';
import { Line } from 'rc-progress';
import { Component } from 'react';
import { isTrue } from '../../helpers/isTrue';
import { getDaysOfMonth } from '../../helpers/getDataRange';
import { getastMonthCharges } from './query';

class AdditionalCharges extends Component {
    constructor(props) {
        super();

        this.isSuggestToChangePlan = this.isSuggestToChangePlan.bind(this);

        this.plan_monthly_discover_pages = Number(props.plan_monthly_discover_pages);
        this.plan_monthly_search_records = Number(props.plan_monthly_search_records);
        this.used_monthly_discover_pages = Number(props.used_monthly_discover_pages);
        this.used_monthly_search_records = Number(props.used_monthly_search_records);

        this.last_month_search_records = Number(props.last_month_search_records);
        this.last_month_search_extra = Number(props.last_month_search_extra);
        this.last_month_discover_records = Number(props.last_month_discover_records);
        this.last_month_discover_extra = Number(props.last_month_discover_extra);

        this.isUsageHigher = this.isUsageHigher.bind(this);
        this.basicPlans = ['Basic', 'Trial_Basic'];

        this.state = {
            additionalChargesDiscover: null,
            additionalChargesSearch: null,
        };
    }

    async componentDidMount() {
        try {
            const response = await getastMonthCharges(this.props.csrf_token);
            const data = await response.json();
            const discover = data?.result.find((x) => x.collected_type === 'discover');
            const search = data?.result.find((x) => x.collected_type === 'search');

            this.setState({
                additionalChargesDiscover: discover,
                additionalChargesSearch: search,
            });
        } catch (err) {
            console.log(err);
        }
    }

    isUsageHigher(type) {
        switch (type) {
            case 'discover':
                return this.used_monthly_discover_pages > this.plan_monthly_discover_pages;
            case 'search':
                return this.used_monthly_search_records > this.plan_monthly_search_records;
            default:
                return false;
        }
    }

    isSuggestToChangePlan(discoverMonthlyEstimation, searchMonthlyEstimation) {
        if (
            this.used_monthly_discover_pages > this.plan_monthly_discover_pages ||
            this.used_monthly_search_records > this.plan_monthly_search_records ||
            discoverMonthlyEstimation > this.plan_monthly_discover_pages ||
            searchMonthlyEstimation > this.plan_monthly_search_records
        )
            return true;
        return false;
    }

    render() {
        const fisrtLinePercent =
            this.plan_monthly_discover_pages >= this.used_monthly_discover_pages
                ? {
                      percent:
                          (this.used_monthly_discover_pages / this.plan_monthly_discover_pages) *
                          100,
                      exceeded: false,
                  }
                : {
                      percent:
                          (this.plan_monthly_discover_pages / this.used_monthly_discover_pages) *
                          100,
                      exceeded: true,
                  };

        const searchLinePercent =
            this.plan_monthly_search_records > this.used_monthly_search_records
                ? {
                      percent:
                          (this.used_monthly_search_records / this.plan_monthly_search_records) *
                          100,
                      exceeded: false,
                  }
                : {
                      percent:
                          (this.plan_monthly_search_records / this.used_monthly_search_records) *
                          100,
                      exceeded: true,
                  };

        const lastMonthFisrtLinePercent =
            this.plan_monthly_discover_pages > this.last_month_discover_records
                ? {
                      percent:
                          (this.last_month_discover_records / this.plan_monthly_discover_pages) *
                          100,
                      exceeded: false,
                  }
                : {
                      percent:
                          (this.plan_monthly_discover_pages / this.last_month_discover_records) *
                          100,
                      exceeded: true,
                  };

        const lastMonthSearchLinePercent =
            this.plan_monthly_search_records > this.last_month_search_records
                ? {
                      percent:
                          (this.last_month_search_records / this.plan_monthly_search_records) * 100,
                      exceeded: false,
                  }
                : {
                      percent:
                          (this.plan_monthly_search_records / this.last_month_search_records) * 100,
                      exceeded: true,
                  };

        let discoverArrow = null;
        let searchArrow = null;
        let lastMonthDiscoverArrow = null;
        let lastMonthSearchArrow = null;

        if (
            this.used_monthly_discover_pages >= this.plan_monthly_discover_pages &&
            this.plan_monthly_discover_pages
        ) {
            discoverArrow =
                (this.plan_monthly_discover_pages / this.used_monthly_discover_pages) * 100;
        }

        if (
            this.used_monthly_search_records >= this.plan_monthly_search_records &&
            this.plan_monthly_search_records
        ) {
            searchArrow =
                (this.plan_monthly_search_records / this.used_monthly_search_records) * 100;
        }

        if (
            this.last_month_discover_records >= this.plan_monthly_discover_pages &&
            this.plan_monthly_discover_pages
        ) {
            lastMonthDiscoverArrow =
                (this.plan_monthly_discover_pages / this.last_month_discover_records) * 100;
        }

        if (
            this.last_month_search_records >= this.plan_monthly_search_records &&
            this.plan_monthly_search_records
        ) {
            lastMonthSearchArrow =
                (this.plan_monthly_search_records / this.last_month_search_records) * 100;
        }

        const now = new Date();
        const days = now.getDate() - 2;
        const month = now.getMonth();
        const monthDaysCount = getDaysOfMonth(month);
        const discoverDailyUsage = this.used_monthly_discover_pages / (days < 1 ? 1 : days);
        const discoverMonthlyEstimation = discoverDailyUsage * monthDaysCount;

        const searchDailyUsage = this.used_monthly_search_records / (days < 1 ? 1 : days);
        const searchMonthlyEstimation = searchDailyUsage * monthDaysCount;

        const isShowSuggestion =
            this.isSuggestToChangePlan(discoverMonthlyEstimation, searchMonthlyEstimation) ||
            this.state.additionalChargesDiscover ||
            this.state.additionalChargesSearch;

        return this.props.sub_status === 'trialing' ? (
            <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2 additional-charges">
                We do not charge any additional usage fees Within Trial Period.
            </small>
        ) : (
            <>
                <div className={'col-md-6'}>
                    <div className="additional-charges-trails">
                        <strong>Current month usage</strong>
                        <div className="additional-charges-trails-item">
                            <strong>Google Discover Pages</strong>
                            <div className="progress-container">
                                <Line
                                    percent={fisrtLinePercent.percent}
                                    strokeWidth={1.5}
                                    trailWidth={1.5}
                                    strokeColor="#00c17d"
                                    trailColor={fisrtLinePercent.exceeded ? '#00935a' : '#d0ced7'}
                                    className="additional-charges-trails-item-bar"
                                />
                                {discoverArrow && (
                                    <div
                                        className="arrow"
                                        style={{ left: `${discoverArrow + 0.5}%` }}
                                    ></div>
                                )}
                            </div>
                            <div className="additional-charges-trails-all-items">
                                <div>{numeral(this.used_monthly_discover_pages).format('0,0')}</div>
                                <div className="additional-charges-trails-all-items-description">
                                    In plan{' '}
                                    {numeral(this.plan_monthly_discover_pages).format('0,0')}
                                </div>
                            </div>
                        </div>
                        {(!this.basicPlans.includes(this.props.plan_type) ||
                            isTrue(this.props.is_superuser)) && (
                            <div className="additional-charges-trails-item">
                                <strong>Google Search Records</strong>
                                <div className="progress-container">
                                    <Line
                                        percent={searchLinePercent.percent}
                                        strokeWidth={1.5}
                                        trailWidth={1.5}
                                        strokeColor="#00c17d"
                                        trailColor={
                                            searchLinePercent.exceeded ? '#00935a' : '#d0ced7'
                                        }
                                        className="additional-charges-trails-item-bar"
                                    />
                                    {searchArrow && (
                                        <div
                                            className="arrow"
                                            style={{ left: `${searchArrow + 0.5}%` }}
                                        ></div>
                                    )}
                                </div>
                                <div className="additional-charges-trails-all-items">
                                    <div>
                                        {numeral(this.used_monthly_search_records).format('0,0')}
                                    </div>
                                    <div className="additional-charges-trails-all-items-description">
                                        In plan{' '}
                                        {numeral(this.plan_monthly_search_records).format('0,0')}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2 additional-charges">
                            Based on <strong>{numeral(discoverDailyUsage).format('0,0')}</strong>{' '}
                            average daily discover pages, we estimate{' '}
                            <strong>{numeral(discoverMonthlyEstimation).format('0,0')}</strong>{' '}
                            pages by end of month (
                            {this.isUsageHigher('discover')
                                ? 'beyond plan limits, additional fees may occur'
                                : 'within plan limits, no additional fees'}
                            )
                        </small>
                    </div>
                    {this.used_monthly_search_records > 0 && (
                        <div>
                            <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2 additional-charges">
                                Based on <strong>{numeral(searchDailyUsage).format('0,0')}</strong>{' '}
                                average daily search records, we estimate{' '}
                                <strong>{numeral(searchMonthlyEstimation).format('0,0')}</strong>{' '}
                                pages by end of month (
                                {this.isUsageHigher('search')
                                    ? 'beyond plan limits, additional fees may occur'
                                    : 'within plan limits, no additional fees'}
                                )
                            </small>
                        </div>
                    )}
                </div>
                {!!(this.last_month_discover_records || this.last_month_search_records) && (
                    <div className="col-md-6">
                        <div className="additional-charges-trails">
                            <strong>Last Month Usages & Charges</strong>
                            {!!this.last_month_discover_records && (
                                <>
                                    <div className="additional-charges-trails-item">
                                        <strong>Google Discover Pages</strong>
                                        <div className="progress-container">
                                            <Line
                                                percent={lastMonthFisrtLinePercent.percent}
                                                strokeWidth={1.5}
                                                trailWidth={1.5}
                                                strokeColor="#00c17d"
                                                trailColor={
                                                    lastMonthFisrtLinePercent.exceeded
                                                        ? '#00935a'
                                                        : '#d0ced7'
                                                }
                                                className="additional-charges-trails-item-bar"
                                            />
                                            {lastMonthDiscoverArrow && (
                                                <div
                                                    className="arrow"
                                                    style={{
                                                        left: `${lastMonthDiscoverArrow + 0.5}%`,
                                                    }}
                                                ></div>
                                            )}
                                        </div>
                                        <div className="additional-charges-trails-all-items">
                                            <div>
                                                {numeral(this.last_month_discover_records).format(
                                                    '0,0',
                                                )}
                                            </div>
                                            <div className="additional-charges-trails-all-items-description">
                                                In plan{' '}
                                                {numeral(this.plan_monthly_discover_pages).format(
                                                    '0,0',
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {(!this.basicPlans.includes(this.props.plan_type) ||
                                isTrue(this.props.is_superuser)) &&
                                !!this.last_month_search_records && (
                                    <div className="additional-charges-trails-item">
                                        <strong>Google Search Records</strong>
                                        <div className="progress-container">
                                            <Line
                                                percent={lastMonthSearchLinePercent.percent}
                                                strokeWidth={1.5}
                                                trailWidth={1.5}
                                                strokeColor="#00c17d"
                                                trailColor={
                                                    lastMonthSearchLinePercent.exceeded
                                                        ? '#00935a'
                                                        : '#d0ced7'
                                                }
                                                className="additional-charges-trails-item-bar"
                                            />
                                            {lastMonthSearchArrow && (
                                                <div
                                                    className="arrow"
                                                    style={{
                                                        left: `${lastMonthSearchArrow + 0.5}%`,
                                                    }}
                                                ></div>
                                            )}
                                        </div>
                                        <div className="additional-charges-trails-all-items">
                                            <div>
                                                {numeral(this.last_month_search_records).format(
                                                    '0,0',
                                                )}
                                            </div>
                                            <div className="additional-charges-trails-all-items-description">
                                                In plan{' '}
                                                {numeral(this.plan_monthly_search_records).format(
                                                    '0,0',
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                        {this.state.additionalChargesDiscover && (
                            <div>
                                <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2 additional-charges">
                                    <strong>
                                        $
                                        {numeral(
                                            this.state.additionalChargesDiscover.charge,
                                        ).format('0,0')}
                                    </strong>{' '}
                                    for{' '}
                                    <strong>
                                        {numeral(
                                            this.state.additionalChargesDiscover.additional_pages,
                                        ).format('0,0')}
                                    </strong>{' '}
                                    additional discover pages. Upgrade the plan now to accommodate
                                    more data and avoid future additional charges.
                                </small>
                            </div>
                        )}
                        {this.state.additionalChargesSearch && (
                            <div>
                                <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2 additional-charges">
                                    <strong>
                                        $
                                        {numeral(this.state.additionalChargesSearch.charge).format(
                                            '0,0',
                                        )}
                                    </strong>{' '}
                                    for{' '}
                                    <strong>
                                        {numeral(
                                            this.state.additionalChargesSearch.additional_pages,
                                        ).format('0,0')}
                                    </strong>{' '}
                                    additional search records.
                                </small>
                            </div>
                        )}
                    </div>
                )}
                {isShowSuggestion && (
                    <div>
                        <strong>
                            Based on the current month usage, we recommend to upgrade your plan to
                            avoid any additional monthly charges.
                        </strong>
                        <div className=" my-3">
                            <a
                                className="button button-filled button-primary width-sm btn"
                                href="/customers/change_plan/"
                            >
                                Upgrade plan
                            </a>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default AdditionalCharges;
