export const getAccounts = (domain_id) => {
    return fetch(`/domains/available-accounts/${domain_id}/`);
    // return fetch(`/domains/available-accounts/uuid:id/`, {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'X-CSRFToken': csrf_token,
    //     },
    // });
};
