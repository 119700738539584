import Query from '../../components/Report';

const query = ({ offset = 0, domain = '', limit }) =>
    new Query('discover-authors', {
        offset,
        extra_domain: domain,
        limit,
    });

export default query;
