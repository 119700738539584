export const KEYWORDS_COUNT = [
    {
        label: '10000',
        value: 10000,
    },
    {
        label: '20000',
        value: 20000,
    },
    {
        label: 30000,
        value: 30000,
    },
];

export const COUNTRIES = [
    {
        code: 'us',
        region: 'United States',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.com',
    },
    {
        code: 'uk',
        region: 'United Kingdom',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.co.uk',
    },
    {
        code: 'ca',
        region: 'Canada',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.ca',
    },
    {
        code: 'ru',
        region: 'Russia',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.ru',
    },
    {
        code: 'de',
        region: 'Germany',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.de',
    },
    {
        code: 'fr',
        region: 'France',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.fr',
    },
    {
        code: 'es',
        region: 'Spain',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.es',
    },
    {
        code: 'it',
        region: 'Italy',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.it',
    },
    {
        code: 'br',
        region: 'Brazil',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.com.br',
    },
    {
        code: 'au',
        region: 'Australia',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.com.au',
    },
    {
        code: 'ar',
        region: 'Argentina',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.com.ar',
    },
    {
        code: 'be',
        region: 'Belgium',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.be',
    },
    {
        code: 'ch',
        region: 'Switzerland',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.ch',
    },
    {
        code: 'dk',
        region: 'Denmark',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.dk',
    },
    {
        code: 'fi',
        region: 'Finland',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.fi',
    },
    {
        code: 'hk',
        region: 'Hong Kong',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.com.hk',
    },
    {
        code: 'ie',
        region: 'Ireland',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.ie',
    },
    {
        code: 'il',
        region: 'Israel',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.co.il',
    },
    {
        code: 'mx',
        region: 'Mexico',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.com.mx',
    },
    {
        code: 'nl',
        region: 'Netherlands',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.nl',
    },
    {
        code: 'no',
        region: 'Norway',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.no',
    },
    {
        code: 'pl',
        region: 'Poland',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.pl',
    },
    {
        code: 'se',
        region: 'Sweden',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.se',
    },
    {
        code: 'sg',
        region: 'Singapore',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.com.sg',
    },
    {
        code: 'tr',
        region: 'Turkey',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.com.tr',
    },
    {
        code: 'jp',
        region: 'Japan',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.co.jp',
    },
    {
        code: 'in',
        region: 'India',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.co.in',
    },
    {
        code: 'hu',
        region: 'Hungary',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.hu',
    },
    {
        code: 'af',
        region: 'Afghanistan',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.af',
    },
    {
        code: 'al',
        region: 'Albania',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.al',
    },
    {
        code: 'dz',
        region: 'Algeria',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.dz',
    },
    {
        code: 'ao',
        region: 'Angola',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.co.ao',
    },
    {
        code: 'am',
        region: 'Armenia',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.am',
    },
    {
        code: 'at',
        region: 'Austria',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.at',
    },
    {
        code: 'az',
        region: 'Azerbaijan',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.az',
    },
    {
        code: 'bh',
        region: 'Bahrain',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.bh',
    },
    {
        code: 'bd',
        region: 'Bangladesh',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.bd',
    },
    {
        code: 'by',
        region: 'Belarus',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.by',
    },
    {
        code: 'bz',
        region: 'Belize',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.bz',
    },
    {
        code: 'bo',
        region: 'Bolivia',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.bo',
    },
    {
        code: 'ba',
        region: 'Bosnia and Herzegovina',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.ba',
    },
    {
        code: 'bw',
        region: 'Botswana',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.co.bw',
    },
    {
        code: 'bn',
        region: 'Brunei',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.bn',
    },
    {
        code: 'bg',
        region: 'Bulgaria',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.bg',
    },
    {
        code: 'cv',
        region: 'Cabo Verde',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.cv',
    },
    {
        code: 'kh',
        region: 'Cambodia',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.kh',
    },
    {
        code: 'cm',
        region: 'Cameroon',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.cm',
    },
    {
        code: 'cl',
        region: 'Chile',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.cl',
    },
    {
        code: 'co',
        region: 'Colombia',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.com.co',
    },
    {
        code: 'cr',
        region: 'Costa Rica',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.co.cr',
    },
    {
        code: 'hr',
        region: 'Croatia',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.hr',
    },
    {
        code: 'cy',
        region: 'Cyprus',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.cy',
    },
    {
        code: 'cz',
        region: 'Czech Republic',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.cz',
    },
    {
        code: 'cd',
        region: 'Congo',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.cd',
    },
    {
        code: 'do',
        region: 'Dominican Republic',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.do',
    },
    {
        code: 'ec',
        region: 'Ecuador',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.ec',
    },
    {
        code: 'eg',
        region: 'Egypt',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.eg',
    },
    {
        code: 'sv',
        region: 'El Salvador',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.sv',
    },
    {
        code: 'ee',
        region: 'Estonia',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.ee',
    },
    {
        code: 'et',
        region: 'Ethiopia',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.et',
    },
    {
        code: 'ge',
        region: 'Georgia',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.ge',
    },
    {
        code: 'gh',
        region: 'Ghana',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.gh',
    },
    {
        code: 'gr',
        region: 'Greece',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.gr',
    },
    {
        code: 'gt',
        region: 'Guatemala',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.gt',
    },
    {
        code: 'gy',
        region: 'Guyana',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.gy',
    },
    {
        code: 'ht',
        region: 'Haiti',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.ht',
    },
    {
        code: 'hn',
        region: 'Honduras',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.hn',
    },
    {
        code: 'is',
        region: 'Iceland',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.is',
    },
    {
        code: 'id',
        region: 'Indonesia',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.co.id',
    },
    {
        code: 'jm',
        region: 'Jamaica',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.jm',
    },
    {
        code: 'jo',
        region: 'Jordan',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.jo',
    },
    {
        code: 'kz',
        region: 'Kazakhstan',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.kz',
    },
    {
        code: 'kw',
        region: 'Kuwait',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.kw',
    },
    {
        code: 'lv',
        region: 'Latvia',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.lv',
    },
    {
        code: 'lb',
        region: 'Lebanon',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.lb',
    },
    {
        code: 'lt',
        region: 'Lithuania',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.lt',
    },
    {
        code: 'lu',
        region: 'Luxembourg',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.lu',
    },
    {
        code: 'mg',
        region: 'Madagascar',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.mg',
    },
    {
        code: 'my',
        region: 'Malaysia',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.com.my',
    },
    {
        code: 'mt',
        region: 'Malta',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.mt',
    },
    {
        code: 'mu',
        region: 'Mauritius',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.mu',
    },
    {
        code: 'md',
        region: 'Moldova',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.md',
    },
    {
        code: 'mn',
        region: 'Mongolia',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.mn',
    },
    {
        code: 'me',
        region: 'Montenegro',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.me',
    },
    {
        code: 'ma',
        region: 'Morocco',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.co.ma',
    },
    {
        code: 'mz',
        region: 'Mozambique',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.co.mz',
    },
    {
        code: 'na',
        region: 'Namibia',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.na',
    },
    {
        code: 'np',
        region: 'Nepal',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.np',
    },
    {
        code: 'nz',
        region: 'New Zealand',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.co.nz',
    },
    {
        code: 'ni',
        region: 'Nicaragua',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.ni',
    },
    {
        code: 'ng',
        region: 'Nigeria',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.ng',
    },
    {
        code: 'om',
        region: 'Oman',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.om',
    },
    {
        code: 'py',
        region: 'Paraguay',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.py',
    },
    {
        code: 'pe',
        region: 'Peru',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.pe',
    },
    {
        code: 'ph',
        region: 'Philippines',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.com.ph',
    },
    {
        code: 'pt',
        region: 'Portugal',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.pt',
    },
    {
        code: 'ro',
        region: 'Romania',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.ro',
    },
    {
        code: 'sa',
        region: 'Saudi Arabia',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.sa',
    },
    {
        code: 'sn',
        region: 'Senegal',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.sn',
    },
    {
        code: 'rs',
        region: 'Serbia',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.rs',
    },
    {
        code: 'sk',
        region: 'Slovakia',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.sk',
    },
    {
        code: 'si',
        region: 'Slovenia',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.si',
    },
    {
        code: 'za',
        region: 'South Africa',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.co.za',
    },
    {
        code: 'kr',
        region: 'South Korea',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.co.kr',
    },
    {
        code: 'lk',
        region: 'Sri Lanka',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.lk',
    },
    {
        code: 'th',
        region: 'Thailand',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.co.th',
    },
    {
        code: 'bs',
        region: 'Bahamas',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.bs',
    },
    {
        code: 'tt',
        region: 'Trinidad and Tobago',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.tt',
    },
    {
        code: 'tn',
        region: 'Tunisia',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.tn',
    },
    {
        code: 'ua',
        region: 'Ukraine',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.ua',
    },
    {
        code: 'ae',
        region: 'United Arab Emirates',
        research_types: 'Organic, Adwords, PLA, Keywords',
        google_search_domain: 'google.ae',
    },
    {
        code: 'uy',
        region: 'Uruguay',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.uy',
    },
    {
        code: 've',
        region: 'Venezuela',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.co.ve',
    },
    {
        code: 'vn',
        region: 'Vietnam',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.vn',
    },
    {
        code: 'zm',
        region: 'Zambia',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.co.zm',
    },
    {
        code: 'zw',
        region: 'Zimbabwe',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.co.zw',
    },
    {
        code: 'ly',
        region: 'Libya',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.ly',
    },
    {
        code: 'mobile-us',
        region: 'United States',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.com',
    },
    {
        code: 'mobile-uk',
        region: 'United Kingdom',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.com',
    },
    {
        code: 'mobile-ca',
        region: 'Canada',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.ca',
    },
    {
        code: 'mobile-de',
        region: 'Germany',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.de',
    },
    {
        code: 'mobile-fr',
        region: 'France',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.fr',
    },
    {
        code: 'mobile-es',
        region: 'Spain',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.es',
    },
    {
        code: 'mobile-it',
        region: 'Italy',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.it',
    },
    {
        code: 'mobile-br',
        region: 'Brazil',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.com.br',
    },
    {
        code: 'mobile-au',
        region: 'Australia',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.com.au',
    },
    {
        code: 'mobile-dk',
        region: 'Denmark',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.dk',
    },
    {
        code: 'mobile-mx',
        region: 'Mexico',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.com.mx',
    },
    {
        code: 'mobile-nl',
        region: 'Netherlands',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.nl',
    },
    {
        code: 'mobile-se',
        region: 'Sweden',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.se',
    },
    {
        code: 'mobile-tr',
        region: 'Turkey',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.com.tr',
    },
    {
        code: 'mobile-in',
        region: 'India',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.co.in',
    },
    {
        code: 'mobile-id',
        region: 'Indonesia',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.co.id',
    },
    {
        code: 'mobile-il',
        region: 'Israel',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.co.il',
    },
    {
        code: 'il-ext',
        region: 'Israel Ext',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.co.il-ext',
    },
    {
        code: 'tr-ext',
        region: 'Turkey Ext',
        research_types: 'Organic, Adwords, PLA',
        google_search_domain: 'google.co.tr-ext',
    },
    {
        code: 'dk-ext',
        region: 'Denmark Ext',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.dk-ext',
    },
    {
        code: 'no-ext',
        region: 'Norway Ext',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.no-ext',
    },
    {
        code: 'se-ext',
        region: 'Sweden Ext',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.se-ext',
    },
    {
        code: 'fi-ext',
        region: 'Finland Ext',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.fi-ext',
    },
    {
        code: 'ch-ext',
        region: 'Switzerland Ext',
        research_types: 'Organic, Adwords, PLA',
        google_search_domain: 'google.ch-ext',
    },
    {
        code: 'mobile-il-ext',
        region: 'Israel Ext',
        research_types: 'Organic, Adwords',
        google_search_domain: 'google.co.il-ext',
    },
    {
        code: 'pa',
        region: 'Panama',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.pa',
    },
    {
        code: 'pk',
        region: 'Pakistan',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.pk',
    },
    {
        code: 'tw',
        region: 'Taiwan',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.tw',
    },
    {
        code: 'qa',
        region: 'Qatar',
        research_types: 'Organic, Adwords, Keywords',
        google_search_domain: 'google.com.qa',
    },
];
