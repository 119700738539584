import { Component } from 'react';
import ActivateDomain from '../../ActivateDomain/Domain';

class ActivateDomains extends Component {
    constructor() {
        super();

        this.changeDisableAll = this.changeDisableAll.bind(this);

        this.state = {
            disableAll: false,
        };
    }

    changeDisableAll(boolean) {
        this.setState({
            disableAll: boolean,
        });
    }

    render() {
        return (
            <div id="onboarding-general-settings">
                <div className="onboarding-subheader">
                    Which of these domains would you like to activate for the trial period?
                </div>
                <div>You can activate one free domain during trial.</div>
                <div className="onboarding-domains">
                    {this.props.domains?.length === 0 && 'Please add domains in Step 2.'}
                    {this.props.domains?.map((item) => (
                        <div key={item.url} className="d-flex mb-10">
                            <div className="mr-10">
                                <ActivateDomain
                                    domain_pk={item.pk}
                                    active_domain={item.status}
                                    domain_url={item.url}
                                    csrf_token={this.props.csrf_token}
                                    disableAll={this.state.disableAll}
                                    changeDisableAll={this.changeDisableAll}
                                    from_onboarding
                                />
                            </div>
                            <strong>{item?.url}</strong>
                        </div>
                    ))}
                </div>
                <div className="d-flex justify-content-end">
                    <button
                        className="button width-sm btn onboarding-btn-next d-flex justify-content-center onboarding-skip-btn"
                        style={{ lineHeight: 'unset' }}
                        onClick={() => this.props.handleBack()}
                    >
                        back
                    </button>
                    <button
                        className="button  button-filled button-primary width-sm btn onboarding-btn-next d-flex justify-content-center"
                        onClick={() => this.props.handleNext()}
                        disabled={this.props.isDisabledNext}
                    >
                        Next
                    </button>
                </div>
            </div>
        );
    }
}

export default ActivateDomains;
