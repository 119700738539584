import Query from '../../../../components/Report';

export const deleteBigramQuery = (clusterName) =>
    new Query('search-keywords-manual-cluster-delete-bigram', {
        clusterName,
    });

export const deleteClusterSummaryQuery = (clusterName) =>
    new Query('search-keywords-manual-cluster-delete-summary', {
        clusterName,
    });
