export function change(data, sel) {
    data.reverse();

    let sum = data.reduce(
        (e, f) => {
            if (e.last) {
                e[sel] = e[sel] || 0;
                e[sel] = e[sel] + (f[sel] - e.last[sel]);
            }

            e.last = f;

            return e;
        },
        { last: undefined },
    );

    data.reverse();
    return sum[sel];
}

export function avg(data, sel) {
    let sum = data.reduce((e, f) => {
        return e + f[sel];
    }, 0);
    return sum / data.length;
}

export function getCount(data) {
    return data.length;
}

/*
    Linear regression is a statistical method used to 
    model the relationship between two continuous variables. 
    It is used to predict the value of a dependent variable 
    based on the value of one or more independent variables.

    x - x-axis values, y - y-axis values

    there is a formula to get Linear regression
*/

// export function linReg2(x, y){
//     if (!y) {
//         y = [].concat(x);
//         x = y.map((d, i) => i);
//     }

//     if (x.length !== x.length) {
//         throw new Error('The parameters values_x and values_y need to have same size!');
//     }

//     if (y.length === 0) {
//         return [[], []];
//     }

//     // Calculate the mean of x and y
//     const xMean = x.reduce((a, b) => a + b, 0) / x.length;
//     const yMean = y.reduce((a, b) => a + b, 0) / y.length;

//     // Calculate the variance and covariance of x and y
//     let covariance = 0;
//     let variance = 0;

//     // this is a formula
//     for (let i = 0; i < x.length; i++) {
//         covariance += (x[i] - xMean) * (y[i] - yMean);
//         variance += Math.pow(x[i] - xMean, 2);
//     }
//     covariance /= x.length;
//     variance /= x.length;

//     // Calculate the slope and intercept of the linear regression equation
//     const slope = covariance / variance;
//     const intercept = yMean - slope * xMean;

//     return {slope, intercept}
// }

/*
    Linear regression is a statistical method used to 
    model the relationship between two continuous variables. 
    It is used to predict the value of a dependent variable 
    based on the value of one or more independent variables.

    values_x - x-axis values, values_y - y-axis values

    there is a formula to get Linear regression
*/

export function linReg(values_x, values_y) {
    if (!values_y) {
        values_y = [].concat(values_x);
        values_x = values_y.map((d, i) => i);
    }

    var sum_x = 0;
    var sum_y = 0;
    var sum_xy = 0;
    var sum_xx = 0;
    var count = 0;

    var x = 0;
    var y = 0;
    var values_length = values_x.length;

    if (values_length != values_y.length) {
        throw new Error('The parameters values_x and values_y need to have same size!');
    }

    if (values_length === 0) {
        return [[], []];
    }

    var v = 0;
    while (v < values_length) {
        x = values_x[v];
        y = values_y[v];
        sum_x += x;
        sum_y += y;
        sum_xx += x * x;
        sum_xy += x * y;
        count++;
        v++;
    }

    var m = (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x);
    var b = sum_y / count - (m * sum_x) / count;

    var result_values_x = [];
    var result_values_y = [];

    v = 0;
    while (v < values_length) {
        x = values_x[v];
        y = x * m + b;
        result_values_x.push(x);
        result_values_y.push(y);
        v++;
    }

    let slope = 1 - result_values_y[0] / result_values_y[result_values_y.length - 1];

    let out = { slope, trend: { x: result_values_x, y: result_values_y } };

    return out;
}
