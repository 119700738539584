import Timeline from '../../../components/Timeline';
import Component from '../../../components/Component';
import Loading from '../../../components/Loading';

import { query_for_manual, query } from './query';
import keys from './keys';
import { isTrue } from '../../../helpers/isTrue';
import { getLabel } from '../../../helpers/date';

class GSCReportKeywordClustersDetailChart extends Component {
    constructor(props) {
        super();
        const isManualCluster = isTrue(props.manual);
        this.query = isManualCluster
            ? query_for_manual(props.cluster_id, props.deleted_keywords, props.user_domain_id)
            : query(props.cluster);
    }

    createLabels(item) {
        return [getLabel(item?.start_date?.value), getLabel(item?.end_date?.value)];
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }
        return (
            <Timeline
                chartSize="lg"
                metricType="primary"
                data={this.state.data}
                error={this.state.error}
                keys={keys}
                parent_id={this.props.parent_id}
                content_id={this.props.content_id}
                forceRun={this.forceRun}
                xAxisKey="start_date"
                createLabels={this.createLabels}
                xAxisTitle="Week Start"
                showGoogleFeeds
                hasRightAxis
                rightAxisLabel="CTR (%)"
                rightSecondAxisLabel="Positions"
                hasSecondRightAxis
                hasLeftSecondAxis
                leftSecondAxisLabel="Clicks"
                leftAxisLabel="Impressions"
                secondRightAxisReversed
            />
        );
    }
}

export default GSCReportKeywordClustersDetailChart;
