import Loading from '../../components/Loading';

import query from './query';
import Component from '../../components/Component';
import { Bar } from 'react-chartjs-2';

import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    LogarithmicScale,
    PointElement,
    Title,
    Tooltip,
    BarElement,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
);

class CollectedPagesChart extends Component {
    constructor() {
        super();
        this.query = query;
    }

    render() {
        if (this.state.loading) return <Loading />;

        if (!this.state.data || !this.state.data[0]) {
            return null;
        }

        // get in descending order and change to ascending
        const reversedData = [...this.state.data].reverse();

        const labels = reversedData.map((x) => x.date.value);
        const pageData = reversedData.map((x) => x.pages);

        const data = {
            labels,
            datasets: [
                {
                    label: '',
                    data: pageData,
                    backgroundColor: '#34BCAE',
                    borderColor: '#34BCAE',
                    borderWidth: 1,
                },
            ],
        };

        const options = {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
            },
            devicePixelRatio: 3,
            scales: {
                x: {
                    ticks: {
                        display: false,
                    },
                    grid: {
                        drawBorder: false,
                        display: false,
                    },
                },
                y: {
                    type: 'logarithmic',
                    ticks: {
                        display: false,
                        beginAtZero: true,
                    },
                    grid: {
                        drawBorder: false,
                        display: false,
                    },
                },
            },
        };

        return (
            <div className="collected-pages-chart-container">
                <div className="collected-pages-chart">
                    <Bar data={data} height={25} options={options} />
                </div>
                <p className="chart-text">
                    Collected pages over the <b>last 30</b> days
                </p>
            </div>
        );
    }
}

export default CollectedPagesChart;
