import React, { Component } from 'react';
import Loading from '../Loading';

class Announcement extends Component {
    constructor(props) {
        super();
        this.buttonName = props.buttonName ?? 'Got It';
        this.onClose = this.onClose.bind(this);
        this.state = {
            isOpen: true,
        };
    }

    onClose() {
        this.setState({ isOpen: false });
    }

    render() {
        const buttonName = this.props.buttonName ?? 'Got It';
        const onClick = this.props.onClick ?? this.onClose;
        return (
            this.state.isOpen && (
                <div>
                    <div
                        className={
                            'elementor-alert elementor-alert-info ' +
                            this.props.announcement_bar_styles
                        }
                        role="alert"
                    >
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="elementor-content d-flex align-items-center mr-3">
                                <div href="/" className="logo">
                                    <img
                                        className="logo-symbol"
                                        alt="logo"
                                        src={this.props.image}
                                        loading="lazy"
                                    />
                                </div>
                                <div>
                                    <h2 className="elementor-alert-title">Announcement</h2>
                                    <span className="elementor-alert-description">
                                        {this.props.message}
                                    </span>
                                </div>
                            </div>
                            {this.props.isLink ? (
                                <a
                                    className={
                                        'bg-transparent button-close ' + this.props.buttonStyles
                                    }
                                    href={this.props.href}
                                >
                                    {buttonName}
                                </a>
                            ) : (
                                <div>
                                    <button
                                        className={
                                            'bg-transparent button-close ' + this.props.buttonStyles
                                        }
                                        onClick={onClick}
                                        disabled={this.props.loading}
                                    >
                                        {buttonName}
                                    </button>
                                    <div className="d-flex justify-content-center pt-2">
                                        {this.props.loading && <Loading onlyPreloader />}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )
        );
    }
}
export default Announcement;
