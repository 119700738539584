import { Component } from 'react';

class AutocompleteInput extends Component {
    constructor() {
        super();

        this.openAutocomplete = this.openAutocomplete.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onSelectAutocomplete = this.onSelectAutocomplete.bind(this);

        this.state = {
            isOpenAutocomplete: false,
            domains: [],
        };
    }

    openAutocomplete(bool) {
        this.setState({
            isOpenAutocomplete: bool,
        });
    }

    onChangeInput(e) {
        const value = e.target.value;
        if (this.props.domains && value.length >= 3) {
            this.setState({
                domains: !value
                    ? this.props.domains
                    : this.props.domains.filter((item) =>
                          item.domain.toLowerCase().includes(value),
                      ),
            });
        } else {
            this.setState({
                domains: [],
            });
        }
        this.props.onChange(value, this.props.i);
    }

    onSelectAutocomplete(domain) {
        this.props.onChange(domain, this.props.i);
    }

    render() {
        return (
            <div className="autocomplete-container">
                {!this.props.dontShowLabel && (
                    <label htmlFor={`competitors ${this.props.i}`}>
                        <strong>Competitor {this.props.i + 1}</strong>
                    </label>
                )}
                <div className="d-flex justify-content-between align-items-center">
                    <input
                        name={`competitors ${this.props.i}`}
                        className={`form-commerce-input ${this.props.showPlus ? 'w-80' : 'w-100'}`}
                        type="text"
                        onFocus={() => this.openAutocomplete(true)}
                        onBlur={() => setTimeout(() => this.openAutocomplete(false), 200)}
                        id={`competitors ${this.props.i}`}
                        onChange={this.onChangeInput}
                        value={this.props.item}
                    />
                    {!this.props.dontShowMinus && (
                        <button
                            className="delete-btn"
                            onClick={() => this.props.deleteCompetitor(this.props.i)}
                            disabled={this.props.disableMinus}
                        >
                            <i className="icon-circle-minus" />
                        </button>
                    )}
                    {this.props.showPlus && (
                        <button className="add-btn" onClick={this.props.addCompetitor}>
                            <i className="icon-add-circle" />
                        </button>
                    )}
                </div>
                {this.state.isOpenAutocomplete && this.state?.domains?.length > 0 && (
                    <div className="autocomplete-box">
                        {this.state.domains.map((item) => (
                            <div
                                key={item.domain}
                                onClick={() => this.onSelectAutocomplete(item.domain)}
                            >
                                {item.domain}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default AutocompleteInput;
