import { Cell } from '@table-library/react-table-library/table';

export const COLUMNS = [
    {
        label: 'Lifespan',
        key: 'lifespan',
        data: 'lifespan',
        Component: Cell,
    },
    {
        label: 'Pages',
        key: 'pages',
        data: 'pages',
        Component: Cell,
    },
    {
        label: 'Percentage(%)',
        key: 'percent',
        data: 'percent',
        Component: Cell,
        renderCell: ({ item, allData }) => `${getPercent(allData, item.pages).toFixed(2)}%`,
    },
];

export function getPercent(allData, newData) {
    const sumAllData = allData.reduce((acc, curr) => acc + curr.pages, 0);
    const percent = (100 * newData) / sumAllData;
    return percent;
}

export function getPercentForChart(allData, oneData) {
    const sumAllData = allData.reduce((acc, curr) => acc + curr, 0);
    const percent = (100 * oneData) / sumAllData;
    return percent;
}
