import Loading from '../../components/Loading';
import Component from '../../components/Component';

import { query_get_average } from './query';
import LowCTR from './content';
import numeral from 'numeral';

class LowCTRContainer extends Component {
    constructor() {
        super();
        this.query = query_get_average;
    }

    showMore() {
        this.setState({
            isShowMore: true,
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        return (
            <div className="card table-main rounded-main overflow-hidden">
                <div className="card-header">
                    <p className="card-title">Underperforming CTR</p>
                    <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                        Your Average CTR is{' '}
                        <strong>{numeral(this.state.data[0]?.avgctr).format('0.0%')}</strong>, here
                        are articles with high impressions and CTR lower than average.
                    </small>
                    <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                        <i className="icon-lightbulb-solid lightbulb-styles"></i>
                        Try rewriting a more engaging clicky headlines without removing main SEO
                        keywords from the title
                    </small>
                </div>
                <div className="card-body">
                    <LowCTR
                        domain={this.props.domain}
                        content_id={this.props.content_id}
                        parent_id={this.props.parent_id}
                        avgctr={this.state.data[0]?.avgctr ?? 0}
                        default_image={this.props.default_image}
                    />
                </div>
            </div>
        );
    }
}

export default LowCTRContainer;
