import Component from '../../components/Component';
import Timeline from '../../components/Timeline';
import Loading from '../../components/Loading';

import query from './query';
import keys from './keys';
import moment from 'moment';
import { getDaysArray } from '../../helpers/date';

class AppearancesChart extends Component {
    constructor() {
        super();
        this.query = query;
        this.seperateData = this.seperateData.bind(this);
    }

    getLabel(data) {
        return moment(data).format('ll').split(',')[0];
    }

    seperateData(data) {
        const allDates = [
            ...new Set([
                ...data.map((item) => item.date.value).sort((a, b) => new Date(a) - new Date(b)),
            ]),
        ];
        const filledDates = getDaysArray(allDates[0], allDates[allDates.length - 1]);

        const collectedData = data.reduce((acc, curr) => {
            if (!acc[curr.appearance]) {
                acc[curr.appearance] = [curr];
            } else {
                acc[curr.appearance] = [...acc[curr.appearance], curr];
            }

            return acc;
        }, {});

        const sortedData = {};

        for (let key in collectedData) {
            sortedData[key] = filledDates.map(
                (date) => collectedData[key].find((item) => item.date.value === date) ?? {},
            );
        }
        return { allDates: filledDates, sortedData };
    }

    getDatasets(collectedData) {
        let datasets = [];

        for (let key in collectedData) {
            const datasetsForOneItem = keys.map((x) => ({
                label: `${key} ${x?.label}`,
                type: 'line',
                data: collectedData[key].map((data) =>
                    x.render ? x.render(data[x.key]) : data?.[x.key],
                ),
                backgroundColor: [x.labelColor, x.labelColor],
                borderColor: x.labelColor,
                hidden: x.key !== 'sumClicks',
                yAxisID: x?.label.toLowerCase().includes('ctr') ? 'y1' : 'y',
                borderRadius: 4,
                tension: 0.4,
            }));
            datasets = [...datasets, ...datasetsForOneItem];
        }

        return datasets;
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        const { allDates, sortedData } = this.seperateData(this.state.data);

        const chartData = {
            labels: allDates.map((label) => this.getLabel(label)),
            datasets: this.getDatasets(sortedData),
        };

        return (
            <Timeline
                chartSize="lg"
                dontShowMetrics
                data={this.state.data}
                chartData={chartData}
                error={this.state.error}
                keys={keys}
                forceRun={this.forceRun}
                parent_id={this.props.parent_id}
                content_id={this.props.content_id}
                hasRightAxis
                rightAxisLabel="CTR (%)"
            />
        );
    }
}

export default AppearancesChart;
