import numeral from 'numeral';
import colors from '../../lib/colors';

export default [
    {
        key: 'name',
        label: 'Category',
        color: colors[0],
        bubble: true,
        value: 'impressions',
    },
    {
        key: 'impressions',
        label: 'Daily Impressions',
        format: '0,00',
        color: colors[0],
    },
    {
        key: 'ctr',
        label: 'CTR',
        render: (data) => numeral(data * 100).format('0.00'),
        format: '0.00%',
        color: colors[1],
    },
];
