import Query from '../../../../components/Report';

export const query = ({ offset, evergreen_weeks = 5, ignored_urls }) =>
    new Query('search-evergreen-urls', {
        offset,
        ignored_urls: collectIgnoredUrls(ignored_urls),
        toggle: 'where pweeks > 2',
        exclude_directories: '',
        include_directories: '',
        evergreen_weeks,
    });

const collectIgnoredUrls = (ignored_urls) => {
    if (ignored_urls?.length === 0) return '';
    const urls = ignored_urls.map((url) => `and url !='${url}' `).join('');
    return urls;
};

export const setIgnoreUrl = async (data, csrf_token) => {
    return fetch('/domains/ignored-keyword-url/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
