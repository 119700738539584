import React, { Component } from 'react';
import ChangePlanComponent from '../../components/ChangePlan';
import ReactModal from '../../components/Modal';
import { reactivateQuery } from './query';

class ReactivateModal extends Component {
    constructor() {
        super();

        this.reactivate = this.reactivate.bind(this);
    }

    reactivate({ price_id, promotion_code, setError }) {
        reactivateQuery({
            endpoint_url: this.props.endpoint_url,
            license_id: this.props.license_id,
            price_id,
            promotion_code,
            setError,
        });
    }

    render() {
        return (
            <ReactModal modalState={this.props.isOpen} className="width-md">
                <ChangePlanComponent
                    onSubmit={this.reactivate}
                    showBtns={true}
                    endpoint_url={this.props.endpoint_url}
                    pl={this.props.pl}
                    plan_intervals={this.props.plan_intervals}
                    plan_names={this.props.plan_names}
                    onCancel={this.props.onClose}
                    submitName="Reactivate"
                />
            </ReactModal>
        );
    }
}

export default ReactivateModal;
