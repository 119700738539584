import Loading from '../../components/Loading';
import LoadMoreComponent from '../../components/LoadMoreComponent';

import { COLUMNS } from './keys';
import query from './query';
import CheckData from '../../components/CatchError';
import DataTable from '../../components/Datatable';
import { scrollForTable } from '../../components/Datatable/utils';

class Summary extends LoadMoreComponent {
    constructor(props) {
        super();
        this.end_date = window.filters.get().dates.values.end_date.value;
        this.start_date = window.filters.get().dates.values.start_date.value;
        this.query = query({
            offset: 0,
            domain: props.domain,
            end_date: this.end_date,
            start_date: this.start_date,
            author: props.author,
        });

        this.offsetStep = 50;
    }

    searchFunction(data, search) {
        return data.filter((item) => item.name?.toLowerCase().includes(search.toLowerCase()));
    }

    render() {
        const attr = {
            query,
            offsetStep: this.offsetStep,
            domain: this.props.domain,
            author: this.props.author,
            start_date: this.start_date,
            end_date: this.end_date,
        };

        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const sortFns = {
            name: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
            type: (array) => array.sort((a, b) => a.type.localeCompare(b.type)),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            ctr: (array) =>
                array.sort((a, b) => a.clicks / a.impressions - b.clicks / b.impressions),
        };

        return (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.data}
                    loading={this.state.loading}
                    loadMore={this.state.loadMore}
                >
                    <DataTable
                        nodes={this.state.allData}
                        sortFns={sortFns}
                        COLUMNS={COLUMNS}
                        fileName="Top Pages"
                        forceRun={this.forceRun}
                        searchFunction={this.searchFunction}
                        additionalStyles={{
                            Table: `
                            --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                            ${scrollForTable}
                        `,
                            BaseCell: `  font-family: 'Raleway';
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                    padding: 16px 5px !important;
                                    color: #0D182C;
                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                
                                    &.underline {
                                        text-decoration: underline;
                                    }
                                    `,
                        }}
                    />
                </CheckData>
                {this.state.loadMore ? (
                    <Loading padding={10} />
                ) : (
                    showLoadMoreBtn && (
                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                            <button
                                onClick={() => this.loadMore(attr)}
                                disabled={this.state.loading}
                                className="load-more-btn"
                            >
                                Load More
                            </button>
                        </div>
                    )
                )}
            </>
        );
    }
}

export default Summary;
