import React, { Component } from 'react';

class CheckboxDropdown extends Component {
    constructor(props) {
        super();

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleApply = this.handleApply.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.showName = this.showName.bind(this);

        this.dropdownRef = React.createRef();
        console.log('props', props);
        this.state = {
            isOpen: false,
            selectedOptions: props.alreadySelected ?? [],
        };
    }

    handleClickOutside(event) {
        if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
            this.setState({
                isOpen: false,
            });
        }
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    toggleDropdown() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    handleCheckboxChange(option) {
        this.setState({
            selectedOptions: this.state.selectedOptions.includes(option)
                ? this.state.selectedOptions.filter((item) => item !== option)
                : [...this.state.selectedOptions, option],
        });
    }

    handleApply() {
        this.props.onApply(this.state.selectedOptions);
        this.toggleDropdown();
    }

    showName() {
        if (this.state.selectedOptions.length) {
            const names = this.props.options
                .filter((item) => this.state.selectedOptions.includes(item.value))
                .map((item) => item.label)
                .join(', ');
            return names;
        }
        return this.props.label;
    }

    render() {
        return (
            <div className="checkbox-dropdown" ref={this.dropdownRef}>
                <button onClick={this.toggleDropdown} className="dropdown-btn">
                    {this.showName()}
                    <div>
                        <i className="icon-arrow-down"></i>
                    </div>
                </button>
                {this.state.isOpen && (
                    <div className="dropdown-content">
                        {this.props.options.map((option, index) => (
                            <label key={index}>
                                <input
                                    type="checkbox"
                                    value={option.value}
                                    checked={this.state.selectedOptions.includes(option.value)}
                                    onChange={() => this.handleCheckboxChange(option.value)}
                                />
                                {option.label}
                            </label>
                        ))}
                        <button onClick={this.handleApply} className="apply-btn">
                            Apply
                        </button>
                    </div>
                )}
            </div>
        );
    }
}

export default CheckboxDropdown;
