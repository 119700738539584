import { loadStripe } from '@stripe/stripe-js';
import React from 'react';

class CardDetails extends React.Component {
    constructor() {
        super();

        this.state = {
            card: {
                complete: false,
            },
        };
    }

    async componentDidMount() {
        this.stripe = await loadStripe(window._config.stripe_public_key);
        this.elements = this.stripe.elements();
        this.card = this.elements.create('card');
        this.card.mount('#card');
        this.props.updateCard(this.card, this.stripe);

        this.card.on('change', (v) => {
            this.props.onEmptyCardError();
            this.setState({
                card: v,
            });
        });
    }

    render() {
        return (
            <>
                <div className="text-field mb-3">
                    <label htmlFor="first_name" className="form-label">
                        Billing Information
                    </label>
                    <div id="card" />
                    {this.props.cardError && (
                        <div className="error-message">{this.props.cardError}</div>
                    )}
                </div>
                <div className="text-field mb-3">
                    <label htmlFor="full_name" className="form-label">
                        Card Holder Name
                    </label>
                    <input
                        name="full_name"
                        id="full_name"
                        className="form-control"
                        placeholder="John Snow"
                        value={this.props.cardInfo.full_name}
                        onChange={(e) => this.props.updateCardInfo('full_name', e.target.value)}
                        onFocus={this.props.onFocus}
                    />
                    {this.props.errors['full_name'] && (
                        <div className="error-message">{this.props.errors['full_name']}</div>
                    )}
                </div>
                <div className="text-field mb-3">
                    <label htmlFor="full_name" className="form-label">
                        Make as default
                    </label>
                    <div>
                        <span
                            className={`switch-button ${
                                this.props.make_default ? 'active' : 'not-active'
                            }`}
                            onClick={this.props.onMakeAsDefault}
                        >
                            <span className="switch-circle" />
                        </span>
                    </div>
                </div>
            </>
        );
    }
}

export default CardDetails;
