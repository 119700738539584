import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';

export const COLUMNS = [
    {
        label: 'Domain',
        key: 'domain',
        data: 'domain',
        Component: Cell,
    },
    {
        label: 'Type',
        key: 'type',
        data: 'type',
        Component: Cell,
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data).format('0.0a');
        },
    },
    {
        label: '%',
        key: 'percent',
        data: 'percent',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data).format('0.00%');
        },
    },
];
