import Query from '../../../components/Report';

const query = ({ project_id, keyword, intent_filter }) =>
    new Query('commerce-detail-all-keyword', {
        project_id,
        keyword,
        intent_filter: setIntent(intent_filter),
    });

export default query;

function setIntent(intent_filter) {
    if (!intent_filter || intent_filter?.length === 0) return '';

    return `and (${intent_filter?.map((item) => `intent LIKE '%${item}%'`).join(' or ')})`;
}
