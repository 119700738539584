import numeral from 'numeral';
import CheckData from '../../../../../components/CatchError';
import DataTable from '../../../../../components/Datatable';
import LoadMoreComponent from '../../../../../components/LoadMoreComponent';
import Loading from '../../../../../components/Loading';
import { COLUMNS, getPercentForChart } from './keys';
import query, { getAllQuery } from './query';
import { v4 as uuid } from 'uuid';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { getRandomColor, labelColors } from '../../../../../lib/colors';
import { downloadAllCsv } from '../../../../../helpers/downloadAll';
import { getSortKeys, setAdditionalStyles } from '../../../../../helpers/table';
import CommerceFilterIntent from '../../../../../components/CommerceFilterIntent';
import { changeIntent } from '../../../../../helpers/commerce';
ChartJS.register(ArcElement, Tooltip, Legend);

class MarketShare extends LoadMoreComponent {
    constructor(props) {
        super();

        this.url = new URL(window.location);
        this.own_domain = this.url.searchParams.get('own_domain');
        this.keyword = this.url.searchParams.get('keyword');
        this.chartColors = null;
        this.intent = this.url.searchParams.get('intent');

        this.exportAll = this.exportAll.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.customForceRun = this.customForceRun.bind(this);
        this.createPiChartData = this.createPiChartData.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);

        const params = {
            project_id: props.project_id,
            offset: 0,
        };

        if (this.intent) {
            params.intent_filter = this.intent?.split(',');
        }

        if (!props.data) this.query = query(params);
        this.offsetStep = 1000;

        const breadcrumbBox = document.getElementById('breadcrumb-box');
        if (breadcrumbBox)
            breadcrumbBox.innerHTML = `<a class="text-primary domain-name" aria-current="page" href="/commerce/">Commerce</a><i class="icon-arrow-right"></i> <a href="#" >Project: ${this.keyword} </a> <i class="icon-arrow-right"></i> <a href="#" >Market Share</a>`;
    }

    searchFunction(data, search) {
        return data.filter((item) => item?.domain?.toLowerCase()?.includes(search.toLowerCase()));
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDatas({ key: 'marketShare', value: this.state.data });
        }

        const filters = localStorage.getItem('filters');
        const data = this.state.data?.map((x) => ({ id: uuid(), ...x }));

        if (prevState.filters !== filters && !this.state.loading) {
            return this.setState({ allData: data, filters });
        }
        if (
            JSON.stringify(this.state.data) !== JSON.stringify(prevState.data) &&
            !this.state.loading
        ) {
            this.setState({ allData: [...this.state.allData, ...data] });
        }
    }

    customForceRun() {
        this.forceRun();
        this.setState({
            allData: [],
            data: null,
        });
        this.props.updateDatas({ key: 'marketShare', empty: true });
    }

    createPiChartData(data) {
        const projectSVSum = data?.reduce((acc, curr) => acc + curr.project_sv, 0);
        const first20 = data?.sort((a, b) => b.project_sv - a.project_sv).slice(0, 20) ?? [];
        const first20Sum = first20?.reduce((acc, curr) => acc + curr.project_sv, 0);
        const others = { domain: 'Others', project_sv: projectSVSum - first20Sum ?? 0 };
        first20.push(others);

        const options = {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Organic traffic for the first 20 domains',
                    padding: {
                        top: 10,
                        bottom: 10,
                    },
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const percent = `${getPercentForChart(
                                context.dataset.data,
                                context.parsed,
                            ).toFixed(2)}%`;
                            return `${context.label} : ${numeral(context.parsed).format('0.0a')} ${
                                context.parsed > 1 ? 's' : ''
                            } (${percent})`;
                        },
                    },
                },
            },
        };

        if (!this.chartColors || first20?.length !== this.chartColors?.length) {
            this.chartColors = first20?.map((item, i) => labelColors[i] ?? getRandomColor());
        }

        const pieChartData = {
            labels: first20?.map((item) => item.domain),
            datasets: [
                {
                    label: 'Organic traffic for the first 20 domains',
                    data: first20?.map((item) => item.project_sv),
                    backgroundColor: this.chartColors,
                    borderRadius: 4,
                    tension: 0.4,
                },
            ],
        };

        return { options, pieChartData };
    }

    async handleSelectChange(values) {
        this.setState({
            select: values,
            loading: true,
            data: null,
            allData: [],
        });

        changeIntent(values);
        this.query = query({
            project_id: this.props.project_id,
            offset: 0,
            intent_filter: values?.map((item) => item.value),
        });
        this.query.bind(this.setState.bind(this));
        this.query.update();
        this.props.updateDatas({ emptyAll: true });
    }

    async exportAll() {
        try {
            this.setState({
                exportAllLoading: true,
            });
            const allQuery = getAllQuery({ project_id: this.props.project_id });
            const response = await allQuery.update();
            downloadAllCsv(response, 'Market Share', COLUMNS(this.props.project_id));
            this.setState({
                exportAllLoading: false,
            });
        } catch (err) {
            this.setState({
                exportAllLoading: false,
            });
            console.log(err);
        }
    }

    onSelect(domain) {
        this.setState({
            selectedDomains: this.state.selectedDomains?.includes(domain)
                ? this.state.selectedDomains?.filter((item) => item !== domain)
                : [...(this.state.selectedDomains ?? []), domain],
        });
    }

    render() {
        let data = this.state.allData?.length ? this.state.allData : this.props.data;
        const attr = {
            query,
            project_id: this.props.project_id,
            intent_filter: this.state.select?.map((item) => item.value) || null,
            offsetStep: data?.length
                ? parseInt(data?.length / this.offsetStep) * this.offsetStep
                : this.offsetStep,
        };

        const showLoadMoreBtn = this.state.data
            ? this.state.data?.length === this.offsetStep
            : data?.length % this.offsetStep === 0;

        data = data?.map((item, i) => ({ ...item, rank: i + 1 }));

        const { options, pieChartData } = this.createPiChartData(data);

        const extraBtns = () => (
            <>
                <a
                    style={
                        !this.state.selectedDomains?.length
                            ? { pointerEvents: 'none', opacity: 0.5 }
                            : {}
                    }
                    href={`/commerce/${this.props.project_id}/?keyword=${
                        this.props.keyword
                    }&own_domain=${
                        this.own_domain
                    }&view=content_gaps&comp_domains=${this.state.selectedDomains?.join(',')}`}
                    className="button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center mr-10"
                    target="_blank"
                    rel="noreferrer"
                >
                    Compare
                </a>
                <CommerceFilterIntent
                    select={this.state.select}
                    handleSelectChange={this.handleSelectChange}
                />
            </>
        );
        return (
            <div className="card rounded-main table-main">
                <div
                    className="card-header d-flex justify-content-between"
                    style={{ borderRadius: '10px' }}
                >
                    <div className="card-title text-dark fw-bold">
                        Market Share: [{this.props.keyword}]
                    </div>
                </div>
                <div className="card-body">
                    <CheckData
                        data={data}
                        loading={(!data || !data?.length) && this.state.loading}
                        loadMore={this.state.loadMore}
                    >
                        <div style={{ height: '400px', position: 'relative' }}>
                            <Pie data={pieChartData} options={options} />
                        </div>
                        <div>
                            <DataTable
                                nodes={data ?? []}
                                sortFns={getSortKeys(COLUMNS())}
                                COLUMNS={COLUMNS(
                                    this.props.project_id,
                                    this.own_domain,
                                    this.keyword,
                                    this.onSelect,
                                    this.state.selectedDomains,
                                )}
                                fileName=""
                                extraBtns={extraBtns}
                                defaultSortKey={'project_sv'}
                                forceRun={this.customForceRun}
                                searchFunction={this.searchFunction}
                                additionalStyles={setAdditionalStyles(COLUMNS())}
                            />
                            {this.state.loadMore ? (
                                <Loading padding={10} />
                            ) : (
                                <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                    {showLoadMoreBtn && (
                                        <button
                                            onClick={() => this.loadMore(attr)}
                                            className="load-more-btn mr-10"
                                        >
                                            Load More
                                        </button>
                                    )}
                                    {this.state.exportAllLoading ? (
                                        <Loading padding={10} />
                                    ) : (
                                        <button
                                            onClick={this.exportAll}
                                            disabled={this.state.loading}
                                            className="load-more-btn"
                                        >
                                            Export All
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    </CheckData>
                </div>
            </div>
        );
    }
}

export default MarketShare;
