export function dataWithouDomain(data) {
    const domain_url = window.filters.get().domain.value;
    const withoutDomain = data.split(domain_url)[1];
    if (withoutDomain) {
        const urlWithSlash = withoutDomain[0] !== '/' ? '/' + withoutDomain : withoutDomain;
        return urlWithSlash;
    }

    return data;
}
