import React from 'react';
import ReactModal from '../Modal';
import { getAccounts } from '../../modules/ChangeDomainLinkAddress/query';
import Loading from '../Loading';

class AccountsModal extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            accounts: {},
        };
    }

    async componentDidMount() {
        try {
            const accounts = await getAccounts(this.props.domain_id);
            const response = await accounts.json();
            this.setState({
                isLoading: false,
                accounts: response.data,
            });
        } catch (err) {
            console.log(err);
            this.setState({
                isLoading: false,
            });
        }
    }

    render() {
        const currentAccount = this.state.accounts.available_accounts?.find(
            (item) => item.account_id === this.state.accounts?.current_account?.account_id,
        );
        const accounts = this.state.accounts.available_accounts?.filter(
            (item) => item.account_id !== this.state.accounts?.current_account?.account_id,
        );
        if (accounts && currentAccount) accounts?.unshift(currentAccount);
        return (
            <>
                <ReactModal
                    modalState={this.props.isOpen}
                    className="width-sm"
                    onClose={this.props.onClose}
                >
                    <h2>Google Linked Accounts</h2>
                    <div>
                        {this.state.isLoading && <Loading padding={10} />}
                        {accounts?.map((item) => {
                            return (
                                <div key={item.account_id} className="linked-accounts">
                                    <div>{item?.client_email}</div>
                                    {item.account_id ===
                                    this.state.accounts.current_account.account_id ? (
                                        'Current Account'
                                    ) : (
                                        <a
                                            href={`/domains/link?account=${
                                                item.account_id
                                            }&domain=${encodeURIComponent(this.props.domain_name)}`}
                                        >
                                            Switch
                                        </a>
                                    )}
                                </div>
                            );
                        })}
                        <div className="linked-accounts d-flex">
                            <div>
                                <a href="/customers/link">Or add new google account</a>
                            </div>
                        </div>
                    </div>
                    <div className="linked-accounts-modal-btns">
                        <button
                            className="button button-outline button-primary width-sm btn"
                            onClick={this.props.onClose}
                            style={{ lineHeight: 'unset' }}
                        >
                            Close
                        </button>
                    </div>
                </ReactModal>
            </>
        );
    }
}

export default AccountsModal;
