import Component from '../../components/Component';
import query from './query';
import Loading from '../../components/Loading';
import { KEYS_CLICKS, KEYS_CTR, KEYS_IMPRESSIONS } from './keys';
import Timeline from '../../components/Timeline';
import { getLabel } from '../../helpers/date';
import CheckData from '../../components/CatchError';

class CategoryAnalyze extends Component {
    constructor(props) {
        super();
        this.query = query(props.category);
    }

    createChartData(data, keys) {
        const chartData = {
            labels: data.map((item) => getLabel(item.date?.value)),
            datasets: keys.map((item) => ({
                label: item?.label,
                type: item.type ?? 'line',
                data: data?.map((data) =>
                    item.render ? item.render(data[item.key]) : data[item.key],
                ),
                backgroundColor: [item.labelColor, item.labelColor],
                borderColor: item.labelColor,
                yAxisID: item.yAxisID ?? 'y',
                hidden: item.hidden ?? false,
                borderRadius: 4,
                tension: 0.4,
            })),
        };

        return chartData;
    }

    render() {
        if (this.state.loading) {
            return <Loading padding={10} />;
        }

        const data = this.state.data ?? [];
        const higerValues = data.reduce(
            (acc, curr) => {
                const higerImpressions =
                    curr.D_impressions > curr.C_impressions
                        ? curr.D_impressions
                        : curr.C_impressions;
                const higerClicks = curr.D_clicks > curr.C_clicks ? curr.D_clicks : curr.C_clicks;

                if (higerImpressions > acc.impressions) acc.impressions = higerImpressions;
                if (higerClicks > acc.clicks) acc.clicks = higerClicks;

                return acc;
            },
            {
                impressions: 0,
                clicks: 0,
            },
        );

        const collectData = data.map((item) => {
            const imp_index_D = higerValues.impressions
                ? (item.D_impressions / higerValues.impressions) * 100
                : null;
            const imp_index_C = higerValues.impressions
                ? (item.C_impressions / higerValues.impressions) * 100
                : null;
            const cl_index_D = higerValues.clicks
                ? (item.D_clicks / higerValues.clicks) * 100
                : null;
            const cl_index_C = higerValues.clicks
                ? (item.C_clicks / higerValues.clicks) * 100
                : null;

            return { ...item, imp_index_D, imp_index_C, cl_index_D, cl_index_C };
        });

        const chartDataImpr = this.createChartData(collectData, KEYS_IMPRESSIONS);
        const chartDataClicks = this.createChartData(collectData, KEYS_CLICKS);
        const chartDataCTR = this.createChartData(collectData, KEYS_CTR);

        return (
            <CheckData
                content_id={this.props.content_id}
                parent_id={this.props.parent_id}
                data={this.state.data}
                loading={this.state.loading}
            >
                <div id="category-analyze-container">
                    <div
                        className="card table-main rounded-main overflow-hidden"
                        style={{ overflow: 'hidden' }}
                    >
                        <div className="card-header">
                            <p className="card-title">Impressions</p>
                        </div>
                        <div className="card-body">
                            <Timeline
                                metricType="primary"
                                data={data}
                                error={this.state.error}
                                keys={KEYS_IMPRESSIONS}
                                forceRun={this.forceRun}
                                chartData={chartDataImpr}
                                dontShowMetrics
                                showGoogleFeeds
                            />
                        </div>
                    </div>
                    <div
                        className="card table-main rounded-main overflow-hidden"
                        style={{ overflow: 'hidden' }}
                    >
                        <div className="card-header">
                            <p className="card-title">Clicks</p>
                        </div>
                        <div className="card-body">
                            <Timeline
                                metricType="primary"
                                data={data}
                                error={this.state.error}
                                keys={KEYS_CLICKS}
                                forceRun={this.forceRun}
                                chartData={chartDataClicks}
                                dontShowMetrics
                                showGoogleFeeds
                            />
                        </div>
                    </div>
                    <div
                        className="card table-main rounded-main overflow-hidden"
                        style={{ overflow: 'hidden' }}
                    >
                        <div className="card-header">
                            <p className="card-title">CTR</p>
                        </div>
                        <div className="card-body">
                            <Timeline
                                metricType="primary"
                                data={data}
                                error={this.state.error}
                                keys={KEYS_CTR}
                                forceRun={this.forceRun}
                                chartData={chartDataCTR}
                                dontShowMetrics
                                showGoogleFeeds
                            />
                        </div>
                    </div>
                </div>
            </CheckData>
        );
    }
}

export default CategoryAnalyze;
