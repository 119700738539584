import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import { collectCell, getPercent } from '../../../helpers/showDelta';

export const COLUMNS = [
    {
        label: 'Author',
        key: 'author',
        data: 'author',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{data}</strong>;
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            if (item.id === 'all_categories') return null;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'impressions_delta',
        data: 'impressions_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return getPercent(item.impressions, item.impressions_prior);
            return collectCell(item.impressions, item.impressions_prior);
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'clicks_delta',
        data: 'clicks_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return getPercent(item.clicks, item.clicks_prior);
            return collectCell(item.clicks, item.clicks_prior);
        },
    },
    {
        label: 'Lifespan',
        key: 'lifespan',
        data: 'lifespan',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'lifespan_delta',
        data: 'lifespan_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return getPercent(item.lifespan, item.lifespan_prior);
            return collectCell(item.lifespan, item.lifespan_prior);
        },
    },
    {
        label: 'CTR',
        key: 'ctr',
        data: 'ctr',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;

            const ctrValue = item.clicks / item.impressions;
            if (csvDownload) return ctrValue;

            return <strong>{numeral(ctrValue).format('0.00%')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'ctr_delta',
        data: 'ctr_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;

            const ctrValue = item.clicks / item.impressions;
            const ctrValuePrior = item.clicks_prior / item.impressions_prior;
            if (csvDownload) return getPercent(ctrValue, ctrValuePrior);
            return collectCell(ctrValue, ctrValuePrior);
        },
    },
];
