import React, { Component, Fragment } from 'react';
import EntitiesView from '../EntitiesView';
import { tags } from './keys';
import RecommendedTopicsContainer from '../RecommendedTopicsContainer';

class EntitiesViewContainer extends Component {
    constructor() {
        super();

        this.onClick = this.onClick.bind(this);
        this.addActive = this.addActive.bind(this);
        this.showElement = this.showElement.bind(this);
        this.urlParams = new URLSearchParams(window.location.search);
        this.view = this.urlParams.get('view') ?? 'entities';

        this.state = {
            types: [this.view],
            alreadeOpenedOpportunities: this.view === 'more_opportunities' ? true : false,
        };
    }

    showElement(id) {
        return !this.state.types.length || this.state.types.includes(id);
    }

    onClick(id) {
        this.url = new URL(window.location);
        this.url.searchParams.set('view', id);
        history.pushState({}, '', this.url);

        if (id === 'more_opportunities') {
            return this.setState({
                types: [id],
                alreadeOpenedOpportunities: true,
            });
        }
        return this.setState({
            types: [id],
        });
    }

    addActive(id) {
        return this.state.types.includes(id);
    }

    render() {
        return (
            <>
                <div className="tags-container">
                    {tags.map((tag) => (
                        <span
                            className={`tab-item ${this.addActive(tag.id) && 'active-tag'}`}
                            key={tag.id}
                            onClick={() => this.onClick(tag.id)}
                        >
                            {tag.label}
                        </span>
                    ))}
                </div>
                <div style={{ display: this.showElement('entities') ? 'block' : 'none' }}>
                    <EntitiesView
                        parent_id="entities-page"
                        content_id="entities-content"
                        image_strong={this.props.image_strong}
                        image_striking_distance={this.props.image_striking_distance}
                        image_weak={this.props.image_weak}
                    />
                </div>
                {this.state.alreadeOpenedOpportunities && (
                    <div
                        style={{
                            display: this.showElement('more_opportunities') ? 'block' : 'none',
                        }}
                    >
                        <RecommendedTopicsContainer
                            domain={this.props.domain}
                            parent_id="recommendations-content"
                        />
                    </div>
                )}
            </>
        );
    }
}

export default EntitiesViewContainer;
