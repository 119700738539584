export const getBalance = (csrf_token) => {
    return fetch('/commerce/semrush/balance', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
    });
};

export const createProject = (csrf_token, body) => {
    return fetch('/commerce/semrush/create-project', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(body),
    });
};
