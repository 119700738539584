import React from 'react';
import Loading from '../../components/Loading';
import { isTrue } from '../../helpers/isTrue';
import CardDetails from './CardDetails';
import { addPaymentMethodQuery } from './query';

class AddPayment extends React.Component {
    constructor(props) {
        super();

        this.onEmptyCardError = this.onEmptyCardError.bind(this);
        this.onMakeAsDefault = this.onMakeAsDefault.bind(this);
        this.updateCardInfo = this.updateCardInfo.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.updateCard = this.updateCard.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.addCard = this.addCard.bind(this);

        this.state = {
            cardInfo: {
                full_name: props.full_name,
                email: props.email,
            },
            card_token: '',
            errors: {},
            cardError: '',
            make_default: false,
            loading: false,
        };
    }

    async addCard() {
        return await this.state.stripe.createPaymentMethod({
            type: 'card',
            card: this.state.card,
            billing_details: {
                name: this.state.cardInfo.full_name,
                email: this.state.cardInfo.email,
            },
        });
    }

    updateCardInfo(name, value) {
        this.setState({
            cardInfo: {
                ...this.state.cardInfo,
                [name]: value,
            },
        });
    }

    updateCard(card, stripe) {
        if (stripe) return this.setState({ card, stripe });

        this.setState({ card });
    }

    checkErrors() {
        const cardInfo = { ...this.state.cardInfo };
        const errors = {};

        for (let key in cardInfo) {
            if (!cardInfo[key]) {
                errors[key] = 'This field is required';
            } else {
                delete errors[key];
            }
        }
        this.setState({ errors });
        return errors;
    }

    onFocus(e) {
        const errors = { ...this.state.errors };
        delete errors[e.target.name];

        this.setState({ errors });
    }

    onEmptyCardError() {
        this.setState({ cardError: '' });
    }

    onMakeAsDefault() {
        this.setState({ make_default: !this.state.make_default });
    }

    async onSubmit() {
        try {
            const errors = this.checkErrors();
            if (Object.keys(errors).length !== 0) return;

            this.setState({ loading: true });

            const card = await this.addCard();
            if (card.error) {
                this.setState({ loading: false });
                this.setState({ cardError: card.error.message });
                return;
            }
            const cardToken = card.paymentMethod.id;

            const params = {
                token: cardToken,
                make_default: this.state.make_default,
            };

            if (isTrue(this.props.active)) params.active_user = true;

            const response = await addPaymentMethodQuery(
                this.props.endpoint_url,
                params,
                this.props.csrf_token,
            );
            const data = await response.json();

            this.setState({ loading: false });

            if (data.status === 200) {
                const pathName = window.location.pathname.split('/');
                pathName.splice(2, 1, data.redirect_url);
                const newPath = pathName.join('/');
                window.location.pathname = newPath;
                return;
            }

            this.setState({ paymentError: data.message });
        } catch (err) {
            this.setState({ loading: false });
            console.log(err);
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <nav className="navbar navbar-expand-lg navbar-light pt-0 pb-0">
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav breadcrumb-custom">
                                    <li>
                                        <h2 className="breadcrumb-title">Add Payment</h2>
                                    </li>
                                    <li className="nav-item">
                                        <div className="nav-link text-primary d-flex align-items-center">
                                            <a
                                                className="text-primary"
                                                aria-current="page"
                                                href="/settings"
                                            >
                                                Settings
                                            </a>
                                            <i className="icon-arrow-right"></i>
                                            <a
                                                className="text-primary"
                                                aria-current="page"
                                                href="/customers/payment-methods"
                                            >
                                                Payment methods
                                            </a>
                                            <i className="icon-arrow-right"></i>
                                            <p className="location">Add payment</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="add-payment-method-wrapper">
                    <div className="card rounded-main table-main">
                        <div className="row">
                            <div className="card-header" style={{ borderRadius: '10px' }}>
                                <div className="card-title text-dark fw-bold">
                                    Fill card details
                                </div>
                            </div>
                            <div className="card-body">
                                <CardDetails
                                    updateCardInfo={this.updateCardInfo}
                                    cardInfo={this.state.cardInfo}
                                    updateCard={this.updateCard}
                                    errors={this.state.errors}
                                    cardError={this.state.cardError}
                                    onFocus={this.onFocus}
                                    onEmptyCardError={this.onEmptyCardError}
                                    active={this.props.active}
                                    make_default={this.state.make_default}
                                    onMakeAsDefault={this.onMakeAsDefault}
                                />
                                <div className="col">
                                    <div className="d-flex justify-content-md-end">
                                        <a
                                            href="/settings/"
                                            className="button button-outline button-primary width-sm mx-md-2 text-center"
                                        >
                                            Cancel
                                        </a>
                                        <button
                                            type="submit"
                                            className="button button-filled button-primary width-sm"
                                            onClick={this.onSubmit}
                                            disabled={this.state.loading}
                                        >
                                            Add
                                        </button>
                                    </div>
                                    {this.state.loading && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'end',
                                                padding: '8px',
                                            }}
                                        >
                                            <Loading onlyPreloader />
                                        </div>
                                    )}
                                    {this.state.paymentError && (
                                        <div className="error-message" style={{ textAlign: 'end' }}>
                                            {this.state.paymentError}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddPayment;
