import Timeline from '../../../components/Timeline';
import Component from '../../../components/Component';
import Loading from '../../../components/Loading';

import query from './query';
import keys from './keys';
import { getLabel } from '../../../helpers/date';

class GSCReportKeywordDetail extends Component {
    constructor(props) {
        super();
        this.query = query(props.keyword);
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        const sortedData = this.state.data.sort((a, b) => a.week.localeCompare(b.week));

        const chartData = {
            labels: sortedData.map((item) => [
                getLabel(item?.start_date?.value),
                getLabel(item?.end_date?.value),
            ]),
            datasets: keys.map((item) => ({
                label: item?.label,
                type: 'line',
                data: sortedData.map((data) =>
                    item.render ? item.render(data[item.key]) : data[item.key],
                ),

                backgroundColor: [item.labelColor, item.labelColor],
                borderColor: item.labelColor,
                yAxisID: item.yAxisID ?? 'y',
                borderRadius: 4,
                tension: 0.4,
            })),
        };

        return (
            <Timeline
                chartSize="lg"
                metricType="primary"
                forceRun={this.forceRun}
                data={this.state.data}
                error={this.state.error}
                keys={keys}
                parent_id={this.props.parent_id}
                content_id={this.props.content_id}
                chartData={chartData}
                xAxisKey="start_date"
                showGoogleFeeds
                hasRightAxis
                rightAxisLabel="CTR (%)"
                rightSecondAxisLabel="Positions"
                hasSecondRightAxis
                hasLeftSecondAxis
                leftSecondAxisLabel="Clicks"
                leftAxisLabel="Impressions"
                secondRightAxisReversed
            />
        );
    }
}

export default GSCReportKeywordDetail;
