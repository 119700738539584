import {
    AIOverviewIcon,
    AdBottomIcon,
    AdMiddleIcon,
    AdTopIcon,
    AddressPack,
    CardsIcon,
    CartIcon,
    ChatIcon1,
    CrownIcon4,
    EventIcon2,
    FAQItem,
    FeaturedVideoItem,
    FindResultsOnIcon,
    FindResultsOnIcon1,
    HotelIcon2,
    ImagePack,
    IndentedResultItem,
    KnowledgePanelItem,
    LinkIcon,
    NewsIcon,
    OrganicCarouselIcon,
    People,
    PeopleAlsoSearchIcon,
    PinIcon,
    PopularProductsIcon,
    QuestionIcon,
    QuestionsAndAnswers,
    RecipesItem,
    Refine,
    RelatedProductsIcon,
    RelatedSearches,
    ReviewsItem,
    TagIcon1,
    ThingsToKnow,
    TopStoriesIcon,
    TwitterCarouselIcon,
    VideoAltIcon,
    VideoCarouselItem,
} from './icons';

export const icons = {
    0: {
        name: 'Instant answer',
        icon: <QuestionIcon />,
    },
    1: {
        name: 'Knowledge panel',
        icon: <KnowledgePanelItem />,
    },
    2: {
        name: 'Carousel',
        icon: <CardsIcon />, // ?
    },
    3: {
        name: 'Local pack',
        icon: <PinIcon />,
    },
    4: {
        name: 'Top stories',
        icon: <TopStoriesIcon />,
    },
    5: {
        name: 'Image pack',
        icon: <ImagePack />,
    },
    6: {
        name: 'Sitelinks',
        icon: <LinkIcon />,
    },
    7: {
        name: 'Reviews',
        icon: <ReviewsItem />,
    },
    8: {
        name: 'Tweet',
        icon: '',
    },
    9: {
        name: 'Video',
        icon: <VideoCarouselItem />,
    },
    10: {
        name: 'Featured video',
        icon: <FeaturedVideoItem />,
    },
    11: {
        name: 'Featured Snippet',
        icon: <CrownIcon4 />,
    },
    12: {
        name: 'AMP',
        icon: '',
    },
    13: {
        name: 'Image',
        icon: <ImagePack />,
    },
    14: {
        name: 'Ads top',
        icon: <AdTopIcon />,
    },
    15: {
        name: 'Ads bottom',
        icon: <AdBottomIcon />,
    },
    16: {
        name: 'Shopping ads',
        icon: <CartIcon />,
    },
    17: {
        name: 'Hotels Pack',
        icon: <HotelIcon2 />,
    },
    18: {
        name: 'Jobs search',
        icon: '',
    },
    19: {
        name: 'Featured images',
        icon: '',
    },
    20: {
        name: 'Video Carousel',
        icon: <VideoAltIcon />,
    },
    21: {
        name: 'People also ask',
        icon: <People />,
    },
    22: {
        name: 'FAQ',
        icon: <FAQItem />,
    },
    23: {
        name: 'Flights',
        icon: '',
    },
    24: {
        name: 'Find results on',
        icon: <FindResultsOnIcon1 />,
    },
    25: {
        name: 'Recipes',
        icon: <RecipesItem />,
    },
    26: {
        name: 'Related Topics',
        icon: <RelatedSearches />,
    },
    27: {
        name: 'Twitter carousel',
        icon: <TwitterCarouselIcon />,
    },
    28: {
        name: 'Indented',
        icon: <IndentedResultItem />,
    },
    29: {
        name: 'News',
        icon: <NewsIcon />,
    },
    30: {
        name: 'Address Pack',
        icon: <AddressPack />,
    },
    31: {
        name: 'Application',
        icon: '',
    },
    32: {
        name: 'Events',
        icon: <EventIcon2 />,
    },
    34: {
        name: 'Popular products',
        icon: <PopularProductsIcon />,
    },
    35: {
        name: 'Related products',
        icon: <RelatedProductsIcon />,
    },
    36: {
        name: 'Related searches',
        icon: <RelatedSearches />,
    },
    37: {
        name: 'See results about',
        icon: <FindResultsOnIcon />,
    },
    38: {
        name: 'Short videos',
        icon: <VideoAltIcon />,
    },
    39: {
        name: 'Web stories',
        icon: '',
    },
    40: {
        name: 'Application list',
        icon: '',
    },
    41: {
        name: 'Buying guide',
        icon: '',
    },
    42: {
        name: 'Organic carousel',
        icon: <OrganicCarouselIcon />,
    },
    43: {
        name: 'Things to know',
        icon: <ThingsToKnow />,
    },
    44: {
        name: 'Datasets',
        icon: '',
    },
    45: {
        name: 'Discussions and forums',
        icon: <ChatIcon1 />,
    },
    46: {
        name: 'Explore brands',
        icon: <TagIcon1 />,
    },
    47: {
        name: 'Questions and answers',
        icon: <QuestionsAndAnswers />,
    },
    48: {
        name: 'Popular stores',
        icon: <PopularProductsIcon />,
    },
    49: {
        name: 'Refine',
        icon: <Refine />,
    },
    50: {
        name: 'People also search',
        icon: <PeopleAlsoSearchIcon />,
    },
    51: {
        name: 'Ads middle',
        icon: <AdMiddleIcon />,
    },
    52: {
        name: 'AI overview',
        icon: <AIOverviewIcon />,
    },
};
