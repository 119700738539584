import Loading from '../../../components/Loading';

import { query_view_1, query_view_2 } from './query';
import { COLUMNS_VIEW_1, COLUMNS_VIEW_2 } from './keys';
import DataTable from '../../../components/Datatable';
import CheckData from '../../../components/CatchError';
import LoadMoreComponent from '../../../components/LoadMoreComponent';
import ExtraButtons from './ExtraBtns';
import { getPercent } from '../../../helpers/showDelta';
import ManualClusterSelect from '../../../components/manualClusterModal';
import Search from '../../../components/Search';
import { scrollForTable } from '../../../components/Datatable/utils';

class Urls extends LoadMoreComponent {
    constructor(props) {
        super();
        this.view_query = query_view_1;
        this.query = this.view_query({
            offset: 0,
            status: 'all',
            last_week: props.last_week,
            prior_week: props.prior_week,
        });
        this.offsetStep = 1000;

        this.onSelect = this.onSelect.bind(this);
        this.onRemoveAllSelected = this.onRemoveAllSelected.bind(this);
        this.getData = this.getData.bind(this);
        this.onChangeView = this.onChangeView.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    searchFunction(data, search) {
        return data.filter((item) => item.url?.toLowerCase().includes(search.toLowerCase()));
    }

    async getData(value, search) {
        this.query = this.view_query({
            offset: 0,
            status: value,
            last_week: this.props.last_week,
            prior_week: this.props.prior_week,
            search: search ?? this.state.search,
        });
        this.query.bind(this.setState.bind(this));
        this.setState({ status: value, allData: [] });

        await this.query.update();
    }

    onRemoveAllSelected(bool) {
        this.setState({
            removeAllSelected: bool,
            selectedItems: [],
        });
    }

    onSelect(action, state) {
        if (action.type === 'REMOVE_ALL') return this.setState({ selectedItems: [] });
        this.setState({ selectedItems: state.ids, removeAllSelected: false });
    }

    onChangeView(view) {
        if (view === 1) {
            this.view_query = query_view_1;
        } else {
            this.view_query = query_view_2;
        }
        this.setState({ view });
        this.getData('all');
    }

    onSearch(value) {
        this.setState({
            search: value,
        });
        this.getData(this.state.status, value);
    }

    getTable() {
        switch (this.state.view) {
            case 2: {
                return {
                    columns: COLUMNS_VIEW_2,
                    tableStyles: `--data-table-library_grid-template-columns :minmax(60px, 0.5fr) minmax(200px, 3fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important; ${scrollForTable}`,
                    sortKey: 'sortFns_2',
                    defaultSortKey: 'clicks',
                    text: `Comparing 2 weeks data in the system (Week ${this.props.prior_week} to ${this.props.last_week})`,
                };
            }
            default: {
                return {
                    columns: COLUMNS_VIEW_1,
                    tableStyles: `--data-table-library_grid-template-columns :minmax(60px, 0.5fr) minmax(200px, 3fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(150px, 1.5fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important; ${scrollForTable}`,
                    sortKey: 'sortFns_1',
                    defaultSortKey: 'maxClicks',
                    text: null,
                };
            }
        }
    }

    render() {
        const attr = {
            query: this.view_query,
            offsetStep: this.offsetStep,
            status: this.state.status,
            last_week: this.props.last_week,
            prior_week: this.props.prior_week,
            search: this.state.search,
        };

        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const sortFns = {
            sortFns_2: {
                url: (array) => array.sort((a, b) => a.url.localeCompare(b.url)),
                keywords: (array) => array.sort((a, b) => a.keywords - b.keywords),
                keywords_delta: (array) =>
                    array.sort(
                        (a, b) =>
                            getPercent(a.keywords, a.keywordsPrior) -
                            getPercent(b.keywords, b.keywordsPrior),
                    ),
                clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
                clicks_delta: (array) =>
                    array.sort(
                        (a, b) =>
                            getPercent(a.clicks, a.clicksPrior) -
                            getPercent(b.clicks, b.clicksPrior),
                    ),
                impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
                impressions_delta: (array) =>
                    array.sort(
                        (a, b) =>
                            getPercent(a.impressions, a.impressionsPrior) -
                            getPercent(b.impressions, b.impressionsPrior),
                    ),
                position: (array) => array.sort((a, b) => a.position - b.position),
                position_delta: (array) =>
                    array.sort(
                        (a, b) =>
                            getPercent(a.position, a.positionPrior) -
                            getPercent(b.position, b.positionPrior),
                    ),
                ctr: (array) => array.sort((a, b) => a.ctr - b.ctr),
                ctr_delta: (array) =>
                    array.sort(
                        (a, b) => getPercent(a.ctr, a.ctrPrior) - getPercent(b.ctr, b.ctrPrior),
                    ),
            },
            sortFns_1: {
                url: (array) => array.sort((a, b) => a.url.localeCompare(b.url)),
                clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
                keywords: (array) => array.sort((a, b) => a.keywords - b.keywords),
                impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
                maxClicks: (array) => array.sort((a, b) => a.maxClicks - b.maxClicks),
                avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
                avgCTR: (array) => array.sort((a, b) => a.avgCTR - b.avgCTR),
            },
        };

        const extraBtns = () => (
            <ExtraButtons
                status={this.state.status}
                getData={this.getData}
                view={this.state.view}
                onChange={this.onChangeView}
            />
        );

        const customeSearch = (
            <Search
                onSubmit={this.onSearch}
                placeholder="Search for items"
                defaultValue={this.state.search}
            />
        );

        const { columns, tableStyles, sortKey, defaultSortKey, text } = this.getTable();

        return (
            <div className="card table-main rounded-main overflow-hidden">
                <div className="col-12">
                    <div className="card-header header-with-options">
                        <div>
                            <p className="card-title">URLs</p>
                            {text && (
                                <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                                    {text}
                                </small>
                            )}
                        </div>
                        <div className="actions-btns">
                            <div>
                                <ManualClusterSelect
                                    current_url={this.props.current_url}
                                    csrf_token={this.props.csrf_token}
                                    selectedItems={this.state.selectedItems?.map((id) => ({
                                        id,
                                        url: this.state.allData.find((item) => item.id === id).url,
                                    }))}
                                    cluster_type="url"
                                    header="Add To URLs List"
                                    onRemoveAllSelected={this.onRemoveAllSelected}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <CheckData
                            content_id={this.props.content_id}
                            parent_id={this.props.parent_id}
                            data={this.state.allData}
                            loading={this.state.loading}
                            loadMore={this.state.loadMore}
                            customCheck
                            customCheckCondition={
                                this.state.status !== 'all' || !!this.state.search
                            }
                        >
                            <DataTable
                                nodes={this.state.allData}
                                sortFns={sortFns[sortKey]}
                                COLUMNS={columns}
                                fileName="GSC Reports: Urls"
                                extraBtns={extraBtns}
                                defaultSortKey={defaultSortKey}
                                searchFunction={this.searchFunction}
                                removeAllSelected={this.state.removeAllSelected}
                                hasSelect={true}
                                forceRun={this.forceRun}
                                onSelect={this.onSelect}
                                customeSearch={customeSearch}
                                additionalStyles={{
                                    Table: tableStyles,
                                    BaseCell: ` font-family: 'Raleway';
                                                font-weight: 500;
                                                font-size: 16px;
                                                line-height: 19px;
                                                padding: 16px 5px !important;
                                                color: #0D182C;
                                                border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                            
                                                &.underline {
                                                    text-decoration: underline;
                                                };
                                                `,
                                }}
                            />
                        </CheckData>
                        {this.state.loadMore ? (
                            <Loading padding={10} />
                        ) : (
                            showLoadMoreBtn && (
                                <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                    <button
                                        onClick={() => this.loadMore(attr)}
                                        disabled={this.state.loading}
                                        className="load-more-btn"
                                    >
                                        Load More
                                    </button>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Urls;
