const CHOICES = [
    {
        label: '14 Days',
        key: 'dates',
        days: 14,
        id: '14_days',
        value: '14_days',
    },
    {
        label: '28 Days',
        key: 'dates',
        days: 28,
        id: '28_days',
        value: '28_days',
    },
    {
        label: '90 Days',
        key: 'dates',
        days: 90,
        id: '90_days',
        value: '90_days',
    },
    {
        label: 'Last Week',
        key: 'dates',
        id: 'last_week',
        value: 'last_week',
    },
    {
        label: 'Last Month',
        key: 'dates',
        id: 'last_month',
        value: 'last_month',
    },
];

export const WITH_TODAY_CHOICES = [
    {
        label: 'Today',
        key: 'dates',
        days: 1,
        id: 'current_day',
        value: 'current_day',
    },
    ...CHOICES,
];
export default CHOICES;
