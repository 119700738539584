import { Component } from 'react';
import { discoverJobsRerun, gscJobsRerun } from './query';
import Loading from '../../components/Loading';

class ActionButtons extends Component {
    constructor() {
        super();

        this.onRerunGsc = this.onRerunGsc.bind(this);
        this.onRerunDiscover = this.onRerunDiscover.bind(this);

        this.state = {
            isLoadingGsc: false,
            isLoadingDiscover: false,
        };
    }

    async onRerunGsc() {
        try {
            this.setState({
                isLoadingGsc: true,
            });
            await gscJobsRerun(this.props.current_url, this.props.csrf_token, {
                domain: this.props.domain,
            });
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({
                isLoadingGsc: false,
            });
        }
    }

    async onRerunDiscover() {
        try {
            this.setState({
                isLoadingDiscover: true,
            });
            await discoverJobsRerun(this.props.current_url, this.props.csrf_token, {
                domain: this.props.domain,
            });
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({
                isLoadingDiscover: false,
            });
        }
    }

    render() {
        return (
            <div className="rerun-btns">
                <button
                    className="rerun-btns-gsc"
                    onClick={this.onRerunGsc}
                    disabled={this.state.isLoadingGsc}
                >
                    <span style={{ marginRight: '5px' }}>Rerun Gsc</span>
                    {this.state.isLoadingGsc && <Loading onlyPreloader />}
                </button>
                <button
                    className="rerun-btns-discover"
                    onClick={this.onRerunDiscover}
                    disabled={this.state.isLoadingDiscover}
                >
                    <span style={{ marginRight: '5px' }}>Rerun Discover</span>
                    {this.state.isLoadingDiscover && <Loading onlyPreloader />}
                </button>
            </div>
        );
    }
}

export default ActionButtons;
