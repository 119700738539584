import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';

export const COLUMNS = [
    {
        label: 'Appearance',
        key: 'appearance',
        data: 'appearance',
        Component: Cell,
    },
    {
        label: 'Impressions',
        key: 'sumImpressions',
        data: 'sumImpressions',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            const percent = item.sumImpressionsPrior && getPercent(data, item.sumImpressionsPrior);
            let cr = percent >= 0 ? '+' : '-';
            return (
                <div>
                    <strong>{numeral(data).format('0.0a')}</strong>
                    <br />
                    {cr}
                    {numeral(Math.abs(percent)).format('0.0')}%
                </div>
            );
        },
    },
    {
        label: 'Clicks',
        key: 'sumClicks',
        data: 'sumClicks',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            const percent = item.sumClicksPrior && getPercent(data, item.sumClicksPrior);
            let cr = percent >= 0 ? '+' : '-';
            return (
                <div>
                    <strong>{numeral(data).format('0.0a')}</strong>
                    <br />
                    {cr}
                    {numeral(Math.abs(percent)).format('0.0')}%
                </div>
            );
        },
    },
    {
        label: 'CTR',
        key: 'avgCTR',
        data: 'avgCTR',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            const percent = item.avgCTRPrior && getPercent(data, item.avgCTRPrior);
            let cr = percent >= 0 ? '+' : '-';
            return (
                <div>
                    <strong>{numeral(data).format('0.00%')}</strong>
                    <br />
                    {cr}
                    {numeral(Math.abs(percent)).format('0.0')}%
                </div>
            );
        },
    },
];

function getPercent(newData, priorData) {
    if (!priorData) return 100;

    return (newData / priorData - 1) * 100;
}
