import React from 'react';
import ReactModal from '../../Modal';
import DataTable from '../../Datatable';
import { scrollForTable } from '../../Datatable/utils';
import { COLUMNS } from './keys';

class AuthorsModal extends React.Component {
    constructor() {
        super();
    }

    render() {
        const data = this.props.item.uniqueDates?.map((item, i) => ({
            date: item,
            headline: this.props.item.uniqueHeadlines[i],
            image: this.props.item.uniqueImages[i],
        }));

        return (
            <ReactModal modalState={this.props.isOpen} className="width-md">
                <div className="modal-image-container author-modal">
                    <DataTable
                        nodes={data ?? []}
                        sortFns={{}}
                        COLUMNS={COLUMNS()}
                        fileName="Authors"
                        defaultSortKey="clicks"
                        searchFunction={(data) => data}
                        onlyTable
                        additionalStyles={{
                            Table: `
                                --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(120px, 1fr) !important;
                                ${scrollForTable}
                            `,
                            BaseCell: `  font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                        }}
                    />
                </div>
                <div className="btn-wrapper">
                    <button
                        type="button"
                        className="button button-outline button-primary width-sm btn"
                        onClick={this.props.onClose}
                        style={{ lineHeight: 'unset' }}
                    >
                        Close
                    </button>
                </div>
            </ReactModal>
        );
    }
}

export default AuthorsModal;
