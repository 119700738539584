import React from 'react';

import { query } from './query';
import DataTable from '../../../../components/Datatable';
import { COLUMNS, keywordItem } from './keys';
import { getPercent } from '../../../../helpers/showDelta';
import { scrollForTable } from '../../../../components/Datatable/utils';
import LoadMoreComponent from '../../../../components/LoadMoreComponent';
import Loading from '../../../../components/Loading';
import { noDataMessage } from '../../../../helpers/noDataMessage';
import { v4 as uuid } from 'uuid';

class Evergreen extends LoadMoreComponent {
    constructor(props) {
        super();

        this.ignored_urls = JSON.parse(props.ignored_urls)?.map((x) => x.url);

        this.query = query({
            offset: 0,
            evergreen_weeks: props.evergreen_weeks,
            ignored_urls: this.ignored_urls,
        });
        this.offsetStep = 1000;

        this.deleteIgnoredItem = this.deleteIgnoredItem.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const filters = localStorage.getItem('filters');
        const data = this.state.data?.map((x) => ({ id: uuid(), ...x }));

        if (prevState.filters !== filters && !this.state.loading) {
            return this.setState({ allData: data, filters });
        }
        if (
            JSON.stringify(this.state.data) !== JSON.stringify(prevState.data) &&
            !this.state.loading
        ) {
            this.setState({ allData: [...this.state.allData, ...data] });
        }

        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDatas({ key: 'evergreen', value: this.state.data });
        }
    }

    searchFunction(data, search) {
        return data.filter((item) => item.url?.toLowerCase().includes(search.toLowerCase()));
    }

    deleteIgnoredItem(url) {
        const filteredData = this.state.allData.filter((x) => x.url !== url);
        this.setState({
            allData: filteredData,
        });
        this.props.updateDatas({ key: 'evergreen', value: filteredData });
    }

    render() {
        const sortFns = {
            clicksL: (array) =>
                array.sort(
                    (a, b) =>
                        getPercent(a.clicksL.at(-1), a.clicksL[0]) -
                        getPercent(b.clicksL.at(-1), b.clicksL[0]),
                ),
            url: (array) => array.sort((a, b) => a.url.localeCompare(b.url)),
            keyword: (array) => array.sort((a, b) => a.keyword.localeCompare(b.keyword)),
            seen: (array) =>
                array.sort((a, b) => new Date(a.firstSeen?.value) - new Date(b.firstSeen?.value)),
            kw_clicks: (array) => array.sort((a, b) => a.kw_clicks - b.kw_clicks),
            maxClicks: (array) => array.sort((a, b) => a.maxClicks - b.maxClicks),
            avgClicks: (array) => array.sort((a, b) => a.avgClicks - b.avgClicks),
            avgKeywords: (array) => array.sort((a, b) => a.avgKeywords - b.avgKeywords),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            avgCTR: (array) => array.sort((a, b) => a.avgCTR - b.avgCTR),
        };

        const selectedData =
            (this.state.allData?.length ? this.state.allData : this.props.data) ?? [];
        if ((!selectedData || !selectedData.length) && this.state.loading) return <Loading />;
        let data = selectedData
            .map((item) => {
                const val = getPercent(item?.clicksL?.at(-1), item.clicksL?.[0]);
                return val <= -25 ? item : null;
            })
            .filter((item) => !!item);
        const columns = COLUMNS({
            csrf_token: this.props.csrf_token,
            deleteIgnoredItem: this.deleteIgnoredItem,
        });
        const customColumnsForExport = [...columns.slice(0, -1), keywordItem];

        return !data || !data.length ? (
            <div>
                {this.props.message ? (
                    <>
                        {this.props.message}
                        <div className="redirect-button">
                            <a
                                className="bg-purple button-close"
                                href="/customers/change_plan/?without_basic=True"
                            >
                                Adjust Plan
                            </a>
                        </div>
                    </>
                ) : (
                    noDataMessage
                )}
            </div>
        ) : (
            <>
                <DataTable
                    nodes={data}
                    sortFns={sortFns}
                    COLUMNS={columns}
                    customColumnsForExport={customColumnsForExport}
                    fileName="Evergreen Reports"
                    searchFunction={this.searchFunction}
                    defaultSortKey={'maxClicks'}
                    forceRun={this.forceRun}
                    errorMessage={this.props.message}
                    additionalStyles={{
                        Table: `--data-table-library_grid-template-columns : minmax(189px, 3fr) minmax(142px, 1.5fr) minmax(131px, 1fr) minmax(130px, 1fr) minmax(127px, 1fr) minmax(155px, 1.5fr) minmax(116px, 1fr) minmax(102px, 1fr) minmax(131px, 1fr) minmax(80px, 1fr) minmax(80px, 1fr) !important; ${scrollForTable}`,
                        BaseCell: `  font-family: 'Raleway';
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 19px;
                                padding: 16px 5px !important;
                                color: #0D182C;
                                border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                            
                                &.underline {
                                    text-decoration: underline;
                                }
                                `,
                    }}
                />
                <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                    <a
                        className="view-all-btn"
                        href={`/domains/${window.info.id}/gsc-reports/evergreen/?optimized=true`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        View Full Report
                    </a>
                </div>
            </>
        );
    }
}

export default Evergreen;
