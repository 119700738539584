export const setDeviceQuery = async (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/add-domain-gsc-data/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const deleteIgnoreItems = async (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/ignored-keyword-url/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
