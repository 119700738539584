import React, { Component } from 'react';
import ReportExplainer from '../index';

class ReportExplainerContentGaps extends Component {
    constructor() {
        super();

        this.state = {
            showMore: false,
        };
    }

    render() {
        const text = (
            <div className="report-explainer-custom">
                <div>
                    The Opportunity Score is a custom metric we've developed to help identify the
                    most promising keyword clusters for your SEO and content strategy. Here's what
                    you need to know:
                </div>
                <div>
                    <ol>
                        <li>The score ranges from 0 to 100, with 50 being average.</li>
                        <li>Scores above 50 indicate better-than-average opportunities.</li>
                        <li>Scores below 50 suggest less favorable opportunities.</li>
                        <li>The higher the score, the more potential the keyword cluster has.</li>
                    </ol>
                </div>
                {this.state.showMore ? (
                    <>
                        <div>
                            <strong>What the score considers:</strong>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    Search volume: Higher volume is better, but with diminishing
                                    returns.
                                </li>
                                <li>
                                    Cost per click (CPC): Higher CPC suggests more valuable traffic.
                                </li>
                                <li>Competition: Lower competition is favored for quicker wins.</li>
                                <li>
                                    Keyword difficulty: Easier keywords score higher for short-term
                                    gains.
                                </li>
                                <li>
                                    Your current presence: Clusters where you already have content
                                    get a boost.
                                </li>
                                <li>
                                    Your current performance: Better rankings and traffic in a
                                    cluster increase its score.
                                </li>
                                <li>
                                    Number of keywords: More keywords in a cluster provide more
                                    targeting options.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <strong>How to use the scores:</strong>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    Focus on clusters with higher scores for your immediate content
                                    and SEO efforts.
                                </li>
                                <li>Clusters scoring 60 or above are particularly promising.</li>
                                <li>
                                    Don't completely ignore lower-scoring clusters, but prioritize
                                    them less.
                                </li>
                                <li>
                                    Always combine these scores with your own content strategy and
                                    expertise.
                                </li>
                            </ul>
                        </div>
                    </>
                ) : (
                    <div className="show-more-btn">
                        <button onClick={() => this.setState({ showMore: true })}>Show More</button>
                    </div>
                )}
            </div>
        );
        return (
            <ReportExplainer
                isNotText
                textcontent={text}
                descriptionHidden={false}
                title="Opportunity Score Explanation"
            />
        );
    }
}

export default ReportExplainerContentGaps;
