import { Component } from 'react';
import DataTable from '../../components/Datatable';
import { COLUMNS } from './keys';
import { v4 as uuid } from 'uuid';
import { scrollForTable } from '../../components/Datatable/utils';

class UserActivities extends Component {
    constructor() {
        super();

        this.searchFunction = this.searchFunction.bind(this);
        this.state = { data: [] };
    }

    componentDidMount() {
        const data = JSON.parse(this.props.user_activities);
        this.setState({
            data: data?.map((x) => ({ ...x, id: uuid(), domain: x.domain ?? '' })),
        });
    }

    searchFunction(data, search) {
        return data.filter(
            (item) =>
                (item.user && item.user.toLowerCase().includes(search.toLowerCase())) ||
                (item.domain && item.domain.toLowerCase().includes(search.toLowerCase())),
        );
    }

    render() {
        if (!JSON.parse(this.props.user_activities)?.[0]) return <div>There is no data.</div>;
        const sortFns = {
            user: (array) => array.sort((a, b) => a.user?.localeCompare(b.user)),
            domain: (array) => array.sort((a, b) => a.domain?.localeCompare(b.domain)),
            pages_count: (array) =>
                array.sort((a, b) => Number(a.pages_count) - Number(b.pages_count)),
            days_count: (array) =>
                array.sort((a, b) => Number(a.days_count) - Number(b.days_count)),
            unique_pages_count: (array) =>
                array.sort((a, b) => Number(a.unique_pages_count) - Number(b.unique_pages_count)),
            created: (array) => array.sort((a, b) => new Date(a.created) - new Date(b.created)),
        };

        return (
            <>
                <DataTable
                    nodes={this.state.data}
                    sortFns={sortFns}
                    searchFunction={this.searchFunction}
                    header="User Activities"
                    COLUMNS={COLUMNS}
                    fileName="User Activities"
                    additionalStyles={{
                        Table: `
                                --data-table-library_grid-template-columns :  minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                               ${scrollForTable}
                            `,
                        BaseCell: ` font-family: 'Raleway';
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                    padding: 16px 5px !important;
                                    color: #0D182C;
                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                
                                    &.underline {
                                        text-decoration: underline;
                                    }
                                    `,
                    }}
                />
            </>
        );
    }
}

export default UserActivities;
