import React from 'react';

async function delay(ms) {
    return new Promise((s) => {
        window.setTimeout(s, ms);
    });
}

class Message extends React.Component {
    constructor() {
        super();

        this.state = {
            display: true,
            opacity: 1,
        };

        this.fadeOut = this.fadeOut.bind(this);
        this.messageClassName = this.messageClassName.bind(this);
    }

    async fadeOut() {
        const state = { ...this.state };
        if (state.opacity < 0) {
            state.display = false;
            if (this.props.deleteMessage) return this.props.deleteMessage();
        }

        state.opacity -= 0.1;

        this.setState(state);

        if (state.opacity > -0.1) {
            await delay(50);
            this.fadeOut();
        }
    }

    messageClassName(type) {
        switch (type) {
            case 'error':
                return 'error-message';
            case 'info':
                return 'info-message';
            case 'success':
                return 'success-message';
            default:
                return 'info-message';
        }
    }

    render() {
        let styles = {
            opacity: this.state.opacity,
            display: this.state.display ? 'flex' : 'none',
            justifyContent: 'space-between',
        };

        let buttonStyle = {
            border: 'none',
            background: 'unset',
            color: 'white',
        };
        return (
            <>
                <div
                    className={`message ${this.messageClassName(this.props.message_tags)}`}
                    style={styles}
                >
                    <span id="message">{this.props.message}</span>
                    <button onClick={this.fadeOut} style={buttonStyle}>
                        X
                    </button>
                </div>
            </>
        );
    }
}

export default Message;
