import { Component } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import numeral from 'numeral';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

class Sparkline extends Component {
    constructor() {
        super();
    }
    render() {
        const options = {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        title: (yDatapoint) => {
                            if (this.props.dontShowLable) return null;
                            const date = moment(yDatapoint[0]?.label).format('ll').split(',')[0];

                            const title = this.props.titleType
                                ? this.props.titleType(date)
                                : `Week Start: ${date}`;

                            return title;
                        },
                        label: (yDatapoint) =>
                            `${this.props.keyName}: ${numeral(yDatapoint.raw).format('0.0a')}`,
                    },
                    displayColors: false,
                },
            },
            devicePixelRatio: 3,
            scales: {
                x: {
                    ticks: {
                        display: false,
                    },
                    grid: {
                        drawBorder: false,
                        display: false,
                    },
                },
                y: {
                    type: 'logarithmic',
                    ticks: {
                        display: false,
                        beginAtZero: true,
                    },
                    grid: {
                        drawBorder: false,
                        display: false,
                    },
                },
            },
        };
        const chartData = {
            labels: this.props.labels ?? this.props.dataL,
            datasets: [
                {
                    label: 'Sparkline',
                    data: this.props.dataL,
                    backgroundColor: '#33aecb',
                    borderColor: '#33aecb',
                    borderWidth: 2,
                    radius: 2,
                    borderRadius: 4,
                    tension: 0.4,
                },
            ],
        };

        return (
            <div style={{ height: '55px', position: 'relative', paddingRight: '20px' }}>
                <Line data={chartData} options={options} />
            </div>
        );
    }
}

export default Sparkline;
