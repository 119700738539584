import query from './query';
import Component from '../../../components/Component';
import Loading from '../../../components/Loading';
import PositionDistributionModule1 from './module1';
import PositionDistributionModule2 from './module2';

class GSCReportPositionDistribution extends Component {
    constructor() {
        super();

        this.query = query;
    }

    searchFunction(data, search) {
        return data.filter((item) => item.url?.toLowerCase().includes(search.toLowerCase()));
    }

    render() {
        return (
            <div id="position-distribution-page">
                <div className="row card-row d-flex" id="number-of-keywords">
                    <div className="col-12">
                        <div className="card rounded-main overflow-hidden">
                            <div className="card-header">Number of Keywords</div>
                            <div className="card-body">
                                {this.state.loading ? (
                                    <Loading />
                                ) : (
                                    <PositionDistributionModule1
                                        content_id="number-of-keywords"
                                        parent_id="position-distribution-page"
                                        data={this.state.data?.map((x) => ({
                                            ...x,
                                            date: [x?.start_date?.value, x?.end_date?.value],
                                        }))}
                                        error={this.state.error}
                                        forceRun={this.forceRun}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row card-row d-flex" id="number-of-keywords-traffic">
                    <div className="col-12">
                        <div className="card rounded-main overflow-hidden">
                            <div className="card-body">
                                {this.state.loading ? (
                                    <Loading />
                                ) : (
                                    <PositionDistributionModule2
                                        data={this.state.data}
                                        content_id="number-of-keywords-traffic"
                                        parent_id="position-distribution-page"
                                        loading={this.state.loading}
                                        forceRun={this.forceRun}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GSCReportPositionDistribution;
