import { Component } from 'react';

class LinkAccount extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div id="onboarding-general-settings">
                <div className="onboarding-subheader">
                    First, let's link your Google Search Console account to get started.{' '}
                    <a
                        href="/customers/link?gd_src=from_onboard"
                        className="button button-outline button-primary width-sm btn"
                        style={{
                            width: 'fit-content',
                        }}
                    >
                        {this.props.available_accounts.length
                            ? 'Add additional GSC Account'
                            : 'Add GSC Account'}
                    </a>
                </div>
                <div className="mt-10">
                    {this.props.available_accounts?.map((item, i) => (
                        <div key={i} className="d-flex  align-items-center mt-10">
                            <div className="d-flex align-items-center">
                                <div
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                        backgroundImage: `url(${item.account_picture})`,
                                        backgroundSize: 'cover',
                                        float: 'left',
                                        marginRight: '10px',
                                        borderRadius: '16px',
                                    }}
                                ></div>
                                <span className="linked-users-span">{item.account_email}</span>
                            </div>
                            <div>
                                <button
                                    className="button button-filled button-primary width-sm btn ml-10 mt-0"
                                    title="Add Domain"
                                    onClick={() => this.props.selectAccount(item)}
                                >
                                    Select Domains from this GSC Account
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default LinkAccount;
