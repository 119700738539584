import { Component } from 'react';
import AuthorSettings from '../../AuthorSettings';

class AuthorReports extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div id="onboarding-general-settings">
                <div className="onboarding-subheader">
                    We offer reports that highlight each author’s performance on Google Discover.
                    Some European countries restrict these reports. Would you like to:
                </div>
                <AuthorSettings csrf_token={this.props.csrf_token} fromOnboard />
                <div className="d-flex justify-content-end">
                    <button
                        className="button width-sm btn onboarding-btn-next d-flex justify-content-center onboarding-skip-btn"
                        style={{ lineHeight: 'unset' }}
                        onClick={() => this.props.handleBack()}
                    >
                        back
                    </button>
                    <button
                        className="button  button-filled button-primary width-sm btn onboarding-btn-next d-flex justify-content-center"
                        onClick={() => this.props.handleNext()}
                    >
                        Next
                    </button>
                </div>
            </div>
        );
    }
}

export default AuthorReports;
