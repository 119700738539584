import React from 'react';
import Message from '../../modules/Message';
import Loading from '../Loading';
import ReactModal from '../Modal';
import { addClusterQuery } from './query';

class CreateClusterModal extends React.Component {
    constructor() {
        super();

        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);

        this.state = {
            value: '',
            loading: false,
            message: '',
        };
    }

    handleChange(e) {
        this.setState({ value: e.target.value });
    }

    deleteMessage() {
        this.setState({ message: '' });
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        const data = {
            domain_url: window.filters.get().domain.value,
            cluster: this.state.value,
            cluster_type: this.props.cluster_type,
        };
        addClusterQuery(this.props.current_url, data, this.props.csrf_token)
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 200) {
                    this.props.getClusters();
                    this.props.onClose(e);
                    this.setState({
                        value: '',
                        message: '',
                    });
                    return;
                }

                if (data.message) {
                    this.setState({ message: data.message });
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <>
                <ReactModal modalState={this.props.isOpen} className="width-sm">
                    <h3>Add Cluster</h3>
                    <form onSubmit={this.onSubmit}>
                        <p>
                            <label>Cluster Name</label>
                            <input
                                className="form-control"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.value}
                            />
                        </p>
                        <div className="btn-wrapper">
                            <button
                                type="button"
                                className="button button-outline button-primary width-sm btn"
                                onClick={this.props.onClose}
                                style={{ lineHeight: 'unset' }}
                            >
                                Cancel
                            </button>
                            <button
                                className="button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center"
                                disabled={this.state.loading}
                            >
                                <span style={{ marginRight: '5px' }}>Create</span>
                                {this.props.loading && <Loading onlyPreloader />}
                            </button>
                        </div>
                    </form>
                </ReactModal>
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={'error'}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default CreateClusterModal;
