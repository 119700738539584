import React from 'react';
import debounce from '../../lib/debounce';
import Loading from '../../components/Loading';
import ChoicesDates, { WITH_TODAY_CHOICES } from './Choices/Dates';
import FilterOptions from '../../components/FilterOptions';
import moment from 'moment';
import getDataRange from '../../helpers/getDataRange';

class Filters extends React.Component {
    constructor(props) {
        super();

        this.state = window.filters.get();

        this.updateFilter = this.updateFilter.bind(this);
        window.gsc_report = props.gsc_report;
        window.filters.bind(this.setState.bind(this));
        this.updateFilter = debounce(this.updateFilter.bind(this), 500);

        const shownDays = document.querySelectorAll('.shown-date-under-title');
        shownDays?.forEach((item) => {
            const customDates = item.getAttribute('custom');
            if (customDates) {
                const range = getDataRange({ days: customDates });
                item.innerHTML = `From ${moment(range.start_date?.value).format(
                    'YYYY-MM-DD',
                )} to ${moment(range.end_date?.value).format('YYYY-MM-DD')}`;
            } else {
                const range = getDataRange(window?.filters?.filters?.dates);
                item.innerHTML = `From ${moment(range.start_date?.value).format(
                    'YYYY-MM-DD',
                )} to ${moment(range.end_date?.value).format('YYYY-MM-DD')}`;
            }
        });
    }

    updateFilter(field, choice) {
        window.filters.update(choice);
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }
        const isTool = window.location.pathname.split('/')[1] === 'tools';

        const choices = isTool ? WITH_TODAY_CHOICES : ChoicesDates;

        return (
            <>
                <FilterOptions
                    name="dates"
                    choices={choices}
                    default={this.state.dates}
                    onChange={this.updateFilter}
                    gsc_report={this.props.gsc_report}
                    show_all={this.props.show_all}
                    dont_show_date_filter={this.props.dont_show_date_filter}
                />
            </>
        );
    }
}

export default Filters;
