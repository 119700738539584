import Query from '../../../components/Report';

export const query = ({
    user_domain_id,
    deleted_clusters,
    deleted_gsc_keyword_dict_with_cluster,
}) =>
    new Query('search-keywords-manual-cluster', {
        user_domain_id,
        deleted_clusters: deleted_clusters
            ? deleted_clusters
                ? `${deleted_clusters
                      .split(',')
                      .map((item) => ' and cluster_id != ' + item)
                      .join(' ')}`
                : ''
            : '',
        deleted_keywords: deleted_gsc_keyword_dict_with_cluster?.length
            ? createDeletedKeywords(deleted_gsc_keyword_dict_with_cluster)
            : '',
    });

function createDeletedKeywords(deleted_gsc_keyword_dict_with_cluster) {
    if (
        !deleted_gsc_keyword_dict_with_cluster ||
        deleted_gsc_keyword_dict_with_cluster?.length === 0
    )
        return '';

    const queryString = deleted_gsc_keyword_dict_with_cluster
        .map(
            (item) =>
                `NOT ( cluster_id = ${item.id} and keyword_name IN (${item.keywords
                    .map((x) => '"' + x + '"')
                    .join(',')}) )`,
        )
        .join(' and ');

    return `and (${queryString})`;
}
