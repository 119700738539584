/* eslint-disable */
import { Component } from 'react';
import { cancelSubscription } from './query';
import Loading from '../../components/Loading';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import Message from '../Message';

const checkboxItems = [
    "I'm taking a break, but I'll be back",
    "The tool is great, but I don't need it anymore",
    "It's missing features I need. (Please specify below)",
    "I'm consolidating with another account",
    'I had too many technical/product issues. (Please specify below)',
    'I found another product I like more',
    "I would use it if it wasn't so hard/confusing to use. (Please specify below)",
    "It's above our budget",
    'Other (Please specify below)',
];

class UserCancelation extends Component {
    constructor() {
        super();

        this.onChangeTextarea = this.onChangeTextarea.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);
        this.isDisabledButton = this.isDisabledButton.bind(this);

        this.state = {
            feedback: [],
            textareaValue: '',
            isLoading: false,
            isOpenModal: false,
        };
    }

    onCheck(e) {
        const value = e.target.value;
        if (this.state.feedback.includes(value)) {
            this.setState({
                feedback: this.state.feedback.filter((item) => item !== value),
            });
        } else {
            this.setState({
                feedback: [...this.state.feedback, value],
            });
        }
    }

    onChangeTextarea(e) {
        this.setState({
            textareaValue: e.target.value,
        });
    }

    async onSubmit() {
        try {
            this.setState({
                isLoading: true,
                isOpenModal: false,
            });
            const data = {
                reason: this.state.feedback.join(','),
                comment: this.state.textareaValue,
            };
            const response = await cancelSubscription(
                data,
                this.props.csrf_token,
                this.props.license_id,
            );
            const resData = await response.json();
            if (response.status === 400) {
                this.setState({
                    isLoading: false,
                    message: resData?.message,
                    message_tag: 'error',
                });
            } else if (response.status === 200) {
                this.setState({
                    message: resData?.message,
                    message_tag: 'success',
                });
                window.location.href = '/settings';
            }
        } catch (err) {
            console.log('error', err);
            this.setState({
                isLoading: false,
                message: 'Something went erong',
                message_tag: 'error',
            });
        }
    }
    onCloseModal() {
        this.setState({
            isOpenModal: false,
        });
    }
    onOpenModal() {
        this.setState({
            isOpenModal: true,
        });
    }

    deleteMessage() {
        this.setState({
            message: '',
            message_tag: '',
        });
    }

    isDisabledButton() {
        if (this.state.isLoading) return true;
        if (this.state.feedback.includes(checkboxItems[8]) && !this.state.textareaValue)
            return true;
        if (!this.state.textareaValue && this.state.feedback?.length === 0) return true;
        return false;
    }

    render() {
        return (
            <>
                <div className="card table-main rounded-main overflow-hidden user-cancelation-container">
                    <div className="card-header">
                        <p className="card-title">Cancel Gddash</p>
                    </div>
                    <div className="card-body d-flex justify-content-center">
                        <div className="user-cancelation-container-body">
                            <h6>Mind telling us why you're cancelling? Check all that apply.</h6>
                            {checkboxItems.map((item, i) => (
                                <div className="user-cancelation-container-item" key={item}>
                                    <input
                                        type="checkbox"
                                        id={`item-${i}`}
                                        checked={this.state.feedback.includes(item)}
                                        onChange={this.onCheck}
                                        value={item}
                                    />
                                    <label htmlFor={`item-${i}`}>{item}</label>
                                </div>
                            ))}
                            <div className="user-cancelation-container-item additional-field">
                                <label>
                                    Additional Feedback (Optional):
                                    <div className="additional-field-description">
                                        We value your feedback. Is there anything else you'd like to
                                        share about your experience?
                                    </div>
                                </label>
                                <div>
                                    <textarea
                                        placeholder="Let us know you really feel"
                                        rows="4"
                                        cols="40"
                                        onChange={this.onChangeTextarea}
                                        value={this.state.textareaValue}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="user-cancelation-container-description">
                                You will continue to have access to your paid features until the end
                                of your current billing period on {this.props.end_date}.
                            </div>
                            <div className="user-cancelation-container-btn">
                                <a href="/settings">Cancel</a>
                                <button
                                    onClick={this.onOpenModal}
                                    disabled={this.isDisabledButton()}
                                >
                                    {!this.state.isLoading ? (
                                        'Cancel subscription'
                                    ) : (
                                        <div className="user-cancelation-container-loading">
                                            <Loading onlyPreloader />
                                        </div>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmationModal
                    isOpen={this.state.isOpenModal}
                    onClose={this.onCloseModal}
                    message={`You will continue to have access to your paid features until the end of your current billing period on ${this.props.end_date}.`}
                    buttonName="Cancel subscription"
                    onSubmit={this.onSubmit}
                />
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tag}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </>
        );
    }
}
export default UserCancelation;
