import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import colors, { labelColors } from '../../../lib/colors';

export const COLUMNS = (onOpenModal) => [
    {
        label: 'Entity',
        key: 'name',
        data: 'name',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong className="wrap-text">{data}</strong>;
        },
    },
    {
        label: 'Wiki',
        key: 'wiki',
        data: 'wiki',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong className="wrap-text">{data}</strong>;
        },
    },
    {
        label: 'Relevancy',
        key: 'relevancy',
        data: 'relevancy',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data).format('0.0a');
        },
    },
    {
        label: 'Pages Count',
        key: 'pagesCNT',
        data: 'pagesCNT',
        Component: Cell,
    },
    {
        label: 'Pages',
        key: 'pages',
        data: 'pages',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="wrap-text d-flex align-items-center">
                    <div style={{ float: 'left', marginRight: '10px' }}>{data?.[0]}</div>
                    <div
                        className="link-website cursor-pointer d-flex align-items-center justify-content-center"
                        onClick={() => onOpenModal(data)}
                    >
                        <i className="icon-enlarge" style={{ fontSize: '12px' }}></i>
                    </div>
                </div>
            );
        },
    },
];

export const CHART_KEYS = [
    {
        key: 'relevancy',
        label: 'Relevancy',
        color: colors[1],
        labelColor: labelColors[1],
        icon: 'ctr',
    },
];
