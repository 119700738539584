import { Cell } from '@table-library/react-table-library/table';
import Sparkline from '../../../components/Sparklines';
import numeral from 'numeral';
import Actions from '../../../components/ClusterActions';

export const COLUMNS = (
    csrf_token,
    endpoint_url,
    updateClusterName,
    deleteCluster,
    deleteItem,
    clusterNames,
) => [
    {
        label: 'Cluster Name',
        key: 'cluster_name',
        data: 'cluster_name',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            const cluster = clusterNames.find((state) => state.id === item.cluster_id);
            if (csvDownload) return cluster?.name ?? data;
            return (
                <strong>
                    <a
                        href={`/domains/${
                            window.info.id
                        }/gsc-reports/keyword-clusters/cluster?cluster=${encodeURIComponent(
                            data,
                        )}&manual=True&cluster_id=${item.cluster_id}`}
                        className="d-inline-flex align-items-center justify-content-center"
                        rel="noreferrer"
                    >
                        {cluster?.name ?? data}
                    </a>
                </strong>
            );
        },
    },
    {
        label: 'Cluster Keywords',
        key: 'cluster_keywords',
        data: 'cluster_keywords',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            const cluster = clusterNames.find((state) => state.id === item.cluster_id);
            return <strong>{cluster?.keyword_count ?? '-'}</strong>;
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Trend',
        key: 'clicks_sparkline',
        data: 'clicks_sparkline',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload || !item.clicksL) return '-';

            return (
                <Sparkline
                    dataL={item.clicksL}
                    keyName="Clicks"
                    labels={item.weeksL?.map((x) => x.value)}
                />
            );
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Trend',
        key: 'impressions_sparkline',
        data: 'impressions_sparkline',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload || !item.impressionsL) return '-';

            return (
                <Sparkline
                    dataL={item.impressionsL}
                    keyName="ImpressionsL"
                    labels={item.weeksL?.map((x) => x.value)}
                />
            );
        },
    },
    {
        label: 'Avg. Weekly Ranked KWs',
        key: 'weekly_keywords',
        data: 'weekly_keywords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'URLs',
        key: 'weekly_urls',
        data: 'weekly_urls',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Position',
        key: 'avgPosition',
        data: 'avgPosition',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Lifespan',
        key: 'lifespan',
        data: 'lifespan',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'CTR',
        key: 'avgctr',
        data: 'avgctr',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.clicks / item.impressions;
            if (csvDownload) return ctrValue;

            return <strong>{numeral(ctrValue).format('0.00%')}</strong>;
        },
    },
    {
        label: 'Actions',
        key: 'actions',
        data: 'actions',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return null;
            const cluster = clusterNames.find((state) => state.id === item.cluster_id);
            return (
                <Actions
                    clusterName={cluster?.name ?? item.cluster_name}
                    cluster_id={item.cluster_id}
                    header="Keywords"
                    clusterType="keyword"
                    id="keywords"
                    endpoint_url={endpoint_url}
                    csrf_token={csrf_token}
                    updateClusterName={updateClusterName}
                    deleteCluster={deleteCluster}
                    deleteItem={deleteItem}
                />
            );
        },
    },
];
