import { Component } from 'react';
import Keywords from './components/Keywords';
import { tags } from './config';
import MarketShare from './components/MarketShare';
import URLs from './components/URLs';
import ContentGaps from './components/ContentGaps';
import Opportunities from './components/Opportunities';

class CommerceViewDetail extends Component {
    constructor(props) {
        super();

        this.addActive = this.addActive.bind(this);
        this.onChangeTags = this.onChangeTags.bind(this);
        this.updateDatas = this.updateDatas.bind(this);
        this.updateDomains = this.updateDomains.bind(this);
        this.updateQualifieres = this.updateQualifieres.bind(this);

        this.commerce_project = JSON.parse(props.commerce_project);

        this.url = new URL(window.location);
        const view = this.url.searchParams.get('view');
        this.keyword = this.url.searchParams.get('keyword');
        this.own_domain = this.url.searchParams.get('own_domain');

        this.state = {
            own_domain: this.own_domain,
            tag: view ?? 'keywords',
            datas: {},
            commerce_project: this.commerce_project,
        };
    }

    searchFunction(data, search) {
        return data.filter((item) => item?.keyword?.toLowerCase()?.includes(search.toLowerCase()));
    }

    onChangeTags(tag_id) {
        this.url = new URL(window.location);
        this.url.searchParams.set('view', tag_id);
        history.pushState({}, '', this.url);

        this.setState({
            tag: tag_id,
        });
    }

    updateQualifieres(qualifiers) {
        this.setState({
            commerce_project: { ...this.state.commerce_project, qualifiers },
        });
    }

    addActive(id) {
        if (this.state.type.includes('keywords')) {
            if (id === 'keywords') return true;
            return false;
        }

        return this.state.type.includes(id);
    }

    updateDatas({ key, value, empty = false, emptyAll = false }) {
        if (emptyAll) {
            return this.setState({
                datas: {
                    allData: this.state.datas?.allData ?? null,
                },
            });
        }
        if (empty) {
            return this.setState({
                datas: {
                    ...this.state.datas,
                    [key]: null,
                },
            });
        }

        this.setState({
            datas: {
                ...this.state.datas,
                [key]: this.state.datas[key] ? [...this.state.datas[key], ...value] : value,
            },
        });
    }

    updateDomains({ own_domain, competitors }) {
        this.setState({
            own_domain,
            competitors,
        });
    }

    render() {
        return (
            <div>
                <div className="tags-container">
                    {tags.map((tag) => (
                        <span
                            className={`tab-item extra-styles ${
                                tag.id === this.state.tag && 'active-tag'
                            }`}
                            key={tag.id}
                            onClick={() => this.onChangeTags(tag.id)}
                        >
                            {tag.label}
                        </span>
                    ))}
                </div>
                {this.state.tag === 'keywords' && (
                    <Keywords
                        own_domain={this.own_domain}
                        keyword={this.keyword}
                        updateDatas={this.updateDatas}
                        project_id={this.props.project_id}
                        data={this.state.datas.allData}
                    />
                )}
                {this.state.tag === 'opportunities' && (
                    <>
                        <Opportunities
                            own_domain={this.own_domain}
                            keyword={this.keyword}
                            updateDatas={this.updateDatas}
                            project_id={this.props.project_id}
                            allData={this.state.datas.allData}
                            updateDomains={this.updateDomains}
                            contentGaps={this.state.datas.contentGaps}
                            domains_for_select={this.state.datas.domains_for_select}
                            competitors={this.state.competitors}
                            commerce_project={this.state.commerce_project}
                            updateQualifieres={this.updateQualifieres}
                            csrf_token={this.props.csrf_token}
                        />
                    </>
                )}
                {this.state.tag === 'market_share' && (
                    <MarketShare
                        updateDatas={this.updateDatas}
                        project_id={this.props.project_id}
                        keyword={this.keyword}
                        data={this.state.datas.marketShare}
                    />
                )}
                {this.state.tag === 'urls' && (
                    <URLs
                        updateDatas={this.updateDatas}
                        project_id={this.props.project_id}
                        keyword={this.keyword}
                        data={this.state.datas.urls}
                    />
                )}
                {this.state.tag === 'content_gaps' && (
                    <ContentGaps
                        updateDatas={this.updateDatas}
                        updateDomains={this.updateDomains}
                        project_id={this.props.project_id}
                        keyword={this.keyword}
                        data={this.state.datas.contentGaps}
                        domains_for_select={this.state.datas.domains_for_select}
                        own_domain={this.state.own_domain}
                        competitors={this.state.competitors}
                    />
                )}
            </div>
        );
    }
}

export default CommerceViewDetail;
