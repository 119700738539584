import { Component } from 'react';
import DataTable from '../../components/Datatable';
import { COLUMNS } from './keys';
import { getSortKeys, setAdditionalStyles } from '../../helpers/table';
import NotesModal from './Notes';
import { v4 as uuid } from 'uuid';
import Message from '../Message';

class DomainsWithoutData extends Component {
    constructor(props) {
        super();

        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);
        this.onOpenMessage = this.onOpenMessage.bind(this);
        this.updateUrls = this.updateUrls.bind(this);

        this.urls = JSON.parse(props.urls);
        if (this.urls?.length) {
            this.urls = this.urls?.reduce((acc, curr) => {
                const index = acc.findIndex((item) => item.url === curr.url);
                if (index !== -1) {
                    acc[index] = { ...acc[index], [curr.object_type]: true };
                } else {
                    const obj = {
                        ...curr,
                        [curr.object_type]: true,
                        id: uuid(),
                    };
                    acc.push(obj);
                }
                return acc;
            }, []);
        }

        this.state = {
            urls: this.urls,
            isOpenModal: false,
            selectedDomain: '',
            selectedNote: '',
        };
    }

    onOpenModal(domain, note) {
        this.setState({
            isOpenModal: true,
            selectedDomain: domain,
            selectedNote: note,
        });
    }

    onCloseModal() {
        this.setState({
            isOpenModal: false,
            selectedDomain: '',
            selectedNote: '',
        });
    }

    onOpenMessage(message, message_tag) {
        this.setState({
            message,
            message_tag: message_tag,
        });
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) => item.url?.toLowerCase().includes(search.toLowerCase()));
    }

    deleteMessage() {
        this.setState({
            message: '',
            message_tag: '',
        });
    }

    updateUrls(domain_id, note) {
        this.setState({
            urls: this.state.urls.map((item) => {
                if (item.domain_id === domain_id) {
                    return { ...item, note };
                }
                return item;
            }),
        });
    }

    render() {
        const columns = COLUMNS(this.onOpenModal);
        return (
            <>
                <DataTable
                    nodes={this.state.urls ?? []}
                    sortFns={getSortKeys(columns)}
                    COLUMNS={columns}
                    fileName="Without data"
                    searchFunction={this.searchFunction}
                    additionalStyles={setAdditionalStyles(columns)}
                    defaultSortKey={'created'}
                />
                {this.state.isOpenModal && (
                    <NotesModal
                        isOpen={this.state.isOpenModal}
                        onClose={this.onCloseModal}
                        selectedDomain={this.state.selectedDomain}
                        csrf_token={this.props.csrf_token}
                        updateUrls={this.updateUrls}
                        onOpenMessage={this.onOpenMessage}
                        selectedNote={this.state.selectedNote}
                    />
                )}
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tag}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default DomainsWithoutData;
