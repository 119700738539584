import numeral from 'numeral';
import colors, { labelColors } from '../../../lib/colors';

const keys = [
    {
        key: 'clicks',
        label: 'Avg. Clicks',
        color: colors[3],
        labelColor: labelColors[3],
        icon: 'clicks',
        yAxisID: 'y3',
    },
    {
        key: 'impressions',
        label: 'Avg. Impressions',
        color: colors[0],
        labelColor: labelColors[0],
        icon: 'search',
    },
    {
        key: 'avgPosition',
        label: 'Avg. Position',
        color: colors[5],
        labelColor: labelColors[5],
        icon: 'pagev-view',
        yAxisID: 'y1',
    },
    {
        key: 'avgCTR',
        label: 'Avg. CTR',
        format: '0.00%',
        yAxisID: 'y1',
        render: (data) => numeral(data * 100).format('0.00'),
        color: colors[1],
        labelColor: labelColors[1],
        icon: 'ctr',
    },
    {
        key: 'urls',
        label: 'URLs',
        color: colors[4],
        labelColor: labelColors[4],
        icon: 'pagev-view',
        type: 'bar',
        yAxisID: 'y1',
        hidden: true,
    },
    {
        key: 'weekly_keywords',
        label: 'Avg. Keywords',
        color: colors[2],
        labelColor: labelColors[2],
        icon: 'pagev-view',
        type: 'bar',
        yAxisID: 'y1',
        hidden: true,
    },
];

export default keys;
