import { Component } from 'react';
import { checkValues } from '../../EditMainInfo/validation';
import ReactSelect from '../../../components/ReactSelect';
import { updateProfile } from './queries';
import _ from 'lodash';
import Loading from '../../../components/Loading';

class AddTaxIdOrVat extends Component {
    constructor(props) {
        super();

        this.onFocus = this.onFocus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.updateCountries = this.updateCountries.bind(this);
        this.updateTaxIdTypes = this.updateTaxIdTypes.bind(this);
        this.tax_id_types = JSON.parse(props.tax_id_types).map((item) => ({
            value: item[0],
            label: item[1],
        }));
        this.tax_id_types.unshift({ value: '', label: 'None' });

        this.state = {
            tax_id: props.profile.tax_id ?? '',
            tax_id_type: props.profile.tax_id_type ?? '',
            company_address1: props.profile.company_address1 ?? '',
            company_address2: props.profile.company_address2 ?? '',
            billing_country: props.profile.billing_country ?? '',
            billing_city: props.profile.billing_city ?? '',
            billing_postal_code: props.profile.billing_postal_code ?? '',
            errors: {},
            loading: false,
        };
    }

    onChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value });
    }

    updateTaxIdTypes(value) {
        this.setState({ tax_id_type: value });
    }

    async onSubmit(e) {
        try {
            // if we have no errors, the form from html will work
            const errors = checkValues(this.state);

            if (!_.isEmpty(errors)) {
                e.preventDefault();
                this.setState({ errors });
            } else {
                this.setState({
                    loading: true,
                });
                const params = {
                    tax_id: this.state.tax_id,
                    tax_id_type: this.state.tax_id_type,
                    company_address1: this.state.company_address1,
                    company_address2: this.state.company_address2,
                    billing_country: this.state.billing_country,
                    billing_city: this.state.billing_city,
                    billing_postal_code: this.state.billing_postal_code,
                };

                const res = await updateProfile(params, this.props.csrf_token);
                const data = await res.json();
                if (res.status === 200) {
                    this.setState({
                        message: data.message,
                        message_tags: 'success',
                    });
                    this.props.finishSteps();
                } else {
                    this.setState({
                        message: data?.message ?? 'Something went wrong',
                        message_tags: 'error',
                    });
                }
            }
        } catch (err) {
            this.setState({
                message: err.message ?? 'Something went wrong',
                message_tags: 'error',
            });
            console.log(err);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    onFocus(e) {
        const key = e.target.name;
        const errors = { ...this.state.errors };
        delete errors[key];

        this.setState({ errors });
    }

    updateCountries(value) {
        this.setState({ geo_location: value });
    }

    render() {
        return (
            <>
                <div id="onboarding-general-settings">
                    <div className="onboarding-subheader">
                        Would you like to add a Tax ID or VAT to your invoice? You can also do this
                        later.
                    </div>
                    <>
                        <div className="edit-form inline" id="name-edit-inputs">
                            <div className="text-field mb-3">
                                <label htmlFor="vat-tax-id" className="form-label">
                                    Vat/Tax Id
                                </label>
                                <input
                                    name="tax_id"
                                    type="text"
                                    id="vat-tax-id"
                                    className="form-control"
                                    onChange={this.onChange}
                                    value={this.state.tax_id}
                                    onFocus={this.onFocus}
                                />
                                {this.state.errors['tax_id'] && (
                                    <div className="error-message">
                                        {this.state.errors['tax_id']}
                                    </div>
                                )}
                            </div>
                            <div className="text-field mb-3">
                                <label htmlFor="tax_id_type" className="form-label">
                                    Tax ID Type
                                </label>
                                <input
                                    type="hidden"
                                    name="tax_id_type"
                                    value={this.state.tax_id_type}
                                />
                                <ReactSelect
                                    options={this.tax_id_types}
                                    onChange={this.updateTaxIdTypes}
                                    isSearchable={true}
                                    value={this.tax_id_types.find(
                                        (item) => item.value === this.state.tax_id_type,
                                    )}
                                    blurInputOnSelect={true}
                                    classname="m-0"
                                    controlStyles={{ height: '45px' }}
                                    onFocus={() =>
                                        this.onFocus({ target: { name: 'tax_id_type' } })
                                    }
                                />
                                {this.state.errors['tax_id_type'] && (
                                    <div className="error-message">
                                        {this.state.errors['tax_id_type']}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="edit-form inline" id="name-edit-inputs">
                            <div className="text-field mb-3">
                                <label htmlFor="company_address1" className="form-label">
                                    Company address 1
                                </label>
                                <input
                                    name="company_address1"
                                    type="text"
                                    id="company_address1"
                                    className="form-control"
                                    onChange={this.onChange}
                                    value={this.state.company_address1}
                                    onFocus={this.onFocus}
                                />
                                {this.state.errors['company_address1'] && (
                                    <div className="error-message">
                                        {this.state.errors['company_address1']}
                                    </div>
                                )}
                            </div>
                            <div className="text-field mb-3">
                                <label htmlFor="company_address2" className="form-label">
                                    Company Address 2
                                </label>
                                <input
                                    name="company_address2"
                                    type="text"
                                    id="company_address2"
                                    className="form-control"
                                    onChange={this.onChange}
                                    value={this.state.company_address2}
                                />
                            </div>
                        </div>
                        <div className="edit-form inline" id="name-edit-inputs">
                            <div className="text-field mb-3">
                                <label htmlFor="Billing Country" className="form-label">
                                    Billing Country
                                </label>
                                <input
                                    name="billing_country"
                                    type="text"
                                    id="billing_country"
                                    className="form-control"
                                    onChange={this.onChange}
                                    value={this.state.billing_country}
                                    onFocus={this.onFocus}
                                />
                                {this.state.errors['billing_country'] && (
                                    <div className="error-message">
                                        {this.state.errors['billing_country']}
                                    </div>
                                )}
                            </div>
                            <div className="text-field mb-3">
                                <label htmlFor="billing_city" className="form-label">
                                    Billing City
                                </label>
                                <input
                                    name="billing_city"
                                    type="text"
                                    id="billing_city"
                                    className="form-control"
                                    onChange={this.onChange}
                                    value={this.state.billing_city}
                                    onFocus={this.onFocus}
                                />
                                {this.state.errors['billing_city'] && (
                                    <div className="error-message">
                                        {this.state.errors['billing_city']}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="edit-form inline" id="name-edit-inputs">
                            <div className="text-field mb-3">
                                <label htmlFor="billing_postal_code" className="form-label">
                                    Billing Postal Code
                                </label>
                                <input
                                    name="billing_postal_code"
                                    type="text"
                                    id="billing_postal_code"
                                    className="form-control"
                                    onChange={this.onChange}
                                    value={this.state.billing_postal_code}
                                    onFocus={this.onFocus}
                                />
                                {this.state.errors['billing_postal_code'] && (
                                    <div className="error-message">
                                        {this.state.errors['billing_postal_code']}
                                    </div>
                                )}
                            </div>
                            <div className="text-field mb-3" style={{ marginRright: '10px' }}></div>
                        </div>
                    </>
                    {this.state.message && (
                        <div className={`onboarding-message-${this.message_tags}`}>
                            {this.message}
                        </div>
                    )}
                    <div className="d-flex justify-content-end">
                        <button
                            className="button width-sm btn onboarding-btn-next d-flex justify-content-center onboarding-skip-btn"
                            style={{ lineHeight: 'unset' }}
                            onClick={() => this.props.handleBack()}
                        >
                            back
                        </button>
                        <button
                            className="button  button-filled button-primary width-sm btn onboarding-btn-next d-flex justify-content-center"
                            onClick={this.onSubmit}
                            disabled={this.state.loading}
                        >
                            {this.state.loading && <Loading onlyPreloader />}
                            Save
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

export default AddTaxIdOrVat;
