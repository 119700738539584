import { COLUMNS } from './keys';
import { Component } from 'react';
import DataTable from '../../../components/Datatable';
import { scrollForTable } from '../../../components/Datatable/utils';

class Entities extends Component {
    constructor() {
        super();
    }

    render() {
        const sortFns = {
            name: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
            type: (array) => array.sort((a, b) => a.type.localeCompare(b.type)),
            salience: (array) => array.sort((a, b) => a.salience - b.salience),
            sentiment: (array) => array.sort((a, b) => a.sentiment - b.sentiment),
        };

        return (
            <>
                <DataTable
                    nodes={this.props.data}
                    sortFns={sortFns}
                    COLUMNS={COLUMNS}
                    fileName="Categories"
                    searchFunction={(data) => data}
                    pdfColumnSize={18}
                    additionalStyles={{
                        Table: `
                            --data-table-library_grid-template-columns : minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                            ${scrollForTable}
                        `,
                        BaseCell: `font-family: 'Raleway';
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                    padding: 16px 5px;
                                    color: #0D182C;
                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                
                                    &.underline {
                                        text-decoration: underline;
                                    }
                                    
                                    `,
                    }}
                />
            </>
        );
    }
}
export default Entities;
