import React from 'react';

class Modal extends React.Component {
    constructor(props) {
        super();

        this.state = {
            active: props.active || false,
            data: {
                name: '',
                email: '',
                organization: '',
            },
        };

        this.next = this.next.bind(this);
        this.open = this.open.bind(this);
        this.back = this.back.bind(this);
    }

    toggleActive() {
        this.setState({
            active: !this.state.active,
        });
    }

    updateData(key, e) {
        const newState = { ...this.state };
        newState.data[key] = e.target.val || e.target.value;
        this.setState(newState);
    }

    next(e) {
        e.preventDefault && e.preventDefault();
        this.props.next && this.props.next(this.state.data);
    }

    back(e) {
        e.preventDefault && e.preventDefault();
        this.props.back && this.props.back(this.state.data);
    }

    open() {
        this.next();
    }

    render() {
        let url = `https://search.google.com/search-console/users?resource_id=${this.props.data.domain}`;

        return (
            <div className="registration info">
                <form action="/domains/add/" method="post">
                    <input type="hidden" name="domain" value={this.props.data.domain} />
                    <input type="hidden" name="account" value={this.props.data.discoverAccount} />
                    <div className="mb-3">
                        <h4>Confirm that your domain is added!</h4>
                        <p>
                            Alright! You&apos;re ready to add your domain. It may take up to 24
                            hours after you&apos;ve added this domain for us to collect and analyze
                            your historical data.
                        </p>
                    </div>
                    <div className="align-right">
                        <a
                            href={url}
                            target="_blank"
                            className="btn btn-lg btn-outline-primary"
                            style={{ marginRight: '10px' }}
                            onClick={this.back}
                            rel="noreferrer"
                        >
                            Back
                        </a>
                        <button className="btn btn-lg btn-primary text-white" type="submit">
                            Add Domain
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

export default Modal;
