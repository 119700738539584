export const sendEmailQuery = async (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/customers/send-bulk-emails/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const sendEmailToAllUsersQuery = async (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/customers/send-emails-to-all-users/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
