import Query from '../../../components/Report';
import { DEFAULT_YEAR } from './keys';

export const query = ({ offset = 0, year = DEFAULT_YEAR, search }) =>
    new Query('search-evergreen-year', {
        offset,
        year,
        search: searchKeywords(search),
    });

function searchKeywords(search) {
    if (!search) return '';
    return `and (keyword like '% ${search}%' OR keyword like '%${search} %' OR keyword like '% ${search} %')`;
}
