import React, { Component } from 'react';

class ExtraButtons extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className="categories-view">
                <button
                    className={`categories-view-btn ${
                        this.props.filter === 'growth' && 'categories-view-btn-active'
                    }`}
                    onClick={() => this.props.chooseFilter('growth')}
                >
                    Growth
                </button>
                <button
                    className={`categories-view-btn ${
                        this.props.filter === 'decline' && 'categories-view-btn-active'
                    }`}
                    onClick={() => this.props.chooseFilter('decline')}
                >
                    Decline
                </button>
                <button
                    className={`categories-view-btn ${
                        this.props.filter === 'new' && 'categories-view-btn-active'
                    }`}
                    onClick={() => this.props.chooseFilter('new')}
                >
                    New
                </button>
                <button
                    className={`categories-view-btn ${
                        this.props.filter === 'lost' && 'categories-view-btn-active'
                    }`}
                    onClick={() => this.props.chooseFilter('lost')}
                >
                    Lost
                </button>
            </div>
        );
    }
}

export default ExtraButtons;
