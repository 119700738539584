export const checkValues = (data) => {
    const {
        tax_id,
        tax_id_type,
        company_address1,
        billing_country,
        billing_city,
        billing_postal_code,
        errors,
    } = data;
    if (tax_id || tax_id_type) {
        if (!tax_id) errors.tax_id = 'This field is required if you filled out Tax ID type';
        if (!tax_id_type) errors.tax_id_type = 'This field is required if you filled out Tax ID';
    } else {
        delete errors.tax_id;
        delete errors.tax_id_type;
    }

    if (company_address1 || billing_country || billing_city || billing_postal_code) {
        if (!company_address1) errors.company_address1 = 'This field is required';
        if (!billing_country) errors.billing_country = 'This field is required';
        if (!billing_city) errors.billing_city = 'This field is required';
        if (!billing_postal_code) errors.billing_postal_code = 'This field is required';
    } else {
        delete errors.company_address1;
        delete errors.billing_country;
        delete errors.billing_city;
        delete errors.billing_postal_code;
    }

    return errors;
};
