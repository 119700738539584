import React, { useState } from 'react';
import TextField from '../../components/TextField';
import ReactModal from '../../components/Modal';
import query from './query';
import { compareDate, datesAreOnSameDay, toDate, toStringDate } from '../../helpers/date';

const DomainRefreshModal = ({
    isOpen,
    onClose,
    domain_id,
    base_url,
    csrf_token,
    changeState,
    domainData,
}) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const onCloseModal = () => {
        setStartDate('');
        setEndDate('');
        onClose();
    };

    const handleStartDate = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDate = (e) => {
        setEndDate(e.target.value);
    };

    const collectPeriods = (startDate, endDate) => {
        const start = toDate(startDate);
        const end = toDate(endDate);

        const domainsInRange = domainData.filter(
            (domain) => toDate(domain.date.value) >= start && toDate(domain.date.value) <= end,
        );

        let loop = toDate(start);
        let obj = {};
        let data = [];

        while (compareDate(loop, end)) {
            if (datesAreOnSameDay(toDate(domainsInRange[0]?.date?.value), loop)) {
                if (domainsInRange[0].scraped > 0) {
                    if (obj.start_date && obj.end_date) {
                        data.push({ ...obj });
                        obj = {};
                    }
                } else {
                    obj.start_date = obj.start_date ?? toStringDate(loop);
                    obj.end_date = toStringDate(loop);
                }

                domainsInRange.shift();
            } else {
                obj.start_date = obj.start_date ?? toStringDate(loop);
                obj.end_date = toStringDate(loop);
            }

            if (datesAreOnSameDay(loop, end)) {
                if (obj.start_date && obj.end_date) {
                    data.push({ ...obj });
                    obj = {};
                }
            }

            let newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate);
        }

        return data;
    };

    const refreshDomain = async (e) => {
        try {
            e.preventDefault();
            const periods = collectPeriods(startDate, endDate);
            const params = {
                start_date: startDate,
                end_date: endDate,
                periods,
            };
            changeState(domain_id, true);
            onCloseModal();
            const response = await query(base_url, domain_id, params, csrf_token);
            const data = await response.json();
            changeState(domain_id, false, 'success', data.message);
        } catch (err) {
            onCloseModal();
            changeState(false, 'fail', 'Something went wrong. Please try again.');
            console.log(err);
        }
    };

    return (
        <ReactModal modalState={isOpen} className="refresh-domain-modal width-sm">
            <p>Please enter the number of days to retrieve the data.</p>
            <p className="helpText">
                (not filling out any of the fields, the last 30 days data will be retrieved)
            </p>
            <form onSubmit={refreshDomain}>
                <TextField
                    label="Enter start date"
                    type="date"
                    placeholder="Days count"
                    value={startDate}
                    onChange={handleStartDate}
                    max={endDate}
                />
                <TextField
                    label="Enter end date"
                    type="date"
                    placeholder="Days count"
                    value={endDate}
                    onChange={handleEndDate}
                    min={startDate}
                />
                <div className="btn-wrapper">
                    <button
                        type="button"
                        className="button button-outline button-primary width-sm btn"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="button button-filled button-primary width-sm btn"
                    >
                        Run
                    </button>
                </div>
            </form>
        </ReactModal>
    );
};

export default DomainRefreshModal;
