import numeral from 'numeral';
import colors, { labelColors } from '../../lib/colors';

export default [
    {
        key: 'impressions',
        label: 'Daily Impressions',
        format: '0,00a',
        color: colors[3],
        labelColor: labelColors[0],
    },
    {
        key: 'clicks',
        label: 'Daily Clicks',
        format: '0,00a',
        color: colors[4],
        labelColor: labelColors[2],
        yAxisID: 'y3',
    },
    {
        key: 'ctr',
        label: 'CTR',
        format: '0.00%',
        render: (data) => numeral(data * 100).format('0.00'),
        yAxisID: 'y1',
        color: colors[2],
        labelColor: labelColors[1],
    },
];
