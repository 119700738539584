import React from 'react';
import Select from 'react-select';
import Component from '../../components/Component';
import { options } from './keys';
import query from './query';

class DeviceDropdown extends Component {
    constructor() {
        super();
        this.query = query;
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            const existingOptionsKeys = this.state.data.map((x) => x.device);
            if (
                existingOptionsKeys.length !== 0 &&
                !existingOptionsKeys.includes(this.props.device)
            ) {
                this.props.updateSelect(existingOptionsKeys[0]);
            }
        }
    }

    render() {
        let filteredOptions = options;

        if (this.state.data?.[0] && !this.props.show_all) {
            const existingOptionsKeys = this.state.data.map((x) => x.device);
            filteredOptions = options.filter((x) => existingOptionsKeys.includes(x.value));
        }
        return (
            <div>
                <Select
                    options={filteredOptions}
                    value={options.find((x) => x.value === this.props.device)}
                    menuPortalTarget={document.querySelector('body')}
                    menuPosition={'fixed'}
                    styles={{
                        container: (baseStyles) => ({
                            ...baseStyles,
                            width: '100%',
                        }),
                        control: (baseStyles) => ({
                            ...baseStyles,
                            boxShadow: 'none',
                            padding: this.props.padding || '4px 5px',
                            fontSize: '13px',
                            minWidth: '295px',
                        }),
                        menuPortal: (provided) => ({ ...provided, zIndex: 999999999 }),
                        menu: (provided) => ({ ...provided, zIndex: 999999999 }),
                    }}
                    theme={(theme) => ({
                        ...theme,
                        height: '45px',
                        borderRadius: '7px',
                        borderColor: '#d9d9d9',
                        transition: 'all 0.2s',
                        colors: {
                            ...theme.colors,
                            primary: '#888adb',
                        },
                    })}
                    onChange={(data) => this.props.updateSelect(data.value)}
                    placeholder="Select Device"
                    isSearchable={false}
                />
            </div>
        );
    }
}

export default DeviceDropdown;
