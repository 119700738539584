import React from 'react';

class Modal extends React.Component {
    constructor(props) {
        super();

        this.state = {
            active: props.active || false,
            data: {
                name: '',
                email: '',
                organization: '',
            },
        };

        this.next = this.next.bind(this);
    }

    toggleActive() {
        this.setState({
            active: !this.state.active,
        });
    }

    updateData(key, e) {
        const newState = { ...this.state };
        newState.data[key] = e.target.val || e.target.value;
        this.setState(newState);
    }

    next(e) {
        e.preventDefault();
        this.props.next && this.props.next(this.state.data);
    }

    render() {
        let params = this.props.params || {};
        let promotion_code = this.props.promotion_code || '';

        let inputs = [];

        for (let i in this.props.data) {
            inputs.push(<input type="hidden" name={i} value={this.props.data[i]} />);
        }

        inputs.push(<input type="hidden" name="product_id" value={params.product_id} />);
        inputs.push(<input type="hidden" name="promotion_code" value={promotion_code} />);

        return (
            <div className="registration info">
                <h3>Get Started</h3>
                <p>
                    Enjoy 14 days of FREE trial. After your trial completes, we&apos;ll convert you
                    to the selected plan.
                </p>
                <p>
                    By clicking &quot;Start Trial&quot;, you agree to the terms of our{' '}
                    <a className="text-primary" href="https://gddash.com/privacy-policy/">
                        Privacy Agreement
                    </a>{' '}
                    and{' '}
                    <a
                        className="text-primary"
                        href="https://gddash.com/beta-terms-of-use-agreement/"
                    >
                        Terms of Service
                    </a>
                    .
                </p>
                <form method="post" action="/customers/register/">
                    <div className="mb-3">
                        {inputs}
                        <div className="mb-3 d-flex justify-content-between">
                            <button
                                type="button"
                                className="btn btn-lg text-white btn-primary "
                                style={{ width: '47%' }}
                                onClick={() => this.props.previous()}
                            >
                                Previous
                            </button>
                            <button
                                className="btn btn-lg btn-primary text-white"
                                style={{ width: '47%' }}
                                type="submit"
                            >
                                Start Trial
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default Modal;
