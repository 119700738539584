import { Component } from 'react';
import { COLUMNS } from './keys';
import DataTable from '../../../components/Datatable';
import { v4 as uuid } from 'uuid';
import { scrollForTable } from '../../../components/Datatable/utils';

class Table extends Component {
    constructor() {
        super();
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) =>
            search ? item.subdomain?.toLowerCase().includes(search.toLowerCase()) : item,
        );
    }

    render() {
        const sortFns = {
            subdomain: (array) => array.sort((a, b) => a.subdomain.localeCompare(b.subdomain)),
            week: (array) => array.sort((a, b) => a.week.localeCompare(b.week)),
            urls: (array) => array.sort((a, b) => a.urls - b.urls),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            keywords: (array) => array.sort((a, b) => a.keywords - b.keywords),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            position: (array) => array.sort((a, b) => a.position - b.position),
            ctr: (array) => array.sort((a, b) => a.ctr - b.ctr),
        };
        const dataWithKey = this.props.data.map((item) => ({ ...item, id: uuid() }));

        return (
            <>
                <DataTable
                    nodes={dataWithKey}
                    sortFns={sortFns}
                    COLUMNS={COLUMNS}
                    fileName="Directory"
                    header="Directory"
                    defaultSortKey="week"
                    forceRun={this.props.forceRun}
                    searchFunction={this.searchFunction}
                    additionalStyles={{
                        Table: `
                            --data-table-library_grid-template-columns : minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                           ${scrollForTable}
                        `,
                        BaseCell: `  font-family: 'Raleway';
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                    padding: 16px 5px !important;
                                    color: #0D182C;
                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                
                                    &.underline {
                                        text-decoration: underline;
                                    }
                                    `,
                    }}
                />
            </>
        );
    }
}

export default Table;
