import Query from '../../../../components/Report';

const query = ({ offset = 0, last_week, prior_week, directory, subdomain }) =>
    new Query('search-urls-directories-detail', {
        offset,
        last_week,
        prior_week,
        directory,
        subdomain,
    });

export default query;
