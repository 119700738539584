import { Component } from 'react';
import { setApiKey } from './query';
import { isTrue } from '../../../helpers/isTrue';
import Message from '../../Message';

class CommerceApiSettings extends Component {
    constructor() {
        super();
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);
        this.changeView = this.changeView.bind(this);

        this.state = {
            api_key: '',
            password: '',
            loading: false,
            message: '',
            message_tags: '',
            showEditMode: false,
            has_api_key: false,
        };
    }

    onChangeInput(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    async onSubmit() {
        try {
            this.setState({
                loading: true,
            });
            const body = {
                api_key: this.state.api_key,
                password: this.state.password,
            };

            const response = await setApiKey(body, this.props.csrf_token);
            const data = await response.json();
            if (response.status === 200 || response.status === 201) {
                this.setState({
                    message_tags: 'success',
                    message: data?.message ?? 'API key added successfully',
                    loading: false,
                    api_key: '',
                    password: '',
                    showEditMode: false,
                    has_api_key: true,
                });
            } else {
                const errorMessage = data.message;
                this.setState({
                    message_tags: 'error',
                    message: errorMessage,
                    loading: false,
                });
            }
        } catch (err) {
            this.setState({
                loading: false,
                message_tags: 'error',
                message: err?.message ?? 'Something went wrong',
            });
            console.log(err);
        }
    }

    deleteMessage() {
        this.setState({ message: '' });
    }

    changeView(isEditMode) {
        this.setState({ showEditMode: isEditMode });
    }

    changeCode(code) {
        const firstPart = code.slice(0, 6);
        const lastPart = code.slice(-6);
        return `${firstPart}*******${lastPart}`;
    }

    render() {
        return (
            <>
                <div className="card rounded-main table-main">
                    <div
                        className="card-header d-flex justify-content-between"
                        style={{ borderRadius: '10px' }}
                    >
                        <div className="card-title text-dark fw-bold">Commerce semrush api key</div>
                        <div className="buttons-wrapper">
                            {this.state.showEditMode ? (
                                <span id="name-edit-btns">
                                    <button
                                        type="button"
                                        className="button button-outline button-primary button-icon mr-10"
                                        onClick={() => this.changeView(false)}
                                    >
                                        <i className="icon-close"></i>
                                    </button>
                                    <button
                                        type="submit"
                                        className="button button-filled button-primary button-icon"
                                        onClick={this.onSubmit}
                                        disabled={
                                            !this.state.api_key ||
                                            !this.state.password ||
                                            this.state.loading
                                        }
                                    >
                                        <i className="icon-check-icon"></i>
                                    </button>
                                </span>
                            ) : (
                                <button
                                    type="button"
                                    className="button button-filled button-primary button-icon"
                                    id="name-edit-btn"
                                    onClick={() => this.changeView(true)}
                                >
                                    <i className="icon-edit"></i>
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="card-body">
                        {this.state.showEditMode ? (
                            <>
                                <div className="settings-commerce">
                                    <label htmlFor="api_key">
                                        <strong>Enter Key</strong>
                                    </label>
                                    <input
                                        name="api_key"
                                        type="text"
                                        id="api_key"
                                        onChange={this.onChangeInput}
                                        value={this.state.api_key}
                                    />
                                    <label htmlFor="password">
                                        <strong>Password</strong>
                                    </label>
                                    <input
                                        name="password"
                                        type="password"
                                        id="password"
                                        onChange={this.onChangeInput}
                                        value={this.state.password}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="font-weight-bold">
                                {isTrue(this.props.has_api_key) || this.state.has_api_key
                                    ? '*****************************'
                                    : 'There is no key'}
                            </div>
                        )}
                    </div>
                </div>
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tags}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default CommerceApiSettings;
