import { Component } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { getRandomColor, labelColors } from '../../../lib/colors';
import { getPercentForChart, tableColumns } from './keys';
import CheckData from '../../../components/CatchError';
import DataTable from '../../../components/Datatable';
import { createDataForModule2 } from './config';
import { Pie } from 'react-chartjs-2';
import numeral from 'numeral';
import { scrollForTable } from '../../../components/Datatable/utils';

ChartJS.register(ArcElement, Tooltip, Legend);

class PositionDistributionModule2 extends Component {
    constructor() {
        super();
    }

    searchFunction(data) {
        return data;
    }

    render() {
        const options = {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Keywords Traffic Share',
                    padding: {
                        top: 10,
                        bottom: 10,
                    },
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const percent = `${getPercentForChart(
                                context.dataset.data,
                                context.parsed,
                            ).toFixed(2)}%`;
                            return `${context.label} : ${numeral(context.parsed).format(
                                '0.0a',
                            )} Click${context.parsed > 1 ? 's' : ''} (${percent})`;
                        },
                    },
                },
            },
        };

        const data = createDataForModule2(this.props.data);

        const pieData = {
            labels: data?.map((item) => item.label),
            datasets: [
                {
                    label: 'Clicks',
                    data: data.map((x) => x.clicks),
                    backgroundColor: data.map((item, i) => labelColors[i] ?? getRandomColor()),
                },
            ],
        };

        return (
            <CheckData
                content_id={this.props.content_id}
                parent_id={this.props.parent_id}
                data={this.props.data}
                loading={this.props.loading}
            >
                <div
                    className="Timeline"
                    style={{ height: '100%', minHeight: '150px', position: 'relative' }}
                >
                    {this.props.data[0] ? (
                        <div className="row">
                            <div
                                className="col-sm-12 col-md-6"
                                style={{ height: '400px', position: 'relative' }}
                            >
                                <Pie data={pieData} options={options} />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="row">
                                    <div className="col">
                                        <DataTable
                                            nodes={data}
                                            sortFns={() => {}}
                                            COLUMNS={tableColumns}
                                            uniqKey={'label'}
                                            fileName="Brandes vs Unbranded"
                                            searchFunction={this.searchFunction}
                                            onlyTable
                                            hasSort={false}
                                            forceRun={this.props.forceRun}
                                            header="Keywords Position Stats"
                                            additionalStyles={{
                                                BaseCell: ` font-family: 'Raleway';
                                                        font-weight: 500;
                                                        font-size: 16px;
                                                        line-height: 19px;
                                                        padding: 16px 5px !important;
                                                        color: #0D182C;
                                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                        &.underline {
                                                            text-decoration: underline;
                                                        }
                                                        `,
                                                Table: `
                                                    --data-table-library_grid-template-columns : minmax(120px, 1fr)  minmax(120px, 1fr)  minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;,
                                                   ${scrollForTable}
                                                `,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>There is no data</div>
                    )}
                </div>
            </CheckData>
        );
    }
}

export default PositionDistributionModule2;
