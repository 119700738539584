import numeral from 'numeral';
import colors, { labelColors } from '../../lib/colors';

const keys = [
    {
        key: 'sumClicks',
        label: 'CLICKS',
        format: '0.00%',
        color: colors[0],
        labelColor: labelColors[0],
    },
    {
        key: 'sumImpressions',
        label: 'IMPRESSIONS',
        color: colors[1],
        labelColor: labelColors[1],
    },
    {
        key: 'avgCTR',
        label: 'CTR',
        render: (data) => numeral(data * 100).format('0.00'),
        yAxisID: 'y1',
        color: colors[2],
        labelColor: labelColors[2],
    },
];

export default keys;
