import numeral from 'numeral';
import { linReg } from '../../lib/reduce';

export default [
    {
        key: (d) => {
            let c = linReg(d.impressionsL);
            return c.slope;
        },
        label: 'ImpressionsChange',
        change: true,
        classes: 'col-2',
        disableArrow: true,
    },
    {
        key: (d) => {
            return (
                <div className="col-12 no-wrap ms-3">
                    <a
                        href={
                            `/domains/${window.info.id}/entities/entity?entities:in=` +
                            encodeURIComponent(d.name)
                        }
                    >
                        {d.name}
                    </a>
                </div>
            );
        },
        label: 'Category',
        type: 'label',
        classes: 'col-10',
    },
    {
        key: 'impressions',
        label: 'Impressions',
        format: '0.0a',
        hidden: true,
    },
    {
        key: 'ctr',
        label: 'CTR',
        format: '0.00%',
        render: (data) => numeral(data * 100).format('0.00'),
        hidden: true,
    },
    {
        key: (d, data) => {
            let imp = d.impressions / data.reduce((e, f) => e + f.impressions, 0);
            let ctr = d.ctr / (data.reduce((e, f) => e + f.ctr, 0) / data.length);

            return (imp + ctr) / 2;
        },
        label: 'Rank',
        bar: true,
        sort: true,
        hidden: true,
    },
];
