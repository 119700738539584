import { Component } from 'react';
import { isTrue } from '../../../helpers/isTrue';
import { COLUMNS } from './keys';
import { getSortKeys, setAdditionalStyles } from '../../../helpers/table';
import DataTable from '../../../components/Datatable';
import QualifiersModal from '../CommerceQualifiersModal';
import Message from '../../Message';

class CommerceView extends Component {
    constructor(props) {
        super();

        const items = JSON.parse(props.commerce_projects);

        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.updateItemQuiliers = this.updateItemQuiliers.bind(this);
        this.onOpenMessage = this.onOpenMessage.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);

        this.state = {
            isOpen: false,
            items,
        };
    }

    searchFunction(data, search) {
        return data.filter((item) => item?.keyword?.toLowerCase()?.includes(search.toLowerCase()));
    }

    onOpen(selectedItem) {
        this.setState({
            isOpen: true,
            selectedItem,
        });
    }

    onClose() {
        this.setState({
            isOpen: false,
            selectedItem: null,
        });
    }

    updateItemQuiliers(qualifiers, projectId) {
        this.setState({
            items: this.state.items?.map((item) => {
                if (projectId === item.project_id) return { ...item, qualifiers };
                return item;
            }),
        });
    }

    onOpenMessage(message, message_tag) {
        this.setState({
            message,
            message_tag: message_tag,
        });
    }

    deleteMessage() {
        this.setState({
            message: '',
            message_tag: '',
        });
    }

    render() {
        const has_api_key = isTrue(this.props.has_api_key);
        return (
            <>
                <div className="card rounded-main table-main">
                    <div
                        className="card-header d-flex justify-content-between"
                        style={{ borderRadius: '10px' }}
                    >
                        <div className="card-title text-dark fw-bold">Commerce</div>
                        <a
                            className="button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center"
                            href="/commerce/create/"
                            style={has_api_key ? {} : { pointerEvents: 'none', opacity: 0.5 }}
                        >
                            Create Project
                        </a>
                    </div>
                    <div className="card-body">
                        {!has_api_key && (
                            <div className="d-flex justify-content-between align-items-center">
                                You Need To Add Semrush Api Key{' '}
                                <a
                                    className="button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center"
                                    href="/commerce/settings/"
                                >
                                    Add Key
                                </a>
                            </div>
                        )}
                        <div>
                            {this.state.items?.length !== 0 && (
                                <DataTable
                                    nodes={this.state.items}
                                    sortFns={getSortKeys(COLUMNS())}
                                    COLUMNS={COLUMNS(this.onOpen)}
                                    fileName="Project"
                                    searchFunction={this.searchFunction}
                                    uniqKey={'project_id'}
                                    additionalStyles={setAdditionalStyles(COLUMNS())}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {this.state.isOpen && (
                    <QualifiersModal
                        csrf_token={this.props.csrf_token}
                        isOpen={this.state.isOpen}
                        onClose={this.onClose}
                        selectedItem={this.state.selectedItem}
                        updateItemQuiliers={this.updateItemQuiliers}
                        onOpenMessage={this.onOpenMessage}
                    />
                )}
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tag}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default CommerceView;
