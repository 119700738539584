import React from 'react';
import ReactModal from '../../components/Modal';

class BuyMoreEntitiesModal extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <>
                <ReactModal modalState={this.props.isOpen} className="width-sm">
                    <p>
                        This will add <strong>{this.props.entityCount}</strong>{' '}
                        {this.props.object_message} to your current subscription for an additional{' '}
                        <strong>${this.props.totalPrice}</strong> {this.props.period}.
                    </p>
                    <div className="btn-wrapper">
                        <button
                            type="button"
                            className="button button-outline button-primary width-sm btn"
                            onClick={this.props.onClose}
                            style={{ lineHeight: 'unset' }}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={this.props.onSubmit}
                            className="button button-filled button-primary width-sm btn"
                        >
                            Continue
                        </button>
                    </div>
                </ReactModal>
            </>
        );
    }
}

export default BuyMoreEntitiesModal;
