import { query_get_top_domains, query_get_values } from './query';
import keys from './keys';
import { getLabel } from '../../helpers/date';
import Loading from '../../components/Loading';
import Timeline from '../../components/Timeline';
import { Component } from 'react';
import { getRandomColor, labelColors } from '../../lib/colors';

class Summary extends Component {
    constructor() {
        super();
        this.urlParams = new URLSearchParams(window.location.search);

        this.entity_ids = this.urlParams.get('entity_ids');
        this.language = this.urlParams.get('language');
        this.country = this.urlParams.get('country');
        this.base_domain = this.urlParams.get('base_domain');

        this.onChangeTags = this.onChangeTags.bind(this);
        this.updateData = this.updateData.bind(this);

        this.state = {
            loading: true,
            data: [],
        };
    }

    updateData(args) {
        if (args.data) {
            this.setState({
                data: args.data,
                loading: false,
                type: this.base_domain ?? 'All',
            });
        } else {
            this.setState(args);
        }
    }
    async componentDidMount() {
        try {
            let domains = [];
            if (!this.base_domain) {
                this.query = await query_get_top_domains({
                    entity_ids: this.entity_ids,
                    country: this.country,
                    language: this.language,
                });
                const topDomains = await this.query.update();
                domains = topDomains.map((item) => `'${item.base_domain}'`).join(',');
            }
            this.query_2 = await query_get_values({
                entity_ids: this.entity_ids,
                country: this.country,
                language: this.language,
                domains: domains,
                base_domain: this.base_domain,
            });
            const data = await this.query_2.update();
            this.query_2.bind(this.updateData);
            this.setState({
                data,
                loading: false,
                type: this.base_domain ?? 'All',
            });
        } catch (err) {
            this.setState({
                loading: false,
            });
            console.log(err);
        }
    }

    onChangeTags(tag) {
        this.setState({
            type: tag,
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        const collectedData = this.state.data
            ? this.state.data?.reduce((acc, item) => {
                  acc[item.base_domain] = acc[item.base_domain]
                      ? [...acc[item.base_domain], item]
                      : [item];
                  return acc;
              }, {})
            : {};
        const tags = Object.keys(collectedData);
        if (!this.base_domain) tags.unshift('All');

        let chartData = {};
        if (this.state.type === 'All' || !this.state.type) {
            let data = [];
            let dates = [];
            for (let key in collectedData) {
                data.push({
                    domain: key,
                    values: collectedData[key].map((item) => {
                        dates.push(item.created_date.value);
                        return { date: item.created_date?.value, appearances: item.appearances };
                    }),
                });
            }
            dates = [...new Set(dates)].sort((a, b) => new Date(a) - new Date(b));
            data = data.map((item) => {
                let values = [];
                dates.forEach((state) => {
                    const foundData = item.values.find((x) => x.date === state);
                    if (foundData) {
                        values.push(foundData);
                    } else {
                        values.push({
                            date: state,
                            appearances: null,
                        });
                    }
                });
                return { domain: item.domain, values };
            });
            chartData = {
                labels: dates.map((date) => getLabel(date)),
                datasets: data?.map((item, i) => {
                    const color = labelColors[i] || getRandomColor();
                    return {
                        label: item.domain,
                        type: 'line',
                        data: item.values.map((x) => x.appearances),
                        borderColor: color,
                        backgroundColor: color,
                        borderRadius: 4,
                        tension: 0.4,
                    };
                }),
            };
        } else {
            const selectedTag = this.state.type ?? tags?.[0];
            const selectedItem = collectedData?.[selectedTag] ?? [];
            const totalAppearances = selectedItem.reduce((acc, item) => acc + item.appearances, 0);
            const totalScore = selectedItem.reduce((acc, item) => acc + item.domainScore, 0);
            chartData = {
                labels: selectedItem?.map((item) => getLabel(item?.created_date?.value)),
                datasets: keys.map((item, i) => {
                    const color = labelColors[i] || getRandomColor();
                    return {
                        label: item.label,
                        type: 'line',
                        data: selectedItem?.map((state) =>
                            item.render
                                ? item.render(
                                      state?.[item.key],
                                      state,
                                      totalAppearances,
                                      totalScore,
                                  )
                                : state?.[item.key],
                        ),
                        borderColor: color,
                        backgroundColor: color,
                        yAxisID: item.yAxisID || 'y',
                        hidden: item.hidden || false,
                        borderRadius: 4,
                        tension: 0.4,
                    };
                }),
            };
        }

        return (
            <>
                {/* <h3 className="custom-title">Google Surfaces Performance</h3> */}
                <div className="tags-container">
                    {tags?.map((tag) => (
                        <span
                            className={`tab-item extra-styles ${
                                tag === this.state.type && 'active-tag'
                            }`}
                            key={tag}
                            onClick={() => this.onChangeTags(tag)}
                        >
                            {tag}
                        </span>
                    ))}
                </div>
                <Timeline
                    chartSize="lg"
                    metricType="primary"
                    data={this.state.data}
                    error={this.state.error}
                    keys={keys}
                    parent_id={this.props.parent_id}
                    content_id={this.props.content_id}
                    forceRun={this.forceRun}
                    percentagesValues={['Market Share', 'Visibility']}
                    chartData={chartData}
                    hasSecondRightAxis={
                        this.state.type === 'All' || !this.state.type ? false : true
                    }
                    xAxisTitle=""
                    leftAxisLabel={
                        this.state.type === 'All' || !this.state.type ? 'Appearances' : ''
                    }
                    xAxisKey="created_date"
                    rightSecondAxisLabel={
                        this.state.type === 'All' || !this.state.type ? '' : 'AVG. Ranking'
                    }
                    dontShowMetrics
                    secondRightAxisReversed
                />
            </>
        );
    }
}

export default Summary;
