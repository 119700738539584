import { query } from './query';
import DataTable from '../../../components/Datatable';
import Component from '../../../components/Component';
import { getDifference } from '../../../helpers/showDelta';
import { noDataMessage } from '../../../helpers/noDataMessage';
import Loading from '../../../components/Loading';
import { scrollForTable } from '../../../components/Datatable/utils';
import { COLUMNS } from './keys';
import { v4 as uuid } from 'uuid';

function findParts(acc, curr, ids) {
    const parts = curr.path.split('/');
    if (parts.length > 1) {
        const parent = parts.shift();
        return findParts(
            acc.nodes.find((item) => item.name === parent),
            { ...curr, path: parts.join('/') },
            ids,
        );
    } else {
        const id = uuid();
        ids.push(id);
        if (acc.nodes) {
            acc.nodes.push({ ...curr, name: parts[0], id });
        } else {
            acc.nodes = [{ ...curr, name: parts[0], id }];
        }
    }
    return acc;
}

function createTree(data) {
    if (!data || data?.length === 0) return { nodes: [], ids: [] };
    const ids = [];
    const collectedData = data.reduce((acc, curr) => {
        const levelParts = curr.level.slice(0, -1).split('/');
        levelParts.shift();
        if (levelParts.length === 1) {
            const id = uuid();
            ids.push(id);
            acc.push({ ...curr, name: levelParts[0], id });
        } else if (levelParts.length >= 2) {
            const parent = levelParts.shift();

            findParts(
                acc.find((item) => item.name === parent),
                { ...curr, path: levelParts.join('/') },
                ids,
            );
        }
        return acc;
    }, []);
    const allData = [
        {
            name: 'All Categories',
            sum_impressions: null,
            sum_clicks: null,
            avg_ctr: null,
            unique_pages: null,
            nodes: collectedData.map((item) => ({ ...item })),
            id: 'all_categories',
        },
    ];
    return { nodes: allData, ids };
}

function getNestedView(data, length) {
    if (!data || data.length === 0) return [];
    const collectedView = data
        .filter((item) => item.level.split('/').length === length)
        .map((item) => ({ ...item, name: `all${item.level}`, id: uuid(), haveStrength: true }));

    return collectedView;
}

class Categories extends Component {
    constructor(props) {
        super();

        this.chooseView = this.chooseView.bind(this);
        if (!props.data) this.query = query(props.dates);
    }

    searchFunction(data, search) {
        return data.filter((item) => item.name?.toLowerCase().includes(search.toLowerCase()));
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.dates) !== JSON.stringify(this.props.dates)) {
            this.props.updateDates({ key: 'categories', value: null });
            this.setState({ loading: true, data: null });
            this.query = query(this.props.dates);
            this.query.bind(this.setState.bind(this));
            this.query.update();
            this.props.updateDates({ emptyAll: true });
        }
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDates({ key: 'categories', value: this.state.data });
            const { nodes, ids } = createTree(this.state.data);
            this.setState({
                nodes,
                ids,
            });
        }
    }

    getViewData(nodes, allData) {
        switch (this.state.view) {
            case 2: {
                const data = getNestedView(allData, 4);
                return {
                    nodes: data,
                    levelName: '2nd Level',
                };
            }
            case 3: {
                const data = getNestedView(allData, 5);
                return {
                    nodes: data,
                    levelName: 'third level',
                };
            }
            default: {
                return {
                    nodes,
                    levelName: 'top level',
                };
            }
        }
    }

    getAverages(data) {
        const length = data?.length ?? 1;
        const sumAll = data?.reduce((acc, curr, i) => {
            if (i === 0) {
                acc = {
                    sumPages: curr.unique_pages,
                    sumImpressions: curr.sum_impressions,
                    sumClicks: curr.sum_clicks,
                    sumCTR: curr.avg_ctr,
                };
            } else {
                acc = {
                    sumPages: acc.sumPages + curr.unique_pages,
                    sumImpressions: acc.sumImpressions + curr.sum_impressions,
                    sumClicks: acc.sumClicks + curr.sum_clicks,
                    sumCTR: acc.sumCTR + curr.avg_ctr,
                };
            }
            return acc;
        }, {});
        const averages = {
            avgPages: sumAll.sumPages / length,
            avgImpressions: sumAll.sumImpressions / length,
            avgClicks: sumAll.sumClicks / length,
            avgCTR: sumAll.sumCTR / length,
        };
        return averages;
    }

    chooseView(view) {
        this.setState({ view });
    }

    render() {
        const data = this.state.data?.length ? this.state.data : this.props.data;
        if ((!data || !data.length) && this.state.loading && !this.props.data) return <Loading />;

        const { nodes: treeNodes } = createTree(data);
        const { nodes } = this.getViewData(treeNodes, data);

        const sortFns = {
            name: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
            unique_pages: (array) =>
                array.sort((a, b) => {
                    return a.unique_pages - b.unique_pages;
                }),
            sum_impressions: (array) => array.sort((a, b) => a.sum_impressions - b.sum_impressions),
            sum_clicks: (array) => array.sort((a, b) => a.sum_clicks - b.sum_clicks),
            median_impressions_per_page: (array) =>
                array.sort((a, b) => a.median_impressions_per_page - b.median_impressions_per_page),
            impressions_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.sum_impressions, a.sum_impressions_prior) -
                        getDifference(b.sum_impressions, b.sum_impressions_prior),
                ),
            clicks_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.sum_clicks, a.sum_clicks_prior) -
                        getDifference(b.sum_clicks, b.sum_clicks_prior),
                ),
            median_impressions_per_page_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(
                            a.median_impressions_per_page,
                            a.median_impressions_per_page_prior,
                        ) -
                        getDifference(
                            b.median_impressions_per_page,
                            b.median_impressions_per_page_prior,
                        ),
                ),
            avgCTR: (array) =>
                array.sort(
                    (a, b) => a.sum_clicks / a.sum_impressions - b.sum_clicks / b.sum_impressions,
                ),
            avgCTR_delta: (array) =>
                array.sort((a, b) => {
                    const ctrValueA = a.sum_clicks / a.sum_impressions;
                    const ctrValuePriorA = a.sum_clicks_prior / a.sum_impressions_prior;
                    const ctrValueB = b.sum_clicks / b.sum_impressions;
                    const ctrValuePriorB = b.sum_clicks_prior / b.sum_impressions_prior;

                    return (
                        getDifference(ctrValueA, ctrValuePriorA) -
                        getDifference(ctrValueB, ctrValuePriorB)
                    );
                }),
        };

        const extraBtns = () => (
            <div className="categories-view">
                <button
                    className={`categories-view-btn ${
                        (!this.state.view || this.state.view === 1) && 'categories-view-btn-active'
                    }`}
                    onClick={() => this.chooseView(1)}
                >
                    <i className="icon-account-tree" style={{ marginRight: '3px' }}></i> Nested View
                </button>
                <button
                    className={`categories-view-btn ${
                        this.state.view === 2 && 'categories-view-btn-active'
                    }`}
                    onClick={() => this.chooseView(2)}
                >
                    <i className="icon-list" style={{ marginRight: '3px' }}></i> 2nd Level
                </button>
                <button
                    className={`categories-view-btn ${
                        this.state.view === 3 && 'categories-view-btn-active'
                    }`}
                    onClick={() => this.chooseView(3)}
                >
                    <i className="icon-list" style={{ marginRight: '3px' }}></i> 3rd Level
                </button>
            </div>
        );

        return !data || !data.length ? (
            <div>{noDataMessage}</div>
        ) : (
            <DataTable
                nodes={nodes}
                sortFns={sortFns}
                COLUMNS={COLUMNS}
                openedItemIds={['all_categories']}
                uniqKey={'first_directory'}
                fileName="Directories"
                searchFunction={this.searchFunction}
                hasTree={!this.state.view || this.state.view === 1}
                searchWithTree={!this.state.view || this.state.view === 1}
                forceRun={this.forceRun}
                extraBtns={extraBtns}
                additionalStyles={{
                    Table: `
                                --data-table-library_grid-template-columns : minmax(400px, 3fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                               ${scrollForTable}
                            `,
                    BaseCell: ` font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                        text-decoration: underline;
                                        }`,
                }}
            />
        );
    }
}

export default Categories;
