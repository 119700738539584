import React from 'react';
import ReactModal from '../Modal';
import Loading from '../Loading';
import _ from 'lodash';

class SendEmailModal extends React.Component {
    constructor() {
        super();

        this.updateInput = this.updateInput.bind(this);
        this.onClose = this.onClose.bind(this);
        this.validation = this.validation.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onFocus = this.onFocus.bind(this);

        this.state = {
            subject: '',
            body_text: '',
            errors: {},
        };
    }

    updateInput(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onClose() {
        this.setState({
            subject: '',
            body_text: '',
        });
        this.props.onClose();
    }

    validation() {
        const { subject, body_text } = this.state;

        const params = { subject, body_text };
        const errors = {};

        for (let key in params) {
            if (!params[key]) errors[key] = 'This field is required.';
        }

        this.setState({ errors });
        return errors;
    }

    onSubmit(e) {
        e.preventDefault();

        const errors = this.validation();
        if (!_.isEmpty(errors)) return;

        this.props.onSubmit({
            subject: this.state.subject,
            body_text: this.state.body_text,
            closeModal: this.onClose,
        });
    }

    onFocus(e) {
        const key = e.target.name;
        const errors = { ...this.state.errors };
        delete errors[key];

        this.setState({ errors });
    }

    render() {
        return (
            <ReactModal modalState={this.props.isOpen} className="width-sm">
                <form onSubmit={this.onSubmit}>
                    <div>
                        <div className="text-field mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                Subject
                            </label>
                            <input
                                name="subject"
                                className="form-control"
                                aria-describedby="Your Name"
                                onChange={this.updateInput}
                                value={this.state.subject}
                                onFocus={this.onFocus}
                            />
                            {this.state.errors['subject'] && (
                                <div className="error-message">{this.state.errors['subject']}</div>
                            )}
                        </div>
                        <div className="text-field mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                Body
                            </label>
                            <textarea
                                name="body_text"
                                className="form-control"
                                style={{ minHeight: '150px' }}
                                onChange={this.updateInput}
                                value={this.state.body_text}
                                onFocus={this.onFocus}
                            ></textarea>
                            {this.state.errors['body_text'] && (
                                <div className="error-message">
                                    {this.state.errors['body_text']}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="btn-wrapper">
                        <button
                            type="button"
                            className="button button-outline button-primary width-sm btn"
                            onClick={this.onClose}
                            style={{ lineHeight: 'unset' }}
                        >
                            Cancel
                        </button>
                        <button
                            className="button button-filled button-primary width-sm btn btn-with-loader"
                            disabled={this.props.isLoading}
                        >
                            Send
                            {this.props.isLoading && <Loading onlyPreloader />}
                        </button>
                    </div>
                </form>
            </ReactModal>
        );
    }
}

export default SendEmailModal;
