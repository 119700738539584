/* eslint-disable */
import Query from '../../../components/Report';

export const query_view_1 = ({ offset = 0, search }) =>
    new Query('search-urls-urls-list-view-1', {
        offset,
        search: searchUrl(search, 'notEmpty'),
    });

export const query_view_2 = ({ offset = 0, status, last_week, prior_week, search }) =>
    new Query('search-urls-urls-list-view-2', {
        offset,
        status: status && status !== 'all' ? "where status = '" + status + "'" : '',
        last_week,
        prior_week,
        search: searchUrl(search, status),
    });

function searchUrl(search, status) {
    if (!search) return '';
    const firstWord = status && status !== 'all' ? 'and' : 'where';
    return `${firstWord} (url like '% ${search}%' OR url like '%${search} %' OR url like '% ${search} %' OR url like '%${search}%')`;
}
