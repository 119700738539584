import Query from '../../components/Report';
import unique from '../../lib/unique';

const query = ({ offset = 0, domain = '' }) =>
    new Query('discover-recommendations-concepts', {
        filtersMap: (f) => {
            if (f && f.key == 'page') {
                f.key = 'pages';
                f.cmp = 'in';
            }

            return f;
        },
        transformRow: (row) => {
            row.pages = unique(row.pages).length;
            row.ipr = row.impressions / row.pages;

            return row;
        },
        offset,
        domain,
    });

export default query;
