import { Component } from 'react';
import { tags } from './config';

class CommerceTabs extends Component {
    constructor() {
        super();
    }

    createLink(tag) {
        this.url = new URL(window.location);
        this.own_domain = this.url.searchParams.get('own_domain');
        this.intent = this.url.searchParams.get('intent');

        let path = `/commerce/${this.props.project_id}/?keyword=${this.props.keyword}&own_domain=${this.own_domain}&view=${tag.id}`;

        if (this.intent) path += `&intent=${this.intent}`;
        return path;
    }

    render() {
        return (
            <div className="tags-container">
                {tags.map((tag) => (
                    <a className="tab-item extra-styles" key={tag.id} href={this.createLink(tag)}>
                        {tag.label}
                    </a>
                ))}
            </div>
        );
    }
}

export default CommerceTabs;
