import LoadMoreComponent from '../../../../components/LoadMoreComponent';
import { getPercent } from '../../../../helpers/showDelta';
import CheckData from '../../../../components/CatchError';
import DataTable from '../../../../components/Datatable';
import Loading from '../../../../components/Loading';
import { COLUMNS } from './keys';
import query from './query';
import { scrollForTable } from '../../../../components/Datatable/utils';

class GSCReportDirectoryUrls extends LoadMoreComponent {
    constructor(props) {
        super();

        this.query = query({
            offset: 0,
            last_week: props.last_week,
            prior_week: props.prior_week,
            directory: props.directory,
            subdomain: props.subdomain,
        });
        this.offsetStep = 1000;
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) => item.url?.toLowerCase().includes(search.toLowerCase()));
    }

    render() {
        const attr = {
            query,
            offsetStep: this.offsetStep,
            last_week: this.props.last_week,
            prior_week: this.props.prior_week,
            directory: this.props.directory,
            subdomain: this.props.subdomain,
        };

        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const sortFns = {
            url: (array) => array.sort((a, b) => a.url.localeCompare(b.url)),
            keywords: (array) => array.sort((a, b) => a.keywords - b.keywords),
            keywords_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getPercent(a.keywords, a.keywordsPrior) -
                        getPercent(b.keywords, b.keywordsPrior),
                ),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            clicks_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getPercent(a.clicks, a.clicksPrior) - getPercent(b.clicks, b.clicksPrior),
                ),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            impressions_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getPercent(a.impressions, a.impressionsPrior) -
                        getPercent(b.impressions, b.impressionsPrior),
                ),
            position: (array) => array.sort((a, b) => a.position - b.position),
            position_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getPercent(a.position, a.positionPrior) -
                        getPercent(b.position, b.positionPrior),
                ),
            ctr: (array) => array.sort((a, b) => a.ctr - b.ctr),
            ctr_delta: (array) =>
                array.sort((a, b) => getPercent(a.ctr, a.ctrPrior) - getPercent(b.ctr, b.ctrPrior)),
        };

        return (
            <div className="card table-main rounded-main overflow-hidden">
                <div className="col-12">
                    <div className="card-header">
                        <p className="card-title">URLs</p>
                        <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                            Comparing 2 weeks data in the system (Week {this.props.prior_week} to{' '}
                            {this.props.last_week})
                        </small>
                    </div>
                    <div className="card-body">
                        <CheckData
                            content_id={this.props.content_id}
                            parent_id={this.props.parent_id}
                            data={this.state.allData}
                            loading={this.state.loading}
                            loadMore={this.state.loadMore}
                        >
                            <DataTable
                                nodes={this.state.allData}
                                sortFns={sortFns}
                                COLUMNS={COLUMNS}
                                fileName="GSC Reports: Urls"
                                defaultSortKey="clicks"
                                forceRun={this.forceRun}
                                searchFunction={this.searchFunction}
                                additionalStyles={{
                                    Table: `
                                            --data-table-library_grid-template-columns : minmax(200px, 3fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                           ${scrollForTable}
                                        `,
                                    BaseCell: ` font-family: 'Raleway';
                                                font-weight: 500;
                                                font-size: 16px;
                                                line-height: 19px;
                                                padding: 16px 5px !important;
                                                color: #0D182C;
                                                border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                            
                                                &.underline {
                                                    text-decoration: underline;
                                                };
                                                `,
                                }}
                            />
                        </CheckData>
                        {this.state.loadMore ? (
                            <Loading padding={10} />
                        ) : (
                            showLoadMoreBtn && (
                                <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                    <button
                                        onClick={() => this.loadMore(attr)}
                                        disabled={this.state.loading}
                                        className="load-more-btn"
                                    >
                                        Load More
                                    </button>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default GSCReportDirectoryUrls;
