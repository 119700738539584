import React from 'react';
import { deleteClusterItem, editClusterQuery, getClusterItemsQuery } from './query';
import ReactModal from '../Modal';
import Loading from '../Loading';
import Message from '../../modules/Message';

class EditModal extends React.Component {
    constructor(props) {
        super();

        this.handleChange = this.handleChange.bind(this);
        this.onEditClusterName = this.onEditClusterName.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);
        this.getClusterItems = this.getClusterItems.bind(this);
        this.onDelete = this.onDelete.bind(this);

        this.state = {
            value: props.clusterName ?? '',
            isLoading: false,
            isEditLoading: false,
            message: '',
            message_tags: '',
            data: [],
            isDeleteLoading: {},
        };
    }

    componentDidMount() {
        this.getClusterItems();
    }

    handleChange(e) {
        this.setState({ value: e.target.value });
    }

    deleteMessage() {
        this.setState({ message: '' });
    }

    async getClusterItems() {
        try {
            this.setState({ isLoading: true });
            const params = {
                domain_url: window.filters.get().domain.value,
                cluster_name: this.props.clusterName,
                cluster_type: this.props.clusterType,
            };

            const response = await getClusterItemsQuery(
                this.props.endpoint_url,
                params,
                this.props.csrf_token,
            );
            const data = await response.json();

            const clusterData = data?.[this.props.id] ?? [];

            this.setState({ data: clusterData?.[this.props.id], isLoading: false });
        } catch (err) {
            this.setState({ isLoading: false });
            console.log(err);
        }
    }

    async onEditClusterName(e) {
        try {
            e.preventDefault();
            if (!this.state.value) return;

            this.setState({ isEditLoading: true });
            const params = {
                domain_url: window.filters.get().domain.value,
                cluster: this.state.value,
                cluster_type: this.props.clusterType,
                cluster_id: this.props.cluster_id,
            };

            const response = await editClusterQuery(
                this.props.endpoint_url,
                params,
                this.props.csrf_token,
            );
            await response.json();
            this.props.updateClusterName(this.props.cluster_id, this.state.value);
            this.props.onClose();
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({ isEditLoading: false });
        }
    }

    async onDelete(value) {
        try {
            this.setState({ isDeleteLoading: { ...this.state.isDeleteLoading, [value]: true } });
            const params = {
                cluster_id: this.props.cluster_id,
                domain_url: window.filters.get().domain.value,
                cluster_type: this.props.clusterType,
                values: [value],
            };

            const response = await deleteClusterItem(
                this.props.endpoint_url,
                params,
                this.props.csrf_token,
            );
            const data = await response.json();

            let formmatedData = this.state.data;

            if (data?.status < 400) {
                this.props.deleteItem(value, this.props.cluster_id);
                formmatedData = this.state.data.filter((x) => x !== value);
            }

            this.setState({
                data: formmatedData,
                message:
                    data?.message ?? `You have successfully removed the ${this.props.clusterType}.`,
                message_tags: data?.status < 400 ? 'success' : 'error',
            });
        } catch (err) {
            this.setState({
                message: 'Something went wrong. Please try again.',
                message_tags: 'error',
            });
            console.log(err);
        } finally {
            this.setState({ isDeleteLoading: { ...this.state.isDeleteLoading, [value]: false } });
        }
    }

    render() {
        return (
            <>
                <ReactModal modalState={this.props.isOpen} className="width-sm">
                    <h3>Edit Cluster</h3>
                    <form onSubmit={this.onEditClusterName}>
                        <label>Cluster Name</label>
                        <div className="edit-cluster">
                            <input
                                className="form-control"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.value}
                            />
                            <button
                                className="button button-filled button-primary d-flex align-items-center justify-content-center"
                                disabled={this.state.isEditLoading}
                            >
                                <i className="icon-check-icon"></i>
                                {this.props.isEditLoading && (
                                    <span style={{ marginLeft: '5px' }}>
                                        <Loading onlyPreloader />
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                    <div className="edit-cluster-items">
                        {this.props.header && (
                            <div className="edit-cluster-header">{this.props.header}</div>
                        )}
                        {this.state.isLoading ? (
                            <Loading />
                        ) : !this.state.data.length ? (
                            <div>No Item</div>
                        ) : (
                            this.state.data.map((item) => (
                                <div className="edit-cluster-item" key={item}>
                                    <div>{item}</div>
                                    <div>
                                        {this.state.isDeleteLoading[item] ? (
                                            <Loading onlyPreloader />
                                        ) : (
                                            <button
                                                onClick={() => this.onDelete(item)}
                                                title="Delete Keyword"
                                            >
                                                <i className="icon-trashcan text-danger"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                            type="button"
                            className="button button-outline button-primary width-sm btn"
                            onClick={this.props.onClose}
                            style={{ lineHeight: 'unset' }}
                        >
                            Close
                        </button>
                    </div>
                </ReactModal>
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tags}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default EditModal;
