import React from 'react';

function getMessage() {
    let message =
        window.no_data_message ??
        'There is no data yet. If this a new account, it takes 48-72 hours to process historic data. If the problem exists more than 3 days with new accounts, please contact us at info@gddash.com';

    if (
        window.domain_is_created_more_than_3_days !== 'False' &&
        window.domain_has_discover_data_in_gsc === 'False' &&
        window.gsc_device !== 'NONE'
    ) {
        message =
            'There is no Google Discover Data in your account in GSC for this domain. If you believe this is an error, contact us at  info@gddash.com';
    }
    if (window.gsc_device === 'NONE') {
        message = (
            <div>
                Search Reports are disabled as GSC Data Import Settings is set to "None". If you
                would like to get Search data analysis, please change it to mobile or other device
                of your selection
                <a
                    href="/domains/get-user-domains/"
                    style={{ color: '#1F4384', textDecoration: 'underline', margin: '0 5px' }}
                >
                    here.
                </a>{' '}
                If you believe this is an error, contact us at info@gddash.com.
            </div>
        );
    }

    return message;
}

export const noDataMessage = getMessage();

export const shortNoDataMessage = 'There is no data.';
