import Query from '../../../components/Report';

export const query = ({
    offset = 0,
    search,
    first_position = 4,
    second_position = 11,
    weeks = 4,
}) =>
    new Query('search-keywords-striking-distance', {
        offset,
        search: searchKeywords(search, 'notEmpty'),
        first_position,
        second_position,
        weeks: weeks ? `having weeks >= ${weeks}` : '',
    });

function searchKeywords(search, status) {
    if (!search) return '';
    const firstWord = status && status !== 'all' ? 'and' : 'where';
    return `${firstWord} (keyword like '% ${search}%' OR keyword like '%${search} %' OR keyword like '% ${search} %')`;
}
