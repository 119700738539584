import { Component } from 'react';
import HelpBubble from '../../components/HelpBubble';
import AuthorTimeline from '../AuthorTimeline';
import AuthorTopPages from '../AuthorTopPages';
import AuthorEntities from '../AuthorEntities';

class AuthorContainer extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            this.props.plan_type !== 'Basic' &&
            this.props.plan_type !== 'Trial' && (
                <div id="author-page">
                    <div className="row card-row" id="author-timeline">
                        <div className="col-12">
                            <div className="card rounded-main overflow-hidden">
                                <div className="chart-help">
                                    <HelpBubble text={this.props.text_author_entity_timeline} />
                                </div>
                                <div className="card-body">
                                    <AuthorTimeline
                                        content_id="author-timeline"
                                        parent_id="author-page"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row card-row" id="author-pages">
                        <div className="col-12">
                            <div className="card table-main rounded-main overflow-hidden">
                                <div className="card-header">
                                    <div className="card-header-help">
                                        <HelpBubble text={this.props.text_domain_top_pages} />
                                    </div>
                                    <p className="card-title">Pages</p>
                                </div>
                                <div className="card-body">
                                    <AuthorTopPages
                                        default_image={this.props.default_image}
                                        domain={this.props.domain}
                                        author={this.props.author}
                                        content_id="author-pages"
                                        parent_id="author-page"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row card-row" id="author-entities">
                        <div className="col-12">
                            <div className="card table-main rounded-main overflow-hidden">
                                <div className="card-header">
                                    <div className="card-header-help">
                                        <HelpBubble text={this.props.text_domain_top_pages} />
                                    </div>
                                    <p className="card-title">Entities</p>
                                </div>
                                <div className="card-body">
                                    <AuthorEntities
                                        domain={this.props.domain}
                                        author={this.props.author}
                                        content_id="author-entities"
                                        parent_id="author-page"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    }
}
export default AuthorContainer;
