import React from 'react';
import ReactModal from '../../components/Modal';
import Loading from '../../components/Loading';

class ActivateModalDomains extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <>
                <ReactModal modalState={this.props.isOpen} className="width-sm">
                    <p>{this.props.message}</p>
                    <div className="btn-wrapper">
                        <button
                            type="button"
                            className="button button-outline button-primary width-sm btn"
                            onClick={this.props.onClose}
                            style={{ lineHeight: 'unset' }}
                        >
                            {this.props.cancleBtn ?? 'Continue Trial'}
                        </button>

                        {this.props.isButton &&
                            (this.props.isLoadingConfirm ? (
                                <div className="loading-component">
                                    <Loading onlyPreloader padding={10} />
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="button button-filled button-primary width-sm btn"
                                    onClick={this.props.onSubmit}
                                    style={{ lineHeight: 'unset' }}
                                >
                                    {this.props.submitBtn ?? 'Proceed & Activate My Paid Plan'}
                                </button>
                            ))}

                        {!this.props.isButton &&
                            (this.props.checkWithConfirm ? (
                                this.props.canConfirm &&
                                (this.props.isLoadingConfirm ? (
                                    <div className="loading-component">
                                        <Loading onlyPreloader padding={10} />
                                    </div>
                                ) : (
                                    <a
                                        onClick={this.props.onSubmit}
                                        href={this.props.activationLink}
                                        className="button button-filled button-primary width-sm btn"
                                    >
                                        {this.props.submitBtn ?? 'Proceed & Activate My Paid Plan'}
                                    </a>
                                ))
                            ) : (
                                <a
                                    href={this.props.activationLink}
                                    className="button button-filled button-primary width-sm btn"
                                >
                                    {this.props.submitBtn ?? 'Proceed & Activate My Paid Plan'}
                                </a>
                            ))}
                    </div>
                </ReactModal>
            </>
        );
    }
}

export default ActivateModalDomains;
