import Component from '../../components/Component';
import Loading from '../../components/Loading';
import request from '../../lib/request';

import query from './query';
import moment from 'moment';

class Table extends Component {
    constructor(props) {
        super();

        this.mapDates = this.mapDates.bind(this);
        if (!props.existingData) {
            this.query = query;
            this.query.domain = props.domain_url;
        }
    }

    async update(domain, interval, force, e) {
        e.preventDefault();
        this.setState({ loading: true });

        let url = `/domains/json?domain=${domain}&interval=${interval}`;
        url += force ? '&force=true' : '';

        let response = await request(url, {}, { method: 'GET' });
        this.setState({ loading: false });

        return response;
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevState.data) !== JSON.stringify(this.state.data)) {
            this.props.findDates && this.props.findDates(this.state.data, this.props.domain_id);
            this.props.getDomainData &&
                this.props.getDomainData(this.state.data, this.props.domain_id);
            this.props.findMean &&
                this.props.findMean(this.mapDates(this.state.data, 90), this.props.domain_id);
        }
    }

    mapDates(data, days) {
        let dates = [];
        let n = days || 90;

        while (n > 2) {
            let date = moment().add(-n, 'days').format('YYYY-MM-DD');
            let vs = data.filter((e) => {
                return e.date.value == date;
            });
            let v = vs[0] || {};

            dates.push({
                pages: v.pages || 0,
                scraped: v.scraped || 0,
                date,
            });

            n--;
        }

        return dates;
    }

    render() {
        if (
            this.state.loading &&
            (!this.props.existingData || this.props.existingData?.length === 0)
        ) {
            return <Loading />;
        }

        let renderBoxes = (d) => {
            let pColor = 'bg-warning';
            let sColor = 'bg-warning';

            pColor = d.pages > 0 ? 'bg-success' : pColor;
            sColor = d.scraped > 0 ? 'bg-success' : sColor;

            return (
                <div className="data-box" title={d.date} key={d.date}>
                    <div className={pColor + ' p-item data-item'} />
                    <div className={sColor + ' c-item data-item'} />
                </div>
            );
        };
        const data = this.props.existingData ?? this.state.data;
        let dates = this.mapDates(data, 90);

        return (
            <div className="data-box-container">
                <div className="data-boxes">{dates.map(renderBoxes)}</div>
            </div>
        );
    }
}

export default Table;
