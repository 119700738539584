import Query from '../../../../components/Report';

export const query = ({ fromEnd, fromStart, toStart, toEnd }) =>
    new Query('search-algo-comparision-oveallPerfomance', {
        fromEnd,
        fromStart,
        toStart,
        toEnd,
    });

export const chart_query = ({ fromStart, toEnd }) =>
    new Query('search-algo-comparision-oveallPerfomance-chart', {
        fromStart,
        toEnd,
    });
