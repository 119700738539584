import Component from '../../components/Component';
import List from '../../components/List';

import { ConceptsSkeys, celebrityKeys } from './keys';
import query from './query';
import colors from '../../lib/colors';

class Summary extends Component {
    constructor(props) {
        super();
        this.query = query(props.page);
    }

    render() {
        const dataForEntities = this.state.data?.filter((item) => item.wiki);
        const dataForConcepts = this.state.data?.filter((item) => !item.wiki);
        return (
            <div className="row">
                <div className="col-6 col-xs-12" id="top-celebrities">
                    <div
                        className="card rounded-main overflow-hidden"
                        style={{ minHeight: '34vh', height: '100%' }}
                    >
                        <div className="card-header">
                            <p className="card-title m-0">ENTITIES (BY RELEVANCY)</p>
                        </div>
                        <div className="card-body">
                            <List
                                noItems="No people, places or things found for this content."
                                loading={this.state.loading}
                                data={dataForEntities}
                                label={this.props.label || 'Recommended Entities'}
                                keys={celebrityKeys}
                                forceRun={this.forceRun}
                                color={colors[2]}
                                content_id="top-celebrities"
                                direct_parent_id="top-pages"
                                parent_id="page-content"
                                refreshCoustomStyles={{ fontSize: '16px', padding: '5px 7px' }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-6 col-xs-12" id="top-entities">
                    <div
                        className="card rounded-main overflow-hidden"
                        style={{ minHeight: '34vh', height: '100%' }}
                    >
                        <div className="card-header">
                            <p className="card-title m-0">CONCEPTS (Non-Wikipedia Entities)</p>
                        </div>
                        <div className="card-body">
                            <List
                                loading={this.state.loading}
                                data={dataForConcepts}
                                label={this.props.label || 'Recommended Concepts'}
                                keys={ConceptsSkeys}
                                color={colors[3]}
                                forceRun={this.forceRun}
                                content_id="top-entities"
                                direct_parent_id="top-pages"
                                parent_id="page-content"
                                refreshCoustomStyles={{ fontSize: '16px', padding: '5px 7px' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Summary;
