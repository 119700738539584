/* eslint-disable */
import React from 'react';
import DataTable from '../../components/Datatable';
import { scrollForTable } from '../../components/Datatable/utils';
import { COLUMNS } from './keys';
import Loading from '../../components/Loading';
import { query } from './query';
import Message from '../Message';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { getPercentForChart } from '../GSCReports/GSCReportBranded/keys';
import { getRandomColor, labelColors } from '../../lib/colors';
import CheckData from '../../components/CatchError';
import LoadMoreComponent from '../../components/LoadMoreComponent';

ChartJS.register(ArcElement, Tooltip, Legend);
class TrackedEntityReport extends LoadMoreComponent {
    constructor() {
        super();
        this.urlParams = new URLSearchParams(window.location.search);
        this.entity_ids = this.urlParams.get('entity_ids');
        this.language = this.urlParams.get('language');
        this.country = this.urlParams.get('country');
        this.base_domain = this.urlParams.get('base_domain');

        this.offsetStep = 100;

        this.query = query({
            entity_ids: this.entity_ids,
            country: this.country,
            language: this.language,
            base_domain: this.base_domain,
            offset: 0,
        });
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) =>
            item.base_domain?.toLowerCase().includes(search.toLowerCase()),
        );
    }

    render() {
        const attr = {
            query,
            entity_ids: this.entity_ids,
            country: this.country,
            language: this.language,
            base_domain: this.base_domain,
            offsetStep: this.offsetStep,
        };

        const options = (title) => ({
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'right',
                },
                title: {
                    display: true,
                    text: title,
                    padding: {
                        top: 10,
                        bottom: 10,
                    },
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const percent = `${getPercentForChart(
                                context.dataset.data,
                                context.parsed,
                            ).toFixed(2)}%`;
                            return `${context.label} : (${percent})`;
                        },
                    },
                },
            },
        });

        const totalAppearances = this.state.allData?.reduce(
            (acc, curr) => acc + curr.appearances,
            0,
        );
        const averageAppearances =
            this.state.allData?.map((item) => ({
                base_domain: item.base_domain,
                avgDomainAppearances: item.appearances / totalAppearances,
            })) ?? [];
        const filterTill20 = averageAppearances?.reduce((acc, curr, i) => {
            if (i < 10) {
                acc.push(curr);
            } else {
                acc[10] = {
                    base_domain: 'Other',
                    avgDomainAppearances:
                        (acc[20]?.avgDomainAppearances ?? 0) + curr.avgDomainAppearances,
                };
            }
            return acc;
        }, []);

        const videosAppearance =
            this.state.allData?.reduce((acc, curr) => {
                if (curr.hasVideos) {
                    acc.hasVideos = {
                        title: 'Has Videos',
                        appearances: (acc?.hasVideos?.appearances ?? 0) + curr.appearances,
                    };
                } else {
                    acc.dontHaveVideos = {
                        title: "Don't Have Videos",
                        appearances: (acc?.dontHaveVideos?.appearances ?? 0) + curr.appearances,
                    };
                }
                return acc;
            }, {}) ?? {};

        const chartData1 = {
            labels: filterTill20?.map((item) => item.base_domain),
            datasets: [
                {
                    label: 'Domain Share (Appearances)',
                    data: filterTill20?.map((item) => item.avgDomainAppearances),
                    backgroundColor: filterTill20?.map(
                        (item, i) => labelColors[i] ?? getRandomColor(),
                    ),
                },
            ],
        };
        const values = Object.values(videosAppearance);
        const chartData2 = {
            labels: values?.map((item) => item.title),
            datasets: [
                {
                    label: 'Video Share (Appearances)',
                    data: values?.map((item) => item.appearances),
                    backgroundColor: values?.map((item, i) => labelColors[i] ?? getRandomColor()),
                },
            ],
        };

        const sortFns = {
            base_domain: (array) =>
                array.sort((a, b) => a.base_domain.localeCompare(b.base_domain)),
            domainScore: (array) => array.sort((a, b) => a.domainScore - b.domainScore),
            uniqueURLs: (array) => array.sort((a, b) => a.uniqueURLs - b.uniqueURLs),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
            appearances: (array) => array.sort((a, b) => a.appearances - b.appearances),
            hasVideos: (array) => array.sort((a, b) => a.hasVideos - b.hasVideos),
            smallV: (array) => array.sort((a, b) => a.smallV - b.smallV),
            medV: (array) => array.sort((a, b) => a.medV - b.medV),
            largeV: (array) => array.sort((a, b) => a.largeV - b.largeV),
        };
        if (this.state.loading) {
            return (
                <div className="card table-main rounded-main overflow-hidden py-3 ">
                    <div className="card-header d-flex justify-content-between">
                        <p className="card-title">Most Visible Domain</p>
                    </div>
                    <div className="card-body">
                        <Loading />
                    </div>
                </div>
            );
        }
        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        return (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.allData}
                    loading={this.state.loading}
                    loadMore={this.state.loadMore}
                >
                    <div className="card table-main rounded-main overflow-hidden ">
                        <div className="card-header d-flex justify-content-between ">
                            <p className="card-title">Most Visible Domain</p>
                        </div>{' '}
                        <div className="card-body">
                            <div className="row d-flex justify-content-center mb-3">
                                {filterTill20?.length !== 0 && (
                                    <div
                                        className="col-6"
                                        style={{ height: '400px', position: 'relative' }}
                                    >
                                        <Pie
                                            data={chartData1}
                                            options={options('Domain Share (Appearances)')}
                                        />
                                    </div>
                                )}
                                {videosAppearance?.length !== 0 && (
                                    <div
                                        className="col-6"
                                        style={{ height: '400px', position: 'relative' }}
                                    >
                                        {videosAppearance?.length !== 0 && (
                                            <Pie
                                                data={chartData2}
                                                options={options('Video Share (Appearances)')}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="row">
                                <DataTable
                                    nodes={this.state.allData}
                                    sortFns={sortFns}
                                    searchFunction={this.searchFunction}
                                    COLUMNS={COLUMNS(this.props.default_image)}
                                    onSelect={this.onSelect}
                                    forceRun={this.forceRun}
                                    uniqKey={'base_domain'}
                                    defaultSortKey="appearances"
                                    fileName="Most Visible Domain"
                                    isSingleSelect
                                    additionalStyles={{
                                        Table: `
                                --data-table-library_grid-template-columns : minmax(200px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(160px, 1fr) minmax(160px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                        ${scrollForTable}
                                    `,
                                        BaseCell: `  font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                                    }}
                                />
                                {this.state.loadMore ? (
                                    <Loading padding={10} />
                                ) : (
                                    showLoadMoreBtn && (
                                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                            <button
                                                onClick={() => this.loadMore(attr)}
                                                disabled={this.state.loading}
                                                className="load-more-btn"
                                            >
                                                Load More
                                            </button>
                                        </div>
                                    )
                                )}
                            </div>
                            {!this.state.loading && this.state.data?.results?.length === 0 && (
                                <div>No Data</div>
                            )}{' '}
                        </div>{' '}
                    </div>
                    {this.state.message && (
                        <div className="messages">
                            <Message
                                message_tags={'info'}
                                message={this.state.message}
                                deleteMessage={this.deleteMessage}
                            />
                        </div>
                    )}
                </CheckData>
            </>
        );
    }
}

export default TrackedEntityReport;
