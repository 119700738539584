import React, { useState } from 'react';

export default function Search({ defaultValue = '', placeholder, onSubmit = () => {} }) {
    const [value, setValue] = useState(defaultValue);

    const handleSearch = (event) => {
        setValue(event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(value);
    };

    return (
        <form className="dt-search" onSubmit={handleSubmit}>
            <button className="dt-search__button">
                <i className="icon-search-plain"></i>
            </button>
            <div className="dt-search__field">
                <input
                    id="DTSearch"
                    type="text"
                    value={value}
                    onChange={handleSearch}
                    placeholder={placeholder}
                    className="dt-search__input"
                />
            </div>
        </form>
    );
}
