import { Component } from 'react';
import OpportunitiesKeywords from './OpportunitiesKeywords';
import OpportunitiesClusters from './OpportunitiesClusters';

class Opportunities extends Component {
    constructor() {
        super();

        this.changeSelect = this.changeSelect.bind(this);
        this.setClustersFunction = this.setClustersFunction.bind(this);
        this.setKeywordsFunction = this.setKeywordsFunction.bind(this);

        this.state = {
            select: null,
        };
    }

    changeSelect(values) {
        this.setState({
            select: values,
        });
    }

    setClustersFunction(callback) {
        this.setState({
            recallClusters: (values) => callback(values),
        });
    }

    setKeywordsFunction(callback) {
        this.setState({
            recallKeywords: (values) => callback(values),
        });
    }

    render() {
        return (
            <>
                <OpportunitiesKeywords
                    own_domain={this.props.own_domain}
                    keyword={this.props.keyword}
                    updateDatas={this.props.updateDatas}
                    project_id={this.props.project_id}
                    data={this.props.allData}
                    select={this.state.select}
                    changeSelect={this.changeSelect}
                    recallClusters={this.state.recallClusters}
                    setKeywordsFunction={this.setKeywordsFunction}
                />
                <OpportunitiesClusters
                    updateDatas={this.props.updateDatas}
                    updateDomains={this.props.updateDomains}
                    project_id={this.props.project_id}
                    keyword={this.props.keyword}
                    data={this.props.contentGaps}
                    domains_for_select={this.props.domains_for_select}
                    own_domain={this.props.own_domain}
                    competitors={this.props.competitors}
                    commerce_project={this.props.commerce_project}
                    updateQualifieres={this.props.updateQualifieres}
                    csrf_token={this.props.csrf_token}
                    select={this.state.select}
                    changeSelect={this.changeSelect}
                    setClustersFunction={this.setClustersFunction}
                    recallKeywords={this.props.recallKeywords}
                />
            </>
        );
    }
}

export default Opportunities;
