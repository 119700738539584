import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import { dataWithouDomain } from '../../../helpers/collectUrl';
import { collectCell, getPercent } from '../../../helpers/showDelta';
import Sparkline from '../../../components/Sparklines';

export const COLUMNS_VIEW_1 = [
    {
        label: 'Url',
        key: 'url',
        data: 'url',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="table-main d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <strong className="table-url-strong">
                            <div>
                                <a
                                    href={`/domains/${window.info.id}/gsc-reports/urls/url/?url=${data}`}
                                    className="word-break"
                                    title={data}
                                >
                                    {dataWithouDomain(data)}
                                </a>
                            </div>
                        </strong>
                    </div>
                    <div className="d-flex align-items-center">
                        <a
                            target="_blank"
                            className="link-website d-inline-flex align-items-center justify-content-center"
                            href={data}
                            rel="noreferrer"
                        >
                            <i className="icon-external-link"></i>
                        </a>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Keywords',
        key: 'keywords',
        data: 'keywords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Max Clicks',
        key: 'maxClicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Trend',
        key: 'sparkline',
        data: 'sparkline',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return '-';

            return (
                <Sparkline
                    labels={item.dateL.map((x) => x.value)}
                    dataL={item.clicksL}
                    keyName="Clicks"
                />
            );
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Position',
        key: 'avgPosition',
        data: 'avgPosition',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'CTR',
        key: 'avgCTR',
        data: 'avgCTR',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.clicks / item.impressions;
            if (csvDownload) return ctrValue;
            return <strong>{numeral(ctrValue).format('0.00%')}</strong>;
        },
    },
    {
        label: 'Actions',
        key: 'actions',
        data: 'actions',
        Component: Cell,
        renderCell: ({ item }) => {
            const url = `/domains/tools/live-url/?domain=${window.info.domain}&url=${item.url}`;
            return (
                <a style={{ textDecoration: 'underline' }} href={url}>
                    URL Analysis
                </a>
            );
        },
    },
];

export const COLUMNS_VIEW_2 = [
    {
        label: 'Url',
        key: 'url',
        data: 'url',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="table-main d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <strong className="table-url-strong">
                            <div>
                                <a
                                    href={`/domains/${window.info.id}/gsc-reports/urls/url/?url=${data}`}
                                    className="word-break"
                                    title={data}
                                >
                                    {dataWithouDomain(data)}
                                </a>
                            </div>
                        </strong>
                    </div>
                    <div className="d-flex align-items-center">
                        <a
                            target="_blank"
                            className="link-website d-inline-flex align-items-center justify-content-center"
                            href={data}
                            rel="noreferrer"
                        >
                            <i className="icon-external-link"></i>
                        </a>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Keywords',
        key: 'keywords',
        data: 'keywords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'keywords_delta',
        data: 'keywords_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.keywords, item.keywordsPrior);
            return collectCell(item.keywords, item.keywordsPrior);
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'clicks_delta',
        data: 'clicks_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.clicks, item.clicksPrior);
            return collectCell(item.clicks, item.clicksPrior);
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'impressions_delta',
        data: 'impressions_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.impressions, item.impressionsPrior);
            return collectCell(item.impressions, item.impressionsPrior);
        },
    },
    {
        label: 'Position',
        key: 'position',
        data: 'position',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'position_delta',
        data: 'position_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.position, item.positionPrior);
            return collectCell(item.position, item.positionPrior);
        },
    },
    {
        label: 'CTR',
        key: 'ctr',
        data: 'ctr',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.clicks / item.impressions;
            if (csvDownload) return ctrValue;
            return <strong>{numeral(ctrValue).format('0.00%')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'ctr_delta',
        data: 'ctr_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.ctr, item.ctrPrior);
            return collectCell(item.ctr, item.ctrPrior);
        },
    },
    {
        label: 'Actions',
        key: 'actions',
        data: 'actions',
        Component: Cell,
        renderCell: ({ item }) => {
            const url = `/domains/tools/live-url/?domain=${window.info.domain}&url=${item.url}`;
            return (
                <a style={{ textDecoration: 'underline' }} href={url}>
                    URL Analysis
                </a>
            );
        },
    },
];

export const STATUSES = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'New',
        value: 'new',
    },
    {
        label: 'Lost',
        value: 'lost',
    },
    {
        label: 'No Change',
        value: 'no change',
    },
    {
        label: 'Drop',
        value: 'drop',
    },
    {
        label: 'Increase',
        value: 'increase',
    },
];
