import Component from '../../../components/Component';
import Timeline from '../../../components/Timeline';
import Loading from '../../../components/Loading';

import query from './query';
import keys from './keys';

class Chart extends Component {
    constructor(props) {
        super();
        this.query = query(props.country);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.country !== this.props.country) {
            this.query = query(this.props.country);
            this.query.bind(this.setState.bind(this));
            this.query.update();
        }
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        return (
            <Timeline
                dontShowMetrics
                chartSize="lg"
                metricType="primary"
                data={this.state.data}
                error={this.state.error}
                forceRun={this.forceRun}
                keys={keys}
                parent_id={this.props.parent_id}
                content_id={this.props.content_id}
                hasRightAxis
                hasSecondRightAxis
                rightAxisLabel="CTR (%)"
                hasLeftSecondAxis
                leftSecondAxisLabel="Clicks"
                leftAxisLabel="Impressions"
            />
        );
    }
}

export default Chart;
