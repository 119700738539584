import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// export const options = {
//   responsive: true,
//   plugins: {
//
//     title: {
//       display: true,
//       text: 'Chart.js Line Chart',
//     },
//   },
// };
//
// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
//
// export const data = {
//   labels,
//   datasets: [
//     {
//       label: "asdasd",
//       data: [13, 11, 2],
//       backgroundColor: [
//         "rgba(0, 135, 136)",
//         "rgba(0, 193, 189)",
//         "rgba(255, 9, 49)"
//       ],
//       borderColor: [
//         "rgba(0, 135, 136)",
//         "rgba(0, 193, 189)",
//         "rgba(255, 9, 49)"
//       ],
//       borderWidth: 1
//     }
//
//   ]
// };

class NewChart extends React.Component {
    constructor() {
        super();
    }

    render() {
        const data = {
            labels: ['sa', 'asdasd', 'asdd'],
            datasets: [
                {
                    label: '',
                    data: [
                        { x: 10, y: 20 },
                        { x: 15, y: null },
                        { x: 20, y: 10 },
                    ],
                    backgroundColor: ['rgba(0, 135, 136)', 'rgba(0, 193, 189)', 'rgba(255, 9, 49)'],

                    borderColor: ['rgba(0, 135, 136)', 'rgba(0, 193, 189)', 'rgba(255, 9, 49)'],
                    borderWidth: 1,
                },
                {
                    label: '',
                    data: [
                        { x: 40, y: 20 },
                        { x: 55, y: null },
                        { x: 70, y: 10 },
                    ],
                    backgroundColor: ['rgba(0, 135, 136)', 'rgba(0, 193, 189)', 'rgba(255, 9, 49)'],

                    borderColor: ['rgba(0, 135, 136)', 'rgba(0, 193, 189)', 'rgba(255, 9, 49)'],
                    borderWidth: 1,
                },
            ],
        };

        return (
            <>
                <div style={{ height: '400px' }}>
                    <p>teststst</p>
                    <Line data={data} />
                </div>
            </>
        );
    }
}

export default NewChart;
