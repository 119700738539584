import React from 'react';

class Modal extends React.Component {
    constructor(props) {
        super();

        this.state = {
            active: props.active || false,
            data: {
                domain: '',
            },
        };

        this.next = this.next.bind(this);
    }

    toggleActive() {
        this.setState({
            active: !this.state.active,
        });
    }

    updateData(key, e) {
        const newState = { ...this.state };
        newState.data[key] = e.target.val || e.target.value;
        this.setState(newState);
    }

    next(e) {
        e.preventDefault();
        this.props.next && this.props.next(this.state.data);
    }

    render() {
        return (
            <div className="registration info">
                <form onSubmit={this.next}>
                    <div className="mb-3">
                        <h4>Add a Domain to GDDash</h4>
                        <p>
                            Add the domain you&apos;d like to track. Make sure the format matches
                            exactly with the way it is specified in Google Search Console.
                        </p>
                        <input
                            className="form-control"
                            placeholder="https://acme.com/"
                            onChange={this.updateData.bind(this, 'domain')}
                            value={this.state.data.domain}
                        />
                    </div>
                    <div className="align-right">
                        <button className="btn btn-lg btn-primary text-white" type="submit">
                            Next
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

export default Modal;
