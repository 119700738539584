import React from 'react';

export default function Search({ value, setSearch, placeholder }) {
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    return (
        <div className="dt-search">
            <button className="dt-search__button">
                <i className="icon-search-plain"></i>
            </button>
            <div className="dt-search__field">
                <input
                    id="DTSearch"
                    type="text"
                    value={value}
                    onChange={handleSearch}
                    placeholder={placeholder}
                    className="dt-search__input"
                />
            </div>
        </div>
    );
}
