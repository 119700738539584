import { Component } from 'react';
import HelpBubble from '../../components/HelpBubble';
import AuthorTimeline from '../AuthorTimeline';
import AuthorTopPages from '../AuthorTopPages';
import AuthorEntities from '../AuthorEntities';
import { tags } from './tabs';
import AuthorCategories from '../AuthorCategories';

class AuthorContainer extends Component {
    constructor() {
        super();

        this.onClick = this.onClick.bind(this);
        this.isActive = this.isActive.bind(this);
        this.url = new URL(window.location);
        let view = this.url.searchParams.get('view');

        this.state = {
            type: view ?? 'pages',
        };
    }

    onClick(id) {
        this.url.searchParams.set('view', id);
        history.pushState({}, '', this.url);

        return this.setState({
            type: id,
        });
    }

    isActive(id) {
        return this.state.type === id;
    }

    render() {
        return (
            this.props.plan_type !== 'Basic' &&
            this.props.plan_type !== 'Trial' && (
                <div id="author-page">
                    <div className="row card-row" id="author-timeline">
                        <div className="col-12">
                            <div className="card rounded-main overflow-hidden">
                                <div className="chart-help">
                                    <HelpBubble text={this.props.text_author_entity_timeline} />
                                </div>
                                <div className="card-body">
                                    <AuthorTimeline
                                        content_id="author-timeline"
                                        parent_id="author-page"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tags-container">
                        {tags.map((tag) => (
                            <span
                                className={`tab-item ${this.isActive(tag.id) && 'active-tag'}`}
                                key={tag.id}
                                onClick={() => this.onClick(tag.id)}
                            >
                                {tag.label}
                            </span>
                        ))}
                    </div>
                    <div
                        className="row card-row"
                        id="author-pages"
                        style={{
                            display: this.isActive('pages') ? 'flex' : 'none',
                        }}
                    >
                        <div className="col-12">
                            <div className="card table-main rounded-main overflow-hidden">
                                <div className="card-header">
                                    <div className="card-header-help">
                                        <HelpBubble text={this.props.text_domain_top_pages} />
                                    </div>
                                    <p className="card-title">Pages</p>
                                </div>
                                <div className="card-body">
                                    <AuthorTopPages
                                        default_image={this.props.default_image}
                                        domain={this.props.domain}
                                        author={this.props.author}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="row card-row"
                        id="author-categories"
                        style={{
                            display: this.isActive('categories') ? 'flex' : 'none',
                        }}
                    >
                        <div className="col-12">
                            <div className="card table-main rounded-main overflow-hidden">
                                <div className="card-header">
                                    <p className="card-title">Categories</p>
                                </div>
                                <div className="card-body">
                                    <AuthorCategories
                                        domain={this.props.domain}
                                        author={this.props.author}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="row card-row"
                        id="author-entities"
                        style={{
                            display: this.isActive('entities') ? 'flex' : 'none',
                        }}
                    >
                        <div className="col-12">
                            <div className="card table-main rounded-main overflow-hidden">
                                <div className="card-header">
                                    <div className="card-header-help">
                                        <HelpBubble text={this.props.text_domain_top_pages} />
                                    </div>
                                    <p className="card-title">Entities</p>
                                </div>
                                <div className="card-body">
                                    <AuthorEntities
                                        domain={this.props.domain}
                                        author={this.props.author}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    }
}
export default AuthorContainer;
