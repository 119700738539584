import { Cell } from '@table-library/react-table-library/table';
import colors, { labelColors } from '../../../lib/colors';
import numeral from 'numeral';

export const module1Keys = [
    {
        key: 'avgCTR',
        label: 'Avg Ctr',
        color: colors[0],
        render: (data) => numeral(data * 100).format('0.00'),
        labelColor: labelColors[5],
        icon: 'search',
    },
    {
        key: 'keywords',
        label: 'Keywords',
        color: colors[0],
        labelColor: labelColors[0],
        icon: 'pagev-view',
        yAxisID: 'y1',
        type: 'bar',
    },
    {
        key: 'clicks',
        label: 'Clicks',
        color: colors[1],
        labelColor: labelColors[1],
        icon: 'pagev-view',
        yAxisID: 'y3',
        type: 'bar',
        hidden: true,
    },
    {
        key: 'impressions',
        label: 'Impressions',
        color: colors[2],
        labelColor: labelColors[2],
        icon: 'pagev-view',
        yAxisID: 'y1',
        type: 'bar',
        hidden: true,
    },
];

export const tableColumns = [
    {
        label: 'Position',
        key: 'position',
        data: 'position',
        Component: Cell,
    },
    {
        label: 'Keywords',
        key: 'keywords',
        data: 'keywords',
        Component: Cell,
        renderCell: ({ data }) => numeral(data).format('0.0a'),
    },
    {
        label: 'Avg CTR',
        key: 'avgCTR',
        data: 'avgCTR',
        Component: Cell,
        renderCell: ({ item }) => numeral(item.clicks / item.impressions).format('0.00%'),
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data }) => numeral(data).format('0.0a'),
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data }) => numeral(data).format('0.0a'),
    },
];

export function getPercentForChart(allData, oneData) {
    const sumAllData = allData.reduce((acc, curr) => acc + curr, 0);
    const percent = (100 * oneData) / sumAllData;
    return percent;
}
