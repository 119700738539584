import React, { Component } from 'react';
import DataTable from '../../../../components/Datatable';
import { scrollForTable } from '../../../../components/Datatable/utils';
import { COLUMNS } from './keys';

class SearchQueries extends Component {
    constructor() {
        super();
    }

    searchFunction(data, search) {
        return data.filter((item) =>
            item?.keys?.[1]?.toLowerCase()?.includes(search.toLowerCase()),
        );
    }

    shouldComponentUpdate(nextProps) {
        if (JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data)) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const sortFns = {
            name: (array) => array.sort((a, b) => a?.keys?.[0].localeCompare(b?.keys?.[0])),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            position: (array) => array.sort((a, b) => a.position - b.position),
            ctr: (array) =>
                array.sort((a, b) => a.clicks / a.impressions - b.clicks / b.impressions),
        };
        return (
            <div className="row card-row d-flex">
                <div className="col-12">
                    <div className="card rounded-main table-main overflow-hidden ">
                        <div className="card-header">
                            <div className="card-title text-dark fw-bold">
                                Top 1000 Search Queries
                            </div>
                        </div>
                        <div className="card-body">
                            <DataTable
                                nodes={this.props.data}
                                sortFns={sortFns}
                                COLUMNS={COLUMNS}
                                fileName="Search"
                                searchFunction={this.searchFunction}
                                additionalStyles={{
                                    Table: `
                                        --data-table-library_grid-template-columns : minmax(120px, 1fr)  minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                    ${scrollForTable}
                                    `,
                                    BaseCell: `  font-family: 'Raleway';
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                    padding: 16px 5px !important;
                                    color: #0D182C;
                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                
                                    &.underline {
                                        text-decoration: underline;
                                    }
                                    `,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SearchQueries;
