import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import { collectCell, getPercent } from '../../../helpers/showDelta';
import Sparkline from '../../../components/Sparklines';

export const COLUMNS_VIEW_1 = [
    {
        label: 'Keyword',
        key: 'keyword',
        data: 'keyword',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <strong>
                    <a
                        href={`/domains/${
                            window.info.id
                        }/gsc-reports/keywords/keyword?keyword=${encodeURIComponent(data)}`}
                        className="d-inline-flex align-items-cente"
                        rel="noreferrer"
                    >
                        {data}
                    </a>
                </strong>
            );
        },
    },
    {
        label: 'Avg Weekly URLs',
        key: 'avgURLs',
        data: 'avgURLs',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0 a')}</strong>;
        },
    },
    {
        label: 'Max. Clicks',
        key: 'maxClicks',
        data: 'maxClicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Trend',
        key: 'sparkline',
        data: 'sparkline',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return '-';

            return (
                <Sparkline
                    labels={item.dateL.map((x) => x.value)}
                    dataL={item.clicksL}
                    keyName="Clicks"
                />
            );
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Position',
        key: 'avgPosition',
        data: 'avgPosition',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'CTR',
        key: 'avgCTR',
        data: 'avgCTR',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.clicks / item.impressions;
            if (csvDownload) return ctrValue;
            return <strong>{numeral(ctrValue).format('0.00%')}</strong>;
        },
    },
];

export const COLUMNS_VIEW_2 = [
    {
        label: 'Keyword',
        key: 'keyword',
        data: 'keyword',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <strong>
                    <a
                        href={`/domains/${
                            window.info.id
                        }/gsc-reports/keywords/keyword?keyword=${encodeURIComponent(data)}`}
                        className="d-inline-flex align-items-cente"
                        rel="noreferrer"
                    >
                        {data}
                    </a>
                </strong>
            );
        },
    },
    {
        label: 'Avg Weekly URLs',
        key: 'urls',
        data: 'urls',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0 a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'urls_delta',
        data: 'urls_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.urls, item.urlsPrior);
            return collectCell(item.urls, item.urlsPrior);
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'clicks_delta',
        data: 'clicks_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.clicks, item.clicksPrior);
            return collectCell(item.clicks, item.clicksPrior);
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'impressions_delta',
        data: 'impressions_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.impressions, item.impressionsPrior);
            return collectCell(item.impressions, item.impressionsPrior);
        },
    },
    {
        label: 'Position',
        key: 'position',
        data: 'position',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'position_delta',
        data: 'position_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.position, item.positionPrior);
            return collectCell(item.position, item.positionPrior);
        },
    },
    {
        label: 'CTR',
        key: 'ctr',
        data: 'ctr',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.clicks / item.impressions;
            if (csvDownload) return ctrValue;
            return <strong>{numeral(ctrValue).format('0.00%')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'ctr_delta',
        data: 'ctr_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.ctr, item.ctrPrior);
            return collectCell(item.ctr, item.ctrPrior);
        },
    },
];

export const STATUSES = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'New',
        value: 'new',
    },
    {
        label: 'Lost',
        value: 'lost',
    },
    {
        label: 'No Change',
        value: 'no change',
    },
    {
        label: 'Drop',
        value: 'drop',
    },
    {
        label: 'Increase',
        value: 'increase',
    },
];
