export const nodes = [
    {
        id: '2',
        name: 'JavaScript',
        deadline: '2020-03-27T20:00:00.000Z',
        type: 'LEARN',
        isComplete: true,
        nodes: [
            {
                id: '23',
                name: 'Objects',
                deadline: '2020-03-21T20:00:00.000Z',
                type: 'LEARN',
                isComplete: true,
                nodes: [
                    {
                        id: '231',
                        name: 'Object Methods',
                        deadline: '2020-03-19T20:00:00.000Z',
                        type: 'LEARN',
                        isComplete: true,
                        nodes: null,
                    },
                    {
                        id: '232',
                        name: 'Garbage Collection',
                        deadline: '2020-03-20T20:00:00.000Z',
                        type: 'LEARN',
                        isComplete: true,
                        nodes: null,
                    },
                ],
            },
            {
                id: '22',
                name: 'Data Types',
                deadline: '2020-03-19T20:00:00.000Z',
                type: 'LEARN',
                isComplete: true,
                nodes: [
                    {
                        id: '221',
                        name: 'Strings',
                        deadline: '2020-03-17T20:00:00.000Z',
                        type: 'LEARN',
                        isComplete: true,
                        nodes: null,
                    },
                    {
                        id: '222',
                        name: 'Numbers',
                        deadline: '2020-03-18T20:00:00.000Z',
                        type: 'LEARN',
                        isComplete: true,
                        nodes: null,
                    },
                ],
            },
            {
                id: '24',
                name: 'Code Style',
                deadline: '2020-03-22T20:00:00.000Z',
                type: 'LEARN',
                isComplete: true,
                nodes: [],
            },
        ],
    },
    {
        id: '3',
        name: 'React',
        deadline: '2020-04-07T20:00:00.000Z',
        type: 'LEARN',
        isComplete: false,
        nodes: [
            {
                id: '35',
                name: 'State',
                deadline: '2020-06-30T20:00:00.000Z',
                type: 'LEARN',
                isComplete: false,
                nodes: [
                    {
                        id: '351',
                        name: 'Remote State',
                        deadline: '2020-07-31T20:00:00.000Z',
                        type: 'LEARN',
                        isComplete: true,
                        nodes: [],
                    },
                    {
                        id: '352',
                        name: 'Local State',
                        deadline: '2020-07-31T20:00:00.000Z',
                        type: 'LEARN',
                        isComplete: false,
                        nodes: [],
                    },
                ],
            },
            {
                id: '34',
                name: 'Props',
                deadline: '2020-05-31T20:00:00.000Z',
                type: 'LEARN',
                isComplete: false,
                nodes: null,
            },
            {
                id: '32',
                name: 'JSX',
                deadline: '2020-03-31T20:00:00.000Z',
                type: 'LEARN',
                isComplete: true,
                nodes: null,
            },
            {
                id: '31',
                name: 'Create React App',
                deadline: '2020-03-31T20:00:00.000Z',
                type: 'SETUP',
                isComplete: true,
                nodes: null,
            },
            {
                id: '33',
                name: 'Components',
                deadline: '2020-04-30T20:00:00.000Z',
                type: 'LEARN',
                isComplete: false,
                nodes: [],
            },
        ],
    },
    {
        id: '4',
        name: 'Git',
        deadline: '2020-05-27T20:00:00.000Z',
        type: 'SETUP',
        isComplete: false,
        nodes: null,
    },
    {
        id: '5',
        name: 'Node',
        deadline: '2020-06-17T20:00:00.000Z',
        type: 'LEARN',
        isComplete: true,
        nodes: [
            {
                id: '51',
                name: 'Express',
                deadline: '2020-06-09T20:00:00.000Z',
                type: 'LEARN',
                isComplete: false,
                nodes: null,
            },
        ],
    },
    {
        id: '6',
        name: 'GraphQL',
        deadline: '2020-07-29T20:00:00.000Z',
        type: 'LEARN',
        isComplete: false,
        nodes: [
            {
                id: '61',
                name: 'Queries and Mutations',
                deadline: '2020-07-27T20:00:00.000Z',
                type: 'LEARN',
                isComplete: false,
                nodes: [
                    {
                        id: '615',
                        name: 'Variables',
                        deadline: '2020-07-23T20:00:00.000Z',
                        type: 'LEARN',
                        isComplete: false,
                        nodes: null,
                    },
                    {
                        id: '614',
                        name: 'Fragments',
                        deadline: '2020-07-22T20:00:00.000Z',
                        type: 'LEARN',
                        isComplete: false,
                        nodes: [
                            {
                                id: '6141',
                                name: 'Inline Fragments',
                                deadline: '2020-07-22T20:00:00.000Z',
                                type: 'LEARN',
                                isComplete: false,
                                nodes: null,
                            },
                        ],
                    },
                    {
                        id: '611',
                        name: 'Fields',
                        deadline: '2020-07-19T20:00:00.000Z',
                        type: 'LEARN',
                        isComplete: false,
                        nodes: null,
                    },
                    {
                        id: '616',
                        name: 'Directives',
                        deadline: '2020-07-24T20:00:00.000Z',
                        type: 'LEARN',
                        isComplete: false,
                        nodes: null,
                    },
                    {
                        id: '612',
                        name: 'Arguments',
                        deadline: '2020-07-20T20:00:00.000Z',
                        type: 'LEARN',
                        isComplete: false,
                        nodes: null,
                    },
                    {
                        id: '613',
                        name: 'Aliases',
                        deadline: '2020-07-21T20:00:00.000Z',
                        type: 'LEARN',
                        isComplete: false,
                        nodes: null,
                    },
                ],
            },
        ],
    },
];

export const customStyles = {
    HeaderCell: `
        padding: 14px 0;
        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
        display: flex !important;
        align-items: start !important;
        div {
            white-space: pre-wrap !important;
         
        }
        button {
            font-size: 12px;
            font-family: 'Raleway';
            font-weight: 600;
            color: rgba(13, 24, 44, 0.7);
            text-transform: uppercase;
            div{
                white-space: pre-line;
                }
        }
        button * {
            pointer-events: all !important;
        }
    `,
    BaseCell: `
        font-family: 'Raleway';
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        padding: 16px 5px;
        color: #0D182C;
        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
        min-height: 50px;
        &.underline {
        text-decoration: underline;
        }
        a {
        white-space: pre-line;
        }
        // div{
        //     height: 100%;
        //     max-width: 100% !important;
        //     align-items: center;
        //     display: flex;
        // }
        *{
            max-width: 100% !important;
        }
    `,
    Cell: `
        cursor: default;
        min-height: 50px;
        a {
            white-space: pre-line;
        }
        // div{
        //     height: 100%;
        //     max-width: 100% !important;
        //     align-items: center;
        //     display: flex;
        // }
        *{
            max-width: 100% !important;
        }
    `,
    Row: `
        &:nth-of-type(odd) {
        }

        &:nth-of-type(even) {
        }
    `,
    Table: `
        &::-webkit-scrollbar {
            width: 5px;
        }
        
        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 3px rgb(255, 255, 255);
            background: rgb(230, 228, 228);
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
        }
            
        &::-webkit-scrollbar-thumb:hover {
            background: rgb(218, 218, 218); 
            cursor: pointer !important;
        }
    `,
};

export const scrollForTable = `
        max-height: 600px;
        &::-webkit-scrollbar {
            width: 5px;
            height:7px;
        };

        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 3px rgb(255, 255, 255);
            background: rgb(230, 228, 228);
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
        };
            
        &::-webkit-scrollbar-thumb:hover {
            background: rgb(218, 218, 218); 
            cursor: pointer !important;
        };
`;
