import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import { Fragment } from 'react';
import Sparkline from '../../../components/Sparklines';
import { showDifficulty, showIntent, showSerpFeatures } from '../../../helpers/commerce';

export const COLUMNS = (project_id, own_domain) => [
    {
        label: 'Keyword',
        key: 'keyword',
        data: 'keyword',
        sortType: 'string',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <strong className="wripe">
                    {' '}
                    <a
                        href={`/commerce/${project_id}/keyword/${encodeURIComponent(
                            data,
                        )}?own_domain=${own_domain}`}
                        target="_blank"
                        rel="noreferrer"
                        className="d-flex justify-content-between align-items-center"
                    >
                        <span>{data}</span> <span style={{ fontSize: '30px' }}>→</span>
                    </a>
                </strong>
            );
        },
    },
    {
        label: 'Own url',
        key: 'own_url',
        data: 'own_url',
        sortType: 'string',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong className="wripe">{data ?? '-'}</strong>;
        },
    },
    {
        label: 'Search \n Volume',
        key: 'search_volume',
        data: 'search_volume',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col list-col align-left">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Own Position',
        key: 'own_position',
        data: 'own_position',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            if (!data) return '-';

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'CPC',
        key: 'cpc',
        data: 'cpc',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>${data}</strong>
                </div>
            );
        },
    },
    {
        label: 'CPC Competition',
        key: 'competition',
        data: 'competition',
        info: 'The competitive density of advertisers using a given term for their ads. A value of one indicates the highest level of competition',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{data}</strong>
                </div>
            );
        },
    },
    {
        label: 'Serp \n features',
        key: 'keywords_serp_features',
        data: 'keywords_serp_features',
        Component: Cell,
        cellSize: 'minmax(180px, 1.5fr)',
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            const values = showSerpFeatures(data);

            return <div className="d-flex align-center">{values}</div>;
        },
    },
    {
        label: 'Keyword \n difficulty',
        key: 'keyword_difficulty_index',
        data: 'keyword_difficulty_index',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            const dot = showDifficulty(data);
            return (
                <div className="d-flex align-items-center">
                    <strong>{data}</strong>
                    {dot}
                </div>
            );
        },
    },
    {
        label: 'Monthly Trends',
        key: 'trends',
        data: 'trends',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <Sparkline
                    dataL={data?.split(',')?.map((item) => Number(item))}
                    keyName="Trends"
                    dontShowLable={true}
                />
            );
        },
    },
    {
        label: 'Intent',
        key: 'intent',
        data: 'intent',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="d-flex align-center">
                    {showIntent(data)?.map((item, i) => (
                        <Fragment key={i}>{item}</Fragment>
                    ))}
                </div>
            );
        },
    },
    {
        label: 'Number \n of \n results',
        key: 'number_of_results',
        data: 'number_of_results',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
];
