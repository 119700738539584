import Loading from '../../../../components/Loading';

import { query } from './query';
import { COLUMNS } from './keys';
import { getPercent } from '../../../../helpers/showDelta';
import { scrollForTable } from '../../../../components/Datatable/utils';
import ExtraButtons from './extraBtns';
import DataTable from '../../../../components/Datatable';
import { noDataMessage } from '../../../../helpers/noDataMessage';
import Component from '../../../../components/Component';

class StrikingDistance extends Component {
    constructor(props) {
        super();
        if (!props.data)
            this.query = query({
                offset: 0,
                search: null,
            });

        this.changePositions = this.changePositions.bind(this);
        this.getData = this.getData.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) => item.keyword?.toLowerCase().includes(search.toLowerCase()));
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDatas({ key: 'strikingDistance', value: this.state.data });
        }
    }

    async getData({ search, first_position, second_position, weeks }) {
        this.query = query({
            offset: 0,
            search: search ?? this.state.search,
            first_position: first_position ?? this.state.first_position,
            second_position: second_position ?? this.state.second_position,
            weeks: weeks ?? this.state.weeks,
        });
        this.query.bind(this.setState.bind(this));
        this.setState({ allData: [] });

        await this.query.update();
    }

    getTable() {
        return {
            columns: COLUMNS,
            tableStyles: `--data-table-library_grid-template-columns : minmax(200px, 1.2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(150px, 1.5fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important; ${scrollForTable}`,
            sortKey: 'sortFns_1',
            defaultSortKey: 'maxClicks',
            text: null,
        };
    }

    onSearch(value) {
        this.setState({
            search: value,
        });
        this.getData({ search: value });
    }

    changePositions(first_position, second_position) {
        this.getData({ first_position, second_position });
        this.setState({
            first_position,
            second_position,
        });
    }

    render() {
        const data = this.state.data?.length ? this.state.data : this.props.data;
        if ((!data || !data.length) && this.state.loading) return <Loading />;
        if (!data || !data.length)
            return (
                <div>
                    {this.props.message ? (
                        <>
                            {noDataMessage}
                            <div className="redirect-button">
                                <a
                                    className="bg-purple button-close"
                                    href="/customers/change_plan/?without_basic=True"
                                >
                                    Adjust Plan
                                </a>
                            </div>
                        </>
                    ) : (
                        noDataMessage
                    )}
                </div>
            );

        const sortFns = {
            sortFns_2: {
                keyword: (array) => array.sort((a, b) => a.keyword.localeCompare(b.keyword)),
                urls: (array) => array.sort((a, b) => a.urls - b.urls),
                urls_delta: (array) =>
                    array.sort(
                        (a, b) => getPercent(a.urls, a.urlsPrior) - getPercent(b.urls, b.urlsPrior),
                    ),
                clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
                weeks: (array) => array.sort((a, b) => a.weeks - b.weeks),
                clicks_delta: (array) =>
                    array.sort(
                        (a, b) =>
                            getPercent(a.clicks, a.clicksPrior) -
                            getPercent(b.clicks, b.clicksPrior),
                    ),
                impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
                impressions_delta: (array) =>
                    array.sort(
                        (a, b) =>
                            getPercent(a.impressions, a.impressionsPrior) -
                            getPercent(b.impressions, b.impressionsPrior),
                    ),
                position: (array) => array.sort((a, b) => a.position - b.position),
                position_delta: (array) =>
                    array.sort(
                        (a, b) =>
                            getPercent(a.position, a.positionPrior) -
                            getPercent(b.position, b.positionPrior),
                    ),
                ctr: (array) => array.sort((a, b) => a.ctr - b.ctr),
                ctr_delta: (array) =>
                    array.sort(
                        (a, b) => getPercent(a.ctr, a.ctrPrior) - getPercent(b.ctr, b.ctrPrior),
                    ),
            },
            sortFns_1: {
                keyword: (array) => array.sort((a, b) => a.keyword.localeCompare(b.keyword)),
                clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
                impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
                avgURLs: (array) => array.sort((a, b) => a.avgURLs - b.avgURLs),
                weeks: (array) => array.sort((a, b) => a.weeks - b.weeks),
                maxClicks: (array) => array.sort((a, b) => a.maxClicks - b.maxClicks),
                avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
                avgCTR: (array) => array.sort((a, b) => a.avgCTR - b.avgCTR),
            },
        };

        const extraBtns = () => (
            <ExtraButtons
                changePositions={this.changePositions}
                changeWeek={this.changeWeek}
                first_position={this.state.first_position}
                second_position={this.state.second_position}
                weeks={this.state.weeks}
            />
        );

        const { columns, tableStyles, sortKey, defaultSortKey } = this.getTable();
        return (
            <>
                <DataTable
                    nodes={data}
                    sortFns={sortFns[sortKey]}
                    COLUMNS={columns}
                    fileName="Keywords"
                    defaultSortKey={defaultSortKey}
                    searchFunction={this.searchFunction}
                    extraBtns={extraBtns}
                    forceRun={this.forceRun}
                    errorMessage={this.props.message}
                    // customeSearch={customeSearch}
                    additionalStyles={{
                        Table: tableStyles,
                        BaseCell: `  font-family: 'Raleway';
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                    padding: 16px 5px !important;
                                    color: #0D182C;
                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                
                                    &.underline {
                                        text-decoration: underline;
                                    }
                                    `,
                    }}
                />
                <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                    <a
                        className="view-all-btn"
                        href={`/domains/${window.info.id}/gsc-reports/striking-distance/`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        View Full Report
                    </a>
                </div>
            </>
        );
    }
}

export default StrikingDistance;
