import React from 'react';
import ReactModal from '../Modal';

class ImageModal extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <ReactModal modalState={this.props.isOpen} className="width-sm">
                <div className="modal-image-container">
                    <img src={this.props.image} alt="image" loading="lazy" />
                </div>
                <div className="btn-wrapper">
                    <button
                        type="button"
                        className="button button-outline button-primary width-sm btn"
                        onClick={this.props.onClose}
                        style={{ lineHeight: 'unset' }}
                    >
                        Close
                    </button>
                </div>
            </ReactModal>
        );
    }
}

export default ImageModal;
