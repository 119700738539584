import React, { Component } from 'react';

class ReportExplainer extends Component {
    constructor(props) {
        super();

        this.state = { descriptionHidden: props.descriptionHidden ?? true };
        this.handleShowDetails = this.handleShowDetails.bind(this);
    }

    handleShowDetails() {
        this.setState({ descriptionHidden: !this.state.descriptionHidden });
    }

    render() {
        return (
            <div className={'explainer-wrapper'}>
                <div className={'explainer-container'}>
                    <div className={'explainer-main-block'}>
                        <div className={'explainer-main-block-item'}>
                            <img
                                className="logo-symbol"
                                loading="lazy"
                                alt="logo"
                                src="/static/img/logo-symbol.svg"
                            />
                            <p className={'explainer-title'}>{this.props.title}</p>
                        </div>
                        <div className={'explainer-main-block-item'}>
                            <button
                                onClick={this.handleShowDetails}
                                className={'explainer-arrow-btn'}
                            >
                                <i
                                    className={
                                        this.state.descriptionHidden
                                            ? 'icon-enlarge2'
                                            : 'icon-shrink2'
                                    }
                                ></i>
                            </button>
                        </div>
                    </div>
                    <div
                        className={'explainer-additional-block'}
                        hidden={this.state.descriptionHidden}
                    >
                        {this.props.isNotText ? (
                            this.props.textcontent
                        ) : (
                            <p>{this.props.textcontent}</p>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default ReportExplainer;
