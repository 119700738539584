/* eslint-disable */
import colors, { labelColors } from '../../lib/colors';

const keys = [
    {
        key: 'appearances',
        label: 'Appearances',
        color: colors[3],
        labelColor: labelColors[3],
    },
    {
        key: 'market-share',
        label: 'Market Share',
        color: colors[2],
        labelColor: labelColors[2],
        render: (data, item, totalAppearances, totalScore) =>
            (item.appearances / totalAppearances) * 100,
    },
    {
        key: 'avgPosition',
        label: 'Avg. Ranking',
        color: colors[0],
        labelColor: labelColors[0],
        icon: 'search',
        yAxisID: 'y2',
    },
    {
        key: 'domainScore',
        label: 'Visibility',
        color: colors[1],
        labelColor: labelColors[1],
        render: (data, item, totalAppearances, totalScore) => (item.domainScore / totalScore) * 100,
    },
    {
        key: 'uniqueURLs',
        label: 'Daily Unique URLs',
        color: colors[4],
        labelColor: labelColors[4],
    },
];

export default keys;
