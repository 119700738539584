import numeral from 'numeral';
import { Cell } from '@table-library/react-table-library/table';

function createLink(path) {
    const url = new URL(window.location);
    const intent = url.searchParams.get('intent');

    if (intent) path += `&intent=${intent}`;
    return path;
}

export const COLUMNS = (project_id, own_domain, keyword) => [
    {
        label: 'Rank',
        key: 'rank',
        data: 'rank',
        Component: Cell,
        cellSize: 'minmax(80px, 1fr)',
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong className="wripe">#{data}</strong>;
        },
    },
    {
        label: 'URL',
        key: 'url',
        data: 'url',
        sortType: 'string',
        cellSize: 'minmax(220px, 2.2fr)',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="d-flex row justify-content-between w-100">
                    <strong className="col-9 wrap-text">
                        {' '}
                        <a
                            href={createLink(
                                `/commerce/${project_id}/url/?url=${encodeURIComponent(
                                    data,
                                )}&own_domain=${own_domain}&keyword=${keyword}`,
                            )}
                        >
                            <span>{data}</span> <span style={{ fontSize: '30px' }}>→</span>
                        </a>
                    </strong>
                    <div className="col-2 d-flex align-items-center">
                        <a
                            target="_blank"
                            className=" link-website d-inline-flex align-items-center justify-content-center"
                            href={data}
                            rel="noreferrer"
                        >
                            <i className="icon-external-link"></i>
                        </a>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Organic \n Traffic',
        key: 'organicTraffic',
        data: 'organicTraffic',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Organic \n Cost',
        key: 'oganicCost',
        data: 'oganicCost',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Organic \n Keywords',
        key: 'organicKeywords',
        data: 'organicKeywords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Ad Traffic',
        key: 'trends',
        data: 'trends',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Ad Keywords',
        key: 'adTraffic',
        data: 'adTraffic',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Ad Cost',
        key: 'adCost',
        data: 'adCost',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Project Keywords',
        key: 'project_keywords',
        data: 'project_keywords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Project Keywords Search Volume',
        key: 'project_keywords_search_volume',
        data: 'project_keywords_search_volume',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
];
