import { Cell } from '@table-library/react-table-library/table';
import { CellTree } from '@table-library/react-table-library/tree';
import numeral from 'numeral';

export const tags = [
    { id: 'categories', label: 'Categories' },
    { id: 'opportunities', label: 'Opportunities' },
];

export const COLUMNS = () => [
    {
        label: 'Category',
        key: 'level',
        data: 'level',
        className: 'underline',
        Component: CellTree,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return item?.path || data;
            return item?.path ? (
                <a
                    href={
                        `/domains/${window.info.id}/categories/category?categories:in=` +
                        window.encodeURIComponent(item.path)
                    }
                    target="_blank"
                    rel="noreferrer"
                >
                    {data}
                </a>
            ) : (
                data
            );
        },
    },
    {
        label: 'Pages',
        key: 'unique_pages',
        data: 'unique_pages',
        Component: Cell,
        renderCell: ({ data }) => new Set(data).size,
    },
    {
        label: 'Impressions',
        key: 'sum_impressions',
        data: 'sum_impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return numeral(data).format('0.0a');
        },
    },
    {
        key: 'impressions_page',
        label: 'Impressions \n /Page',
        data: 'impressions_page',
        Component: Cell,
        renderCell: ({ item }) => {
            return numeral(item.sum_impressions / item.unique_pages).format('0.0a');
        },
    },
    {
        label: 'Clicks',
        key: 'sum_clicks',
        data: 'sum_clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return numeral(data).format('0.0a');
        },
    },
    {
        label: 'Ctr',
        key: 'avg_ctr',
        data: 'avg_ctr',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return numeral(data).format('0,0%');
        },
    },
    // {
    //     label: 'Strength',
    //     key: 'strength',
    //     data: 'strength',
    //     Component: Cell,
    //     renderCell: ({ item, csvDownload }) => {
    //         if (csvDownload) return getStrength(item, averages);
    //         return getStrength(item, averages, false, images);
    //     },
    // },
];

export const getStrength = (item, averages, isReturnNumber = true, images = {}) => {
    const { avgPages, avgImpressions, avgClicks, avgCTR } = averages;
    if (item.id === 'all_categories' || !item.haveStrength) return isReturnNumber ? 0 : null;

    const pages = new Set(item.pages).size;
    if (pages > avgPages && item.impressions > avgImpressions && item.clicks > avgClicks) {
        return isReturnNumber ? (
            3
        ) : (
            <div className="categories-strength" title="Strong">
                <img src={images.strong} alt="strong" loading="lazy" />
            </div>
        );
    } else if (
        (item.impressions > avgImpressions && item.ctr < avgCTR) ||
        (item.impressions > avgImpressions && pages < avgPages)
    ) {
        return isReturnNumber ? (
            2
        ) : (
            <div className="categories-strength" title="Striking Distance">
                <img src={images.striking_distance} loading="lazy" alt="image_striking_distance" />
            </div>
        );
    } else if (
        pages > avgPages &&
        item.impressions < avgImpressions &&
        item.clicks < avgClicks &&
        item.ctr < avgCTR
    ) {
        return isReturnNumber ? (
            1
        ) : (
            <div className="categories-strength" title="Weak">
                <img src={images.weak} alt="image_weak" loading="lazy" />
            </div>
        );
    }
    return isReturnNumber ? 0 : null;
};

export const opportunitiesFirstColumn = [
    {
        label: 'Category',
        key: 'level',
        data: 'level',
        Component: Cell,
        renderCell: ({ data }) => <strong>{data}</strong>,
    },
    {
        label: 'Impressions',
        key: 'sum_impressions',
        data: 'sum_impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return numeral(data).format('0.0a');
        },
    },
    {
        label: 'Clicks',
        key: 'sum_clicks',
        data: 'sum_clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return numeral(data).format('0.0a');
        },
    },
];

export const additionalFields = [
    {
        label: 'Pages',
        key: 'unique_pages',
        data: 'unique_pages',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return numeral(data).format('0a');
        },
    },
];
