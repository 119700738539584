export default [
    'linear-gradient(81.93deg, #D04B6A 1.86%, #EE9565 100%)',
    'linear-gradient(80deg, #854FB0 1.5%, #DF69B7 100%)',
    'linear-gradient(81.67deg, #3A83B1 1.2%, #47B2E2 98.87%)',
    'linear-gradient(81.92deg, #33AA9D 1.5%, #8EB683 99.5%)',
    '#6ebbe8',
    '#689e41',
    '#bbbab8',
    '#f3e7e5',
];

export const labelColors = [
    '#BE7A6F',
    '#7A6FBE',
    '#1193F0',
    '#8EB683',
    '#848382',
    '#E5B711',
    '#b73b7d',
];

export const commerceColor = ['#D3D3D3', '#DCDCDC', '#C0C0C0', '#808080', '#696969', '#2F4F4F'];

export const getRandomColor = () => {
    var rgb = [];

    for (var i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));

    return 'rgb(' + rgb.join(',') + ')';
};
