import React from 'react';
import ReactModal from '../../components/Modal';

class AuthorModal extends React.Component {
    constructor() {
        super();
    }

    activate() {
        fetch(`{{current_url}}/customers/end-trial/?domain=${window.filters.get().domain.value}`)
            .then((res) => res.json())
            .then(() => {
                window.location.reload();
            })
            .catch((err) => console.log(err));
    }

    render() {
        return (
            <>
                <ReactModal modalState={this.props.isOpen} className="width-sm">
                    <p>
                        {this.props.plan_type === 'Trial'
                            ? 'Individual author analysis is not available in your Trial period. Please activate your subscription to view Each Author Analysis'
                            : 'Individual author analysis is not available in your Basic Plan. Please upgrade to view Each Author Analysis'}
                    </p>
                    <div className="btn-wrapper">
                        <button
                            type="button"
                            className="button button-outline button-primary width-sm btn"
                            onClick={this.props.onClose}
                            style={{ lineHeight: 'unset' }}
                        >
                            Cancel
                        </button>
                        {this.props.plan_type === 'Trial' ? (
                            <button className="button button-filled button-primary width-sm btn">
                                Activate
                            </button>
                        ) : (
                            <a
                                href={'/customers/change_plan/?without_basic=True'}
                                className="button button-filled button-primary width-sm btn"
                            >
                                Upgrade
                            </a>
                        )}
                    </div>
                </ReactModal>
            </>
        );
    }
}

export default AuthorModal;
