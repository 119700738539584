export const getDiscoverResults = (entity, searchType) => {
    let url = `/tools/google-discover-entity?s=${entity}&limit=100&indent=True`;
    if (searchType == 'id') url = url + '&method=kgid';
    return fetch(url);
};

export const saveEntitiy = (data, csrf_token) => {
    return fetch('/tools/google-discover-entity', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const saveEntitiyAdmin = (data, csrf_token) => {
    return fetch('/tools/add-user-entity', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
