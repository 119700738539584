import React from 'react';

import LoadMoreComponent from '../../../components/LoadMoreComponent';
import { query, setEvergreenWeeksQuery } from './query';
import CheckData from '../../../components/CatchError';
import DataTable from '../../../components/Datatable';
import Loading from '../../../components/Loading';
import { COLUMNS, keywordItem } from './keys';
import ExtraButtons from './ExtraBtns';
import { getPercent } from '../../../helpers/showDelta';
import ManualClusterSelect from '../../../components/manualClusterModal';
import ActionsModal from './actionsModal';
import { scrollForTable } from '../../../components/Datatable/utils';

class GSCReportEvergreen extends LoadMoreComponent {
    constructor(props) {
        super();
        this.ignored_urls = JSON.parse(props.ignored_urls)?.map((x) => x.url);
        this.exclude_directories = props.exclude_directories
            ? props.exclude_directories.split(',')
            : [];
        this.include_directories = props.include_directories
            ? props.include_directories.split(',')
            : [];

        this.query = query({
            offset: 0,
            evergreen_weeks: props.evergreen_weeks,
            ignored_urls: this.ignored_urls,
            toggle: true,
            exclude_directories: this.exclude_directories,
            include_directories: this.include_directories,
        });

        const params = new URL(document.location).searchParams;
        const optimization = params.get('optimized');
        if (optimization === 'true' && !this.state.isOptimiz && this.state.isOptimiz !== false) {
            this.state = { ...this.state, isOptimiz: true };
        }

        this.offsetStep = 1000;

        this.onChangeWeeks = this.onChangeWeeks.bind(this);
        this.optimiz = this.optimiz.bind(this);
        this.deleteIgnoredItem = this.deleteIgnoredItem.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.getData = this.getData.bind(this);
        this.onRemoveAllSelected = this.onRemoveAllSelected.bind(this);
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) => item.url?.toLowerCase().includes(search.toLowerCase()));
    }

    getData({ evergreenWeeks, exclude_directories, include_directories, toggle = null }) {
        this.query = query({
            offset: 0,
            evergreen_weeks: evergreenWeeks,
            ignored_urls: this.ignored_urls,
            toggle: toggle ?? this.state.toggle,
            exclude_directories: exclude_directories,
            include_directories: include_directories,
        });
        this.query.bind(this.setState.bind(this));
        this.setState({
            evergreen_weeks: evergreenWeeks,
            exclude_directories,
            include_directories,
            allData: [],
        });
        this.query.update();
    }

    onToggle(value) {
        this.setState({
            toggle: value,
        });
        this.getData({
            evergreen_weeks: this.state.evergreen_weeks || this.props.evergreen_weeks,
            exclude_directories: this.state.exclude_directories || this.exclude_directories,
            include_directories: this.state.include_directories || this.include_directories,
            toggle: value,
        });
    }

    optimiz() {
        this.setState({
            isOptimiz: !this.state.isOptimiz,
        });
    }

    onChangeWeeks(e, evergreenWeeks) {
        e.preventDefault();

        if (!evergreenWeeks) return;

        const params = {
            domain_url: window.filters.get().domain.value,
            evergreen_weeks: evergreenWeeks,
        };

        this.setState({ isLoadingEvergreen: true });

        setEvergreenWeeksQuery(this.props.endpoint_url, params, this.props.csrf_token)
            .then((res) => res.json())
            .then((data) => {
                const evergreen_weeks = data.evergreen_weeks;
                this.getData({ evergreen_weeks });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ isLoadingEvergreen: false });
            });
    }

    deleteIgnoredItem(url) {
        this.setState({
            allData: this.state.allData.filter((x) => x.url !== url),
        });
    }

    onRemoveAllSelected(bool) {
        this.setState({
            removeAllSelected: bool,
            selectedItems: [],
        });
    }

    onSelect(action, state) {
        if (action.type === 'REMOVE_ALL') return this.setState({ selectedItems: [] });
        this.setState({ selectedItems: state.ids, removeAllSelected: false });
    }

    render() {
        const attr = {
            query,
            offsetStep: this.offsetStep,
            evergreen_weeks: this.state.evergreen_weeks || this.props.evergreen_weeks,
            ignored_urls: this.ignored_urls,
            toggle: this.state.toggle ?? true,
        };

        const sortFns = {
            clicksL: (array) =>
                array.sort(
                    (a, b) =>
                        getPercent(a.clicksL.at(-1), a.clicksL[0]) -
                        getPercent(b.clicksL.at(-1), b.clicksL[0]),
                ),
            url: (array) => array.sort((a, b) => a.url.localeCompare(b.url)),
            keyword: (array) => array.sort((a, b) => a.keyword.localeCompare(b.keyword)),
            seen: (array) =>
                array.sort((a, b) => new Date(a.firstSeen?.value) - new Date(b.firstSeen?.value)),
            kw_clicks: (array) => array.sort((a, b) => a.kw_clicks - b.kw_clicks),
            maxClicks: (array) => array.sort((a, b) => a.maxClicks - b.maxClicks),
            avgClicks: (array) => array.sort((a, b) => a.avgClicks - b.avgClicks),
            avgKeywords: (array) => array.sort((a, b) => a.avgKeywords - b.avgKeywords),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            avgCTR: (array) =>
                array.sort((a, b) => a.kw_clicks / a.impressions - b.kw_clicks / b.impressions),
        };

        const extraBtns = () => (
            <ExtraButtons
                onToggle={this.onToggle}
                toggle={this.state.toggle ?? true}
                optimiz={this.optimiz}
                isOptimiz={this.state.isOptimiz}
            />
        );

        let data = [...this.state.allData];
        if (this.state.isOptimiz) {
            data = data
                .map((item) => {
                    const val = getPercent(item?.clicksL?.at(-1), item.clicksL?.[0]);
                    return val <= -25 ? item : null;
                })
                .filter((item) => !!item);
        } else {
            data = [...this.state.allData];
        }

        const showLoadMoreBtn =
            this.state.data?.length === this.offsetStep && !this.state.isLoadingEvergreen;

        const columns = COLUMNS({
            endpoint_url: this.props.endpoint_url,
            csrf_token: this.props.csrf_token,
            deleteIgnoredItem: this.deleteIgnoredItem,
        });
        const customColumnsForExport = [...columns.slice(0, -1), keywordItem];

        return (
            <>
                <div className="card table-main rounded-main overflow-hidden">
                    <div className="col-12">
                        <div className="card-header header-with-options">
                            <div>
                                <p className="card-title">EVERGREEN REPORTS</p>
                                <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                                    Evergreen report highlights all pages that lasted in search for{' '}
                                    {this.state.evergreen_weeks || this.props.evergreen_weeks}{' '}
                                    number of weeks over the past 365 days. The default number of
                                    weeks is 4. You can change it by clicking the gear button below.
                                </small>
                            </div>
                            <div className="actions-btns">
                                <div>
                                    <ManualClusterSelect
                                        current_url={this.props.endpoint_url}
                                        csrf_token={this.props.csrf_token}
                                        selectedItems={this.state.selectedItems?.map((id) => ({
                                            id,
                                            url: this.state.allData.find((item) => item.id === id)
                                                ?.url,
                                        }))}
                                        cluster_type="url"
                                        header="Add To URLs List"
                                        onRemoveAllSelected={this.onRemoveAllSelected}
                                    />
                                </div>
                                <a href="/domains/get-user-domains/">See Ignored Urls</a>
                                <ActionsModal
                                    onChange={this.onChangeWeeks}
                                    header="Evergreen weeks"
                                    inputType="number"
                                    defaultValue={this.props.evergreen_weeks}
                                    endpoint_url={this.props.endpoint_url}
                                    csrf_token={this.props.csrf_token}
                                    getData={this.getData}
                                    exclude_directories={this.exclude_directories}
                                    include_directories={this.include_directories}
                                    loading={this.state.loading || this.state.isLoadingEvergreen}
                                />
                            </div>
                        </div>
                        <div className="card-body">
                            <CheckData
                                content_id={this.props.content_id}
                                parent_id={this.props.parent_id}
                                data={this.state.allData}
                                loading={this.state.loading || this.state.isLoadingEvergreen}
                                loadMore={this.state.loadMore}
                                customCheck
                                customCheckCondition={true}
                            >
                                <DataTable
                                    nodes={data}
                                    sortFns={sortFns}
                                    COLUMNS={columns}
                                    customColumnsForExport={customColumnsForExport}
                                    fileName="Evergreen Reports"
                                    searchFunction={this.searchFunction}
                                    defaultSortKey={'maxClicks'}
                                    extraBtns={extraBtns}
                                    removeAllSelected={this.state.removeAllSelected}
                                    hasSelect={true}
                                    forceRun={this.forceRun}
                                    onSelect={this.onSelect}
                                    additionalStyles={{
                                        Table: `--data-table-library_grid-template-columns :  minmax(60px, 0.5fr) minmax(50px, 1fr) minmax(189px, 3fr) minmax(142px, 1.5fr) minmax(131px, 1fr) minmax(130px, 1fr) minmax(127px, 1fr) minmax(155px, 1.5fr) minmax(116px, 1fr) minmax(102px, 1fr) minmax(131px, 1fr) minmax(80px, 1fr) minmax(97px, 1fr) !important; ${scrollForTable}`,
                                        BaseCell: `  font-family: 'Raleway';
                                                font-weight: 500;
                                                font-size: 14px;
                                                line-height: 19px;
                                                padding: 16px 5px !important;
                                                color: #0D182C;
                                                border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                            
                                                &.underline {
                                                    text-decoration: underline;
                                                }
                                                `,
                                    }}
                                />
                            </CheckData>
                            {this.state.loadMore ? (
                                <Loading padding={10} />
                            ) : (
                                showLoadMoreBtn && (
                                    <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                        <button
                                            onClick={() => this.loadMore(attr)}
                                            disabled={this.state.loading}
                                            className="load-more-btn"
                                        >
                                            Load More
                                        </button>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default GSCReportEvergreen;
