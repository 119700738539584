import Query from '../../../components/Report';

export const query = ({ brandedTerms }) =>
    new Query('search-stats-ctr-per-position', {
        brandedTerms: excludeBrandedTerms(brandedTerms),
    });

function excludeBrandedTerms(values) {
    if (!values || !values.length) return '';

    const brandedTerms = values
        .map(
            (item) =>
                `keyword not like '%${item} %' or keyword not like '% ${item} %' or keyword not like '% ${item}%'`,
        )
        .join(' or ');
    return `and (${brandedTerms})`;
}
