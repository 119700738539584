import Loading from '../../../components/Loading';

import { COLUMNS } from './keys';
import { Component } from 'react';
import CheckData from '../../../components/CatchError';
import DataTable from '../../../components/Datatable';
import { noDataMessage } from '../../../helpers/noDataMessage';
import { linReg } from '../../../lib/reduce';
import { scrollForTable } from '../../../components/Datatable/utils';

class Table extends Component {
    constructor() {
        super();
        this.offsetStep = 100;
    }

    render() {
        const attr = {
            query: this.props.query,
            offsetStep: this.offsetStep,
            domain: this.props.domain,
        };

        const sumImpressions = this.props.allData.reduce((acc, curr) => {
            acc += curr.impressions;
            return acc;
        }, 0);

        const avgImpressions = sumImpressions / this.props.allData.length;
        const filteredData = this.props.getData(this.props.allData, avgImpressions);

        const showLoadMoreBtn = this.props.data?.length === this.offsetStep;

        const sortFns = {
            attr_imp: (array) =>
                array.sort((a, b) => {
                    let a_imp = linReg(a.impressionsL);
                    let b_imp = linReg(b.impressionsL);
                    return a_imp.slope - b_imp.slope;
                }),
            name: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
            type: (array) => array.sort((a, b) => a.type.localeCompare(b.type)),
            pages: (array) => array.sort((a, b) => a.pages - b.pages),
            salience: (array) => array.sort((a, b) => a.salience - b.salience),
            impressionsL: (array) => array.sort((a, b) => a.impressions - b.impressions),
            impressions_per_page: (array) =>
                array.sort((a, b) => a.impressions / a.pages - b.impressions / b.pages),
            ctr: (array) => array.sort((a, b) => a.ctr - b.ctr),
        };

        return !this.props.allData.length ? (
            <div>{noDataMessage}</div>
        ) : (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={filteredData}
                    loading={this.props.loading}
                    loadMore={this.props.isLoadMore}
                >
                    <DataTable
                        nodes={filteredData}
                        sortFns={sortFns}
                        COLUMNS={COLUMNS}
                        fileName="Concepts"
                        forceRun={this.props.forceRun}
                        defaultSize={this.props.defaultCount}
                        defaultSortKey="impressions"
                        additionalStyles={{
                            Table: `
                                        --data-table-library_grid-template-columns :  minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                       ${scrollForTable}
                                    `,
                            BaseCell: `  font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                        text-decoration: underline;
                                        }`,
                        }}
                    />
                </CheckData>
                {this.props.isLoadMore ? (
                    <Loading padding={5} />
                ) : (
                    showLoadMoreBtn && (
                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                            <button
                                onClick={() => this.props.loadMore(attr)}
                                disabled={this.props.loading}
                                className="load-more-btn"
                            >
                                Load More
                            </button>
                        </div>
                    )
                )}
            </>
        );
    }
}

export default Table;
