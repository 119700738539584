import { Component } from 'react';
import AccountsModal from '../../components/Modals/ChangeAccountModal';

class ChangeDomainLinkAddress extends Component {
    constructor() {
        super();

        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);

        this.state = {
            isOpen: false,
            isLoading: true,
        };
    }

    onOpenModal() {
        this.setState({
            isOpen: true,
        });
    }
    onCloseModal() {
        this.setState({
            isOpen: false,
        });
    }

    render() {
        return (
            <div className="d-flex justify-content-end">
                <button
                    className="button button-filled button-primary width-sm btn align-items-center justify-content-center change-domain-like-btn"
                    onClick={this.onOpenModal}
                >
                    Change gsc account
                </button>
                {this.state.isOpen && (
                    <AccountsModal
                        isOpen={this.state.isOpen}
                        onClose={this.onCloseModal}
                        accounts={this.state.accounts}
                        domain_id={this.props.domain_id}
                        domain_name={this.props.domain_name}
                    />
                )}
            </div>
        );
    }
}

export default ChangeDomainLinkAddress;
