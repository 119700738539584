import { Cell } from '@table-library/react-table-library/table';
import colors, { labelColors } from '../../../lib/colors';
import numeral from 'numeral';

export const module1Keys = [
    {
        key: 'position1',
        label: 'Rank #1',
        color: colors[3],
        labelColor: labelColors[3],
        icon: 'clicks',
    },
    {
        key: 'positiontop3',
        label: 'Rank #1-3',
        color: colors[0],
        labelColor: labelColors[0],
        icon: 'search',
    },
    {
        key: 'position4to10',
        label: 'Rank #4-10',
        color: colors[5],
        labelColor: labelColors[5],
        icon: 'pagev-view',
    },
    {
        key: 'position2',
        label: 'Rank Page 2',
        color: colors[1],
        labelColor: labelColors[1],
        icon: 'ctr',
        hidden: true,
    },
    {
        key: 'position3',
        label: 'Rank Page 3',
        color: colors[4],
        labelColor: labelColors[4],
        icon: 'pagev-view',
        hidden: true,
    },
    {
        key: 'position4',
        label: 'Rank Page 4',
        color: colors[2],
        labelColor: labelColors[2],
        icon: 'pagev-view',
        hidden: true,
    },
    {
        key: 'position5',
        label: 'Rank Page 5',
        color: colors[6],
        labelColor: labelColors[6],
        icon: 'pagev-view',
        hidden: true,
    },
];

export const pieChartKeys = [
    {
        keys: [
            { chartKey: 'clicks', key: 'clicks1' },
            { chartKey: 'position', key: 'position1' },
            { chartKey: 'impressions', key: 'impressions1' },
        ],
        label: 'Rank #1',
    },
    {
        keys: [
            { chartKey: 'clicks', key: 'clickstop3' },
            { chartKey: 'position', key: 'positiontop3' },
            { chartKey: 'impressions', key: 'impressionstop3' },
        ],
        label: 'Rank #1-3',
    },
    {
        keys: [
            { chartKey: 'clicks', key: 'clicks4to10' },
            { chartKey: 'position', key: 'position4to10' },
            { chartKey: 'impressions', key: 'impressions4to10' },
        ],
        label: 'Rank #4-10',
    },
    {
        keys: [
            { chartKey: 'clicks', key: 'clicks2' },
            { chartKey: 'position', key: 'position2' },
            { chartKey: 'impressions', key: 'impressions2' },
        ],
        label: 'Rank Page 2',
    },
    {
        keys: [
            { chartKey: 'clicks', key: 'clicks3' },
            { chartKey: 'position', key: 'position3' },
            { chartKey: 'impressions', key: 'impressions3' },
        ],
        label: 'Rank Page 3',
    },
    {
        keys: [
            { chartKey: 'clicks', key: 'clicks4' },
            { chartKey: 'position', key: 'position4' },
            { chartKey: 'impressions', key: 'impressions4' },
        ],
        label: 'Rank Page 4',
    },
    {
        keys: [
            { chartKey: 'clicks', key: 'clicks5' },
            { chartKey: 'position', key: 'position5' },
            { chartKey: 'impressions', key: 'impressions5' },
        ],
        label: 'Rank Page 5',
    },
];

export const tableColumns = [
    {
        label: 'Ranking',
        key: 'label',
        data: 'label',
        Component: Cell,
    },
    {
        label: 'Number of Keywords',
        key: 'position',
        data: 'position',
        Component: Cell,
        renderCell: ({ data }) => numeral(data).format('0.0a'),
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data }) => numeral(data).format('0.0a'),
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data }) => numeral(data).format('0.0a'),
    },
    {
        label: 'Avg CTR',
        key: 'ctr',
        data: 'ctr',
        Component: Cell,
        renderCell: ({ item }) => {
            return numeral(item.clicks / item.impressions).format('0.00%');
        },
    },
];

export function getPercentForChart(allData, oneData) {
    const sumAllData = allData.reduce((acc, curr) => acc + curr, 0);
    const percent = (100 * oneData) / sumAllData;
    return percent;
}
