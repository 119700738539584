import { Component } from 'react';
import Loading from '../../components/Loading';

class ActivateAccountDomains extends Component {
    constructor(props) {
        super();
        this.onSubmit = this.onSubmit.bind(this);
        this.slectAll = this.slectAll.bind(this);
        this.onChange = this.onChange.bind(this);

        this.domains = JSON.parse(props.domains);
        this.all_existing_urls = JSON.parse(props.all_existing_urls);

        this.allExistingDomains = this.domains.filter(
            (item) => !this.all_existing_urls.includes(item.siteUrl),
        );

        this.state = {
            domains: [],
            loading: false,
        };
    }

    onChange(domain) {
        const domains = this.state.domains;
        const newDomains = domains.includes(domain)
            ? domains.filter((item) => item !== domain)
            : [...domains, domain];
        this.setState({
            domains: newDomains,
        });
    }

    onSubmit() {
        if (this.state.domains.length === 0) return;
        this.setState({
            loading: true,
        });
        const a = document.createElement('a');
        a.href = `/domains/link?account=${
            this.props.account_pk
        }&domains_url=${this.state.domains.join(',')}`;
        a.click();
    }

    slectAll() {
        if (this.allExistingDomains.length === this.state.domains.length) {
            this.setState({
                domains: [],
            });
        } else {
            this.setState({
                domains: this.allExistingDomains.map((item) => item.siteUrl),
            });
        }
    }

    render() {
        return (
            <>
                <table className="table select-domain">
                    <tbody>
                        {this.domains?.length > 0 && (
                            <tr>
                                <td>
                                    <input
                                        id="all"
                                        type="checkbox"
                                        onChange={this.slectAll}
                                        checked={
                                            this.allExistingDomains.length ===
                                            this.state.domains.length
                                        }
                                    />
                                    <label htmlFor="all">
                                        <span className="font-style">Select All</span>
                                    </label>
                                </td>
                            </tr>
                        )}
                        {this.domains.map((item) => (
                            <tr key={item.siteUrl}>
                                <td>
                                    <input
                                        id={item.siteUrl}
                                        type="checkbox"
                                        onChange={() => this.onChange(item?.siteUrl)}
                                        checked={
                                            this.all_existing_urls.includes(item?.siteUrl) ||
                                            this.state.domains.includes(item?.siteUrl)
                                        }
                                        disabled={this.all_existing_urls.includes(item?.siteUrl)}
                                    />
                                    <label htmlFor={item.siteUrl}>
                                        <span className="font-style">{item.name}</span>
                                        <span className="font-style">
                                            permission: {item.permissionLevel}
                                        </span>
                                    </label>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="d-flex justify-content-end">
                    <button
                        className="button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center mt-10"
                        disabled={this.state.loading}
                        onClick={this.onSubmit}
                    >
                        {this.state.loading && <Loading onlyPreloader />}
                        Add domain(s)
                    </button>
                </div>
            </>
        );
    }
}

export default ActivateAccountDomains;
