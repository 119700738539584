import React, { Component } from 'react';
import { purchaseQuery } from './query';
import Loading from '../../components/Loading';
import Message from '../Message';
import BuyMoreEntitiesModal from './modal';

class CustomView extends Component {
    constructor() {
        super();

        this.onBuy = this.onBuy.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.state = {
            isOpenModal: false,
            message: '',
            message_tags: '',
            selectedOption: 1,
        };
    }

    openModal() {
        this.setState({
            isOpenModal: true,
        });
    }

    closeModal() {
        this.setState({
            isOpenModal: false,
        });
    }

    deleteMessage() {
        this.setState({ message: '' });
    }

    calculateTotalPrice(selectedAmount) {
        const price = this.props.item.additional_price.find((item, i, arr) => {
            if (i === 0 && selectedAmount > item?.up_to) return false;
            if (i === 0 && selectedAmount <= item?.up_to) return true;
            if (i === arr.length - 1) return true;
            if (selectedAmount > arr[i - 1]?.up_to && selectedAmount <= item.up_to) return true;

            return false;
        })?.unit_amount;

        return price ? price / 100 : 0;
    }

    hasMoreThanThreeDecimals(num) {
        var decimalPart = (num % 1).toString();
        return decimalPart.length > 4;
    }

    toFix(num) {
        return this.hasMoreThanThreeDecimals(num) ? num.toFixed(2) : num;
    }

    async onBuy() {
        try {
            this.setState({
                isLoading: true,
            });
            const params = {
                quantity: Number(this.state.customValue) || this.state.selectedOption,
                object_name: this.props.item.object_name,
            };
            const response = await purchaseQuery(params, this.props.csrf_token);
            this.closeModal();
            const data = await response.json();
            if (data.status === 400) {
                this.setState({
                    message_tags: 'error',
                    message: data.message,
                    isLoading: false,
                });
            } else {
                this.setState({
                    message_tags: 'success',
                    message: data.message ?? 'Your purchase has been successfully completed.',
                    isLoading: false,
                    selectedOption: 1,
                    customValue: 0,
                    openCustomInput: false,
                });
            }
        } catch (err) {
            this.setState({
                message_tags: 'error',
                message: 'Something went wrong',
                isLoading: false,
            });
            console.log(err);
        }
    }

    showName(key) {
        const names = {
            content_analysis: 'Content Analysis',
            commerce_analytics: 'Commerce Analytics',
            entities: 'Entities',
            seats: 'Seats',
        };

        return names[key];
    }

    render() {
        const price = this.calculateTotalPrice(1);

        return (
            <>
                <div>
                    <>
                        <div className="my-10">
                            <strong>Total Price -</strong> $ {this.toFix(price)} /{' '}
                            {this.props.item.current_plan_interval}
                        </div>
                        <small className="d-block card-sub-title text-muted fs-7 py-2">
                            Please note, the {this.showName(this.props.item.object_name)}
                            cost will be added to your {this.props.item?.current_plan_interval}{' '}
                            subscription invoice.
                        </small>
                        <button
                            className="button button-filled button-primary btn d-flex align-items-center justify-content-center mt-10 px-35"
                            onClick={this.openModal}
                            disabled={
                                !this.props.item.additional_price || !price || this.state.isLoading
                            }
                        >
                            Buy
                        </button>
                        {this.state.isLoading && <Loading padding={10} />}
                    </>
                </div>

                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tags}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
                <BuyMoreEntitiesModal
                    object_message={'Additional Coommerce Analytics'}
                    isOpen={this.state.isOpenModal}
                    onClose={this.closeModal}
                    entityCount={1}
                    onSubmit={this.onBuy}
                    totalPrice={this.toFix(price)}
                    period={this.props.item.current_plan_interval}
                />
            </>
        );
    }
}

export default CustomView;
