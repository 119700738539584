export const cancelSubscription = (data, csrf_token, license_id) => {
    return fetch(`/customers/licenses/${license_id}/deactivate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
