import { Component } from 'react';
import Timeline from '../../../../components/Timeline';

import keys from './keys';

class Chart extends Component {
    constructor() {
        super();
    }

    render() {
        const chartData = {
            labels: this.props.selectedItem?.dateL.map((item) => item.value),
            datasets: keys.map((item) => ({
                label: item.label,
                type: item.type || 'line',
                data: this.props.selectedItem[item.key],
                borderColor: item.labelColor,
                backgroundColor: item.labelColor,
                yAxisID: item.yAxisID || 'y',
                hidden: item.hidden || false,
                borderRadius: 4,
                tension: 0.4,
            })),
        };
        return (
            <Timeline
                dontShowMetrics
                chartSize="lg"
                metricType="primary"
                data={chartData?.labels?.map((x) => ({ date: x }))}
                keys={keys}
                chartData={chartData}
                forceRun={this.props.forceRun}
                parent_id={this.props.parent_id}
                content_id={this.props.content_id}
                hasRightAxis
                rightAxisLabel="CTR (%)"
                rightSecondAxisLabel="Positions"
                hasSecondRightAxis
                showGoogleFeeds
                hasLeftSecondAxis
                leftSecondAxisLabel="Clicks"
                leftAxisLabel="Impressions"
            />
        );
    }
}

export default Chart;
