import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';

export const COLUMNS = [
    {
        label: 'Type',
        key: 'type',
        data: 'type',
        Component: Cell,
    },
    {
        label: 'Keywords',
        key: 'keywords',
        data: 'keywords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data).format('0.0a');
        },
    },
    {
        label: '',
        key: 'percent',
        data: 'percent',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data / 100).format('0.00%');
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data).format('0.0a');
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data).format('0.0a');
        },
    },
    {
        label: 'Avg. CTR',
        key: 'ctr',
        data: 'ctr',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.clicks / item.impressions;
            if (csvDownload) return ctrValue;

            return numeral(ctrValue).format('0.00%');
        },
    },
    {
        label: 'Avg. Position',
        key: 'position',
        data: 'position',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data).format('0.0a');
        },
    },
];

export function getPercent(allData, newData) {
    const sumAllData = allData.reduce((acc, curr) => acc + curr.pages, 0);
    const percent = (100 * newData) / sumAllData;
    return percent;
}

export function getPercentForChart(allData, oneData) {
    const sumAllData = allData.reduce((acc, curr) => acc + curr, 0);
    const percent = (100 * oneData) / sumAllData;
    return percent;
}
