import Query from '../../../../../components/Report';

const query = ({ project_id, own_domain, competitors = [], offset = 0, intent_filter = '' }) =>
    new Query('commerce-detail-content-gaps', {
        project_id,
        offset,
        domains: setDomains({ own_domain, competitors }),
        intent_filter: setIntent(intent_filter),
    });

export const getDomains = ({ project_id }) =>
    new Query('commerce-get-domains', {
        project_id,
    });

export default query;

function setDomains({ own_domain, competitors }) {
    const competitorsinQuery = competitors
        ?.map(
            (item, i) =>
                `
            count(case when domain='${item}' then url end) comp_${i}_urls,
            sum(case when domain='${item}' then search_volume end) comp_${i}_sv,
            avg(case when domain='${item}' then position end) comp_${i}_position,
            count(distinct case when domain='${item}' then dck.keyword end) comp_${i}_kws
            `,
        )
        .join(',');
    return `
            count(case when domain='${own_domain}' then url end) own_urls,
            sum(case when domain='${own_domain}' then search_volume end) own_sv,
            avg(case when domain='${own_domain}' then position end) own_position,
            count(distinct case when domain='${own_domain}' then dck.keyword end) own_kws,
        
        ${competitorsinQuery}
    `;
}

function setIntent(intent_filter) {
    if (!intent_filter || intent_filter?.length === 0) return '';

    return `and (${intent_filter?.map((item) => `dck.intent LIKE '%${item}%'`).join(' or ')})`;
}
