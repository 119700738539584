import numeral from 'numeral';
import { Cell } from '@table-library/react-table-library/table';
import { Fragment } from 'react';
import Sparkline from '../../../../../components/Sparklines';
import { showDifficulty, showIntent, showSerpFeatures } from '../../../../../helpers/commerce';

function createLink(path) {
    const url = new URL(window.location);
    const intent = url.searchParams.get('intent');

    if (intent) path += `&intent=${intent}`;
    return path;
}

export const COLUMNS = (project_id, own_domain) => [
    {
        label: 'Keyword',
        key: 'keyword',
        data: 'keyword',
        sortType: 'string',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <strong className="wripe">
                    {' '}
                    <a
                        href={createLink(
                            `/commerce/${project_id}/keyword/${encodeURIComponent(
                                data,
                            )}?own_domain=${own_domain}`,
                        )}
                        target="_blank"
                        rel="noreferrer"
                        // className="d-flex justify-content-between align-items-center"
                        style={{ color: item.position ? 'green' : 'red' }}
                    >
                        <span>{data}</span> <span style={{ fontSize: '30px' }}>→</span>
                    </a>
                </strong>
            );
        },
    },
    {
        label: 'Search \n Volume',
        key: 'search_volume',
        data: 'search_volume',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col list-col align-left">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Own ranking',
        key: 'position',
        data: 'position',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Own URLS',
        key: 'own_urls',
        data: 'own_urls',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'CPC',
        key: 'cpc',
        data: 'cpc',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>${data}</strong>
                </div>
            );
        },
    },
    {
        label: 'Serp \n features',
        key: 'keywords_serp_features',
        data: 'keywords_serp_features',
        Component: Cell,
        cellSize: 'minmax(180px, 2fr)',
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            const values = showSerpFeatures(data);

            return <div className="d-flex align-center">{values}</div>;
        },
    },
    {
        label: 'Keyword \n difficulty',
        key: 'keyword_difficulty_index',
        data: 'keyword_difficulty_index',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            const dot = showDifficulty(data);
            return (
                <div className="d-flex align-items-center">
                    <strong>{data}</strong>
                    {dot}
                </div>
            );
        },
    },
    {
        label: 'Monthly Trends',
        key: 'trends',
        data: 'trends',
        Component: Cell,
        cellSize: 'minmax(180px, 1.5fr)',
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <Sparkline
                    dataL={data?.split(',')?.map((item) => Number(item))}
                    keyName="Trends"
                    dontShowLable={true}
                />
            );
        },
    },
    {
        label: 'Intent',
        key: 'intent',
        data: 'intent',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="d-flex align-center">
                    {showIntent(data)?.map((item, i) => (
                        <Fragment key={i}>{item}</Fragment>
                    ))}
                </div>
            );
        },
    },
];
