import React from 'react';
import Component from '../../components/Component';
import DomainsData from '../DomainsData';

class Container extends Component {
    constructor() {
        super();

        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);

        this.state = {
            isOpen: false,
        };
    }

    onOpenModal() {
        this.setState({ isOpen: true });
    }

    onCloseModal() {
        this.setState({ isOpen: false });
    }

    render() {
        const domains = this.props.domains ? JSON.parse(this.props.domains) : [];
        return domains.length === 0 ? (
            'There is no domain'
        ) : (
            <>
                <div className="domain-information">
                    <div className="domain-main-info">
                        <button className="btn-icon" onClick={this.onOpenModal}>
                            <i className="text-success icon-external-link"></i>
                        </button>
                    </div>
                    <div>
                        {domains.map((domain) => (
                            <div className="domain-information-content" key={domain.id}>
                                <div className="domain-name">
                                    <div>
                                        <small>
                                            {domain.active ? (
                                                <i className="text-success icofont-verification-check"></i>
                                            ) : (
                                                <i className="text-danger icofont-ui-close"></i>
                                            )}
                                        </small>
                                        <a href={`/domains/${domain.id}`}>{domain.url}</a>
                                    </div>
                                </div>
                                <DomainsData
                                    isOpen={this.state.isOpen}
                                    onClose={this.onCloseModal}
                                    domains={domains}
                                    base_url={this.props.base_url}
                                    csrf_token={this.props.csrf_token}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    }
}

export default Container;
