import DataTable from '../../../components/Datatable';
import CheckData from '../../../components/CatchError';
import CommerceFilterIntent from '../../../components/CommerceFilterIntent';
import CommerceTabs from '../../../components/CommerceTabs';
import Component from '../../../components/Component';
import { getSortKeys, setAdditionalStyles } from '../../../helpers/table';
import { COLUMNS } from './keys';
import query from './query';
import { changeIntent } from '../../../helpers/commerce';

class CommerceViewDetailCluster extends Component {
    constructor(props) {
        super();
        this.url = new URL(window.location);
        this.own_domain = this.url.searchParams.get('own_domain');
        this.keyword = this.url.searchParams.get('keyword');

        this.handleSelectChange = this.handleSelectChange.bind(this);

        this.query = query({
            project_id: props.project_id,
            cluster_name: props.cluster_name,
            own_domain: this.own_domain,
        });

        const breadcrumbBox = document.getElementById('breadcrumb-box');
        breadcrumbBox.innerHTML = `<a class="text-primary domain-name" aria-current="page" href="/commerce/">Commerce</a><i class="icon-arrow-right"></i> <a href="#" >Project: ${this.keyword} </a> <i class="icon-arrow-right"></i> <a href="#" >Cluster: ${props.cluster_name}</a>`;
    }

    searchFunction(data, search) {
        return data.filter((item) => item?.keyword?.toLowerCase()?.includes(search.toLowerCase()));
    }

    checkIntent(itemIntent, selectedIntent) {
        for (let i = 0; i < selectedIntent?.length; i++) {
            if (itemIntent.includes(selectedIntent[i])) return true;
        }
        return false;
    }

    async handleSelectChange(values) {
        this.setState({
            select: values,
        });

        changeIntent(values);

        this.query = query({
            project_id: this.props.project_id,
            cluster_name: this.props.cluster_name,
            own_domain: this.own_domain,
            intent_filter: values?.map((item) => item.value),
        });
        this.query.bind(this.setState.bind(this));
        this.query.update();
    }

    render() {
        const extraBtns = () => (
            <CommerceFilterIntent
                select={this.state.select}
                handleSelectChange={this.handleSelectChange}
            />
        );

        return (
            <>
                <CommerceTabs project_id={this.props.project_id} keyword={this.keyword} />
                <div className="card rounded-main table-main">
                    <div
                        className="card-header d-flex justify-content-between"
                        style={{ borderRadius: '10px' }}
                    >
                        <div className="card-title text-dark fw-bold">Own URLs table</div>
                    </div>
                    <div className="card-body">
                        <div>
                            <CheckData data={this.state.data} loading={this.state.loading}>
                                <DataTable
                                    nodes={this.state.data}
                                    sortFns={getSortKeys(COLUMNS())}
                                    COLUMNS={COLUMNS(this.props.project_id, this.own_domain)}
                                    fileName="Project"
                                    searchFunction={this.searchFunction}
                                    uniqKey={'url'}
                                    extraBtns={extraBtns}
                                    defaultSortKey={'position'}
                                    additionalStyles={setAdditionalStyles(COLUMNS())}
                                />
                            </CheckData>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CommerceViewDetailCluster;
