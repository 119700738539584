export const reactivateQuery = async ({
    endpoint_url,
    license_id,
    price_id,
    promotion_code,
    setError,
}) => {
    return fetch(
        `${endpoint_url}/customers/licenses/${license_id}/reactivate/?price_id=${price_id}&promotion_code=${
            promotion_code ?? ''
        }`,
    )
        .then((res) => res.json())
        .then((res) => {
            if (res.status === 200) {
                window.location.reload();
                setError(null);
            }
            setError(res.message);
        })
        .catch((err) => console.log(err));
};
