import CheckData from '../../../../../components/CatchError';
import CommerceFilterIntent from '../../../../../components/CommerceFilterIntent';
import DataTable from '../../../../../components/Datatable';
import LoadMoreComponent from '../../../../../components/LoadMoreComponent';
import Loading from '../../../../../components/Loading';
import { changeIntent } from '../../../../../helpers/commerce';
import { downloadAllCsv } from '../../../../../helpers/downloadAll';
import { sleep } from '../../../../../helpers/sleep';
import { getSortKeys, setAdditionalStyles } from '../../../../../helpers/table';
import SearchVolume from './SearchVolume';
import { COLUMNS } from './keys';
import query from './query';
import { v4 as uuid } from 'uuid';

class Keywords extends LoadMoreComponent {
    constructor(props) {
        super();
        if (!props.data)
            this.query = query({
                project_id: props.project_id,
                domain: props.own_domain,
                offset: 0,
            });

        this.url = new URL(window.location);
        this.search = this.url.searchParams.get('search');
        this.intent = this.url.searchParams.get('intent');

        this.onFilter = this.onFilter.bind(this);
        this.exportAll = this.exportAll.bind(this);
        this.customForceRun = this.customForceRun.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.offsetStep = 1000;

        this.options = [
            { label: 'Commercial', value: 0 },
            { label: 'Informational', value: 1 },
            { label: 'Navigational', value: 2 },
            { label: 'Transactional', value: 3 },
        ];

        const breadcrumbBox = document.getElementById('breadcrumb-box');
        if (breadcrumbBox)
            breadcrumbBox.innerHTML = `<a class="text-primary domain-name" aria-current="page" href="/commerce/">Commerce</a><i class="icon-arrow-right"></i> <a href="#" >Project: ${props.keyword} </a> <i class="icon-arrow-right"></i> <a href="#" >Keywords</a>`;
    }

    searchFunction(data, search) {
        return data.filter((item) => item?.keyword?.toLowerCase()?.includes(search.toLowerCase()));
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDatas({ key: 'allData', value: this.state.data });
        }

        const filters = localStorage.getItem('filters');
        const data = this.state.data?.map((x) => ({ id: uuid(), ...x }));

        if (prevState.filters !== filters && !this.state.loading) {
            return this.setState({ allData: data, filters });
        }
        if (
            JSON.stringify(this.state.data) !== JSON.stringify(prevState.data) &&
            !this.state.loading
        ) {
            this.setState({ allData: [...this.state.allData, ...data] });
            if (this.intent) {
                const filter = this.options.filter((item) => this.intent?.includes(item.value));
                await sleep(0);
                this.onFilter({ select: filter });
            }
        }
    }

    checkIntent(itemIntent, selectedIntent) {
        for (let i = 0; i < selectedIntent?.length; i++) {
            if (itemIntent.includes(selectedIntent[i])) return true;
        }
        return false;
    }

    customForceRun() {
        this.forceRun();
        this.setState({
            allData: [],
            data: null,
        });
        this.props.updateDatas({ key: 'allData', empty: true });
    }

    onFilter({ toggle, select, min_search_volume, max_search_volume }) {
        let data = this.state.allData?.length ? this.state.allData : this.props.data;
        const toggleValue = toggle ?? this.state.toggle;
        const max = max_search_volume ?? this.state.max_search_volume;
        const min = min_search_volume ?? this.state.min_search_volume;

        let selectValue =
            select?.map((item) => item.value) || this.state.select?.map((item) => item.value) || [];

        let sortedValues = [];

        switch (toggleValue) {
            case 'all': {
                sortedValues = data;
                break;
            }
            case 'striking_distance': {
                sortedValues = data.filter((item) => item.position > 4 && item.position <= 10);
                break;
            }
            case 'page_2': {
                sortedValues = data.filter((item) => item.position > 10);
                break;
            }
            case 'long_tail': {
                sortedValues = data.filter((item) => item.keyword?.split(' ')?.length > 3);
                break;
            }
            case 'low_keyword_difficulty': {
                sortedValues = data.filter(
                    (item) =>
                        item.keyword_difficulty_index >= 1 && item.keyword_difficulty_index <= 30,
                );
                break;
            }
            case 'not_ranking': {
                sortedValues = data.filter((item) => !item.position);
                break;
            }
            default:
                sortedValues = data;
        }

        if (selectValue?.length !== 0) {
            sortedValues = sortedValues.filter((item) =>
                this.checkIntent(item.intent, selectValue),
            );
        }

        sortedValues = sortedValues.filter((item) => {
            if (min && max) {
                return item.search_volume >= Number(min) && item.search_volume <= Number(max);
            }

            if (min) {
                return item.search_volume >= Number(min);
            }
            if (max) {
                return item.search_volume <= Number(max);
            }

            return true;
        });

        this.setState({
            toggle: toggleValue,
            sortedValues,
        });
    }

    async exportAll() {
        try {
            let data = this.state.allData?.length ? this.state.allData : this.props.data;
            this.setState({
                exportAllLoading: true,
            });

            downloadAllCsv(data, 'Commerce All Data', COLUMNS(this.props.project_id));
            this.setState({
                exportAllLoading: false,
            });
        } catch (err) {
            this.setState({
                exportAllLoading: false,
            });
            console.log(err);
        }
    }

    handleSelectChange(values) {
        this.setState({
            select: values,
        });
        changeIntent(values);
        this.props.updateDatas({ emptyAll: true });

        this.onFilter({ select: values });
    }

    render() {
        let data = this.state.allData?.length ? this.state.allData : this.props.data;

        data = this.state.sortedValues || data;

        return (
            <>
                <div className="card table-main rounded-main">
                    <div className="card-header">
                        <p className="card-title">Oppoprtunities</p>
                    </div>
                    <div className="card-body">
                        <div className="entity-toggle-container">
                            <div className="toggle-Invalid-items right-border">
                                <span>All</span>
                                <button
                                    className={`switch-button mr-10 ${
                                        !this.state.toggle || this.state.toggle === 'all'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => this.onFilter({ toggle: 'all' })}
                                >
                                    <span className="switch-circle" />
                                </button>
                            </div>
                            <div
                                className="toggle-Invalid-items right-border"
                                title="These are the 2nd level categories that have high median impression per article, continue writing about them"
                            >
                                <span>Striking distance</span>
                                <button
                                    className={`switch-button mr-10 ${
                                        this.state.toggle === 'striking_distance'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() =>
                                        this.onFilter({
                                            toggle: 'striking_distance',
                                        })
                                    }
                                >
                                    <span className="switch-circle" />
                                </button>
                            </div>
                            <div
                                className="toggle-Invalid-items right-border"
                                title="These are the 2nd level categories that have high median impression per article but low number of articles, test writing about them"
                            >
                                <span>Page 2</span>
                                <button
                                    className={`switch-button mr-10 ${
                                        this.state.toggle === 'page_2' ? 'active' : 'not-active'
                                    }`}
                                    onClick={() => this.onFilter({ toggle: 'page_2' })}
                                >
                                    <span className="switch-circle" />
                                </button>
                            </div>
                            <div
                                className="toggle-Invalid-items right-border"
                                title="These are the 2nd level categories that have high median impression per article but low number of articles, test writing about them"
                            >
                                <span>Long tail</span>
                                <button
                                    className={`switch-button mr-10 ${
                                        this.state.toggle === 'long_tail' ? 'active' : 'not-active'
                                    }`}
                                    onClick={() => this.onFilter({ toggle: 'long_tail' })}
                                    // title="Displaying Top entities that have 10 pages or more ordered by 'No. of Pages'"
                                >
                                    <span className="switch-circle" />
                                </button>
                            </div>
                            <div
                                className="toggle-Invalid-items right-border"
                                title="These are the 2nd level categories that have high median impression per article but low number of articles, test writing about them"
                            >
                                <span>Low Keyword Difficulty</span>
                                <button
                                    className={`switch-button mr-10 ${
                                        this.state.toggle === 'low_keyword_difficulty'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() =>
                                        this.onFilter({ toggle: 'low_keyword_difficulty' })
                                    }
                                    // title="Displaying Top entities that have 10 pages or more ordered by 'No. of Pages'"
                                >
                                    <span className="switch-circle" />
                                </button>
                            </div>
                            <div
                                className="toggle-Invalid-items right-border"
                                title="These are the 2nd level categories that have high median impression per article but low number of articles, test writing about them"
                            >
                                <span>Not Ranking</span>
                                <button
                                    className={`switch-button mr-10 ${
                                        this.state.toggle === 'not_ranking'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => this.onFilter({ toggle: 'not_ranking' })}
                                    // title="Displaying Top entities that have 10 pages or more ordered by 'No. of Pages'"
                                >
                                    <span className="switch-circle" />
                                </button>
                            </div>
                        </div>
                        <div className="intent-container">
                            <CommerceFilterIntent
                                select={this.state.select}
                                handleSelectChange={this.handleSelectChange}
                            />
                        </div>
                        <SearchVolume
                            onFilter={this.onFilter}
                            data={data}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
                <div className="card rounded-main table-main">
                    <div
                        className="card-header d-flex justify-content-between"
                        style={{ borderRadius: '10px' }}
                    >
                        <div className="card-title text-dark fw-bold">
                            Keywords: [{this.props.keyword}]
                        </div>
                    </div>
                    <div className="card-body">
                        <div>
                            <div className="d-flex align-items-center">
                                <div className="all-data-dot red mr-10"></div> you are not ranking
                                for this keyword
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="all-data-dot green mr-10"></div> you are ranking for
                                this keyword
                            </div>
                        </div>
                        <div>
                            <CheckData
                                data={data}
                                loading={!data && this.state.loading}
                                loadMore={this.state.loadMore}
                            >
                                <DataTable
                                    nodes={data}
                                    sortFns={getSortKeys(COLUMNS())}
                                    COLUMNS={COLUMNS(this.props.project_id, this.props.own_domain)}
                                    fileName="Top Pages"
                                    forceRun={this.customForceRun}
                                    defaultSortKey={'search_volume'}
                                    searchFunction={this.searchFunction}
                                    additionalStyles={setAdditionalStyles(COLUMNS())}
                                    defaultSearch={this.search}
                                />
                            </CheckData>

                            <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                {this.state.exportAllLoading ? (
                                    <Loading padding={10} />
                                ) : (
                                    <button
                                        onClick={this.exportAll}
                                        disabled={this.state.loading}
                                        className="load-more-btn"
                                    >
                                        Export All
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Keywords;
