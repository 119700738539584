import { Component } from 'react';
import {
    getDiversifyContent,
    getLeverageStrikingDistance,
    getSubCategories,
    getTopCategoriesImpressionsLow,
    getTopLevels,
    getTopLevelsMediaImpressions,
    getTopLevelsMediaImpressionsHiger,
    getTopPerformingCategories,
} from './helpers';
import DataTable from '../../components/Datatable';
import { additionalFields, opportunitiesFirstColumn } from './keys';
import { scrollForTable } from '../../components/Datatable/utils';

class Opportunities extends Component {
    constructor() {
        super();
    }

    render() {
        const topCategories = getTopPerformingCategories(this.props.data);
        const topCategories2ndLevel = getTopLevels(this.props.data, 4);
        const topCategories3rdLevel = getTopLevels(this.props.data, 5);
        const leverageStrikingDistance = getLeverageStrikingDistance(this.props.data);
        const topMediaImpressions = getTopLevelsMediaImpressions(this.props.data, 5);
        const topCategoriesImpressions1stLevel = getTopLevelsMediaImpressionsHiger(
            this.props.data,
            3,
        );
        const topCategoriesImpressions2ndLevel = getTopLevelsMediaImpressionsHiger(
            this.props.data,
            4,
        );
        const topCategoriesImpressions3rdLevel = getTopLevelsMediaImpressionsHiger(
            this.props.data,
            5,
        );
        const topCategoriesImpressions1stLevelLow = getTopCategoriesImpressionsLow(
            this.props.data,
            3,
        );
        const topCategoriesImpressions2ndLevelLow = getTopCategoriesImpressionsLow(
            this.props.data,
            4,
        );
        const topCategoriesImpressions3rdLevelLow = getTopCategoriesImpressionsLow(
            this.props.data,
            5,
        );
        const topDiversifyContent = getDiversifyContent(this.props.data);
        const subCategories = getSubCategories(this.props.data);

        const sortFns = {
            level: (array) => array.sort((a, b) => a.level.localeCompare(b.level)),
            sum_impressions: (array) => array.sort((a, b) => a.sum_impressions - b.sum_impressions),
            sum_clicks: (array) => array.sort((a, b) => a.sum_clicks - b.sum_clicks),
            unique_pages: (array) => array.sort((a, b) => a.unique_pages - b.unique_pages),
        };

        return (
            <>
                <div className="card table-main rounded-main overflow-hidden">
                    <div className="card-header">
                        <p className="card-title">Categories Opportunities</p>
                    </div>
                    <div className="card-header">
                        <p className="card-title">
                            <strong>1. Focus on High-Performing Categories:</strong>
                        </p>
                    </div>
                    <div className="card-body">
                        <ul>
                            {topCategories?.length !== 0 && (
                                <li>
                                    Continue creating content in top-performing categories like
                                    <DataTable
                                        nodes={topCategories}
                                        sortFns={sortFns}
                                        defaultSortKey="sum_impressions"
                                        COLUMNS={opportunitiesFirstColumn}
                                        searchFunction={(data) => data}
                                        onlyTable
                                        additionalStyles={{
                                            Table: `
                                            --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                            ${scrollForTable}
                                        `,
                                            BaseCell: `  font-family: 'Raleway';
                                                    font-weight: 500;
                                                    font-size: 16px;
                                                    line-height: 19px;
                                                    padding: 16px 5px !important;
                                                    color: #0D182C;
                                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                
                                                    &.underline {
                                                        text-decoration: underline;
                                                    }
                                                    `,
                                        }}
                                    />
                                </li>
                            )}

                            {subCategories?.items?.length !== 0 && (
                                <li>
                                    Explore sub-categories within these categories to sustain and
                                    potentially boost traffic.
                                    <DataTable
                                        nodes={subCategories?.items ?? []}
                                        sortFns={sortFns}
                                        defaultSortKey="sum_impressions"
                                        COLUMNS={opportunitiesFirstColumn}
                                        searchFunction={(data) => data}
                                        onlyTable
                                        additionalStyles={{
                                            Table: `
                                            --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                            ${scrollForTable}
                                        `,
                                            BaseCell: `  font-family: 'Raleway';
                                                    font-weight: 500;
                                                    font-size: 16px;
                                                    line-height: 19px;
                                                    padding: 16px 5px !important;
                                                    color: #0D182C;
                                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                
                                                    &.underline {
                                                        text-decoration: underline;
                                                    }
                                                    `,
                                        }}
                                    />
                                </li>
                            )}
                            {topCategories2ndLevel?.length !== 0 && (
                                <li>
                                    Continue creating content in top-performing 2nd level categories
                                    like
                                    <DataTable
                                        nodes={topCategories2ndLevel}
                                        sortFns={sortFns}
                                        defaultSortKey="sum_impressions"
                                        COLUMNS={opportunitiesFirstColumn}
                                        searchFunction={(data) => data}
                                        onlyTable
                                        additionalStyles={{
                                            Table: `
                                            --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                            ${scrollForTable}
                                        `,
                                            BaseCell: `  font-family: 'Raleway';
                                                    font-weight: 500;
                                                    font-size: 16px;
                                                    line-height: 19px;
                                                    padding: 16px 5px !important;
                                                    color: #0D182C;
                                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                
                                                    &.underline {
                                                        text-decoration: underline;
                                                    }
                                                    `,
                                        }}
                                    />
                                </li>
                            )}
                            {topCategories3rdLevel?.length !== 0 && (
                                <li>
                                    Continue creating content in top-performing 3rd level categories
                                    like
                                    <DataTable
                                        nodes={topCategories3rdLevel}
                                        sortFns={sortFns}
                                        defaultSortKey="sum_impressions"
                                        COLUMNS={opportunitiesFirstColumn}
                                        searchFunction={(data) => data}
                                        onlyTable
                                        additionalStyles={{
                                            Table: `
                                            --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                            ${scrollForTable}
                                        `,
                                            BaseCell: `  font-family: 'Raleway';
                                                    font-weight: 500;
                                                    font-size: 16px;
                                                    line-height: 19px;
                                                    padding: 16px 5px !important;
                                                    color: #0D182C;
                                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                
                                                    &.underline {
                                                        text-decoration: underline;
                                                    }
                                                    `,
                                        }}
                                    />
                                </li>
                            )}
                            {topDiversifyContent?.items?.length !== 0 && (
                                <li>
                                    Diversify content within your top successful category: For
                                    example, within the{' '}
                                    <strong>{topDiversifyContent.topCategory?.level}</strong>{' '}
                                    category, explore more sub-categories like
                                    <DataTable
                                        nodes={topDiversifyContent?.items ?? []}
                                        sortFns={sortFns}
                                        defaultSortKey="sum_impressions"
                                        COLUMNS={opportunitiesFirstColumn}
                                        searchFunction={(data) => data}
                                        onlyTable
                                        additionalStyles={{
                                            Table: `
                                                --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                                ${scrollForTable}
                                            `,
                                            BaseCell: `  font-family: 'Raleway';
                                                        font-weight: 500;
                                                        font-size: 16px;
                                                        line-height: 19px;
                                                        padding: 16px 5px !important;
                                                        color: #0D182C;
                                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                    
                                                        &.underline {
                                                            text-decoration: underline;
                                                        }
                                                        `,
                                        }}
                                    />
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="card table-main rounded-main overflow-hidden">
                    <div className="card-header">
                        <p className="card-title">
                            <strong>2. Leverage Striking Distance Categories:</strong>
                        </p>
                    </div>
                    <div className="card-body">
                        <ul>
                            {leverageStrikingDistance?.length !== 0 && (
                                <li>
                                    Explore top level categories that need additional content
                                    production like
                                    <DataTable
                                        nodes={leverageStrikingDistance}
                                        sortFns={sortFns}
                                        defaultSortKey="sum_impressions"
                                        COLUMNS={[...opportunitiesFirstColumn, ...additionalFields]}
                                        searchFunction={(data) => data}
                                        onlyTable
                                        additionalStyles={{
                                            Table: `
                                            --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                            ${scrollForTable}
                                        `,
                                            BaseCell: `  font-family: 'Raleway';
                                                    font-weight: 500;
                                                    font-size: 16px;
                                                    line-height: 19px;
                                                    padding: 16px 5px !important;
                                                    color: #0D182C;
                                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                
                                                    &.underline {
                                                        text-decoration: underline;
                                                    }
                                                    `,
                                        }}
                                    />
                                </li>
                            )}
                            {topMediaImpressions?.length !== 0 && (
                                <li>
                                    Expand creating more content in moderate-performing 3rd level
                                    categories like
                                    <DataTable
                                        nodes={topMediaImpressions}
                                        sortFns={sortFns}
                                        defaultSortKey="sum_impressions"
                                        COLUMNS={[...opportunitiesFirstColumn, ...additionalFields]}
                                        searchFunction={(data) => data}
                                        onlyTable
                                        additionalStyles={{
                                            Table: `
                                            --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                            ${scrollForTable}
                                        `,
                                            BaseCell: `  font-family: 'Raleway';
                                                    font-weight: 500;
                                                    font-size: 16px;
                                                    line-height: 19px;
                                                    padding: 16px 5px !important;
                                                    color: #0D182C;
                                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                
                                                    &.underline {
                                                        text-decoration: underline;
                                                    }
                                                    `,
                                        }}
                                    />
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="card table-main rounded-main overflow-hidden">
                    <div className="card-header">
                        <p className="card-title">
                            <strong>3. Most Important Categories</strong>
                        </p>
                    </div>
                    <div className="card-body">
                        <div>
                            <strong>Top Categories</strong>
                        </div>
                        <ul>
                            {topCategoriesImpressions1stLevel?.length !== 0 && (
                                <li>
                                    First Level Categories
                                    <DataTable
                                        nodes={topCategoriesImpressions1stLevel}
                                        sortFns={sortFns}
                                        defaultSortKey="sum_impressions"
                                        COLUMNS={opportunitiesFirstColumn}
                                        searchFunction={(data) => data}
                                        onlyTable
                                        additionalStyles={{
                                            Table: `
                                            --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                            ${scrollForTable}
                                        `,
                                            BaseCell: `  font-family: 'Raleway';
                                                    font-weight: 500;
                                                    font-size: 16px;
                                                    line-height: 19px;
                                                    padding: 16px 5px !important;
                                                    color: #0D182C;
                                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                
                                                    &.underline {
                                                        text-decoration: underline;
                                                    }
                                                    `,
                                        }}
                                    />
                                </li>
                            )}
                            {topCategoriesImpressions2ndLevel?.length !== 0 && (
                                <li>
                                    Second Level Categories
                                    <DataTable
                                        nodes={topCategoriesImpressions2ndLevel}
                                        sortFns={sortFns}
                                        defaultSortKey="sum_impressions"
                                        COLUMNS={opportunitiesFirstColumn}
                                        searchFunction={(data) => data}
                                        onlyTable
                                        additionalStyles={{
                                            Table: `
                                            --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                            ${scrollForTable}
                                        `,
                                            BaseCell: `  font-family: 'Raleway';
                                                    font-weight: 500;
                                                    font-size: 16px;
                                                    line-height: 19px;
                                                    padding: 16px 5px !important;
                                                    color: #0D182C;
                                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                
                                                    &.underline {
                                                        text-decoration: underline;
                                                    }
                                                    `,
                                        }}
                                    />
                                </li>
                            )}
                            {topCategoriesImpressions3rdLevel?.length !== 0 && (
                                <li>
                                    Third Level Categories
                                    <DataTable
                                        nodes={topCategoriesImpressions3rdLevel}
                                        sortFns={sortFns}
                                        COLUMNS={opportunitiesFirstColumn}
                                        searchFunction={(data) => data}
                                        onlyTable
                                        defaultSortKey="sum_impressions"
                                        additionalStyles={{
                                            Table: `
                                            --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                            ${scrollForTable}
                                        `,
                                            BaseCell: `  font-family: 'Raleway';
                                                    font-weight: 500;
                                                    font-size: 16px;
                                                    line-height: 19px;
                                                    padding: 16px 5px !important;
                                                    color: #0D182C;
                                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                
                                                    &.underline {
                                                        text-decoration: underline;
                                                    }
                                                    `,
                                        }}
                                    />
                                </li>
                            )}
                        </ul>
                        <div>
                            <strong>Striking Distance</strong>
                        </div>
                        <ul>
                            {topCategoriesImpressions1stLevelLow?.length !== 0 && (
                                <li>
                                    First level striking distance top level categories:
                                    <DataTable
                                        nodes={topCategoriesImpressions1stLevelLow}
                                        sortFns={sortFns}
                                        COLUMNS={opportunitiesFirstColumn}
                                        searchFunction={(data) => data}
                                        onlyTable
                                        defaultSortKey="sum_impressions"
                                        additionalStyles={{
                                            Table: `
                                            --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                            ${scrollForTable}
                                        `,
                                            BaseCell: `  font-family: 'Raleway';
                                                    font-weight: 500;
                                                    font-size: 16px;
                                                    line-height: 19px;
                                                    padding: 16px 5px !important;
                                                    color: #0D182C;
                                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                
                                                    &.underline {
                                                        text-decoration: underline;
                                                    }
                                                    `,
                                        }}
                                    />
                                </li>
                            )}
                            {topCategoriesImpressions2ndLevelLow?.length !== 0 && (
                                <li>
                                    Second level striking distance top level categories:
                                    <DataTable
                                        nodes={topCategoriesImpressions2ndLevelLow}
                                        sortFns={sortFns}
                                        COLUMNS={opportunitiesFirstColumn}
                                        searchFunction={(data) => data}
                                        onlyTable
                                        defaultSortKey="sum_impressions"
                                        additionalStyles={{
                                            Table: `
                                            --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                            ${scrollForTable}
                                        `,
                                            BaseCell: `  font-family: 'Raleway';
                                                    font-weight: 500;
                                                    font-size: 16px;
                                                    line-height: 19px;
                                                    padding: 16px 5px !important;
                                                    color: #0D182C;
                                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                
                                                    &.underline {
                                                        text-decoration: underline;
                                                    }
                                                    `,
                                        }}
                                    />
                                </li>
                            )}
                            {topCategoriesImpressions3rdLevelLow?.length !== 0 && (
                                <li>
                                    Third level categories that are striking distance:
                                    <DataTable
                                        nodes={topCategoriesImpressions3rdLevelLow}
                                        sortFns={sortFns}
                                        COLUMNS={opportunitiesFirstColumn}
                                        searchFunction={(data) => data}
                                        onlyTable
                                        defaultSortKey="sum_impressions"
                                        additionalStyles={{
                                            Table: `
                                            --data-table-library_grid-template-columns :  minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                            ${scrollForTable}
                                        `,
                                            BaseCell: `  font-family: 'Raleway';
                                                    font-weight: 500;
                                                    font-size: 16px;
                                                    line-height: 19px;
                                                    padding: 16px 5px !important;
                                                    color: #0D182C;
                                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                
                                                    &.underline {
                                                        text-decoration: underline;
                                                    }
                                                    `,
                                        }}
                                    />
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

export default Opportunities;
