import React, { Component } from 'react';
import ReactivateModal from './modal';

class Reactivate extends Component {
    constructor() {
        super();

        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);

        this.state = {
            isOpen: false,
        };
    }

    onOpen() {
        this.setState({ isOpen: true });
    }

    onClose() {
        this.setState({ isOpen: false });
    }

    render() {
        return (
            <>
                <button className={this.props.classname} onClick={this.onOpen}>
                    Reactivate
                </button>
                <ReactivateModal
                    isOpen={this.state.isOpen}
                    onClose={this.onClose}
                    plan_intervals={JSON.parse(this.props.plan_intervals)}
                    plan_names={JSON.parse(this.props.plan_names)}
                    pl={JSON.parse(this.props.pl)}
                    endpoint_url={this.props.endpoint_url}
                    license_id={this.props.license_id}
                />
            </>
        );
    }
}

export default Reactivate;
