import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';

export const COLUMNS = (default_image) => [
    {
        label: 'Page',
        key: 'page',
        data: 'page',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            let title = item.title || item.page;

            if (csvDownload) return title;

            return (
                <div>
                    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <div
                            style={{
                                float: 'left',
                                marginRight: '10px',
                                marginBottom: '10px',
                                width: '100px',
                            }}
                        >
                            <img
                                onError={(event) => (event.target.src = default_image)}
                                src={item.image ?? default_image}
                                style={{ width: '100%', minHeight: '60px', background: '#BBB' }}
                                loading="lazy"
                            />
                        </div>
                        <strong>
                            <a
                                href={
                                    `/domains/${window.info.id}/pages/page?page=` +
                                    encodeURIComponent(item.page) +
                                    '&last60=true'
                                }
                            >
                                {title.length > 64 ? title.substr(0, 64) + '...' : title}
                            </a>
                        </strong>
                        <div>
                            <small>
                                <a href={item.page}>{item.page.substr(0, 45) + '...'}</a>
                            </small>
                        </div>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Lifespan',
        key: 'days',
        data: 'days',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0,00')} Days</strong>;
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Published',
        key: 'published',
        data: 'published',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return item.published.value;

            return <strong>{item.published.value}</strong>;
        },
    },
    {
        label: 'Last Modified',
        key: 'last_modified',
        data: 'last_modified',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return item.modified.value;

            return <strong>{item.modified.value}</strong>;
        },
    },
    {
        label: 'Clicks Change',
        key: 'clicks_change',
        data: 'clicks_change',
        Component: Cell,
        renderCell: ({ data }) => {
            return showWithArrow(data);
        },
    },
    {
        label: 'Impressions Δ',
        key: 'impressions_change',
        data: 'impressions_change',
        Component: Cell,
        renderCell: ({ data }) => {
            return showWithArrow(data);
        },
    },
];

function showWithArrow(val) {
    const dir = val > 0 ? 'up' : 'down';

    const arrow =
        dir == 'up' ? (
            <i className="icofont-dotted-up"></i>
        ) : (
            <i className="icofont-dotted-down"></i>
        );

    return (
        <div className="extra-options">
            <div className="list-item d-flex align-items-center">
                <span className={'change ' + dir}>
                    {arrow} {numeral(Math.abs(val)).format('0a%')}
                </span>
            </div>
        </div>
    );
}
