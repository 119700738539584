import React from 'react';
import ReactModal from '../Modal';

class GoogleFeedsModal extends React.Component {
    constructor() {
        super();
        this.showReferances = this.showReferances.bind(this);
    }

    getReferences(option) {
        try {
            const references = JSON.parse(option.replace(/"/g, '').replace(/'/g, '"'));
            return references;
        } catch (err) {
            return option;
        }
    }

    showReferances(item) {
        const references = this.getReferences(item?.references);
        return Array.isArray(references) ? (
            references.map((state, index, arr) => (
                <a
                    href={state}
                    target="_blank"
                    rel="noreferrer"
                    className="reference-link"
                    key={index}
                >
                    Reference {index + 1} {index !== arr.length - 1 && ','}
                </a>
            ))
        ) : (
            <a href={references} target="_blank" rel="noreferrer" className="reference-link">
                Reference 1
            </a>
        );
    }

    render() {
        if (!this.props.items || !this.props.items?.[0]) return null;

        return (
            <>
                <ReactModal modalState={this.props.isOpen} className="width-sm">
                    <div className="google-feeds-container">
                        {this.props.items.map((item) => (
                            <div className="google-feeds-container-item" key={item.place}>
                                <div>
                                    <strong>Feed source</strong> {item.feed ?? '-'}
                                </div>
                                <div>
                                    <strong>Date</strong> {item.date ?? '-'}
                                </div>
                                <div>
                                    <strong>Date Completed</strong> {item.date_completed ?? '-'}
                                </div>
                                <div>
                                    <strong>Confirmed</strong> {item.confirmed ?? '-'}
                                </div>
                                <div>
                                    <strong>References</strong>
                                    {this.showReferances(item)}
                                </div>
                                <div>
                                    <strong>Summary</strong> {item.summary}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                            type="button"
                            className="button button-outline button-primary width-sm btn"
                            onClick={this.props.onClose}
                            style={{ lineHeight: 'unset' }}
                        >
                            Close
                        </button>
                    </div>
                </ReactModal>
            </>
        );
    }
}

export default GoogleFeedsModal;
