import React, { Component } from 'react';

class Actions extends Component {
    constructor(props) {
        super();

        this.onChange = this.onChange.bind(this);
        this.changeView = this.changeView.bind(this);

        this.state = {
            value: props.defaultValue ?? 0,
            showEditMode: props.showOpened ?? false,
        };
    }

    onChange(e) {
        this.setState({ value: e.target.value });
    }

    changeView(bool) {
        this.setState({ showEditMode: bool });
    }
    render() {
        return this.state.showEditMode ? (
            <div>
                {this.props.header && (
                    <div className="change-gsc-field-form-header">{this.props.header}</div>
                )}
                <form
                    className="change-gsc-field-form"
                    onSubmit={(e) => this.props.onChange(e, this.state.value)}
                >
                    <div className="text-field">
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <input
                                name="form-input"
                                className="form-control"
                                type={this.props.inputType ?? 'text'}
                                value={this.state.value}
                                onChange={this.onChange}
                                style={this.props.inputStyles ? { ...this.props.inputStyles } : {}}
                            />
                        </div>
                    </div>
                    <div className="text-right">
                        <button
                            type="submit"
                            className="button button-filled button-primary button-icon"
                            disabled={this.props.loading}
                        >
                            <i className="icon-check-icon"></i>
                        </button>
                        <button
                            type="button"
                            className="button button-outline button-primary button-icon"
                            onClick={() => this.changeView(false)}
                        >
                            <i className="icon-close"></i>
                        </button>
                    </div>
                </form>
            </div>
        ) : (
            <div className="change-gsc-field-form">
                <button
                    type="button"
                    className="button button-filled button-primary button-icon"
                    id="name-edit-btn"
                    onClick={() => this.changeView(true)}
                >
                    <i className="icon-settings"></i>
                </button>
            </div>
        );
    }
}

export default Actions;
