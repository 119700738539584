import { Component } from 'react';
import { cancelSubscription } from './query';
import Loading from '../../components/Loading';

class UserCancelationBtn extends Component {
    constructor() {
        super();

        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            isLoading: false,
            isOpenModal: false,
        };
    }
    async onSubmit() {
        try {
            this.setState({
                isLoading: true,
                isOpenModal: false,
            });
            const data = {
                reason: 'by admin',
                comment: '',
            };
            const response = await cancelSubscription(
                data,
                this.props.csrf_token,
                this.props.license_id,
            );
            const resData = await response.json();
            if (response.status === 400) {
                this.setState({
                    isLoading: false,
                    message: resData?.message,
                    message_tag: 'error',
                });
            } else if (response.status === 200) {
                this.setState({
                    message: resData?.message,
                    message_tag: 'success',
                });
                window.location.reload();
            }
        } catch (err) {
            console.log('error', err);
            this.setState({
                isLoading: false,
                message: 'Something went erong',
                message_tag: 'error',
            });
        }
    }

    render() {
        if (this.state.isLoading) return <Loading onlyPreloader />;
        return (
            <button type="button" onClick={this.onSubmit} className="btn btn-dark">
                Deactivate
            </button>
        );
    }
}
export default UserCancelationBtn;
