import DataTable from '../../components/Datatable';
import Loading from '../../components/Loading';
import { COLUMNS } from './keys';
import { query } from './query';
import Component from '../../components/Component';
import ExtraButtons from './extraButtons';
import UserInfoModal from '../../components/Modals/UserInfoModal';
import { v4 as uuid } from 'uuid';
import { getTime } from '../../helpers/date';
import moment from 'moment';

class CustomersDomainComponent extends Component {
    constructor(props) {
        super();
        this.query = query();
        this.user_domain_list = JSON.parse(props.user_domain_list);

        this.collectData = this.collectData.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.toogleInvalidItems = this.toogleInvalidItems.bind(this);
        this.toogleNewItems = this.toogleNewItems.bind(this);
        this.customForceRun = this.customForceRun.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevState.data) !== JSON.stringify(this.state.data)) {
            const data = this.collectData();
            this.setState({
                collectedData: data,
                savedData: data,
            });
        }
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter(
            (item) =>
                item.domain?.toLowerCase().includes(search.toLowerCase()) ||
                item.user?.toLowerCase().includes(search.toLowerCase()),
        );
    }

    collectData() {
        let results = [];
        if (!this.user_domain_list) return results;
        for (let key in this.user_domain_list) {
            const domains = this.user_domain_list[key]?.domains?.map((item) => {
                const domaInfo = this.state.data?.find((x) => x.domain === item.domain);
                return {
                    user: key,
                    userId: this.user_domain_list[key]?.id,
                    ...domaInfo,
                    domain: item.domain,
                    domain_id: item.id,
                    id: uuid(),
                    import_keywords: item.import_keywords,
                };
            });
            results = results.concat(domains);
        }
        return results;
    }

    onOpenModal(user, import_keywords) {
        this.setState({
            isOpenModal: true,
            selectedUser: user,
            selectedUserImportKeywords: import_keywords,
        });
    }

    onCloseModal() {
        this.setState({ isOpenModal: false });
    }

    toogleInvalidItems() {
        const data = this.state.savedData ?? [];
        let newData = [];
        if (!this.state.showInvalidItems) {
            let date3Days = new Date();
            date3Days.setDate(date3Days.getDate() - 3);
            const date3Modefied = moment(date3Days).format('YYYY-MM-DD');

            let date7Days = new Date();
            date7Days.setDate(date7Days.getDate() - 8);
            const date7Modefied = moment(date7Days).format('YYYY-MM-DD');

            newData = data.filter((item) => {
                return (
                    !item.DlastDate?.value ||
                    new Date(item.DlastDate?.value) < new Date(date3Modefied) ||
                    !item.lastDate?.value ||
                    new Date(item.lastDate?.value) < new Date(date7Modefied)
                );
            });
        } else {
            newData = this.state.savedData;
        }
        this.setState({
            showInvalidItems: !this.state.showInvalidItems,
            showNewItems: false,
            collectedData: newData,
        });
    }

    toogleNewItems() {
        const data = this.state.savedData ?? [];
        let newData = [];
        if (!this.state.showNewItems) {
            newData = data.filter((item) => {
                return !item.DlastDate?.value && !item.lastDate?.value;
            });
        } else {
            newData = this.state.savedData;
        }
        this.setState({
            showInvalidItems: false,
            showNewItems: !this.state.showNewItems,
            collectedData: newData,
        });
    }

    customForceRun() {
        this.setState({
            showInvalidItems: false,
            showNewItems: false,
        });
        this.forceRun();
    }

    returnNumber(value) {
        return Number(value || 0);
    }

    render() {
        if (this.state.loading) return <Loading />;

        const extraBtns = () => (
            <ExtraButtons
                csrf_token={this.props.csrf_token}
                current_url={this.props.current_url}
                showInvalidItems={this.state.showInvalidItems}
                toogleInvalidItems={this.toogleInvalidItems}
                toogleNewItems={this.toogleNewItems}
                showNewItems={this.state.showNewItems}
            />
        );

        const sortFns = {
            user: (array) => array.sort((a, b) => a.user.localeCompare(b.user)),
            domain: (array) => array.sort((a, b) => a.domain?.localeCompare(b.domain)),
            DavgDailyRecords: (array) =>
                array.sort(
                    (a, b) =>
                        this.returnNumber(a.DavgDailyRecords) -
                        this.returnNumber(b.DavgDailyRecords),
                ),
            DcollectionDays: (array) =>
                array.sort(
                    (a, b) =>
                        this.returnNumber(a.DcollectionDays) - this.returnNumber(b.DcollectionDays),
                ),
            DlastRecordsCollected: (array) =>
                array.sort(
                    (a, b) =>
                        this.returnNumber(a.DlastRecordsCollected) -
                        this.returnNumber(b.DlastRecordsCollected),
                ),
            Drecords: (array) =>
                array.sort((a, b) => this.returnNumber(a.Drecords) - this.returnNumber(b.Drecords)),
            avgWeeklyRecords: (array) =>
                array.sort(
                    (a, b) =>
                        this.returnNumber(a.avgWeeklyRecords) -
                        this.returnNumber(b.avgWeeklyRecords),
                ),
            collectionWeeks: (array) =>
                array.sort(
                    (a, b) =>
                        this.returnNumber(a.collectionWeeks) - this.returnNumber(b.collectionWeeks),
                ),
            lastRecordsCollected: (array) =>
                array.sort(
                    (a, b) =>
                        this.returnNumber(a.lastRecordsCollected) -
                        this.returnNumber(b.lastRecordsCollected),
                ),
            records: (array) =>
                array.sort((a, b) => this.returnNumber(a.records) - this.returnNumber(b.records)),
            DlastCollection: (array) =>
                array.sort(
                    (a, b) => getTime(a.DlastCollection?.value) - getTime(b.DlastCollection?.value),
                ),
            DlastDate: (array) =>
                array.sort((a, b) => getTime(a.DlastDate?.value) - getTime(b.DlastDate?.value)),
            firstDate: (array) =>
                array.sort((a, b) => getTime(a.firstDate?.value) - getTime(b.firstDate?.value)),
            lastCollection: (array) =>
                array.sort(
                    (a, b) => getTime(a.lastCollection?.value) - getTime(b.lastCollection?.value),
                ),
            lastDate: (array) =>
                array.sort((a, b) => getTime(a.lastDate?.value) - getTime(b.lastDate?.value)),
        };

        return (
            <>
                <DataTable
                    nodes={this.state.collectedData ?? []}
                    sortFns={sortFns}
                    COLUMNS={COLUMNS(
                        this.props.current_url,
                        this.props.csrf_token,
                        this.onOpenModal,
                    )}
                    fileName="Google Discover"
                    searchFunction={this.searchFunction}
                    extraBtns={extraBtns}
                    forceRun={this.customForceRun}
                    additionalStyles={{
                        Table: `
                            --data-table-library_grid-template-columns : minmax(250px, 2.5fr) minmax(250px, 2.5fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(300px, 3fr) !important;
                            max-height: 600px
                        `,
                        BaseCell: ` font-family: 'Raleway';
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                    padding: 16px 5px !important;
                                    color: #0D182C;
                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);

                                    &:nth-of-type(1) {
                                      left: 0px;
                                    }
                            
                                    &:nth-of-type(2) {
                                      left: 250px;
                                    }
                                    
                                    &.underline {
                                        text-decoration: underline;
                                    }
                                
                                `,
                    }}
                />
                {this.state.isOpenModal && (
                    <UserInfoModal
                        isOpen={this.state.isOpenModal}
                        onClose={this.onCloseModal}
                        selectedUser={this.state.selectedUser}
                        hasImportKeywords
                        selectedUserImportKeywords={this.state.selectedUserImportKeywords}
                        current_url={this.props.current_url}
                        csrf_token={this.props.csrf_token}
                    />
                )}
            </>
        );
    }
}

export default CustomersDomainComponent;
