import LoadMoreComponent from '../../../../../../components/LoadMoreComponent';
import { getSortKeys, setAdditionalStyles } from '../../../../../../helpers/table';
import { COLUMNS, EXTRA_HEADER, getScore } from './keys';
import query from './query';
import { v4 as uuid } from 'uuid';
import Item from './Item';
import Qualifiers from './Qualifiers';
import { changeIntent } from '../../../../../../helpers/commerce';

class OpportunitiesClusters extends LoadMoreComponent {
    constructor(props) {
        super();
        this.customForceRun = this.customForceRun.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.updateTables = this.updateTables.bind(this);

        this.url = new URL(window.location);
        this.keyword = this.url.searchParams.get('keyword');
        this.intent = this.url.searchParams.get('intent');

        const params = {
            project_id: props.project_id,
            offset: 0,
            own_domain: props.own_domain,
            competitors: props.competitors,
        };

        if (this.intent) {
            params.intent_filter = this.intent?.split(',');
        }

        if (!props.data) this.query = query(params);
        this.offsetStep = 1000;
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) =>
            item?.cluster_name?.toLowerCase()?.includes(search.toLowerCase()),
        );
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDatas({ key: 'contentGaps', value: this.state.data });
            this.props.setClustersFunction(this.handleSelectChange);
        }

        if (
            prevProps.own_domain !== this.props.own_domain ||
            JSON.stringify(prevProps.competitors) !== JSON.stringify(this.props.competitors)
        ) {
            this.query = query({
                project_id: this.props.project_id,
                offset: 0,
                own_domain: this.props.own_domain,
                competitors: this.props.competitors,
            });
            this.setState({
                loading: true,
                offset: 0,
                allData: [],
            });

            this.props.updateDatas({ key: 'contentGaps', empty: true });
            this.query.bind(this.setState.bind(this));
            await this.query.update();
        }
    }

    customForceRun() {
        this.forceRun();
        this.setState({
            allData: [],
            data: null,
        });
        this.props.updateDatas({ key: 'contentGaps', empty: true });
    }

    updateTables(values) {
        this.handleSelectChange(values);
        this.props.recallKeywords && this.props.recallKeywords(values);
    }

    async handleSelectChange(values) {
        this.setState({
            select: values,
            loading: true,
            data: null,
            allData: [],
        });
        this.props.changeSelect(values);

        changeIntent(values);

        this.query = query({
            project_id: this.props.project_id,
            offset: 0,
            own_domain: this.props.own_domain,
            competitors: this.props.competitors,
            intent_filter: values?.map((item) => item.value),
        });
        this.query.bind(this.setState.bind(this));
        this.query.update();
        this.props.updateDatas({ emptyAll: true });
    }

    render() {
        let data = this.state.allData?.length ? this.state.allData : this.props.data;

        data = (this.state.sortedValues ?? data)?.map((item, i) => ({
            ...item,
            rank: i + 1,
        }));

        const updatedData = data?.reduce(
            (acc, curr) => {
                const score = getScore(curr);
                if (!acc.max || acc.max < score) acc.max = score;

                if (!acc.min || acc.min > score) acc.min = score;
                acc.sum = acc?.sum ?? 0 + score;
                const trends = [
                    curr.t1 ?? 0,
                    curr.t2 ?? 0,
                    curr.t3 ?? 0,
                    curr.t4 ?? 0,
                    curr.t5 ?? 0,
                    curr.t6 ?? 0,
                    curr.t7 ?? 0,
                    curr.t8 ?? 0,
                    curr.t9 ?? 0,
                    curr.t10 ?? 0,
                    curr.t11 ?? 0,
                    curr.t12 ?? 0,
                ];
                const item = {
                    ...curr,
                    trends,
                    score,
                    id: uuid(),
                };
                acc.data.push(item);
                return acc;
            },
            {
                data: [],
            },
        );

        const columns = COLUMNS(
            this.props.project_id,
            this.props.own_domain,
            this.props.competitors,
            this.keyword,
            updatedData?.max,
            updatedData?.min,
            updatedData?.sum / updatedData?.data?.length,
        );

        const sortFns = getSortKeys(columns);

        const items = [
            {
                title: 'High Potential Keyword Clusters',
                defaultSortKey: '',
                getData: (data) => data?.sort((a, b) => b.score - a.score)?.slice(0, 100),
            },
            {
                title: 'Untapped Keyword Clusters',
                defaultSortKey: '',
                getData: (data) => data?.filter((item) => !item.own_urls)?.slice(0, 100),
            },
            {
                title: '"Best" Keywords',
                defaultSortKey: 'score',
                getData: (data) =>
                    data
                        ?.sort((a, b) => b.score - a.score)
                        ?.filter((item) => item.cluster_name?.toLowerCase()?.includes('best'))
                        ?.slice(0, 100),
            },
            {
                title: '"Gift" Keywords',
                defaultSortKey: 'score',
                getData: (data) =>
                    data
                        ?.sort((a, b) => b.score - a.score)
                        ?.filter((item) => item.cluster_name?.toLowerCase()?.includes('gift'))
                        ?.slice(0, 100),
            },
            {
                title: 'High CPC',
                defaultSortKey: '',
                getData: (data) => data?.sort((a, b) => b.median_cpc - a.median_cpc)?.slice(0, 100),
            },
        ];

        return (
            <>
                {items?.map((item) => (
                    <Item
                        key={item.title}
                        title={item.title}
                        data={item.getData(updatedData?.data)}
                        sortFns={sortFns}
                        columns={columns}
                        fileName=""
                        defaultSortKey={item.defaultSortKey ?? ''}
                        EXTRA_HEADER={EXTRA_HEADER(this.props.own_domain, this.props.competitors)}
                        forceRun={this.customForceRun}
                        searchFunction={this.searchFunction}
                        additionalStyles={setAdditionalStyles(columns)}
                        loading={this.state.loading}
                        select={this.props.select}
                        handleSelectChange={this.updateTables}
                    />
                ))}
                <Qualifiers
                    commerce_project={this.props.commerce_project}
                    project_id={this.props.project_id}
                    keyword={this.props.keyword}
                    own_domain={this.props.own_domain}
                    updateQualifieres={this.props.updateQualifieres}
                    csrf_token={this.props.csrf_token}
                />
            </>
        );
    }
}

export default OpportunitiesClusters;
