import { COLUMNS } from './keys';
import { query } from './query';
import DataTable from '../../../../components/Datatable';
import Component from '../../../../components/Component';
import { getDifference } from '../../../../helpers/showDelta';
import Loading from '../../../../components/Loading';
import ExtraButtons from '../extraBtns';
import { scrollForTable } from '../../../../components/Datatable/utils';

class GeoPerformance extends Component {
    constructor(props) {
        super();
        if (!props.data) this.query = query(props.dates);
        this.chooseFilter = this.chooseFilter.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.dates) !== JSON.stringify(this.props.dates)) {
            this.props.updateDates({ key: 'geoPerformance', value: null });
            this.setState({ loading: true, data: null });
            this.query = query({ ...this.props.dates, filter: this.state.filter });
            this.query.bind(this.setState.bind(this));
            this.query.update();
            this.props.updateDates({ emptyAll: true });
        }
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDates({ key: 'geoPerformance', value: this.state.data });
        }
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) => item.country?.toLowerCase().includes(search.toLowerCase()));
    }

    chooseFilter(filter) {
        if (this.state.filter === filter) return;
        this.setState({
            filter,
            loading: true,
            data: [],
        });
        this.props.updateDates({ key: 'geoPerformance', value: null });
        this.query = query({ ...this.props.dates, filter });
        this.query.bind(this.setState.bind(this));
        this.query.update();
    }

    render() {
        const data = this.state.data?.length ? this.state.data : this.props.data;
        if (
            (!data || !data.length) &&
            this.state.loading &&
            (!this.props.data || !this.props.data.length)
        )
            return <Loading />;

        const sortFns = {
            country: (array) => array.sort((a, b) => a.country.localeCompare(b.country)),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            avgCTR: (array) =>
                array.sort((a, b) => a.clicks / a.impressions - b.clicks / b.impressions),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            impressions_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.impressions, a.impressionsPrior) -
                        getDifference(b.impressions, b.impressionsPrior),
                ),
            clicks_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.clicks, a.clicksPrior) -
                        getDifference(b.clicks, b.clicksPrior),
                ),
            avgPosition_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.avgPosition, a.avgPositionPrior) -
                        getDifference(b.avgPosition, b.avgPositionPrior),
                ),
            avgCTR_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.avgCTR, a.avgCTRPrior) -
                        getDifference(b.avgCTR, b.avgCTRPrior),
                ),
        };

        return (
            <DataTable
                nodes={data ?? []}
                sortFns={sortFns}
                COLUMNS={COLUMNS}
                uniqKey={'country'}
                fileName="Geo Performance"
                searchFunction={this.searchFunction}
                extraBtns={() => (
                    <ExtraButtons filter={this.state.filter} chooseFilter={this.chooseFilter} />
                )}
                forceRun={this.forceRun}
                additionalStyles={{
                    Table: `
                                --data-table-library_grid-template-columns : minmax(120px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                               ${scrollForTable}
                            `,
                    BaseCell: `  font-family: 'Raleway';
                                            font-weight: 500;
                                            font-size: 16px;
                                            line-height: 19px;
                                            padding: 16px 5px;
                                            color: #0D182C;
                                            border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                        
                                            &.underline {
                                            text-decoration: underline;
                                            }`,
                }}
            />
        );
    }
}

export default GeoPerformance;
