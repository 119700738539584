import CheckData from '../../../../../components/CatchError';
import DataTable from '../../../../../components/Datatable';
import LoadMoreComponent from '../../../../../components/LoadMoreComponent';
import Loading from '../../../../../components/Loading';
import { downloadAllCsv } from '../../../../../helpers/downloadAll';
import { getAllQuery } from '../MarketShare/query';
import { COLUMNS } from './keys';
import { v4 as uuid } from 'uuid';
import query from './query';
import { getSortKeys, setAdditionalStyles } from '../../../../../helpers/table';
import CommerceFilterIntent from '../../../../../components/CommerceFilterIntent';
import { changeIntent } from '../../../../../helpers/commerce';

class URLs extends LoadMoreComponent {
    constructor(props) {
        super();
        this.exportAll = this.exportAll.bind(this);
        this.customForceRun = this.customForceRun.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.url = new URL(window.location);
        this.own_domain = this.url.searchParams.get('own_domain');
        this.keyword = this.url.searchParams.get('keyword');
        this.intent = this.url.searchParams.get('intent');

        const params = {
            project_id: props.project_id,
            offset: 0,
        };

        if (this.intent) {
            params.intent_filter = this.intent?.split(',');
        }

        if (!props.data) this.query = query(params);
        this.offsetStep = 10000;

        const breadcrumbBox = document.getElementById('breadcrumb-box');
        if (breadcrumbBox)
            breadcrumbBox.innerHTML = `<a class="text-primary domain-name" aria-current="page" href="/commerce/">Commerce</a><i class="icon-arrow-right"></i> <a href="#" >Project: ${this.keyword} </a> <i class="icon-arrow-right"></i> <a href="#" >URLS</a>`;
    }

    searchFunction(data, search) {
        return data.filter((item) => item?.url?.toLowerCase()?.includes(search.toLowerCase()));
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDatas({ key: 'urls', value: this.state.data });
        }
        const filters = localStorage.getItem('filters');
        const data = this.state.data?.map((x) => ({ id: uuid(), ...x }));

        if (prevState.filters !== filters && !this.state.loading) {
            return this.setState({ allData: data, filters });
        }
        if (
            JSON.stringify(this.state.data) !== JSON.stringify(prevState.data) &&
            !this.state.loading
        ) {
            this.setState({ allData: [...this.state.allData, ...data] });
        }
    }

    async handleSelectChange(values) {
        this.setState({
            select: values,
            loading: true,
            data: null,
            allData: [],
        });

        changeIntent(values);

        this.query = query({
            project_id: this.props.project_id,
            offset: 0,
            intent_filter: values?.map((item) => item.value),
        });
        this.query.bind(this.setState.bind(this));
        this.query.update();
        this.props.updateDatas({ key: 'urls', empty: true });
    }

    customForceRun() {
        this.forceRun();
        this.setState({
            allData: [],
            data: null,
        });
        this.props.updateDatas({ key: 'urls', empty: true });
    }

    async exportAll() {
        try {
            this.setState({
                exportAllLoading: true,
            });
            const allQuery = getAllQuery({ project_id: this.props.project_id });
            const response = await allQuery.update();
            downloadAllCsv(response, 'URLS', COLUMNS());
            this.setState({
                exportAllLoading: false,
            });
        } catch (err) {
            this.setState({
                exportAllLoading: false,
            });
            console.log(err);
        }
    }

    render() {
        let data = this.state.allData?.length ? this.state.allData : this.props.data;
        const attr = {
            query,
            project_id: this.props.project_id,
            intent_filter: this.state.select?.map((item) => item.value) || null,
            offsetStep: data?.length
                ? parseInt(data?.length / this.offsetStep) * this.offsetStep
                : this.offsetStep,
        };

        const showLoadMoreBtn = this.state.data
            ? this.state.data?.length === this.offsetStep
            : data?.length % this.offsetStep === 0;

        data = data?.map((item, i) => ({ ...item, rank: i + 1 }));

        const extraBtns = () => (
            <CommerceFilterIntent
                select={this.state.select}
                handleSelectChange={this.handleSelectChange}
            />
        );

        return (
            <div className="card rounded-main table-main">
                <div
                    className="card-header d-flex justify-content-between"
                    style={{ borderRadius: '10px' }}
                >
                    <div className="card-title text-dark fw-bold">URLs: [{this.props.keyword}]</div>
                </div>
                <div className="card-body">
                    <div>
                        <CheckData
                            data={data}
                            loading={!data && this.state.loading}
                            loadMore={this.state.loadMore}
                        >
                            <DataTable
                                nodes={data ?? []}
                                sortFns={getSortKeys(COLUMNS())}
                                extraBtns={extraBtns}
                                COLUMNS={COLUMNS(
                                    this.props.project_id,
                                    this.own_domain,
                                    this.keyword,
                                )}
                                fileName=""
                                forceRun={this.customForceRun}
                                searchFunction={this.searchFunction}
                                additionalStyles={setAdditionalStyles(COLUMNS())}
                            />
                        </CheckData>
                        {this.state.loadMore ? (
                            <Loading padding={10} />
                        ) : (
                            <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                {showLoadMoreBtn && (
                                    <button
                                        onClick={() => this.loadMore(attr)}
                                        className="load-more-btn mr-10"
                                    >
                                        Load More
                                    </button>
                                )}
                                {this.state.exportAllLoading ? (
                                    <Loading padding={10} />
                                ) : (
                                    <button
                                        onClick={this.exportAll}
                                        disabled={this.state.loading}
                                        className="load-more-btn"
                                    >
                                        Export All
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default URLs;
