import numeral from 'numeral';
import { dataWithouDomain } from '../../../helpers/collectUrl';
import { linReg } from '../../../lib/reduce';

export default [
    {
        key: (d) => {
            let c = linReg(d.clicksL);
            return c.slope;
        },
        label: 'ClicksChange',
        change: true,
        classes: 'col-2',
    },
    {
        key: (d) => {
            const url = dataWithouDomain(d.url);
            return (
                <div className="col-12 no-wrap ms-3">
                    <a
                        href={`/domains/${window.info.id}/gsc-reports/urls/url/?url=${d.url}`}
                        target="_blank"
                        rel="noreferrer"
                        title={d.url}
                    >
                        {url.length > 40 ? `${url.slice(0, 37)}...` : url}
                    </a>
                </div>
            );
        },
        label: 'Pages',
        classes: 'no-wrap col-9',
    },
    {
        key: 'clicks',
        label: 'Clicks',
        classes: 'no-wrap col',
        render: (d) => <div className="no-wrap col">{numeral(d.clicks).format('0.0a')}</div>,
    },
    {
        key: (d, data) => {
            let clicks = d.clicks / data.reduce((e, f) => e + f.clicks, 0);

            return clicks / 2;
        },
        label: 'Rank',
        bar: true,
        sort: true,
        hidden: true,
    },
];
