import { Component } from 'react';
import Loading from '../../../components/Loading';

class Domains extends Component {
    constructor() {
        super();
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            domains: [],
            loading: false,
            defaultSelectedDomains: [],
        };
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(prevProps.selectedAccount) !== JSON.stringify(this.props.selectedAccount)
        ) {
            const defaultSelectedDomains = this.props.selectedAccount?.available_domains
                ?.filter((item) =>
                    this.props.domains?.find((domain) => item.siteUrl === domain.url),
                )
                .map((item) => item.siteUrl);
            this.setState({
                defaultSelectedDomains,
                domains: defaultSelectedDomains,
            });
        }
    }

    onChange(domain) {
        const domains = this.state.domains;
        const newDomains = domains.includes(domain)
            ? domains.filter((item) => item !== domain)
            : [...domains, domain];
        this.setState({
            domains: newDomains,
        });
    }

    onSubmit() {
        if (this.state.domains.length === 0) return;
        this.setState({
            loading: true,
        });
        const a = document.createElement('a');

        a.href = `/domains/link?account=${
            this.props.selectedAccount.account_id
        }&domains_url=${this.state.domains.join(',')}&dest=to_onboard`;
        a.click();
    }

    slectAll() {
        if (this.props.selectedAccount?.available_domains.length === this.state.domains.length) {
            this.setState({
                domains: this.state.defaultSelectedDomains,
            });
        } else {
            this.setState({
                domains: this.props.selectedAccount?.available_domains.map((item) => item.siteUrl),
            });
        }
    }

    render() {
        console.log('selectedAccount', this.props.selectedAccount);
        return (
            <div id="onboarding-general-settings">
                <div className="onboarding-subheader">
                    Choose all the domains you'd like to add to our tool.
                </div>
                <div className="onboarding-domains">
                    {!this.props.selectedAccount &&
                        'Please click "Select Domains" button next to the Google Account in Step 1.'}
                    {this.props.selectedAccount &&
                        !this.props.selectedAccount?.available_domains?.length &&
                        `There are no domains in the GSC account ${this.props?.selectedAccount?.account_email}, please add another Google Search Console account in step #1 that has the domain(s) you want to analyze.`}
                    {!!this.props.selectedAccount?.available_domains?.length && (
                        <div className="onboarding-domains-item">
                            <input
                                id="domain-all"
                                type="checkbox"
                                onChange={() => this.slectAll()}
                                checked={
                                    this.props.selectedAccount?.available_domains.length ===
                                    this.state.domains.length
                                }
                            />
                            <label htmlFor="domain-all">
                                <span>Select All</span>
                            </label>
                        </div>
                    )}
                    {this.props.selectedAccount?.available_domains?.map((item) => (
                        <div key={item.siteUrl} className="onboarding-domains-item">
                            <input
                                id={`domain-${item?.siteUrl}`}
                                type="checkbox"
                                onChange={() => this.onChange(item?.siteUrl)}
                                checked={this.state.domains.includes(item?.siteUrl)}
                                disabled={this.state.defaultSelectedDomains.includes(item.siteUrl)}
                            />
                            <label htmlFor={`domain-${item?.siteUrl}`}>
                                <span>{item?.siteUrl}</span>{' '}
                                <span className="onboarding-domains-permission">
                                    permission:
                                    {item?.permissionLevel}
                                </span>
                            </label>
                        </div>
                    ))}
                </div>
                <div className="d-flex justify-content-between">
                    <button
                        className="button button-outline button-primary width-sm btn onboarding-btn-back d-flex justify-content-center"
                        onClick={this.onSubmit}
                        disabled={this.state.loading || this.state.domains.length === 0}
                    >
                        {this.state.loading && <Loading onlyPreloader />}
                        Add domain(s)
                    </button>
                    <div className="d-flex">
                        <button
                            className="button width-sm btn onboarding-btn-next d-flex justify-content-center onboarding-skip-btn"
                            style={{ lineHeight: 'unset' }}
                            onClick={() => this.props.handleBack()}
                        >
                            back
                        </button>
                        <button
                            className="button  button-filled button-primary width-sm btn onboarding-btn-next d-flex justify-content-center"
                            onClick={() => this.props.handleNext()}
                            disabled={this.props.isDisabledNext}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Domains;
