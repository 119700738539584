import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';

export const COLUMNS = (onSelect, onOpenModal) => [
    {
        label: 'Entity 1',
        key: 'entity1',
        data: 'entity1',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <strong className="wrap-text cursor-pointer" onClick={() => onSelect(data)}>
                    {data}
                </strong>
            );
        },
    },
    {
        label: 'Entity 2',
        key: 'entity2',
        data: 'entity2',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <strong className="wrap-text cursor-pointer" onClick={() => onSelect(data)}>
                    {data}
                </strong>
            );
        },
    },
    {
        label: 'Pair Relevancy',
        key: 'avg_pair_relevancy',
        data: 'avg_pair_relevancy',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data).format('0.0a');
        },
    },
    {
        label: 'Pages Count',
        key: 'pair_count',
        data: 'pair_count',
        Component: Cell,
    },
    {
        label: 'Pages',
        key: 'pages',
        data: 'pages',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="wrap-text d-flex align-items-center">
                    <div style={{ float: 'left', marginRight: '10px' }}>{data?.[0]}</div>
                    <div
                        className="link-website cursor-pointer d-flex align-items-center justify-content-center"
                        onClick={() => onOpenModal(data)}
                    >
                        <i className="icon-enlarge" style={{ fontSize: '12px' }}></i>
                    </div>
                </div>
            );
        },
    },
];
