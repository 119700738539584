import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';

export const COLUMNS = [
    {
        label: 'Country',
        key: 'country',
        data: 'country',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{data}</strong>;
        },
    },
    {
        label: 'Search\nImpressions',
        key: 'search_impressions',
        data: 'search_impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Search\nClicks',
        key: 'search_clicks',
        data: 'search_clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Search\nCTR',
        key: 'search_ctr',
        data: 'search_ctr',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.search_clicks / item.search_impressions;
            if (csvDownload) return ctrValue;

            return <strong>{numeral(ctrValue).format('0.00%')}</strong>;
        },
    },
    {
        label: 'Discover\nImpressions',
        key: 'discover_impressions',
        data: 'discover_impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Discover\nClicks',
        key: 'discover_clicks',
        data: 'discover_clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Discover\nCTR',
        key: 'discover_ctr',
        data: 'discover_ctr',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.discover_clicks / item.discover_impressions;
            if (csvDownload) return ctrValue;

            return <strong>{numeral(ctrValue).format('0.00%')}</strong>;
        },
    },
];
