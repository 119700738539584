import React, { Component } from 'react';
import DataTable from '../../../../components/Datatable';
import { scrollForTable } from '../../../../components/Datatable/utils';
import { COLUMNS } from './keys';

class TrafficByCountry extends Component {
    constructor() {
        super();
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) => item?.country?.toLowerCase()?.includes(search.toLowerCase()));
    }

    render() {
        const search = this.props.searchData?.map((item) => ({ ...item })) ?? [];
        const discover = this.props.discoverData?.map((item) => ({ ...item })) ?? [];
        const allData = search.map((item) => {
            const country = item?.keys?.[0];
            const countryDiscover = discover.find((data) => data.keys[0] === country);
            return {
                country,
                search_clicks: item.clicks,
                search_impressions: item.impressions,
                discover_clicks: countryDiscover?.clicks ?? 0,
                discover_impressions: countryDiscover?.impressions ?? 0,
            };
        });
        discover.forEach((item) => {
            if (!allData.find((d) => d.country === item.keys[0])) {
                allData.push({
                    country: item.keys[0],
                    search_clicks: 0,
                    search_impressions: 0,
                    discover_clicks: item.clicks,
                    discover_impressions: item.impressions,
                });
            }
        });
        const sortFns = {
            country: (array) => array.sort((a, b) => a?.keys?.[0].localeCompare(b?.keys?.[0])),
            search_impressions: (array) =>
                array.sort((a, b) => a.search_impressions - b.search_impressions),
            search_clicks: (array) => array.sort((a, b) => a.search_clicks - b.search_clicks),
            search_ctr: (array) =>
                array.sort(
                    (a, b) =>
                        a.search_clicks / a.search_impressions -
                        b.search_clicks / b.search_impressions,
                ),
            discover_impressions: (array) =>
                array.sort((a, b) => a.discover_impressions - b.discover_impressions),
            discover_clicks: (array) => array.sort((a, b) => a.discover_clicks - b.discover_clicks),
            discover_ctr: (array) =>
                array.sort(
                    (a, b) =>
                        a.discover_clicks / a.discover_impressions -
                        b.discover_clicks / b.discover_impressions,
                ),
        };

        return (
            <div className="row card-row d-flex">
                <div className="col-12">
                    <div className="card rounded-main table-main overflow-hidden ">
                        <div className="card-header">
                            <div className="card-title text-dark fw-bold">Traffic By Country</div>
                        </div>
                        <div className="card-body">
                            <DataTable
                                nodes={allData}
                                sortFns={sortFns}
                                COLUMNS={COLUMNS}
                                fileName="Traffic By Country"
                                searchFunction={this.searchFunction}
                                defaultSortKey={'search_impressions'}
                                additionalStyles={{
                                    Table: `
                                        --data-table-library_grid-template-columns : minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                    ${scrollForTable}
                                    `,
                                    BaseCell: `  font-family: 'Raleway';
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                    padding: 16px 5px !important;
                                    color: #0D182C;
                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                
                                    &.underline {
                                        text-decoration: underline;
                                    }
                                    `,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TrafficByCountry;
