import React from 'react';
import Loading from './Loading';
import request from '../lib/request';

class Chart extends React.Component {
    constructor() {
        super();

        this.state = {
            loading: false,
        };
    }

    async click(href, e) {
        e.preventDefault();
        this.setState({ loading: true });
        await request(href, false, { method: 'GET' });
        this.setState({ loading: false });
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        return (
            <a href={this.props.href} onClick={this.click.bind(this, this.props.href)}>
                {this.props.label || this.props.children}
            </a>
        );
    }
}

export default Chart;
