import React from 'react';

class Modal extends React.Component {
    constructor(props) {
        super();

        let emails = [
            'analytics-84e0ed@gddash.com',
            'analytics-995773@gddash.com',
            'analytics-da5ee4@gddash.com',
            'analytics-95ac99@gddash.com',
            'analytics-0dc30c@gddash.com',
        ];

        let email = emails[Math.floor(Math.random() * emails.length)];

        this.state = {
            active: props.active || false,
            data: {
                discoverAccount: email,
            },
        };

        this.next = this.next.bind(this);
        this.open = this.open.bind(this);
        this.back = this.back.bind(this);
    }

    toggleActive() {
        this.setState({
            active: !this.state.active,
        });
    }

    updateData(key, e) {
        const newState = { ...this.state };
        newState.data[key] = e.target.val || e.target.value;
        this.setState(newState);
    }

    next(e) {
        e && e.preventDefault && e.preventDefault();
        this.props.next && this.props.next(this.state.data);
    }

    back(e) {
        e && e.preventDefault && e.preventDefault();
        this.props.back && this.props.back(this.state.data);
    }

    open() {
        this.next();
    }

    render() {
        let url = `https://search.google.com/search-console/users?resource_id=${this.props.data.domain}`;

        return (
            <div className="registration info">
                <form onSubmit={this.next}>
                    <div className="mb-3">
                        <h4>Add GDDash to your Google Search Console Authorized Users.</h4>
                        <p>
                            Copy the email address below and click &quot;Go to GSC Prefs&quot; to
                            add it to this domain with <strong>restricted</strong> access.
                        </p>
                        <input
                            className="form-control"
                            name="email"
                            placeholder="https://acme.com/"
                            value={this.state.data.discoverAccount}
                        />
                    </div>

                    <div className="align-right">
                        <a
                            href={url}
                            target="_blank"
                            className="btn btn-lg btn-outline-primary"
                            style={{ marginRight: '10px' }}
                            onClick={this.back}
                            rel="noreferrer"
                        >
                            Back
                        </a>
                        <a
                            href={url}
                            target="_blank"
                            className="btn btn-lg btn-primary text-white"
                            onClick={this.open}
                            rel="noreferrer"
                        >
                            Go to your GSC Prefs
                        </a>
                    </div>
                </form>
            </div>
        );
    }
}

export default Modal;
