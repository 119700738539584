import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';

const color = '#ebe9e9';

export const COLUMNS = (own_domain) => [
    {
        label: 'URL',
        key: 'url',
        data: 'url',
        Component: Cell,
        sortType: 'string',
        cellSize: 'minmax(250px, 2.5fr)',
        style: (item) => {
            if (item.domain === own_domain) return { backgroundColor: color };
            return {};
        },
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="d-flex row justify-content-between w-100">
                    <div className="col-10 d-flex row justify-content-between">
                        <div className="col-2">
                            {data?.includes('https') ? (
                                <img
                                    src={`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${data}&size=32`}
                                    alt="domain-img"
                                    className="domain_img"
                                    loading="lazy"
                                />
                            ) : (
                                <img
                                    src={`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${data} &size=32`}
                                    alt="domain-img"
                                    className="domain_img"
                                    loading="lazy"
                                ></img>
                            )}
                        </div>
                        <strong className="col-10 wrap-text">{data}</strong>
                    </div>
                    <div className="col-2 d-flex align-items-center">
                        <a
                            target="_blank"
                            className=" link-website d-inline-flex align-items-center justify-content-center"
                            href={data}
                            rel="noreferrer"
                        >
                            <i className="icon-external-link"></i>
                        </a>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Domain',
        key: 'domain',
        data: 'domain',
        sortType: 'string',
        Component: Cell,
        style: (item) => {
            if (item.domain === own_domain) return { backgroundColor: color };
            return {};
        },
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <span className="wripe">{data}</span>;
        },
    },
    {
        label: 'Position',
        key: 'position',
        data: 'position',
        sortType: (array) => array.sort((a, b) => b.position - a.position),
        Component: Cell,
        style: (item) => {
            if (item.domain === own_domain) return { backgroundColor: color };
            return {};
        },
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <span className="wripe">{numeral(data).format('0 a')}</span>;
        },
    },
    {
        label: 'Organic \n keywords',
        key: 'organic_keywords',
        data: 'organic_keywords',
        sortType: 'number',
        Component: Cell,
        style: (item) => {
            if (item.domain === own_domain) return { backgroundColor: color };
            return {};
        },
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <span className="wripe">{numeral(data).format('0 a')}</span>;
        },
    },
    {
        label: 'Organic \n traffic',
        key: 'organic_traffic',
        data: 'organic_traffic',
        sortType: 'number',
        Component: Cell,
        style: (item) => {
            if (item.domain === own_domain) return { backgroundColor: color };
            return {};
        },
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <span className="wripe">{numeral(data).format('0 a')}</span>;
        },
    },
    {
        label: 'Organic \n cost',
        key: 'organic_cost',
        data: 'organic_cost',
        sortType: 'number',
        Component: Cell,
        style: (item) => {
            if (item.domain === own_domain) return { backgroundColor: color };
            return {};
        },
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <span className="wripe">{numeral(data).format('0 a')}</span>;
        },
    },
    {
        label: 'Adwords \n keywords',
        key: 'adwords_keywords',
        data: 'adwords_keywords',
        sortType: 'number',
        Component: Cell,
        style: (item) => {
            if (item.domain === own_domain) return { backgroundColor: color };
            return {};
        },
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <span className="wripe">{numeral(data).format('0 a')}</span>;
        },
    },
    {
        label: 'Adwords \n traffic',
        key: 'adwords_traffic',
        data: 'adwords_traffic',
        sortType: 'number',
        Component: Cell,
        style: (item) => {
            if (item.domain === own_domain) return { backgroundColor: color };
            return {};
        },
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <span className="wripe">{numeral(data).format('0 a')}</span>;
        },
    },
    {
        label: 'Adwords \n cost',
        key: 'adwords_cost',
        data: 'adwords_cost',
        sortType: 'number',
        Component: Cell,
        style: (item) => {
            if (item.domain === own_domain) return { backgroundColor: color };
            return {};
        },
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <span className="wripe">{numeral(data).format('0 a')}</span>;
        },
    },
];
