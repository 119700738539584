import React from 'react';

import LoadMoreComponent from '../../../components/LoadMoreComponent';
import { query } from './query';
import CheckData from '../../../components/CatchError';
import DataTable from '../../../components/Datatable';
import Loading from '../../../components/Loading';
import { COLUMNS } from './keys';
import ExtraButtons from './ExtraBtns';
import Search from '../../../components/Search';
import { scrollForTable } from '../../../components/Datatable/utils';

class Items extends LoadMoreComponent {
    constructor(props) {
        super();
        this.query = query({
            offset: 0,
            search: null,
            seasonKeywords: props.seasons?.[0]?.keywords,
        });
        this.offsetStep = 1000;

        this.getData = this.getData.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) => item.keyword?.toLowerCase().includes(search.toLowerCase()));
    }

    async getData(value, search) {
        this.query = query({
            offset: 0,
            seasonKeywords: this.props.seasons?.find((item) => item.id === value)?.keywords,
            search: search ?? this.state.search,
        });
        this.query.bind(this.setState.bind(this));
        this.setState({ season: value, allData: [] });

        await this.query.update();
    }

    onSearch(value) {
        this.setState({
            search: value,
        });
        this.getData(this.state.season, value);
    }

    render() {
        const attr = {
            query,
            offsetStep: this.offsetStep,
            seasonKeywords: this.props.seasons?.find((item) => item.id === this.state.season)
                ?.keywords,
            search: this.state.search,
        };

        const sortFns = {
            keyword: (array) => array.sort((a, b) => a.keyword.localeCompare(b.keyword)),
            seen: (array) =>
                array.sort((a, b) => new Date(a.firstSeen?.value) - new Date(b.firstSeen?.value)),
            maxClicks: (array) => array.sort((a, b) => a.maxClicks - b.maxClicks),
            avgClicks: (array) => array.sort((a, b) => a.avgClicks - b.avgClicks),
            avgURLs: (array) => array.sort((a, b) => a.avgURLs - b.avgURLs),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            avgCTR: (array) => array.sort((a, b) => a.avgCTR - b.avgCTR),
        };

        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const extraBtns = () => (
            <ExtraButtons
                season={this.state.season ?? this.props.seasons[0]?.id}
                seasons={this.props.seasons}
                getData={this.getData}
                onOpenSettingsModal={this.props.onOpenSettingsModal}
            />
        );
        const customeSearch = (
            <Search
                onSubmit={this.onSearch}
                placeholder="Search for items"
                defaultValue={this.state.search}
            />
        );

        return (
            <>
                <>
                    <CheckData
                        content_id={this.props.content_id}
                        parent_id={this.props.parent_id}
                        data={this.state.allData}
                        loading={this.state.loading}
                        loadMore={this.state.loadMore}
                        customCheck
                        customCheckCondition={!!this.state.season || !!this.state.search}
                    >
                        <DataTable
                            nodes={this.state.allData}
                            sortFns={sortFns}
                            COLUMNS={COLUMNS}
                            fileName="Custom Keywords"
                            searchFunction={this.searchFunction}
                            defaultSortKey="maxClicks"
                            extraBtns={extraBtns}
                            forceRun={this.forceRun}
                            removeAllSelected={this.state.removeAllSelected}
                            hasSelect={true}
                            customeSearch={customeSearch}
                            onSelect={this.props.onSelect}
                            additionalStyles={{
                                Table: `
                                    --data-table-library_grid-template-columns : minmax(60px, 0.5fr) minmax(200px, 3fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                   ${scrollForTable}
                                `,
                                BaseCell: ` font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                            }}
                        />
                    </CheckData>
                    {this.state.loadMore ? (
                        <Loading padding={10} />
                    ) : (
                        showLoadMoreBtn && (
                            <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                <button
                                    onClick={() => this.loadMore(attr)}
                                    disabled={this.state.loading}
                                    className="load-more-btn"
                                >
                                    Load More
                                </button>
                            </div>
                        )
                    )}
                </>
            </>
        );
    }
}

export default Items;
