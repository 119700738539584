import numeral from 'numeral';
// import { linReg } from '../../lib/reduce';

import { Cell } from '@table-library/react-table-library/table';

export const COLUMNS = (default_image) => [
    {
        label: 'Page',
        key: 'page',
        data: 'page',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            let title = item.title;

            if (!item.title) {
                title = item.page;
            }
            if (csvDownload) return title;
            return (
                <div className="list-col d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <div style={{ float: 'left', marginRight: '10px', width: '100px' }}>
                            <img
                                onError={(event) => (event.target.src = default_image)}
                                src={item.image ?? default_image}
                                style={{
                                    width: '100px',
                                    display: 'inline-block',
                                    minHeight: '60px',
                                    background: '#BBB',
                                }}
                                loading="lazy"
                            />
                        </div>
                        <strong>
                            <a
                                href={
                                    `/domains/${window.info.id}/pages/page?page=` +
                                    encodeURIComponent(item.page)
                                }
                            >
                                {title.length > 64 ? title.substr(0, 64) + '...' : title}
                            </a>
                        </strong>
                    </div>
                    <div className="d-flex align-items-center">
                        <a
                            target="_blank"
                            className="link-website d-inline-flex align-items-center justify-content-center"
                            href={item.page}
                            rel="noreferrer"
                        >
                            <i className="icon-external-link"></i>
                        </a>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Lifespan',
        key: 'age',
        data: 'age',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return (
                <div className="col-1">
                    <strong>{numeral(data).format('0,00')} Days</strong>
                </div>
            );
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return (
                <div className="col-1">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return (
                <div className="col-1">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'CTR',
        key: 'ctr',
        data: 'ctr',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return (
                <div className="col-1 align-center">
                    <strong>{numeral(data).format('0.00%')}</strong>{' '}
                </div>
            );
        },
    },
];

export const COLUMNS_EXPORT = [
    ...COLUMNS(),
    {
        label: 'URL',
        key: 'page',
        data: 'page',
        Component: Cell,
    },
];
