export const setDeviceQuery = async (data, csrf_token) => {
    return fetch('/domains/add-domain-gsc-data/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const deleteIgnoreItems = async (data, csrf_token) => {
    return fetch('/domains/ignored-keyword-url/', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
