/* eslint-disable */
export const updateQualifiers = (data, csrf_token) => {
    return fetch(`/commerce/semrush/update-project`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
