import Query from '../../components/Report';

export const query = () => new Query('get-domains-per-user');

export const gscJobsRerun = (current_url, csrf_token, data = {}) => {
    return fetch(`${current_url}/domains/gsc-jobs-rerun-for-active-domains/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const discoverJobsRerun = (current_url, csrf_token, data = {}) => {
    return fetch(`${current_url}/domains/discover-data-raw-rerun-for-active-domains/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const getUserInfo = (current_url, csrf_token, data = {}) => {
    return fetch(`${current_url}/customers/user-info/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
