import Loading from '../../components/Loading';

import query from './query';
import List from '../../components/List';
import colors from '../../lib/colors';
import { keys } from './keys';
import Component from '../../components/Component';

class Summary extends Component {
    constructor(props) {
        super();
        this.query = query({
            offset: 0,
            domain: props.domain,
            limit: 10,
        });

        this.offsetStep = 100;
    }

    render() {
        if (this.state.loading) {
            return <Loading padding={10} />;
        }

        return (
            <List
                noItems="No item."
                loading={this.state.loading}
                data={this.state.data}
                label={'Top Authors(By impressions)'}
                keys={keys}
                forceRun={this.forceRun}
                color={colors[2]}
                content_id={this.props.content_id}
                parent_id={this.props.parent_id}
                direct_parent_id={this.props.direct_parent_id}
                refreshCoustomStyles={{ fontSize: '16px', padding: '5px 7px' }}
            />
        );
    }
}

export default Summary;
