import Query from '../../../components/Report';

export const query = ({
    offset,
    evergreen_weeks = 5,
    ignored_urls,
    toggle = true,
    exclude_directories = [],
    include_directories = [],
}) =>
    new Query('search-evergreen-urls', {
        offset,
        ignored_urls: collectIgnoredUrls(ignored_urls),
        toggle: toggle ? 'where pweeks > 2' : '',
        exclude_directories: collectExcludeedDirectories(exclude_directories),
        include_directories: collectIncludedDirectories(include_directories),
        evergreen_weeks,
    });

export const setEvergreenWeeksQuery = async (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/add-domain-gsc-data/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const setIgnoreUrl = async (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/ignored-keyword-url/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

const collectIgnoredUrls = (ignored_urls) => {
    if (ignored_urls?.length === 0) return '';
    const urls = ignored_urls.map((url) => `and url !='${url}' `).join('');
    return urls;
};

const collectIncludedDirectories = (directories) => {
    if (!directories || directories?.length === 0) return '';
    const urls = directories.map((item) => `url like '%${item}/%' `).join(' or ');

    return ` and (${urls})`;
};

const collectExcludeedDirectories = (directories) => {
    if (!directories || directories?.length === 0) return '';
    const urls = directories.map((item) => `url not like '%${item}/%' `).join(' and ');

    return ` and (${urls})`;
};
