import { Component } from 'react';
import DataTable from '../../components/Datatable';
import Loading from '../../components/Loading';
import { COLUMNS, EMPTY_DATA_KEYS } from './keys';
import { discoverQuery, searchQuery } from './query';
import { isTrue } from '../../helpers/isTrue';
import { v4 as uuid } from 'uuid';
import { scrollForTable } from '../../components/Datatable/utils';
import Message from '../Message';

class Overview extends Component {
    constructor(props) {
        super();
        if (props.domains) {
            this.domains = JSON.parse(props.domains);
            this.domains_urls = this.domains?.map((item) => `'${item.url}'`).join(',');
        }
        this.permission_errors = JSON.parse(props.permission_errors);
        this.deleteMessage = this.deleteMessage.bind(this);
        this.isShownButton = this.isShownButton.bind(this);
        this.handleMoodeChange = this.handleMoodeChange.bind(this);
        this.query = discoverQuery(this.domains_urls ?? '');
        this.basicPlans = ['Basic', 'Trial_Basic'];
        if (!this.basicPlans.includes(props.plan_type) || isTrue(props.is_superuser)) {
            this.querySearch = searchQuery(this.domains_urls ?? '');
        }

        const isDark = JSON.parse(localStorage.getItem('dark-mode'));

        this.state = {
            discoverData: [],
            searchData: [],
            allData: [],
            message_tags: '',
            message: '',
            buttonName: '',
            showButton: false,
            link: '',
            isDark: !!isDark,
            tableEmptyMessage: '',
        };
    }

    async componentDidMount() {
        try {
            this.isShownButton();
            if (!this.domains_urls) return;
            this.setState({
                isLoading: true,
            });
            const discoverData = await this.query.update();
            const discoverDataForTable =
                discoverData?.map((item) => ({ ...item, dataType: 'discover', id: uuid() })) ?? [];
            let allData = [...discoverDataForTable];

            if (
                !this.basicPlans.includes(this.props.plan_type) ||
                isTrue(this.props.is_superuser)
            ) {
                const searchData = await this.querySearch.update();
                const searchDataForTable =
                    searchData?.map((item) => ({ ...item, dataType: 'search', id: uuid() })) ?? [];
                allData = [...allData, ...searchDataForTable];
            }
            allData = allData.sort((a, b) => a.domain.localeCompare(b.domain));

            this.setState({
                allData,
                isLoading: false,
            });
        } catch (err) {
            console.log(err);
            this.setState({
                isLoading: false,
            });
        }
        document.addEventListener('changeMoodEvent', this.handleMoodeChange);
    }

    componentWillUnmount() {
        document.removeEventListener('changeMoodEvent', this.handleMoodeChange);
    }

    handleMoodeChange() {
        const isDark = JSON.parse(localStorage.getItem('dark-mode'));
        this.setState({ isDark });
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter(
            (item) =>
                item.domain?.toLowerCase().includes(search.toLowerCase()) ||
                item.dataType?.toLowerCase().includes(search.toLowerCase()),
        );
    }

    deleteMessage() {
        this.setState({ message: '' });
    }

    isShownButton() {
        if (isTrue(this.props.is_deactivated) || isTrue(this.props.is_viewer_user)) {
            return;
        }

        if (!isTrue(this.props.linked_accounts)) {
            this.setState({
                buttonName: 'Connect GSC',
                link: `${this.props.current_url}/customers/link/`,
                showButton: true,
            });
            return;
        }

        if (this.domains?.length === 0) {
            this.setState({
                buttonName: 'Add domains',
                link: `${this.props.current_url}/domains/link/`,
                showButton: true,
            });
            return;
        }
        const activeDomain = this.domains?.find((item) => item.active);
        if (!activeDomain) {
            this.setState({
                buttonName: 'Activate your domains',
                link: `${this.props.current_url}/settings/`,
                showButton: true,
                tableEmptyMessage:
                    'You have not activated any domain yet, please activate one of your domains.',
            });
            return;
        }

        if (this.props.sub_status === 'trialing') {
            this.setState({
                buttonName: 'Activate Plan',
                link: `${this.props.current_url}/customers/change_plan/?without_basic=True`,
                showButton: true,
            });
            return;
        }
    }

    render() {
        if (this.state.isLoading) return <Loading />;
        let keys = COLUMNS(this.domains ?? [], this.state.isDark);
        let data = this.state.allData;
        let styles = `
            --data-table-library_grid-template-columns : minmax(250px, 2.5fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(200px, 1.5fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr)!important;
            ${scrollForTable}
        `;

        if (!data[0]) {
            keys = EMPTY_DATA_KEYS(this.domains ?? [], this.permission_errors);
            data = this.domains?.map((item) => ({ domain: item.url }));
            styles = ` --data-table-library_grid-template-columns : minmax(250px, 2.5fr) minmax(120px, 1fr) !important;
                    max-height: 600px
                `;
        }
        const activeDomainsWithData = data.filter((item) => {
            const isActive = this.domains?.find(
                (state) => item.domain === state.url && state.active,
            );
            return isActive;
        });

        const activeDomains = this.domains?.filter((item) => item.active) ?? [];

        const sortFns = {
            domain: (array) => array.sort((a, b) => a.domain.localeCompare(b.domain)),
            dataType: (array) => array.sort((a, b) => a.dataType.localeCompare(b.dataType)),
            uniquePages: (array) =>
                array.sort((a, b) => (a.uniquePages ?? 0) - (b.uniquePages ?? 0)),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            avgCTR: (array) => array.sort((a, b) => a.avgCTR - b.avgCTR),
            avgPosition: (array) =>
                array.sort((a, b) => (a.avgPosition ?? 0) - (b.avgPosition ?? 0)),
        };

        const errorMessage = (
            <div>
                {this.state.tableEmptyMessage || (
                    <>
                        <strong>{activeDomains?.map((item) => item.url).join(', ')}</strong> There
                        is no data yet. If this a new account, it takes 48-72 hours to process
                        historic data. If the problem exists more than 3 days with new accounts,
                        please contact us at info@gddash.com
                    </>
                )}
            </div>
        );
        return (
            <>
                <DataTable
                    nodes={activeDomainsWithData ?? []}
                    sortFns={sortFns}
                    COLUMNS={keys}
                    fileName="Google Discover"
                    searchFunction={this.searchFunction}
                    customEmptyMessage={errorMessage}
                    additionalStyles={{
                        Table: styles,
                        BaseCell: `font-family: 'Raleway';
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                padding: 16px 5px;
                                color: #0D182C;
                                border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                            
                                &.underline {
                                    text-decoration: underline;
                                }
                                
                                `,
                    }}
                />
                {this.state.showButton && (
                    <div className="redirect-button">
                        <a className="bg-purple button-close" href={this.state.link}>
                            {this.state.buttonName}
                        </a>
                    </div>
                )}
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tags}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default Overview;
