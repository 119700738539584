import { labelColors } from '../../lib/colors';

export const keys = [
    {
        key: 'lifespan',
        label: 'Lifespan',
        format: '0.0',
        info: 'Total number of days',
        labelColor: labelColors[5],
    },
    {
        key: 'activeDays',
        label: 'Active Days',
        format: '0.0',
        info: 'Only days with 50% or higher of max clicks',
        labelColor: labelColors[2],
    },
];
