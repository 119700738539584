import { Component } from 'react';
import EditModal from './EditModal';
import { deleteClusterQuery } from './query';
import Loading from '../Loading';

class Actions extends Component {
    constructor() {
        super();

        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDeleteCluster = this.onDeleteCluster.bind(this);

        this.state = {
            loading: false,
            isOpen: false,
            isLoadingCluster: false,
        };
    }

    onOpen() {
        this.setState({ isOpen: true });
    }

    onClose() {
        this.setState({ isOpen: false });
    }

    onDeleteCluster(e) {
        e.stopPropagation();
        const data = {
            domain_url: window.filters.get().domain.value,
            cluster_type: this.props.clusterType,
            cluster_id: this.props.cluster_id,
        };
        this.setState({
            isLoadingCluster: true,
        });

        deleteClusterQuery(this.props.endpoint_url, data, this.props.csrf_token)
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 200) {
                    this.setState({
                        message: data?.message,
                        message_tags: 'success',
                        isLoadingCluster: false,
                    });
                    this.props.deleteCluster(this.props.clusterName);
                } else {
                    this.setState({
                        message: data?.message,
                        message_tags: 'error',
                        isLoadingCluster: false,
                    });
                }
            })
            .catch((err) => {
                this.setState({ isLoadingCluster: false });
                console.log(err);
            });
    }

    render() {
        return (
            <>
                <div className="cluster-actions">
                    <a
                        href={`/domains/${window.info.id}/gsc-reports/${this.props.id}/`}
                        className="ignor-icon-btn text-success cluster-actions-minus"
                        title={`Add ${this.props.header}`}
                    >
                        <i className="icon-add-circle"></i>
                    </a>
                    <button
                        className="ignor-icon-btn text-danger cluster-actions-plus"
                        title={`Delete ${this.props.header}`}
                        onClick={this.onOpen}
                    >
                        <i className="icon-circle-minus"></i>
                    </button>
                    {this.state.isLoadingCluster ? (
                        <div>
                            <Loading onlyPreloader />
                        </div>
                    ) : (
                        <button
                            className="cluster-actions-delete"
                            onClick={this.onDeleteCluster}
                            title="Delete List"
                        >
                            <i className="icon-trashcan"></i>
                        </button>
                    )}
                </div>
                {this.state.isOpen && (
                    <EditModal
                        endpoint_url={this.props.endpoint_url}
                        clusterName={this.props.clusterName}
                        clusterType={this.props.clusterType}
                        csrf_token={this.props.csrf_token}
                        isOpen={this.state.isOpen}
                        onClose={this.onClose}
                        header={this.props.header}
                        id={this.props.id}
                        updateClusterName={this.props.updateClusterName}
                        cluster_id={this.props.cluster_id}
                        deleteItem={this.props.deleteItem}
                    />
                )}
            </>
        );
    }
}

export default Actions;
