import { Component } from 'react';

class DomainImage extends Component {
    constructor() {
        super();

        this.handleChangeVisibility = this.handleChangeVisibility.bind(this);

        this.state = {
            isHidden: false,
        };
    }

    componentDidMount() {
        this.handleChangeVisibility();
        document.addEventListener('changeDomainVisibilityEvent', this.handleChangeVisibility);
    }

    componentWillUnmount() {
        document.removeEventListener('changeDomainVisibilityEvent', this.handleChangeVisibility);
    }

    handleChangeVisibility() {
        const isHidden = JSON.parse(localStorage.getItem('hide-domain'));
        this.setState({ isHidden });
    }

    render() {
        let domainName = this.props.domain_name;

        if (domainName.includes('sc-domain:')) {
            domainName = domainName.replace(/sc-domain:/g, '');
        }
        const image_url = domainName.includes('https') ? domainName : `https://${domainName}`;
        return (
            !this.state.isHidden && (
                <img
                    src={`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${image_url}&size=32`}
                    loading="lazy"
                    alt=""
                    id="domain-img"
                />
            )
        );
    }
}

export default DomainImage;
