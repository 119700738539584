import LoadMoreComponent from '../../../components/LoadMoreComponent';
import CheckData from '../../../components/CatchError';
import DataTable from '../../../components/Datatable';
import { query_view_1, query_view_2 } from './query';
import Loading from '../../../components/Loading';
import { COLUMNS_1, COLUMNS_2 } from './keys';
import ExtraButtons from './ExtraBtns';
import { v4 as uuid } from 'uuid';
import { getDifference } from '../../../helpers/showDelta';
import { scrollForTable } from '../../../components/Datatable/utils';

class GSCReportsKeywordCluster extends LoadMoreComponent {
    constructor() {
        super();
        this.view_query = query_view_1;
        this.query = this.view_query({ offset: 0 });
        this.offsetStep = 1000;

        this.getData = this.getData.bind(this);
        this.onChangeView = this.onChangeView.bind(this);
        this.getTable = this.getTable.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.forceRun = this.forceRun.bind(this);
    }

    searchTreeFunction(data, search) {
        const filtered = data
            .map((item) => {
                let childrens = [];

                if (item.nodes) {
                    childrens = item.nodes.filter((children) =>
                        children.keyword.toLowerCase().includes(search.toLowerCase()),
                    );
                }

                if (childrens.length) {
                    return { ...item, nodes: childrens };
                }

                return null;
            })
            .filter((x) => x);
        return filtered;
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) =>
            item.cluster_name?.toLowerCase().includes(search.toLowerCase()),
        );
    }

    createNode(data) {
        if (!data) return [];

        const tree = data.map((item) => {
            const childrenNodes = item.keyword_arr.map((keyword, i) => {
                return {
                    keyword,
                    urls: item.kw_urls[i],
                    clicks: item.kw_clicks[i],
                    impressions: item.kw_impressions[i],
                    position: item.kw_position[i],
                    ctr: item.kw_ctr[i],
                    lifespan: item.kw_lifespan[i],
                    keywords: 1,
                    id: uuid(),
                    isClusterName: false,
                };
            });

            return {
                keyword: item.cluster_name,
                urls: item.avg_weekly_urls,
                clicks: item.clicks,
                impressions: item.impressions,
                position: item.avgPosition,
                ctr: item.avgCTR,
                lifespan: item.lifespan,
                keywords: item.keywords,
                nodes: childrenNodes,
                id: uuid(),
                isClusterName: true,
            };
        });

        return tree;
    }

    getData() {
        this.query = this.view_query({
            offset: 0,
        });
        this.query.bind(this.setState.bind(this));
        this.setState({ allData: [] });
        this.query.update();
    }

    deleteItem(clusterName) {
        this.setState({
            allData: this.state.allData.filter((x) => x.cluster_name !== clusterName),
        });
        this.query.update({ force: true, dontUpdateExistingData: true });
    }

    onChangeView(view) {
        if (view === 1) {
            this.view_query = query_view_1;
        } else {
            this.view_query = query_view_2;
        }
        this.setState({ view });
        this.getData();
    }

    getTable() {
        switch (this.state.view) {
            case 2: {
                return {
                    data: this.createNode(this.state.allData),
                    columns: COLUMNS_2(this.deleteItem),
                    tableStyles: `--data-table-library_grid-template-columns : minmax(200px, 3fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(100px, 1fr) !important; ${scrollForTable}`,
                    sortKey: 'sortFns_2',
                    searchFunction: this.searchTreeFunction,
                    hasTree: true,
                };
            }
            default: {
                return {
                    data: this.state.allData,
                    columns: COLUMNS_1(this.deleteItem),
                    tableStyles: `--data-table-library_grid-template-columns :  minmax(190px, 3fr) minmax(90px, 1fr) minmax(150px, 1.8fr) minmax(120px, 1fr) minmax(130px, 1.3fr) minmax(120px, 1fr) minmax(150px, 1.8fr) minmax(90px, 1fr) minmax(150px, 1.8fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(90px, 1fr) minmax(100px, 1fr) !important; ${scrollForTable}`,
                    sortKey: 'sortFns_1',
                    searchFunction: this.searchFunction,
                    hasTree: false,
                };
            }
        }
    }

    render() {
        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const attr = {
            query: this.view_query,
            offsetStep: this.offsetStep,
        };

        const sortFns = {
            sortFns_2: {
                keyword: (array) => array.sort((a, b) => a.keyword.localeCompare(b.keyword)),
                urls: (array) => array.sort((a, b) => a.urls - b.urls),
                clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
                keywords: (array) => array.sort((a, b) => a.keywords - b.keywords),
                impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
                position: (array) => array.sort((a, b) => a.position - b.position),
                ctr: (array) =>
                    array.sort((a, b) => a.clicks / a.impressions - b.clicks / b.impressions),
            },
            sortFns_1: {
                cluster_name: (array) =>
                    array.sort((a, b) => a.cluster_name.localeCompare(b.cluster_name)),
                clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
                impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
                weekly_keywords: (array) =>
                    array.sort((a, b) => a.weekly_keywords - b.weekly_keywords),
                weekly_urls: (array) => array.sort((a, b) => a.weekly_urls - b.weekly_urls),
                avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
                lifespan: (array) => array.sort((a, b) => a.lifespan - b.lifespan),
                avgctr: (array) =>
                    array.sort((a, b) => a.clicks / a.impressions - b.clicks / b.impressions),
                clicks_delta: (array) =>
                    array.sort(
                        (a, b) =>
                            getDifference(a.clicksL.at(-1), a.clicksL[0]) -
                            getDifference(b.clicksL.at(-1), b.clicksL[0]),
                    ),
            },
        };

        const extraBtns = () => (
            <ExtraButtons view={this.state.view} onChange={this.onChangeView} />
        );

        const { data, columns, tableStyles, sortKey, searchFunction, hasTree } = this.getTable();
        return (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.allData}
                    loading={this.state.loading}
                    loadMore={this.state.loadMore}
                >
                    <DataTable
                        nodes={data}
                        sortFns={sortFns[sortKey]}
                        COLUMNS={columns}
                        fileName="Keyword Clusters"
                        hasTree={hasTree}
                        searchFunction={searchFunction}
                        extraBtns={extraBtns}
                        defaultSortKey="clicks"
                        forceRun={this.forceRun}
                        customEmptyMessage="We generate Keyword Clusters once a week every Tuesday. If you recently added your domain, please wait till the following Tuesday for the data to generate. If it is over 8 days since you activated your domain and still no data, please contact us at info@gddash.com"
                        additionalStyles={{
                            Table: tableStyles,
                            BaseCell: `  font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                        }}
                    />
                </CheckData>
                {this.state.loadMore ? (
                    <Loading padding={10} />
                ) : (
                    showLoadMoreBtn && (
                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                            <button
                                onClick={() => this.loadMore(attr)}
                                disabled={this.state.loading}
                                className="load-more-btn"
                            >
                                Load More
                            </button>
                        </div>
                    )
                )}
            </>
        );
    }
}

export default GSCReportsKeywordCluster;
