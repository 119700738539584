import query from './query';
import Component from '../../components/Component';
import ReactSelect from '../../components/ReactSelect';
import Loading from '../../components/Loading';
import Pairs from './Pairs';
import Entity from './Entity';

class SearchTopEntities extends Component {
    constructor() {
        super();

        this.urlParams = new URLSearchParams(window.location.search);
        this.entity = this.urlParams.get('entity');

        this.query = query('https://www.wired.com/');

        this.onSelect = this.onSelect.bind(this);
        this.onChangeTab = this.onChangeTab.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.repeat && this.entity) {
            this.onSelect(this.entity);
            this.setState({
                repeat: true,
            });
        }
    }

    onSelect(value) {
        this.url = new URL(window.location);
        this.url.searchParams.set('entity', value);
        history.pushState({}, '', this.url);
        this.setState({
            entity: value,
            tab: 'entity',
        });
    }

    onChangeTab(key) {
        this.setState({
            tab: key,
        });
    }

    render() {
        const options = this.state.data?.map((item) => ({ label: item.name, value: item.name }));
        return (
            <>
                <div className="row card-row d-flex" id="summary">
                    <div className="col-12">
                        <div
                            className="card table-main rounded-main overflow-hidden "
                            id="domain-summery"
                        >
                            <div className="card-header">
                                <p className="card-title">Entity Clusters</p>
                            </div>
                            <div className="card-body">
                                {this.state.loading ? (
                                    <Loading />
                                ) : (
                                    <div className="entity-select-component">
                                        <label>Select Entity</label>
                                        <ReactSelect
                                            options={options}
                                            value={options.find(
                                                (x) => x.value === this.state.entity,
                                            )}
                                            onChange={(value) => this.onSelect(value)}
                                            blurInputOnSelect={true}
                                            classname="mr-10"
                                            isSearchable
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.tab && (
                    <div className="tags-container">
                        <button
                            className={`tab-item ${
                                this.state.tab === 'all' ? 'active-tag' : 'not-active'
                            }`}
                            onClick={() => this.onChangeTab('all')}
                        >
                            All Entity Clusters
                        </button>
                        <button
                            className={`tab-item ${
                                this.state.tab === 'entity' ? 'active-tag' : 'not-active'
                            }`}
                            onClick={() => this.onChangeTab('entity')}
                        >
                            "{this.state.entity}" Entity Clusters
                        </button>
                    </div>
                )}
                <div
                    className="row card-row"
                    id="summary"
                    style={{
                        display: !this.state.tab || this.state.tab === 'all' ? 'flex' : 'none',
                    }}
                >
                    <div className="col-12">
                        <div
                            className="card table-main rounded-main overflow-hidden "
                            id="domain-summery"
                        >
                            <div className="card-header">
                                <p className="card-title">Entity Clusters</p>
                            </div>
                            <div className="card-body">
                                <Pairs onSelect={this.onSelect} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: this.state.tab === 'entity' ? 'block' : 'none' }}>
                    {this.state.entity && <Entity entity={this.state.entity} />}
                </div>
            </>
        );
    }
}

export default SearchTopEntities;
