import React, { Component } from 'react';

import ManualClusterSelect from '../../../components/manualClusterModal';
import SettingsModal from './SettingsModal';
import OnboardingJoyride from '../../../components/Onboarding';
import {
    SEAONAL_SETTINGS_STEPS,
    SEASONAL_FEATURE_SETTINGS,
    SEASONAL_PAGE,
} from '../../../config/_announcement_keys';
import Items from './items';
import Loading from '../../../components/Loading';

class GSCReportSeasonalKeywords extends Component {
    constructor() {
        super();
        this.offsetStep = 1000;

        this.getSeasons = this.getSeasons.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onOpenSettingsModal = this.onOpenSettingsModal.bind(this);
        this.onCloseSettingsModal = this.onCloseSettingsModal.bind(this);
        this.onRemoveAllSelected = this.onRemoveAllSelected.bind(this);
        this.state = {
            loading: true,
        };
    }

    onRemoveAllSelected(bool) {
        this.setState({
            removeAllSelected: bool,
            selectedItems: [],
        });
    }

    onSelect(action, state) {
        if (action.type === 'REMOVE_ALL') return this.setState({ selectedItems: [] });
        this.setState({ selectedItems: state.ids, removeAllSelected: false });
    }

    getSeasons(seasons) {
        this.setState({
            seasons,
            loading: false,
        });
    }

    onOpenSettingsModal() {
        this.setState({ isOpen: true });
    }

    onCloseSettingsModal() {
        this.setState({ isOpen: false });
    }
    render() {
        const existingSeasonalKeywords = this.state.seasons?.filter((item) => item.keywords);
        return (
            <>
                <div className="actions-btns">
                    <div>
                        <ManualClusterSelect
                            current_url={this.props.current_url}
                            csrf_token={this.props.csrf_token}
                            selectedItems={this.state.selectedItems?.map((id) => ({
                                id,
                                keyword: this.state.allData.find((item) => item.id === id).keyword,
                            }))}
                            header="Add To Keyword List"
                            cluster_type="keyword"
                            onRemoveAllSelected={this.onRemoveAllSelected}
                        />
                    </div>
                    <SettingsModal
                        endpoint_url={this.props.current_url}
                        csrf_token={this.props.csrf_token}
                        getSeasons={this.getSeasons}
                        seasons={this.state.seasons}
                        isOpen={this.state.isOpen ?? false}
                        onOpenSettingsModal={this.onOpenSettingsModal}
                        onCloseSettingsModal={this.onCloseSettingsModal}
                    />
                </div>
                {this.state.loading ? (
                    <Loading />
                ) : existingSeasonalKeywords?.length !== 0 ? (
                    <Items
                        onSelect={this.onSelect}
                        onOpenSettingsModal={this.onOpenSettingsModal}
                        content_id={this.props.content_id}
                        parent_id={this.props.parent_id}
                        seasons={existingSeasonalKeywords}
                    />
                ) : (
                    <div className="w-100 d-flex align-items-center justify-content-center">
                        <button
                            className="button button-filled button-primary btn d-flex align-items-center justify-content-center"
                            onClick={this.onOpenSettingsModal}
                        >
                            Add keywords group
                        </button>
                    </div>
                )}
                {this.state.allData?.[0] && (
                    <OnboardingJoyride
                        endpoint_url={this.props.current_url}
                        csrf_token={this.props.csrf_token}
                        steps={SEAONAL_SETTINGS_STEPS}
                        page={SEASONAL_PAGE}
                        feature={SEASONAL_FEATURE_SETTINGS}
                    />
                )}
            </>
        );
    }
}

export default GSCReportSeasonalKeywords;
