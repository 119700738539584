import Query from '../../../components/Report';

export const query_view_1 = ({ offset = 0 }) =>
    new Query('search-keywords-clusters-view-1', {
        offset,
    });

export const query_view_2 = ({ offset = 0 }) =>
    new Query('search-keywords-clusters-view-2', {
        offset,
    });
