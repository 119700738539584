import { Component } from 'react';
import { TAGS } from './keys';
import Categories from './Categories';
import Entities from './Entities';
import Authors from './Authors';

class ReportComparisonItems extends Component {
    constructor() {
        super();
        this.onChangeTags = this.onChangeTags.bind(this);
        this.showElement = this.showElement.bind(this);
        this.addActive = this.addActive.bind(this);
        this.updateDates = this.updateDates.bind(this);

        this.url = new URL(window.location);
        let view = this.url.searchParams.get('view');
        view = [view ?? 'categories'];

        this.state = {
            types: view,
            datas: {},
        };
    }

    onChangeTags(id, filter) {
        this.url.searchParams.set('view', id);
        if (filter) {
            this.url.searchParams.set('defaultFilter', filter);
        } else {
            this.url.searchParams.delete('defaultFilter');
        }
        history.pushState({}, '', this.url);

        if (id === 'all') {
            return this.setState({
                types: TAGS.map((tag) => tag.id),
            });
        }

        this.setState({
            types: [id],
        });
    }

    showElement(id) {
        return !this.state.types.length || this.state.types.includes(id);
    }

    addActive(id) {
        if (this.state.types.includes('all')) {
            if (id === 'all') return true;
            return false;
        }

        return this.state.types.includes(id);
    }

    updateDates({ key, value, emptyAll = false }) {
        if (emptyAll) {
            return this.setState({
                datas: {},
            });
        }

        this.setState({
            datas: {
                ...this.state.datas,
                [key]: value,
            },
        });
    }

    render() {
        return (
            <>
                <div className="tags-container">
                    {TAGS.map((tag) => (
                        <span
                            className={`tab-item ${this.addActive(tag.id) && 'active-tag'}`}
                            key={tag.id}
                            onClick={() => this.onChangeTags(tag.id, tag.filter)}
                        >
                            {tag.label}
                        </span>
                    ))}
                </div>
                {this.showElement('categories') && (
                    <>
                        <div id="overall-table-content">
                            <div className="row card-row" id="overall-table">
                                <div className="col-12">
                                    <div className="card table-main rounded-main overflow-hidden">
                                        <div className="card-header">
                                            <p className="card-title">Categories</p>
                                        </div>
                                        <div className="card-body">
                                            <Categories
                                                content_id="recommendations-page"
                                                parent_id="recommendations-content"
                                                dates={this.props.dates}
                                                data={this.state.datas.categories}
                                                updateDates={this.updateDates}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {this.showElement('entities') && (
                    <>
                        <div id="overall-table-content">
                            <div className="row card-row" id="overall-table">
                                <div className="col-12">
                                    <div className="card table-main rounded-main overflow-hidden">
                                        <div className="card-header">
                                            <p className="card-title">Entities</p>
                                        </div>
                                        <div className="card-body">
                                            <Entities
                                                content_id="recommendations-page"
                                                parent_id="recommendations-content"
                                                dates={this.props.dates}
                                                data={this.state.datas.entities}
                                                updateDates={this.updateDates}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {this.showElement('authors') && (
                    <>
                        <div id="overall-table-content">
                            <div className="row card-row" id="overall-table">
                                <div className="col-12">
                                    <div className="card table-main rounded-main overflow-hidden">
                                        <div className="card-header">
                                            <p className="card-title">Authors</p>
                                        </div>
                                        <div className="card-body">
                                            <Authors
                                                is_authors_disabled={this.props.is_authors_disabled}
                                                author_message={this.props.author_message}
                                                content_id="recommendations-page"
                                                parent_id="recommendations-content"
                                                dates={this.props.dates}
                                                data={this.state.datas.authors}
                                                updateDates={this.updateDates}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }
}

export default ReportComparisonItems;
