import React, { useState } from 'react';

const HelpBubble = (props) => {
    const [value, setValue] = useState(false);

    let classes = ['bubble-content', value ? 'active' : 'inactive'];

    if (props.active !== 'true') {
        return null;
    }

    return (
        <div className="HelpBubble">
            <button className="btn" onClick={() => setValue(!value)}>
                <i className="icofont-question"></i>
            </button>
            <div className={classes.join(' ')}>{props.text}</div>
        </div>
    );
};

export default HelpBubble;
