import React, { Component } from 'react';

class ExtraBtns extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className="d-flex align-items-center">
                <div className="categories-view">
                    <button
                        className={`categories-view-btn ${
                            (!this.props.toggle || this.props.toggle === 'all') &&
                            'categories-view-btn-active'
                        }`}
                        onClick={() => this.props.onChange('all')}
                    >
                        All
                    </button>
                    <button
                        className={`categories-view-btn ${
                            this.props.toggle === 'growth' && 'categories-view-btn-active'
                        }`}
                        onClick={() => this.props.onChange('growth')}
                    >
                        Growth
                    </button>
                    <button
                        className={`categories-view-btn ${
                            this.props.toggle === 'decline' && 'categories-view-btn-active'
                        }`}
                        onClick={() => this.props.onChange('decline')}
                    >
                        Decline
                    </button>
                </div>
            </div>
        );
    }
}

export default ExtraBtns;
