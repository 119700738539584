import GSCReportDirectories from '../GSCReportDirectories';
import GSCReportSubdomains from '../GSCReportSubdomains';
import Component from '../../../components/Component';
import Loading from '../../../components/Loading';
import query from './query';

class GSCContainerWithTotalClicks extends Component {
    constructor() {
        super();
        this.query = query;
    }

    render() {
        if (this.state.loading) return <Loading />;

        return (
            <>
                {this.props.component_type === 'directories' && (
                    <GSCReportDirectories
                        content_id={this.props.content_id}
                        parent_id={this.props.parent_id}
                        total_clicks={this.state.data?.[0].total_clicks}
                    />
                )}
                {this.props.component_type === 'subdomains' && (
                    <GSCReportSubdomains
                        content_id={this.props.content_id}
                        parent_id={this.props.parent_id}
                        total_clicks={this.state.data?.[0].total_clicks}
                    />
                )}
            </>
        );
    }
}

export default GSCContainerWithTotalClicks;
