import React, { Component } from 'react';
import ReactModal from '../../../components/Modal';
import Loading from '../../../components/Loading';
import { getSeasonsQuery, setSeasonsQuery } from './query';
import { v4 as uuid } from 'uuid';

class SettingsModal extends Component {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.getSeasonsItems = this.getSeasonsItems.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.domain_url = window.filters.get().domain.value;
        this.state = {
            seasons: [{ name: '', keywords: '', id: uuid() }],
            isLoadingItems: false,
            isLoading: false,
            error: null,
        };

        this.seasonsContainerRef = React.createRef();
    }

    componentDidMount() {
        this.setState({
            isLoadingItems: true,
        });
        this.getSeasonsItems();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen && this.props.seasons) {
            this.setState({
                seasons: this.props.seasons,
            });
        }
        if (
            this.state.seasons?.length > prevState?.seasons?.length &&
            !!this.seasonsContainerRef.current
        ) {
            this.seasonsContainerRef.current.scrollTop =
                this.seasonsContainerRef.current?.scrollHeight;
        }
    }

    async getSeasonsItems() {
        try {
            const response = await getSeasonsQuery(this.props.endpoint_url, this.domain_url);
            const items = await response.json();
            const seasons = JSON.parse(items.seasons)?.map((item) => ({ ...item, id: uuid() }));
            const data = seasons?.length !== 0 ? seasons : [{ name: '', keywords: '', id: uuid() }];
            this.props.getSeasons(data);
            this.setState({
                seasons: data,
                isLoadingItems: false,
            });
        } catch (err) {
            this.setState({
                isLoadingItems: false,
            });
            console.log(err);
        }
    }

    async onSubmit(e) {
        e.preventDefault();
        try {
            const season = [...this.state.seasons].filter(
                (item) => !!item.name?.trim() || !!item?.keywords.trim(),
            );

            for (let item of season) {
                if (!item.name || !item.keywords) {
                    return this.setState({
                        error: 'You have empty fields, please fill in or delete.',
                    });
                }
            }

            this.setState({
                isLoading: true,
            });

            const seasonsTrim = season.map((item) => {
                const keywords = item.keywords
                    ?.split(',')
                    ?.map((data) => data.trim())
                    ?.join(',');
                const name = item.name?.trim();
                return { name, keywords, id: item.id };
            });

            const data = {
                domain_url: this.domain_url,
                seasons: seasonsTrim,
            };

            await setSeasonsQuery(this.props.endpoint_url, data, this.props.csrf_token);
            this.getSeasonsItems();
            this.setState({
                isLoading: false,
            });
            this.props.onCloseSettingsModal();
        } catch (err) {
            this.setState({
                isLoisLoadingItemsading: false,
            });
            console.log(err);
        }
    }

    handleChange(name, key, id) {
        const seasons = this.state.seasons.map((item) => {
            if (id === item.id) item[name] = key;
            return item;
        });

        this.setState({
            seasons,
            error: null,
        });
    }

    onAdd() {
        const seasons = [...this.state.seasons];
        seasons.push({
            name: '',
            keywords: '',
            id: uuid(),
        });
        this.setState({
            seasons,
        });
        // this.seasonsContainerRef.current.scrollTop = this.seasonsContainerRef.current.scrollHeight;
    }

    onDelete(id) {
        const seasons = this.state.seasons.filter((item) => item.id !== id);
        if (seasons?.length === 0) seasons.push({ name: '', keywords: '', id: uuid() });
        this.setState({
            seasons,
            error: null,
        });
    }

    render() {
        return (
            <>
                <div className="change-gsc-field-form seasons-settings-btn">
                    <button
                        type="button"
                        className="button button-filled button-primary button-icon"
                        id="name-edit-btn"
                        onClick={this.props.onOpenSettingsModal}
                    >
                        <i className="icon-settings"></i>
                    </button>
                </div>
                <ReactModal modalState={this.props.isOpen} className="width-sm">
                    <form onSubmit={this.onSubmit}>
                        {this.state.isLoadingItems ? (
                            <Loading />
                        ) : (
                            <div className="seasons-container" ref={this.seasonsContainerRef}>
                                {this.state.seasons.map((item, i, arr) => {
                                    return (
                                        <div key={item.id} className="seasons-item-container">
                                            <p className="seasons-item-container-name">
                                                <label>Label</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    onChange={(e) =>
                                                        this.handleChange(
                                                            'name',
                                                            e.target.value,
                                                            item.id,
                                                        )
                                                    }
                                                    value={item.name}
                                                />
                                            </p>
                                            <p className="seasons-item-container-keywords">
                                                <label>Keywords</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    onChange={(e) =>
                                                        this.handleChange(
                                                            'keywords',
                                                            e.target.value,
                                                            item.id,
                                                        )
                                                    }
                                                    value={item.keywords}
                                                />
                                            </p>
                                            <div className="seasons-item-container-btns">
                                                {arr.length - 1 === i && (
                                                    <button
                                                        type="button"
                                                        className="button button-filled button-primary btn d-flex align-items-center justify-content-center"
                                                        onClick={this.onAdd}
                                                    >
                                                        +
                                                    </button>
                                                )}
                                                <button
                                                    type="button"
                                                    className="button button-outline button-primary btn d-flex align-items-center justify-content-center"
                                                    onClick={() => this.onDelete(item.id)}
                                                >
                                                    -
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {this.state.error && (
                            <div className="error-message">{this.state.error}</div>
                        )}
                        <div className="btn-wrapper">
                            <button
                                type="button"
                                className="button button-outline button-primary width-sm btn"
                                onClick={this.props.onCloseSettingsModal}
                                style={{ lineHeight: 'unset' }}
                            >
                                Cancel
                            </button>
                            <button
                                className="button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center"
                                disabled={this.state.isLoading}
                            >
                                <span style={{ marginRight: '5px' }}>Save</span>
                                {this.state.isLoading && <Loading onlyPreloader />}
                            </button>
                        </div>
                    </form>
                </ReactModal>
            </>
        );
    }
}

export default SettingsModal;
