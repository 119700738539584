export const createData = (images) => {
    const data = [
        {
            label: 'Keyword Clusters',
            description:
                'We group all your keywords into clusters for you to easily understand the movement of each group based on similarity)',
            image: images.sample_keyword_cluster,
        },
        {
            label: 'Evergreen',
            description:
                '(One of the most tedious tasks is to identify your evergreen content from news content and keep track on their performance. Now it is a report that is ready for you',
            image: images.sample_evergreen,
        },
        {
            label: 'Evergreen',
            description:
                'Find evergreen opportunities based List Keywords, Question Keywords, Year Keywords, , and more',
            image: images.sample_best_reports,
        },
        {
            label: 'Quick Summary',
            description: '',
            image: images.sample_quick_summary,
        },
        {
            label: 'Branded vs Unbranded share',
            description: '',
            image: images.sample_branded_vs_unbranded,
        },
        {
            label: 'New Keywords',
            description: '',
            image: images.sample_keyword_cluster,
        },
        {
            label: 'New URLs',
            description: '',
            image: images.sample_new_urls,
        },
        {
            label: 'Keywords (New, Lost, etc.)',
            description: '',
            image: images.sample_new_keywords,
        },
        {
            label: 'URLs',
            description: '',
            image: images.sample_urls,
        },
    ];

    return data;
};
