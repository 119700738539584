export const keys = [
    {
        key: (d) => {
            return (
                <div className="col-12 no-wrap ms-3">
                    <a
                        href={
                            `/domains/${window.info.id}/categories/category?categories:in=` +
                            window.encodeURIComponent(d.level)
                        }
                        title={d.level}
                    >
                        {d.level}
                    </a>
                </div>
            );
        },
        label: 'Category',
        type: 'label',
        classes: 'no-wrap col-10',
    },
    {
        key: 'sum_impressions',
        label: 'Impressions',
        format: '0.0a',
        classes: 'col-2 d-flex justify-content-end',
    },
    {
        key: (d, data) => {
            const max = getMax(data);
            const percent = d.sum_impressions / max;
            return percent;
        },
        label: 'Rank',
        bar: true,
        sort: true,
        hidden: true,
        percentReady: true,
    },
];

export const keysPage = [
    {
        key: (d) => {
            return d.relevancy;
        },
        label: 'relevancy',
        change: true,
        classes: 'col-2',
        sort: true,
        disableArrow: true,
    },
    {
        key: (d) => {
            return (
                <div className="col-12 no-wrap ms-3">
                    <a
                        href={
                            `/domains/${window.info.id}/categories/category?categories:in=` +
                            window.encodeURIComponent(d.name)
                        }
                        title={d.name}
                    >
                        {d.name}
                    </a>
                </div>
            );
        },
        label: 'Category',
        type: 'label',
        classes: 'no-wrap col-10',
    },
    {
        key: (d, data) => {
            const max = getMax(data);
            const percent = d.sum_impressions / max;
            return percent;
        },
        label: 'Rank',
        bar: true,
        hidden: true,
        percentReady: true,
    },
];

function getMax(data) {
    return data?.reduce((acc, curr) => {
        if (curr.sum_impressions > acc) acc = curr.sum_impressions;
        return acc;
    }, 0);
}
