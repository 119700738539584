import moment from 'moment';

export const chooseLast60Days = {
    label: 'custom',
    key: 'dates',
    id: 'custom',
    values: {
        start_date: {
            label: 'Start',
            key: 'date',
            value: moment().subtract(61, 'days').format('YYYY-MM-DD'),
            cmp: '>',
        },
        end_date: {
            label: 'End',
            key: 'date',
            value: moment().add(1, 'days').format('YYYY-MM-DD'),
            cmp: '<',
        },
    },
};
