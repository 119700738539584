import numeral from 'numeral';
import { Cell } from '@table-library/react-table-library/table';
import { Fragment } from 'react';
import { showDifficulty, showIntent, showSerpFeatures } from '../../../helpers/commerce';

export const COLUMNS = [
    {
        label: 'Keyword',
        key: 'keyword',
        data: 'keyword',
        sortType: 'string',
        Component: Cell,
        cellSize: 'minmax(200px, 2fr)',
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong className="wripe">{data}</strong>;
        },
    },
    {
        label: 'Search \n Volume',
        key: 'search_volume',
        data: 'search_volume',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col list-col align-left">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'CPC',
        key: 'cpc',
        data: 'cpc',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>${data}</strong>
                </div>
            );
        },
    },
    {
        label: 'CPC Competition',
        key: 'competition',
        data: 'competition',
        info: 'The competitive density of advertisers using a given term for their ads. A value of one indicates the highest level of competition',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{data}</strong>
                </div>
            );
        },
    },
    {
        label: 'Serp \n features',
        key: 'keywords_serp_features',
        data: 'keywords_serp_features',
        Component: Cell,
        cellSize: 'minmax(180px, 2fr)',
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            const values = showSerpFeatures(data);

            return <div className="d-flex align-center">{values}</div>;
        },
    },
    {
        label: 'Keyword \n difficulty',
        key: 'keyword_difficulty_index',
        data: 'keyword_difficulty_index',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            const dot = showDifficulty(data);
            return (
                <div className="d-flex align-items-center">
                    <strong>{data}</strong>
                    {dot}
                </div>
            );
        },
    },
    {
        label: 'Intent',
        key: 'intent',
        data: 'intent',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="d-flex align-center">
                    {showIntent(data)?.map((item, i) => (
                        <Fragment key={i}>{item}</Fragment>
                    ))}
                </div>
            );
        },
    },
    {
        label: 'Number \n of \n results',
        key: 'number_of_results',
        data: 'number_of_results',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-items-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
];
