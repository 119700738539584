import Query from '../../components/Report';

const query = (page) =>
    page
        ? new Query('discover-page-top-relevancy-page', {
              page,
          })
        : new Query('discover-page-top-relevancy');

export default query;
