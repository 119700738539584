import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';

export const COLUMNS = (total_clicks = 1) => [
    {
        label: 'Subdomain',
        key: 'subdomain',
        data: 'subdomain',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return (
                <strong>
                    <a
                        href={`/domains/${window.info.id}/gsc-reports/subdomains/subdomain?subdomain=${data}`}
                        className="link-website d-inline-flex align-items-center justify-content-center"
                        rel="noreferrer"
                    >
                        {data}
                    </a>
                </strong>
            );
        },
    },
    {
        label: 'Traffic Share',
        key: 'traffic_share',
        data: 'traffic_share',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return item.clicks / total_clicks;
            return <strong>{numeral(item.clicks / total_clicks).format('0.00%')}</strong>;
        },
    },
    {
        label: 'avg. Weekly URLs ',
        key: 'urls',
        data: 'urls',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'avg. Weekly Keywords',
        key: 'keywords',
        data: 'keywords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'avg. Position',
        key: 'position',
        data: 'position',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'CTR',
        key: 'ctr',
        data: 'ctr',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return item.clicks / item.impressions;
            return (
                <div>
                    <strong>{numeral(item.clicks / item.impressions).format('0.00%')}</strong>
                </div>
            );
        },
    },
];
