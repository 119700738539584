import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import { CellTree } from '@table-library/react-table-library/tree';
import { tooltips } from '../GSCReportTypePerformance/keys';

export const webNodesTypes = ['NEWS', 'VIDEO', 'IMAGE', 'WEB OTHER'];

export const COLUMNS = [
    {
        label: 'Google Surface',
        key: 'searchtype',
        data: 'searchtype',
        Component: CellTree,
        style: (item) => {
            if (webNodesTypes.includes(item.searchtype)) {
                return { fontSize: '13px' };
            }
            return {};
        },
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <span title={tooltips[data]}>{data}</span>;
        },
    },
    {
        label: 'Clicks Share',
        key: 'share',
        data: 'share',
        Component: Cell,
        style: (item) => {
            if (webNodesTypes.includes(item.searchtype)) {
                return { fontSize: '13px' };
            }
            return {};
        },
        renderCell: ({ item, csvDownload, allData }) => {
            const clickSum = getSum(allData);
            const data = item.clicks / clickSum;
            if (csvDownload) return data;

            return numeral(data).format('0.00%');
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        style: (item) => {
            if (webNodesTypes.includes(item.searchtype)) {
                return { fontSize: '13px' };
            }
            return {};
        },
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data).format('0.0a');
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        style: (item) => {
            if (webNodesTypes.includes(item.searchtype)) {
                return { fontSize: '13px' };
            }
            return {};
        },
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data).format('0.0a');
        },
    },
    {
        label: 'avg. Position',
        key: 'position',
        data: 'position',
        Component: Cell,
        style: (item) => {
            if (webNodesTypes.includes(item.searchtype)) {
                return { fontSize: '13px' };
            }
            return {};
        },
        renderCell: ({ data, csvDownload }) => {
            if (!data) return '-';
            if (csvDownload) return data;

            return numeral(data).format('0.0a');
        },
    },
    {
        label: 'CTR',
        key: 'ctr',
        data: 'ctr',
        Component: Cell,
        style: (item) => {
            if (webNodesTypes.includes(item.searchtype)) {
                return { fontSize: '13px' };
            }
            return {};
        },
        renderCell: ({ item, csvDownload }) => {
            const data = item.clicks / item.impressions;
            if (csvDownload) return data;

            return numeral(data).format('0.00%');
        },
    },
];

export function getSum(data) {
    const sum = data.reduce((acc, curr) => {
        if (!webNodesTypes.includes(curr.searchtype)) acc = acc + curr.clicks;
        return acc;
    }, 0);
    return sum;
}

export function getPercent(allData, newData) {
    const sumAllData = allData.reduce((acc, curr) => acc + curr.pages, 0);
    const percent = (100 * newData) / sumAllData;
    return percent;
}

export function getPercentForChart(allData, oneData) {
    const sumAllData = allData.reduce((acc, curr) => acc + curr, 0);
    const percent = (100 * oneData) / sumAllData;
    return percent;
}
