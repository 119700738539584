import moment from 'moment';
import Query from '../../../../components/Report';

export const query = () => {
    const lastYear = moment().subtract(1, 'years').year();
    const currentYear = moment().year();

    return new Query('opportunities-search-year-keywords', {
        first_day_last_year: `${lastYear}-01-01`,
        last_day_last_year: `${lastYear}-12-31`,
        first_day_current_year: `${currentYear}-01-01`,
        last_day_current_year: `${currentYear}-12-31`,
        last_year: lastYear,
        current_year: currentYear,
    });
};
