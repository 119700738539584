/* eslint-disable */
import Query from '../../../components/Report';

export const query_view_1 = ({ offset = 0, search }) =>
    new Query('search-keywords-keywords-list-view-1', {
        search: searchKeywords(search, 'notEmpty'),
        offset,
    });

export const query_view_2 = ({ offset = 0, status, last_week, prior_week, search }) =>
    new Query('search-keywords-keywords-list-view-2', {
        status: status !== 'all' ? "where status = '" + status + "'" : '',
        search: searchKeywords(search, status),
        last_week,
        prior_week,
        offset,
    });

function searchKeywords(search, status) {
    if (!search) return '';
    const firstWord = status && status !== 'all' ? 'and' : 'where';
    return `${firstWord} (keyword like '% ${search}%' OR keyword like '%${search} %' OR keyword like '% ${search} %')`;
}
