import Component from '../../components/Component';
import query from './query';
import Loading from '../../components/Loading';
import { v4 as uuid } from 'uuid';
import DataTable from '../../components/Datatable';
import { COLUMNS, getStrength } from './keys';
import { scrollForTable } from '../../components/Datatable/utils';

function findParts(acc, curr, ids) {
    const parts = curr.path.split('/');
    if (parts.length > 1) {
        const parent = parts.shift();
        return findParts(
            acc.nodes.find((item) => item.name === parent),
            { ...curr, path: parts.join('/') },
            ids,
        );
    } else {
        const id = uuid();
        ids.push(id);
        if (acc.nodes) {
            acc.nodes.push({ ...curr, name: parts[0], id });
        } else {
            acc.nodes = [{ ...curr, name: parts[0], id }];
        }
    }
    return acc;
}

function createTree(data) {
    if (!data || data?.length === 0) return { nodes: [], ids: [] };
    const ids = [];
    const collectedData = data.reduce((acc, curr) => {
        const levelParts = curr.own_level.slice(0, -1).split('/');
        levelParts.shift();
        if (levelParts.length === 1) {
            const id = uuid();
            ids.push(id);
            acc.push({ ...curr, name: levelParts[0], id });
        } else if (levelParts.length >= 2) {
            const parent = levelParts.shift();
            findParts(
                acc.find((item) => item.name === parent),
                { ...curr, path: levelParts.join('/') },
                ids,
            );
        }
        return acc;
    }, []);
    const allData = [
        {
            name: 'All Categories',
            own_unique_pages: null,
            own_sum_impressions: null,
            own_sum_clicks: null,
            own_avg_ctr: null,
            comp_unique_pages: null,
            comp_sum_impressions: null,
            comp_sum_clicks: null,
            comp_avg_ctr: null,
            nodes: collectedData.map((item) => ({ ...item, haveStrength: true })),
            id: 'all_categories',
        },
    ];
    return { nodes: allData, ids };
}

function getNestedView(data, length) {
    if (!data || data.length === 0) return [];
    const collectedView = data
        .filter((item) => item.own_level.split('/').length === length)
        .map((item) => ({ ...item, name: `all${item.own_level}`, id: uuid(), haveStrength: true }));
    return collectedView;
}

class Analysis extends Component {
    constructor(props) {
        super();
        this.query = query;

        this.chooseView = this.chooseView.bind(this);
        this.getViewData = this.getViewData.bind(this);

        this.images = {
            strong: props.image_strong,
            striking_distance: props.image_striking_distance,
            weak: props.image_weak,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevState.data) !== JSON.stringify(this.state.data)) {
            const { nodes, ids } = createTree(this.state.data);
            this.setState({
                nodes,
                ids,
            });
        }
    }

    chooseView(view) {
        this.setState({ view });
    }

    getViewData(nodes) {
        switch (this.state.view) {
            case 1:
                return nodes;
            case 2: {
                const data = getNestedView(this.state.data, 4);
                return data;
            }
            case 3: {
                const data = getNestedView(this.state.data, 5);
                return data;
            }
            default:
                return nodes;
        }
    }

    getPercentOwnImpCompImp(item) {
        const impPerPage = item.own_sum_impressions / item.own_unique_pages;
        const compImpPerPage = item.comp_sum_impressions / item.comp_unique_pages;
        const value = (impPerPage - compImpPerPage) / compImpPerPage;
        return value;
    }

    getPercentOwnClickCompClick(item) {
        const own_ClicksPerPage = item.own_sum_clicks / item.own_unique_pages;
        const comp_ClicksPerPage = item.comp_sum_clicks / item.comp_unique_pages;
        const value = (own_ClicksPerPage - comp_ClicksPerPage) / comp_ClicksPerPage;
        return value;
    }

    render() {
        if (this.state.loading) {
            return <Loading padding={10} />;
        }

        if (!this.state.data || !this.state.data[0]) return <div>There is no data</div>;

        const sortFns = {
            category: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
            own_unique_pages: (array) =>
                array.sort((a, b) => a.own_unique_pages - b.own_unique_pages),
            own_avg_ctr: (array) => array.sort((a, b) => a.own_avg_ctr - b.own_avg_ctr),
            Own_ClicksPerPage: (array) => array.sort((a, b) => a.own_sum_clicks - b.own_sum_clicks),
            Own_impPerPage: (array) =>
                array.sort((a, b) => a.own_sum_impressions - b.own_sum_impressions),
            strength: (array) => array.sort((a, b) => getStrength(a) - getStrength(b)),
            confidence: (array) => array.sort((a, b) => a.comp_unique_pages - b.comp_unique_pages),
            'Own_ctr-comp_ctr': (array) =>
                array.sort(
                    (a, b) =>
                        a['own_avg_ctr'] -
                        a['comp_avg_ctr'] -
                        (b['own_avg_ctr'] - b['comp_avg_ctr']),
                ),
            'percent-ownImp-compimp': (array) =>
                array.sort((a, b) => {
                    const a_value = this.getPercentOwnImpCompImp(a);
                    const b_value = this.getPercentOwnImpCompImp(b);

                    return a_value - b_value;
                }),
            'percent-ownClick-compClick': (array) =>
                array.sort((a, b) => {
                    const a_value = this.getPercentOwnClickCompClick(a);
                    const b_value = this.getPercentOwnClickCompClick(b);
                    return a_value - b_value;
                }),
        };

        const extraBtns = (hasTree, tree) => (
            <div className="categories-view">
                <button
                    className={`categories-view-btn ${
                        (!this.state.view || this.state.view === 1) && 'categories-view-btn-active'
                    }`}
                    onClick={() => this.chooseView(1)}
                >
                    <i className="icon-account-tree" style={{ marginRight: '3px' }}></i> Nested View
                </button>
                <button
                    className={`categories-view-btn ${
                        this.state.view === 2 && 'categories-view-btn-active'
                    }`}
                    onClick={() => this.chooseView(2)}
                >
                    <i className="icon-list" style={{ marginRight: '3px' }}></i> 2nd Level
                </button>
                <button
                    className={`categories-view-btn ${
                        this.state.view === 3 && 'categories-view-btn-active'
                    }`}
                    onClick={() => this.chooseView(3)}
                >
                    <i className="icon-list" style={{ marginRight: '3px' }}></i> 3rd Level
                </button>
                {hasTree && (
                    <button
                        type="button"
                        className="categories-view-btn"
                        onClick={() => {
                            // check if "all categories" is open or not
                            if (!tree.state.ids.includes('all_categories')) {
                                tree.fns.onToggleById('all_categories');
                            }

                            // minus 1 because ids doesn't include 'all_categoris'
                            // this one for closing all
                            if (tree.state.ids.length - 1 === this.state.ids?.length) {
                                return this.state.ids.forEach((id) => tree.fns.onToggleById(id));
                            }

                            //this one for opening all
                            this.state.ids?.forEach((id) => {
                                if (!tree.state.ids.includes(id)) {
                                    tree.fns.onToggleById(id);
                                }
                            });
                        }}
                    >
                        {tree.state.ids.length - 1 === this.state.ids?.length
                            ? 'Collaps All'
                            : 'Expand All'}
                    </button>
                )}
            </div>
        );

        const data = this.getViewData(this.state.nodes);
        return (
            <DataTable
                nodes={data}
                sortFns={sortFns}
                openedItemIds={['all_categories']}
                COLUMNS={COLUMNS(this.images)}
                fileName="Competitive Analysis"
                extraBtns={extraBtns}
                hasTree={!this.state.view || this.state.view === 1}
                searchWithTree={!this.state.view || this.state.view === 1}
                forceRun={this.forceRun}
                defaultSortKey={'Own_impPerPage'}
                additionalStyles={{
                    Table: `
                        --data-table-library_grid-template-columns : minmax(200px, 4fr)  minmax(120px, 1fr) minmax(140px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(130px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                        ${scrollForTable}
                    `,
                    BaseCell: ` 
                        font-family: 'Raleway';
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        padding: 16px 5px !important;
                        color: #0D182C;
                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                    
                        &.underline {
                            text-decoration: underline;
                        }
                    `,
                }}
            />
        );
    }
}

export default Analysis;
