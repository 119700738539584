import Query from '../../../components/Report';

export const query = ({ offset = 0, seasonKeywords, search }) =>
    new Query('search-evergreen-custom-keywords', {
        offset,
        seasonKeywords: setSeasonalKeywords(seasonKeywords),
        search: searchKeywords(search),
    });

function searchKeywords(search, key = 'and') {
    if (!search) return '';
    return `${key} (keyword like '% ${search}%' OR keyword like '%${search} %' OR keyword like '% ${search} %')`;
}

function setSeasonalKeywords(seasonKeywords) {
    if (!seasonKeywords || seasonKeywords === 'all') return '';

    const items = seasonKeywords.split(',').map((item) => item.trim());
    const geneatedKeywords = items
        .map(
            (item) =>
                `keyword like '% ${item}%' OR keyword like '%${item} %' OR keyword like '% ${item} %'`,
        )
        .join(' OR ');
    return `and (${geneatedKeywords})`;
}

export const setSeasonsQuery = (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/season-keywords/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const getSeasonsQuery = (endpoint_url, domain_url) => {
    return fetch(`${endpoint_url}/domains/season-keywords/?domain_url=${domain_url}`);
};
