export const getClusterItemsQuery = (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/get-cluster-items/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const deleteClusterItem = (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/cluster-keyword-url/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const editClusterQuery = async (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/cluster-actions/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const deleteClusterQuery = async (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/cluster-actions/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
