import Query from '../../../components/Report';

export const query = ({ offset = 0, cluster }) =>
    new Query('search-keywords-clusters-detail-table', {
        offset,
        cluster,
    });

export const query_for_manual = ({ offset = 0, cluster_id, deleted_keywords, user_domain_id }) =>
    new Query('search-keywords-clusters-detail-table-manual', {
        offset,
        cluster_id,
        deleted_keywords: deleted_keywords ? `and keyword_name NOT IN ${deleted_keywords}` : '',
        user_domain_id,
    });
