import Query from '../../../components/Report';

export const query = ({ offset = 0, questions_translation = [], search }) =>
    new Query('search-evergreen-question', {
        offset,
        questions_translation: collectKeywords(questions_translation),
        search: searchKeywords(search),
    });

export const setQuestionsTranslationQuery = async (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/add-domain-gsc-data/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

const collectKeywords = (keywords) => {
    if (keywords?.length === 0) return '';
    const keywordsForQuery = keywords
        .map(
            (keyword) =>
                `keyword like '${keyword} %' or keyword like '% ${keyword} %' or keyword like '% ${keyword}'`,
        )
        .join(' or ');
    return keywordsForQuery;
};

function searchKeywords(search) {
    if (!search) return '';
    return `and (keyword like '% ${search}' OR keyword like '${search} %' OR keyword like '% ${search} %')`;
}
