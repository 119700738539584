import moment from 'moment';

function getDataRange(choice) {
    switch (choice.id) {
        case 'last_week':
            return getLastWeek();
        case 'last_month':
            return getLastMonth();
        case 'current_day':
            return getCurrentDay();
        case 'custom':
            return getCustomDate(choice);
        default:
            return getValuesByDays(choice.days);
    }
}

function getValuesByDays(days = 28) {
    return {
        start_date: {
            label: `Last ${days} Days`,
            key: 'date',
            value: moment()
                .subtract(Number(days) + 1, 'days')
                .format('YYYY-MM-DD'),
            cmp: '>=',
        },
        end_date: {
            label: `Last ${days} Days`,
            key: 'date',
            value: moment().subtract(2, 'days').format('YYYY-MM-DD'),
            cmp: '<=',
        },
    };
}

function getCustomDate(choice) {
    const {
        values: { start_date, end_date },
    } = choice;

    return {
        start_date: {
            label: `Start ${start_date.value}`,
            key: 'date',
            value: moment(start_date.value).format('YYYY-MM-DD'),
            cmp: '>=',
        },
        end_date: {
            label: `End ${end_date.value}`,
            key: 'date',
            value: moment(end_date.value).format('YYYY-MM-DD'),
            cmp: '<=',
        },
    };
}

function getLastWeek() {
    const now = new Date();
    const day = now.getDay();
    return {
        start_date: {
            label: 'Start month',
            key: 'date',
            value: moment()
                .subtract(day + 7, 'days')
                .format('YYYY-MM-DD'),
            cmp: '>=',
        },
        end_date: {
            label: 'End month',
            key: 'date',
            value: moment()
                .subtract(day + 1, 'days')
                .format('YYYY-MM-DD'),
            cmp: '<=',
        },
    };
}

function getCurrentDay() {
    const now = new Date();
    return {
        start_date: {
            label: 'Today',
            key: 'date',
            value: moment(now).format('YYYY-MM-DD'),
            cmp: '>=',
        },
        end_date: {
            label: 'Today',
            key: 'date',
            value: moment(now).format('YYYY-MM-DD'),
            cmp: '<=',
        },
    };
}

export function getLastMonth() {
    const now = new Date();
    const days = now.getDate();
    const month = now.getMonth();
    const lastMonthDays = getDaysOfMonth(month - 1);
    return {
        start_date: {
            label: 'Start month',
            key: 'date',
            value: moment()
                .subtract(days + lastMonthDays - 1, 'days')
                .format('YYYY-MM-DD'),
            cmp: '>=',
        },
        end_date: {
            label: 'End month',
            key: 'date',
            value: moment().subtract(days, 'days').format('YYYY-MM-DD'),
            cmp: '<=',
        },
    };
}

export function getDaysOfMonth(month) {
    switch (month) {
        case -1:
        case 0:
        case 2:
        case 4:
        case 6:
        case 7:
        case 9:
            return 31;
        case 3:
        case 5:
        case 8:
        case 10:
            return 30;
        case 1:
            return 28;
        default:
            return 30;
    }
}

export default getDataRange;
