import { Component } from 'react';
import ReactSelect from '../../../components/ReactSelect';

class ExtraButtons extends Component {
    constructor() {
        super();
    }

    openModal(e) {
        e.stopPropagation();
    }

    render() {
        const options = this.props.seasons
            ? this.props.seasons?.map((item) => ({ label: item.name, value: item.id }))
            : [];
        options.push({
            label: (
                <div className="w-100 d-flex align-items-center justify-content-center">
                    <button
                        className="button button-filled button-primary btn d-flex align-items-center justify-content-center"
                        onClick={this.props.onOpenSettingsModal}
                    >
                        Add/edit
                    </button>
                </div>
            ),
            value: 'add/edit',
        });
        return (
            <div className="categories-view">
                <ReactSelect
                    options={options}
                    value={options.find((x) => x.value === this.props.season)}
                    isSearchable={false}
                    // placeholder="Start Date"
                    onChange={(value) => {
                        if (value !== 'add/edit') {
                            this.props.getData(value);
                        }
                    }}
                    blurInputOnSelect={true}
                    classname="mr-10"
                />
            </div>
        );
    }
}

export default ExtraButtons;
