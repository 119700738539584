import Component from '../../components/Component';
import Timeline from '../../components/Timeline';
import Loading from '../../components/Loading';

import query from './query';
import keys from './keys';
import { fillMissingDates } from '../../helpers/fillEmptyDates';

class Summary extends Component {
    constructor() {
        super();
        this.query = query;
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }
        const filledAllItems = fillMissingDates(this.state.data ?? []);

        return (
            <Timeline
                chartSize="lg"
                metricType="primary"
                data={filledAllItems}
                error={this.state.error}
                keys={keys}
                parent_id={this.props.parent_id}
                content_id={this.props.content_id}
                hasRightAxis
                hasSecondRightAxis
                hasLeftSecondAxis
                leftSecondAxisLabel="Clicks"
                leftAxisLabel="Impressions"
                rightAxisLabel="CTR (%)"
                forceRun={this.forceRun}
                showGoogleFeeds
            />
        );
    }
}

export default Summary;
