import Timeline from '../../../components/Timeline';
import Component from '../../../components/Component';
import Loading from '../../../components/Loading';
import { getLabel } from '../../../helpers/date';

import { query } from './query';
import keys from './keys';

class GSCReportManualUrlClustersDetailChart extends Component {
    constructor(props) {
        super();
        this.query = query(props.cluster_id, props.deleted_urls, props.user_domain_id);
    }

    createLabels(item) {
        return [getLabel(item?.start_date?.value), getLabel(item?.end_date?.value)];
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        return (
            <Timeline
                chartSize="lg"
                metricType="primary"
                data={this.state.data}
                error={this.state.error}
                keys={keys}
                parent_id={this.props.parent_id}
                content_id={this.props.content_id}
                forceRun={this.forceRun}
                xAxisKey="start_date"
                createLabels={this.createLabels}
                hasRightAxis
                rightAxisLabel="CTR (%) / Positions"
                xAxisTitle="Week Start"
                showGoogleFeeds
                hasLeftSecondAxis
                leftSecondAxisLabel="Clicks"
                leftAxisLabel="Impressions"
            />
        );
    }
}

export default GSCReportManualUrlClustersDetailChart;
