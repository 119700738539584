export const opportunities = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Untapped',
        value: 'untapped',
    },
    {
        label: 'High Search Volume',
        value: 'high_search_volume',
    },
    {
        label: 'Low KD',
        value: 'low_competition',
    },
    {
        label: 'Striking Distance',
        value: 'striking_distance',
    },
    {
        label: 'Page 2',
        value: 'page_2',
    },
    {
        label: 'Competitor Only Rankings',
        value: 'competitor_only_rankings',
    },
];
