import React from 'react';
import ReactModal from '../Modal';

class PagesModal extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <>
                <ReactModal
                    modalState={this.props.isOpen}
                    className="width-sm"
                    onClose={this.props.onClose}
                >
                    <h2>Pages</h2>
                    <div className="pages-modal-container">
                        {this.props.pages?.map((item) => {
                            return (
                                <div className="pages-modal-container-item" key={item}>
                                    <a href={item} target="_blank" rel="noreferrer">
                                        {item}
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                            type="button"
                            className="button button-outline button-primary width-sm btn"
                            onClick={this.props.onClose}
                            style={{ lineHeight: 'unset' }}
                        >
                            Close
                        </button>
                    </div>
                </ReactModal>
            </>
        );
    }
}

export default PagesModal;
