import Component from '../../components/Component';
import Loading from '../../components/Loading';

import query from './query';
import keys from './keys';
import Timeline from '../../components/Timeline';

class Summary extends Component {
    constructor() {
        super();
        this.query = query;
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        return (
            <div
                className="Timeline"
                style={{ height: '100%', minHeight: '150px', position: 'relative' }}
            >
                <h3 className="font-weight-bold">New vs Old Pages</h3>
                <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                    How many new pages drive Google Discover to Your site vs older pages?
                </small>
                <div>
                    <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                        "new page" calculation is based on the first day a URL receives any Google
                        Discover traffic, regardless of its original publication date.
                    </small>
                </div>
                {this.state.data[0] ? (
                    <div style={{ height: '400px', position: 'relative' }}>
                        <Timeline
                            chartSize="lg"
                            metricType="primary"
                            data={this.state.data}
                            error={this.state.error}
                            forceRun={this.forceRun}
                            keys={keys}
                            dontShowMetrics
                        />
                    </div>
                ) : (
                    <div>There is no data</div>
                )}
            </div>
        );
    }
}

export default Summary;
