export const defaultWords = [
    'best',
    'review',
    'gift',
    'sale',
    'deal',
    'most',
    'lightest',
    'fastest',
    'brightest',
    'quietest',
    'strongest',
    'safest',
    'toughest',
    'softest',
    'coolest',
    'warmest',
    'smoothest',
    'healthiest',
    'largest',
    'smallest',
    'fluffiest',
    'sharpest',
    'coziest',
    'plushest',
    'cutest',
    'cheapest',
    'trendiest',
    'chicest',
];
