/* eslint-disable */
import React, { useEffect, useState } from 'react';

export default function Pagination({ pagination, sizes, nodes, data }) {
    // Array of buttons what we see on the page
    const [arrOfCurrButtons, setArrOfCurrButtons] = useState([]);

    useEffect(() => {
        const numberOfPages = [];
        for (let i = 1; i <= pagination.state.getPages(data.nodes).length; i++) {
            numberOfPages.push(i);
        }

        let tempNumberOfPages = [...arrOfCurrButtons];
        const currentButton = pagination.state.page + 1;

        let dotsInitial = '...';

        if (numberOfPages.length < 6) {
            tempNumberOfPages = numberOfPages;
        } else if (currentButton >= 1 && currentButton <= 3) {
            tempNumberOfPages = [1, 2, 3, 4, dotsInitial, numberOfPages.length];
        } else if (currentButton === 4) {
            const sliced = numberOfPages.slice(0, 5);
            tempNumberOfPages = [...sliced, dotsInitial, numberOfPages.length];
        } else if (currentButton > 4 && currentButton < numberOfPages.length - 2) {
            const sliced1 = numberOfPages.slice(currentButton - 2, currentButton); // sliced1 (5-2, 5) -> [4,5]
            const sliced2 = numberOfPages.slice(currentButton, currentButton + 1); // sliced1 (5, 5+1) -> [6]
            tempNumberOfPages = [
                1,
                dotsInitial,
                ...sliced1,
                ...sliced2,
                dotsInitial,
                numberOfPages.length,
            ]; // [1, '...', 4, 5, 6, '...', 10]
        } else if (currentButton > numberOfPages.length - 3) {
            const sliced = numberOfPages.slice(numberOfPages.length - 4); // slice(10-4)
            tempNumberOfPages = [1, dotsInitial, ...sliced];
        }

        setArrOfCurrButtons(tempNumberOfPages);
    }, [data.nodes, pagination.state]);

    return (
        <div className="dt-footer">
            <div className="dt-pagination__state">
                <p>
                    {'Showing '}
                    <span>{pagination.state.getPageBoundaries(data.nodes).start}</span>
                    {' to '}
                    <span>{pagination.state.getPageBoundaries(data.nodes).end}</span>
                    {' of '}
                    <span>{data.nodes.length}</span>
                    {' entries '}
                </p>
            </div>

            <div className="dt-pagination__block">
                <div className="dt-pagination__entries">
                    <div className="dropdown">
                        <a
                            className="list-group-item-action"
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <p className="mb-0">
                                Rows per page: {pagination.state.size}{' '}
                                <i className="icon-arrow-down"></i>
                            </p>
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li>
                                <button
                                    type="button"
                                    className={`${
                                        pagination.state.size === nodes.length ? 'active' : ''
                                    } dropdown-item`}
                                    onClick={() => pagination.fns.onSetSize(nodes.length)}
                                >
                                    All
                                </button>
                            </li>
                            <li>
                                {sizes.map((size) => (
                                    <button
                                        key={size}
                                        type="button"
                                        className={`${
                                            pagination.state.size === size ? 'active' : ''
                                        } dropdown-item`}
                                        onClick={() => pagination.fns.onSetSize(size)}
                                    >
                                        {size}
                                    </button>
                                ))}
                            </li>
                        </ul>
                    </div>
                </div>

                <ul className="dt-pagination mb-0">
                    <li>
                        <button
                            type="button"
                            disabled={pagination.state.page === 0}
                            onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
                        >
                            {'Prev'}
                        </button>
                    </li>
                    {arrOfCurrButtons.map((item, index) => (
                        <li key={item + index}>
                            <button
                                type="button"
                                className={pagination.state.page === item - 1 ? 'active' : ''}
                                disabled={item === '...'}
                                onClick={() => pagination.fns.onSetPage(item - 1)}
                            >
                                {item}
                            </button>
                        </li>
                    ))}
                    <li>
                        <button
                            type="button"
                            disabled={
                                pagination.state.page + 1 ===
                                pagination.state.getTotalPages(data.nodes)
                            }
                            onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
                        >
                            {'Next'}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
}
