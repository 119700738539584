import numeral from 'numeral';
import colors, { labelColors } from '../../lib/colors';

export const KEYS_IMPRESSIONS = [
    {
        key: 'imp_index_D',
        label: 'Domain Impressions Index',
        color: colors[1],
        labelColor: labelColors[1],
    },
    {
        key: 'imp_index_C',
        label: 'Competitive Impressions Index',
        color: colors[5],
        labelColor: labelColors[5],
    },
];
export const KEYS_CLICKS = [
    {
        key: 'cl_index_D',
        label: 'Domain Clicks Index',
        format: '0.00%',
        color: colors[1],
        labelColor: labelColors[1],
    },
    {
        key: 'cl_index_C',
        label: 'Competitive Clicks Index',
        color: colors[5],
        labelColor: labelColors[5],
    },
];
export const KEYS_CTR = [
    {
        key: 'D_ctr',
        label: 'Domain CTR Index',
        render: (data) => numeral(data * 100).format('0.00'),
        color: colors[1],
        labelColor: labelColors[1],
    },
    {
        key: 'C_ctr',
        label: 'Competitive CTR Index',
        render: (data) => numeral(data * 100).format('0.00'),
        color: colors[5],
        labelColor: labelColors[5],
    },
];
