export const sendPromoCodeQuery = async ({
    promotion_code,
    amount,
    setPromoCode,
    endpoint_url,
}) => {
    return fetch(
        `${endpoint_url}/customers/discount-coupon-code/?promotion_code=${promotion_code}&amount=${amount}`,
    )
        .then((res) => res.json())
        .then((res) => {
            if (res.status === 200) {
                setPromoCode(res);
            } else {
                setPromoCode(null, res.message);
            }
        })
        .catch((err) => console.log(err));
};
