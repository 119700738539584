import numeral from 'numeral';
import { linReg } from '../../../lib/reduce';

export default [
    {
        key: (d) => {
            let c = linReg(d.clicksL);
            return c.slope;
        },
        label: 'ClicksChange',
        change: true,
        classes: 'col-2',
    },
    {
        key: 'keyword',
        label: 'keyword',
        classes: 'no-wrap col-9',
        render: (d) => (
            <div className="no-wrap col-9">
                <a
                    href={`/domains/${window.info.id}/gsc-reports/keywords/keyword?keyword=${d.keyword}`}
                    className="d-inline-flex align-items-cente"
                    rel="noreferrer"
                >
                    {d.keyword}
                </a>
            </div>
        ),
    },
    {
        key: 'clicks',
        label: 'Clicks',
        classes: 'no-wrap col',
        render: (d) => <div className="no-wrap col">{numeral(d.clicks).format('0.0a')}</div>,
    },
    {
        key: (d, data) => {
            let clicks = d.clicks / data.reduce((e, f) => e + f.clicks, 0);

            return clicks / 2;
        },
        label: 'Rank',
        bar: true,
        sort: true,
        hidden: true,
    },
];
