import colors, { labelColors } from '../../lib/colors';

const keys = [
    {
        key: 'allPages',
        label: 'All Pages',
        color: colors[0],
        labelColor: labelColors[0],
        type: 'bar',
    },
    {
        key: 'newPages',
        label: 'New Pages',
        color: colors[1],
        labelColor: labelColors[1],
        type: 'bar',
    },
];

export default keys;
