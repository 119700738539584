import Query from '../../../components/Report';

const query = ({ offset = 0, keyword, last_week, prior_week }) =>
    new Query('search-keywords-keywords-details-week', {
        offset,
        keyword,
        last_week,
        prior_week,
    });

export default query;
