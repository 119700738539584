import React from 'react';

import { query } from './query';
import DataTable from '../../../../components/Datatable';
import { COLUMNS } from './keys';
import Search from '../../../../components/Search';
import { scrollForTable } from '../../../../components/Datatable/utils';
import Component from '../../../../components/Component';
import { noDataMessage } from '../../../../helpers/noDataMessage';
import Loading from '../../../../components/Loading';

class YearKeywords extends Component {
    constructor(props) {
        super();
        if (!props.data) this.query = query({ offset: 0, search: null });
        this.offsetStep = 1000;

        this.getData = this.getData.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    searchFunction(data, search) {
        return data.filter((item) => item.keyword?.toLowerCase().includes(search.toLowerCase()));
    }

    async getData(value, search) {
        this.query = query({
            offset: 0,
            year: value,
            search: search ?? this.state.search,
        });
        this.query.bind(this.setState.bind(this));
        this.setState({ year: value, data: [] });

        await this.query.update();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDatas({ key: 'yearKeywords', value: this.state.data });
        }
    }

    onSearch(value) {
        this.setState({
            search: value,
        });
        this.getData(this.state.year, value);
    }

    render() {
        const data = this.state.data?.length ? this.state.data : this.props.data;
        if ((!data || !data.length) && this.state.loading) return <Loading />;
        const sortFns = {
            keyword: (array) => array.sort((a, b) => a.keyword.localeCompare(b.keyword)),
            seen: (array) =>
                array.sort((a, b) => new Date(a.firstSeen?.value) - new Date(b.firstSeen?.value)),
            maxClicks: (array) => array.sort((a, b) => a.maxClicks - b.maxClicks),
            avgClicks: (array) => array.sort((a, b) => a.avgClicks - b.avgClicks),
            avgURLs: (array) => array.sort((a, b) => a.avgURLs - b.avgURLs),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            avgCTR: (array) => array.sort((a, b) => a.avgCTR - b.avgCTR),
        };

        const customeSearch = (
            <Search
                onSubmit={this.onSearch}
                placeholder="Search for items"
                defaultValue={this.state.search}
            />
        );

        return (!data || !data.length) && !this.state.filter ? (
            <div>
                {this.props.message ? (
                    <>
                        {this.props.message}
                        <div className="redirect-button">
                            <a
                                className="bg-purple button-close"
                                href="/customers/change_plan/?without_basic=True"
                            >
                                Adjust Plan
                            </a>
                        </div>
                    </>
                ) : (
                    noDataMessage
                )}
            </div>
        ) : (
            <>
                <DataTable
                    nodes={data}
                    sortFns={sortFns}
                    COLUMNS={COLUMNS}
                    fileName="Year Keywords"
                    searchFunction={this.searchFunction}
                    defaultSortKey="maxClicks"
                    forceRun={this.forceRun}
                    customeSearch={customeSearch}
                    additionalStyles={{
                        Table: `
                                    --data-table-library_grid-template-columns : minmax(200px, 3fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                   ${scrollForTable}
                                `,
                        BaseCell: ` font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                    }}
                />
                <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                    <a
                        className="view-all-btn"
                        href={`/domains/${window.info.id}/gsc-reports/year-keywords/`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        View Full Report
                    </a>
                </div>
            </>
        );
    }
}

export default YearKeywords;
