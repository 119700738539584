import Query from '../../components/Report';

const query = ({ entity = '' }) =>
    new Query('discover-page-entity-single', {
        map: {
            entities: 'name',
        },
        entity,
    });

export default query;
