import moment from 'moment';
import { Cell } from '@table-library/react-table-library/table';

const keysFunction = (default_image, page) => [
    {
        key: (d) => {
            return d.title || d.page;
        },
        render: (d) => {
            let title = d.title;

            if (!d.title) {
                title = d.page;
            }

            return (
                <div style={{ width: '100%' }} className="list-col">
                    <div
                        className="w-100 d-flex align-items-center"
                        style={{ paddingTop: 10, paddingBottom: 10 }}
                    >
                        <div style={{ marginRight: '10px', width: '100px' }}>
                            <img
                                onError={(event) => (event.target.src = default_image)}
                                src={d.image ?? default_image}
                                style={{
                                    width: '100px',
                                    display: 'inline-block',
                                    minHeight: '60px',
                                    background: '#BBB',
                                }}
                                loading="lazy"
                            />
                        </div>
                        <div>
                            {/*<p>{ title.length > 90 ? title.substr(0, 90) + '...' : title }</p>*/}
                            <p className="text-title">
                                {title}{' '}
                                <a
                                    target="_blank"
                                    className="link-website d-inline-flex align-items-center justify-content-center"
                                    href={page}
                                    rel="noreferrer"
                                >
                                    <i className="icon-external-link"></i>
                                </a>
                            </p>
                            <div className="additional-info align-center d-flex align-items-center">
                                <strong>Published: {d?.published?.value ?? '-'}</strong>
                                <strong>Modified: {d?.modified?.value ?? '-'}</strong>
                                <strong>
                                    Author:{' '}
                                    {(
                                        <a
                                            className="text-success fw-bold"
                                            href={
                                                `/domains/${window.info.id}/authors/author?author=` +
                                                window.encodeURIComponent(d.author)
                                            }
                                        >
                                            {d.author}
                                        </a>
                                    ) ?? '-'}
                                </strong>
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
        label: 'Page',
        type: 'label',
        classes: 'col-6 d-flex align-items-center justify-content-center',
    },
];

export const COLUMNS = (default_image) => [
    {
        label: 'Title',
        key: 'title',
        data: 'title',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            const title = data;
            return (
                <div className="d-flex">
                    <div className="d-flex align-items-center tracking-pages-name mr-10">
                        <div style={{ float: 'left', marginRight: '10px' }}>
                            <img
                                onError={(event) => (event.target.src = default_image)}
                                src={item.image ?? default_image}
                                loading="lazy"
                                alt="page_image"
                                className="cursor-pointer"
                            />
                        </div>
                        <strong>{title.length > 64 ? title.substr(0, 64) + '...' : title}</strong>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Headline',
        key: 'headline',
        data: 'headline',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <div className="d-flex text-wrap">{data}</div>;
        },
    },
    {
        label: 'Author',
        key: 'author',
        data: 'author',
        Component: Cell,
    },
    {
        label: 'ogTitle',
        key: 'ogtitle',
        data: 'ogtitle',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <div className="d-flex text-wrap">{data}</div>;
        },
    },
    {
        label: 'Schema',
        key: 'schema',
        data: 'schema',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <div className="d-flex text-wrap">{data}</div>;
        },
    },
    {
        label: 'Words',
        key: 'words',
        data: 'words',
        Component: Cell,
    },
    {
        label: 'First Seen',
        key: 'firstSeen',
        data: 'firstSeen',
        Component: Cell,
        renderCell: ({ data }) => {
            return moment(data?.value).format('YYYY-MM-DD');
        },
    },
    {
        label: 'Modified',
        key: 'modified',
        data: 'modified',
        Component: Cell,
        renderCell: ({ data }) => {
            return moment(data?.value).format('YYYY-MM-DD');
        },
    },
    {
        label: 'Published',
        key: 'published',
        data: 'published',
        Component: Cell,
        renderCell: ({ data }) => {
            return moment(data?.value).format('YYYY-MM-DD');
        },
    },
];

export default keysFunction;
