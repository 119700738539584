export const addEmails = (data) => {
    return fetch('/customers/extra-report-emails/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': window.csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const getEmails = () => {
    return fetch('/customers/extra-report-emails/');
};

export const deleteEmail = (data) => {
    return fetch('/customers/extra-report-emails/delete/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': window.csrf_token,
        },
        body: JSON.stringify(data),
    });
};
