import React from 'react';
import Loading from '../../../components/Loading';
import { checkEmail } from './query';
import Select from 'react-select';

class Modal extends React.Component {
    constructor(props) {
        super();

        this.state = {
            active: props.active || false,
            data: {
                name: props?.data?.name ?? '',
                email: props?.data?.email ?? '',
                organization: props?.data?.organization ?? '',
                geo_location: props?.data?.geo_location ?? '',
                password: '',
            },
            errors: {},
            loading: false,
            isAcceptCondition: false,
        };

        this.updateCountries = this.updateCountries.bind(this);
        this.next = this.next.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    toggleActive() {
        this.setState({
            active: !this.state.active,
        });
    }

    updateData(key, e) {
        const newState = { ...this.state };
        newState.data[key] = e.target.val || e.target.value;
        this.setState(newState);
    }

    updateCountries(data) {
        const newState = { ...this.state };
        newState.data.geo_location = data.value;
        this.setState(newState);
    }

    handleCheckboxChange(e) {
        this.setState({
            isAcceptCondition: e.target.checked,
        });
    }

    isPasswordStrong(password) {
        // Regular expression pattern for strong password
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        // Check if the password matches the pattern
        return pattern.test(password);
    }

    async next(e) {
        e.preventDefault();
        this.setState({ loading: true });
        const copyOfData = { ...this.state.data };
        // delete optional fields
        delete copyOfData.organization;
        delete copyOfData.geo_location;

        const errors = {};
        for (let key in copyOfData) {
            if (!copyOfData[key]) {
                errors[key] = 'This field is required';
            } else {
                delete errors[key];
            }
        }
        if (!this.isPasswordStrong(copyOfData['password'])) {
            errors['password'] =
                'Your password does not meet our security requirements. Please create a stronger password that includes at least 8 characters, containing at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character. Allowed special characters are: @$!%*?&';
        }

        if (copyOfData.email) {
            const response = await checkEmail({
                endpointUrl: this.props.current_url,
                email: copyOfData.email,
            });
            const data = await response.json();
            if (data.status === 400)
                errors['email'] = (
                    <span>
                        We’re sorry. This email address already exists. Please try a different email
                        address to register, or{' '}
                        <a href="/accounts/login/" className="login-message">
                            login
                        </a>{' '}
                        to your existing account.
                    </span>
                );
        }

        this.setState({ errors, loading: false });

        if (!Object.keys(errors).length) this.props.next && this.props.next(this.state.data);
    }

    onFocus(e) {
        const errors = { ...this.state.errors };
        delete errors[e.target.name];

        this.setState({ errors });
    }

    render() {
        const optionList = this.props.geo_locations.map((item) => ({
            value: item.geo,
            label: item.location,
        }));
        optionList.unshift({ value: '', label: 'None' });
        return (
            <div className="registration info">
                <form onSubmit={this.next}>
                    <div className="text-field mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Your Name
                        </label>
                        <input
                            name="name"
                            className="form-control"
                            placeholder="John Snow"
                            aria-describedby="Your Name"
                            onChange={this.updateData.bind(this, 'name')}
                            value={this.state.data.name}
                            onFocus={this.onFocus}
                        />
                        {this.state.errors['name'] && (
                            <div className="error-message">{this.state.errors['name']}</div>
                        )}
                    </div>
                    <div className="text-field mb-3">
                        <label htmlFor="companies" className="form-label">
                            Company Name (Optional - displayed on invoice for tax purposes)
                        </label>
                        <input
                            name="organization"
                            type="text"
                            className="form-control"
                            placeholder="WallGuards, Inc."
                            onChange={this.updateData.bind(this, 'organization')}
                            value={this.state.data.organization}
                        />
                    </div>
                    <div className="text-field mb-3">
                        <label htmlFor="countries" className="form-label">
                            Location (Optional)
                        </label>
                        <Select
                            options={optionList}
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    boxShadow: 'none',
                                    padding: '3px 3px',
                                    fontSize: '13px',
                                }),
                            }}
                            value={optionList.find((x) => x.value === this.state.data.geo_location)}
                            theme={(theme) => ({
                                ...theme,
                                height: '45px',
                                borderRadius: '7px',
                                borderColor: '#d9d9d9',
                                transition: 'all 0.2s',
                                colors: {
                                    ...theme.colors,
                                    primary: '#888adb',
                                },
                            })}
                            onChange={this.updateCountries}
                            placeholder="Select Location"
                            isSearchable={true}
                            onFocus={(e) => {
                                const parent = e.nativeEvent.target.parentNode.parentNode;
                                const selectedValue = parent.children[0];
                                selectedValue.style.display = 'none';
                            }}
                            onBlur={(e) => {
                                const parent = e.nativeEvent.target.parentNode.parentNode;
                                const selectedValue = parent.children[0];
                                selectedValue.style.display = 'inline-grid';
                            }}
                            blurInputOnSelect={true}
                        />
                    </div>
                    <div className="text-field mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Email
                        </label>
                        <input
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="john.snow@wallguardinc.com"
                            onChange={this.updateData.bind(this, 'email')}
                            value={this.state.data.email}
                            onFocus={this.onFocus}
                        />
                        {this.state.errors['email'] && (
                            <div className="error-message">{this.state.errors['email']}</div>
                        )}
                    </div>
                    <div className="text-field mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Password
                        </label>
                        <input
                            name="password"
                            type="password"
                            className="form-control"
                            placeholder="password"
                            onChange={this.updateData.bind(this, 'password')}
                            value={this.state.data.password}
                            onFocus={this.onFocus}
                        />
                        {this.state.errors['password'] && (
                            <div className="error-message">{this.state.errors['password']}</div>
                        )}
                    </div>
                    <div className="text-field mb-3 registration-checkbox">
                        <input
                            id="selectForOppening"
                            type="checkbox"
                            value={this.state.isAcceptCondition}
                            onChange={this.handleCheckboxChange}
                        />
                        <label htmlFor="selectForOppening" className="form-label">
                            The selected plan comes with {this.props.monthly_pages} Discover pages,
                            and {this.props.monthly_search_records} Search records per month. Any
                            additional discover pages and search records beyond plan limits will be
                            charged once you are no longer in trial.
                        </label>
                    </div>
                    <div className="mb-3 d-flex justify-content-end">
                        {this.state.loading ? (
                            <Loading onlyPreloader />
                        ) : (
                            <button
                                className="button button-filled button-primary width-sm"
                                type="submit"
                                disabled={!this.state.isAcceptCondition}
                            >
                                Next Step
                            </button>
                        )}
                    </div>
                </form>
            </div>
        );
    }
}

export default Modal;
