import Loading from '../../components/Loading';
import LoadMoreComponent from '../../components/LoadMoreComponent';
import query, { getAllQuery } from './query';
import CheckData from '../../components/CatchError';
import DataTable from '../../components/Datatable';
import { COLUMNS, FIELD_FOR_DOWNLOADS } from './keys';
import { linReg } from '../../lib/reduce';
import { scrollForTable } from '../../components/Datatable/utils';
import { downloadAllCsv } from '../../helpers/downloadAll';

class Summary extends LoadMoreComponent {
    constructor() {
        super();
        this.url = new URL(window.location);
        this.entity = this.url.searchParams.get('entities:in');
        this.query = query({ entity: this.entity });

        this.loadMore = this.loadMore.bind(this);
        this.exportAll = this.exportAll.bind(this);
        this.offsetStep = 25;
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) => {
            if (item.title) return item.title?.toLowerCase().includes(search.toLowerCase());
            return item.page?.toLowerCase().includes(search.toLowerCase());
        });
    }

    async exportAll() {
        try {
            this.setState({
                exportAllLoading: true,
            });
            const allQuery = getAllQuery();
            const response = await allQuery.update();
            downloadAllCsv(response, 'Pages', [
                ...COLUMNS(this.props.default_image),
                ...FIELD_FOR_DOWNLOADS,
            ]);
            this.setState({
                exportAllLoading: false,
            });
        } catch (err) {
            this.setState({
                exportAllLoading: false,
            });
            console.log(err);
        }
    }

    render() {
        // if we get less than offsetStep it means we don't have more data
        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const attr = {
            query,
            offsetStep: this.offsetStep,
            entity: this.entity,
        };

        const sortFns = {
            impressions_delta: (array) =>
                array.sort((a, b) => {
                    let a_imp = linReg(a.impressionsL);
                    let b_imp = linReg(b.impressionsL);
                    return a_imp.slope - b_imp.slope;
                }),
            page: (array) =>
                array.sort((a, b) => {
                    const a_title = a.title ?? a.page;
                    const b_title = b.title ?? b.page;
                    return a_title.localeCompare(b_title);
                }),
            age: (array) => array.sort((a, b) => a.age - b.age),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            ctr: (array) => array.sort((a, b) => a.ctr - b.ctr),
        };

        if (this.state.loading && !this.state.loadMore) {
            return <Loading padding={10} />;
        }

        // if (this.state.allData?.length === 0) return <div>No Data.</div>;

        return (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.allData}
                    loading={this.state.loading}
                    loadMore={this.state.loadMore}
                >
                    <DataTable
                        nodes={
                            this.props.shorted_view
                                ? this.state.allData.slice(0, 10)
                                : this.state.allData
                        }
                        sortFns={sortFns}
                        COLUMNS={COLUMNS(this.props.default_image)}
                        fileName="Pages"
                        forceRun={this.forceRun}
                        searchFunction={this.searchFunction}
                        defaultSortKey="clicks"
                        pdfColumnSize={18}
                        customColumnsForExport={[
                            ...COLUMNS(this.props.default_image),
                            ...FIELD_FOR_DOWNLOADS,
                        ]}
                        additionalStyles={{
                            Table: `
                                --data-table-library_grid-template-columns : minmax(400px, 5fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(150px, 1fr) minmax(120px, 1fr) !important;
                               ${scrollForTable}
                            `,
                            BaseCell: `font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        
                                        `,
                        }}
                    />
                </CheckData>
                {!this.props?.shorted_view &&
                    (this.state.loadMore ? (
                        <Loading padding={10} />
                    ) : (
                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section mr-10">
                            {showLoadMoreBtn && (
                                <button
                                    onClick={() => this.loadMore(attr)}
                                    disabled={this.state.loading}
                                    className="load-more-btn mr-10"
                                >
                                    Load More
                                </button>
                            )}
                            {this.state.exportAllLoading ? (
                                <Loading padding={10} />
                            ) : (
                                <button
                                    onClick={this.exportAll}
                                    disabled={this.state.loading}
                                    className="load-more-btn"
                                >
                                    Export All
                                </button>
                            )}
                        </div>
                    ))}
                {this.state.allData?.length !== 0 &&
                    this.props?.shorted_view &&
                    (this.state.loading && this.state.shorted ? (
                        <Loading padding={10} />
                    ) : (
                        this.props?.shorted_view &&
                        !this.state.loading && (
                            <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                <a
                                    href={`/domains/${this.props?.domain_id}/pages`}
                                    className="load-more-btn"
                                >
                                    View All
                                </a>
                            </div>
                        )
                    ))}
            </>
        );
    }
}
export default Summary;
