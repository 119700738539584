import React, { Component } from 'react';
import ReactSelect from '../../components/ReactSelect';

class ExtraBtns extends Component {
    constructor() {
        super();
    }

    render() {
        const options = [
            { label: 'All', value: '' },
            { label: 'PERSON', value: 'PERSON' },
            { label: 'ORGANIZATION', value: 'ORGANIZATION' },
            { label: 'EVENT', value: 'EVENT' },
            { label: 'CONSUMER_GOOD', value: 'CONSUMER_GOOD' },
            { label: 'WORK_OF_ART', value: 'WORK_OF_ART' },
            { label: 'ADDRESS', value: 'ADDRESS' },
            { label: 'FACILITY', value: 'FACILITY' },
            { label: 'GEOGRAPHICFEATURE', value: 'GEOGRAPHICFEATURE' },
            { label: 'JOBTITLE', value: 'JOBTITLE' },
            { label: 'LOCATION', value: 'LOCATION' },
            { label: 'OTHER', value: 'OTHER' },
        ];
        return (
            <div className="d-flex align-items-center">
                <ReactSelect
                    options={options}
                    isSearchable={false}
                    value={options.find((item) => item.value === this.props.selectedType)}
                    placeholder="Type"
                    onChange={(value) => this.props.handleTypeChange(value)}
                    blurInputOnSelect={true}
                    classname="mr-10"
                />
                <div className="toggle-Invalid-items mt-0">
                    <span>Show Entities with confirmed Wiki page</span>
                    <span
                        className={`switch-button mr-10 ${
                            this.props.toggleWiki ? 'active' : 'not-active'
                        }`}
                        onClick={() => this.props.onToggleWiki()}
                    >
                        <span className="switch-circle" />
                    </span>
                </div>
            </div>
        );
    }
}

export default ExtraBtns;
