import { Component } from 'react';
import Timeline from '../../../../components/Timeline';
import keys, { TAGS } from './keys';
import colors, { labelColors } from '../../../../lib/colors';
import GoogleSurfaces from './GoogleSurfaces';

class TrafficTimeline extends Component {
    constructor() {
        super();

        this.onClick = this.onClick.bind(this);
        this.showAll = this.showAll.bind(this);
        this.getChartData = this.getChartData.bind(this);

        this.state = {
            tag: 'all',
        };
    }

    addActive(id) {
        return this.state.tag === id;
    }

    onClick(id) {
        this.setState({
            tag: id,
        });
    }

    allData() {
        const web_clicks = {
            label: 'Web',
            searchtype: 'web',
            items: this.props.traffic_web.map((item) => item.clicks),
            labelColor: labelColors[0],
            color: colors[0],
            data: this.props.traffic_web,
        };
        const discover_clicks = {
            label: 'Discover',
            searchtype: 'discover',
            items: this.props.traffic_discover.map((item) => item.clicks),
            labelColor: labelColors[1],
            color: colors[1],
            data: this.props.traffic_discover,
        };
        const googlenews_clicks = {
            label: 'Googlenews',
            searchtype: 'googlenews',
            items: this.props.traffic_googlenews.map((item) => item.clicks),
            labelColor: labelColors[2],
            color: colors[2],
            data: this.props.traffic_googlenews,
        };
        const image_clicks = {
            label: 'Image',
            searchtype: 'image',
            items: this.props.traffic_image.map((item) => item.clicks),
            labelColor: labelColors[3],
            color: colors[3],
            data: this.props.traffic_image,
        };
        const news_clicks = {
            label: 'News',
            searchtype: 'news',
            items: this.props.traffic_news.map((item) => item.clicks),
            labelColor: labelColors[4],
            color: colors[4],
            data: this.props.traffic_news,
        };
        const video_clicks = {
            label: 'Video',
            searchtype: 'video',
            items: this.props.traffic_video.map((item) => item.clicks),
            labelColor: labelColors[5],
            color: colors[5],
            data: this.props.traffic_video,
        };
        const allTypes = [
            web_clicks,
            discover_clicks,
            googlenews_clicks,
            image_clicks,
            news_clicks,
            video_clicks,
        ];

        return allTypes;
    }

    showAll() {
        const allTypes = this.allData();
        const chartData = {
            labels: this.props.traffic_web.map((item) => item.keys[0]),
            datasets: allTypes.map((item) => ({
                label: item?.label,
                type: 'line',
                data: item.render ? item.render(item?.items) : item?.items,
                backgroundColor: [item.labelColor, item.labelColor],
                borderColor: item.labelColor,
                borderDash: item?.borderDash ?? [0],
                yAxisID: item.yAxisID ?? 'y',
                hidden: item.hidden ?? false,
                borderRadius: 4,
                tension: 0.4,
            })),
        };

        return chartData;
    }

    showOne(data) {
        const chartData = data && {
            labels: data.map((item) => item.keys[0]),
            datasets: keys.map((item) => ({
                label: item?.label,
                type: item.type ?? 'line',
                data: data.map((data) =>
                    item.render ? item.render(data?.[item.key]) : data?.[item.key],
                ),
                backgroundColor: [item.labelColor, item.labelColor],
                borderColor: item.labelColor,
                borderDash: item?.borderDash ?? [0],
                yAxisID: item.yAxisID ?? 'y',
                hidden: item.hidden ?? false,
                borderRadius: 4,
                tension: 0.4,
            })),
        };

        return chartData;
    }

    getChartData() {
        let data = null;
        let chartData = null;

        switch (this.state.tag) {
            case 'all':
                data = this.props.traffic_web;
                chartData = this.showAll();
                break;
            case 'web':
                data = this.props.traffic_web;
                chartData = this.showOne(data);
                break;
            case 'discover':
                data = this.props.traffic_discover;
                chartData = this.showOne(data);
                break;
            case 'googlenews':
                data = this.props.traffic_googlenews;
                chartData = this.showOne(data);
                break;
            case 'image':
                data = this.props.traffic_image;
                chartData = this.showOne(data);
                break;
            case 'news':
                data = this.props.traffic_news;
                chartData = this.showOne(data);
                break;
            case 'video':
                data = this.props.traffic_video;
                chartData = this.showOne(data);
                break;
            default:
                data = this.props.traffic_web;
                chartData = this.showOne(data);
        }

        return { chartData, data };
    }

    render() {
        const { chartData, data } = this.getChartData();
        return (
            <>
                <div className="row card-row d-flex">
                    <div className="col-12">
                        <div className="card rounded-main overflow-hidden ">
                            <div className="card-body">
                                <div className="tags-container">
                                    {TAGS.map((tag) => (
                                        <span
                                            className={`tab-item ${
                                                this.addActive(tag.id) && 'active-tag'
                                            }`}
                                            key={tag.id}
                                            onClick={() => this.onClick(tag.id)}
                                        >
                                            {tag.label}
                                        </span>
                                    ))}
                                </div>
                                {data?.length === 0 && <div>No Data</div>}
                                <Timeline
                                    chartSize="lg"
                                    metricType="primary"
                                    data={data}
                                    chartData={chartData}
                                    keys={keys}
                                    hasRightAxis={this.state.tag !== 'all'}
                                    hasSecondRightAxis={this.state.tag !== 'all'}
                                    hasLeftSecondAxis={this.state.tag !== 'all'}
                                    leftSecondAxisLabel="Impressions"
                                    leftAxisLabel="Clicks"
                                    rightAxisLabel="CTR (%)"
                                    dontShowMetrics
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <GoogleSurfaces data={this.allData()} />
            </>
        );
    }
}

export default TrafficTimeline;
