import { Component } from 'react';
import Loading from '../../../../components/Loading';
import { deleteBigramQuery, deleteClusterSummaryQuery } from './query';

class DeleteButton extends Component {
    constructor() {
        super();

        this.onDelete = this.onDelete.bind(this);

        this.state = {
            loading: false,
        };
    }

    async onDelete() {
        try {
            this.setState({ loading: true });
            const deleteBigram = deleteBigramQuery(this.props.clusterName);
            const deleteClusterSummary = deleteClusterSummaryQuery(this.props.clusterName);
            await deleteBigram.update();
            await deleteClusterSummary.update();
            this.props.deleteItem(this.props.clusterName);
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <Loading onlyPreloader padding={10} />
                ) : (
                    <button
                        className="ignor-icon-btn text-danger"
                        onClick={this.onDelete}
                        title="Delete Cluster "
                    >
                        <i className="icon-delete"></i>
                    </button>
                )}
            </div>
        );
    }
}

export default DeleteButton;
