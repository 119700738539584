import numeral from 'numeral';

function Metric(props) {
    let getArrow = (inp) => {
        return inp > 0 ? (
            <i className="icofont-dotted-up"></i>
        ) : (
            <i className="icofont-dotted-down"></i>
        );
    };

    return (
        <>
            {props?.metricType === 'primary' ? (
                <div className="metric with-pattern" style={{ background: props.color || '#AAA' }}>
                    <h5 className="metric-name">
                        {props.label}
                        {props.info && (
                            <i
                                title={props.info}
                                className="icon-info cursor-pointer"
                                style={{ marginLeft: '7px' }}
                            ></i>
                        )}
                    </h5>
                    <h3 className="metric-value" style={{ fontFamily: 'helvetica' }}>
                        <span>{numeral(props.value).format(props.format || '0,00')}</span>
                    </h3>
                    <small className={`percent-box ${props.change > 0 ? 'up' : 'down'}`}>
                        {getArrow(props.change)} <span>{numeral(props.change).format('0.0%')}</span>
                    </small>
                    <i className={`box-icon icon-${props.icon}`} />
                </div>
            ) : (
                <div className="metric without-pattern d-inline-flex align-items-center">
                    <div className="info">
                        <h2 className="metric-name">
                            {props.label}{' '}
                            {props.info && (
                                <i
                                    title={props.info}
                                    className="icon-info cursor-pointer"
                                    style={{ marginLeft: '7px' }}
                                ></i>
                            )}
                        </h2>
                        <div className="d-flex align-items-center">
                            <h3 className="metric-value">
                                {numeral(props.value).format(props.format || '0,00')}
                            </h3>
                            <small className={`percent-box ${props.change > 0 ? 'up' : 'down'}`}>
                                {getArrow(props.change)}
                                {numeral(props.change).format('0.0%')}
                            </small>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Metric;
