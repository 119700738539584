import Component from '../../../components/Component';
import Timeline from '../../../components/Timeline';
import { getLabel } from '../../../helpers/date';
import { chartKeys } from './keys';

class Chart extends Component {
    constructor() {
        super();
    }
    render() {
        const chartData = {
            labels: this.props.data.map((item) => [
                getLabel(item?.start_date?.value),
                getLabel(item?.end_date?.value),
            ]),
            datasets: chartKeys.map((item) => ({
                label: item?.label,
                type: item.type ?? 'line',
                data: this.props.data.map((data) =>
                    item.render ? item.render(data[item.key]) : data[item.key],
                ),
                backgroundColor: item.labelColor,
                borderColor: item.labelColor,
                yAxisID: item.yAxisID ?? 'y',
                hidden: item.hidden ?? false,
                borderRadius: 4,
                tension: 0.4,
            })),
        };

        return (
            <Timeline
                chartSize="lg"
                metricType="primary"
                data={this.props.data}
                forceRun={this.forceRun}
                keys={chartKeys}
                chartData={chartData}
                hasRightAxis
                rightAxisLabel="CTR (%)"
                rightSecondAxisLabel="Positions"
                hasSecondRightAxis
                hasLeftSecondAxis
                leftSecondAxisLabel="Clicks"
                leftAxisLabel="Impressions"
                secondRightAxisReversed
            />
        );
    }
}

export default Chart;
