import Loading from '../../components/Loading';
import LoadMoreComponent from '../../components/LoadMoreComponent';

import CheckData from '../../components/CatchError';
import DataTable from '../../components/Datatable';
import AuthorModal from './Modal';
import { COLUMNS } from './keys';
import query from './query';
import { scrollForTable } from '../../components/Datatable/utils';
import MessageCustom from '../../components/MessageCustom';

class Summary extends LoadMoreComponent {
    constructor(props) {
        super();
        this.query = query({
            offset: 0,
            domain: props.domain,
            limit: props.plan_type === 'Basic' || props.plan_type === 'Trial' ? 5 : 100,
        });

        this.onToggle = this.onToggle.bind(this);

        this.loadMore = this.loadMore.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);

        this.offsetStep = 100;
    }

    onOpenModal() {
        this.setState({
            openAuthorModal: true,
        });
    }

    onCloseModal() {
        this.setState({
            openAuthorModal: false,
        });
    }

    searchFunction(data, search) {
        if (!search) return data;
        return data.filter((item) => item.author?.toLowerCase()?.includes(search.toLowerCase()));
    }

    onToggle(value) {
        if (value === 'all') {
            this.setState({
                sortedByToggle: false,
                sortedData: [],
            });
        } else if (value === 'striking_distance_authors') {
            this.setState({
                sortedByToggle: true,
                sortedData: this.state.allData.filter((item) => item.pages >= 5 && item.pages <= 9),
            });
        } else if (value === 'top_authors') {
            this.setState({
                sortedByToggle: true,
                sortedData: this.state.allData.filter((item) => item.pages > 10),
            });
        } else {
            this.setState({
                sortedByToggle: true,
                sortedData: this.state.allData.filter((item) => item.pages < 5),
            });
        }

        this.setState({
            toggle: value,
            sortKey: 'median_impressions_per_author',
        });
    }

    render() {
        if (this.state.loading && !this.state.loadMore) {
            return <Loading padding={10} />;
        }
        // if we get less than offsetStep it means we don't have more data
        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const attr = {
            query,
            offsetStep: this.offsetStep,
            domain: this.props.domain,
            start_date: this.start_date,
            end_date: this.end_date,
        };

        const sortFns = {
            author: (array) => array.sort((a, b) => a.author.localeCompare(b.author)),
            pages: (array) => array.sort((a, b) => a.pages - b.pages),
            entities: (array) => array.sort((a, b) => a.entities - b.entities),
            lifespan: (array) => array.sort((a, b) => a.lifespan - b.lifespan),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            avgWords: (array) => array.sort((a, b) => a.avgWords - b.avgWords),
            median_impressions_per_author: (array) =>
                array.sort(
                    (a, b) => a.median_impressions_per_author - b.median_impressions_per_author,
                ),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            avgctr: (array) =>
                array.sort((a, b) => a.clicks / a.impressions - b.clicks / b.impressions),
        };
        const data = this.state.sortedByToggle ? this.state.sortedData : this.state.allData;

        if (this.state.allData?.length === 1 && this.state.allData[0]?.author === 'NA') {
            this.showErrorMessage = true;
        } else {
            this.showErrorMessage = false;
        }

        return (
            <>
                {this.showErrorMessage && (
                    <MessageCustom
                        message="We encountered an issue while trying to extract author information from your website. This might be because your website doesn't display author names prominently or because of a technical issue. Don't worry, our support team can help! Contact us at info@gddash.com and we'll be happy to assist you."
                        header="ALERT"
                    />
                )}
                <div className="card table-main rounded-main overflow-hidden">
                    <div className="card-header">
                        <p className="card-title">Oppoprtunities</p>
                    </div>
                    <div className="card-body">
                        <div className="entity-toggle-container">
                            <div className="toggle-Invalid-items right-border">
                                <span>All</span>
                                <span
                                    className={`switch-button mr-10 ${
                                        !this.state.toggle || this.state.toggle === 'all'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => this.onToggle('all')}
                                >
                                    <span className="switch-circle" />
                                </span>
                            </div>
                            <div className="toggle-Invalid-items right-border">
                                <span>Top Authors</span>
                                <span
                                    className={`switch-button mr-10 ${
                                        this.state.toggle === 'top_authors'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => this.onToggle('top_authors')}
                                    // title="Displaying Top entities that have 10 pages or more ordered by 'Relative Authority'"
                                >
                                    <span className="switch-circle" />
                                </span>
                            </div>
                            <div className="toggle-Invalid-items right-border">
                                <span>Striking Distance Authors</span>
                                <span
                                    className={`switch-button mr-10 ${
                                        this.state.toggle === 'striking_distance_authors'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => this.onToggle('striking_distance_authors')}
                                    // title="Displaying Top entities that have 10 pages or more ordered by 'Relative Authority'"
                                >
                                    <span className="switch-circle" />
                                </span>
                            </div>
                            <div className="toggle-Invalid-items right-border">
                                <span>Top Authors with little Content</span>
                                <span
                                    className={`switch-button mr-10 ${
                                        this.state.toggle === 'top_Authors_with_little_content'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => this.onToggle('top_Authors_with_little_content')}
                                    // title="Displaying Top entities that have 10 pages or more ordered by 'No. of Pages'"
                                >
                                    <span className="switch-circle" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card table-main rounded-main overflow-hidden">
                    <div className="card-header">
                        <p className="card-title">Authors</p>
                    </div>
                    <div className="card-body">
                        <CheckData
                            content_id={this.props.content_id}
                            parent_id={this.props.parent_id}
                            data={data}
                            loading={this.state.loading}
                            loadMore={this.state.loadMore}
                        >
                            <DataTable
                                nodes={data}
                                sortFns={sortFns}
                                COLUMNS={COLUMNS(this.props.plan_type, this.onOpenModal)}
                                fileName="Authors"
                                defaultSortKey={this.state.sortKey || 'impressions'}
                                forceRun={this.forceRun}
                                searchFunction={this.searchFunction}
                                additionalStyles={{
                                    Table: `
                                --data-table-library_grid-template-columns :  minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(150px, 1fr)  minmax(120px, 1fr) !important;
                                ${scrollForTable}
                            `,
                                    BaseCell: `  font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                                }}
                            />
                        </CheckData>
                        {this.state.loadMore ? (
                            <Loading padding={10} />
                        ) : (
                            showLoadMoreBtn && (
                                <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                    <button
                                        onClick={() => this.loadMore(attr)}
                                        disabled={this.state.loading}
                                        className="load-more-btn"
                                    >
                                        Load More
                                    </button>
                                </div>
                            )
                        )}
                        <AuthorModal
                            isOpen={this.state.openAuthorModal}
                            onClose={this.onCloseModal}
                            plan_type={this.props.plan_type}
                        />
                    </div>
                    {this.props.message && this.propsredirectButton}
                </div>
            </>
        );
    }
}

export default Summary;
