import React, { Component } from 'react';
import { LANGUAGES } from './config';
import ReactSelect from '../../components/ReactSelect';
import { getDiscoverResults, saveEntitiyAdmin } from './query';
import DataTable from '../../components/Datatable';
import { scrollForTable } from '../../components/Datatable/utils';
import { COLUMNS } from './keys';
import Loading from '../../components/Loading';
import { saveEntitiy } from './query';
import { isTrue } from '../../helpers/isTrue';
import Message from '../Message';

class TrackGoogleDiscoverResultsAdd extends Component {
    constructor() {
        super();

        this.urlParams = new URLSearchParams(window.location.search);

        this.entity = this.urlParams.get('entity');
        this.search_by = this.urlParams.get('search_by');

        this.onChageEntity = this.onChageEntity.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.getResults = this.getResults.bind(this);
        this.onChangeRadio = this.onChangeRadio.bind(this);
        this.onGetEntityById = this.onGetEntityById.bind(this);
        this.onChageEntityId = this.onChageEntityId.bind(this);
        this.onChageEntityAdmin = this.onChageEntityAdmin.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);
        this.handleSelectAdmin = this.handleSelectAdmin.bind(this);
        this.onBack = this.onBack.bind(this);
        this.onSave = this.onSave.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);

        this.step1Ref = React.createRef();
        this.step2Ref = React.createRef();
        this.step3Ref = React.createRef();

        this.state = {
            data: null,
            entity: this.entity ?? '',
            country: {
                gl: null,
                hl: null,
                search_url: null,
            },
            countryAdmin: {
                gl: null,
                hl: null,
                search_url: null,
            },
            loading: false,
            selectedItem: null,
            activeCollapse: 1,
            trackLoading: false,
            trackLoadingAdmin: false,
            entityId: '',
            entityAdmin: '',
            isExistsEntity: true,
            entityIdLoading: false,
            message: '',
            searchType: this.search_by === 'id' ? 'id' : 'name',
        };
    }

    componentDidMount() {
        if (this.state.entity) {
            this.getResults();
        }
    }

    onChageEntity(e) {
        this.setState({
            entity: e.target.value,
        });
    }

    onChageEntityAdmin(e) {
        this.setState({
            entityAdmin: e.target.value,
        });
    }

    onChageEntityId(e) {
        this.setState({
            entityId: e.target.value,
        });
    }

    handleSelect(value) {
        this.setState({ country: value, error: null });
    }

    handleSelectAdmin(value) {
        this.setState({ countryAdmin: value, error: null });
    }

    async getResults(e) {
        try {
            e?.preventDefault();
            this.setState({ data: null, loading: true, selectedItem: null });
            const response = await getDiscoverResults(
                this.state.entity,
                this.props.current_url,
                this.state.searchType,
            );
            const data = await response.json();
            const collectedData = data?.itemListElement?.map((item) => ({
                type: Array.isArray(item?.result['@type'])
                    ? item?.result['@type'].join(' , ')
                    : item?.result['@type'],
                name: item?.result?.name,
                description: item?.result?.description,
                url: item?.result?.detailedDescription?.url,
                score: item?.resultScore,
                image: item?.result?.image?.contentUrl,
                id: item.result?.['@id'],
            }));

            this.setState({
                data: collectedData,
                loading: false,
                activeCollapse: 2,
            });
            this.step2Ref.current.click();
        } catch (err) {
            this.setState({
                loading: false,
                message: err?.message,
            });
            console.log(err);
        }
    }

    onChangeRadio(id) {
        this.setState({ error: null, selectedItem: id, activeCollapse: 3 });
        this.step3Ref.current.click();
    }

    async onSave(isAdmin) {
        try {
            let params = {};
            if (isAdmin) {
                const entity = this.state.entityContent;
                this.setState({ trackLoadingAdmin: true });
                params = {
                    country: entity.country,
                    lang: entity.language,
                    entity_name: entity.entity_name,
                    entity_url: entity.entity_url,
                    kg_id: entity.kg_id,
                    search_url: entity?.search_url,
                    image_url: entity?.image_url,
                    description: entity?.description,
                    score: entity?.score,
                    entity_type: entity?.entity_type,
                };
            } else {
                if (!this.state.selectedItem || !this.state.country)
                    return this.setState({ error: 'Please check country and entity' });
                const selectedItem = this.state.data.find(
                    (item) => item.id === this.state.selectedItem,
                );
                this.setState({ trackLoading: true });
                params = {
                    country: this.state.country?.gl,
                    lang: this.state.country?.hl,
                    entity_name: selectedItem?.name?.trim(),
                    entity_url: selectedItem?.url,
                    kg_id: selectedItem?.id,
                    search_url: this.state.country?.search_url,
                    image_url: selectedItem?.image,
                    description: selectedItem?.description,
                    score: selectedItem?.score,
                    entity_type: selectedItem?.type,
                };
            }

            const response = await saveEntitiy(
                this.props.current_url,
                params,
                this.props.csrf_token,
            );
            const data = await response.json();
            if (data?.status === 201) {
                window.location.href = '/tools/google-discover-data/';
            } else {
                if (isAdmin) {
                    this.setState({
                        trackLoadingAdmin: false,
                        message: data?.message,
                    });
                } else {
                    this.setState({
                        trackLoading: false,
                        error: 'Something went wrong!',
                    });
                }
            }
        } catch (err) {
            console.log(err);
            this.setState({ trackLoading: false, trackLoadingAdmin: false, message: err?.message });
        }
    }

    async onGetEntityById(e) {
        try {
            e?.preventDefault();
            if (!this.state.country)
                return this.setState({ error: 'Please check country and entity' });

            this.setState({ entityIdLoading: true });
            const params = {
                country: this.state.countryAdmin?.gl,
                lang: this.state.countryAdmin?.hl,
                kg_id: this.state.entityId?.trim(),
                search_url: this.state.countryAdmin?.search_url,
                entity_name: this.state?.entityAdmin?.trim(),
            };

            const response = await saveEntitiyAdmin(
                this.props.current_url,
                params,
                this.props.csrf_token,
            );
            const data = await response.json();
            if (data?.status === 200 || data?.status === 404) {
                this.setState({
                    entityContent: data.result,
                    entityMessage: data.message,
                    entityIdLoading: false,
                });
            } else {
                this.setState({ entityIdLoading: false, message: 'Something went wrong' });
            }
        } catch (err) {
            console.log(err);
            this.setState({ entityIdLoading: false, message: err?.message });
        }
    }

    onBack() {
        this.setState({ activeCollapse: this.state.activeCollapse - 1 });
    }

    onCancel() {
        this.setState({
            entityContent: null,
            entityId: '',
            entityAdmin: '',
            entityMessage: '',
        });
    }

    deleteMessage() {
        this.setState({ message: '' });
    }

    onClickOnStep(step) {
        if (step === 1) {
            this.step1Ref.current.classList.add('active');
        } else {
            this.step1Ref.current.classList.remove('active');
        }

        if (step === 2) {
            this.step2Ref.current.classList.add('active');
        } else {
            this.step2Ref.current.classList.remove('active');
        }

        if (step === 3) {
            this.step3Ref.current.classList.add('active');
        } else {
            this.step3Ref.current.classList.remove('active');
        }
    }

    handleTypeChange(e) {
        this.setState({
            searchType: e.target.value,
        });
    }

    render() {
        const languageOptions = LANGUAGES.map((item) => ({
            label: `${item.name} - ${item.lang}`,
            value: {
                gl: item.gl,
                hl: item.hl,
                search_url: item.url,
            },
        }));

        return (
            <>
                {isTrue(this.props.is_superuser) && (
                    <div className="card table-main rounded-main ">
                        <div className="card-header">Track New Entity By ID</div>{' '}
                        <div className="card-body">
                            <div>
                                <div>
                                    <div>
                                        <form onSubmit={this.onGetEntityById}>
                                            <label>Entity Name</label>
                                            <input
                                                type="text"
                                                className="entity-input"
                                                value={this.state.entityAdmin}
                                                onChange={this.onChageEntityAdmin}
                                            />
                                            <label>Entity ID</label>
                                            <input
                                                type="text"
                                                className="entity-input"
                                                value={this.state.entityId}
                                                onChange={this.onChageEntityId}
                                            />
                                            <ReactSelect
                                                options={languageOptions}
                                                value={languageOptions.find(
                                                    (x) =>
                                                        x.value.gl === this.state.countryAdmin.gl &&
                                                        x.value.hl === this.state.countryAdmin.hl,
                                                )}
                                                label="Country"
                                                placeholder="Country"
                                                onChange={this.handleSelectAdmin}
                                                blurInputOnSelect={true}
                                                classname="mt-10 mr-0"
                                                isSearchable
                                            />
                                            <button
                                                className="button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center"
                                                disabled={this.state.entityIdLoading}
                                            >
                                                Submit
                                            </button>
                                        </form>
                                        {this.state.entityIdLoading && (
                                            <Loading padding="15px 0 0" />
                                        )}
                                    </div>
                                    {this.state.entityContent && (
                                        <div className="save-entity-content">
                                            <p>Entity - {this.state.entityMessage}</p>
                                            <div className="save-entity-btns">
                                                <button
                                                    className="button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center"
                                                    onClick={() => this.onSave(true)}
                                                    disabled={this.state.trackLoadingAdmin}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    className="button button-outline button-primary width-sm btn d-flex align-items-center justify-content-center"
                                                    onClick={this.onCancel}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            {this.state.trackLoadingAdmin && (
                                                <Loading padding="15px 0 0" />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="card table-main rounded-main ">
                    <div className="card-header">Track New Entity</div>{' '}
                    <div className="card-body">
                        <div id="entity-steps">
                            <div>
                                <div className="header-button-container">
                                    <button
                                        ref={this.step1Ref}
                                        id="step1"
                                        className="collapsed header-buttons panel-heading active"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#step1Content"
                                        aria-expanded="false"
                                        aria-controls="step1Content"
                                        onClick={() => this.onClickOnStep(1)}
                                    >
                                        <span className="collaps-btn">1. Search Entity</span>
                                    </button>
                                </div>
                                <div
                                    id="step1Content"
                                    className="collapse panel-collapse show"
                                    aria-labelledby="headingStep1"
                                    data-bs-parent="#entity-steps"
                                >
                                    <form onSubmit={this.getResults}>
                                        <div className="track-entity-radio-btns">
                                            <div>Search By</div>
                                            <div className="track-entity-radio-btns-content">
                                                <label htmlFor="radio-name">Entity Name</label>
                                                <input
                                                    type="radio"
                                                    id="radio-name"
                                                    name="entity-type"
                                                    value="name"
                                                    onChange={this.handleTypeChange}
                                                    checked={this.state.searchType === 'name'}
                                                />
                                                <label htmlFor="radio-id">Entity Id</label>
                                                <input
                                                    type="radio"
                                                    id="radio-id"
                                                    name="entity-type"
                                                    value="id"
                                                    onChange={this.handleTypeChange}
                                                    checked={this.state.searchType === 'id'}
                                                />
                                            </div>
                                        </div>
                                        {/* <label>Entity</label> */}
                                        <input
                                            type="text"
                                            className="entity-input"
                                            value={this.state.entity}
                                            onChange={this.onChageEntity}
                                            placeholder={
                                                this.state.searchType === 'name'
                                                    ? 'Search By Name'
                                                    : 'Search By Id'
                                            }
                                        />
                                        <button
                                            className="button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center"
                                            disabled={this.state.loading || !this.state.entity}
                                        >
                                            Submit
                                        </button>
                                    </form>
                                    {this.state.loading && <Loading padding="15px 0 0" />}
                                </div>
                            </div>
                            <div>
                                <div className="header-button-container">
                                    <button
                                        ref={this.step2Ref}
                                        id="step2"
                                        className="collapsed header-buttons panel-heading"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#step2Content"
                                        aria-expanded="false"
                                        aria-controls="step2Content"
                                        onClick={() => this.onClickOnStep(2)}
                                    >
                                        <span className="collaps-btn">2. Select Entity</span>
                                    </button>
                                </div>
                                <div
                                    id="step2Content"
                                    className="collapse panel-collapse"
                                    aria-labelledby="headingStep2"
                                    data-bs-parent="#entity-steps"
                                >
                                    {!this.state.loading &&
                                        (this.state.data && this.state.data?.length ? (
                                            <DataTable
                                                nodes={this.state.data}
                                                sortFns={() => {}}
                                                onlyTable
                                                COLUMNS={COLUMNS(
                                                    this.props.default_image,
                                                    this.onChangeRadio,
                                                    this.state.selectedItem,
                                                )}
                                                hasSort={false}
                                                isSingleSelect
                                                additionalStyles={{
                                                    Table: `
                                                        --data-table-library_grid-template-columns : minmax(40px, 0.3fr) minmax(200px, 1.5fr) minmax(120px, 1fr)  minmax(200px, 1.5fr) minmax(200px, 2fr) minmax(80px, 1fr) !important;
                                                        ${scrollForTable}
                                                    `,
                                                    BaseCell: ` font-family: 'Raleway';
                                                                font-weight: 500;
                                                                font-size: 16px;
                                                                line-height: 19px;
                                                                padding: 16px 5px !important;
                                                                color: #0D182C;
                                                                border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                            
                                                                &.underline {
                                                                    text-decoration: underline;
                                                                }
                                                                `,
                                                }}
                                            />
                                        ) : (
                                            <div>No Items</div>
                                        ))}
                                </div>
                            </div>
                            <div>
                                <div className="header-button-container">
                                    <button
                                        ref={this.step3Ref}
                                        id="step3"
                                        className="collapsed header-buttons panel-heading"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#step3Content"
                                        aria-expanded="false"
                                        aria-controls="step3Content"
                                        onClick={() => this.onClickOnStep(3)}
                                    >
                                        <span className="collaps-btn">
                                            3. Select Country and language
                                        </span>
                                    </button>
                                </div>
                                <div
                                    id="step3Content"
                                    className="collapse panel-collapse"
                                    aria-labelledby="headingStep3"
                                    data-bs-parent="#entity-steps"
                                >
                                    <>
                                        <ReactSelect
                                            options={languageOptions}
                                            value={languageOptions.find(
                                                (x) =>
                                                    x.value.gl === this.state.country.gl &&
                                                    x.value.hl === this.state.country.hl,
                                            )}
                                            label="Country"
                                            placeholder="Country"
                                            onChange={this.handleSelect}
                                            blurInputOnSelect={true}
                                            classname="mt-10 mr-0"
                                            isSearchable
                                        />
                                        {this.state.error && (
                                            <div className="error-message">{this.state.error}</div>
                                        )}
                                        <button
                                            className="button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center mt-10"
                                            disabled={
                                                !this.state.selectedItem ||
                                                !this.state.country?.gl ||
                                                this.state.trackLoading
                                            }
                                            onClick={() => this.onSave()}
                                        >
                                            Track entity
                                        </button>
                                        {this.state.trackLoading && <Loading padding="15px 0 0" />}
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>{' '}
                </div>
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={'error'}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default TrackGoogleDiscoverResultsAdd;
