/* eslint-disable */
import { Component } from 'react';
import Message from '../../components/MessageCustom';
import { isTrue } from '../../helpers/isTrue';

class OverviewErros extends Component {
    constructor(props) {
        super();
        if (props.domains) {
            this.domains = JSON.parse(props.domains);
        }
        this.isShownButton = this.isShownButton.bind(this);

        this.state = {
            show: false,
        };
    }

    componentDidMount() {
        this.isShownButton();
    }

    isShownButton() {
        if (isTrue(this.props.is_deactivated) || isTrue(this.props.is_viewer_user)) {
            return;
        }

        if (!isTrue(this.props.linked_accounts)) {
            const message =
                "You haven't connected the app to GSC, please connect you Google Search Console account.";
            this.setState({
                message,
                header: 'ALERT',
                show: true,
                message_type: 'alert-danger',
                link: '/customers/link',
                link_name: 'Connect GSC',
            });
            return;
        }

        if (this.domains?.length === 0) {
            const message = "You haven't added any domains yet to the app, please add a domain.";
            this.setState({
                message,
                header: 'ALERT',
                show: true,
                message_type: 'alert-danger',
                link: '/domains/link/',
                link_name: 'Add domains',
            });
            return;
        }
        const activeDomain = this.domains?.find((item) => item.active);
        if (!activeDomain) {
            const message =
                "You haven't activated any domain yet, please activate one of your domains.";
            this.setState({
                message,
                header: 'ALERT',
                message_type: 'alert-danger',
                show: true,
                link: '/settings/',
                link_name: 'Activate your domains',
            });
            return;
        }

        // if (this.props.sub_status === 'trialing') {
        //     const message =
        //         'Your plan is in Trial, please activate your plan to enjoy all the features.';
        //     this.setState({
        //         message,
        //         header: 'NOTE',
        //         message_type: 'alert-warning',
        //         show: true,
        //         link: '/customers/change_plan/?without_basic=True',
        //         link_name: 'Activate Plan',
        //     });
        //     return;
        // }
    }

    render() {
        return (
            this.state.show && (
                <Message
                    header={this.state.header}
                    message={this.state.message}
                    link={this.state.link}
                    link_name={this.state.link_name}
                    message_type={this.state.message_type}
                />
            )
        );
    }
}

export default OverviewErros;
