import numeral from 'numeral';
import { Cell } from '@table-library/react-table-library/table';

function createLink(path) {
    const url = new URL(window.location);
    const intent = url.searchParams.get('intent');

    if (intent) path += `&intent=${intent}`;
    return path;
}

export const COLUMNS = (project_id, own_domain, keyword, onSelect, selectedDomains = []) => [
    {
        label: 'Rank',
        key: 'rank',
        data: 'rank',
        Component: Cell,
        cellSize: 'minmax(80px, 1fr)',
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <strong className="wripe">
                    {' '}
                    <input
                        className="css-e0dnmk"
                        type="checkbox"
                        style={{ marginRight: '10px' }}
                        onChange={() => onSelect(item.domain)}
                        checked={selectedDomains?.includes(item.domain)}
                        disabled={
                            selectedDomains?.length >= 5 && !selectedDomains?.includes(item.domain)
                        }
                    />
                    #{data}
                </strong>
            );
        },
    },
    {
        label: 'Domain',
        key: 'domain',
        data: 'domain',
        sortType: 'string',
        Component: Cell,
        cellSize: 'minmax(200px, 2fr)',
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <strong className="wrap-text d-flex align-items-center">
                    {data?.includes('https') ? (
                        <img
                            src={`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${data}&size=32`}
                            alt="domain-img"
                            className="domain_img"
                            loading="lazy"
                        />
                    ) : (
                        <img
                            src={`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${data} &size=32`}
                            alt="domain-img"
                            className="domain_img"
                            loading="lazy"
                        ></img>
                    )}
                    <a
                        href={createLink(
                            `/commerce/${project_id}/domain/?domain=${encodeURIComponent(
                                data,
                            )}&own_domain=${own_domain}&keyword=${keyword}`,
                        )}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span>{data}</span> <span style={{ fontSize: '20px' }}>→</span>
                    </a>
                </strong>
            );
        },
    },
    {
        label: 'URLs',
        key: 'urls',
        data: 'urls',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Project Keywords',
        key: 'project_keywords',
        data: 'project_keywords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Project SV',
        key: 'project_sv',
        data: 'project_sv',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'KW Difficulty',
        key: 'kw_difficulty',
        data: 'kw_difficulty',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Organic \n Traffic',
        key: 'organicTraffic',
        data: 'organicTraffic',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Organic \n Keywords',
        key: 'organicKeywords',
        data: 'organicKeywords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Ad Cost',
        key: 'adCost',
        data: 'adCost',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
];

export function getPercentForChart(allData, oneData) {
    const sumAllData = allData.reduce((acc, curr) => acc + curr, 0);
    const percent = (100 * oneData) / sumAllData;
    return percent;
}
