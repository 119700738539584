import React from 'react';

export default class Loading extends React.Component {
    constructor() {
        super();

        this.state = {
            opacity: 0.3,
            in: true,
        };

        this.glow = this.glow.bind(this);
        this.timeId = window.setInterval(this.glow, 50);
    }

    componentWillUnmount() {
        clearInterval(this.timeId);
    }

    glow() {
        let newState = { ...this.state };
        if (this.state.in) {
            newState.opacity += 0.1;
            newState.in = newState.opacity > 1 ? false : true;
        } else {
            newState.opacity -= 0.1;
            newState.in = newState.opacity < 0 ? true : false;
        }

        this.setState(newState);
    }

    render() {
        let styles = {
            padding: this.props.padding || 0 + 'px',
        };

        if (this.props.span) {
            return (
                <span className="Loading" style={styles}>
                    <i
                        className="icofont-spinner-alt-1"
                        style={{ opacity: this.state.opacity }}
                    ></i>{' '}
                    {!this.props.onlyPreloader && 'Loading...'}
                </span>
            );
        }

        return (
            <div className="Loading" style={styles}>
                <i className="icofont-spinner-alt-1" style={{ opacity: this.state.opacity }}></i>{' '}
                {!this.props.onlyPreloader && 'Loading...'}
            </div>
        );
    }
}
