import LoadMoreComponent from '../../components/LoadMoreComponent';

import query from './query';
import Table from './Table';

class Summary extends LoadMoreComponent {
    constructor(props) {
        super();
        this.query = query({ offset: 0, domain: props.domain });

        this.offsetStep = 100;
    }

    getTestTopics(data, avg) {
        return data.filter((item) => item.impressions >= avg && item.pages < 5);
    }

    getTopics(data, avg) {
        return data.filter((item) => item.impressions >= avg && item.pages > 10);
    }

    render() {
        return (
            <>
                <div className="row card-row d-flex" id="recommendations-topics">
                    <div className="col-12">
                        <div className="card table-main rounded-main overflow-hidden">
                            <div className="card-header">
                                <p className="card-title">Continue to write about these Topics</p>
                                <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                                    The following entities are working well for you based on
                                    Attributed Impressions in Google Discover, which means you have
                                    a good chances to appear for these entities for interested
                                    audience. We recommend writing more content focused on these
                                    entities and and/or ensure there is a continuous coverage for
                                    these entities on weekly basis.
                                </small>
                            </div>
                            <div className="card-body">
                                <Table
                                    query={query}
                                    data={this.state.data}
                                    allData={this.state.allData}
                                    domain={this.props.domain}
                                    isLoadMore={this.state.loadMore}
                                    loadMore={this.loadMore}
                                    loading={this.state.loading}
                                    content_id="recommendations-topics"
                                    parent_id="recommendations-content"
                                    direct_parent_id="recommended-topics-container"
                                    getData={this.getTopics}
                                    forceRun={this.forceRun}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row card-row d-flex" id="recommendations-test-topics">
                    <div className="col-12">
                        <div className="card table-main rounded-main overflow-hidden">
                            <div className="card-header">
                                <p className="card-title">Test writing more about these Topics</p>
                                <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                                    The following entities are in near distance, it is not clear yet
                                    based on data if these entities are good traffic drivers since
                                    you haven&apos;t covered them that much. We recommend testing
                                    some of these entities by increasing the content coverage to see
                                    if they work for you.
                                </small>
                            </div>
                            <div className="card-body">
                                <Table
                                    query={query}
                                    data={this.state.data}
                                    allData={this.state.allData}
                                    domain={this.props.domain}
                                    isLoadMore={this.state.loadMore}
                                    loadMore={this.loadMore}
                                    loading={this.state.loading}
                                    content_id="recommendations-test-topics"
                                    parent_id="recommendations-content"
                                    direct_parent_id="recommended-topics-container"
                                    getData={this.getTestTopics}
                                    forceRun={this.forceRun}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Summary;
