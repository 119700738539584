import Timeline from '../../../components/Timeline';
import { Component } from 'react';
import { module1Keys } from './keys';

class PositionModule1 extends Component {
    constructor() {
        super();
    }

    render() {
        const chartData = {
            labels: this.props.data?.map((item) => item.position),
            datasets: module1Keys.map((item) => ({
                label: item?.label,
                type: item.type ?? 'line',
                data: this.props.data?.map((data) =>
                    item.render ? item.render(data[item.key]) : data[item.key],
                ),
                backgroundColor: [item.labelColor, item.labelColor],
                borderColor: item.labelColor,
                yAxisID: item.yAxisID ?? 'y',
                hidden: item.hidden ?? false,
                borderRadius: 4,
                tension: 0.4,
            })),
        };

        return (
            <Timeline
                chartSize="lg"
                metricType="primary"
                data={this.props.data}
                error={this.props.error}
                keys={module1Keys}
                parent_id={this.props.parent_id}
                content_id={this.props.content_id}
                chartData={chartData}
                hasRightAxis
                forceRun={this.props.forceRun}
                leftAxisLabel="CTR (%)"
                rightAxisLabel="Keywords"
                xAxisTitle="Position"
                hasLeftSecondAxis
                leftSecondAxisLabel="Clicks"
            />
        );
    }
}

export default PositionModule1;
