import { Component } from 'react';
import ReactModal from '../../components/Modal';
import { updateNotes } from './query';

class NotesModal extends Component {
    constructor(props) {
        super();

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onHandleClose = this.onHandleClose.bind(this);

        this.state = {
            loading: false,
            value: props.selectedNote ?? '',
        };
    }

    onChange(e) {
        const value = e.target.value;

        this.setState({
            value,
        });
    }

    onHandleClose() {
        this.setState({
            value: '',
        });
        this.props.onClose();
    }

    async onSubmit(e) {
        e.preventDefault();
        try {
            this.setState({
                loading: true,
            });

            const body = {
                domain_id: this.props?.selectedDomain,
                note: this.state.value,
            };

            const response = await updateNotes(body, this.props.csrf_token);
            const data = await response.json();
            if (response?.status === 200) {
                this.props.onOpenMessage(data?.message, 'success');
                this.props.updateUrls(this.props?.selectedDomain, this.state.value);
                this.onHandleClose();
            } else {
                this.props.onOpenMessage(data?.message, 'error');
            }
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    render() {
        return (
            <ReactModal
                modalState={this.props.isOpen}
                className="width-sm"
                onClose={this.onHandleClose}
            >
                <form onSubmit={this.onSubmit}>
                    <h2>Notes</h2>
                    <div className="pages-modal-container">
                        <div className="text-field mb-3">
                            {/* <label>{this.props.selectedDomain}</label> */}
                            <textarea
                                className="form-control qualifieres-input"
                                onChange={(e) => this.onChange(e)}
                                value={this.state.value}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                            className="button button-filled button-primary width-sm btn"
                            style={{ lineHeight: 'unset', marginRight: '10px' }}
                            disabled={this.state.loading}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="button button-outline button-primary width-sm btn"
                            onClick={this.onHandleClose}
                            style={{ lineHeight: 'unset' }}
                        >
                            Close
                        </button>
                    </div>
                </form>
            </ReactModal>
        );
    }
}

export default NotesModal;
