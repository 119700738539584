import colors, { labelColors } from '../../lib/colors';

export default [
    {
        key: 'impressions',
        label: 'Impressions / Page',
        format: '0,00a',
        color: colors[3],
        labelColor: labelColors[0],
    },
    {
        key: 'clicks',
        label: 'Clicks / Page',
        format: '0,00a',
        color: colors[4],
        labelColor: labelColors[2],
        yAxisID: 'y1',
    },
];
