import { Cell } from '@table-library/react-table-library/table';
import { linReg } from '../../../lib/reduce';
import numeral from 'numeral';

let get = function (key, d, data) {
    if (typeof key == 'function') {
        return key(d, data);
    }

    return d[key];
};

let getImpCtr = (d, data) => {
    let imp = d.impressions / data.reduce((e, f) => e + f.impressions, 0);
    let ctr = d.ctr / (data.reduce((e, f) => e + f.ctr, 0) / data.length);

    return (imp + ctr) / 2;
};

export const COLUMNS = [
    {
        label: 'Attr. Imp. Δ',
        key: 'attr_imp',
        data: 'attr_imp',
        Component: Cell,
        infoLink: '/support/attributed-impressions',
        info: 'Attributed Impressions is own unique metric to measure entity authority on site. Click on the icon to find out more.',
        renderCell: ({ item, allData, csvDownload }) => {
            let c = linReg(item.impressionsL);
            if (csvDownload) return c.slope;

            let dir = c.slope > 0 ? 'up' : 'down';
            let arrow =
                dir === 'up' ? (
                    <i className="icofont-dotted-up"></i>
                ) : (
                    <i className="icofont-dotted-down"></i>
                );
            let val = (
                <span className="extra-options">
                    <div className="list-item extra-options-content">
                        <span className={'change ' + dir}>
                            {arrow} {numeral(Math.abs(c.slope)).format('0a%')}
                        </span>
                    </div>
                </span>
            );

            let red =
                get(getImpCtr, item, allData) /
                allData.reduce((e, f) => {
                    return e + get(getImpCtr, f, allData);
                }, 0);

            let bar = <div className="list-bar" style={{ width: red * 100 + '%' }}></div>;
            return (
                <div style={{ position: 'relative' }}>
                    {val}
                    {bar}
                </div>
            );
        },
    },
    {
        label: 'Concept',
        key: 'name',
        data: 'name',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div>
                    <a
                        href={
                            `/domains/${window.info.id}/entities/entity?entities:in=` +
                            encodeURIComponent(data)
                        }
                    >
                        {data}
                    </a>
                </div>
            );
        },
    },
    {
        label: 'Type',
        key: 'type',
        data: 'type',
        Component: Cell,
    },
    {
        label: 'No. of Pages \n (Content Production)',
        key: 'pages',
        data: 'pages',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data).format('0.00');
        },
    },
    {
        label: 'Salience',
        key: 'salience',
        data: 'salience',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return item.salience;

            let c = linReg(item.salienceL);
            let cr = c.slope >= 0 ? '+' : '-';

            return (
                <div>
                    <strong>{numeral(item.salience).format('0.00')}</strong>
                    <br />
                    {cr}
                    {numeral(Math.abs(c.slope)).format('0.0')}%
                </div>
            );
        },
    },
    {
        label: 'Attr. Imp. \n (Authority)',
        key: 'impressionsL',
        data: 'impressionsL',
        infoLink: '/support/attributed-impressions',
        info: 'Attributed Impressions is own unique metric to measure entity authority on site. Click on the icon to find out more.',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return item.impressions;

            let c = linReg(data);
            let cr = c.slope >= 0 ? '+' : '-';

            return (
                <div>
                    <strong>{numeral(item.impressions).format('0.0a')}</strong>
                    <br />
                    {cr}
                    {numeral(Math.abs(c.slope)).format('0.0')}%
                </div>
            );
        },
    },
    {
        label: 'Attr. Imp. per Page',
        key: 'impressions_per_page',
        data: 'impressions_per_page',
        infoLink: '/support/attributed-impressions',
        info: 'Attributed Impressions is own unique metric to measure entity authority on site. Click on the icon to find out more.',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return item.impressions / item.pages;

            return (
                <div>
                    <strong>{numeral(item.impressions / item.pages).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'CTR \n (Engagement)',
        key: 'ctr',
        data: 'ctr',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            let c = linReg(item.ctrL);
            let cr = c.slope >= 0 ? '+' : '-';
            return (
                <div>
                    <strong>{numeral(data).format('0.0%')}</strong>
                    <br />
                    {cr}
                    {numeral(Math.abs(c.slope)).format('0.0')}%
                </div>
            );
        },
    },
];
