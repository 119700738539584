import numeral from 'numeral';
import { Cell } from '@table-library/react-table-library/table';

export const COLUMNS = [
    {
        label: 'Name',
        key: 'name',
        data: 'name',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col list-col align-left">
                    <a
                        href={
                            `/domains/${window.info.id}/entities/entity?entities:in=` +
                            encodeURIComponent(data)
                        }
                    >
                        {data}
                    </a>
                </div>
            );
        },
    },
    {
        label: 'Type',
        key: 'type',
        data: 'type',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <div className="col list-col align-left">{data}</div>;
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'CTR',
        key: 'ctr',
        data: 'ctr',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const value = item.clicks / item.impressions;
            if (csvDownload) return value;

            return (
                <div className="col align-center">
                    <strong>{numeral(value).format('0.00%')}</strong>{' '}
                </div>
            );
        },
    },
];
