import { Component } from 'react';
import DataTable from '../../../../../../components/Datatable';
import CheckData from '../../../../../../components/CatchError';
import CommerceFilterIntent from '../../../../../../components/CommerceFilterIntent';

class Item extends Component {
    render() {
        const extraBtns = () => (
            <CommerceFilterIntent
                select={this.props.select}
                handleSelectChange={this.props.handleSelectChange}
            />
        );
        return (
            <div className="card table-main rounded-main overflow-hidden">
                <div className="card-header">
                    <p className="card-title">{this.props.title}</p>
                </div>
                <div className="card-body">
                    <CheckData
                        data={this.props.data}
                        loading={!this.props.data && this.props.loading}
                        loadMore={this.props.loadMore}
                    >
                        <DataTable
                            nodes={this.props.data}
                            sortFns={this.props.sortFns}
                            COLUMNS={this.props.columns}
                            fileName=""
                            extraBtns={extraBtns}
                            defaultSortKey={this.props.defaultSortKey ?? ''}
                            EXTRA_HEADER={this.props.EXTRA_HEADER}
                            forceRun={this.props.forceRun}
                            searchFunction={this.props.searchFunction}
                            additionalStyles={this.props.additionalStyles}
                            customEmptyMessage="No Data available"
                        />
                    </CheckData>
                </div>
            </div>
        );
    }
}

export default Item;
