import Query from '../../../components/Report';

const query = ({ offset = 0, current_url, last_week, prior_week }) =>
    new Query('search-urls-urls-details-table', {
        offset,
        current_url,
        last_week,
        prior_week,
    });

export default query;
