import moment from 'moment';

export const toDate = (date) => new Date(date);

export const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

export const compareDate = (first, second) => {
    if (first.getFullYear() < second.getFullYear()) return true;
    if (first.getFullYear() === second.getFullYear()) {
        if (first.getMonth() < second.getMonth()) return true;
        if (first.getMonth() === second.getMonth() && first.getDate() <= second.getDate())
            return true;
    }
    return false;
};

export const getDaysArray = function (start, end) {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt).toISOString().split('T')[0]);
    }
    return arr;
};

export const toStringDate = (date) => date.toISOString().substr(0, 10);

export const getLabel = (data) => {
    if (Array.isArray(data)) return data;
    return moment(data).format('ll').split(',')[0];
};

export const getTime = (date) => {
    if (!date) return 0;
    return new Date(date).getTime();
};
