import React from 'react';
import { v4 as uuid } from 'uuid';

class Component extends React.Component {
    constructor() {
        super();

        this.forceRun = this.forceRun.bind(this);
        this.state = {
            loading: true,
        };
    }

    async componentDidMount() {
        if (this.query) {
            this.query.bind(this.setState.bind(this));
            const results = await this.query.update();
            const data = results?.map((x) => ({ id: uuid(), ...x }));
            this.setState({
                data,
            });
        }
    }

    forceRun() {
        if (this.query) this.query.update({ force: true, alreadyInQueue: true });
    }
}

export default Component;
