export const getValues = (endpoint_url, params) => {
    return fetch(
        `${endpoint_url}/customers/user-feature-gide/?domain_url=${params.domain_url}&page=${params.page}&feature=${params.feature}`,
    );
};

export const setValues = (endpoint_url, csrf_token, data) => {
    return fetch(`${endpoint_url}/customers/user-feature-gide/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
