import { Cell } from '@table-library/react-table-library/table';
import colors, { labelColors } from '../../../lib/colors';
import numeral from 'numeral';

export const COLUMNS = [
    {
        label: 'Subdomain',
        key: 'subdomain',
        data: 'subdomain',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{data}</strong>;
        },
    },
    {
        label: 'Week',
        key: 'week',
        data: 'week',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{data}</strong>;
        },
    },
    {
        label: 'avg. Weekly URLs ',
        key: 'urls',
        data: 'urls',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'avg. Weekly Keywords',
        key: 'keywords',
        data: 'keywords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'avg. Position',
        key: 'position',
        data: 'position',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'CTR',
        key: 'ctr',
        data: 'ctr',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return (
                <div>
                    <strong>{numeral(data).format('0.00%')}</strong>
                </div>
            );
        },
    },
];

export const chartKeys = [
    {
        key: 'clicks',
        label: 'Clicks',
        color: colors[0],
        labelColor: labelColors[0],
        icon: 'clicks',
        yAxisID: 'y3',
    },
    {
        key: 'impressions',
        label: ' Impressions',
        color: colors[1],
        labelColor: labelColors[1],
        icon: 'search',
    },
    {
        key: 'position',
        label: 'Position',
        color: colors[4],
        labelColor: labelColors[4],
        icon: 'pagev-view',
        yAxisID: 'y2',
        hidden: true,
    },
    {
        key: 'ctr',
        label: 'CTR',
        format: '0.00%',
        yAxisID: 'y4',
        render: (data) => numeral(data * 100).format('0.00'),
        color: colors[5],
        labelColor: labelColors[5],
        icon: 'ctr',
        hidden: true,
    },
    {
        key: 'urls',
        label: 'Urls',
        color: colors[2],
        labelColor: labelColors[2],
        icon: 'pagev-view',
        yAxisID: 'y1',
        type: 'bar',
    },
    {
        key: 'keywords',
        label: 'Keywords',
        color: colors[3],
        labelColor: labelColors[3],
        icon: 'pagev-view',
        yAxisID: 'y1',
        type: 'bar',
    },
];
