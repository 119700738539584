import { Cell } from '@table-library/react-table-library/table';

export const COLUMNS = ({ openModal }) => [
    {
        label: 'DOMAIN \n NAME',
        key: 'domain_name',
        data: 'domain_name',
        Component: Cell,
    },
    {
        label: '"BEST" \n TRANSLATION',
        key: 'best_translation',
        data: 'best_translation',
        info: 'What is the translation for the word "Best" in the site language? We use it to generate LIST reports.',
        Component: Cell,
    },
    {
        label: 'BRANDED \n TERMS',
        key: 'branded_terms',
        data: 'branded_terms',
        info: 'List your branded terms for your site, for example "site name", "site.com", etc. We use it for branded terms reports.',
        Component: Cell,
    },
    {
        label: 'EVERGREEN \n WEEKS',
        key: 'evergreen_weeks',
        data: 'evergreen_weeks',
        info: 'What is the number of week an article should rank for you to consider it "evergreen" article. We recommend 5.',
        Component: Cell,
    },
    {
        label: '"QUESTIONS \n TRANSLATION"',
        key: 'questions',
        data: 'questions',
        // info: 'We can collect all GSC data for your Search Keywords and URLs for all devices. Please note this may incur additional charges as we collect and analyze more data. You can keep as "MOBILE" to minimize the charges.',
        Component: Cell,
    },
    {
        label: 'DEVICE',
        key: 'device',
        data: 'device',
        info: 'We can collect all GSC data for your Search Keywords and URLs for all devices. Please note this may incur additional charges as we collect and analyze more data. You can keep as "MOBILE" to minimize the charges.',
        Component: Cell,
    },
    {
        label: 'ACTION',
        key: 'action',
        data: 'action',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return null;
            return (
                <button
                    type="button"
                    className="button button-filled button-primary button-icon"
                    id="name-edit-btn"
                    onClick={() => openModal(item)}
                >
                    <i className="icon-edit"></i>
                </button>
            );
        },
    },
];
