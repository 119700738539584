import numeral from 'numeral';
import { getPercent } from './showDelta';

function showWithArrow(dataL) {
    const val = getPercent(dataL.at(-1), dataL[0]);
    const dir = val > 0 ? 'up' : 'down';

    const arrow =
        dir == 'up' ? (
            <i className="icofont-dotted-up"></i>
        ) : (
            <i className="icofont-dotted-down"></i>
        );

    return (
        <div className={'delta-number'}>
            <div className={'difference-percentage extra-options'}>
                <span className={`change ${dir}`}>
                    {arrow} {numeral(Math.abs(val)).format('0.0')}%
                </span>
            </div>
        </div>
    );
}

export function collectCell(data, dataL, format) {
    const delta = showWithArrow(dataL);

    return (
        <div>
            <div className="prior-now-data">
                <strong>{numeral(data).format(format || '0.0a')}</strong>
                <br />
                {delta}
            </div>
        </div>
    );
}
