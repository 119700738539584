export const keys = [
    {
        key: 'author',
        label: 'Author',
        type: 'label',
        classes: 'no-wrap col-10',
    },
    {
        key: 'impressions',
        label: 'Impressions',
        format: '0.0a',
        classes: 'col-2 d-flex justify-content-end',
    },
    {
        key: (d, data) => {
            const max = getMax(data);
            const percent = d.impressions / max;
            return percent;
        },
        label: 'Rank',
        bar: true,
        sort: true,
        hidden: true,
        percentReady: true,
    },
];

function getMax(data) {
    return data?.reduce((acc, curr) => {
        if (curr.impressions > acc) acc = curr.impressions;
        return acc;
    }, 0);
}
