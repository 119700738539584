import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import { Fragment } from 'react';

export const COLUMNS = [
    {
        label: 'URL',
        key: 'url',
        data: 'url',
        sortType: 'string',
        cellSize: ' minmax(250px, 2.5fr)',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="d-flex row justify-content-between w-100">
                    <strong className="col-9 wrap-text">{data}</strong>
                    <div className="col-2 d-flex align-items-center">
                        <a
                            target="_blank"
                            className=" link-website d-inline-flex align-items-center justify-content-center"
                            href={data}
                            rel="noreferrer"
                        >
                            <i className="icon-external-link"></i>
                        </a>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Cluster Keywords',
        key: 'cluster_keywords',
        data: 'cluster_keywords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Compeition',
        key: 'compeition',
        data: 'compeition',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'CPC',
        key: 'cpc',
        data: 'cpc',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>${numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Keywords sv',
        key: 'keywords_sv',
        data: 'keywords_sv',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Median \n Keyword \n Difficulty',
        key: 'median_keyword_difficulty',
        data: 'median_keyword_difficulty',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="d-flex align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Position',
        key: 'position',
        data: 'position',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Intent',
        key: 'intent',
        data: 'intent',
        Component: Cell,
        cellSize: 'minmax(180px, 1.5fr)',
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="d-flex align-center">
                    {showIntent(data)?.map((item, i) => (
                        <Fragment key={i}>{item}</Fragment>
                    ))}
                </div>
            );
        },
    },
    {
        label: 'URL Adwords Cost',
        key: 'url_adwords_cost',
        data: 'url_adwords_cost',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'URL Adwords Keywords',
        key: 'url_adwords_keywords',
        data: 'url_adwords_keywords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'URL Adwords Traffic',
        key: 'url_adwords_traffic',
        data: 'url_adwords_traffic',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'URL Organic Cost',
        key: 'url_organic_cost',
        data: 'url_organic_cost',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'URL Organic Keywords',
        key: 'url_organic_keywords',
        data: 'url_organic_keywords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'URL Organic Traffic',
        key: 'url_organic_traffic',
        data: 'url_organic_traffic',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col align-center">
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
];

function showIntent(value) {
    const box = [];
    value.split(',').forEach((item) => {
        const number = item.trim();
        switch (number) {
            case '0':
                box.push(
                    <div className="intent intent-c" title="Commercial">
                        C
                    </div>,
                );
                break;
            case '1':
                box.push(
                    <div className="intent intent-i" title="Informational">
                        I
                    </div>,
                );
                break;
            case '2':
                box.push(
                    <div className="intent intent-n" title="Navigational">
                        N
                    </div>,
                );
                break;
            case '3':
                box.push(
                    <div className="intent intent-t" title="Transactional">
                        T
                    </div>,
                );
                break;
        }
    });
    return box;
}
