import { Component } from 'react';
import CommerceSettingsModal from '../../../../CommerceSettingsModal';
import Message from '../../../../../Message';
import { defaultWords } from '../../../../CommerceSettingsModal/config';

class Qualifiers extends Component {
    constructor() {
        super();

        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onOpenMessage = this.onOpenMessage.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);

        this.state = {
            isOpen: false,
        };
    }

    onOpen() {
        this.setState({
            isOpen: true,
        });
    }

    onClose() {
        this.setState({
            isOpen: false,
        });
    }

    onOpenMessage(message, message_tag) {
        this.setState({
            message,
            message_tag: message_tag,
        });
    }

    deleteMessage() {
        this.setState({
            message: '',
            message_tag: '',
        });
    }

    render() {
        const words = this.props.commerce_project?.qualifiers
            ? this.props.commerce_project?.qualifiers?.split(',')
            : defaultWords;
        return (
            <>
                <div className="card table-main rounded-main overflow-hidden">
                    <div className="card-header d-flex justify-content-between">
                        <p className="card-title"> Qualifiers</p>
                        <button className="commerce-action-settings-btn" onClick={this.onOpen}>
                            <i className="icon-settings nav-icon"></i>
                        </button>
                    </div>
                    <div className="card-body">
                        <div className="qualifiers-items">
                            {words?.map((item) => (
                                <a
                                    href={`/commerce/${this.props.project_id}/?keyword=${this.props.keyword}&own_domain=${this.props.own_domain}&view=keywords&search=${item}`}
                                    className="button button-outline button-primary"
                                    key={item}
                                >
                                    {item}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
                <CommerceSettingsModal
                    updateItemQuiliers={this.props.updateQualifieres}
                    isOpen={this.state.isOpen}
                    onClose={this.onClose}
                    csrf_token={this.props.csrf_token}
                    selectedItem={this.props.commerce_project}
                    onOpenMessage={this.onOpenMessage}
                />
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tag}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default Qualifiers;
