import { Cell } from '@table-library/react-table-library/table';
import { CellTree } from '@table-library/react-table-library/tree';
import Sparkline from '../../../components/Sparklines';
import numeral from 'numeral';
import DeleteButton from './DeleteButton';
import { collectCell, getPercent } from '../../../helpers/showDelta';

export const COLUMNS_1 = (deleteItem) => [
    {
        label: 'Cluster Name',
        key: 'cluster_name',
        data: 'cluster_name',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <strong>
                    <a
                        href={`/domains/${
                            window.info.id
                        }/gsc-reports/keyword-clusters/cluster?cluster=${encodeURIComponent(data)}`}
                        className="d-inline-flex align-items-center justify-content-center"
                        rel="noreferrer"
                    >
                        {data}
                    </a>
                </strong>
            );
        },
    },
    {
        label: 'Keywords',
        key: 'weekly_keywords',
        data: 'weekly_keywords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Trend',
        key: 'keywords_sparkline',
        data: 'keywords_sparkline',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return '-';

            return (
                <Sparkline
                    dataL={item.keywordsL}
                    keyName="Keywords"
                    labels={item.weeksL.map((x) => x.value)}
                />
            );
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Trend',
        key: 'clicks_sparkline',
        data: 'clicks_sparkline',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return '-';

            return (
                <Sparkline
                    dataL={item.clicksL}
                    keyName="Clicks"
                    labels={item.weeksL.map((x) => x.value)}
                />
            );
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'clicks_delta',
        data: 'clicks_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.clicksL.at(-1), item.clicksL[0]);
            return collectCell(item.clicksL.at(-1), item.clicksL[0]);
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'URLs',
        key: 'weekly_urls',
        data: 'weekly_urls',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Trend',
        key: 'urls_sparkline',
        data: 'urls_sparkline',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return '-';

            return (
                <Sparkline
                    dataL={item.urlsL}
                    keyName="URLs"
                    labels={item.weeksL.map((x) => x.value)}
                />
            );
        },
    },
    {
        label: 'Position',
        key: 'avgPosition',
        data: 'avgPosition',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Lifespan',
        key: 'lifespan',
        data: 'lifespan',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'CTR',
        key: 'avgctr',
        data: 'avgctr',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.clicks / item.impressions;
            if (csvDownload) return ctrValue;

            return <strong>{numeral(ctrValue).format('0.00%')}</strong>;
        },
    },
    {
        label: 'Actions',
        key: 'actions',
        data: 'actions',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return null;

            return <DeleteButton clusterName={item.cluster_name} deleteItem={deleteItem} />;
        },
    },
];

export const COLUMNS_2 = (deleteItem) => [
    {
        label: 'Cluster',
        key: 'keyword',
        data: 'keyword',
        Component: CellTree,
        renderCell: ({ data, item }) => {
            if (item.isClusterName) {
                return (
                    <strong>
                        <a
                            href={`/domains/${window.info.id}/gsc-reports/keyword-clusters/cluster?cluster=${data}`}
                            className="d-inline-flex align-items-center justify-content-center"
                            rel="noreferrer"
                        >
                            {data}
                        </a>
                    </strong>
                );
            }
            return <strong>{data}</strong>;
        },
    },
    {
        label: 'Keywords',
        key: 'keywords',
        data: 'keywords',
        Component: Cell,
        renderCell: ({ data }) => {
            return <strong>{numeral(data).format('0 a')}</strong>;
        },
    },
    {
        label: 'avg. Weekly URLs ',
        key: 'urls',
        data: 'urls',
        Component: Cell,
        renderCell: ({ data }) => <strong>{numeral(data).format('0.0a')}</strong>,
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data }) => <strong>{numeral(data).format('0.0a')}</strong>,
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data }) => <strong>{numeral(data).format('0.0a')}</strong>,
    },
    {
        label: 'avg. Position',
        key: 'position',
        data: 'position',
        Component: Cell,
        renderCell: ({ data }) => <strong>{numeral(data).format('0.0a')}</strong>,
    },
    {
        label: 'Lifespan',
        key: 'lifespan',
        data: 'lifespan',
        Component: Cell,
        renderCell: ({ data }) => <strong>{numeral(data).format('0.0a')}</strong>,
    },
    {
        label: 'CTR',
        key: 'ctr',
        data: 'ctr',
        Component: Cell,
        renderCell: ({ item }) => {
            return (
                <div>
                    <strong>{numeral(item.clicks / item.impressions).format('0.00%')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Actions',
        key: 'actions',
        data: 'actions',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload || !item.isClusterName) return null;

            return <DeleteButton clusterName={item.keyword} deleteItem={deleteItem} />;
        },
    },
];
