import React from 'react';
import ReactModal from '../Modal';
import ManualClusterSelect from '../manualClusterModal';
import { v4 as uuid } from 'uuid';
import Message from '../../modules/Message';

class UrlsModal extends React.Component {
    constructor() {
        super();

        this.onChange = this.onChange.bind(this);
        this.setValues = this.setValues.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);

        this.state = {
            value: '',
        };
    }

    onChange(e) {
        this.setState({
            value: e.target.value,
        });
    }

    onSubmit() {
        const values = this.state.value?.split('\n').filter((item) => item);
        const isExistWrongUrls = this.checkUrls(values);

        if (isExistWrongUrls)
            return this.setState({
                message: 'One or more URLs do not belong to this URL.',
                message_tags: 'error',
                loading: false,
            });

        const data = {
            domain_url: window.filters.get().domain.value,
            cluster_type: 'url',
            values,
            cluster_id: this.state.clusterId,
        };

        this.state.addList(data);
        this.setState({
            loading: true,
        });
    }

    setValues(clusterId, callback) {
        this.setState({
            clusterId,
            addList: callback,
        });
    }

    checkUrls(urls) {
        const domain = window.filters.get().domain.value;
        let notInThisDomain = [];
        if (domain.includes('sc-domain:')) {
            const domainName = domain.replace(/sc-domain:/, '');
            notInThisDomain = urls.filter((item) => {
                return !(
                    (item.startsWith('https://') || item.startsWith('http://')) &&
                    item.includes(domainName)
                );
            });
        } else {
            notInThisDomain = urls.filter((item) => {
                if (!item.startsWith(domain)) return true;
                return false;
            });
        }
        return notInThisDomain.length;
    }

    deleteMessage() {
        this.setState({
            message: '',
            message_tags: '',
        });
    }
    render() {
        const values = this.state.value?.split('\n').filter((item) => item);
        return (
            <>
                <ReactModal
                    modalState={this.props.isOpen}
                    className="width-sm"
                    onClose={this.props.onClose}
                >
                    <h2>URLs</h2>
                    <div className="urls-modal-container">
                        <label>You need to write each URL on a new line</label>
                        <textarea onChange={this.onChange} value={this.state.value} />
                    </div>
                    <div className="mb-10">
                        <ManualClusterSelect
                            current_url={this.props.current_url}
                            csrf_token={this.props.csrf_token}
                            selectedItems={values.map((item) => ({
                                id: uuid(),
                                url: item,
                            }))}
                            cluster_type="url"
                            header="Add To URLs List"
                            onRemoveAllSelected={this.onRemoveAllSelected}
                            onSaveList={this.props.onSaveUrlList}
                            fromManualList
                            setValues={this.setValues}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                            type="button"
                            className="button button-filled button-primary width-sm btn mr-10"
                            onClick={this.onSubmit}
                            style={{ lineHeight: 'unset' }}
                            disabled={
                                this.state.loading || !this.state.value || !this.state.clusterId
                            }
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="button button-outline button-primary width-sm btn"
                            onClick={this.props.onClose}
                            style={{ lineHeight: 'unset' }}
                        >
                            Close
                        </button>
                    </div>
                </ReactModal>
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tags}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default UrlsModal;
