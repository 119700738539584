import Query from '../../../../../components/Report';

const query = ({ project_id, offset = 0, intent_filter = '' }) =>
    new Query('commerce-detail-market-share', {
        project_id,
        offset,
        intent_filter: setIntent(intent_filter),
    });

export const getAllQuery = ({ project_id }) =>
    new Query('commerce-detail-market-share-all', { project_id });

export default query;

function setIntent(intent_filter) {
    if (!intent_filter || intent_filter?.length === 0) return '';

    return `and (${intent_filter?.map((item) => `dck.intent LIKE '%${item}%'`).join(' or ')})`;
}
