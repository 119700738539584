import { Component } from 'react';
import debounce from '../../../../../lib/debounce';
import numeral from 'numeral';

class SearchVolume extends Component {
    constructor() {
        super();

        this.onChangeSearchVolume = this.onChangeSearchVolume.bind(this);

        this.state = {
            min_search_volume: '',
            max_search_volume: '',
            errorMessage: '',
        };
    }

    debounceLog = debounce((params) => {
        this.props.onFilter(params);
    }, 1000);

    onChangeSearchVolume(e) {
        const data = {
            min_search_volume: this.state.min_search_volume,
            max_search_volume: this.state.max_search_volume,
        };
        data[e.target.name] = e.target.value;

        this.debounceLog({
            min_search_volume: data.min_search_volume,
            max_search_volume: data.max_search_volume,
        });

        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    render() {
        const minAndMax = this.props?.data?.reduce((acc, curr) => {
            if (acc.min > curr.search_volume || !acc.min) {
                acc.min = curr.search_volume;
            }

            if (acc.max < curr.search_volume || !acc.max) {
                acc.max = curr.search_volume;
            }

            return acc;
        }, {});

        let error = '';

        if (
            this.state.max_search_volume &&
            this.state.min_search_volume &&
            Number(this.state.max_search_volume ?? 0) < Number(this.state.min_search_volume ?? 0)
        ) {
            error = 'The maximum value must be greater than the minimum.';
        }

        return (
            <div className="search-volume">
                Search Volume
                <div className="row">
                    <div className="col-3">
                        <label>
                            Min (The minimum value from the data is{' '}
                            {numeral(minAndMax?.min).format('0.0a')})
                        </label>
                        <input
                            className="form-commerce-input"
                            name="min_search_volume"
                            value={this.state.min_search_volume}
                            onChange={this.onChangeSearchVolume}
                            type="number"
                            min={minAndMax?.min}
                            max={minAndMax?.max}
                            disabled={this.props.loading}
                        />
                    </div>
                    <div className="col-3">
                        <label>
                            Max (The maximum value from the data is{' '}
                            {numeral(minAndMax?.max).format('0.0a')})
                        </label>
                        <input
                            type="number"
                            className="form-commerce-input"
                            name="max_search_volume"
                            value={this.state.max_search_volume}
                            onChange={this.onChangeSearchVolume}
                            min={minAndMax?.min}
                            max={minAndMax?.max}
                            disabled={this.props.loading}
                        />
                    </div>
                </div>
                {error && <div className="error-message">{error}</div>}
            </div>
        );
    }
}

export default SearchVolume;
