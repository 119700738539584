import { Component } from 'react';
import Loading from '../../../components/Loading';
import { setDeviceQuery } from './queries';

class SearchBehavior extends Component {
    constructor(props) {
        super();
        this.updateInput = this.updateInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.activeDomain = props.domains?.find((item) => item.status);

        this.state = {
            questions: 'where,when,how,why,what',
            best_translation: 'Best,Top,Most',
        };
    }

    updateInput(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();

        const params = {
            domain_url: this.activeDomain?.url,
            questions: this.state.questions,
            best_translation: this.state.best_translation,
        };

        this.setState({ isLoading: true });

        setDeviceQuery(params, this.props.csrf_token)
            .then((res) => res.json())
            .then(() => {
                this.setState({ message: 'Added successfully.', message_tags: 'success' });
            })
            .catch((err) =>
                this.setState({
                    message: err.message ?? 'Something went wrong.',
                    message_tags: 'error',
                }),
            )
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        return (
            <>
                <div id="onboarding-general-settings">
                    {this.activeDomain ? (
                        <form onSubmit={this.onSubmit}>
                            <div className="onboarding-subheader">
                                How do users search for questions in your language? (e.g., in
                                English, users search using words like "where," "when," "how,"
                                "why," "what.")
                            </div>
                            <div className="row mr-10 align-items-center">
                                <div className="col-3">{this.activeDomain?.url}</div>
                                <div className="col-9 text-field">
                                    <input
                                        name="questions"
                                        className="form-control"
                                        onChange={this.updateInput}
                                        value={this.state.questions}
                                    />
                                </div>
                            </div>
                            <div className="onboarding-subheader mt-10">
                                How do users search for "best" keywords in your language? (e.g., in
                                English, people search using terms like "Best," "Top," "Most.")
                            </div>
                            <div className="row mr-10 align-items-center">
                                <div className="col-3">{this.activeDomain?.url}</div>
                                <div className="col-9 text-field">
                                    <input
                                        name="best_translation"
                                        className="form-control"
                                        onChange={this.updateInput}
                                        value={this.state.best_translation}
                                    />
                                </div>
                            </div>
                            <button
                                className="button button-outline button-primary width-sm btn d-flex justify-content-center"
                                disabled={this.state.isLoading}
                            >
                                Save
                                {this.state.isLoading && <Loading onlyPreloader />}
                            </button>
                        </form>
                    ) : (
                        'You need to activate the domain.'
                    )}
                    {this.state.message && (
                        <div className={`onboarding-message-${this.state.message_tags}`}>
                            {this.state.message}
                        </div>
                    )}
                    <div className="d-flex justify-content-end">
                        <button
                            className="button width-sm btn onboarding-btn-next d-flex justify-content-center onboarding-skip-btn"
                            style={{ lineHeight: 'unset' }}
                            onClick={() => this.props.handleBack()}
                        >
                            back
                        </button>
                        <button
                            className="button  button-filled button-primary width-sm btn onboarding-btn-next d-flex justify-content-center"
                            onClick={() => this.props.handleNext()}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

export default SearchBehavior;
