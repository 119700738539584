import { Scatter } from 'react-chartjs-2';
import Component from '../../../components/Component';
import DataTable from '../../../components/Datatable';
import { scrollForTable } from '../../../components/Datatable/utils';
import Loading from '../../../components/Loading';
import { COLUMNS } from './keys';
import query from './query';
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js';
import PagesModal from '../../../components/Modals/PagesModal';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

class Entity extends Component {
    constructor(props) {
        super();
        this.query = query(props.entity);

        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    searchFunction(data, search) {
        return data.filter((item) => item.name?.toLowerCase().includes(search.toLowerCase()));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.entity !== this.props.entity) {
            this.query = query(this.props.entity);
            this.query.bind(this.setState.bind(this));
            this.query.update();
        }
    }

    getSize(size) {
        if (size < 3) return 3;
        if (size > 30) return 30;
        return size;
    }

    onOpenModal(pages) {
        this.setState({
            isOpen: true,
            selectedPages: pages,
        });
    }

    onCloseModal() {
        this.setState({
            isOpen: false,
        });
    }

    render() {
        if (this.state.loading)
            return (
                <div className="row card-row d-flex" id="summary">
                    <div className="col-12">
                        <div className="card rounded-main overflow-hidden " id="domain-summery">
                            <div className="card-body">
                                <Loading />
                            </div>
                        </div>
                    </div>
                </div>
            );

        const options = {
            maintainAspectRatio: false,
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: 'Pages Count',
                    },
                },
                y: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: 'Relevancy',
                    },
                    beginAtZero: true,
                },
            },
            plugins: {
                legend: {
                    align: 'start',
                    labels: {
                        usePointStyle: true,
                        pointStyle: 'rect',
                    },
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            return `${context.raw.label}: ${context.formattedValue}`;
                        },
                    },
                },
            },
        };
        const chartData = {
            datasets: [
                {
                    label: 'Relevancy',
                    data: this.state.data.map((item) => ({
                        label: item.name,
                        x: item.pagesCNT,
                        y: item.relevancy,
                    })),
                    backgroundColor: this.state.data.map((item) =>
                        item.wiki ? 'green' : 'yellow',
                    ),
                    pointRadius: this.state.data.map((item) => this.getSize(item.size / 10)),
                    hoverRadius: this.state.data.map((item) => this.getSize(item.size / 10)),
                },
            ],
        };

        const sortFns = {
            name: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
            wiki: (array) => array.sort((a, b) => a.wiki - b.wiki),
            relevancy: (array) => array.sort((a, b) => a.relevancy - b.relevancy),
            pagesCNT: (array) => array.sort((a, b) => a.pagesCNT - b.pagesCNT),
            pages: (array) => array.sort((a, b) => a.pages?.[0].localeCompare(b.pages?.[0])),
        };

        return (
            <>
                <div className="row card-row d-flex" id="summary">
                    <div className="col-12">
                        <div
                            className="card table-main rounded-main overflow-hidden "
                            id="domain-summery"
                        >
                            <div className="card-header">
                                <p className="card-title">"{this.props.entity}" Entity Clusters</p>
                            </div>
                            {this.state.data && this.state.data?.[0] ? (
                                <div className="card-body" style={{ minHeight: '600px' }}>
                                    <Scatter options={options} data={chartData} />
                                </div>
                            ) : (
                                <div className="card-body">'No Data'</div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row card-row d-flex" id="summary">
                    <div className="col-12">
                        <div
                            className="card table-main rounded-main overflow-hidden "
                            id="domain-summery"
                        >
                            <div className="card-header">
                                <p className="card-title">"{this.props.entity}" Entity Clusters</p>
                                <small className="d-block card-sub-title text-muted fs-7 py-2 text-capitalize">
                                    he following data displays the most common connected entities
                                    with "{this.props.entity}" across pages that gets Discover
                                    traffic and has relevancy higher than 10%
                                </small>
                            </div>
                            <div className="card-body">
                                <DataTable
                                    nodes={this.state.data ?? []}
                                    sortFns={sortFns}
                                    COLUMNS={COLUMNS(this.onOpenModal)}
                                    fileName="Entitiy Pairs"
                                    searchFunction={this.searchFunction}
                                    additionalStyles={{
                                        Table: `
                                            --data-table-library_grid-template-columns :  minmax(150px, 1.5fr) minmax(150px, 1.5fr) minmax(120px, 1fr) minmax(150px, 1fr) minmax(150px, 1.5fr) !important;
                                            ${scrollForTable}
                                        `,
                                        BaseCell: `  font-family: 'Raleway';
                                                    font-weight: 500;
                                                    font-size: 16px;
                                                    line-height: 19px;
                                                    padding: 16px 5px !important;
                                                    color: #0D182C;
                                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                
                                                    &.underline {
                                                        text-decoration: underline;
                                                    }
                                                    `,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <PagesModal
                    pages={this.state.selectedPages}
                    isOpen={this.state.isOpen}
                    onClose={this.onCloseModal}
                />
            </>
        );
    }
}

export default Entity;
