import React from 'react';
// import SortDefault from "../../../static/img/sort_default.svg";
// import SortUp from "../../../static/img/sort_up.svg";
// import SortDown from "../../../static/img/sort_down.svg";

export default function SortIcon({ size = 'sm', type = 'right', active = false, state }) {
    const Icon = (state) => {
        switch (state) {
            case 'up':
                return 'icon-arrow-up';
            case 'down':
                return 'icon-arrow-down';
            default:
                return 'icon-sort-default';
        }
    };

    return (
        <div className={`dt-sort__icon ${size} ${type} ${active ? 'active' : ''}`}>
            <i className={Icon(state)} />
        </div>
    );
}
