import React, { Component } from 'react';
import Loading from '../../components/Loading';
import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import Table from './Table';
import moment from 'moment';
import PageTable from './PagesView';
import Categories from './Categories';
import Entities from './Entities';

class ScrapPagesTest extends Component {
    constructor(props) {
        super();

        this.result = props.result;
        this.onHandleText = this.onHandleText.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.collectData = this.collectData.bind(this);
        this.collectEntities = this.collectEntities.bind(this);
        this.onChangeRadio = this.onChangeRadio.bind(this);

        (this.end_date = moment().format('YYYY-MM-DD')),
            (this.start_date = moment().subtract(7, 'days').format('YYYY-MM-DD')),
            (this.state = {
                textValue: '',
                domain_url: '',
                error: {},
                showedValue: [],
                isLoading: false,
                savedBody: {},
                data: [],
                columns: [],
                results: {},
                nlp_entity: 'google',
                nlp_category: 'google',
            });
    }

    searchFunction(data, search) {
        return data.filter((item) => {
            for (let key in item) {
                if (item[key]?.toString().toLowerCase().includes(search.toLowerCase())) return true;
            }
            return false;
        });
    }
    handleSelect(e) {
        this.setState({
            domain_url: e.value,
            error: {
                ...this.state.error,
                select: '',
            },
        });
    }

    onHandleText(e) {
        this.setState({
            textValue: e.target.value,
            error: {
                ...this.state.error,
                input: '',
            },
        });
    }

    onSubmit() {
        const { textValue, domain_url } = this.state;
        try {
            // if (!domain_url)
            //     return this.setState({
            //         error: { ...this.state.error, select: 'You need to select domain.' },
            //     });
            this.sendMessage(textValue, domain_url);
        } catch (err) {
            return this.setState({
                error: { ...this.state.error, input: 'Please enter valid object' },
            });
        }
    }

    async sendMessage(resource, domain_url = '') {
        try {
            this.setState({ isLoading: true, data: [], savedBody: resource });

            const response = await fetch(
                'https://us-central1-eseos-apps.cloudfunctions.net/getPageEntities',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        params: {
                            force: true,
                            page: this.state.textValue,
                            domain: domain_url,
                            nlp_entity: this.state.nlp_entity, // google or ibm
                            nlp_category: this.state.nlp_category, // google or ibm
                        },
                    }),
                },
            );
            const data = await response.json();

            //results
            if (data?.status === 200 && data?.results) {
                this.setState({
                    results: data.results,
                    error: { ...this.state.error, input: '' },
                });
                this.collectData();
            } else {
                this.setState({
                    results: [],
                    error: {
                        ...this.state.error,
                        input: data?.message ?? 'Something went wrong. Please try again.',
                    },
                });
            }
        } catch (err) {
            return this.setState({
                error: {
                    ...this.state.error,
                    input: err?.message ?? 'Something went wrong. Please try again.',
                },
            });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    collectData() {
        try {
            if (this.state.showedValue?.length === 0) return;
            const parsedData = _.cloneDeep(this.state.showedValue);

            const data = parsedData?.map((item) => {
                if (item?.keys) {
                    const dimensions = item.keys.reduce((acc, curr, i) => {
                        const key = (this.state.savedBody?.dimensions?.[i] ?? `key - ${i}`).replace(
                            /_/g,
                            ' ',
                        );
                        acc[key] = curr;
                        return acc;
                    }, {});
                    delete item.keys;
                    return { ...dimensions, ...item, id: uuid() };
                }
                return item;
            });

            const keys = Object.keys(data?.[0])?.filter((item) => item !== 'id') ?? [];
            const columns = keys.map((key) => ({
                label: key,
                key: key,
                data: key,
                Component: Cell,
                renderCell: ({ data }) => {
                    if (key === 'ctr') return numeral(data).format('0.00%');
                    if (typeof data === 'number') return numeral(data).format('0.0a');
                    return data;
                },
            }));
            this.setState({ data, columns });
        } catch (err) {
            console.log(err);
        }
    }

    collectEntities() {
        const entities = this.state.results.entities.map((item) => {
            return {
                name: item.name || item.text,
                type: item.type,
                salience: item.salience || item.relevance,
                sentiment: (item.sentiment && item.sentiment.score) || 0,
            };
        });

        return entities ?? [];
    }

    onChangeRadio(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    render() {
        return (
            <div className="gsc-testing-container">
                <div>
                    <div className="gsc-testing-header">Page</div>
                    <input
                        className="scrap-pages-input"
                        onChange={this.onHandleText}
                        value={this.state.textValue}
                    />
                </div>
                <div>
                    {this.state.error?.input && (
                        <div className="error-message">{this.state.error.input}</div>
                    )}
                </div>
                <div className="d-flex mb-10">
                    <div className="mr-100">
                        <div className="gsc-testing-header">NLP Entity</div>
                        <div className="d-flex">
                            <div className="gsc-testing-nlp-box">
                                <label htmlFor="nlp_entity_ibm">IBM</label>
                                <input
                                    id="nlp_entity_ibm"
                                    type="radio"
                                    name="nlp_entity"
                                    value="ibm"
                                    onChange={this.onChangeRadio}
                                    checked={this.state.nlp_entity === 'ibm'}
                                />
                            </div>
                            <div className="gsc-testing-nlp-box">
                                <label htmlFor="nlp_entity_google">GOOGLE</label>
                                <input
                                    id="nlp_entity_google"
                                    type="radio"
                                    name="nlp_entity"
                                    value="google"
                                    checked={this.state.nlp_entity === 'google'}
                                    onChange={this.onChangeRadio}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="gsc-testing-header">NLP Category</div>
                        <div className="d-flex">
                            <div className="gsc-testing-nlp-box">
                                <label htmlFor="nlp_category_ibm">IBM</label>
                                <input
                                    id="nlp_category_ibm"
                                    type="radio"
                                    name="nlp_category"
                                    value="ibm"
                                    checked={this.state.nlp_category === 'ibm'}
                                    onChange={this.onChangeRadio}
                                />
                            </div>
                            <div className="gsc-testing-nlp-box">
                                <label htmlFor="nlp_category_google">GOOGLE</label>
                                <input
                                    id="nlp_category_google"
                                    type="radio"
                                    name="nlp_category"
                                    value="google"
                                    checked={this.state.nlp_category === 'google'}
                                    onChange={this.onChangeRadio}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="execute-btn">
                    <button
                        onClick={this.onSubmit}
                        className="bg-purple gsc-testing-execute"
                        disabled={this.state.isLoading}
                    >
                        Execute
                    </button>
                    {this.state.isLoading && <Loading />}
                </div>
                <Table
                    data={this.state.data}
                    columns={this.state.columns}
                    searchFunction={this.searchFunction}
                    isLoading={this.state.isLoading}
                />
                {this.state.results.meta && (
                    <PageTable
                        data={[{ ...this.state.results.meta, words: this.state.results.words }]}
                    />
                )}
                {this.state.results.categories && (
                    <div>
                        <h3 style={{ padding: '20px 0 0 20px' }}>Categories</h3>
                        <Categories data={this.state.results.categories} />
                    </div>
                )}
                {this.state.results.entities && (
                    <div>
                        <h3 style={{ padding: '20px 0 0 20px' }}>Entities</h3>
                        <Entities data={this.collectEntities()} />
                    </div>
                )}
                {this.state.results.content && (
                    <div>
                        <h3 style={{ padding: '20px 0 0 20px' }}>Content</h3>
                        <div style={{ padding: '20px' }}>{this.state.results.content}</div>
                    </div>
                )}
            </div>
        );
    }
}

export default ScrapPagesTest;
