import Component from '../../components/Component';
import Timeline from '../../components/Timeline';
import Loading from '../../components/Loading';

import query from './query';
import keys from './keys';

class Summary extends Component {
    constructor() {
        super();
        this.query = query;
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        return (
            <Timeline
                data={this.state.data}
                direct_parent_id={this.props.direct_parent_id}
                error={this.state.error}
                forceRun={this.forceRun}
                keys={keys}
                content_id={this.props.content_id}
                parent_id={this.props.parent_id}
                hasRightAxis
            />
        );
    }
}

export default Summary;
