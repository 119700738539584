export function fillMissingDates(data) {
    if (!Array.isArray(data)) return [];
    // Sort data by date (just in case)
    data.sort((a, b) => new Date(a.date.value) - new Date(b.date.value));

    let filledData = [];
    let currentDate = new Date(data[0].date.value); // Start from the earliest date
    const lastDate = new Date(data[data.length - 1].date.value);

    let index = 0;

    while (currentDate <= lastDate) {
        let dateStr = currentDate.toISOString().split('T')[0]; // Format YYYY-MM-DD

        if (index < data.length && data[index].date.value === dateStr) {
            filledData.push(data[index]); // Add existing entry
            index++;
        } else {
            // Insert missing date entry
            let newEntry = {};
            Object.keys(data[0]).forEach((key) => {
                if (key === 'date') {
                    newEntry[key] = { value: dateStr }; // Set correct date
                } else {
                    newEntry[key] = null; // Assign null for all other keys
                }
            });

            filledData.push(newEntry);
        }
        currentDate.setDate(currentDate.getDate() + 1); // Move to next day
    }

    return filledData;
}
