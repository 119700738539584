import { Component } from 'react';
import TrackGoogleDiscoverResultsAdd from '../../TrackGoogleDiscoverResultsAdd';

class TrackGoogleDiscover extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div id="onboarding-general-settings">
                <div className="onboarding-subheader">
                    Our tool can track Google Discover and identify viral stories and top
                    competitors. Please provide a key entity important to you (e.g., Taylor Swift or
                    any topic you write about frequently).
                </div>
                <div className="onboarding-subheader">
                    Your Trial Account comes with 1 Entity Tracking. You are tracking{' '}
                    {this.props.tracking_entity ? 1 : 0} so far.{' '}
                    {this.props.tracking_entity
                        ? 'Please go to the next step.'
                        : 'Please add an entity below to track.'}
                </div>
                <TrackGoogleDiscoverResultsAdd
                    csrf_token={this.props.csrf_token}
                    default_image={this.props.default_image}
                    defaultDisabled={this.props.tracking_entity}
                    fromOnboard
                />
                <div className="d-flex justify-content-end">
                    <button
                        className="button width-sm btn onboarding-btn-next d-flex justify-content-center onboarding-skip-btn"
                        onClick={() => this.props.handleBack()}
                    >
                        back
                    </button>
                    <button
                        className="button  button-filled button-primary width-sm btn onboarding-btn-next d-flex justify-content-center"
                        onClick={() => this.props.handleNext()}
                    >
                        Next
                    </button>
                </div>
            </div>
        );
    }
}

export default TrackGoogleDiscover;
