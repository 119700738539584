export const SEASONAL_PAGE = 'gsc-seasonal-report';

export const SEASONAL_FEATURE_SETTINGS = 'settings-icon';

export const SEAONAL_SETTINGS_STEPS = [
    {
        content:
            'Click on the Settings "gear" icon to add seasonal keywords. Enter the label for each group, and the keywords for the group',
        target: '.seasons-settings-btn',
        disableBeacon: true,
        disableOverlayClose: false,
        disableScrolling: true,
        locale: {
            last: 'Close',
        },
    },
];
