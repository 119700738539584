import CheckData from '../../../components/CatchError';
import CommerceFilterIntent from '../../../components/CommerceFilterIntent';
import CommerceTabs from '../../../components/CommerceTabs';
import Component from '../../../components/Component';
import DataTable from '../../../components/Datatable';
import { changeIntent } from '../../../helpers/commerce';
import { getSortKeys, setAdditionalStyles } from '../../../helpers/table';
import { COLUMNS } from './keys';
import query from './query';
import { v4 as uuid } from 'uuid';

class CommerceViewDetailKeyword extends Component {
    constructor(props) {
        super();

        this.handleSelectChange = this.handleSelectChange.bind(this);

        this.url = new URL(window.location);
        this.intent = this.url.searchParams.get('intent');
        this.own_domain = this.url.searchParams.get('own_domain');

        const params = {
            project_id: props.project_id,
            keyword: props.keyword,
        };

        if (this.intent) {
            params.intent_filter = this.intent?.split(',');
        }

        this.query = query(params);
    }

    searchFunction(data, search) {
        return data.filter((item) => item?.url?.toLowerCase()?.includes(search.toLowerCase()));
    }

    componentDidUpdate(_, prevState) {
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            const data = this.state.data?.map((x) => ({ id: uuid(), ...x }));
            this.setState({ allData: data });
        }
    }

    async handleSelectChange(values) {
        this.setState({
            select: values,
            loading: true,
            data: null,
            allData: [],
        });

        changeIntent(values);

        this.query = query({
            project_id: this.props.project_id,
            intent_filter: values?.map((item) => item.value),
            keyword: this.props.keyword,
        });
        this.query.bind(this.setState.bind(this));
        this.query.update();
    }

    render() {
        const extraBtns = () => (
            <CommerceFilterIntent
                select={this.state.select}
                handleSelectChange={this.handleSelectChange}
            />
        );
        return (
            <>
                <CommerceTabs project_id={this.props.project_id} keyword={this.props.keyword} />
                <div className="card rounded-main table-main">
                    <div
                        className="card-header d-flex justify-content-between"
                        style={{ borderRadius: '10px' }}
                    >
                        <div className="card-title text-dark fw-bold">Commerce</div>
                    </div>
                    <div className="card-body">
                        <div>
                            <CheckData
                                data={this.state?.allData ?? []}
                                loading={this.state.loading}
                            >
                                <DataTable
                                    nodes={this.state?.allData ?? []}
                                    sortFns={getSortKeys(COLUMNS(this.own_domain))}
                                    COLUMNS={COLUMNS(this.own_domain)}
                                    fileName="Project"
                                    searchFunction={this.searchFunction}
                                    defaultSortKey={'position'}
                                    extraBtns={extraBtns}
                                    additionalStyles={setAdditionalStyles(COLUMNS(this.own_domain))}
                                    defaultSize={50}
                                />
                            </CheckData>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CommerceViewDetailKeyword;
