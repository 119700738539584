export const getAccountForDomain = (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/get-account-for-domain/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
