import Query from '../../../components/Report';

export const query = (branded_terms) =>
    new Query('search-summary-branded', {
        branded_terms: `(${branded_terms})`,
    });

export const setDeviceQuery = async (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/add-domain-gsc-data/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
