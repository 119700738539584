export const getEntity = (endpoint_url, csrf_token, params) => {
    return fetch(`${endpoint_url}/tools/entity/${params.language}/${params.country}/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify({
            entity_id: params.entity_id,
        }),
    });
};
export default getEntity;
