import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import { escape } from '../../helpers/symbols';

export const COLUMNS = (default_image, onChangeRadio, selectedItem) => [
    {
        label: '',
        key: 'radio',
        data: 'radio',
        Component: Cell,
        renderCell: ({ item }) => {
            return (
                <input
                    type="radio"
                    name="entity-radio"
                    checked={selectedItem === item.id}
                    onChange={() => onChangeRadio(item.id)}
                />
            );
        },
    },
    {
        label: 'Name',
        key: 'name',
        data: 'name',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            const title = escape(data);
            if (csvDownload) return title;
            return (
                <div>
                    <div className="d-flex align-items-center tracking-pages-name wripe w-100">
                        <div style={{ float: 'left', marginRight: '10px' }}>
                            <img
                                onError={(event) => (event.target.src = default_image)}
                                src={item.image ?? default_image}
                                alt="page_image"
                                loading="lazy"
                            />
                        </div>
                        <strong>{title}</strong>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Entity Type',
        key: 'type',
        data: 'type',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <span className="wripe">{data}</span>;
        },
    },
    {
        label: 'Wikipedia',
        key: 'url',
        data: 'url',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            const linkName = data?.split('/wiki/')?.[1] ?? data;
            if (csvDownload) return linkName;
            return linkName ? (
                <div className="d-flex justify-content-between">
                    <div className="mr-10" style={{ maxWidth: 'calc(100% - 47px)' }}>
                        <strong>{window.decodeURIComponent(linkName)}</strong>
                    </div>
                    <div className="d-flex align-items-center">
                        <a
                            target="_blank"
                            className="link-website d-inline-flex align-items-center justify-content-center"
                            href={data}
                            rel="noreferrer"
                        >
                            <i className="icon-external-link"></i>
                        </a>
                    </div>
                </div>
            ) : (
                '-'
            );
        },
    },
    {
        label: 'Description',
        key: 'description',
        data: 'description',
        Component: Cell,
    },
    {
        label: 'Score',
        key: 'score',
        data: 'score',
        info: 'An indicator of how well the entity matches the query',
        Component: Cell,
        renderCell: ({ data }) => {
            return numeral(data).format('0.0a');
        },
    },
];
