import React, { Component } from 'react';

import ReactJoyride, { ACTIONS } from 'react-joyride';
import { getValues, setValues } from './query';
import { sleep } from '../../helpers/sleep';

class OnboardingJoyride extends Component {
    constructor(props) {
        super();

        this.handleChange = this.handleChange.bind(this);
        this.page = props.page;
        this.feature = props.feature;
        this.domain_url = window.filters.get().domain.value;

        this.params = {
            page: this.page,
            feature: this.feature,
            domain_url: this.domain_url,
        };

        this.state = {
            isShow: false,
        };

        this.seeAnnouncement = this.seeAnnouncement.bind(this);
    }

    async componentDidMount() {
        try {
            const response = await getValues(this.props.endpoint_url, this.params);
            const data = await response.json();
            const exists = data.exists;
            await sleep();
            this.setState({
                isShow: !exists,
            });
        } catch (err) {
            console.log(err);
        }
    }

    async seeAnnouncement() {
        try {
            await setValues(this.props.endpoint_url, this.props.csrf_token, this.params);
        } catch (err) {
            console.log(err);
        }
    }

    handleChange(event) {
        if (event.action === ACTIONS.RESET) {
            this.seeAnnouncement();
        }
    }
    render() {
        const { steps } = this.props;
        const { isShow } = this.state;

        return (
            isShow && (
                <ReactJoyride
                    steps={steps}
                    continuous
                    run={true}
                    showSkipButton
                    callback={this.handleChange}
                    disableScrollParentFix
                    disableScrolling
                    disableOverlayClose
                    styles={{
                        options: {
                            zIndex: 10000,
                            primaryColor: '#2175D8',
                        },
                    }}
                    hideCloseButton
                />
            )
        );
    }
}

export default OnboardingJoyride;
