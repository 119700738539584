const query = (base_url, id, data, csrf_token) => {
    return fetch(`${base_url}/domains/${id}/refresh/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export default query;
