import { Component } from 'react';
import { deleteLinkedAccount } from './query';
import Loading from '../../components/Loading';

class DeleteLinkedUser extends Component {
    constructor() {
        super();

        this.deleteAccount = this.deleteAccount.bind(this);

        this.state = {
            loading: false,
        };
    }

    async deleteAccount() {
        try {
            const { endpoint_url, csrf_token, account_id, user_email } = this.props;
            this.setState({ loading: true });
            await deleteLinkedAccount(endpoint_url, csrf_token, account_id, user_email);
            window.location.reload();
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        return (
            <div className="dropdown align-right">
                {this.state.loading ? (
                    <Loading onlyPreloader />
                ) : (
                    <button
                        onClick={this.deleteAccount}
                        className="btn text-primary dropdown-toggle damage d-inline-flex align-items-center justify-content-center btn-icon d-flex"
                        title="Add Domain"
                    >
                        <i className="icon-delete"></i>
                    </button>
                )}
            </div>
        );
    }
}

export default DeleteLinkedUser;
