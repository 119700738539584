import React, { Component } from 'react';
import ReactModal from '../../../components/Modal';
import ReactSelect from '../../../components/ReactSelect';
import query from '../GSCReportDirectories/query';
import { setEvergreenWeeksQuery } from './query';
import Loading from '../../../components/Loading';

class ActionsModal extends Component {
    constructor(props) {
        super();
        this.query = query;
        this.changeView = this.changeView.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            isOpen: false,
            weeksCount: props.defaultValue ?? 4,
            showEditMode: props.showOpened ?? false,
            isLoadingEvergreen: false,
            include_directories:
                props.include_directories?.map((item) => ({ value: item, label: item })) ?? [],
            exclude_directories:
                props.exclude_directories?.map((item) => ({ value: item, label: item })) ?? [],
            data: [],
        };
    }

    onSubmit(e) {
        e.preventDefault();

        const include_directories = this.state.include_directories
            .map((item) => item.value)
            .join(',');
        const exclude_directories = this.state.exclude_directories
            .map((item) => item.value)
            .join(',');

        const params = {
            domain_url: window.filters.get().domain.value,
            evergreen_weeks: this.state.weeksCount,
            include_directories,
            exclude_directories,
        };

        this.setState({ isLoadingEvergreen: true });
        setEvergreenWeeksQuery(this.props.endpoint_url, params, this.props.csrf_token)
            .then((res) => res.json())
            .then((data) => {
                const evergreenWeeks = data.evergreen_weeks;
                this.props.getData({
                    evergreenWeeks,
                    include_directories: this.state.include_directories.map((item) => item.value),
                    exclude_directories: this.state.exclude_directories.map((item) => item.value),
                });
                this.onClose();
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ isLoadingEvergreen: false });
            });
    }
    changeView(bool) {
        this.setState({ showEditMode: bool });
    }

    async onOpen() {
        this.setState({ isOpen: true });
        if (this.state.data) {
            this.query.bind(this.setState.bind(this));
            await this.query.update();
        }
    }
    onClose() {
        this.setState({ isOpen: false });
    }

    onChangeSelect(key, value) {
        this.setState({ [key]: value });
    }

    handleChange(e) {
        this.setState({ weeksCount: e.target.value });
    }

    render() {
        let options = [];
        if (this.state.data && this.state.data?.length !== 0) {
            options = this.state.data
                ?.map((item) => ({
                    label: item.first_directory,
                    value: item.first_directory,
                }))
                .filter((item) => item && item !== '/');
        }
        return (
            <>
                <div className="change-gsc-field-form">
                    <button
                        type="button"
                        className="button button-filled button-primary button-icon"
                        id="name-edit-btn"
                        onClick={this.onOpen}
                    >
                        <i className="icon-settings"></i>
                    </button>
                </div>
                <ReactModal modalState={this.state.isOpen} className="width-sm">
                    <form onSubmit={this.onSubmit}>
                        <p>
                            <label>Evergreen weeks</label>
                            <input
                                className="form-control"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.weeksCount}
                            />
                        </p>
                        <ReactSelect
                            options={options}
                            value={this.state.include_directories}
                            label="Include Directories"
                            isSearchable={true}
                            placeholder="Include Directories"
                            onChange={(value) => this.onChangeSelect('include_directories', value)}
                            blurInputOnSelect={true}
                            classname="mr-10"
                            isMulti
                        />
                        <ReactSelect
                            options={options}
                            value={this.state.exclude_directories}
                            label="Exclude Directories"
                            isSearchable={true}
                            placeholder="Exclude Directories"
                            onChange={(value) => this.onChangeSelect('exclude_directories', value)}
                            blurInputOnSelect={true}
                            classname="mr-10"
                            isMulti
                        />
                        <div className="btn-wrapper">
                            <button
                                type="button"
                                className="button button-outline button-primary width-sm btn"
                                onClick={this.onClose}
                                style={{ lineHeight: 'unset' }}
                            >
                                Cancel
                            </button>
                            <button
                                className="button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center"
                                disabled={this.state.isLoadingEvergreen}
                            >
                                <span style={{ marginRight: '5px' }}>Update</span>
                                {this.props.isLoadingEvergreen && <Loading onlyPreloader />}
                            </button>
                        </div>
                    </form>
                </ReactModal>
            </>
        );
    }
}

export default ActionsModal;
