import React from 'react';
import Info from './steps/Info';
import Billing from './steps/Billing';
import Confirm from './steps/Confirm';
import PromoCode from './PromoCode';

class Registration extends React.Component {
    constructor(props) {
        super();
        this.state = {
            active: props.active || false,
            data: {},
            step: 0,
            promotion_code: '',
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.setPromotionCode = this.setPromotionCode.bind(this);
    }

    next(data) {
        const newState = { ...this.state };
        Object.assign(newState.data, data);
        newState.step += 1;

        this.setState(newState);
    }

    previous(cardholder_name) {
        const newState = { ...this.state };
        const step = newState.step - 1;

        const data = {
            ...this.state.data,
        };

        if (cardholder_name) data.cardholder_name = cardholder_name;

        this.setState({ step, data });
    }

    setPromotionCode(code) {
        this.setState({
            promotion_code: code,
        });
    }

    render() {
        const geo_locations = JSON.parse(this.props.geo_locations);
        let steps = [
            <Info
                data={this.state.data}
                next={this.next}
                current_url={this.props.current_url}
                geo_locations={geo_locations}
                key={0}
                monthly_pages={this.props.monthly_pages}
                monthly_search_records={this.props.monthly_search_records}
            />,
            <Billing data={this.state.data} next={this.next} previous={this.previous} key={1} />,
            <Confirm
                data={this.state.data}
                params={this.props}
                promotion_code={this.state.promotion_code}
                next={this.next}
                previous={this.previous}
                key={2}
            />,
        ];

        return (
            <div className="row d-flex justify-content-center align-items-top registration-flow">
                <div className="col-12">
                    <a href="/" className="logo">
                        <img
                            className="logo-text"
                            alt="logo"
                            src="/static/img/logo-colored.svg"
                            loading="lazy"
                        />
                    </a>
                    <h2>You&apos;re registering for the GDDash</h2>
                </div>
                <PromoCode
                    domains_count={this.props.domains_count}
                    promotion={this.props.promotion}
                    endpoint_url={this.props.endpoint_url}
                    product={this.props.product}
                    product_id={this.props.product_id}
                    discount_price={this.props.discount_price}
                    price={this.props.price}
                    show_plans={this.props.show_plans}
                    setPromotionCode={this.setPromotionCode}
                    additional_pages_payment_amount={this.props.additional_pages_payment_amount}
                    per_page_amount={this.props.per_page_amount}
                    search_per_page_amount={this.props.search_per_page_amount}
                />
                <div className="col-lg-6 col-sm-6 col-xs-12">{steps[this.state.step]}</div>
            </div>
        );
    }
}

export default Registration;
