import { Component } from 'react';

class ExtraButtons extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <>
                <div className="categories-view no-margin">
                    <div>
                        {' '}
                        <i
                            title="Only show articles that have 75% of maximum clicks for at least 3 weeks or more"
                            className="icon-info cursor-pointer"
                            style={{ marginLeft: '7px' }}
                        ></i>{' '}
                        High Potential
                    </div>
                    <div className="switch-container">
                        <button
                            className={`switch-button ${
                                this.props.toggle ? 'active' : 'not-active'
                            }`}
                            onClick={() => this.props.onToggle(!this.props.toggle)}
                        >
                            <span className="switch-circle"></span>
                        </button>
                    </div>
                </div>
                <div className="categories-view ml-10">
                    <div>
                        <i
                            title="Only show evergreen articles that need to be optimized"
                            className="icon-info cursor-pointer"
                            style={{ marginLeft: '7px' }}
                        ></i>{' '}
                        Optimization Recommended
                    </div>
                    <div className="switch-container">
                        <button
                            className={`switch-button ${
                                this.props.isOptimiz ? 'active' : 'not-active'
                            }`}
                            onClick={() => this.props.optimiz()}
                        >
                            <span className="switch-circle"></span>
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

export default ExtraButtons;
