import { Component } from 'react';
import Loading from '../../components/Loading';
import CompetitiveAnalysisView from '../CompetitiveAnalysisView';

class CompetitiveAnalysisContainer extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div id="competitive-content">
                {!this.props.message ? (
                    <div className="row card-row" id="competitive-view">
                        <div className="col-12 col-sm-12">
                            <div
                                className="card table-main rounded-main overflow-hidden"
                                style={{ overflow: 'hidden' }}
                            >
                                <div className="card-header" style={{ borderRadius: '10px' }}>
                                    <div className="card-title text-dark fw-bold">
                                        Competitive Analysis
                                    </div>
                                    <small className="d-block card-sub-title text-muted fs-7 py-2 text-capitalize">
                                        This report analyzes your content performance against the
                                        whole dataset of 600B impressions over the past 90 days.
                                        <ul>
                                            <li>
                                                If your Avg. Impressions/Page is higher than
                                                competitors, then you are more authoritative (and
                                                vice versa).
                                            </li>
                                            <li>
                                                If your Avg. Clicks/Page or Avg CTR are lower than
                                                competitors, then your titles and/or images are less
                                                efficient than competitors.
                                            </li>
                                        </ul>
                                    </small>
                                </div>
                                <div className="card-body">
                                    <CompetitiveAnalysisView
                                        content_id="competitive-view"
                                        parent_id="competitive-content"
                                        image_strong={this.props.image_strong}
                                        image_striking_distance={this.props.image_striking_distance}
                                        image_weak={this.props.image_weak}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="row card-row d-flex" id="authors-content">
                        <div className="col-12">
                            <div className="card table-main rounded-main overflow-hidden">
                                <img
                                    src={this.props.default_image}
                                    alt="Copetetive analysis image"
                                    loading="lazy"
                                />
                                <div className="redirect-button">
                                    {this.props.isLink ? (
                                        <a
                                            className="bg-purple button-close"
                                            href={this.props.href}
                                        >
                                            {this.props.buttonName}
                                        </a>
                                    ) : (
                                        <div>
                                            <button
                                                className={'bg-purple button-close'}
                                                onClick={this.props.onClick}
                                                disabled={this.props.loading}
                                            >
                                                {this.props.buttonName}
                                            </button>
                                            <div className="d-flex justify-content-center pt-2">
                                                {this.props.loading && <Loading onlyPreloader />}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
export default CompetitiveAnalysisContainer;
